export async function translateText(text: string, targetLanguage: string, apiKey: string) {
  const prompt = `Translate the following text to ${targetLanguage}. Maintain the original formatting and structure as much as possible:\n\n${text}`;

  const response = await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    },
    body: JSON.stringify({
      model: "gpt-4o-2024-08-06",
      messages: [{ role: "user", content: prompt }],
      temperature: 0.7
    })
  });

  const data = await response.json();
  return data.choices[0].message.content.trim();
}

export async function getAIAssistance(prompt: string, context: string, apiKey: string) {
  const fullPrompt = `Given the following context, ${prompt}\n\nContext: ${context}`;

  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: "gpt-4o-2024-08-06",
        messages: [{ role: "user", content: fullPrompt }],
        temperature: 0.7
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`API request failed: ${errorData.error?.message || response.statusText}`);
    }

    const data = await response.json();

    if (!data.choices || data.choices.length === 0) {
      throw new Error('No choices returned from the API');
    }

    return data.choices[0].message.content.trim();
  } catch (error) {
    console.error('Error in getAIAssistance:', error);
    throw error;
  }
}
