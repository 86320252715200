import { IStore, IVirtualBoutiqueConfig } from './../interfaces/index';
import { IConcurrentAvailableSlot } from './../components/VirtualBoutique/Appointment/IWCAmsterdamAppointment';
import { AxiosResponse } from 'axios';
import { IStoreDevice } from '../interfaces';
import {
  getStreamingStudioDeviceId,
  inviteStreamingStudioToMeeting,
  isEmbeddedInStreamingStudio
} from '../utils/streamingstudio';
import httpClient from './http';

export const getStoreDevices = (
  storeId: string
): Promise<AxiosResponse<IStoreDevice[]>> => {
  return httpClient.get(`/api/stores/devices/${storeId}`);
};

export const getBrandDevices = (
  brandId: string
): Promise<AxiosResponse<IStoreDevice[]>> =>
  httpClient.get(`/api/stores/devicesByBrandId/${brandId}`);

export const sendInvitationToStoreDevice = (
  deviceId: string,
  meetingId: string,
  serverUrl: string,
  hostName: string
): Promise<any> => {
  if (
    isEmbeddedInStreamingStudio() &&
    getStreamingStudioDeviceId() === deviceId
  ) {
    inviteStreamingStudioToMeeting(meetingId, serverUrl, hostName);
    return Promise.resolve();
  }
  return httpClient.post(`/api/stores/connectToDevice`, {
    deviceId,
    meetingId,
    serverUrl,
    hostName
  });
};

export const getStoreConfig = (
  storeId: string
): Promise<AxiosResponse<IVirtualBoutiqueConfig | null>> =>
  httpClient.get(`/api/stores/config/${storeId}`);

export const getStoreById = (
  storeId: string
): Promise<AxiosResponse<IStore | null>> =>
  httpClient.get(`/api/stores/${storeId}`);

interface GetAvailableSlotsPayload {
  date: string;
  storeId: string;
  advisorId?: string;
}

export const getAvailableSlotsByDateAndStore = (
  payload: GetAvailableSlotsPayload
): Promise<AxiosResponse<IConcurrentAvailableSlot[]>> =>
  httpClient.post('/api/getAvailableSlots', payload);

export const getStoreByRackId = (
  rackId: string
): Promise<AxiosResponse<IStore | null>> =>
  httpClient.get(`/api/storeByRack/${rackId}`);

export const getCountryCode = () => httpClient.get(`/api/getCountryCode`);

export const getVirtualImagesByStoreId = (storeId: string) =>
  httpClient.get(`/api/virtualBackgrounds/${storeId}`);
