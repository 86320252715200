import {
  INPUT_DEVICES_STORED,
  VIRTUAL_BACKGROUND_STORED
} from './../../../utils/constants';
import {
  actionDidAddLocalTrack,
  actionLocalTrackAudioMuteDidChange,
  actionLocalTrackVideoMuteDidChange,
  actionNotifyCamError,
  actionNotifyMicError,
  actionToggleVirtualBackground
} from './../../../redux/actions';
import {
  actionChangeInputDeviceAsync,
  actionReplaceLocalTrackAsync
} from './../../../redux/asyncActions';
import { first } from 'lodash';
import { Dispatch } from 'redux';
import {
  IMeeting,
  IMeetingLocalUser,
  JitsiDevice,
  JitsiLocalTrack,
  JitsiMeetingRoom,
  JitsiVideoTrackEffect
} from '../../../interfaces';
import { actionDidAddDevicesList } from '../../../redux/actions';
import JitsiStreamBackgroundEffect from '../VideoBackground/JitsiStreamBackgroundEffect';
import { createVirtualBackgroundEffect } from '../VideoBackground';
import { TFunction } from 'next-i18next';
import { tryGetLocalStorage } from '../../../utils/window';

export const getLocalAudioTrack = (
  tracks: JitsiLocalTrack[]
): JitsiLocalTrack => tracks?.find((t) => t.getType() === 'audio');

export const getLocalVideoTrack = (
  tracks: JitsiLocalTrack[]
): JitsiLocalTrack => tracks?.find((t) => t.getType() === 'video');

export const indexOfAudioLocalTrack = (tracks: JitsiLocalTrack[]): number =>
  tracks.indexOf(getLocalAudioTrack(tracks));

export const indexOfVideoLocalTrack = (tracks: JitsiLocalTrack[]): number =>
  tracks.indexOf(getLocalVideoTrack(tracks));

export const getTrackOptions = (type: 'audio' | 'video', id: string) => {
  return type === 'audio'
    ? { devices: ['audio'], micDeviceId: id }
    : { devices: ['video'], cameraDeviceId: id };
};

export const getTrackIndex = (
  type: 'audio' | 'video',
  tracks: JitsiLocalTrack[]
): number => {
  return type === 'audio'
    ? indexOfAudioLocalTrack(tracks)
    : indexOfVideoLocalTrack(tracks);
};

export enum MEDIA_TYPE {
  AUDIO = 'audio',
  VIDEO = 'video',
  PRESENTER = 'presenter'
}

export const disposeTrackIfNeeded = (track: JitsiLocalTrack) => {
  return track?.dispose().catch((err) => {
    // Track might be already disposed so ignore such an error.
    // and re-throw any other error(s).
    if (err.name !== JitsiMeetJS.errors.track.TRACK_IS_DISPOSED)
      console.log(err);
  });
};

export const handleSetLocalTrack = (
  type: 'audio' | 'video',
  id: string,
  JitsiMeetJS: any,
  trackConstraints: object,
  dispatch: Dispatch<any>,
  trackEffects: JitsiStreamBackgroundEffect[] = []
) => {
  const createLocalTrack = () => {
    const options = getTrackOptions(type, id);
    return JitsiMeetJS.createLocalTracks({
      ...options,
      effects: trackEffects,
      constraints: trackConstraints || defaultVideoConstraints,
      timeout: 10000
    })
      .then((tracks: JitsiLocalTrack[]) => {
        const newTrack = first(tracks);
        if (newTrack) {
          dispatch(actionReplaceLocalTrackAsync(newTrack));
        }
      })
      .catch((err) => {
        console.log('debug:: dispatch error');
        const action =
          type === 'audio' ? actionNotifyMicError : actionNotifyCamError;
        dispatch(action(err));
        throw err;
      });
  };
  return createLocalTrack();
};

const getMicDevices = (devices: JitsiDevice[]) =>
  devices?.filter((d) => d.kind === 'audioinput') || [];

const getDefaultDevice = (devices: JitsiDevice[]) =>
  devices?.find((d) => d.deviceId === 'default') || first(devices);

const micChangeHandler = (
  newMicDevices: JitsiDevice[],
  localUser: IMeetingLocalUser,
  dispatch: Dispatch<any>
) => {
  const newDefaultMic = getDefaultDevice(newMicDevices);
  const oldActiveMic = localUser?.activeDevices?.microphone;
  const oldDefaultMic = getDefaultDevice(localUser?.availableDevices || []);
  const defaultMicHasChange =
    oldDefaultMic && oldDefaultMic?.label !== newDefaultMic?.label;
  const isOldActiveMicDefault = oldActiveMic === 'default';

  const setMic = (micId?: string) => {
    dispatch(actionChangeInputDeviceAsync(micId, 'audio'));
  };

  if (
    newMicDevices?.some((d) => d.deviceId === oldActiveMic) &&
    !isOldActiveMicDefault
  ) {
    return;
  }

  if (isOldActiveMicDefault && defaultMicHasChange && newDefaultMic?.deviceId) {
    setMic(newDefaultMic.deviceId);
    return;
  }
};

export const deviceChangeHandler = (
  devices: JitsiDevice[],
  meeting: IMeeting,
  dispatch: Dispatch
) => {
  const { localUser } = meeting;
  const _devices = devices.filter((d) => !!d.deviceId);
  console.log('debug:: device change handler');
  dispatch(actionDidAddDevicesList(devices));

  //TODO: investigate why device has delay time before it really connected
  setTimeout(() => {
    micChangeHandler(getMicDevices(_devices), localUser, dispatch);
  }, 1000);
};

export const getVideoStreamEffects = (
  effects: JitsiVideoTrackEffect[]
): Promise<JitsiStreamBackgroundEffect[]> => {
  if (!effects?.length) return Promise.resolve([]);
  const mapEffectsToPromises = effects
    .map((effect) => {
      if (!effect?.enabled) return null;
      return createVirtualBackgroundEffect(effect);
    })
    .filter((p) => p);
  return Promise.all(mapEffectsToPromises);
};

export const syncLocalTrack = (room: JitsiMeetingRoom, dispatch) => {
  if (!room) return;
  const roomLocalTracks = room.getLocalTracks();
  return dispatch(actionDidAddLocalTrack(roomLocalTracks));
};

export const transformDefaultDeviceLabel = (
  deviceId: string,
  label: string,
  t: TFunction
) => {
  return deviceId === 'default'
    ? t('same_as_system', { label: label.replace('Default - ', '') })
    : label;
};
export const createLocalTrack = (
  type: string,
  deviceId: string,
  timeout?: number,
  additionalOptions?: any
): JitsiLocalTrack => {
  return JitsiMeetJS.createLocalTracks({
    cameraDeviceId: deviceId,
    devices: [type],
    constraints: defaultVideoConstraints,
    micDeviceId: deviceId,
    timeout,
    ...additionalOptions
  }).then(([jitsiLocalTrack]) => jitsiLocalTrack);
};
export interface IAudioTrack {
  deviceId: string;
  hasError: boolean;
  jitsiTrack: JitsiLocalTrack;
  label: string;
}
export const createLocalAudioTracks = (
  devices: JitsiDevice[],
  timeout = 5000
): Promise<IAudioTrack[]> => {
  return Promise.all(
    devices.map(async ({ deviceId, label }) => {
      let jitsiTrack = null;
      let hasError = false;
      try {
        jitsiTrack = await createLocalTrack('audio', deviceId, timeout);
      } catch (err) {
        hasError = true;
      }
      return {
        deviceId,
        hasError,
        jitsiTrack,
        label
      };
    })
  );
};

export const getInitialDevices = (): {
  cameraDeviceId: string;
  micDeviceId: string;
} => {
  const selectedDevices = tryGetLocalStorage(INPUT_DEVICES_STORED);
  if (!selectedDevices) return null;
  try {
    const { cameraDeviceId, micDeviceId } = JSON.parse(selectedDevices);
    return {
      cameraDeviceId,
      micDeviceId
    };
  } catch (error) {
    console.error('Fail to presist devices, stored data: ', selectedDevices);
    return null;
  }
};

export const getInitialEffects = (
  dispatch: Dispatch
): JitsiVideoTrackEffect => {
  const selectedVB = tryGetLocalStorage(VIRTUAL_BACKGROUND_STORED);
  if (!selectedVB) return undefined;
  try {
    const virtualBackground: JitsiVideoTrackEffect = JSON.parse(selectedVB);
    dispatch(actionToggleVirtualBackground(virtualBackground));
    return virtualBackground;
  } catch (error) {
    console.error('Fail to presist virtual background', error);
    return undefined;
  }
};

export interface ITrackOptions {
  cameraDeviceId?: string | null;
  constraints?: {
    video?: {
      height?: {
        ideal?: number;
        max?: number;
        min?: number;
      };
    };
  };
  desktopSharingSourceDevice?: string;
  desktopSharingSources?: string[];
  devices?: string[];
  facingMode?: string;
  firePermissionPromptIsShownEvent?: boolean;
  micDeviceId?: string | null;
  timeout?: number;
  effects?: JitsiStreamBackgroundEffect[];
}

export function createLocalTracksF(options: ITrackOptions = {}) {
  const { cameraDeviceId, micDeviceId, effects } = options;
  const constraints = options.constraints || defaultVideoConstraints;
  // const ca
  return JitsiMeetJS.createLocalTracks({
    cameraDeviceId: cameraDeviceId || 'default',
    constraints,
    // Copy array to avoid mutations inside library.
    devices: options.devices?.slice(0),
    micDeviceId: micDeviceId || 'default',
    effects
  });
}

interface ICreateInitialLocalTracks {
  initialDevices: string[];
  micDeviceId?: string;
  cameraDeviceId?: string;
  effects?: JitsiStreamBackgroundEffect[];
  constraints: any;
  facingMode?: string;
}

export const defaultVideoConstraints = {
  video: {
    frameRate: {
      max: 60,
      ideal: 30,
      min: 20
    },
    height: {
      ideal: 1080,
      max: 2160,
      min: 360
    }
  }
};

export const createInitialLocalTracks = (
  options: ICreateInitialLocalTracks
) => {
  let tryCreateLocalTracks;
  const errors: any = {};
  console.log('debug:: creating initial tracks, option::', options);
  const {
    initialDevices,
    micDeviceId: micId,
    cameraDeviceId: camId,
    facingMode,
    constraints,
    effects
  } = options;
  const cameraDeviceId = camId || 'default';
  const micDeviceId = micId || 'default';
  const requestAudio = initialDevices.includes('audio');
  const requestVideo = initialDevices.includes('video');
  if (!requestAudio && !requestVideo) {
    tryCreateLocalTracks = Promise.resolve([]);
  } else {
    tryCreateLocalTracks = createLocalTracksF({
      devices: initialDevices,
      micDeviceId,
      cameraDeviceId,
      effects,
      constraints: constraints || defaultVideoConstraints,
      facingMode
    })
      .catch((err) => {
        if (micDeviceId || cameraDeviceId) {
          //try with other devices
          errors.initialDevicesError = err;
          return createLocalTracksF({ devices: initialDevices });
        } else if (requestAudio && requestVideo) {
          //try audio only
          errors.audioAndVideoError = err;
          return createLocalTracksF({ devices: ['audio'], micDeviceId });
        } else if (requestVideo && !requestAudio) {
          errors.videoOnlyError = err;
          return [];
        }
      })
      .catch((err) => {
        if (!requestAudio) {
          console.error('debug:: create audio only failed', err);
        }
        errors.audioOnlyError = err;
        //try video only
        return requestVideo ? createLocalTracksF({ devices: ['video'] }) : [];
      })
      .catch((err) => {
        if (!requestVideo) {
          console.log('debug:: the impossible happened', err);
        }
        errors.videoOnlyError = err;
        return [];
      });
    return {
      tryCreateLocalTracks,
      errors
    };
  }
  return { tryCreateLocalTracks, errors };
};

export const onTrackStop = async (
  track: JitsiLocalTrack,
  meeting: IMeeting,
  dispatch: Dispatch<any>
) => {
  console.log('on track stop', meeting);
  const conference = meeting.jitsi;
  const currentTracks = conference?.getLocalTracks?.() || [];
  const updatedTracks = currentTracks.filter(
    (t) => t.getId() !== track.getId()
  );
  const isUsingTrack = currentTracks.some((t) => t.getId() === track.getId());
  const type = track.getType();
  const actionMute =
    type === 'audio'
      ? actionLocalTrackAudioMuteDidChange
      : actionLocalTrackVideoMuteDidChange;
  if (conference) {
    await conference.replaceTrack(track, null);
  }
  disposeTrackIfNeeded(track);
  if (isUsingTrack) {
    dispatch(actionDidAddLocalTrack(updatedTracks));
    dispatch(actionMute(true));
  }

  return;
};

export const hasAvailableDevices = (
  availableDevices: JitsiDevice[],
  type: 'audioinput' | 'videoinput'
) => {
  const devices = availableDevices || [];
  return devices.some((d) => d.kind === type) || false;
};
