import { videoBaseUrl } from '.';
import { PanoView, RDUHudMenu } from '../interfaces';

export const secretRoomTunnelDesktopVideoUrl =
  'https://panos.inspify.io/virtual_boutique/6578a5b9-0382-4ebc-a4b5-ebeb4a5408a5/tunnel_animation_desktop_v2.mp4';

export const secretRoomTunnelMobileVideoUrl =
  'https://panos.inspify.io/virtual_boutique/6578a5b9-0382-4ebc-a4b5-ebeb4a5408a5/tunnel_animation_mobile_v3.mp4';

export const interiorWallVideoScenes = [
  'scene_03',
  'scene_05',
  'scene_07',
  'scene_09',
  'scene_11'
];

export const expressiveSingularityScenes = [
  'scene_rd_expsing_01',
  'scene_rd_expsing_02',
  'scene_rd_expsing_03',
  'scene_rd_expsing_04',
  'scene_rd_expsing_05',
  'scene_rd_expsing_06',
  'scene_rd_expsing_07',
  'scene_rd_expsing_08',
  'scene_rd_expsing_09',
  'scene_rd_expsing_10',
  'scene_rd_expsing_11',
  'scene_rd_expsing_12',
  'scene_rd_expsing_13',
  'scene_rd_expsing_14',
  'scene_rd_expsing_15'
];

export const adrenalineScenes = [
  'scene_adrenaline01',
  'scene_adrenaline02',
  'scene_adrenaline03',
  'scene_adrenaline04',
  'scene_adrenaline05',
  'scene_adrenaline06',
  'scene_adrenaline07',
  'scene_adrenaline08',
  'scene_adrenaline09',
  'scene_adrenaline10',
  'scene_adrenaline11',
  'scene_adrenaline12',
  'scene_adrenaline13',
  'scene_adrenaline14',
  'scene_adrenaline15',
  'scene_adrenaline16',
  'scene_adrenaline17'
];

export const manufactureLevel1Scenes = [
  'scene_pano_looby',
  'scene_pano_5axis_milling',
  'scene_pano_electro-erosion',
  'scene_anglage',
  'scene_in_between',
  'scene_ettirage',
  'scene_perlissage',
  'scene_laserengraving'
];
export const manufactureLevel2Scenes = ['scene_horology_assembly'];
export const manufactureLevel3Scenes = [
  'scene_qlab_01',
  'scene_qlab_02',
  'scene_qlab_03'
];

export const manufactureScenes = [
  ...manufactureLevel1Scenes,
  ...manufactureLevel2Scenes,
  ...manufactureLevel3Scenes
];

export interface SceneForMenu {
  scene: string;
  displayName?: string;
  view?: PanoView;
}
export const sceneByMenu: { [key: string]: SceneForMenu } = {
  [RDUHudMenu.LOBBY]: {
    scene: 'scene_pano_looby',
    displayName: 'lobby'
  },
  [RDUHudMenu.FIVE_AXIS_MILLING]: {
    scene: 'scene_pano_5axis_milling',
    displayName: '5 axis milling'
  },
  [RDUHudMenu.ELECTRO_EROSION]: {
    scene: 'scene_pano_electro-erosion',
    displayName: 'electro erosion'
  },
  [RDUHudMenu.WHEELS_POLISHING_WORKSHOPS]: {
    scene: 'scene_anglage',
    displayName: 'wheels polishing workshops',
    view: {
      viewH: 145.69774374673437
    }
  },
  [RDUHudMenu.POLISH_OF_THE_EDGES]: {
    scene: 'scene_anglage',
    displayName: 'polish of the edges',
    view: {
      viewH: 181.65
    }
  },
  [RDUHudMenu.POLISH_OF_THE_WINGS]: {
    scene: 'scene_anglage',
    displayName: 'polish of the edges',
    view: {
      fov: 113.82163572889942,
      viewH: 110.75015107108119,
      viewV: 0.20486143890380867
    }
  },
  [RDUHudMenu.DIMENSION_CONTROLLING]: {
    scene: 'scene_anglage',
    displayName: 'polish of the edges',
    view: {
      viewH: 27.72
    }
  },
  [RDUHudMenu.CERCLAGE]: {
    scene: 'scene_anglage',
    displayName: 'polish of the edges',
    view: {
      viewH: -70.45
    }
  },
  [RDUHudMenu.PERLAGE]: { scene: 'scene_perlissage', displayName: 'perlage' },
  [RDUHudMenu.FLAT_MIRROR_POLISH_AND_DETOURING]: {
    scene: 'scene_ettirage',
    displayName: 'detouring & flat mirror polish',
    view: {
      viewH: -41.946084633785325
    }
  },
  [RDUHudMenu.FLAT_MIRROR_POLISH]: {
    scene: 'scene_ettirage',
    displayName: 'flat mirror polish',
    view: {
      viewH: -97.6
    }
  },
  [RDUHudMenu.DETOURING]: {
    scene: 'scene_ettirage',
    displayName: 'detouring',
    view: {
      viewH: 10.191
    }
  },
  [RDUHudMenu.GARNISH_WORKSHOPS]: {
    scene: 'scene_laserengraving',
    displayName: 'garnish workshops',
    view: {
      viewH: 3242.135
    }
  },
  [RDUHudMenu.GARNISH_WORKSHOPS_OPENING]: {
    scene: 'scene_laserengraving',
    displayName: 'garnish workshops',
    view: {
      viewH: 3083.72,
      fov:120
    }
  },
  [RDUHudMenu.ETIRAGE]: {
    scene: 'scene_in_between',
    displayName: 'etirage',
    view: {
      viewH: 2972.913,
      viewV: 3.724
    }
  },
  [RDUHudMenu.WATCH_MAKING_WORKSHOPS]: {
    scene: 'scene_horology_assembly',
    displayName: 'watch making workshops'
  },
  [RDUHudMenu.QLAB]: { scene: 'scene_qlab_01', displayName: 'qlab' },
  [RDUHudMenu.EXIT]: {
    scene: 'scene_12',
    displayName: 'home',
    view: {
      viewH: 178.62390595313292,
      viewV: 34.315106682583185
    }
  },
  [RDUHudMenu.PIRELLI_RED]: {
    scene: 'scene_adrenaline09',
    displayName: 'red',
    view: {
      viewH: 142.02207974888844,
      viewV: 17.26338641705462,
      fov: 82.52715037477867
    }
  },
  [RDUHudMenu.PIRELLI_WHITE]: {
    scene: 'scene_adrenaline09',
    displayName: 'white',
    view: {
      viewH: 142.02207974888844,
      viewV: 17.26338641705462,
      fov: 82.52715037477867
    }
  },
  [RDUHudMenu.PIRELLI_YELLOW]: {
    scene: 'scene_adrenaline09',
    displayName: 'yellow',
    view: {
      viewH: 142.02207974888844,
      viewV: 17.26338641705462,
      fov: 82.52715037477867
    }
  }
};

export const pirelliScenes = ['scene_adrenaline09'];

export const pirelliScenesRange = {
  scene_adrenaline09: {
    start: 112.08521276893579,
    end: 169.78018852158337
  }
};

export const secretRoomFlipScenePairs = {
  scene_rd_expsing_01: 'scene_adrenaline02',
  scene_adrenaline02: 'scene_rd_expsing_01',
  scene_rd_expsing_04: 'scene_adrenaline04',
  scene_adrenaline04: 'scene_rd_expsing_04',
  scene_rd_expsing_02: 'scene_adrenaline03',
  scene_adrenaline03: 'scene_rd_expsing_02',
  scene_rd_expsing_03: 'scene_adrenaline01',
  scene_adrenaline01: 'scene_rd_expsing_03',
  scene_rd_expsing_06: 'scene_adrenaline05',
  scene_adrenaline05: 'scene_rd_expsing_06',
  scene_rd_expsing_09: 'scene_adrenaline06',
  scene_adrenaline06: 'scene_rd_expsing_09',
  scene_rd_expsing_10: 'scene_adrenaline07',
  scene_adrenaline07: 'scene_rd_expsing_10',
  scene_rd_expsing_11: 'scene_adrenaline08',
  scene_adrenaline08: 'scene_rd_expsing_11',
  scene_rd_expsing_05: 'scene_adrenaline09',
  scene_adrenaline09: 'scene_rd_expsing_05',
  scene_rd_expsing_07: 'scene_adrenaline10',
  scene_adrenaline10: 'scene_rd_expsing_07',
  scene_rd_expsing_08: 'scene_adrenaline11',
  scene_adrenaline11: 'scene_rd_expsing_08',
  scene_rd_expsing_12: 'scene_adrenaline12',
  scene_adrenaline12: 'scene_rd_expsing_12',
  scene_rd_expsing_13: 'scene_adrenaline13',
  scene_adrenaline13: 'scene_rd_expsing_13',
  scene_adrenaline14: 'scene_rd_expsing_13',
  scene_rd_expsing_14: 'scene_adrenaline15',
  scene_adrenaline15: 'scene_rd_expsing_14',
  scene_rd_expsing_15: 'scene_adrenaline16',
  scene_adrenaline16: 'scene_rd_expsing_15',
  scene_adrenaline17: 'scene_rd_expsing_15'
};

export const welcomeToScene = ['scene_pano_looby'];

export const whitePirelliProductId = '01aad47d-4f0b-44fe-9d6c-0f8010b75c33';
export const whitePirelliFrameUrl =
  'https://products.inspify.io/4bba131d-6238-4c54-b4e4-506bc199d13d/01aad47d-4f0b-44fe-9d6c-0f8010b75c33/virtual_showroom/01aad47d-4f0b-44fe-9d6c-0f8010b75c33_bottom_frame.png';
export const yellowPirelliProductId = '98de9a70-4240-4b56-abb4-d7b36725a08b';
export const yellowPirelliFrameUrl =
  'https://products.inspify.io/4bba131d-6238-4c54-b4e4-506bc199d13d/98de9a70-4240-4b56-abb4-d7b36725a08b/virtual_showroom/98de9a70-4240-4b56-abb4-d7b36725a08b_bottom_frame.png';
export const redPirelliProductId = '93b5c19f-5ba7-4708-bda4-ed040197761e';
export const redPirelliFrameUrl =
  'https://products.inspify.io/4bba131d-6238-4c54-b4e4-506bc199d13d/93b5c19f-5ba7-4708-bda4-ed040197761e/virtual_showroom/93b5c19f-5ba7-4708-bda4-ed040197761e_bottom_frame.png';

export const rduHudMenuToMirrorInRemoteSelling = [
  RDUHudMenu.PIRELLI_RED,
  RDUHudMenu.PIRELLI_WHITE,
  RDUHudMenu.PIRELLI_YELLOW
];

const avatarVideoPathBase = `${videoBaseUrl}/content/converted/67efead6-a996-49db-9c41-d3da8d4469f5`;

export const avatarVideoList: {
  url: string;
  scene: string;
  next?: RDUHudMenu;
  view?: PanoView;
  end?: boolean;
}[] = [
  {
    url: `${avatarVideoPathBase}/lobby_welcome_(part_1).mp4/index.m3u8`,
    scene: 'scene_pano_looby'
  },
  {
    url: `${avatarVideoPathBase}/roger_dubuis.mp4/index.m3u8`,
    scene: 'scene_pano_looby',
    next: RDUHudMenu.FIVE_AXIS_MILLING,
    view: { fov: 108.39400963989132, viewH: 2447.304850685775, viewV: -1.25 }
  },
  {
    url: `${avatarVideoPathBase}/5axismilling.mp4/index.m3u8`,
    scene: 'scene_pano_5axis_milling',
    next: RDUHudMenu.ELECTRO_EROSION
  },
  {
    url: `${avatarVideoPathBase}/electroerosion.mp4/index.m3u8`,
    scene: 'scene_pano_electro-erosion'
  },
  {
    url: `${avatarVideoPathBase}/industrialization_workshop.mp4/index.m3u8`,
    scene: 'scene_pano_electro-erosion',
    next: RDUHudMenu.WHEELS_POLISHING_WORKSHOPS,
    view: {
      fov: 100.05238286542416,
      viewH: 57.8427060751851,
      viewV: 6.532188549985247
    }
  },

  {
    url: `${avatarVideoPathBase}/polishing_workshop.mp4/index.m3u8`,
    scene: 'scene_anglage'
  },

  {
    url: `${avatarVideoPathBase}/polish_of_the_wings.mp4/index.m3u8`,
    scene: 'scene_anglage',
    view: sceneByMenu[RDUHudMenu.POLISH_OF_THE_WINGS].view
  },

  {
    url: `${avatarVideoPathBase}/polish_of_the_edges.mp4/index.m3u8`,
    scene: 'scene_anglage',
    view: sceneByMenu[RDUHudMenu.POLISH_OF_THE_EDGES].view
  },

  {
    url: `${avatarVideoPathBase}/dimension_controlling.mp4/index.m3u8`,
    scene: 'scene_anglage',
    view: sceneByMenu[RDUHudMenu.DIMENSION_CONTROLLING].view
  },

  {
    url: `${avatarVideoPathBase}/cerclage.mp4/index.m3u8`,
    scene: 'scene_anglage',
    view: sceneByMenu[RDUHudMenu.CERCLAGE].view,
    next: RDUHudMenu.PERLAGE
  },

  // {
  //   url: `${avatarVideoPathBase}/quality_check.mp4/index.m3u8`,
  //   scene: 'scene_anglage',

  // },

  {
    url: `${avatarVideoPathBase}/perlage.mp4/index.m3u8`,
    scene: 'scene_perlissage',
    next: RDUHudMenu.DETOURING
  },

  {
    url: `${avatarVideoPathBase}/detouring.mp4/index.m3u8`,
    scene: 'scene_ettirage',
    view: sceneByMenu[RDUHudMenu.DETOURING].view
  },
  {
    url: `${avatarVideoPathBase}/mirror_polishing.mp4/index.m3u8`,
    scene: 'scene_ettirage',
    view: sceneByMenu[RDUHudMenu.FLAT_MIRROR_POLISH].view,
    next: RDUHudMenu.GARNISH_WORKSHOPS_OPENING
  },
  {
    url: `${avatarVideoPathBase}/garnish_workshop.mp4/index.m3u8`,
    scene: 'scene_laserengraving'
  },
  {
    url: `${avatarVideoPathBase}/laser_engraving.mp4/index.m3u8`,
    scene: 'scene_laserengraving',
    view: sceneByMenu[RDUHudMenu.GARNISH_WORKSHOPS].view,
    next: RDUHudMenu.WATCH_MAKING_WORKSHOPS
  },
  {
    url: `${avatarVideoPathBase}/watch_making_workshop.mp4/index.m3u8`,
    scene: 'scene_horology_assembly'
  },
  {
    url: `${avatarVideoPathBase}/test_and_conclusion.mp4/index.m3u8`,
    scene: 'scene_horology_assembly',
    view: { fov: 136.605, viewH: 3596.031, viewV: 5.062 },
    next: RDUHudMenu.LOBBY,
    end: true
  }
];
