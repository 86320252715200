import { orderBy } from 'lodash';
import { ILibraryVersion } from '../../interfaces';
import {
  checkForMissingPolotnoPage,
  cloneDefaultToNewLanguage,
  generateBlankPolotnoPage,
  getPolotnoJson,
  getRenderedSceneLayer,
  IPolotnoJSON,
  IPolotnoPage,
  LANGUAGE_VARIATIONS,
  POLOTNO_PAGE_HEIGHT,
  POLOTNO_PAGE_WIDTH,
  TLanguage
} from '../../mappers/polotno';
import { isVideo } from '../../utils/file';
import { generateV4UUID } from '../../utils/identityGenerator';
import { extractPageId, getPolotnoStore } from '../../utils/window';
import { TDeviceView } from '../components/Common/DeviceViewToggle';
import {
  ILibraryScene,
  IStorybookPage,
  IStorybookSettings
} from './../../interfaces/index';
import {
  appendLayerIntoSetting,
  mapStorybookSettingsStringToObject
} from './../../mappers/storybook';
import { getPolotnoJsonInStorybookPage, getStatusLabel } from './storybook';

export const getSceneVersionForDropdown = (
  versions: ILibraryVersion[] | undefined
) => {
  if (!versions || !versions.length) return [];

  const ordered = orderBy(versions, ['version'], ['desc']) || [];
  const latestVersionStatus = ordered[0].status;
  const hasManyVersions = ordered.length > 1;

  const mapped = (ordered || [])?.map((v) => ({
    value: v?.version,
    label: `${v?.version}`
  }));

  mapped[0].label = `${ordered[0].version} - ${getStatusLabel(
    latestVersionStatus
  )}`;

  if (hasManyVersions && latestVersionStatus === 'draft') {
    mapped[1].label = `${ordered[1].version} - ${getStatusLabel(
      ordered[1].status
    )}`;
  }

  return mapped;
};

export const hasActiveVersion = (versions: ILibraryVersion[]) => {
  return versions?.some((v) => v.status === 'activated');
};

export const generateScenePreview = ({
  scene,
  onSuccess,
  onLoading,
  onFailed
}: {
  scene: ILibraryScene;
  onSuccess: (scene: ILibraryScene) => void;
  onLoading: (loading: boolean) => void;
  onFailed: (message: string) => void;
}) => {
  if (scene?.status === 'activated') {
    onLoading(false);
    onSuccess(scene);
    return;
  }

  onLoading(true);

  const store = getPolotnoStore();

  const missingPage = checkForMissingPolotnoPage(store?.toJSON());

  const hasLandscapePage = !(
    missingPage === 'desktop' || missingPage === 'compare'
  );
  if (!hasLandscapePage) {
    onLoading(false);
    onFailed('Landscape page cannot be blank. Please add some content to it.');
    return;
  }
  getRenderedSceneLayer(store)
    .then((layer) => {
      onLoading(false);
      const previewScene = {
        ...scene,
        content: {
          ...scene.content,
          settings: appendLayerIntoSetting(scene.content.settings, layer)
        }
      };
      onSuccess(previewScene);
    })
    .catch(() => {
      onLoading(false);
      onFailed('Error while generating preview, please try again later.');
    });
};

export const getPolotnoJsonInScene = (
  scene: ILibraryScene,
  fakeId?: string
): IPolotnoJSON => {
  if (scene?.content?.overlay) return JSON.parse(scene.content.overlay);
  if (scene?.editor) return scene.editor;
  return {
    width: POLOTNO_PAGE_WIDTH,
    height: POLOTNO_PAGE_HEIGHT,
    fonts: [],
    pages: [
      {
        ...generateBlankPolotnoPage(scene?.id || fakeId),
        children:
          scene?.content?.url && !isVideo(scene?.content?.url)
            ? [
                {
                  id: generateV4UUID(),
                  src: scene.content.url,
                  type: 'image',
                  visible: true,
                  width: POLOTNO_PAGE_WIDTH,
                  height: POLOTNO_PAGE_HEIGHT,
                  x: 0,
                  y: 0
                }
              ]
            : []
      }
    ]
  };
};

export const getCustomFontsInScene = (scene: ILibraryScene) => {
  if (scene?.content?.settings) {
    const settings = JSON.parse(scene.content.settings);
    return settings.customFont;
  }
  return [];
};

export const mapCurrentJSONWithStorePages = (currentJSON) => {
  const store: IPolotnoJSON = getPolotnoJson().obj;
  const currentJSONPages = currentJSON?.pages;
  const edittingPages = store?.pages || [];

  for (const el of edittingPages) {
    const indexInA = currentJSONPages.findIndex(
      (aElement) => aElement.id === el.id
    );
    if (indexInA !== -1) {
      currentJSONPages[indexInA] = el;
    } else {
      currentJSONPages.push(el);
    }
  }
  return {
    ...currentJSON,
    pages: currentJSONPages
  };
};

export const mapCurrentSceneWithStorePages = (
  currentScene: ILibraryScene,
  fresh?: boolean
) => {
  const store: IPolotnoJSON = getPolotnoJson().obj;
  const currentScenePages = getPolotnoJsonInScene(currentScene)?.pages;
  const editingPages = store?.pages || [];
  let currentSetting = {
    customFont: []
  };
  try {
    currentSetting = JSON.parse(currentScene?.content?.settings);
  } catch (error) {
    currentSetting = {
      customFont: []
    };
  }
  const customFont = currentSetting?.customFont || [];
  const storeFont = store?.fonts || [];
  for (const el of editingPages) {
    const indexInA = currentScenePages.findIndex(
      (aElement) => aElement.id === el.id
    );
    if (indexInA !== -1) {
      currentScenePages[indexInA] = { ...el };
    } else {
      if (!currentScene?.isTemplate) {
      currentScenePages.push(el);
      }
    }
  }
  //console.log('Current Scene Pages:', currentScenePages.map(page => ({ id: page.id, language: extractPageId(page.id).language })));
  return {
    ...currentScene,
    content: {
      ...currentScene.content,
      overlay: JSON.stringify({
        ...JSON.parse(currentScene?.content?.overlay || '{}'),
        pages: currentScenePages
      }),
      settings: JSON.stringify({
        ...currentSetting,
        customFont: [...customFont, ...storeFont]
      })
    },
    status: fresh ? 'draft' : currentScene.status
  };
};

export const mapCurrentSceneWithNewLanguagePages = async (
  currentScene: ILibraryScene,
  language: TLanguage,
  fresh?: boolean
) => {
  const store = getPolotnoStore();
  const defaultPageLandscape: IPolotnoPage = store.toJSON().pages[0];
  const defaultPagePortrait: IPolotnoPage =
    store.toJSON().pages[1] || generateBlankPolotnoPage(generateV4UUID(), true);
  const newLandscape = await cloneDefaultToNewLanguage(
    currentScene?.id,
    defaultPageLandscape,
    language
  );
  const newPortrait = await cloneDefaultToNewLanguage(
    currentScene?.id,
    defaultPagePortrait,
    language,
    true
  );

  const currentScenePages = getPolotnoJsonInScene(currentScene)?.pages || [];
  if (!currentScenePages.length) {
    currentScenePages.push(defaultPageLandscape, defaultPagePortrait);
  }
  currentScenePages.push(newLandscape, newPortrait);

  // Update the scene's language
  const updatedSettings = JSON.parse(currentScene.content.settings || '{}');
  updatedSettings.language = language;

  console.log('Updating scene with new language:', language);
  console.log(
    'Updated Scene Pages:',
    currentScenePages.map((page) => ({
      id: page.id,
      language: extractPageId(page.id).language
    }))
  );

  return {
    ...currentScene,
    content: {
      ...currentScene.content,
      overlay: JSON.stringify({
        ...JSON.parse(currentScene.content.overlay || '{}'),
        pages: currentScenePages
      }),
      settings: JSON.stringify(updatedSettings)
    },
    status: fresh ? 'draft' : currentScene.status,
    currentLanguage: language
  };
};

export const mapCurrentJSONWithNewLanguagePages = (
  currentJSON,
  language: TLanguage
) => {
  const store: IPolotnoJSON = getPolotnoJson().obj;
  const defaultPageLandscape: IPolotnoPage = store.pages[0];
  const defaultPagePortrait: IPolotnoPage =
    store.pages[1] || generateBlankPolotnoPage(generateV4UUID(), true);
  const newLandscape = cloneDefaultToNewLanguage(
    currentJSON?.id,
    defaultPageLandscape,
    language
  );
  const newPortrait = cloneDefaultToNewLanguage(
    currentJSON?.id,
    defaultPagePortrait,
    language,
    true
  );

  const currentJSONPages = currentJSON?.pages;
  if (!currentJSONPages.length) {
    currentJSONPages.push(defaultPageLandscape, defaultPagePortrait);
  }
  currentJSONPages.push(newLandscape, newPortrait);

  console.log('currentJSONPages', currentJSONPages);

  return {
    ...currentJSON,
    pages: currentJSONPages
  };
};

export const removeLanguagePageInScene = (
  currentScene: ILibraryScene,
  language: TLanguage,
  fresh?: boolean
) => {
  const store: IPolotnoJSON = getPolotnoJson().obj;
  const currentScenePages = getPolotnoJsonInScene(currentScene)?.pages;
  const ids = currentScenePages
    ?.filter((p) => p.id.includes(`-${language}`))
    .map((p) => p.id);

  ids.map((id) => {
    const index = currentScenePages.findIndex((p) => p.id === id);
    if (index !== -1) currentScenePages.splice(index, 1);
  });

  return {
    ...currentScene,
    content: {
      ...currentScene.content,
      overlay: JSON.stringify({
        ...store,
        pages: currentScenePages
      })
    },
    status: fresh ? 'draft' : currentScene.status
  };
};

export const getAvailableLanguageInScene = (
  scene: ILibraryScene
): TLanguage[] => {
  const json = getPolotnoJsonInScene(scene);
  const pages = json.pages;
  const languages: TLanguage[] = [];
  pages
    .filter((page) => !page.id.includes('-portrait'))
    .map((l_page) => {
      const { language } = extractPageId(l_page.id);
      if (LANGUAGE_VARIATIONS.includes(language as TLanguage)) {
        languages.push(language as TLanguage);
      }
    });
  const uniqueLanguages: TLanguage[] = [];

  for (const lang of languages) {
    if (!uniqueLanguages.includes(lang)) {
      uniqueLanguages.push(lang);
    }
  }
  return uniqueLanguages;
};

export const getAvailableLanguageInPages = (pages): TLanguage[] => {
  const languages: TLanguage[] = [];
  pages
    .filter((page) => !page.id.includes('-portrait'))
    .map((l_page) => {
      const { language } = extractPageId(l_page.id);
      if (LANGUAGE_VARIATIONS.includes(language as TLanguage)) {
        languages.push(language as TLanguage);
      }
    });
  return languages;
};

export const getAvailableLanguageInSBPage = (
  SBPage: IStorybookPage
): TLanguage[] => {
  if (!SBPage) return ['en'];
  const json = getPolotnoJsonInStorybookPage(SBPage);
  const pages = json.pages;
  const languages: TLanguage[] = [];
  pages
    .filter((page) => !page.id.includes('-portrait'))
    .map((l_page) => {
      const language = extractPageId(l_page.id)?.language as TLanguage;
      if (LANGUAGE_VARIATIONS.includes(language)) {
        languages.push(language);
      }
    });
  return languages;
};

export const updateSceneLanguageInSBSettings = (
  setting: IStorybookSettings,
  language: TLanguage
): IStorybookSettings => {
  setting.language = language;
  return setting;
};

export const sceneHasPolotnoJson = (scene: ILibraryScene): boolean =>
  !!(scene.content.overlay || scene.editor);

export const checkForMissingScenePage = (scene: ILibraryScene): TDeviceView => {
  if (!scene) return 'compare';

  const hasNoLandscape = !scene.content.url;
  const hasNoPortrait = !mapStorybookSettingsStringToObject(
    scene.content.settings
  ).portraitUrl;

  if (hasNoLandscape && hasNoPortrait) {
    return 'compare';
  }
  if (hasNoLandscape) {
    return 'desktop';
  }
  if (hasNoPortrait) {
    return 'mobile';
  }
};

export const getSceneContentType = (scene: ILibraryScene) => {
  if (!scene) return 'layer';

  const settings = mapStorybookSettingsStringToObject(scene.content.settings);

  if (isVideo(scene.content.url) || settings.type === 'video') return 'video';

  if (settings.type === 'image') return 'image';

  return 'layer';
};
