import * as Sentry from '@sentry/node';
import { AnyAction, Dispatch, Store } from 'redux';
import { saveChatToLocalStorage } from '../components/Meeting/Chat/chatUtils/chatStorage';

import {
  handleEndpointMessage,
  sendCommandForAction
} from '../components/Meeting/commands';
import { getEndpointMessageFromAction } from '../components/Meeting/Messaging/Presenter/PresenterCommandSender';
import { deviceChangeHandler } from '../components/Meeting/utils/track';
import {
  IMainState,
  JitsiLocalTrack,
  JitsiMeetingRoom,
  MeetingLayoutMode,
  MeetingRole
} from '../interfaces';
import {
  INPUT_DEVICES_STORED,
  IS_IN_MEETING,
  MEETING_ROLE,
  OUTPUT_DEVICES_STORED,
  VIRTUAL_BACKGROUND_STORED
} from '../utils/constants';
import {
  isEmbeddedInStreamingStudio,
  sendVoyageControlMessage
} from '../utils/streamingstudio';
import { isOnClientSide, trySetLocalStorage } from '../utils/window';
import {
  actionSetAudioInput,
  actionSetVideoInput,
  actionShowWarningNotification,
  actionToggleEnlargeVideo,
  ADD_DEVICES_LIST,
  BRIDGE_CHANNEL_CLOSED,
  DELETE_CHAT,
  DID_ADD_LOCAL_TRACK,
  DID_CLICK_ON_PREVENT_INTERACTION_VEIL,
  DID_JOIN_MEETING,
  DID_LEAVE_MEETING,
  DID_RECEIVE_ADVISOR_INFO,
  DID_RECEIVE_ENDPOINT_MESSAGE,
  DID_RECEIVE_PARTICIPANT_USER_INFO,
  JOIN_AGAIN_AS_CLIENT,
  MUTE_PARTICIPANT_AUDIO_TRACK,
  NOTIFY_CAM_ERROR,
  NOTIFY_MIC_ERROR,
  SEND_CHAT,
  SET_ACTIVE_PAGE,
  SET_AUDIO_INPUT,
  SET_AUDIO_OUTPUT,
  SET_VIDEO_INPUT,
  TOGGLE_VIRTUAL_BACKGROUND_EFFECT,
  VB_OPEN_POPUP
} from './actions';

const preserveMeetingStateForPopup = (payload: {
  participantId: string;
  role: MeetingRole;
}) => {
  window[IS_IN_MEETING] = true;
  window[MEETING_ROLE] = payload.role;
};

const clearMeetingStateForPopup = () => {
  window[IS_IN_MEETING] = false;
  window[MEETING_ROLE] = undefined;
};

const joinAgainAsClient = () => {
  if (location.search.includes('role=advisor')) {
    const clientMeetingUrl = location.href.replace(
      'role=advisor',
      'role=client'
    );
    window.location.assign(clientMeetingUrl);
  }
};

const autoEnlargeStudioIfNeeded = (
  action: AnyAction,
  state: IMainState,
  dispatch: Dispatch
) => {
  const { participantId, role } = action.payload || {};
  if (
    role === MeetingRole.STUDIO &&
    state.clientState.meeting?.localUser?.role === MeetingRole.ADVISOR &&
    state.clientState.meeting?.layout?.mode ===
      MeetingLayoutMode.PRESENTATION &&
    !state.clientState.meeting?.layout?.enlargedVideoParticipantId
  ) {
    dispatch(
      actionToggleEnlargeVideo({
        participantId: participantId,
        shouldEnlarge: true
      })
    );
  }
};

const transferJitsiOwnershipIfNeeded = (advisorParticipantId: string) => {
  const room: JitsiMeetingRoom = isOnClientSide() && window['room'];
  if (room?.isModerator()) {
    room?.grantOwner(advisorParticipantId);
  }
};

const muteParticipantViaJitsiApi = (
  participantId: string,
  allRemoteParticipantIds: string[]
) => {
  const room: JitsiMeetingRoom = isOnClientSide() && window['room'];
  if (room?.isModerator()) {
    if (participantId === 'all') {
      allRemoteParticipantIds.forEach((id) => room?.muteParticipant(id));
    } else {
      room?.muteParticipant(participantId);
    }
  }
};

const handlePreventInteractionVeilClick = (action: AnyAction) => {
  const iframe = document.getElementById('content-popup') as HTMLIFrameElement;
  iframe?.contentWindow?.postMessage(
    action,
    `${location.protocol}//${location.host}`
  );
};

const persistenceRegister = (storedName, payload) => {
  if (isOnClientSide()) {
    trySetLocalStorage(storedName, payload);
  }
};
const getJitsiTrackErrorMessageKey = (type: 'mic' | 'cam', error = '') => {
  const JitsiTrackErrors = JitsiMeetJS.errors.track;
  const keymap = {
    mic: {
      [JitsiTrackErrors.CONSTRAINT_FAILED]: 'dialog.micConstraintFailedError',
      [JitsiTrackErrors.GENERAL]: 'dialog.micUnknownError',
      [JitsiTrackErrors.NOT_FOUND]: 'dialog.micNotFoundError',
      [JitsiTrackErrors.PERMISSION_DENIED]: 'dialog.micPermissionDeniedError',
      [JitsiTrackErrors.TIMEOUT]: 'dialog.micTimeoutError'
    },
    cam: {
      [JitsiTrackErrors.CONSTRAINT_FAILED]:
        'dialog.cameraConstraintFailedError',
      [JitsiTrackErrors.GENERAL]: 'dialog.cameraUnknownError',
      [JitsiTrackErrors.NOT_FOUND]: 'dialog.cameraNotFoundError',
      [JitsiTrackErrors.PERMISSION_DENIED]:
        'dialog.cameraPermissionDeniedError',
      [JitsiTrackErrors.UNSUPPORTED_RESOLUTION]:
        'dialog.cameraUnsupportedResolutionError',
      [JitsiTrackErrors.TIMEOUT]: 'dialog.cameraTimeoutError'
    }
  };
  return keymap[type][error] || keymap[type][JitsiTrackErrors.GENERAL];
};

const getJitsiTrackErrorKey = (type: 'mic' | 'cam', errorName: string) => {
  const JitsiTrackErrors = JitsiMeetJS.errors.track;
  const device = type === 'mic' ? 'microphone' : 'camera';
  return errorName === JitsiTrackErrors.PERMISSION_DENIED
    ? `deviceError.${device}Permission`
    : `deviceError.${device}Error`;
};

const minimizeParticipantIfNeeded = (state: IMainState, dispatch: Dispatch) => {
  const layout = state.clientState?.meeting?.layout || {};
  if (layout.enlargedVideoParticipantId) {
    dispatch(
      actionToggleEnlargeVideo({
        participantId: layout.enlargedVideoParticipantId,
        shouldEnlarge: false
      })
    );
  }
};

const meetingMiddleware =
  (store: Store<IMainState>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const result = next(action);
    const state = store.getState();
    const dispatch = store.dispatch;
    const meeting = state.clientState.meeting;

    try {
      if (meeting?.bridgeChannelOpened) {
        sendCommandForAction(state.clientState, action, dispatch);
      }
      if (isEmbeddedInStreamingStudio()) {
        sendVoyageControlMessage(
          getEndpointMessageFromAction(action, undefined, state.clientState)
        );
      }
      switch (action.type) {
        case DID_CLICK_ON_PREVENT_INTERACTION_VEIL:
          handlePreventInteractionVeilClick(action);
          break;
        case DID_RECEIVE_ENDPOINT_MESSAGE:
          handleEndpointMessage(action.payload, dispatch, state.clientState);
          break;
        case DID_JOIN_MEETING:
          preserveMeetingStateForPopup(action.payload);
          break;
        case DID_LEAVE_MEETING:
          clearMeetingStateForPopup();
          break;
        case JOIN_AGAIN_AS_CLIENT:
          joinAgainAsClient();
          break;
        case VB_OPEN_POPUP:
        case SET_ACTIVE_PAGE: {
          minimizeParticipantIfNeeded(state, dispatch);
          break;
        }

        case DID_RECEIVE_PARTICIPANT_USER_INFO:
          autoEnlargeStudioIfNeeded(action, state, dispatch);
          break;
        case DID_RECEIVE_ADVISOR_INFO:
          transferJitsiOwnershipIfNeeded(action.payload.participantId);
          break;
        case MUTE_PARTICIPANT_AUDIO_TRACK:
          muteParticipantViaJitsiApi(
            action.payload.participantId,
            Object.keys(meeting?.remoteUsers || {})
          );
          break;
        case ADD_DEVICES_LIST:
          deviceChangeHandler(action.payload, meeting, dispatch);
          break;
        case DID_ADD_LOCAL_TRACK: {
          const tracks: JitsiLocalTrack[] = action.payload || [];
          tracks.forEach((track) => {
            if (track.getType() === 'audio') {
              dispatch(actionSetAudioInput(track.getDeviceId()));
            } else {
              dispatch(actionSetVideoInput(track.getDeviceId()));
            }
          });
          break;
        }
        case SEND_CHAT:
          saveChatToLocalStorage(meeting?.chat || [], meeting?.meetingId);
          break;
        case DELETE_CHAT:
          saveChatToLocalStorage(
            (meeting?.chat || []).filter((msg) => msg.id !== action.payload),
            meeting?.meetingId
          );
          break;
        case TOGGLE_VIRTUAL_BACKGROUND_EFFECT: {
          const presistedData = JSON.stringify(action.payload);
          persistenceRegister(VIRTUAL_BACKGROUND_STORED, presistedData);
          break;
        }
        case SET_VIDEO_INPUT: {
          const presistedData = {
            cameraDeviceId: action.payload,
            micDeviceId: meeting?.localUser?.activeDevices?.microphone
          };
          persistenceRegister(
            INPUT_DEVICES_STORED,
            JSON.stringify(presistedData)
          );
          break;
        }
        case SET_AUDIO_INPUT: {
          const presistedData = {
            cameraDeviceId: meeting?.localUser?.activeDevices?.camera,
            micDeviceId: action.payload
          };
          persistenceRegister(
            INPUT_DEVICES_STORED,
            JSON.stringify(presistedData)
          );
          break;
        }
        case SET_AUDIO_OUTPUT: {
          persistenceRegister(
            OUTPUT_DEVICES_STORED,
            action.payload
          )
          break;
        }

        case BRIDGE_CHANNEL_CLOSED: {
          const titleKey = 'dialog.bridgeChannelClosedError';
          const descriptionKey = 'dialog.bridgeChannelError';
          dispatch(
            actionShowWarningNotification({
              description: null,
              titleKey,
              descriptionKey,
              id: Date.now().toString()
            })
          );
          break;
        }
        case NOTIFY_CAM_ERROR: {
          if (!action.payload) {
            break;
          }
          const { message, name } = action.payload;

          const camJitsiTrackErrorMsg = getJitsiTrackErrorMessageKey(
            'cam',
            name
          );
          const camErrorMsg =
            camJitsiTrackErrorMsg || getJitsiTrackErrorMessageKey('cam');
          const additionalCamErrorMsg = camJitsiTrackErrorMsg ? null : message;
          const titleKey = getJitsiTrackErrorKey('cam', name);
          dispatch(
            actionShowWarningNotification({
              description: additionalCamErrorMsg,
              descriptionKey: camErrorMsg,
              titleKey,
              id: Date.now().toString()
            })
          );
          break;
        }
        case NOTIFY_MIC_ERROR: {
          if (!action.payload) {
            break;
          }
          const { message, name } = action.payload;

          const micJitsiTrackErrorMsg = getJitsiTrackErrorMessageKey(
            'mic',
            name
          );
          const micErrorMsg =
            micJitsiTrackErrorMsg || getJitsiTrackErrorMessageKey('mic');
          const additionalMicErrorMsg = micJitsiTrackErrorMsg ? null : message;
          const titleKey = getJitsiTrackErrorKey('mic', name);
          dispatch(
            actionShowWarningNotification({
              description: additionalMicErrorMsg,
              descriptionKey: micErrorMsg,
              titleKey,
              id: Date.now().toString()
            })
          );
          break;
        }
        default:
          break;
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
    return result;
  };

export default meetingMiddleware;
