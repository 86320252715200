import { createWrapper, MakeStore } from 'next-redux-wrapper';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { IMainState } from '../interfaces';
import { isOnClientSide } from '../utils/window';
import meetingMiddleware from './meetingMiddleware';
import rootReducer from './reducers';
import { defaultClientState } from './reducers/clientReducer';
import popupMeetingMiddleware from './vbPopupMeetingMiddleware';
import virtualBoutiqueMiddleware from './virtualBoutiqueMiddleware';

const defaultState: IMainState = {
  clientState: defaultClientState,
  serverState: {
    productInspirations: {
      inspirations: []
    }
  }
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (isOnClientSide() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
// create a makeStore function
const makeStore: MakeStore<IMainState> = () =>
  createStore(
    rootReducer,
    defaultState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        meetingMiddleware,
        popupMeetingMiddleware,
        virtualBoutiqueMiddleware
      )
    )
  );

// export an assembled wrapper
export const wrapper = createWrapper<IMainState>(makeStore);
