import { AnyAction, Reducer } from 'redux';
import { ClientState } from '../../interfaces';
import parentPathReducer from './parentPathReducer';
import productCompareReducer from './productCompareReducer';
import virtualBoutiqueReducer from './virtualBoutiqueReducer';
import wishlistReducer from './wishlistReducer';
import meetingReducer from './meetingReducer';
import userReducer from './userReducer';
import productDetailsReducer from './productDetailsReducer';
import inspirationRelatedProductsReducer from './inspirationRelatedProductsReducer';
import similarProductsReducer from './similarProductsReducer';
import searchReducer from './searchReducer';
import shoppingCartReducer from './shoppingCartReducer';
import hubReducer from './hubReducer';
import productAccessoriesReducer from './productAccessoriesReducer';
import loubiAirwaysReducer from './loubiAirwaysReducer';
import fileViewerReducer from './fileViewerReducer';
import chopardReducer from './chopardReducer';
import loubiFutureReducer from './loubiFutureReducer';
import common3DReducer from './common3DReducer';
import vcaReducer from './vcaReducer';

export const defaultClientState: ClientState = {
  showBoutiqueJourney: true,
  pathHistory: [],
  storySearch: {
    keywords: '',
    selectedContext: [],
    selectedLanguage: undefined,
    showFilter: false
  }
};

export const reduceReducers = (
  defaultState: ClientState,
  reducers: Reducer<ClientState, AnyAction>[]
): Reducer<ClientState, AnyAction> => (
  state: ClientState = defaultState,
  action: AnyAction
): ClientState => reducers.reduce((acc, curr) => curr(acc, action), state);

const clientReducer = reduceReducers(defaultClientState, [
  parentPathReducer,
  virtualBoutiqueReducer,
  productCompareReducer,
  wishlistReducer,
  meetingReducer,
  inspirationRelatedProductsReducer,
  userReducer,
  productDetailsReducer,
  similarProductsReducer,
  searchReducer,
  shoppingCartReducer,
  productAccessoriesReducer,
  hubReducer,
  vcaReducer,
  loubiAirwaysReducer,
  loubiFutureReducer,
  fileViewerReducer,
  chopardReducer,
  common3DReducer
]);

export default clientReducer;
