import { uniqBy } from 'lodash';
import { AnyAction } from 'redux';
import { aspreyBrandId } from '../../config';
import { ClientState, IProduct } from '../../interfaces';
import {
  ADD_PRODUCT_TO_COMPARE,
  FOCUS_ITEM,
  REMOVE_PRODUCT_FROM_COMPARE
} from '../actions';
import { defaultClientState } from './clientReducer';

const productCompareReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case ADD_PRODUCT_TO_COMPARE: {
      const existingProducts = state.productsToCompare || [];
      const product = action.payload;
      if (existingProducts.length === 4) {
        return {
          ...state,
          productsToCompare: [...existingProducts.slice(1), product]
        };
      }
      let products: IProduct[];
      if (
        existingProducts.some((p) => p.type !== product.type) &&
        product.brandId !== aspreyBrandId
      ) {
        products = [product];
      } else {
        products = uniqBy([...existingProducts, product], 'id');
      }
      return {
        ...state,
        productsToCompare: products
      };
    }
    case REMOVE_PRODUCT_FROM_COMPARE: {
      const existingProducts = state.productsToCompare || [];
      const product = action.payload;
      const products = existingProducts.filter((p) => p.id !== product.id);
      const focusedProductId =
        state.focusedItem?.section === 'compareProduct'
          ? state.focusedItem?.itemId
          : undefined;
      return {
        ...state,
        productsToCompare: products,
        focusedItem:
          product.id === focusedProductId
            ? undefined
            : { section: 'compareProduct', itemId: focusedProductId }
      };
    }
    case FOCUS_ITEM: {
      return {
        ...state,
        focusedItem: action.payload
      };
    }
    default:
      return state;
  }
};

export default productCompareReducer;
