import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import {
  VB_CLOSE_POPUP,
  VB_OPEN_POPUP,
  VB_OPEN_JOURNEY,
  VB_HIDE_JOURNEY,
  VB_DID_GO_TO_SCENE,
  VB_ENABLE_JOURNEY,
  VB_DISABLE_JOURNEY,
  VB_DID_GO_BACK_TO_SCENE,
  VB_UPDATE_MODE,
  VB_DID_LOOK_TO_VIEW,
  VB_APPOINTMENT_POPUP,
  VB_CONTACT_POPUP,
  VB_FEEDBACK_POPUP,
  VB_DID_CLICK_CONTACT,
  VB_FEEDBACK_TIMER_FIRED,
  VB_DID_LOAD_JOURNEY_CONFIG,
  VB_DID_LOAD_CHAT_CONFIG,
  VB_SHOW_ZOOM_INDICATOR,
  VB_UPDATE_DISCOVER_MORE,
  VB_MUTE_BACKGROUND_SOUND,
  VB_UNMUTE_BACKGROUND_SOUND,
  VB_PANO_LOADED,
  VB_DID_START_PLAYING_VIDEO,
  UPDATE_STORE,
  VB_DID_SHOW_CAMPAIGN,
  VB_SHOW_HUD_BUTTON,
  VB_OPEN_HUD,
  VB_SHOW_WELCOME_TO_SCENE,
  VB_TOGGLE_VEIL,
  VB_DID_CLICK_HUD_MENU,
  VB_ACCESS_REQUEST,
  VB_OPEN_MUSIC_PLAYLIST,
  UPDATE_VB_CONFIG,
  VB_ENQUIRY_POPUP,
  VB_UPDATE_BACKGROUND_MUSIC_URL,
  VB_SHOW_OUTRO,
  VB_SHOW_STUDIO_JOIN_BUTTON,
  VB_SWITCH_OFF_LIGHTS,
  VB_SHOW_FULL_SCREEN_VIDEO_WITH_URL,
  VB_VIDEO_APPOINTMENT_POPUP,
  VB_SULTANATE_OF_OMAN_POPUP,
  VB_UPDATE_MAP,
  TELEPORT_SHOW_OPTIONS,
  TELEPORT_TO,
  TELEPORT_SELECT_SITE,
  OPEN_TOOLTIP,
  SET_PIXEL_STREAM_SERVER,
  SET_PIXEL_STREAM_CONNECTION_STATUS,
  SET_PIXEL_STREAM_PLAYER_STATUS,
  PIXEL_STREAM_DID_FOCUS_ON_AN_OBJECT,
  UPDATE_VIEWPORT_INFO,
  VB_UPDATE_POPUP_PAGE_TYPE,
  BROWSE_STORYBOOK_IN_MEETING,
  VB_OPEN_SSP_STORYBOOK_SEARCH,
  VB_CLOSE_SSP_STORYBOOK_SEARCH
} from '../actions';
import { defaultClientState } from './clientReducer';
import { last } from 'lodash';
import { StreamConnectionStatus } from '../../components/PixelStream/PSInterface';

const virtualBoutiqueReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case VB_OPEN_POPUP:
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          popup: {
            open: true,
            url: action.payload.url,
            darkHeader: action.payload.darkHeader
          }
        }
      };

    case VB_UPDATE_POPUP_PAGE_TYPE:
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          popup: {
            ...(state.vb?.popup || {
              open: false,
              url: '',
              darkHeader: false
            }),
            pageType: action.payload
          }
        }
      };
    case VB_CLOSE_POPUP:
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          popup: {
            open: false,
            url: '',
            darkHeader: false
          }
        }
      };
    case VB_OPEN_JOURNEY:
      return {
        ...state,
        showBoutiqueJourney: true
      };
    case VB_HIDE_JOURNEY:
      return {
        ...state,
        showBoutiqueJourney: false
      };
    case VB_ENABLE_JOURNEY:
      return {
        ...state,
        disableBoutiqueJourney: false
      };
    case VB_DISABLE_JOURNEY:
      return {
        ...state,
        disableBoutiqueJourney: true
      };
    case VB_DID_GO_TO_SCENE: {
      const currentSceneIds: string[] = state.vb?.sceneIds || [];
      if (last(currentSceneIds) && last(currentSceneIds) === action.payload) {
        return state;
      }
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          sceneIds: [...currentSceneIds, action.payload]
        }
      };
    }
    case VB_DID_LOOK_TO_VIEW: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          currentView: action.payload
        }
      };
    }
    case VB_DID_GO_BACK_TO_SCENE: {
      const currentSceneIds: string[] = state.vb?.sceneIds || [];
      if (currentSceneIds.length < 2) {
        return state;
      }
      const copy = [...currentSceneIds];
      copy.pop();
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          sceneIds: copy
        }
      };
    }
    case VB_UPDATE_MODE: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          mode: action.payload
        }
      };
    }
    case VB_APPOINTMENT_POPUP: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          appointmentPopup: action.payload
        }
      };
    }

    case VB_SULTANATE_OF_OMAN_POPUP: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          sultanateOfOmanPopup: action.payload
        }
      };
    }

    case VB_VIDEO_APPOINTMENT_POPUP: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          videoAppointmentPopup: action.payload
        }
      };
    }

    case VB_ENQUIRY_POPUP: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          enquiryPopup: action.payload
        }
      };
    }

    case VB_CONTACT_POPUP: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          openContactPopup: action.payload
        }
      };
    }

    case VB_DID_CLICK_CONTACT: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          contactButtonClicked: true
        }
      };
    }

    case VB_FEEDBACK_TIMER_FIRED: {
      const contactClickedStatus = state.vb?.contactButtonClicked;
      const hasAppointmentPopupState = state.vb?.appointmentPopup;

      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          openFeedbackPopup: !(contactClickedStatus || hasAppointmentPopupState)
        }
      };
    }
    case VB_FEEDBACK_POPUP: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          openFeedbackPopup: action.payload
        }
      };
    }
    case VB_DID_LOAD_JOURNEY_CONFIG: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          journeyConfig: action.payload
        }
      };
    }
    case VB_DID_LOAD_CHAT_CONFIG: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          chatConfig: action.payload
        }
      };
    }
    case VB_SHOW_ZOOM_INDICATOR: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          showZoomIndicator: action.payload
        }
      };
    }
    case VB_UPDATE_DISCOVER_MORE: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          discoverMore: action.payload
        }
      };
    }

    case VB_DID_START_PLAYING_VIDEO: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          backgroundVideoPlayed: action.payload
        }
      };
    }

    case VB_MUTE_BACKGROUND_SOUND: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          muteBackgroundSound: true
        }
      };
    }

    case VB_UNMUTE_BACKGROUND_SOUND: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          muteBackgroundSound: false
        }
      };
    }

    case VB_PANO_LOADED: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          panoLoaded: action.payload
        }
      };
    }

    case VB_SHOW_FULL_SCREEN_VIDEO_WITH_URL: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          fullScreenVideoWithUrl: action.payload
        }
      };
    }

    case UPDATE_STORE: {
      return {
        ...state,
        store: action.payload
      };
    }

    case UPDATE_VB_CONFIG: {
      return {
        ...state,
        virtualBoutiqueConfig: action.payload
      };
    }

    case VB_DID_SHOW_CAMPAIGN: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          hasShownCampaign: true
        }
      };
    }
    case VB_SHOW_HUD_BUTTON: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          hud: {
            ...(state.vb?.hud || {}),
            showButton: action.payload
          }
        }
      };
    }

    case VB_OPEN_HUD: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          hud: {
            ...(state.vb?.hud || {}),
            open: action.payload
          }
        }
      };
    }
    case VB_SHOW_WELCOME_TO_SCENE: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          showWelcomeToScene: action.payload
        }
      };
    }
    case VB_TOGGLE_VEIL: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          showVeil: action.payload
        }
      };
    }

    case VB_DID_CLICK_HUD_MENU: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          hud: {
            ...(state.vb?.hud || {}),
            activeMenu: action.payload
          }
        }
      };
    }

    case VB_ACCESS_REQUEST: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          accessRequest: {
            ...(state.vb?.accessRequest || {}),
            ...action.payload
          }
        }
      };
    }

    case VB_OPEN_MUSIC_PLAYLIST: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          openMusicPlaylist: action.payload
        }
      };
    }

    case VB_UPDATE_BACKGROUND_MUSIC_URL: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          activeBackgroundMusicUrl: action.payload
        }
      };
    }

    case VB_SHOW_OUTRO: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          showOutro: action.payload
        }
      };
    }

    case VB_SHOW_STUDIO_JOIN_BUTTON: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          showStudioJoinButton: action.payload
        }
      };
    }

    case VB_SWITCH_OFF_LIGHTS: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          lightsOff: action.payload
        }
      };
    }

    case VB_UPDATE_MAP: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          map: {
            ...(state.vb?.map || {}),
            ...(action.payload || {})
          }
        }
      };
    }

    case TELEPORT_SHOW_OPTIONS: {
      return {
        ...state,
        teleport: {
          ...(state.teleport || {}),
          showOptions: action.payload
        }
      };
    }

    case TELEPORT_SELECT_SITE: {
      const currentTeleport = state.teleport?.teleportTo?.store?.id;
      const currentPixelStreaming = state.vb?.pixelStreaming || null;

      return {
        ...state,
        teleport: {
          ...(state?.teleport || {}),
          teleportTo: action.payload
            ? { ...(state?.teleport?.teleportTo || {}), siteId: action.payload }
            : undefined
        },
        vb: {
          ...(state?.vb || {}),
          pixelStreaming:
            currentTeleport === action.payload ? currentPixelStreaming : null
        }
      };
    }

    case TELEPORT_TO: {
      const inMeeting = !!state?.meeting?.meetingId;
      const currentTeleport = state.teleport?.teleportTo?.store?.id;
      const currentTeleportHistory = state.teleport?.history || [];
      const currentPixelStreaming = state.vb?.pixelStreaming || null;

      const isNewTeleport = action.payload?.store?.id !== currentTeleport;
      const shouldClearVB =
        (inMeeting && state?.meeting?.isPresenter) || !inMeeting;

      const updatedState = shouldClearVB
        ? {
            ...state,
            vb: { mode: state?.vb?.mode },
            loubiAirways: undefined,
            loubiFuture: undefined
          }
        : state;
      return {
        ...updatedState,
        teleport: {
          teleportTo: action.payload,
          history: isNewTeleport
            ? [
                ...currentTeleportHistory,
                ...(action.payload?.store?.id
                  ? [action.payload?.store?.id]
                  : [])
              ]
            : currentTeleportHistory,
          showOptions: false
        },
        vb: {
          ...(updatedState?.vb || {}),
          pixelStreaming: isNewTeleport ? null : currentPixelStreaming
        }
      };
    }

    case OPEN_TOOLTIP: {
      return {
        ...(state || {}),
        tooltip: action.payload === state?.tooltip ? undefined : action.payload
      };
    }

    case SET_PIXEL_STREAM_SERVER: {
      const psStoreId = action.payload.storeId;
      const currentTeleport = state.teleport?.teleportTo?.store?.id;
      const isConnected = [
        StreamConnectionStatus.CONNECTED,
        StreamConnectionStatus.CONNECTING
      ].includes(state.vb?.pixelStreaming?.connectionStatus);
      const isPsStoreValid = psStoreId === currentTeleport && !isConnected;
      const shouldUpdate = isPsStoreValid || !currentTeleport;
      if (!shouldUpdate) return state;
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          pixelStreaming: {
            ...(state.vb?.pixelStreaming || {}),
            server: action.payload,
            connectionStatus: StreamConnectionStatus.SERVER_FOUND,
            focusedObject: undefined,
            playerStatus: undefined
          }
        }
      };
    }

    case SET_PIXEL_STREAM_CONNECTION_STATUS: {
      const clearPlayAndServerState = [
        StreamConnectionStatus.DISCONNECTED,
        StreamConnectionStatus.FINDING_SERVER,
        StreamConnectionStatus.SERVER_NOT_FOUND
      ].includes(action.payload);

      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          pixelStreaming: {
            ...(state.vb?.pixelStreaming || {}),
            connectionStatus: action.payload,
            playerStatus: clearPlayAndServerState
              ? undefined
              : state.vb?.pixelStreaming?.playerStatus,
            server: clearPlayAndServerState
              ? null
              : state.vb?.pixelStreaming?.server,
            focusedObject: undefined
          }
        }
      };
    }

    case SET_PIXEL_STREAM_PLAYER_STATUS: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          pixelStreaming: {
            ...(state.vb?.pixelStreaming || {}),
            playerStatus: action.payload
          }
        }
      };
    }

    case PIXEL_STREAM_DID_FOCUS_ON_AN_OBJECT: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          pixelStreaming: {
            ...(state.vb?.pixelStreaming || {}),
            focusedObject: action.payload
          }
        }
      };
    }

    case UPDATE_VIEWPORT_INFO: {
      return {
        ...state,
        viewport: action.payload
      };
    }
    case BROWSE_STORYBOOK_IN_MEETING: {
      return {
        ...state,
        isBrowseStorybookInMeeting: action.payload
      };
    }

    case VB_OPEN_SSP_STORYBOOK_SEARCH: {
      const payload = action.payload;
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          sspStorybook: {
            ...payload,
            open: true
          }
        }
      };
    }
    case VB_CLOSE_SSP_STORYBOOK_SEARCH: {
      return {
        ...state,
        vb: {
          ...(state.vb || {}),
          sspStorybook: {
            open: false
          }
        }
      };
    }
    default:
      return state;
  }
};

export default virtualBoutiqueReducer;
