import React from 'react';

const TiffanyStyle = () => (
  <style jsx global>{`
    .appointment .logo img {
      margin: auto;
      width: 300px;
    }
    @media (min-width: 1024px) {
      .appointment .logo img {
        margin: auto;
        width: 400px;
      }
    }
  `}</style>
);

export default TiffanyStyle;
