import {
  IFile,
  IStorybookFileState,
  LoadingStatus
} from './../../../../interfaces/index';
import { getMeetingRole } from './../../../../utils/meeting';
import {
  actionActivateFeatures,
  actionDidReceiveParticipantUserInfo,
  actionExitOneThirdLayout,
  actionFocusItem,
  actionMeetingOverlay,
  actionParticipantDidEnterFromLounge,
  actionPixelStreamDidFocusOnAnObject,
  actionSetFileController,
  actionSetFileViewerStatus,
  actionSetMeetingDate,
  actionSetStorybook,
  actionUpdateLanguage,
  actionUpdateOneThirdLayout,
  SET_MEETING_DATE
} from './../../../../redux/actions';
import { AnyAction, Dispatch } from 'redux';
import { rduSgPanoBasePath } from '../../../../config/rduWW2021';
import {
  ClientState,
  IMeetingPopupState,
  JitsiEndpointMessage,
  MeetingLayout,
  MeetingRole,
  PanoView,
  participantInMeetingStates,
  ParticipantMeetingState,
  Source
} from '../../../../interfaces';
import { IChopardState } from '../../../../interfaces/chopard';
import { ILoubiAirwaysState } from '../../../../interfaces/loubiairways';
import { ILoubiFutureState } from '../../../../interfaces/loubifuture';
import { ICommon3DState } from '../../../../interfaces/common3D';
import {
  actionAddLayoutPresenter,
  actionAppointmentPopup,
  actionApproveRaiseHand,
  actionCloseVBPopup,
  actionDidClickHudMenu,
  actionDidClickOnHotSpot,
  actionDidReceiveAdvisorInfo,
  actionDidReceiveChangeToViewCommand,
  actionDidReceiveMoveToSceneCommand,
  actionDidReceiveRemotePopupAction,
  actionDidReceiveRequestUserInfo,
  actionDidReceiveToggleRaiseHand,
  actionEnterMeeting,
  actionFlipPano,
  actionKickedOutFromMeeting,
  actionLoadNewPano,
  actionLocalTrackAudioMuteDidChange,
  actionOpenVBPopup,
  actionPopupVideoDidPlayWithSound,
  actionRemoteHideHotSpot,
  actionRemoteShowHotSpot,
  actionRemoveLayoutPresenter,
  actionRequestToBeAdmittedInMeeting,
  actionResetAndFlipPano,
  actionSetActivePage,
  actionSetIsMeetingPresenter,
  actionSetMeetingLayoutState,
  actionSetRaiseHandState,
  actionShowFullScreenVideoWithUrl,
  actionToggleEnlargeVideo,
  actionToggleParticipantVisibility,
  actionUpdateFileViewerLayout,
  actionUpdateMeetingLayout,
  actionUpdatePlayerControl,
  actionUpdateStudioVideoRotateDeg,
  actionUpdateStudioVideoZoomLevel,
  actionUpdateMap as actionUpdateVBMap,
  actionTeleportSelectSite
} from '../../../../redux/actions';
import {
  actionDidReceiveRemoteChopardAnimationState,
  actionUpdateChopardActiveAnimationInPano,
  actionUpdateChopardSelectedModel
} from '../../../../redux/customActions/chopard';
import {
  actionDidReceiveRemoteLoubiAirwaysAnimationState,
  actionPlayIntroductionVideo,
  actionUpdateActiveAnimationInPano,
  actionUpdateLoubiAirwaysFullScreenVideo,
  actionUpdateLoubiAirwaysPlanePlan,
  actionUpdateLoubiAirwaysSelectedModel,
  actionUpdateVisitedPlanePlanSpot
} from '../../../../redux/customActions/loubiAirways';
import {
  actionUpdateLoubiFutureCanvasState,
  actionUpdateLoubiFutureSelectedProduct,
  actionUpdateMap
} from '../../../../redux/customActions/loubiFuture';
import {
  actionDidReceiveRemoteCommon3DAnimationState,
  actionUpdateCommon3DActiveAnimationInPano
} from '../../../../redux/customActions/common3DAction';
import { scrollForVoyage } from '../../../hooks/scroll';
import {
  ACTIVE_PAGE,
  ADMIT_INTO_MEETING,
  ADVISOR_INFO,
  CHOPARD_REMOTE_CONTROL_ANIMATION,
  CHOPARD_UPDATE_ACTIVE_ANIMATION,
  CHOPARD_UPDATE_SELECTED_MODEL,
  FILE_VIEWER_LAYOUT,
  KICK_PARTICIPANT,
  MeetingCommand,
  MUTE_AUDIO_TRACK,
  PAGE_SCROLL,
  PLAYER_STATE,
  PLAY_LOUBIAIRWAYS_INTRODUCTION_VIDEO_FOR_MEETING,
  RAISE_HAND,
  RAISE_HAND_APPROVED,
  RELAY_ACTION,
  REMOTE_CONTROL,
  REMOTE_CONTROL_ANIMATION,
  REQUEST_USER_INFO,
  COMMON3D_REMOTE_CONTROL_ANIMATION,
  COMMON3D_UPDATE_ACTIVE_ANIMATION,
  TRANSFER_PRESENTER,
  UNMUTE_ALL_AUDIO_TRACK,
  UNMUTE_AUDIO_TRACK,
  UPDATE_ACTIVE_ANIMATION,
  UPDATE_LOUBIAIRWAYS_FULL_SCREEN_VIDEO,
  UPDATE_LOUBIAIRWAYS_PLAN,
  UPDATE_LOUBIAIRWAYS_VISITED_PLAN,
  UPDATE_SELECTED_MODEL,
  USER_INFO,
  SYNC_MEETING_INFO,
  UPDATE_LANGUAGE
} from '../../commands';
import { IVCAState } from '../../../../interfaces/vca';
import { actionOpenVCAPopup } from '../../../../redux/customActions/vca';
import { actionToggleMuteLocalTrackAsync } from '../../../../redux/asyncActions';
import { mapStorybookToDocument } from '../../../../mappers/storybook';
import { getActivedStorybookById } from '../../../../clientSideServices/storybook';

const getActionFromPayload = (payload) => {
  switch (payload.value) {
    case MeetingCommand.MOVE:
      if (payload.attributes?.sceneId) {
        return actionDidReceiveMoveToSceneCommand(payload.attributes.sceneId);
      } else {
        return;
      }
    case MeetingCommand.CHANGE_VIEW:
      if (payload.attributes?.currentView) {
        return actionDidReceiveChangeToViewCommand(
          payload.attributes.currentView
        );
      } else {
        return;
      }
    case MeetingCommand.CLICK_HOT_SPOT:
      return actionDidClickOnHotSpot(payload.attributes);
    case MeetingCommand.CLOSE_POPUP:
      return actionCloseVBPopup();
    case MeetingCommand.OPEN_POPUP:
      return actionOpenVBPopup(
        payload.attributes?.url,
        payload.attributes?.darkHeader
      );
    case MeetingCommand.POPUP_ACTION:
      return actionDidReceiveRemotePopupAction(payload.attributes);
    case MeetingCommand.ENLARGE_VIDEO:
      return actionToggleEnlargeVideo(payload.attributes);
    case MeetingCommand.TOGGLE_PARTICIPANT_VISIBILITY:
      return actionToggleParticipantVisibility(payload.attributes);
    case MeetingCommand.UPDATE_LAYOUT_MODE:
      return actionUpdateMeetingLayout(payload.attributes);

    case MeetingCommand.UPDATE_STUDIO_VIDEO_ZOOM_LEVEL:
      return actionUpdateStudioVideoZoomLevel(
        payload.attributes.studioParticipantId,
        payload.attributes.zoomLevel
      );
    case MeetingCommand.TELEPORT_TO:
      return actionTeleportSelectSite(payload.attributes);

    case MeetingCommand.UPDATE_STUDIO_VIDEO_ROTATE_DEGREE:
      return actionUpdateStudioVideoRotateDeg(
        payload.attributes.studioParticipantId,
        payload.attributes.rotateDeg
      );
    case MeetingCommand.SHOW_HOT_SPOT:
      return actionRemoteShowHotSpot(payload.attributes);
    case MeetingCommand.HIDE_HOT_SPOT:
      return actionRemoteHideHotSpot(payload.attributes);
    case MeetingCommand.APPOINTMENT_POPUP:
      return actionAppointmentPopup(payload.attributes);
    case MeetingCommand.FLIP_PANO:
      return actionFlipPano(payload.attributes);
    case MeetingCommand.RESET_AND_FLIP_PANO:
      return actionResetAndFlipPano();
    case MeetingCommand.UPDATE_LAYOUT:
      return actionSetMeetingLayoutState(payload.attributes);
    case MeetingCommand.ADD_LAYOUT_PRESENTER:
      return actionAddLayoutPresenter(payload.attributes);
    case MeetingCommand.REMOVE_LAYOUT_PRESENTER:
      return actionRemoveLayoutPresenter(payload.attributes);
    case MeetingCommand.HUD_MENU_CLICK:
      return actionDidClickHudMenu(payload.attributes);
    case MeetingCommand.UPDATE_MAP:
      return actionUpdateVBMap(payload.attributes);

    case MeetingCommand.UPDATE_ONE_THIRD_LAYOUT:
      return actionUpdateOneThirdLayout(payload.attributes);
    case MeetingCommand.EXIT_ONE_THIRD_LAYOUT:
      return actionExitOneThirdLayout();
    default:
      return;
  }
};

export const handleUserInfo = (attributes: any, dispatch: Dispatch) => {
  const { participantId, identityId, role } = attributes;
  const action = actionDidReceiveParticipantUserInfo(
    participantId,
    identityId,
    role
  );
  dispatch(action);
  const isStudio = getMeetingRole(role) === MeetingRole.STUDIO;
  isStudio && dispatch(actionParticipantDidEnterFromLounge(participantId));
};

const syncVirtualBoutiqueState = (
  dispatch: Dispatch,
  sceneId: string | undefined,
  panoView: PanoView | undefined
) => {
  if (sceneId) {
    dispatch(actionDidReceiveMoveToSceneCommand(sceneId));
  }
  if (panoView) {
    dispatch(actionDidReceiveChangeToViewCommand(panoView));
  }
};

const syncRemoteUsersInfo = (
  dispatch: Dispatch,
  users: { participantId: string; role: MeetingRole; identityId: string }[],
  myParticipantId: string
) => {
  users.forEach((user) => {
    if (user.participantId !== myParticipantId) {
      handleUserInfo(user, dispatch);
    }
  });
};

const syncMeetingViewState = (dispatch: Dispatch, layout: MeetingLayout) => {
  if (layout) {
    dispatch(actionSetMeetingLayoutState(layout));
  }
};

const syncMeetingDateState = (dispatch: Dispatch, date: number) => {
  if (date) {
    dispatch(actionSetMeetingDate(date));
  }
};

const syncRaiseHand = (dispatch: Dispatch, participantIds: string[]) => {
  if (participantIds?.length) {
    dispatch(actionSetRaiseHandState(participantIds));
  }
};

const syncPopupState = (
  dispatch: Dispatch,
  popupState: IMeetingPopupState,
  storeId: string
) => {
  if (!popupState) {
    dispatch(actionCloseVBPopup());
  }
  if (popupState?.url) {
    const url =
      popupState.url.startsWith('/') && !popupState.url.includes('source')
        ? `${location.protocol}//${location.host}${popupState.url}${
            popupState.url.includes('?') ? '&' : '?'
          }source=${Source.Showroom}&store=${storeId}`
        : popupState.url;
    dispatch(actionOpenVBPopup(url));
  }
  if (popupState?.action) {
    dispatch(actionDidReceiveRemotePopupAction(popupState.action));
  }
};

const syncLoubiAirwaysState = (
  dispatch: Dispatch,
  loubiAirwaysState: Partial<ILoubiAirwaysState>
) => {
  if (loubiAirwaysState?.activeAnimation) {
    dispatch(
      actionUpdateActiveAnimationInPano(loubiAirwaysState?.activeAnimation)
    );
  }
  if (loubiAirwaysState?.fullScreenVideo) {
    dispatch(
      actionUpdateLoubiAirwaysFullScreenVideo(
        loubiAirwaysState?.fullScreenVideo
      )
    );
  }
  if (loubiAirwaysState?.planePlan) {
    dispatch(actionUpdateLoubiAirwaysPlanePlan(loubiAirwaysState?.planePlan));
  }
  if (loubiAirwaysState?.selectedModel) {
    dispatch(
      actionUpdateLoubiAirwaysSelectedModel(loubiAirwaysState?.selectedModel)
    );
  }
};

const syncLoubiFutureState = (
  dispatch: Dispatch,
  loubiFutureState: Partial<ILoubiFutureState>
) => {
  if (loubiFutureState?.canvasState) {
    dispatch(actionUpdateLoubiFutureCanvasState(loubiFutureState.canvasState));
  }
  if (loubiFutureState?.map) {
    dispatch(actionUpdateMap(loubiFutureState.map));
  }
  if (loubiFutureState?.selectedProductId) {
    dispatch(
      actionUpdateLoubiFutureSelectedProduct(loubiFutureState.selectedProductId)
    );
  }
};

const syncVCAState = (dispatch: Dispatch, vcaState: Partial<IVCAState>) => {
  if (vcaState?.popup) {
    dispatch(actionOpenVCAPopup(vcaState.popup));
  }
};

const syncChopardState = (
  dispatch: Dispatch,
  chopardState: Partial<IChopardState>
) => {
  if (chopardState?.activeAnimation) {
    dispatch(
      actionUpdateChopardActiveAnimationInPano(chopardState?.activeAnimation)
    );
  }
  if (chopardState?.selectedModel) {
    dispatch(actionUpdateChopardSelectedModel(chopardState?.selectedModel));
  }
};

const syncCommon3DState = (
  dispatch: Dispatch,
  common3DState: Partial<ICommon3DState>
) => {
  if (common3DState?.name) {
    dispatch(actionUpdateCommon3DActiveAnimationInPano(common3DState?.name));
  }
  if (common3DState?.animationState && common3DState?.name)
    dispatch(
      actionDidReceiveRemoteCommon3DAnimationState(
        common3DState?.name,
        common3DState?.animationState[common3DState?.name]
      )
    );
};

export const syncRduManufacture = (
  dispatch: Dispatch,
  advisorSceneId: string
) => {
  dispatch(
    actionLoadNewPano({
      swf: `${rduSgPanoBasePath}tour.swf`,
      xml: `${rduSgPanoBasePath}tour.xml`,
      target: 'embedframe',
      html5: 'auto',
      consolelog: location.hostname === 'localhost',
      mobilescale: 1.0,
      passQueryParameters: true,
      basepath: `${rduSgPanoBasePath}`,
      initvars: {
        startscene: advisorSceneId || 'scene_pano_looby'
      }
    })
  );
};

const syncFileViewerState = (
  dispatch: Dispatch,
  fileViewer: IStorybookFileState,
  clientState: ClientState
) => {
  if (fileViewer?.currentPage) {
    if (
      fileViewer?.currentPage?.url !== clientState.fileViewer?.currentPage?.url
    ) {
      handleLoadStorybook(fileViewer?.currentPage?.id, dispatch).then(
        (sb: IFile) => {
          dispatch(
            actionSetActivePage(
              getCurrentPage(sb, fileViewer?.currentPage.url),
              fileViewer?.nextUrl,
              fileViewer?.prevUrl
            )
          );
        }
      );
    }
    dispatch(actionSetFileController(fileViewer?.controller));
  } else {
    dispatch(actionSetActivePage(null));
  }
};
export const handleAdvisorInfo = (
  attributes: any,
  clientState: ClientState,
  dispatch: Dispatch,
  currentUserEnteredFromLounge: boolean,
  forceEnterMeeting?: boolean
) => {
  const {
    identityId,
    displayName,
    participantId,
    advisorEnteredMeetingFromLounge, // SSP backwards compatibility
    advisorMeetingState,
    advisorSceneId,
    advisorView,
    layout,
    popupState,
    raiseHandParticipantIds,
    loubiAirwaysState,
    loubiFutureState,
    chopardState,
    common3DState,
    vcaState,
    fileViewer,
    fullScreenVideo,
    transitionedToRDUManufacture,
    map,
    remoteUsersInfo,
    remoteUsersRole, // SSP backwards compatibility
    teleportTo,
    meetingDate,
    activeFeatures,
    languageCode,
    isReconnecting,
    psFocusedObject,
    focusedItem,
    isOverlayImgVisible
  } = attributes;
  const isAutoAdmit = clientState.meeting?.autoAdmit;
  if (activeFeatures?.length) {
    dispatch(actionActivateFeatures(activeFeatures));
  }

  const isAlreadyAnAdvisor =
    clientState.meeting?.advisorIdentityId &&
    identityId &&
    clientState.meeting?.advisorIdentityId !== identityId;

  if (isAlreadyAnAdvisor && !isReconnecting) {
    return;
  }
  if (identityId && participantId) {
    dispatch(
      actionDidReceiveAdvisorInfo(identityId, displayName, participantId)
    );
  }
  if (map) {
    dispatch(actionUpdateVBMap(map));
  }

  syncRemoteUsersInfo(
    dispatch,
    remoteUsersInfo || remoteUsersRole || [],
    clientState.meeting?.localUser?.participantId
  );

  if (advisorEnteredMeetingFromLounge) {
    dispatch(actionParticipantDidEnterFromLounge(participantId));
  }
  const advisorEnteredFromLounge =
    participantInMeetingStates.includes(advisorMeetingState) ||
    advisorEnteredMeetingFromLounge;

  if (forceEnterMeeting && advisorEnteredFromLounge) {
    dispatch(actionEnterMeeting(advisorMeetingState));
  } else if (!currentUserEnteredFromLounge && advisorEnteredFromLounge) {
    isAutoAdmit
      ? dispatch(actionEnterMeeting(advisorMeetingState))
      : dispatch(actionRequestToBeAdmittedInMeeting());
  }

  if (fullScreenVideo?.show && fullScreenVideo?.url) {
    dispatch(
      actionShowFullScreenVideoWithUrl({
        show: true,
        url: fullScreenVideo.url
      })
    );
  }
  syncFileViewerState(dispatch, fileViewer, clientState);

  if (fileViewer?.layoutMode || isReconnecting) {
    dispatch(actionUpdateFileViewerLayout(fileViewer?.layoutMode));
  }

  if (psFocusedObject) {
    dispatch(actionPixelStreamDidFocusOnAnObject(psFocusedObject));
  }
  if (focusedItem) {
    dispatch(actionFocusItem(focusedItem));
  }
  if (
    (fileViewer?.playerControl &&
      !clientState.fileViewer?.playerControl?.currentScene) ||
    isReconnecting
  ) {
    dispatch(actionUpdatePlayerControl(fileViewer?.playerControl));
  }

  if (isAutoAdmit || currentUserEnteredFromLounge || forceEnterMeeting) {
    if (teleportTo || isReconnecting) {
      dispatch(actionTeleportSelectSite(teleportTo));
    }
    syncPopupState(dispatch, popupState, teleportTo || clientState.store?.id);
  }
  if (languageCode) dispatch(actionUpdateLanguage(languageCode));
  syncVirtualBoutiqueState(dispatch, advisorSceneId, advisorView);
  syncMeetingViewState(dispatch, layout);
  syncMeetingDateState(dispatch, meetingDate);
  syncRaiseHand(dispatch, raiseHandParticipantIds);
  syncLoubiAirwaysState(dispatch, loubiAirwaysState);
  syncLoubiFutureState(dispatch, loubiFutureState);
  syncChopardState(dispatch, chopardState);
  syncVCAState(dispatch, vcaState);
  syncCommon3DState(dispatch, common3DState);
  if (transitionedToRDUManufacture) {
    syncRduManufacture(dispatch, advisorSceneId);
  }
  if (isOverlayImgVisible) {
    dispatch(actionMeetingOverlay(isOverlayImgVisible));
  }
};

export const handleLoadStorybook = async (
  storybookId: string,
  dispatch: Dispatch
) => {
  try {
    dispatch(actionSetFileViewerStatus(LoadingStatus.LOADING));
    const res = await getActivedStorybookById(storybookId);
    const file = mapStorybookToDocument(res.data);
    dispatch(actionSetStorybook(file));
    dispatch(actionSetFileViewerStatus(LoadingStatus.LOADED));
    console.log('debug:: loaded storybook', file);
    return file;
  } catch (error) {
    dispatch(actionSetFileViewerStatus(LoadingStatus.FAILED));
    console.log('debug:: failed to load storybook', error);
    return Promise.reject(error);
  }
};

export const getCurrentPage = (sb: IFile, currentUrl: string) => {
  const pageIndex = sb.pages.findIndex((url) => url === currentUrl);
  return {
    url: sb.pages[pageIndex],
    setting: sb.pagesSetting?.[pageIndex],
    silence: sb.silences?.[pageIndex],
    file: sb.title,
    id: sb.id
  };
};

export const handleSpectatorEndpointMessage = (
  payload: JitsiEndpointMessage,
  dispatch: Dispatch,
  clientState: ClientState
) => {
  console.log('handleSpectatorEndpointMessage - received payload');

  const currentUserEnteredFromLounge = participantInMeetingStates.includes(
    clientState.meeting?.state
  );

  switch (payload.type) {
    case USER_INFO:
      handleUserInfo(payload.attributes, dispatch);
      break;
    case ADVISOR_INFO: {
      handleAdvisorInfo(
        payload.attributes,
        clientState,
        dispatch,
        currentUserEnteredFromLounge
      );
      break;
    }
    case REQUEST_USER_INFO:
      dispatch(actionDidReceiveRequestUserInfo());
      break;
    case ADMIT_INTO_MEETING: {
      const { participantId, meetingState } = payload.attributes;
      const myParticipantId = clientState.meeting?.localUser?.participantId;
      if (myParticipantId === participantId || participantId === 'all') {
        dispatch(actionEnterMeeting(meetingState));
      } else dispatch(actionParticipantDidEnterFromLounge(participantId));
      break;
    }

    case SYNC_MEETING_INFO: {
      handleAdvisorInfo(
        payload.attributes,
        clientState,
        dispatch,
        currentUserEnteredFromLounge,
        true
      );
      break;
    }
    case SET_MEETING_DATE: {
      dispatch(actionSetMeetingDate(payload.attributes));
      break;
    }

    case REMOTE_CONTROL: {
      const action: AnyAction | undefined = getActionFromPayload(payload);
      const meetingState = clientState.meeting?.state;
      let shouldMirrorRemoteAction = false;
      if (meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE)
        shouldMirrorRemoteAction = true;
      else if (meetingState === ParticipantMeetingState.IN_THE_LOUNGE) {
        shouldMirrorRemoteAction = payload.value !== MeetingCommand.OPEN_POPUP;
      }

      if (action && shouldMirrorRemoteAction) {
        dispatch(action);
      }
      if (payload.value === MeetingCommand.CLOSE_POPUP) {
        dispatch(actionPopupVideoDidPlayWithSound(undefined));
      }
      break;
    }
    case TRANSFER_PRESENTER: {
      dispatch(actionSetIsMeetingPresenter(true));
      break;
    }
    case KICK_PARTICIPANT: {
      dispatch(actionKickedOutFromMeeting());
      break;
    }

    case RAISE_HAND: {
      dispatch(
        actionDidReceiveToggleRaiseHand(payload.attributes.participantId)
      );
      break;
    }

    case RAISE_HAND_APPROVED: {
      dispatch(actionApproveRaiseHand(payload.attributes.participantId));
      break;
    }

    case MUTE_AUDIO_TRACK: {
      const meeting = clientState.meeting || {};
      const localTracks = meeting.localUser?.tracks || [];
      const audio = localTracks.find((t) => t.getType() === 'audio');

      audio?.mute()?.then(() => {
        dispatch(actionLocalTrackAudioMuteDidChange(true));
      });

      break;
    }
    case UPDATE_LANGUAGE:
      if (payload?.attributes)
        dispatch(actionUpdateLanguage(payload.attributes));
      break;
    case UNMUTE_AUDIO_TRACK: {
      const meeting = clientState.meeting || {};
      const audioTrack = meeting.localUser?.tracks?.find(
        (t) => t.getType() === 'audio'
      );
      const isStudio = meeting.localUser?.role === MeetingRole.STUDIO;
      if (!isStudio && (!audioTrack || audioTrack?.isMuted()))
        dispatch(actionToggleMuteLocalTrackAsync('audio') as any);

      break;
    }

    case UNMUTE_ALL_AUDIO_TRACK: {
      const meeting = clientState.meeting || {};
      const audioTrack = meeting.localUser?.tracks?.find(
        (t) => t.getType() === 'audio'
      );
      const isStudio = meeting.localUser?.role === MeetingRole.STUDIO;
      if (!isStudio && (!audioTrack || audioTrack?.isMuted()))
        dispatch(actionToggleMuteLocalTrackAsync('audio') as any);

      break;
    }

    case REMOTE_CONTROL_ANIMATION: {
      dispatch(
        actionDidReceiveRemoteLoubiAirwaysAnimationState(
          payload.attributes?.animation,
          payload.attributes?.state
        )
      );
      break;
    }

    case CHOPARD_REMOTE_CONTROL_ANIMATION: {
      dispatch(
        actionDidReceiveRemoteChopardAnimationState(
          payload.attributes?.animation,
          payload.attributes?.state
        )
      );
      break;
    }

    case COMMON3D_REMOTE_CONTROL_ANIMATION: {
      dispatch(
        actionDidReceiveRemoteCommon3DAnimationState(
          payload.attributes?.animation,
          payload.attributes?.state
        )
      );
      break;
    }
    case COMMON3D_UPDATE_ACTIVE_ANIMATION: {
      dispatch(
        actionUpdateCommon3DActiveAnimationInPano(payload.attributes?.animation)
      );
      break;
    }

    case UPDATE_ACTIVE_ANIMATION: {
      dispatch(
        actionUpdateActiveAnimationInPano(payload.attributes?.animation)
      );
      break;
    }

    case CHOPARD_UPDATE_ACTIVE_ANIMATION: {
      dispatch(
        actionUpdateChopardActiveAnimationInPano(payload.attributes?.animation)
      );
      break;
    }

    case UPDATE_SELECTED_MODEL: {
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel(payload.attributes?.selectedModel)
      );
      break;
    }

    case CHOPARD_UPDATE_SELECTED_MODEL: {
      dispatch(
        actionUpdateChopardSelectedModel(payload.attributes?.selectedModel)
      );
      break;
    }

    case UPDATE_LOUBIAIRWAYS_PLAN: {
      dispatch(
        actionUpdateLoubiAirwaysPlanePlan(payload.attributes?.planePlan)
      );
      break;
    }
    case UPDATE_LOUBIAIRWAYS_VISITED_PLAN: {
      dispatch(actionUpdateVisitedPlanePlanSpot(payload.attributes?.spot));
      break;
    }

    case UPDATE_LOUBIAIRWAYS_FULL_SCREEN_VIDEO: {
      dispatch(
        actionUpdateLoubiAirwaysFullScreenVideo(payload.attributes?.video)
      );
      break;
    }

    case PLAY_LOUBIAIRWAYS_INTRODUCTION_VIDEO_FOR_MEETING: {
      dispatch(actionPlayIntroductionVideo());
      break;
    }

    case RELAY_ACTION: {
      dispatch(payload.attributes);
      break;
    }
    case ACTIVE_PAGE: {
      const filerController = clientState?.fileViewer?.controller;
      const myParticipantId = clientState?.meeting?.localUser?.participantId;
      const isFileController = filerController === myParticipantId;
      const activePage = clientState?.fileViewer?.currentPage;
      const currentPage = payload.attributes?.currentPage;
      //detect closing file - need to mirror
      const isClosingFile = currentPage === null;
      if (isFileController && activePage && !isClosingFile) break;

      const type = currentPage?.type;

      if (type !== 'storybook') {
        dispatch(
          actionSetActivePage(
            currentPage,
            payload.attributes?.nextUrl,
            payload.attributes?.prevUrl
          )
        );
        break;
      }
      const activeSb = clientState?.fileViewer?.sbFromMeeting;
      const fileId = currentPage?.id;

      if (activeSb && activeSb?.id === fileId) {
        dispatch(
          actionSetActivePage(
            getCurrentPage(activeSb, currentPage.url),
            payload.attributes?.nextUrl,
            payload.attributes?.prevUrl
          )
        );
      } else {
        handleLoadStorybook(fileId, dispatch)
          .then((sb: IFile) => {
            dispatch(
              actionSetActivePage(
                getCurrentPage(sb, currentPage.url),
                payload.attributes?.nextUrl,
                payload.attributes?.prevUrl
              )
            );
          })
          .catch((_) => {
            console.log('debug:: failed to load storybook');
          });
      }
      break;
    }

    case PLAYER_STATE: {
      dispatch(actionUpdatePlayerControl(payload.attributes));
      break;
    }
    case FILE_VIEWER_LAYOUT: {
      dispatch(actionUpdateFileViewerLayout(payload.attributes));
      break;
    }

    case PAGE_SCROLL: {
      scrollForVoyage(payload.attributes);
      break;
    }

    default:
      break;
  }
};
