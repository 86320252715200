import { OPEN_POPUP, CLOSE_POPUP } from './../customActions/vca';
import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import { defaultClientState } from './clientReducer';

const vcaReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case OPEN_POPUP:
      return {
        ...state,
        vca: {
          ...(state.vca || {}),
          popup: action.payload
        }
      };
    case CLOSE_POPUP:
      return {
        ...state,
        vca: {
          ...(state.vca || {}),
          popup: undefined
        }
      };

    default:
      return state;
  }
};

export default vcaReducer;
