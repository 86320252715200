export const reduceSizeAndWidth = (
  image: HTMLImageElement,
  filename: string,
  type = 'image/png',
  quality = 0.8,
  maxWith?: number,
  ratio?: number
): Promise<File> => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const aspectRatio = ratio ||(image.naturalWidth / image.naturalHeight);
  canvas.width = maxWith || 700;
  canvas.height = canvas.width / aspectRatio;
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  return new Promise((resolve) => {
    canvas.toBlob(
      async (blob) => {
        // error when convert svg image to file
        (blob as any).lastModifiedDate = new Date();
        (blob as any).name = filename;
        resolve(blob as File);
      },
      type,
      quality
    );
  });
};

export const blobToDataURL = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(e) {
      resolve(e.target.result);
    };
    reader.onerror = function(e) {
      reject(e);
    };
    reader.readAsDataURL(blob);
  });
}