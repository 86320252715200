import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import { DID_CHANGE_WISHLIST_TAB, UPDATE_WISHLIST } from '../actions';
import { defaultClientState } from './clientReducer';

const wishlistReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case UPDATE_WISHLIST: {
      return {
        ...state,
        wishlist: action.payload
      };
    }
    case DID_CHANGE_WISHLIST_TAB: {
      return {
        ...state,
        wishlistTab: action.payload
      };
    }
    default:
      return state;
  }
};

export default wishlistReducer;
