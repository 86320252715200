import App from 'next/app';
import '../styles/bootstrap.min.css';
import '../styles/index.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { wrapper } from '../redux/store';
import { appWithTranslation } from 'next-i18next';
import * as Sentry from '@sentry/node';
import 'core-js/features/promise/finally';
import nextI18NextConfig from '../next-i18next.config.js';

/* eslint-disable no-undef */
Sentry.init({
  enabled: process.env.NEXT_PUBLIC_APP_ENV === 'production',
  dsn: 'https://1d5b131ab20d48d196f3b117c973b382@o433551.ingest.sentry.io/5388896',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0 : 1,
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 0 : 1
});
/* eslint-enable no-undef */

// This default export is required in a new `pages/_app.js` file.
class InspifyWeb extends App {
  render() {
    const { Component, pageProps } = this.props;
    return <Component {...pageProps} />;
  }
}

export default wrapper.withRedux(
  appWithTranslation(InspifyWeb, nextI18NextConfig)
);
