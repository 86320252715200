import { some } from 'lodash';
import {
  reqMeetUserType,
  SALES_STORYBOOK_USER
} from '../advisorHub/utils/hubEntitlements';
import {
  chopardHighJewelleryId,
  getLoftUrlByStoreId,
  getVBUrlByStoreId,
  inspifyBrandId,
  inspifyGCCSiteId,
  inspifySiteId,
  inspifyJPSilkRoadSiteId,
  inspifyTestBrandId,
  inspifyTestingSiteId,
  isChopardWW2021,
  isIWCSGIon,
  isRDULite,
  iwcThaiBangkokBoutiqueId,
  iwcWW2021Id,
  jlcAuMelbourneBoutiqueId,
  loubiFutureSiteId,
  rogerDubuisRetailId,
  rogerDubuisSgBoutiqueId,
  rogerDubuisWholeSaleId,
  rogerDubuisWW2021Id,
  vacSgBoutiqueId,
  vacMarinaBaySandsBoutiqueId
} from '../config';
import {
  IMeetUserPermission,
  IVirtualBoutiqueEntitlement,
  MeetUserPermissionType
} from '../interfaces';

export const shouldShowCompare = (
  entitlement: IVirtualBoutiqueEntitlement | null | undefined
) => {
  return !!entitlement?.productCompare;
};

export const shouldShowWishlist = (
  entitlement: IVirtualBoutiqueEntitlement | null | undefined
) => {
  return !!entitlement?.wishlist;
};

export const shouldHideSoundButton = (storeId: string, sceneId: string) => {
  return (
    storeId === jlcAuMelbourneBoutiqueId &&
    !(sceneId === 'scene_14' || sceneId === 'scene_13')
  );
};

export const shouldMuteByDefault = (storeId: string) => {
  const muteStore = [jlcAuMelbourneBoutiqueId, iwcThaiBangkokBoutiqueId];
  return isIWCSGIon(storeId) || muteStore.includes(storeId);
};

export const shouldInDarkMode = (storeId: string) =>
  [
    rogerDubuisSgBoutiqueId,
    rogerDubuisWW2021Id,
    rogerDubuisRetailId,
    rogerDubuisWholeSaleId
  ].includes(storeId) || isRDULite(storeId);

export const shouldShowCountdownPageByDefaultForStore = (storeId: string) =>
  storeId === iwcWW2021Id ||
  [rogerDubuisWW2021Id, rogerDubuisWholeSaleId, rogerDubuisRetailId].includes(
    storeId
  ) ||
  storeId === loubiFutureSiteId ||
  isChopardWW2021(storeId);

export const shouldHideLoungeInspirations = (storeId: string) =>
  [
    iwcWW2021Id,
    inspifyTestingSiteId,
    inspifySiteId,
    inspifyGCCSiteId,
    inspifyJPSilkRoadSiteId
  ].includes(storeId);

export const shouldHidePresentationLayout = (storeId: string): boolean => {
  return storeId === loubiFutureSiteId;
};

export const shouldShowSalesLoftAppointment = (storeId?: string) =>
  storeId === vacSgBoutiqueId || storeId === vacMarinaBaySandsBoutiqueId;

export const shouldShowOpenSalesLoft = (
  brandId: string,
  storeId: string,
  isEmbeddedInSsp: boolean,
  meetOnly: boolean,
  meetUserType: string
): boolean => {
  if (isEmbeddedInSsp) {
    return false;
  }
  if (meetOnly) {
    return false;
  }
  if (brandId === inspifyBrandId || brandId === inspifyTestBrandId) {
    return false;
  }
  if (reqMeetUserType.includes(meetUserType)) {
    return false;
  }
  return !!getLoftUrlByStoreId(storeId);
};

export const shouldShowOpenVB = (
  brandId: string,
  storeId: string,
  isEmbeddedInSsp: boolean,
  meetOnly: boolean,
  meetUserType: string
): boolean => {
  if (isEmbeddedInSsp) {
    return false;
  }
  if (meetOnly) {
    return false;
  }
  if (brandId === inspifyBrandId || brandId === inspifyTestBrandId) {
    return false;
  }
  if (reqMeetUserType.includes(meetUserType)) {
    return false;
  }
  return !!getVBUrlByStoreId(storeId);
};

export const shouldShowStartLiveSession = (
  brandId: string,
  storeId: string,
  isEmbeddedInSsp: boolean,
  meetOnly: boolean,
  meetUserType: string
): boolean => {
  if (isEmbeddedInSsp) {
    return false;
  }
  if (storeId === chopardHighJewelleryId) {
    return false;
  }
  if (brandId === inspifyBrandId || brandId === inspifyTestBrandId) {
    return false;
  }
  if (meetOnly) {
    return false;
  }
  if (reqMeetUserType.includes(meetUserType)) {
    return false;
  }
  return true;
};

export const shouldShowCalendar = (
  permissions: IMeetUserPermission[],
  meetUserType: string
): boolean => {
  if (!reqMeetUserType.includes(meetUserType)) {
    return true;
  }
  return some(
    permissions,
    (permission) => MeetUserPermissionType.CALENDAR === permission.name
  );
};
export const shouldShowTemplates = (
  meetUserType: string,
  meetOnly: boolean
): boolean => {
  if (meetOnly) {
    return false;
  }
  return !reqMeetUserType.includes(meetUserType);
};
export const shouldShowInvites = (
  meetUserType: string,
  meetOnly: boolean
): boolean => {
  if (meetOnly) {
    return false;
  }
  return !reqMeetUserType.includes(meetUserType);
};
export const shouldShowGallery = (
  meetUserType: string,
  meetOnly: boolean
): boolean => {
  if (meetOnly) {
    return false;
  }
  return !reqMeetUserType.includes(meetUserType);
};
export const shouldShowProfile = (
  permissions: IMeetUserPermission[],
  meetUserType: string
): boolean => {
  if (!reqMeetUserType.includes(meetUserType)) {
    return true;
  }
  return some(
    permissions,
    (permission) => MeetUserPermissionType.PROFILE === permission.name
  );
};
export const shouldShowAdmin = (
  permissions: IMeetUserPermission[],
  meetUserType: string,
  isAdmin: boolean
): boolean => {
  if (isAdmin) {
    return true;
  }
  return some(
    permissions,
    (permission) => MeetUserPermissionType.USER_MANAGEMENT === permission.name
  );
};
export const shouldShowTeams = ({
  brandId,
  permissions,
  userType,
  storeId
}: {
  brandId?: string;
  permissions: IMeetUserPermission[];
  userType: string;
  storeId: string;
}) => {
  if (userType === SALES_STORYBOOK_USER) {
    return true;
  }
  if (!reqMeetUserType.includes(userType)) {
    if (storeId === inspifyGCCSiteId || storeId === inspifyJPSilkRoadSiteId) {
      return false;
    }
    return brandId === inspifyBrandId || brandId === inspifyTestBrandId;
  }
  return some(
    permissions,
    (permission) => MeetUserPermissionType.TEAM === permission.name
  );
};
