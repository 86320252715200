import { AxiosResponse } from 'axios';
import { ISessionDetails } from '../interfaces';
import httpClient from './http';

export const fetchSessionFiles = (meetingId: string) =>
  httpClient.get(`/api/sessionFiles/${meetingId}`);

export const getSessionById = (
  meetingId: string
): Promise<AxiosResponse<ISessionDetails>> =>
  httpClient.get(`/api/session/${meetingId}`, {
    headers: {
      'Cache-Control': 'no-cache'
    }
  });
