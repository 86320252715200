import {
  isUserOnKakaotalk,
  isUserOnLineBrowser,
  isUserOnWeChat
} from './deviceDetector';
import { AnyAction, Dispatch } from 'redux';
import { logEvent } from '../analytics';
import { parseSourceString } from '../components/AppContext';
import { getVBUrl } from '../config';
import {
  IMeeting,
  MeetingLayout,
  MeetingLayoutMode,
  MeetingRole,
  Source,
  ViewportType
} from '../interfaces';
import { actionMeetingOverlay, actionSendMirrorAction } from '../redux/actions';
import { DID_SHOW_MEETING_OVERLAY } from './events/meeting';
import { getDefaultMeetingLayout } from './stores';
import { trySetLocalStorage } from './window';

export const getMeetingRole = (roleInRouter: string | undefined) => {
  if (roleInRouter?.toLowerCase() === 'advisor') return MeetingRole.ADVISOR;
  if (roleInRouter?.toLowerCase() === 'co_advisor')
    return MeetingRole.CO_ADVISOR;
  if (roleInRouter?.toLowerCase() === 'mc') return MeetingRole.MC;
  if (
    roleInRouter?.toLowerCase() === 'studio' ||
    roleInRouter?.toLowerCase() === 'product'
  )
    return MeetingRole.STUDIO;
  return MeetingRole.CLIENT;
};

export const getMeetingRoleString = (role?: MeetingRole): string => {
  switch (role) {
    case MeetingRole.ADVISOR:
      return 'advisor';
    case MeetingRole.CO_ADVISOR:
      return 'co_advisor';
    case MeetingRole.MC:
      return 'mc';
    case MeetingRole.STUDIO:
      return 'studio';
    default:
      return 'client';
  }
};

export const getMeetingLayoutMode = (
  storeId: string,
  mode: string
): MeetingLayoutMode => {
  switch (mode) {
    case 'presentation':
      return MeetingLayoutMode.PRESENTATION;
    case 'tile':
      return MeetingLayoutMode.TILE;
    default:
      return getDefaultMeetingLayout(storeId) || MeetingLayoutMode.TILE;
  }
};

export const composeMeetingParentClassList = (
  layout?: MeetingLayout,
  viewportType?: ViewportType,
  popupOpened?: boolean,
  documentOpened?: boolean,
  isDocumentController?: boolean,
  canControl?: boolean,
  fullContent?: boolean,
  theme?: 'light' | 'dark'
): string => {
  if (!layout && !viewportType)
    return 'in-meeting client SM SMPortrait normal-mode';

  const hasPopupContent = popupOpened || documentOpened;
  let classList = `in-meeting ${canControl ? 'canControl' : 'client'} `;

  let viewport = 'SM SMPortrait';

  if (viewportType === ViewportType.SM_LANDSCAPE) {
    viewport = 'SM SMLandscape';
  }

  if (viewportType === ViewportType.MD_LANDSCAPE) {
    viewport = 'MD MDLandscape';
  }

  if (viewportType === ViewportType.MD_PORTRAIT) {
    viewport = 'MD MDPortrait';
  }

  classList += viewport;

  if (layout?.presenterIds?.length && layout?.mode !== MeetingLayoutMode.TILE) {
    classList += ' has-presenter';
    if (layout?.presenterIds?.length === 1) {
      classList += ' single-presenter';
    }
  }

  classList += ` ${theme || 'dark'}-theme`;
  let layoutMode = 'normal-mode';

  if (layout?.mode === MeetingLayoutMode.TILE) {
    layoutMode = hasPopupContent
      ? 'normal-mode'
      : layout?.enlargedVideoParticipantId
      ? 'normal-mode'
      : 'tile-mode';
  }

  if (layout?.mode === MeetingLayoutMode.PRESENTATION) {
    layoutMode = 'presentation-mode';
  }

  if (layout?.mode === MeetingLayoutMode.ONE_THIRD) {
    layoutMode = 'normal-mode one-third';
  }
  classList += ' ' + layoutMode;
  if (layout?.hideControls) {
    classList += ' no-controls';
  }

  if (hasPopupContent) {
    classList += ' has-popup';
    if (popupOpened) {
      classList += ' popup-page-opened';
      if (document.body?.className?.includes('modal-pixel-layer'))
        classList += ' modal-pixel-layer';
    }
    if (documentOpened) {
      classList += ' document-opened';
    }
    if (isDocumentController) {
      classList += ' document-controller';
    }
  }

  if (fullContent) {
    classList += ' content-full';
  }

  if (layout?.enlargedVideoParticipantId) {
    classList += ' has-enlarged';
  }

  return classList;
};

const deviceWidthBreakPoint = 768; //standard max mobile width
const deviceHeightBreakPoint = 620;

export const getViewportType = (viewportSize: {
  width: number;
  height: number;
}): ViewportType => {
  const { height, width } = viewportSize;
  const isPortrait = width < height;
  const isSmallLandScape = !isPortrait && height < deviceHeightBreakPoint;
  const isSmallPortrait = isPortrait && width < deviceWidthBreakPoint;

  const isMediumPortrait = isPortrait && width >= deviceWidthBreakPoint;

  if (isSmallPortrait) return ViewportType.SM_PORTRAIT;
  if (isSmallLandScape) return ViewportType.SM_LANDSCAPE;
  if (isMediumPortrait) return ViewportType.MD_PORTRAIT;
  return ViewportType.MD_LANDSCAPE;
};

export const meetingColor = {
  warning: '#D14545',
  success: '#61e70f',
  border: '#383838',
  info: '#4373bc',
  transparentDark: 'rgba(0,0,0,.9)',
  transparentDark2: 'rgba(0,0,0,.7)'
};

export const generateMeetingUrl = (params: {
  storeId: string;
  meetingId: string;
  region?: string;
  role?: MeetingRole;
  source?: Source;
}) => {
  const { storeId, meetingId, region, role, source } = params;

  if (!storeId || !meetingId) return;

  const storeUrl = getVBUrl(storeId);

  let meetingUrl = `${storeUrl}?mode=cockpit&meeting=${meetingId}`;
  if (role) {
    meetingUrl = `${meetingUrl}&role=${getMeetingRoleString(role)}`;
  }
  if (region) {
    meetingUrl = `${meetingUrl}&region=${region}`;
  }

  if (source) {
    meetingUrl = `${meetingUrl}&source=${parseSourceString(source)}`;
  }

  return meetingUrl;
};

export const wrapActionToBeMirroring = (
  dispatch: Dispatch,
  action: AnyAction,
  option?: {
    to: string | string[];
    mirrorOnly?: boolean;
  }
) => {
  if (option) {
    if (typeof option.to === 'string') {
      dispatch(actionSendMirrorAction(action, option.to));
    } else {
      option.to.forEach((to) => {
        dispatch(actionSendMirrorAction(action, to));
      });
    }
  }
  if (!option?.mirrorOnly) {
    dispatch(action);
  }
};

export const isUnsupportedBrowser = () => {
  return isUserOnKakaotalk() || isUserOnLineBrowser() || isUserOnWeChat();
};

export const onMeetingOverlay = (
  dispatch: Dispatch,
  meeting: IMeeting,
  visible: boolean
) => {
  wrapActionToBeMirroring(dispatch, actionMeetingOverlay(visible), { to: '' });
  trySetLocalStorage(meeting?.meetingId + 'overlayImg', `${visible}`);
  logEvent(DID_SHOW_MEETING_OVERLAY, DID_SHOW_MEETING_OVERLAY, {
    meetingId: meeting.meetingId,
    isPresenter: meeting.isPresenter,
    overlayImg: visible
  });
};
