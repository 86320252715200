import { some } from 'lodash';
import {
  chopardChStanBoutiqueId,
  chopardHighJewelleryId,
  inspifyBrandId,
  inspifyTestBrandId
} from '../../config';
import { IMeetUserPermission, MeetUserPermissionType } from '../../interfaces';

export const shouldShowPriceOptions = (storeId: string) =>
  storeId === chopardHighJewelleryId;

export const shouldShowStorybooks = ({
  storeId,
  brandId,
  permissions,
  userType
}: {
  storeId?: string;
  brandId?: string;
  permissions: IMeetUserPermission[];
  userType: string;
}) => {
  if (userType === SALES_STORYBOOK_USER) {
    return true;
  }
  if (!reqMeetUserType.includes(userType)) {
    return (
      brandId === inspifyBrandId ||
      storeId === chopardChStanBoutiqueId ||
      brandId === inspifyTestBrandId
    );
  }
  return some(
    permissions,
    (permission) => MeetUserPermissionType.STORYBOOK === permission.name
  );
};
export const SALES = 'sales';
export const MEET_USER = 'meet_user';
export const MEET_ADMIN_USER = 'meet_admin_user';
export const SALES_STORYBOOK_USER = 'sales_storybook_user';

export const reqMeetUserType = [MEET_ADMIN_USER, MEET_USER];
export const shouldShowMenuItem = (
  reqPermissionType: MeetUserPermissionType,
  permissions: IMeetUserPermission[],
  meetUserType?: string
) => {
  if (meetUserType && !reqMeetUserType.includes(meetUserType)) {
    return true;
  }
  return some(
    permissions,
    (permission) => reqPermissionType === permission.name
  );
};

export const isInspifyTestBrand = (brandId) => {
  return inspifyTestBrandId === brandId;
};

export const isInspifyBrand = (brandId) => {
  return [inspifyTestBrandId, inspifyBrandId].includes(brandId);
};
