import { IStorybook } from './../interfaces/index';
import { AxiosResponse } from 'axios';
import httpClient from './http';

export const getPublishedStorybookById = (body: {
  id: string;
  passcode?: string;
  isSyncAllPages?: boolean;
  syncPageIdx?: number[];
}): Promise<AxiosResponse<IStorybook>> =>
  httpClient.post(`/api/storybook`, body);

export const getActivedStorybookById = (
  id: string
): Promise<AxiosResponse<IStorybook>> => {
  return httpClient.post(`/api/activedStorybook`, { id });
};
