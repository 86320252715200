import { PageView } from '../../utils/pageView';

export enum StreamConnectionStatus {
  INITIAL = 'INITIAL',
  PAGE_LOADED = 'PAGE_LOADED',
  FINDING_SERVER = 'FINDING_SERVER',
  SERVER_FOUND = 'SERVER_FOUND',
  SERVER_NOT_FOUND = 'SERVER_NOT_FOUND',
  CONNECTING = 'CONNECTING',
  CONNECTING_ERROR = 'CONNECTING_ERROR',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  DISCONNECTED_BY_AFK = 'DISCONNECTED_BY_AFK',
  DISCONNECTED_BY_PING_TIMEOUT = 'DISCONNECTED_BY_PING_TIMEOUT',
  ICE_DISCONNECTED = 'ICE_DISCONNECTED'
}

export enum StreamPlayerStatus {
  NEED_INTERACTION = 'NEED_INTERACTION',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED'
}

export interface PSServer {
  host: string;
  protocol: string;
  storeId?: string;
}

export interface IPSState {
  connectionStatus?: StreamConnectionStatus;
  server?: PSServer;
  playerStatus?: StreamPlayerStatus;
  focusedObject?: IPSHotspot;
}

export enum EmitType {
  IFrameRequest = 'IFrameRequest',
  RequestQualityControl = 'RequestQualityControl',
  FpsRequest = 'FpsRequest',
  AverageBitrateRequest = 'AverageBitrateRequest',
  StartStreaming = 'StartStreaming',
  StopStreaming = 'StopStreaming',
  LatencyTest = 'LatencyTest',
  RequestInitialSettings = 'RequestInitialSettings',
  UIInteraction = 'UIInteraction',
  Command = 'Command',
  KeyDown = 'KeyDown',
  KeyUp = 'KeyUp',
  KeyPress = 'KeyPress',
  MouseEnter = 'MouseEnter',
  MouseLeave = 'MouseLeave',
  MouseDown = 'MouseDown',
  MouseUp = 'MouseUp',
  MouseMove = 'MouseMove',
  MouseWheel = 'MouseWheel',
  TouchStart = 'TouchStart',
  TouchEnd = 'TouchEnd',
  TouchMove = 'TouchMove',
  GamepadButtonPressed = 'GamepadButtonPressed',
  GamepadButtonReleased = 'GamepadButtonReleased',
  GamepadAnalog = 'GamepadAnalog',
  Config = 'Config'
}

export enum PSConfigCommand {
  ENABLE_AFK = `ENABLE_AFK`,
  DISABLE_AFK = 'DISABLE_AFK',
  SHOW_CONFIG = 'SHOW_CONFIG',
  DISCONNECT = 'DISCONNECT',
  SHOW_VERSION = 'SHOW_VERSION',
  ENABLE_FAKE_MOUSE = 'ENABLE_FAKE_MOUSE',
  DISABLE_FAKE_MOUSE = 'DISABLE_FAKE_MOUSE',
  SHOW_GRID = 'SHOW_GRID',
  HIDE_GRID = 'HIDE_GRID'
}

export enum MouseButton {
  MainButton = 'MainButton', // Left button.
  AuxiliaryButton = 'AuxiliaryButton', // Wheel button.
  SecondaryButton = 'AuxiliaryButton', // Right button.
  FourthButton = 'AuxiliaryButton', // Browser Back button.
  FifthButton = 'AuxiliaryButton' // Browser Forward button.
}

export enum CHScenes {
  SCENE_LOBBY = 'SCENE_LOBBY',
  SCENE_CHAPTER1 = 'SCENE_CHAPTER1',
  SCENE_CHAPTER2 = 'SCENE_CHAPTER2',
  SCENE_CHAPTER3 = 'SCENE_CHAPTER3',
  SCENE_CHAPTER4 = 'SCENE_CHAPTER4',
  SCENE_CHAPTER5 = 'SCENE_CHAPTER5',
  SCENE_CHAPTER6 = 'SCENE_CHAPTER6',
  SCENE_ENTRANCE = 'START_EXPERIENCE',
  SCENE_LOADING_CHAPTER = 'SCENE_LOADING_CHAPTER',
  SCENE_LANDING = 'SCENE_LANDING', //on finding server,
  SCENE_ENTER_TO_CONNECT = 'SCENE_ENTER_TO_CONNECT', //on ps iframe loaded
  SCENE_TUTORIAL = 'SCENE_TUTORIAL' //on ps iframe loaded
}
export type IPSHotspotType = PageView | 'video' | 'text';
export interface IPSHotspot {
  type: IPSHotspotType;
  id: string;
  idKo?: string;
}

export enum PSEventType {
  CONNECTION_STATUS = 'CONNECTION_STATUS',
  UE_EVENT = 'UE_EVENT',
  PLAYER_STATUS = 'PLAYER_STATUS',
  DEBUG = 'DEBUG'
}
