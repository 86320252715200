import { getFormattedDate } from '../utils/clock';
import {
    getCurrentHost,
    isOnboardingBrand,
    isOnClientSide
} from '../utils/window';

export const IPStackApiKey = '5110837e247b8be749dee17ceb09f193';

export const isChina = process.env.NEXT_PUBLIC_APP_REGION === 'cn';

export const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'production';
export const isStaging = process.env.NEXT_PUBLIC_APP_ENV === 'staging';

// If it's running on China server, use Chinese URL
// Else, if there is region for China, use Chinese URL
// Else use global URL

const isRegionSetInChina = () =>
  location?.search?.includes('region=china') ||
  location?.search?.includes('region=cn');

export const videoBaseUrl = (function () {
  if (isChina) {
    return 'https://videos.inspify.cn';
  }
  if (isOnClientSide() && isRegionSetInChina()) {
    return 'https://videos.inspify.cn';
  }
  return 'https://videos.inspify.com';
})();

export const assetBaseUrl = (function () {
  if (isChina) {
    return 'https://assets.inspify.cn';
  }
  if (isOnClientSide() && isRegionSetInChina()) {
    return 'https://assets.inspify.cn';
  }
  return 'https://assets.inspify.com';
})();

export const storyAssetBaseUrl = (function () {
  if (isChina) {
    return 'https://stories.inspify.cn';
  }
  if (isOnClientSide() && isRegionSetInChina()) {
    return 'https://stories.inspify.cn';
  }
  return 'https://stories.inspify.io';
})();

export const panoBaseUrl = (function () {
  if (isChina) {
    return 'https://panos2.inspify.cn';
  }
  if (isOnClientSide() && isRegionSetInChina()) {
    return 'https://panos2.inspify.cn';
  }
  return 'https://panos2.inspify.io';
})();

export const staticBaseUrl = (function () {
  if (isChina) {
    return 'https://static.inspify.cn';
  }
  if (isOnClientSide() && isRegionSetInChina()) {
    return 'https://static.inspify.cn';
  }
  return 'https://static.inspify.io';
})();

export const sgApiBaseUrl =
  'https://hmux9j27n4.execute-api.ap-southeast-1.amazonaws.com/dev';
const cnApiBaseUrl =
  'https://b0dzshgz23.execute-api.cn-northwest-1.amazonaws.com.cn/prod';
export const baseUrl = isChina ? cnApiBaseUrl : sgApiBaseUrl;

export const chatWsUrl =
  'wss://p77p1t3fnk.execute-api.ap-southeast-1.amazonaws.com/prod';

const cnRegion = 'cn-northwest-1';
export const sgRegion = 'ap-southeast-1';
export const region = isChina ? cnRegion : sgRegion;
export const s3BaseUrl = isChina
  ? `s3.${region}.amazonaws.com.cn`
  : `s3.${region}.amazonaws.com`;
export const appDomain = isChina ? `inspify.cn` : `inspify.io`;
export const siteName = 'INSPIFY';
export const failedToLoadImagePlaceholder = `https://inspify-public.${s3BaseUrl}/placeholder.png`;

export const sgStagingHost = 'meet-uat.inspify.io';
export const euProdHost = 'meet-eu.inspify.io';
export const eu2ProdHost = 'meet-eu2.inspify.io';
export const usProdHost = 'meet-us.inspify.io';
export const us2ProdHost = 'meet-us2.inspify.io';
export const apProdHost = 'meet-ap.inspify.io';
export const ap2ProdHost = 'meet-ap2.inspify.io';
export const cnProdHost = 'meet.inspify.cn';

// prettier-ignore
export type JitsiServerRegion = 'us' | 'eu' | 'ap' | 'cn' | 'us2' | 'eu2' | 'ap2';

export const iwcBrandId = 'fe37f5a8-5648-4930-9e66-5c55e9b26dfd';
export const alsBrandId = '33e8880d-64c0-4a84-afcf-de8d5511cc10';
export const piagetBrandId = '3de1a511-8a84-458b-80f5-ee73269246f6';
export const jlcBrandId = 'edccdb6b-31c9-4200-b679-6d195a6beccc';
export const lvBrandId = '10d7a048-a1f3-46b1-ba4b-ad1d448fd6ab';
export const rduBrandId = '4bba131d-6238-4c54-b4e4-506bc199d13d';
export const louboutinBrandId = '476bd411-03e9-4e6f-8c8b-8c51d1e0f5e1';
export const zegnaBrandId = '4a6f65c4-92d8-4c85-a907-da3cb61842c6';
export const chopardBrandId = 'e2a99213-7ee9-4c0b-9169-b751098d458f';
export const inspifyBrandId = '1bc913e3-faf3-47c3-a097-074905a292fd';
export const vacBrandId = 'e0f98b18-4e8e-474a-8c99-d0afe9ad4eb5';
export const cartierBrandId = '24243547-b586-48d0-a03f-06ef684e5b4c';
export const vanCleefArpelsBrandId = 'bd530325-565c-4323-b8f7-caacca4ad9a0';
export const aspreyBrandId = '4bba131d-b24b-4bbb-bc32-b4da62f51df8';
export const tiffanyBrandId = '2886a335-2075-44c2-bdf5-16e62ee7f673';
export const gustoLuxeBrandId = '52695cf8-9ea9-4d02-8dc5-3b0a1bdefcb3';
export const inspifyTestBrandId = '65c50009-143d-4325-a40a-78aebff5e43b';

export const inspifySpaceId = '4d2cdb58-4650-4ff1-9495-037ddc253486';

type BrandConfig = {
  id: string;
  name: string;
  host: string;
  isNotPreConfigured?: boolean;
}[];

export const getBrandStorybookHost = (brandId: string) => {
  // TODO: Move to allBrands
  // This really depends on how big the scope creeps.
  // If it's strictly for storybook only, then I'd add an optional field `storybookHost` in `BrandConfig`,
  // and only configure for INSPIFY brand,
  // then fetch the storybook host from config.
  // For now, I'm just putting the logic here locally.
  // E.g. const brandId = getStorybookHost(brandId) || getCurrentHost();
  const currentHost = getCurrentHost();
  if (currentHost?.includes(UAT_HOST) || !isProd) {
    return currentHost;
  }
  return brandId === inspifyBrandId ? 'https://app.inspify.com' : currentHost;
};

export const getPresentBrandSBHost = (brandId: string) => {
  // TODO: Move to allBrands
  // This really depends on how big the scope creeps.
  // If it's strictly for storybook only, then I'd add an optional field `storybookHost` in `BrandConfig`,
  // and only configure for INSPIFY brand,
  // then fetch the storybook host from config.
  // For now, I'm just putting the logic here locally.
  // E.g. const brandId = getStorybookHost(brandId) || getCurrentHost();
  return brandId === inspifyBrandId
    ? 'https://meet.inspify.com'
    : getCurrentHost();
};

export const allBrands: BrandConfig = [
  {
    id: iwcBrandId,
    name: 'IWC Schaffhausen',
    host: 'iwc.v-boutique.com'
  },
  {
    id: alsBrandId,
    name: 'A. Lange & S\u00f6hne',
    host: 'alange-soehne.v-boutique.com'
  },
  {
    id: piagetBrandId,
    name: 'Piaget',
    host: 'piaget.v-boutique.com'
  },
  {
    id: lvBrandId,
    name: 'Louis Vuitton',
    host: 'louisvuitton.v-boutique.com'
  },
  {
    id: jlcBrandId,
    name: 'Jaeger Le-Coultre',
    host: 'jaeger-lecoultre.v-boutique.com'
  },
  {
    id: rduBrandId,
    name: 'Roger Dubuis',
    host: 'rogerdubuis.v-boutique.com'
  },
  {
    id: zegnaBrandId,
    name: 'Ermenegildo Zegna',
    host: 'zegna.v-boutique.com'
  },
  {
    id: chopardBrandId,
    name: 'Chopard',
    host: 'chopard.v-boutique.com'
  },
  {
    id: chopardBrandId,
    name: 'Chopard',
    host: 'experience.chopard.com'
  },
  {
    id: inspifyBrandId,
    name: 'INSPIFY',
    host: 'meet.inspify.com'
  },
  {
    id: inspifyBrandId,
    name: 'INSPIFY',
    host: 'app.inspify.com'
  },
  {
    id: inspifyTestBrandId,
    name: 'INSPIFY',
    host: 'meet-test.inspify.com'
  },
  {
    id: vacBrandId,
    name: 'Vacheron Constantin',
    host: 'vacheron-constantin.v-boutique.com'
  },
  {
    id: cartierBrandId,
    name: 'Cartier',
    host: 'cartier.v-boutique.com'
  },
  {
    id: aspreyBrandId,
    name: 'Asprey',
    host: 'asprey.v-boutique.com'
  },
  {
    id: vanCleefArpelsBrandId,
    name: 'Van Cleef Arpels',
    host: 'vancleefarpels.v-boutique.com'
  },
  {
    id: tiffanyBrandId,
    name: 'Tiffany & Co.',
    host: 'tiffany.v-boutique.com'
  },
  {
    id: gustoLuxeBrandId,
    name: 'Gusto Luxe',
    host: 'gusto-luxe.inspify.com'
  }
];

export const getBrandNameById = (brandId: string) => {
  return allBrands.find((brand) => brandId === brand.id)?.name;
};

export const getBrandIdByHost = (host: string) => {
  return allBrands.find((brand) => host === brand.host)?.id;
};

export const rogerDubuisUaeDubaiId = 'cabe522f-6c37-46fe-af53-9ebcd7a0c39c';
export const rogerDubuisChGenevaId = '2cf7bdc5-fb91-4081-a450-549dbaf7daa4';
export const rogerDubuisUKLondonId = 'e0db105d-76d8-446d-8b14-813fc5345a9c';
export const rogerDubuisCnBeijingId = 'f788d334-a088-4bfe-8f4f-ae9dc78c562c';
export const rogerDubuisCnChengduId = 'e2c2f142-c263-4395-85a3-958b8b5b537b';
export const rogerDubuisCnShanghaiId = '0b9fd9bf-7acd-4a3d-9f5b-d1dfe8eff2fc';
export const rogerDubuisCnShenzhenId = '0ac8f5ee-3f2f-4f44-9558-07b050056adb';
export const rogerDubuisCnXianId = '883feabc-4372-4724-8589-00e7c1f9fde3';
export const rogerDubuisCnHangzhouId = '431edcca-62e7-432e-9604-60e9bbea1432';
export const rogerDubuisHkPacificPlaceId =
  '40167c79-5624-4375-8ff8-6569d4aae8b2';
export const rogerDubuisHkOtId = 'b019386c-657b-4bd7-918c-61973939ec1d';
export const rogerDubuisMoGalaxyId = '1d13a373-d4c8-46a4-8e9d-03701f2ba5b5';
export const rogerDubuisMoWynnpalaceId = 'd3763a22-301c-4a71-b65e-d50c51ff51d2';
export const rogerDubuisUsBeverlyHillsId =
  '8ec9d9ca-e2a9-4f82-8798-6320fba4fafb';
export const rogerDubuisUsNycId = '24976583-26d9-4777-bb3a-8c8ce9392af2';
export const rogerDubuisKrAvenuelId = 'c0fb2f3a-f073-4a8d-9844-0c9177557df9';
export const rogerDubuisKrWorldtowerId = '283695eb-5f9a-4394-8001-81a2f507b557';
export const rogerDubuisKrGangnamId = '3f115927-f479-4ec2-9eff-6d5edb13f823';
export const rogerDubuisTwTaipeiId = '0fc37eda-3488-4daf-bf24-0fba6943fd07';
export const rogerDubuisAuSydneyId = '16d91c3f-086d-4f86-ae90-cc45264c4e27';
export const rogerDubuisJpTokyoId = 'd39bb277-c2f5-465e-8b2b-71ef44cfd5a7';
export const rogerDubuisJpFukuokaId = 'd4ad8ea8-1237-4f88-b232-babb23399fec';
export const rogerDubuisTbcId = '86749ad9-8aa2-4a3c-a0d4-8321dda06448';
export const rogerDubuisSgIonId = '8e54958b-e7b2-4593-b681-8c2694947b2d';
export const rogerDubuisSgMbsId = '759663d2-a79e-4d5d-a155-a1e29d76f563';

export const isRDULite = (storeId: string) =>
  [
    rogerDubuisHqId,
    rogerDubuisUaeDubaiId,
    rogerDubuisChGenevaId,
    rogerDubuisUKLondonId,
    rogerDubuisCnBeijingId,
    rogerDubuisCnChengduId,
    rogerDubuisCnShanghaiId,
    rogerDubuisCnShenzhenId,
    rogerDubuisCnXianId,
    rogerDubuisCnHangzhouId,
    rogerDubuisHkPacificPlaceId,
    rogerDubuisHkOtId,
    rogerDubuisMoGalaxyId,
    rogerDubuisMoWynnpalaceId,
    rogerDubuisUsBeverlyHillsId,
    rogerDubuisUsNycId,
    rogerDubuisKrAvenuelId,
    rogerDubuisKrWorldtowerId,
    rogerDubuisKrGangnamId,
    rogerDubuisTwTaipeiId,
    rogerDubuisAuSydneyId,
    rogerDubuisJpTokyoId,
    rogerDubuisJpFukuokaId,
    rogerDubuisTbcId,
    rogerDubuisSgIonId,
    rogerDubuisSgMbsId
  ].includes(storeId);

export const louisVuittonBoutiqueId = 'd3b20960-7481-4ebe-8d2c-96f24305a910';
export const piagetSgBoutiqueId = 'a2205d4b-eb8c-47f2-bf56-e30d716720e1';
export const piagetMyBoutiqueId = '31a0173c-643b-43fa-a4c2-fbc63a734962';
export const piagetAuBoutiqueId = 'c05cc82e-f803-4851-91f0-2925061d08a4';
export const piagetVnBoutiqueId = 'bc57587b-9ce9-43de-90c3-e035192d7739';
export const piagetGenevaBoutiqueId = 'a1186f9d-9f4d-471a-964c-90d47ffb4b13';
export const iwcSgIonBoutiqueId = '316261f4-fbf6-49e2-88cf-e4d166f79267';
export const iwcSgIonSalesLoftSiteId = '03095eac-96bc-4ad4-acc7-04e0738c7b55';
export const iwcSgIonBoutiqueIdV2 = 'a1cf2986-7493-4559-9ab3-e07dac90c501';

export const iwcSgIonBoutiqueIdV3 = 'ba7ae1fe-424c-4c22-a538-83fe2e173ab9';
export const iwcSgMbsBoutiqueId = '93ac013c-4626-4fed-a38a-c94f08bdb926';

export const iwcUaeDubaiBoutiqueId = '844cc92c-8783-48a5-bb06-e37a093e0bbd';
export const iwcSihh2020BoutiqueId = '9ed05ed1-cd1a-475f-bbf1-40c32915efa';
export const iwcChZurichBoutiqueId = '02e6b046-19a0-4f86-8cf2-9162b5bdbb28';
export const iwcChZurichVirtualBoutiqueId =
  '984bc244-abf6-414d-b9db-a0992d5a3eee';
export const iwcChSchaffhausenBoutiqueId =
  '64bb1118-c951-4e45-ac5c-c8cb0ff8a5d0';
export const iwcThaiBangkokBoutiqueId = 'e433b0f0-ea8b-4feb-b4b8-9b4dff7bf564';
export const iwcUkLondonBoutiqueId = 'cc972f97-10dd-489a-bc3f-c83b80894be2';
export const iwcUsMiamiBoutiqueId = '569ea26b-ac93-41c6-bcff-a988944bd4e0';
export const iwcUsRodeoDriveBoutiqueId = 'ff4864da-f8a9-4542-ac6c-88b67412fb9f';
export const iwcUsMadisonBoutiqueId = '14b728b7-af0c-4e8a-b46f-d81b6edebda6';
export const iwcUsBalHarbourBoutiqueId = '35423d9f-dc5f-4bf3-ac9f-b7082223c35f';
export const iwcFranceParisBoutiqueId = '99f869cc-c342-4118-87cf-3958cee0c6d1';
export const iwcGermanyMunichBoutiqueId =
  'c9504377-7449-4ce8-a6b8-4e79b42216f9';
export const iwcCanadaTorontoBoutiqueId =
  '00794093-7b88-421c-afe0-12f921a3c48a';
export const iwcSpainMadridBoutiqueId = '47f0410b-bf83-4f1f-8ccd-09edf9c0ba99';
export const iwcItalyMilanBoutiqueId = 'd02697ec-6707-43a1-bd6a-cf73457c73ad';
export const iwcUsCostamesaSiteId = '4f3aafb6-0a79-4b31-ba96-3ffeb93cac50';
export const iwcChGenevaSiteId = '134adc59-1776-40bc-aede-c26239f9b476';
export const iwcUsCrcSiteId = '41273d30-1e89-4dba-a14c-3ef8e7e36173';
export const iwcCanadaVancouverSiteId = 'd8089192-413f-4ee3-8639-7f804ff9b84b';
export const iwcNlAmsterdamSiteId = 'de8fdf1b-a323-485b-9e5b-f1f1c5f22288';
export const iwcBrazilSiteId = 'e86d50b0-2cdc-4f80-94a8-ebd4f6013a23';
export const iwcWW2021Id = 'c3f011ac-c437-4fd6-8c3d-6b6128f3139a';
export const alsSgIonBoutiqueId = '13c68da7-ff4c-44b9-930c-b9e8e031a7fd';
export const jlcAuMelbourneBoutiqueId = '0bc6b25d-84a0-405d-bc46-1e7e816ba5c1';
export const rogerDubuisSgBoutiqueId = '6578a5b9-0382-4ebc-a4b5-ebeb4a5408a5';
export const rogerDubuisWW2021Id = '68f1c557-3873-4069-83e7-ec6b0e7a7985';
export const rogerDubuisWholeSaleId = '10aadba6-b10f-4e67-a018-d2b22f0ae3e9';
export const rogerDubuisHqId = '63e328ef-ed8c-4766-bab7-5143d45a5470';
export const rogerDubuisRetailId = '51347ada-fe3f-435e-b1e8-a7d8804f5d5e';
export const louboutinFranceParisBoutiqueId =
  'd430a5c8-0857-4398-9dba-622b42262f41';
export const loubiAirwaysBoutiqueId = '0e6ce77d-1e03-40cd-a88e-e0aaf2d00769';
export const loubiAirwaysCnBoutiqueId = '9ceba3cd-f457-4d28-8a8b-a97694a29dea';
export const loubiAirwaysJpBoutiqueId = '6774a7d5-ab88-4c19-8cf8-c56e3315abcf';
export const loubiFutureSiteId = 'ab1cedc7-e050-40b9-bb4c-373f6f6886b8';
export const zegnaUSNewYorkBoutiqueId = '21f228f9-553a-4a26-b483-e47791e9064a';
export const chopardSgNacBoutiqueId = '5fe1729a-b700-40c2-b377-24596fd78264';
export const chopardSgMbsBoutiqueId = 'b7e5c976-f09c-4000-aceb-c06397679149';
export const chopardUkLondonBoutiqueId = '7339f54c-4b21-416b-8fc7-0b87699c0e7c';
export const chopardUkLondonSelfridgesBoutiqueId =
  '45a6efb9-c4cf-4684-b0eb-8dbb816d4788';
export const chopardUkLondonHarrodsBoutiqueId =
  '6b2ac9ff-3573-4968-a40d-c6420d991378';
export const chopardUsNewYorkBoutiqueId =
  'b2fb5f43-cc19-4e4a-a90e-1ec90632833c';
export const chopardChGenevaBoutiqueId = '6e599522-d7f5-4089-b808-e8459c64e2ce';
export const chopardAtViennaBoutiqueId = '714311a6-0f84-452c-925a-3aeb3df8dacb';
export const chopardUsMiamiBoutiqueId = '974fd72b-427f-41ec-b13f-062fe4dc2ba1';
export const chopardWW2021Id = '00047fe9-c7a5-4919-8883-4181b5d4a22c';
export const chopardWW2021DubaiId = '36809fa0-aebb-4dcc-9f76-3cbce9f20a83';
export const chopardWW2021SpainId = '98d2c0d6-e119-43a4-a9f5-da5400d33344';
export const chopardWW2021AustriaId = '196cd9fc-aa38-45be-8922-0bc4ce4398f1';
export const chopardWW2021UkId = '373488bf-08dd-4862-ba71-c90b747e2cf3';
export const chopardWW2021SgId = '4299d348-7e3d-41e6-b61a-ee4eb72051b4';
export const chopardWW2021HkId = '02d3fc38-cad6-4080-83a2-098b71bb2cdc';
export const chopardWW2021GermanyId = '7a227e0a-44c6-4d8a-aaf5-c45ee520b351';
export const chopardWW2021FranceId = '4b779feb-6716-4344-8395-5985ef3363ba';
export const chopardWW2021PressId = '6e6ae5be-58a1-417f-bb46-f036c3a36335';
export const chopardWW2021UsId = 'c49b2b0a-9088-4ca8-a126-0bdfa163d64c';
export const chopardWW2021ItalyId = '4022e094-9ab4-11eb-a8b3-0242ac130003';
export const chopardWW2021AuId = '89d68914-3f0e-4ec7-8edf-74b70e062915';
export const chopardHappySportVirtualExpId =
  '09b12134-191c-4747-a722-97f0618c39c3';
export const chopardHappySportKorea = 'b3f4668b-a415-4123-b9c4-f0c36050afe2';
export const chopardHappySportGenevaHQ = '812dfb4d-9df4-4e03-a5ef-d262a03b719e';
export const chopardHappySportGenevaHQTraining =
  'ee3abfc5-eda6-4b4c-ad19-c834d39a352c';
export const chopardHighJewelleryId = 'afd080e7-1a4b-4d41-9f26-737a9ca39eac';
export const chopardChStanBoutiqueId = 'ba0325ba-e556-4b16-81ec-32502485b843';
export const inspifySiteId = '88f684ae-3c27-40fc-82b7-6bd02634d44b';
export const inspifyInverseSiteId = '147b53a2-5544-4267-9ef8-51aec33e0da5';
export const inspifyTestingSiteId = '5de75140-048d-40ef-940a-c0b66fd32e3a';
export const inspifyGCCSiteId = '6c2e331a-2e6f-4944-af2f-a7a135039c69';
export const inspifyJPSilkRoadSiteId = '1c5b2d25-7c63-45e4-998a-965f46bd8091';
export const inspifyTestBrandSiteId = 'aa4701fc-2d32-4789-a413-aff937cc4423';
export const vacSgBoutiqueId = '7089d3d2-e34f-4c66-b4ff-639202e17308';
export const vacMarinaBaySandsBoutiqueId =
  '9c7ea1f8-32cd-454c-923c-d1a394e4197f';
export const cartierUsMiamiSiteId = '95c28c72-bd65-43d3-b379-9a00263a4511';
export const cartierUsBeverlyHillsSiteId =
  'a055d8fb-e742-4113-bbca-3ce4e9169c91';
export const cartierUsNewYorkSiteId = 'f2eb4a73-54d7-4c83-b073-d010e3b4e2b1';
export const vanCleefArpelsUsBeverlyHillsSiteId =
  '872c111d-0efa-4e11-8532-28556a348450';
export const vanCleefArpelsUsSiliconValleyFairSiteId =
  'c395c4b3-18a6-4a22-bdb1-0bb2fe830d32';
export const vanCleefArpelsUsKingOfPrussiaSiteId =
  '332bbec5-b725-475c-8bfa-fbce0a5791a7';
export const aspreyUkLondonSiteId = '3a9aecc1-9e9d-4337-8875-6e0dc90c7129';
export const tiffanyUsNycId = '67dc311c-1bde-439b-8fb0-ee9c988da0b1';

export const gustoLuxeSiteId = '6477b865-3864-42b8-a922-db88f40aabe9';
export const mercedesBenzSiteId = '56f3c4c9-04c3-479d-8742-a19902a51621';
export const mercedesBenzStoreId = '98534392-6d2e-41b7-95b9-654eed9ceadc';
export const boutiquesWithBoshDataChannel = [
  piagetSgBoutiqueId,
  iwcSgIonBoutiqueId,
  iwcUaeDubaiBoutiqueId,
  iwcThaiBangkokBoutiqueId,
  alsSgIonBoutiqueId,
  jlcAuMelbourneBoutiqueId
];

export const getSspWebviewUrlByStoreId = (storeId: string): string => {
  if (storeId === iwcSgIonBoutiqueId) {
    return `https://iwc.v-boutique.com/sg/loft`;
  }
  return allBoutiqueUrl[storeId]?.salesLoft;
};

export const getJitsiRegionByString = (
  region: string
): JitsiServerRegion | undefined =>
  (['eu', 'eu2', 'us', 'us2', 'ap', 'ap2', 'cn'] as JitsiServerRegion[]).find(
    (r) => r === region?.toLowerCase()
  );

export const getProdHostForStore = (storeId: string): string => {
  switch (storeId) {
    case iwcCanadaTorontoBoutiqueId:
    case iwcUsMiamiBoutiqueId:
    case iwcUsRodeoDriveBoutiqueId:
    case iwcUsBalHarbourBoutiqueId:
    case iwcUsMadisonBoutiqueId:
    case zegnaUSNewYorkBoutiqueId:
    case chopardUsMiamiBoutiqueId:
    case chopardWW2021UsId:
    case chopardUsNewYorkBoutiqueId:
    case iwcUsCostamesaSiteId:
    case iwcUsCrcSiteId:
    case iwcCanadaVancouverSiteId:
    case cartierUsMiamiSiteId:
    case cartierUsNewYorkSiteId:
    case cartierUsBeverlyHillsSiteId:
    case vanCleefArpelsUsBeverlyHillsSiteId:
    case vanCleefArpelsUsSiliconValleyFairSiteId:
    case vanCleefArpelsUsKingOfPrussiaSiteId:
    case iwcBrazilSiteId:
    case rogerDubuisUsBeverlyHillsId:
    case rogerDubuisUsNycId:
    case tiffanyUsNycId:
      return usProdHost;
    case iwcUkLondonBoutiqueId:
    case iwcFranceParisBoutiqueId:
    case iwcGermanyMunichBoutiqueId:
    case iwcSpainMadridBoutiqueId:
    case iwcItalyMilanBoutiqueId:
    case iwcChZurichBoutiqueId:
    case iwcChZurichVirtualBoutiqueId:
    case iwcChSchaffhausenBoutiqueId:
    case iwcNlAmsterdamSiteId:
    case iwcWW2021Id:
    case chopardUkLondonBoutiqueId:
    case chopardUkLondonSelfridgesBoutiqueId:
    case chopardUkLondonHarrodsBoutiqueId:
    case chopardChGenevaBoutiqueId:
    case chopardAtViennaBoutiqueId:
    case chopardWW2021Id:
    case chopardHighJewelleryId:
    case chopardWW2021SpainId:
    case chopardWW2021AustriaId:
    case chopardWW2021UkId:
    case chopardWW2021GermanyId:
    case chopardWW2021PressId:
    case chopardWW2021FranceId:
    case chopardWW2021ItalyId:
    case chopardHappySportVirtualExpId:
    case chopardChStanBoutiqueId:
    case louboutinFranceParisBoutiqueId:
    case loubiAirwaysBoutiqueId:
    case rogerDubuisWW2021Id:
    case rogerDubuisWholeSaleId:
    case rogerDubuisRetailId:
    case rogerDubuisHqId:
    case iwcChGenevaSiteId:
    case aspreyUkLondonSiteId:
    case piagetGenevaBoutiqueId:
    case rogerDubuisChGenevaId:
    case rogerDubuisUKLondonId:
    case rogerDubuisTbcId:
    case chopardHappySportGenevaHQ:
    case chopardHappySportGenevaHQTraining:
      return euProdHost;
    case loubiAirwaysCnBoutiqueId:
    case rogerDubuisCnBeijingId:
    case rogerDubuisCnChengduId:
    case rogerDubuisCnShanghaiId:
    case rogerDubuisCnShenzhenId:
    case rogerDubuisCnXianId:
    case rogerDubuisCnHangzhouId:
      return cnProdHost;
    case jlcAuMelbourneBoutiqueId:
    case rogerDubuisAuSydneyId:
      return ap2ProdHost;
    default:
      return apProdHost;
  }
};

export const getRegionForStore = (storeId: string) => {
  const host = getProdHostForStore(storeId);
  switch (host) {
    case usProdHost:
      return 'us';
    case us2ProdHost:
      return 'us2';
    case euProdHost:
      return 'eu';
    case eu2ProdHost:
      return 'eu2';
    case cnProdHost:
      return 'cn';
    case apProdHost:
      return 'ap';
    case ap2ProdHost:
      return 'ap2';
    default:
      return '';
  }
};

export const getStagingHostForStore = (storeId: string) => {
  switch (storeId) {
    case iwcCanadaTorontoBoutiqueId:
    case zegnaUSNewYorkBoutiqueId:
    case chopardUsMiamiBoutiqueId:
    case chopardWW2021UsId:
    case chopardUsNewYorkBoutiqueId:
    case iwcUsMiamiBoutiqueId:
    case iwcUsRodeoDriveBoutiqueId:
    case iwcUsBalHarbourBoutiqueId:
    case iwcUsMadisonBoutiqueId:
    case iwcUsCostamesaSiteId:
    case iwcUsCrcSiteId:
    case iwcCanadaVancouverSiteId:
    case cartierUsMiamiSiteId:
    case cartierUsNewYorkSiteId:
    case cartierUsBeverlyHillsSiteId:
    case vanCleefArpelsUsBeverlyHillsSiteId:
    case vanCleefArpelsUsSiliconValleyFairSiteId:
    case vanCleefArpelsUsKingOfPrussiaSiteId:
    case iwcBrazilSiteId:
    case rogerDubuisUsBeverlyHillsId:
    case rogerDubuisUsNycId:
    case tiffanyUsNycId:
      return usProdHost;
    case iwcUkLondonBoutiqueId:
    case iwcFranceParisBoutiqueId:
    case iwcGermanyMunichBoutiqueId:
    case iwcSpainMadridBoutiqueId:
    case iwcItalyMilanBoutiqueId:
    case iwcChZurichBoutiqueId:
    case iwcChZurichVirtualBoutiqueId:
    case iwcChSchaffhausenBoutiqueId:
    case chopardUkLondonBoutiqueId:
    case chopardUkLondonSelfridgesBoutiqueId:
    case chopardUkLondonHarrodsBoutiqueId:
    case chopardChGenevaBoutiqueId:
    case chopardAtViennaBoutiqueId:
    case chopardWW2021Id:
    case chopardHighJewelleryId:
    case chopardWW2021SpainId:
    case chopardWW2021AustriaId:
    case chopardWW2021UkId:
    case chopardWW2021GermanyId:
    case chopardWW2021ItalyId:
    case chopardHappySportVirtualExpId:
    case chopardChStanBoutiqueId:
    case louboutinFranceParisBoutiqueId:
    case loubiAirwaysBoutiqueId:
    case iwcChGenevaSiteId:
    case iwcNlAmsterdamSiteId:
    case aspreyUkLondonSiteId:
    case piagetGenevaBoutiqueId:
    case rogerDubuisChGenevaId:
    case rogerDubuisUKLondonId:
    case rogerDubuisTbcId:
    case chopardHappySportGenevaHQ:
    case chopardHappySportGenevaHQTraining:
      return euProdHost;
    case loubiAirwaysCnBoutiqueId:
    case rogerDubuisCnBeijingId:
    case rogerDubuisCnChengduId:
    case rogerDubuisCnShanghaiId:
    case rogerDubuisCnShenzhenId:
    case rogerDubuisCnXianId:
    case rogerDubuisCnHangzhouId:
      return cnProdHost;
    case iwcWW2021Id:
    case rogerDubuisWW2021Id:
    case rogerDubuisWholeSaleId:
    case rogerDubuisRetailId:
    case rogerDubuisHqId:
    case inspifySiteId:
    case inspifyTestingSiteId:
    case inspifyGCCSiteId:
    case loubiFutureSiteId:
    case vacSgBoutiqueId:
    case vacMarinaBaySandsBoutiqueId:
      return sgStagingHost;
    case jlcAuMelbourneBoutiqueId:
    case rogerDubuisAuSydneyId:
      return ap2ProdHost;
    default:
      return apProdHost;
  }
};

const chopardVBLiteList = [
  chopardSgMbsBoutiqueId,
  chopardSgNacBoutiqueId,
  chopardUkLondonBoutiqueId,
  chopardUkLondonSelfridgesBoutiqueId,
  chopardUkLondonHarrodsBoutiqueId,
  chopardUsNewYorkBoutiqueId,
  chopardUsMiamiBoutiqueId,
  chopardChGenevaBoutiqueId,
  chopardAtViennaBoutiqueId
];

const chopardWW2021List = [
  chopardWW2021Id,
  chopardWW2021DubaiId,
  chopardWW2021SpainId,
  chopardWW2021AustriaId,
  chopardWW2021UkId,
  chopardWW2021SgId,
  chopardWW2021HkId,
  chopardWW2021GermanyId,
  chopardWW2021FranceId,
  chopardWW2021PressId,
  chopardWW2021UsId,
  chopardWW2021ItalyId,
  chopardWW2021AuId,
  chopardHighJewelleryId,
  chopardHappySportGenevaHQTraining
];

const allChopardList = [
  ...chopardVBLiteList,
  ...chopardWW2021List,
  chopardHappySportVirtualExpId,
  chopardHappySportKorea,
  chopardHappySportGenevaHQ,
  chopardChStanBoutiqueId
];

export const isChopard = (storeId?: string): boolean => {
  if (!storeId) return false;
  return storeId === chopardBrandId || allChopardList.includes(storeId);
};

export const isChopardVBLite = (storeId: string): boolean => {
  return chopardVBLiteList.includes(storeId);
};

export const isChopardWW2021 = (storeId: string): boolean => {
  return chopardWW2021List.includes(storeId);
};

// Does not depend upon firewall policy
export const getProdHostForRegion = (region: JitsiServerRegion) => {
  switch (region) {
    case 'eu':
      return euProdHost;
    case 'eu2':
      return eu2ProdHost;
    case 'us':
      return usProdHost;
    case 'us2':
      return us2ProdHost;
    case 'ap':
      return apProdHost;
    case 'ap2':
      return ap2ProdHost;
    case 'cn':
      return cnProdHost;
    default:
      return apProdHost;
  }
};

export const getStagingHostForRegion = (region: JitsiServerRegion) => {
  switch (region) {
    case 'eu':
      return euProdHost;
    case 'eu2':
      return eu2ProdHost;
    case 'us':
      return usProdHost;
    case 'us2':
      return us2ProdHost;
    case 'ap':
      return apProdHost;
    case 'ap2':
      return ap2ProdHost;
    case 'cn':
      return cnProdHost;
    default:
      return sgStagingHost;
  }
};

export const allBoutiqueUrl: {
  [key: string]: { vb?: string; salesLoft?: string };
} = {
  [louisVuittonBoutiqueId]: {
    vb: 'https://louisvuitton.v-boutique.com/sg'
  },
  [piagetSgBoutiqueId]: {
    vb: 'https://piaget.v-boutique.com/sg'
  },
  [piagetMyBoutiqueId]: {
    vb: 'https://piaget.v-boutique.com/my'
  },
  [piagetVnBoutiqueId]: {
    vb: 'https://piaget.v-boutique.com/vn'
  },
  [piagetAuBoutiqueId]: {
    vb: 'https://piaget.v-boutique.com/au'
  },
  [piagetGenevaBoutiqueId]: {
    salesLoft: 'https://piaget.v-boutique.com/geneva'
  },
  [iwcSgIonBoutiqueId]: {
    vb: 'https://iwc.v-boutique.com/sg'
  },
  [iwcSgIonBoutiqueIdV2]: {
    vb: 'https://iwc.v-boutique.com/sg'
  },
  [iwcSgIonBoutiqueIdV3]: {
    salesLoft: 'https://iwc.v-boutique.com/sg/ion'
  },
  [iwcSgMbsBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/sg/mbs'
  },
  [iwcSgIonSalesLoftSiteId]: {
    salesLoft: 'https://iwc.v-boutique.com/sg/loft'
  },
  [iwcUaeDubaiBoutiqueId]: {
    vb: 'https://iwc.v-boutique.com/uae/dubai'
  },
  [iwcSihh2020BoutiqueId]: {
    vb: 'https://iwc.inspify.cn/sihh2020'
  },
  [iwcChZurichBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/ch/zurich'
  },
  [iwcChZurichVirtualBoutiqueId]: {
    vb: 'https://iwc.v-boutique.com/ch/zurichvb'
  },
  [iwcChGenevaSiteId]: {
    salesLoft: 'https://iwc.v-boutique.com/ch/geneva'
  },
  [iwcThaiBangkokBoutiqueId]: {
    vb: 'https://iwc.v-boutique.com/th'
  },
  [iwcUkLondonBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/uk/london'
  },
  [iwcFranceParisBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/fr/paris'
  },
  [iwcGermanyMunichBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/de/munich'
  },
  [iwcSpainMadridBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/es/madrid'
  },
  [iwcItalyMilanBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/it/milano'
  },
  [iwcChSchaffhausenBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/ch/schaffhausen'
  },
  [iwcCanadaTorontoBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/ca/toronto'
  },
  [iwcCanadaVancouverSiteId]: {
    salesLoft: 'https://iwc.v-boutique.com/ca/vancouver'
  },
  [iwcUsMiamiBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/us/miami'
  },
  [iwcUsRodeoDriveBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/us/rodeodrive'
  },
  [iwcUsMadisonBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/us/madison'
  },
  [iwcUsBalHarbourBoutiqueId]: {
    salesLoft: 'https://iwc.v-boutique.com/us/balharbour'
  },
  [iwcUsCostamesaSiteId]: {
    salesLoft: 'https://iwc.v-boutique.com/us/costamesa'
  },
  [iwcUsCrcSiteId]: {
    salesLoft: 'https://iwc.v-boutique.com/us/crc'
  },
  [iwcWW2021Id]: {
    salesLoft: 'https://iwc.v-boutique.com/ww2021'
  },
  [iwcNlAmsterdamSiteId]: {
    vb: 'https://iwc.v-boutique.com/nl'
  },
  [iwcBrazilSiteId]: {
    vb: 'https://iwc.v-boutique.com/brazil'
  },
  [rogerDubuisWW2021Id]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/ww2021'
  },
  [rogerDubuisWholeSaleId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/wholesale'
  },
  [rogerDubuisHqId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/hq'
  },
  [rogerDubuisRetailId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/retail'
  },
  [alsSgIonBoutiqueId]: {
    vb: 'https://alange-soehne.v-boutique.com/sg'
  },
  [jlcAuMelbourneBoutiqueId]: {
    vb: 'https://jaeger-lecoultre.v-boutique.com/au/melbourne'
  },
  [rogerDubuisSgBoutiqueId]: {
    vb: 'https://rogerdubuis.v-boutique.com/sg'
  },
  [louboutinFranceParisBoutiqueId]: {
    salesLoft: 'https://christianlouboutin.v-boutique.com/fr/paris'
  },
  [zegnaUSNewYorkBoutiqueId]: {
    salesLoft: 'https://zegna.v-boutique.com/us/nyc'
  },
  [chopardSgNacBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/sg/nac'
  },
  [chopardSgMbsBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/sg/mbs'
  },
  [chopardUkLondonBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/uk/london'
  },
  [chopardUkLondonHarrodsBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/uk/london/harrods'
  },
  [chopardUkLondonSelfridgesBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/uk/london/selfridges'
  },
  [chopardUsNewYorkBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/us/newyork'
  },
  [chopardChGenevaBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/ch/geneva'
  },
  [chopardAtViennaBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/at/vienna'
  },
  [chopardUsMiamiBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/us/miami'
  },
  [chopardWW2021Id]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021'
  },
  [chopardHighJewelleryId]: {
    salesLoft: 'https://chopard.v-boutique.com/highjewellery'
  },
  [chopardWW2021DubaiId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/dubai'
  },
  [chopardWW2021SpainId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/spain'
  },
  [chopardWW2021AustriaId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/austria'
  },
  [chopardWW2021UkId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/uk'
  },
  [chopardWW2021SgId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/sg'
  },
  [chopardWW2021HkId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/hk'
  },
  [chopardWW2021GermanyId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/germany'
  },
  [chopardWW2021FranceId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/france'
  },
  [chopardWW2021PressId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/press'
  },
  [chopardWW2021UsId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/us'
  },
  [chopardWW2021ItalyId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/italy'
  },
  [chopardWW2021AuId]: {
    salesLoft: 'https://chopard.v-boutique.com/ww2021/au'
  },
  [chopardChStanBoutiqueId]: {
    salesLoft: 'https://chopard.v-boutique.com/ch/stan'
  },
  [chopardHappySportVirtualExpId]: {
    vb: 'https://chopard.v-boutique.com/happysport'
  },
  [chopardHappySportKorea]: {
    vb: 'https://experience.chopard.com/ko-kr/happysport'
  },
  [chopardHappySportGenevaHQ]: {
    vb: 'https://experience.chopard.com/ch-en/happysport'
  },
  [chopardHappySportGenevaHQTraining]: {
    vb: 'https://chopard.v-boutique.com/ch/training'
  },
  [inspifySiteId]: {
    salesLoft: 'https://meet.inspify.com/loft'
  },
  [inspifyTestingSiteId]: {
    salesLoft: 'https://meet.inspify.com/testing'
  },
  [inspifyGCCSiteId]: {
    salesLoft: 'https://meet.inspify.com/gcc'
  },
  [inspifyJPSilkRoadSiteId]: {
    salesLoft: 'https://meet.inspify.com/sra'
  },
  [inspifyTestBrandSiteId]: {
    salesLoft: 'https://meet-test.inspify.com/loft'
  },
  [loubiAirwaysJpBoutiqueId]: {
    vb: 'https://loubiairways.christianlouboutin.com/jp'
  },
  [loubiFutureSiteId]: {
    vb: 'https://loubifuture.christianlouboutin.com'
  },
  [vacSgBoutiqueId]: {
    salesLoft: 'https://vacheron-constantin.v-boutique.com/sg'
  },
  [vacMarinaBaySandsBoutiqueId]: {
    salesLoft: 'https://vacheron-constantin.v-boutique.com/mbs'
  },
  [cartierUsMiamiSiteId]: {
    salesLoft: 'https://cartier.v-boutique.com/miami'
  },
  [cartierUsBeverlyHillsSiteId]: {
    salesLoft: 'https://cartier.v-boutique.com/beverlyhills'
  },
  [cartierUsNewYorkSiteId]: {
    salesLoft: 'https://cartier.v-boutique.com/newyork'
  },
  [aspreyUkLondonSiteId]: {
    salesLoft: 'https://asprey.v-boutique.com/london'
  },
  [tiffanyUsNycId]: {
    salesLoft: 'https://tiffany.v-boutique.com/us/nyc'
  },
  [vanCleefArpelsUsBeverlyHillsSiteId]: {
    vb: 'https://vancleefarpels.v-boutique.com/beverlyhills'
  },
  [vanCleefArpelsUsSiliconValleyFairSiteId]: {
    vb: 'https://vancleefarpels.v-boutique.com/valleyfair'
  },
  [vanCleefArpelsUsKingOfPrussiaSiteId]: {
    vb: 'https://vancleefarpels.v-boutique.com/kingofprussia'
  },
  [rogerDubuisUaeDubaiId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/uae/dubai'
  },
  [rogerDubuisChGenevaId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/ch/geneva'
  },
  [rogerDubuisUKLondonId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/uk/london'
  },
  [rogerDubuisCnBeijingId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/cn/beijing'
  },
  [rogerDubuisCnChengduId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/cn/chengdu'
  },
  [rogerDubuisCnShanghaiId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/cn/shanghai'
  },
  [rogerDubuisCnShenzhenId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/cn/shenzhen'
  },
  [rogerDubuisCnXianId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/cn/xian'
  },
  [rogerDubuisCnHangzhouId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/cn/hangzhou'
  },
  [rogerDubuisHkPacificPlaceId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/hk/pacificplace'
  },
  [rogerDubuisHkOtId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/hk/ot'
  },
  [rogerDubuisMoGalaxyId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/mo/galaxy'
  },
  [rogerDubuisMoWynnpalaceId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/mo/wynnpalace'
  },
  [rogerDubuisUsBeverlyHillsId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/us/beverlyhills'
  },
  [rogerDubuisUsNycId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/us/nyc'
  },
  [rogerDubuisKrAvenuelId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/kr/avenuel'
  },
  [rogerDubuisKrWorldtowerId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/kr/worldtower'
  },
  [rogerDubuisKrGangnamId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/kr/gangnam'
  },
  [rogerDubuisTwTaipeiId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/tw/taipei'
  },
  [rogerDubuisAuSydneyId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/au/sydney'
  },
  [rogerDubuisJpTokyoId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/jp/tokyo'
  },
  [rogerDubuisJpFukuokaId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/jp/fukuoka'
  },
  [rogerDubuisTbcId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/tbc'
  },
  [rogerDubuisSgIonId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/sg/ion'
  },
  [rogerDubuisSgMbsId]: {
    salesLoft: 'https://rogerdubuis.v-boutique.com/sg/mbs'
  },
  [gustoLuxeSiteId]: {
    salesLoft: 'https://gusto-luxe.inspify.com/loft'
  }
};

export const getLoftUrlByStoreId = (storeId: string) =>
  allBoutiqueUrl[storeId]?.salesLoft;

export const getVBUrlByStoreId = (storeId: string) =>
  allBoutiqueUrl[storeId]?.vb;

export const getVBOnboardingUrl = (storeId: string, host?: string) => {
  const brandOrigin = host || location.origin;
  return `${brandOrigin}/hub/meet/${storeId}`;
};

export const getVBUrl = (storeId: string, host?: string) => {
  const isProduction = isProd && !(host || location.host).includes(UAT_HOST);
  if (!isProduction) return `${getCurrentHost()}/virtualboutique/${storeId}`;
  return isOnboardingBrand()
    ? getVBOnboardingUrl(storeId, host)
    : getVBUrlByStoreId(storeId) || getLoftUrlByStoreId(storeId);
};
export const getSessionUrl = ({
  storeId,
  sessionId,
  region,
  source = 'session',
  isStoreUrlProd = false,
  host
}: {
  storeId: string;
  sessionId: string;
  region?: string;
  source?: string;
  isStoreUrlProd?: boolean;
  host?: string;
}) => {
  const isProduction = isProd && !host.includes(UAT_HOST);
  const brand = allBrands.find((b) => b.host === host);
  const isOnboarding = brand?.isNotPreConfigured || isOnboardingBrand();
  const storeUrlProd = isOnboarding
    ? getVBOnboardingUrl(
        storeId,
        host && (!host?.includes('http') ? `https://${host}` : host)
      )
    : getVBUrlByStoreId(storeId) || getLoftUrlByStoreId(storeId);
  const storeUrlStaging = `${getCurrentHost()}/virtualboutique/${storeId}`;
  const storeUrl =
    isProduction || isStoreUrlProd || isOnboarding
      ? storeUrlProd
      : storeUrlStaging;

  let sessionURL = `${storeUrl}?meeting=${sessionId}&mode=cockpit&source=${source}`;
  if (region) {
    sessionURL = `${sessionURL}&region=${region}`;
  }

  return {
    host: `${sessionURL}&role=advisor`,
    mc: `${sessionURL}&role=mc`,
    guest: sessionURL
  };
};

export const isIWCSGIon = (id: string) =>
  id === iwcSgIonBoutiqueId ||
  id === iwcSgIonBoutiqueIdV2 ||
  id === iwcSgIonSalesLoftSiteId;

export const isLoubiAirways = (id: string) =>
  id === loubiAirwaysBoutiqueId ||
  id === loubiAirwaysCnBoutiqueId ||
  id === loubiAirwaysJpBoutiqueId;

export const alsSgIonShopNowCC = 'keith-tt.lee@lange-soehne.com.sg';

export const isIWCUaeDubai = (id: string) => id === iwcUaeDubaiBoutiqueId;

// TODO: Move to API when it's available
export const sgPublicHolidays2022 = [
  '2022-12-26',
  '2022-10-24',
  '2022-08-09',
  '2022-07-10',
  '2022-05-16',
  '2022-05-02',
  '2022-04-15',
  '2022-02-02',
  '2022-02-01',
  '2022-01-01'
];

export const alsWhatsAppRota = {
  '2020-06-01': '6585267217',
  '2020-06-02': '6597705034',
  '2020-06-03': '6583665602',
  '2020-06-04': '6583980952',
  '2020-06-05': '6585755447',
  '2020-06-06': '6597640848',
  '2020-06-07': '6585267217',
  '2020-06-08': '6597705034',
  '2020-06-09': '6583665602',
  '2020-06-10': '6583980952',
  '2020-06-11': '6585755447',
  '2020-06-12': '6597640848',
  '2020-06-13': '6585267217',
  '2020-06-14': '6597705034',
  '2020-06-15': '6583665602',
  '2020-06-16': '6583980952',
  '2020-06-17': '6585755447',
  '2020-06-18': '6597640848',
  '2020-06-19': '6585267217',
  '2020-06-20': '6597705034',
  '2020-06-21': '6583665602',
  '2020-06-22': '6583980952',
  '2020-06-23': '6585755447',
  '2020-06-24': '6597640848',
  '2020-06-25': '6585267217',
  '2020-06-26': '6597705034',
  '2020-06-27': '6583665602',
  '2020-06-28': '6583980952',
  '2020-06-29': '6585755447',
  '2020-06-30': '6597640848'
};

export const getWhatsappNumberForAls = () => {
  const today = getFormattedDate();
  return alsWhatsAppRota[today];
};

export const maximumCompareProducts = 4;

export const watchSpecs = [
  {
    title: 'case',
    specs: 'caseSpecs'
  },
  {
    title: 'movement',
    specs: 'movementSpecs'
  },
  {
    title: 'features',
    specs: 'featuresSpecs'
  },
  {
    title: 'dial',
    specs: 'dialSpecs'
  },
  {
    title: 'strap',
    specs: 'strapSpecs'
  }
];

export const jewelrySpecs = [
  {
    title: 'material',
    specs: 'material'
  },
  {
    title: 'precious_stones',
    specs: 'preciousStones'
  },
  {
    title: 'size',
    specs: 'size'
  },
  {
    title: 'weight',
    specs: 'weight'
  },
  {
    title: 'width',
    specs: 'width'
  },
  {
    title: 'other',
    specs: 'other'
  }
];

export const highJewelrySpecs = [
  {
    title: 'ringSize',
    specs: 'ringSize'
  },
  {
    title: 'carats',
    specs: 'carat'
  },
  {
    title: 'mainStoneMaterial',
    specs: 'mainStoneMaterial'
  },
  {
    title: 'mainStoneCarat',
    specs: 'mainStoneCarat'
  },
  {
    title: 'mainStoneShape',
    specs: 'mainStoneShape'
  },
  {
    title: 'precious_stones',
    specs: 'preciousStones'
  }
];

export const commonProductSpecs = [
  {
    title: 'description',
    specs: 'description'
  }
];

export const iwcPortugieser2020CampaignGrouping = [
  {
    name: 'AUTOMATIC 40',
    products: [
      'ce1b205f-b73a-4c0e-bca7-4e617e4563b3',
      '42b1538f-af77-429f-bb67-6ce968133881',
      '39b0a046-234c-4f53-bedc-86ecf9e0a9d8',
      '29d3c4c0-f3c1-4f55-b53f-62ab07978b58'
    ]
  },
  {
    name: 'CHRONOGRAPH',
    products: [
      '4b2cab9d-b4b0-4fb8-b992-b1178781bbb1',
      'a9914f6a-9b45-41ce-a70e-56b1c17ba75d',
      '8cb8f16c-c187-4bd7-a147-8805f89fbe98',
      '20271c7a-70a1-43df-93d0-3e42cb730000',
      '605dbfbd-fd34-465d-bbd7-cb74219e28cc',
      '181d6781-180e-4e63-969a-a701df748228',
      'e8985240-c1f4-426d-9cba-24f144aca0ab',
      '5e2b03b6-0ee3-4c4a-b353-fa7fe20270df',
      'fbed9f58-abf8-4681-9460-aa5088fab4ba'
    ]
  },
  {
    name: 'AUTOMATIC',
    products: [
      '7b32acde-1a1b-4013-bf45-af21ed84941f',
      '8c9be8a2-fbf2-46f5-a675-e25b98e52481'
    ]
  },
  {
    name: 'PERPETUAL CALENDAR 42',
    products: [
      'be55f6e9-e823-4222-aad6-dca0c1e9de2a',
      'ee609209-0ef4-45ed-b83e-53560090519a',
      '32457a25-748a-41a4-b1f8-f6debb43665f'
    ]
  },
  {
    name: 'PERPETUAL CALENDAR',
    products: ['ae5c3f0f-c617-41e8-9d93-b6928d775e89']
  },
  {
    name: 'COMPLICATIONS',
    products: [
      '288a3c04-1ce9-437a-8d17-ae34326f76b6',
      '3c5e5c8d-3e73-4677-8bf7-7c6e50cd7d7f',
      '055c70c9-4a90-4a05-9195-a898df619f5e',
      'be7f9c10-4031-44fb-8ab8-683f3dfa35dc'
    ]
  },
  {
    name: 'YACHT CLUB',
    products: [
      'b2214da0-cc17-4891-97cd-912f6b12d1c6',
      '72f74d22-bf6a-43cd-ad8c-d7bcf0ef3444',
      '04860d37-89c6-4cfc-ba02-a0ee93f2c83c',
      '694eb7a9-9cde-401c-bac1-b493d9525f4f'
    ]
  }
];

export const defaultOpeningHours = [
  '01:00AM - 02:00AM',
  '02:00AM - 03:00AM',
  '03:00AM - 04:00AM',
  '04:00AM - 05:00AM',
  '05:00AM - 06:00AM',
  '06:00AM - 07:00AM',
  '07:00AM - 08:00AM',
  '08:00AM - 09:00AM',
  '09:00AM - 10:00AM',
  '10:00AM - 11:00AM',
  '11:00AM - 12:00AM',
  '12:00AM - 1:00PM',
  '1:00PM - 2:00PM',
  '2:00PM - 3:00PM',
  '3:00PM - 4:00PM',
  '4:00PM - 5:00PM',
  '5:00PM - 6:00PM',
  '6:00PM - 7:00PM',
  '7:00PM - 8:00PM',
  '8:00PM - 9:00PM',
  '9:00PM - 10:00PM',
  '10:00PM - 11:00PM'
];

export const zIndex = {
  meetingVideosWrapper: 1102,
  popupPage: 1002,
  documentViewer: 1101,
  popupMenu: 1200,
  preventActionVeil: 1100,
  fullScreenVideo: 1099,
  meetingControls: 1110,
  notification: 1105,
  chatWindow: 12000,
  disconnectedPopup: 12002,
  deviceWarning: 12001
};

export const loopingVideos = [
  '/content/converted/ed821690-7a90-418b-81ec-6738348da86f/thorsten_video.mp4'
];

export const INSPIFY_ASSETS_BUCKET = 'inspify-assets';

export const INSPIFY_LIGHT_LOGO =
  'https://panos2.inspify.io/virtual_boutique/88f684ae-3c27-40fc-82b7-6bd02634d44b/logo3.png';
export const INSPIFY_DARK_LOGO =
  'https://panos2.inspify.io/virtual_boutique/88f684ae-3c27-40fc-82b7-6bd02634d44b/logo3-dark.png';

export const defaultWsMatchMaker = `matchmaker.inspify.io`;

export const matchMakerBySpaceId = {
  [chopardHappySportGenevaHQ]: 'matchmaker-chge.inspify.io',
  [chopardHappySportGenevaHQTraining]: 'matchmaker-chge.inspify.io',
  [inspifyInverseSiteId]: 'matchmaker-inverse.inspify.io'
};

export const UAT_HOST = 'web-uat.inspify.io';

export const SUPPORT_TOPICS = [
  {
    val: 'getting_started',
    label: 'Getting Started'
  },
  {
    val: 'billing_subscription',
    label: 'Billing/Subscription'
  },
  {
    val: 'report_bug',
    label: 'Report a bug'
  },
  {
    val: 'legal_security',
    label: 'Legal and Security'
  },
  {
    val: 'request_feature',
    label: 'I have a feature request'
  },
  {
    val: 'my_account',
    label: 'My Account'
  },
  {
    val: 'team_user_mgm',
    label: 'Team/User Management'
  },
  {
    val: 'close_my_account',
    label: 'Close my account'
  },
  {
    val: 'change_domain',
    label: 'Change Domain'
  },
  {
    val: 'other',
    label: 'Other'
  }
];

export const BRANDING_DEFAULT_SECONDARY_COLOR = '#4B2953';
export const isResourceFromAssetLib = (url = '') => {
  return (
    url.startsWith(`${assetBaseUrl}/assets/`) ||
    url.startsWith(`${videoBaseUrl}/assets/`)
  );
};

const devHosts = [
  'web-dev-a.inspify.io',
  'web-uat.inspify.io',
  'web-dev-b.inspify.io',
  'web-dev-c.inspify.io',
  'web-dev.inspify.io'
];

export const isDevHost = (host: string) =>
  devHosts.includes(host) || host.includes('localhost');
