import {
  REQUEST_UPDATE_FILE_VIEWER_LAYOUT,
  REQUEST_UPDATE_PLAYER_CONTROL
} from './../../../../redux/actions';
import { AnyAction } from 'redux';
import {
  ClientState,
  IFile,
  JitsiEndpointMessage,
  JitsiMeetingRoom
} from '../../../../interfaces';
import {
  DID_RECEIVE_REQUEST_USER_INFO,
  PARTICIPANT_DID_SET_ACTIVE_PAGE,
  REQUEST_PARTICIPANT_ADVISOR_INFO,
  REQUEST_TO_BE_ADMITTED_IN_MEETING,
  REQUEST_TO_BE_PRESENTER,
  SEND_USER_INFO,
  SET_PIXEL_STREAM_PLAYER_STATUS,
  TOGGLE_RAISE_HAND
} from '../../../../redux/actions';
import { getIdentityId } from '../../../../utils/identity';
import {
  PLAYER_STATUS,
  RAISE_HAND,
  REQUEST_ADMITTANCE,
  REQUEST_ADVISOR_INFO,
  USER_INFO,
  tryToSendMessage
} from '../../commands';
import { formatActivePageMessage } from '../Presenter/PresenterCommandSender';

const getMessageFromAction = (
  room: JitsiMeetingRoom,
  action: AnyAction,
  clientState: ClientState
): JitsiEndpointMessage => {
  switch (action.type) {
    case SEND_USER_INFO:
      return {
        type: USER_INFO,
        value: undefined,
        attributes: {
          participantId: room?.myUserId(),
          identityId: getIdentityId(),
          role: action.payload,
          oldParticipantId: clientState?.meeting?.reconnect?.oldParticipantId
        }
      };

    case DID_RECEIVE_REQUEST_USER_INFO:
      return {
        type: USER_INFO,
        value: undefined,
        attributes: {
          participantId: room?.myUserId(),
          identityId: getIdentityId(),
          role: clientState.meeting?.localUser?.role,
          meetingDate: clientState?.meeting?.date,
          raiseHandParticipantIds:
            clientState?.meeting?.raiseHand?.participantIds || []
        }
      };
    case REQUEST_PARTICIPANT_ADVISOR_INFO:
      return {
        type: REQUEST_ADVISOR_INFO,
        attributes: {
          participantId: room?.myUserId()
        }
      };
    case REQUEST_TO_BE_ADMITTED_IN_MEETING:
      return {
        type: REQUEST_ADMITTANCE,
        attributes: {
          participantId: room?.myUserId(),
          displayName: clientState.user?.alias,
          role: clientState?.meeting?.localUser?.role
        }
      };
    case TOGGLE_RAISE_HAND:
      return {
        type: RAISE_HAND,
        attributes: {
          participantId: room?.myUserId()
        }
      };
    case REQUEST_TO_BE_PRESENTER: {
      const file: IFile = {
        ...(action.payload || {})
      };
      if (file.type === 'storybook') {
        delete file.pagesSetting;
        delete file.pages;
        delete file.silences;
        delete file.thumbnails;
      }
      return {
        type: REQUEST_TO_BE_PRESENTER,
        attributes: {
          participantId: room?.myUserId(),
          file
        }
      };
    }
    case PARTICIPANT_DID_SET_ACTIVE_PAGE: {
      return {
        type: PARTICIPANT_DID_SET_ACTIVE_PAGE,
        attributes: {
          activePage: formatActivePageMessage(
            clientState,
            action.payload?.currentPage,
            action.payload?.nextUrl,
            action.payload?.prevUrl
          ),
          participantId: room?.myUserId()
        }
      };
    }
    case SET_PIXEL_STREAM_PLAYER_STATUS:
      return {
        type: PLAYER_STATUS,
        attributes: {
          participantId: room?.myUserId(),
          playerStatus: action.payload
        }
      };
    case REQUEST_UPDATE_FILE_VIEWER_LAYOUT:
      return {
        type: REQUEST_UPDATE_FILE_VIEWER_LAYOUT,
        attributes: {
          participantId: room?.myUserId(),
          layout: action.payload
        }
      };
    case REQUEST_UPDATE_PLAYER_CONTROL:
      return {
        type: REQUEST_UPDATE_PLAYER_CONTROL,
        attributes: {
          control: action.payload,
          participantId: room?.myUserId()
        }
      };
    default:
      return;
  }
};

export const sendSpectatorCommandForAction = (
  room: JitsiMeetingRoom,
  action: AnyAction,
  clientState: ClientState
) => {
  const message = getMessageFromAction(room, action, clientState);
  if (message) {
    console.log('sendPresenterCommandForAction - sending message');
    console.log(message);
    tryToSendMessage(room, message);
  }
};
