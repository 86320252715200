import CryptoJS, { AES, HmacSHA256 } from 'crypto-js';

export const enc = (plain: string, key: string) => {
  return AES.encrypt(plain, key).toString();
};

export const dec = (cipher: string, key: string) => {
  const bytes = AES.decrypt(cipher, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const createHash = (plain: string, key: string) => {
  return HmacSHA256(plain.substring(0,1024), key).toString(CryptoJS.enc.Hex);
};
