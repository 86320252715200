import { ClientState } from '../../interfaces';
import { defaultClientState } from './clientReducer';
import { AnyAction } from 'redux';
import {
  DID_LOAD_RELATED_PRODUCTS_FOR_INSPIRATION,
  START_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION,
  FINISH_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION
} from '../actions';

const inspirationRelatedProductsReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case DID_LOAD_RELATED_PRODUCTS_FOR_INSPIRATION: {
      const existingInspirations = {
        ...(state.inspirationRelatedProducts?.data || {})
      };
      existingInspirations[action.payload.inspirationId] =
        action.payload.products;
      return {
        ...state,
        inspirationRelatedProducts: {
          ...(state.inspirationRelatedProducts || {}),
          data: existingInspirations
        }
      };
    }
    case START_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION:
      return {
        ...state,
        inspirationRelatedProducts: {
          ...(state.inspirationRelatedProducts || {}),
          loading: true
        }
      };
    case FINISH_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION:
      return {
        ...state,
        inspirationRelatedProducts: {
          ...(state.inspirationRelatedProducts || {}),
          loading: false
        }
      };
    default:
      return state;
  }
};

export default inspirationRelatedProductsReducer;
