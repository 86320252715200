import { AnyAction, Dispatch, Store } from 'redux';
import {
  chopardHappySportVirtualExpId,
  isLoubiAirways,
  rogerDubuisSgBoutiqueId,
  rogerDubuisWW2021Id,
  loubiAirwaysJpBoutiqueId,
  rogerDubuisWholeSaleId,
  rogerDubuisRetailId,
  vanCleefArpelsUsSiliconValleyFairSiteId,
  vanCleefArpelsUsKingOfPrussiaSiteId
} from '../../config';
import { IMainState } from '../../interfaces';
import { handleCommonAction } from './common';
import { handleLoubiAirwaysAction } from './loubiAirwaysActionHandler';
import { handleLoubiAirwaysJpAction } from './loubiAirwaysJpActionHandler';
import { handleRogerDubuisSGBoutiqueAction } from './rduSgActionHandler';
import { handleRDWW2021Action } from './rogerDubuisWW2021ActionHandler';
import { handleVCAAction } from './vcaActionHandler';
import { TRANSITIONED_FROM_WW2021_TO_MANUFACTURE } from '../../config/rduWW2021';
import { handleChopardHappySportAction } from './chopardHappySportActionHandler';

const virtualBoutiqueMiddleware =
  (store: Store<IMainState>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const result = next(action);
    const state = store.getState();
    const dispatch = store.dispatch;
    const storeId =
      state?.clientState?.teleport?.teleportTo?.store?.id ||
      state.clientState.store?.id;

    if (storeId === rogerDubuisSgBoutiqueId) {
      handleRogerDubuisSGBoutiqueAction(action, dispatch);
      // For now only enable for RDU, we need to migrate the KPRano changes one boutique at a time
      handleCommonAction(action, dispatch);
    } else if (storeId === loubiAirwaysJpBoutiqueId) {
      handleLoubiAirwaysJpAction(action, dispatch, state.clientState);
    } else if (isLoubiAirways(storeId)) {
      handleLoubiAirwaysAction(action, dispatch, state.clientState);
    } else if (
      [
        rogerDubuisWW2021Id,
        rogerDubuisWholeSaleId,
        rogerDubuisRetailId
      ].includes(storeId)
    ) {
      handleRDWW2021Action(action, dispatch, state.clientState, storeId);
      if (window[TRANSITIONED_FROM_WW2021_TO_MANUFACTURE]) {
        handleRogerDubuisSGBoutiqueAction(action, dispatch);
      }
    } else if (storeId === chopardHappySportVirtualExpId) {
      handleChopardHappySportAction(action, dispatch);
    } else if (
      storeId === vanCleefArpelsUsSiliconValleyFairSiteId ||
      storeId === vanCleefArpelsUsKingOfPrussiaSiteId
    ) {
      handleVCAAction(action, dispatch);
    }
    return result;
  };

export default virtualBoutiqueMiddleware;
