import React from 'react';
import { chopardHappySportGenevaHQ, chopardHappySportKorea } from '../../config';

export const ChopardHappySportStyle = ({ storeId }: { storeId?: string }) => (
  <style jsx global>{`
    body {
      color: #000;
      ${storeId === chopardHappySportKorea ||
      storeId === chopardHappySportGenevaHQ
        ? `touch-action: none;`
        : ''}
    }
    .chopard-sans {
      font-family: 'EngraversGothicBTW03-Rg', 'Source Sans Pro', sans-serif !important;
      font-weight: normal !important;
    }
    .chopard-serif {
      font-family: 'Walbaum', 'Garamond', 'Baskerville', 'Baskerville Old Face',
        'Hoefler Text', 'Times New Roman', serif;
      font-weight: normal !important;
    }
    .chopard-content {
      font-family: 'HelveticaChopard', 'HelveticaNeue-Light',
        'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Open Sans',
        'Arial Narrow', 'Arial', 'Lucida Grande', 'sans-serif';
    }
    .landing-page h1,
    .landing h1 {
      font-family: 'Walbaum', 'Garamond', 'Baskerville', 'Baskerville Old Face',
        'Hoefler Text', 'Times New Roman', serif;
    }
    .landing-page h1 {
      font-weight: normal !important;
      color: #fff !important;
      letter-spacing: 1px !important;
      margin-bottom: 0 !important;
    }
  `}</style>
);

export const ChopardHappySportTouchStyle = () => (
  <style jsx global>{`
    body {
      color: #000;
      font-family: 'HelveticaChopard', 'HelveticaNeue-Light',
        'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Open Sans',
        'Arial Narrow', 'Arial', 'Lucida Grande', 'sans-serif';
    }

    .chopard-sans,
    .btn {
      font-family: 'EngraversGothicBTW03-Rg', 'Source Sans Pro', sans-serif !important;
      font-weight: normal !important;
    }
    .chopard-serif {
      font-family: 'Walbaum', 'Garamond', 'Baskerville', 'Baskerville Old Face',
        'Hoefler Text', 'Times New Roman', serif;
      font-weight: normal !important;
    }
    .inspiration-content p:first-child {
      display: none;
    }
    .inspiration-content p:nth-child(2) {
      font-family: 'Walbaum', 'Garamond', 'Baskerville', 'Baskerville Old Face',
        'Hoefler Text', 'Times New Roman', serif;
      font-weight: normal !important;
      font-size: 1.6em;
      letter-spacing: 0.05em;
    }
    .content-video .inspiration-content p:nth-child(2):after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #000;
      margin: 30px auto;
      width: 40%;
    }
  `}</style>
);

const ChopardStyle = () => (
  <style jsx global>{`
    .vb-lite {
      font-family: Helvetica, sans-serif;
    }

    h1 {
      font-family: 'Walbaum', 'Garamond', 'Baskerville', 'Baskerville Old Face',
        'Hoefler Text', 'Times New Roman', serif;
      font-weight: normal;
      color: #000 !important;
      text-shadow: 0 0 5px #fff !important;
      letter-spacing: 1px !important;
      margin-bottom: 0 !important;
      width: 330px !important;
    }
    .logo-top {
      display: none !important;
    }
    .logo-middle {
      display: block !important;
      height: 180px;
    }
    .options-container {
      width: 90%;
      margin: auto !important;
      max-width: 520px;
      padding-top: 30px;
      border-radius: 20px 20px 0 0;
    }
    .option {
      background: none !important;
      border: none !important;
    }
    .option span {
      color: #000 !important;
      font-size: 0.8em !important;
    }
    @media (min-width: 920px) {
      h1 {
        font-size: 1.5em !important;
        width: 420px !important;
      }
    }
    @media (min-width: 1200px) {
      .options-container {
        left: 58% !important;
      }
    }
    @media (orientation: landscape) and (min-width: 1024px),
      (min-width: 620px) and (max-height: 500px) {
      .vb-lite {
        background-position: center top !important;
      }
    }
  `}</style>
);

export const ChopardWW2021Style = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'Helvetica Neue LT Std BLkCn';
      src: url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.eot');
      src: url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.eot?#iefix')
          format('embedded-opentype'),
        url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.woff2')
          format('woff2'),
        url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.woff') format('woff'),
        url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.ttf')
          format('truetype'),
        url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.svg#HelveticaNeueLTStd-BlkCn')
          format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Helvetica Neue LT Std Th';
      src: url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.eot');
      src: url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.eot?#iefix')
          format('embedded-opentype'),
        url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.woff2') format('woff2'),
        url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.woff') format('woff'),
        url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.ttf') format('truetype'),
        url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.svg#HelveticaNeueLTStd-Th')
          format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Lucida Grande';
      src: url('/asset/chopard/fonts/LucidaGrandeBold.eot');
      src: url('/asset/chopard/fonts/LucidaGrandeBold.eot?#iefix')
          format('embedded-opentype'),
        url('/asset/chopard/fonts/LucidaGrandeBold.woff2') format('woff2'),
        url('/asset/chopard/fonts/LucidaGrandeBold.woff') format('woff'),
        url('/asset/chopard/fonts/LucidaGrandeBold.ttf') format('truetype'),
        url('/asset/chopard/fonts/LucidaGrandeBold.svg#LucidaGrandeBold')
          format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    .vb-lite {
      background-size: 100% auto !important;
      background-color: #060918 !important;
      height: 100vh;
      font-family: Helvetica, sans-serif;
    }

    .vb-lite.store-afd080e7-1a4b-4d41-9f26-737a9ca39eac {
      background-color: #060300 !important;
    }

    h1 {
      font-family: 'Walbaum', 'Garamond', 'Baskerville', 'Baskerville Old Face',
        'Hoefler Text', 'Times New Roman', serif;
      font-weight: normal;
      letter-spacing: 1px !important;
      margin-bottom: 0 !important;
      width: 330px !important;
    }

    .logo-top {
      display: none !important;
    }
    .logo-middle {
      display: block !important;
      width: 150px;
      margin: 0 auto 20px;
    }
    .options-container {
      background: rgba(0, 0, 0, 0.6);
      width: 90%;
      max-width: 520px;
      padding-top: 30px;
      border-radius: 20px 20px 0 0;
    }
    .option {
      background: none !important;
      border: none !important;
    }
    .option span {
      font-size: 0.6em !important;
    }
    @media (min-width: 768px) {
      h1 {
        width: 420px !important;
      }
      .options-container {
        padding: 50px;
      }
      .option span {
        font-size: 0.8em !important;
      }
    }

    @media (min-width: 920) {
      h1 {
        font-size: 2em !important;
      }
    }

    @media (orientation: landscape) and (min-width: 1024px),
      (min-width: 620px) and (max-height: 500px) {
      .vb-lite {
        background-size: auto 100% !important;
      }
      .logo-middle {
        width: 300px !important;
      }
      .options-container {
        width: 50%;
        max-width: none !important;
        padding: 0 !important;
        background: none !important;
        height: auto !important;
        margin: auto !important;
      }
      h1 {
        width: 100% !important;
        max-width: 500px;
      }
      .vb-lite.store-afd080e7-1a4b-4d41-9f26-737a9ca39eac h1 {
        line-height: 1.2 !important;
        font-size: 1.8em !important;
      }
      .option {
        background: none !important;
      }
    }
  `}</style>
);

export const ChopardHJTouchStyle = () => (
  <style jsx global>{`
    .thumbnail .prod-model {
      font-size: 0.8em !important;
      margin-top: 0;
    }
    .thumbnail .prod-title {
      font-size: 0.9em !important;
    }
  `}</style>
);

export const ChopardHSProductSearchStyle = () => {
  return (
    <style jsx global>{`
      .productsindex-page {
        font-family: Helvetica, sans-serif;
        background: transparent !important;
      }
      .filter-col .complication {
        display: none;
      }

      .productsindex-page header {
        padding: 0 !important;
        border-bottom: none;
      }
      #shareButton.shareButton {
        background-color: #fff;
      }

      #shareButton.shareButton svg {
        fill: #000;
      }
      .filter-col {
        padding-top: 35px !important;
        font-weight: 500;
      }
      .pagetitle {
        color: #000 !important;
        text-align: left;
        height: 46px;
        line-height: 46px;

        transform: translateX(-1px);
      }
      .keywords-input {
        width: 90% !important;
      }
      .keywords-input input {
        border-radius: 0 !important;
        border-right-color: transparent;
      }
      .keywords-input input::placeholder {
        text-transform: uppercase;
        font-size: 0.7em;
      }
      .input-group-append {
        --border-color: #ced4da;
      }
      .input-group-append button {
        background: transparent !important;
        color: inherit !important;
        border: 1px solid var(--border-color) !important;
        border-left-color: transparent !important;
        border-radius: 0 !important;
      }

      .accordion .card,
      .search-container {
        background: white;
      }
      .btn-exit-compare-mode,
      .compare-products-button {
        display: block;
        background: #000 !important;
        border-radius: 0 !important;
        padding: 0.9rem 0.7rem !important;
      }
      .btn-exit-compare-mode {
        background: #fff !important;
      }
      .btn-exit-compare-mode:hover {
        background: #000 !important;
      }

      .start-compare-container {
        max-width: none !important;
      }
      @media (min-width: 576px) {
        .container {
          max-width: calc(100% - 50px);
        }
      }
      @media (min-width: 1450px) {
        .container {
          max-width: 1400px;
        }
      }
      @media (min-width: 767px) {
        .col-lg-3 {
          width: 220px;
          max-width: 220px;
          border-right: 1px solid #efefef;
        }
        .filter-col {
          border-right: none;
        }
        .filter-col + .col-lg-9 {
          max-width: calc(100% - 220px);
          flex: 0 0 calc(100% - 220px);
        }
        .start-compare-container {
          position: fixed !important;
          bottom: 0 !important;
          left: 220px;
          width: auto !important;
          right: 0;
        }
        .start-compare-container .btn {
          padding: 10px 15px;
        }
      }
      @media (max-width: 767px) {
        .filter-col {
          z-index: 1002 !important;
          padding-top: 50px !important;
        }

        .m-pageTitle {
          display: block !important;
        }
        .keywords-input {
          width: 80% !important;
        }
        .btn-filter {
          background-color: transparent !important;
          border: none !important;
          color: #000 !important;
        }
      }
    `}</style>
  );
};
export default ChopardStyle;
