import React from 'react';
import { assetBaseUrl } from '../../config';

const GustoLuxeStyle = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'Optima';
      src: url('${assetBaseUrl}/fonts/Optima.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    body {
      font-family: 'Optima', 'Arial';
    }

    button.close,
    button.popup-close,
    button.btn-close,
    .close-container,
    input:not(:placeholder-shown)[type="password"] {
      font-family: 'Arial' !important;
    }
  `}</style>
);

export default GustoLuxeStyle;
