import { AnyAction } from 'redux';
import { SearchAssetOptions } from '../advisorHub/clientSideServices/assetLibrary';
import { IMeetingChat } from '../components/Meeting/Chat/chatUtils';
import { JitsiConnectionStatus } from '../components/Meeting/CockpitContainer';
import {
  IPSHotspot,
  StreamConnectionStatus,
  StreamPlayerStatus
} from '../components/PixelStream/PSInterface';
import {
  AppointmentPopup,
  ChatConfig,
  CheckoutPaymentStatus,
  CheckoutStatus,
  CheckoutStep,
  EmailSentState,
  EnquiryPopup,
  FileViewerLayout,
  FocusedItemState,
  HubAlertType,
  IAccessRequest,
  ICheckoutFormData,
  ICollaborationState,
  IComparedProduct,
  IFileToUpload,
  IFile,
  IFileUploadStatus,
  IFolder,
  IFolderContext,
  IInspiration,
  IMapState,
  IMeetingStats,
  IMultilingualVideoUrls,
  INudge,
  INudgeItem,
  INudgePayload,
  INudgeTemplate,
  IPixelStreamServer,
  IPlayerControl,
  IProduct,
  IProductAccessories,
  ISessionDetails,
  ISessionFile,
  ISessionFormData,
  IShoppingFormData,
  IStore,
  IStoreDevice,
  IStorybookFileState,
  IStorySearchState,
  IStudioViewState,
  IUser,
  IUserInfoState,
  IVirtualBoutiqueConfig,
  IWishlistPayload,
  JitsiDevice,
  JitsiLocalTrack,
  JitsiParticipant,
  JitsiRemoteTrack,
  LoadingStatus,
  LoginStatus,
  MeetingLayout,
  MeetingLayoutMode,
  MeetingNotification,
  MeetingRole,
  PanoDiscoverMore,
  PanoJourneyConfig,
  PanoView,
  ProductFamily,
  ProductSearchState,
  ProgressStatus,
  RDUHudMenu,
  SultanateOfOmanPopup,
  ViewportInfo,
  VirtualBoutiqueMode,
  ISceneState,
  IAsset,
  AssetLibraryPopupState,
  JitsiMeetingRoom,
  IDeviceWarning,
  IPexelsUploadStatus
} from '../interfaces';
import { PageView } from '../utils/pageView';
import {
  AppFeature,
  IRemoteUserStats,
  ITeleportSite,
  IWishlistItem,
  JitsiEndpointMessage,
  JitsiVideoTrackEffect,
  ParticipantMeetingState
} from './../interfaces/index';

export const OPEN_TOOLTIP = 'OPEN_TOOLTIP';

export const TELEPORT_SHOW_OPTIONS = 'TELEPORT_SHOW_OPTIONS';
export const TELEPORT_SELECT_SITE = 'TELEPORT_SELECT_SITE';
export const TELEPORT_TO = 'TELEPORT_TO';

export const DID_LOAD_PRODUCT_INSPIRATIONS = 'DID_LOAD_PRODUCT_INSPIRATIONS';
export const DID_LOAD_RELATED_PRODUCTS_FOR_INSPIRATION =
  'DID_LOAD_RELATED_PRODUCTS_FOR_INSPIRATION';
export const START_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION =
  'START_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION';
export const FINISH_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION =
  'FINISH_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION';
export const SET_INSPIRATIONS_LIST = 'SET_INSPIRATIONS_LIST';
export const ADD_PRODUCT_TO_COMPARE = 'ADD_PRODUCT_TO_COMPARE';
export const REMOVE_PRODUCT_FROM_COMPARE = 'REMOVE_PRODUCT_FROM_COMPARE';
export const FOCUS_ITEM = 'FOCUS_ITEM';
export const DID_LOAD_SIMILAR_PRODUCTS = 'DID_LOAD_SIMILAR_PRODUCTS';
export const START_LOADING_SIMILAR_PRODUCTS = 'START_LOADING_SIMILAR_PRODUCTS';
export const FINISH_LOADING_SIMILAR_PRODUCTS =
  'FINISH_LOADING_SIMILAR_PRODUCTS';

export const UPDATE_WISHLIST = 'UPDATE_WISHLIST';
export const FETCH_WISHLIST = 'FETCH_WISHLIST';
export const DID_CHANGE_WISHLIST_TAB = 'DID_CHANGE_WISHLIST_TAB';

export const VIEW_PAGE = 'VIEW_PAGE';
export const CLICK_BACK_BUTTON = 'CLICK_BACK_BUTTON';
export const DID_LOAD_LOCAL_USER_FAILED = 'DID_LOAD_LOCAL_USER_FAILED';
export const DID_LOAD_LOCAL_USER = 'DID_LOAD_LOCAL_USER';
export const DID_UPDATE_LOCAL_USER = 'DID_UPDATE_LOCAL_USER';

export const OPEN_APPOINTMENT_FROM_POPUP = 'OPEN_APPOINTMENT_FROM_POPUP';
export const OPEN_ENQUIRY_FROM_POPUP = 'OPEN_ENQUIRY_FROM_POPUP';
export const CLICK_PRODUCT_COLOR = 'CLICK_PRODUCT_COLOR';
export const SHOW_PRODUCT_DESCRIPTION = 'SHOW_PRODUCT_DESCRIPTION';
export const SHOW_PRODUCT_SPECIFICATIONS = 'SHOW_PRODUCT_SPECIFICATIONS';
export const UPDATE_PRODUCT_SEARCH_STATE = 'UPDATE_PRODUCT_SEARCH_STATE';
export const UPDATE_STORY_SEARCH_STATE = 'UPDATE_STORY_SEARCH_STATE';
export const DID_SLIDE_PRODUCT_IMAGES_SLIDER =
  'DID_SLIDE_PRODUCT_IMAGES_SLIDER';
export const VIDEO_PLAYED_WITH_SOUND = 'VIDEO_PLAYED_WITH_SOUND';
export const DID_CLICK_ON_PREVENT_INTERACTION_VEIL =
  'DID_CLICK_ON_PREVENT_INTERACTION_VEIL';

export const DID_LOAD_PRODUCT_ACCESSORIES = 'DID_LOAD_PRODUCT_ACCESSORIES';
export const SELECT_PRODUCT_STRAP = 'SELECT_PRODUCT_STRAP';
export const SELECT_PRODUCT_BUCKLE = 'SELECT_PRODUCT_BUCKLE';
export const SELECT_PRODUCT_DIAL = 'SELECT_PRODUCT_DIAL';
export const SELECT_ACCESSORIES_SECTION = 'SELECT_ACCESSORIES_SECTION';

export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_VB_CONFIG = 'UPDATE_VB_CONFIG';

export const DID_ACCEPT_COOKIES = 'DID_ACCEPT_COOKIES';
export const DID_REJECT_COOKIES = 'DID_REJECT_COOKIES';

export const LOAD_FILE_LIST = 'LOAD_FILE_LIST';
export const SET_ACTIVE_FILE = 'SET_ACTIVE_FILE';
export const SHOW_STORYBOOK_IN_MEETING = 'SHOW_STORYBOOK_IN_MEETING';
export const UPDATE_STORYBOOK_INDEX = 'UPDATE_STORYBOOK_INDEX';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const PARTICIPANT_DID_SET_ACTIVE_PAGE =
  'PARTICIPANT_DID_SET_ACTIVE_PAGE';
export const SET_FILE_CONTROLLER = 'SET_FILE_CONTROLLER';
export const UPDATE_HIDDEN_PAGES = 'UPDATE_HIDDEN_PAGES';
export const UPDATE_PLAYER_CONTROL = 'UPDATE_PLAYER_CONTROL';
export const REQUEST_UPDATE_PLAYER_CONTROL = 'REQUEST_UPDATE_PLAYER_CONTROL';
export const UPDATE_LOCAL_PLAYER_CONTROL_CURRENT_SCENE =
  'UPDATE_LOCAL_PLAYER_CONTROL_CURRENT_SCENE';
export const UPDATE_FILE_VIEWER_LAYOUT = 'UPDATE_FILE_VIEWER_LAYOUT';
export const REQUEST_UPDATE_FILE_VIEWER_LAYOUT =
  'REQUEST_UPDATE_FILE_VIEWER_LAYOUT';
// VB Actions
export const VB_OPEN_POPUP = 'VB.OPEN_POPUP';
export const VB_POPUP_REDIRECT = 'VB.POPUP_REDIRECT';
export const VB_UPDATE_POPUP_PAGE_TYPE = 'VB.UPDATE_POPUP_PAGE_TYPE';
export const VB_CLOSE_POPUP = 'VB.CLOSE_POPUP';
export const VB_OPEN_JOURNEY = 'VB.OPEN_JOURNEY';
export const VB_HIDE_JOURNEY = 'VB.HIDE_JOURNEY';
export const VB_ENABLE_JOURNEY = 'VB.ENABLE_JOURNEY';
export const VB_DISABLE_JOURNEY = 'VB.DISABLE_JOURNEY';
export const VB_DID_GO_TO_SCENE = 'VB.DID_GO_TO_SCENE';
export const VB_DID_LOOK_TO_VIEW = 'VB.DID_LOOK_TO_VIEW';
export const VB_DID_GO_BACK_TO_SCENE = 'VB.DID_GO_BACK_TO_SCENE';
export const VB_UPDATE_MODE = 'VB.UPDATE_MODE';
export const VB_APPOINTMENT_POPUP = 'VB.APPOINTMENT_POPUP';
export const VB_VIDEO_APPOINTMENT_POPUP = 'VB.VIDEO_APPOINTMENT_POPUP';
export const VB_ENQUIRY_POPUP = 'VB.ENQUIRY_POPUP';
export const VB_CONTACT_POPUP = 'VB.CONTACT_POPUP';
export const VB_POPUP_ACTION = 'VB.POPUP_ACTION';
export const VB_FEEDBACK_POPUP = 'VB.FEEDBACK_POPUP';
export const VB_SULTANATE_OF_OMAN_POPUP = 'VB.SULTANATE_OF_OMAN_POPUP';
export const VB_FEEDBACK_TIMER_FIRED = 'VB.FEEDBACK_TIMER_FIRED';
export const VB_DID_CLICK_CONTACT = 'VB.DID_CLICK_CONTACT';
export const VB_DID_LOAD_JOURNEY_CONFIG = 'VB.DID_LOAD_JOURNEY_CONFIG';
export const VB_DID_LOAD_CHAT_CONFIG = 'VB.DID_LOAD_CHAT_CONFIG';
export const VB_SHOW_ZOOM_INDICATOR = 'VB.SHOW_ZOOM_INDICATOR';
export const VB_UPDATE_DISCOVER_MORE = 'VB.UPDATE_DISCOVER_MORE';
export const VB_SHOW_HOT_SPOT = 'VB.SHOW_HOT_SPOT';
export const VB_HIDE_HOT_SPOT = 'VB.HIDE_HOT_SPOT';
export const VB_VIDEO_DID_COMPLETE = 'VB.VIDEO_DID_COMPLETE';
export const VB_DID_CLICK_ON_HOT_SPOT = 'VB.DID_CLICK_ON_HOT_SPOT';
export const VB_MUTE_BACKGROUND_SOUND = 'VB.MUTE_BACKGROUND_SOUND';
export const VB_UNMUTE_BACKGROUND_SOUND = 'VB.UNMUTE_BACKGROUND_SOUND';
export const VB_PANO_LOADED = 'VB.PANO_LOADED';
export const VB_SHOW_FULL_SCREEN_VIDEO_WITH_URL =
  'VB.VB_SHOW_FULL_SCREEN_VIDEO_WITH_URL';
export const VB_DID_START_PLAYING_VIDEO = 'VB.DID_START_PLAYING_VIDEO';
export const VB_DID_CLICK_ON_MAIN_VIDEO = 'VB.DID_CLICK_ON_MAIN_VIDEO';
export const VB_START_GLITCH_PANO = 'VB.START_GLITCH_PANO';
export const VB_FLIP_PANO = 'VB.FLIP_PANO';
export const VB_RESET_AND_FLIP_PANO = 'VB.RESET_AND_FLIP_PANO';
export const VB_DID_SHOW_CAMPAIGN = 'VB.DID_SHOW_CAMPAIGN';
export const VB_SHOW_HUD_BUTTON = 'VB.SHOW_HUD_BUTTON';
export const VB_OPEN_HUD = 'VB.OPEN_HUD';
export const VB_DID_CLICK_HUD_MENU = 'VB.DID_CLICK_HUD_MENU';
export const VB_SHOW_WELCOME_TO_SCENE = 'VB.SHOW_WELCOME_TO_SCENE';
export const VB_SHOW_IWC_XMAS_VIDEO = 'VB.SHOW_IWC_XMAS_VIDEO';
export const VB_TOGGLE_VEIL = 'VB.TOGGLE_VEIL';
export const VB_ACCESS_REQUEST = 'VB.ACCESS_REQUEST';
export const VB_DID_START_PLAYING_FULL_SCREEN_VIDEO =
  'VB.DID_START_PLAYING_FULL_SCREEN_VIDEO';
export const VB_OPEN_MUSIC_PLAYLIST = 'VB.TOGGLE_MUSIC_PLAYLIST';
export const VB_UPDATE_BACKGROUND_MUSIC_URL = 'VB.UPDATE_BACKGROUND_MUSIC_URL';
export const VB_SWITCH_OFF_LIGHTS = 'VB.SWITCH_OFF_LIGHTS';
export const VB_SHOW_OUTRO = 'VB.SHOW_OUTRO';
export const VB_SHOW_STUDIO_JOIN_BUTTON = 'VB.SHOW_STUDIO_JOIN_BUTTON';
export const VB_LOAD_NEW_PANO = 'VB.LOAD_NEW_PANO';
export const VB_RELOAD_DEFAULT_PANO = 'VB.RELOAD_DEFAULT_PANO';
export const VB_UPDATE_MAP = 'VB.UPDATE_MAP';
export const VB_UPDATE_LANGUAGE = 'VB.UPDATE_LANGUAGE';
export const VB_OPEN_SSP_STORYBOOK_SEARCH = 'VB.OPEN_SSP_STORYBOOK_SEARCH';
export const VB_CLOSE_SSP_STORYBOOK_SEARCH = 'VB.CLOSE_SSP_STORYBOOK_SEARCH';

export const SET_PIXEL_STREAM_SERVER = 'SET_PIXEL_STREAM_SERVER';
export const SET_PIXEL_STREAM_CONNECTION_STATUS =
  'SET_PIXEL_STREAM_CONNECTION_STATUS';
export const SET_PIXEL_STREAM_PLAYER_STATUS = 'SET_PIXEL_STREAM_PLAYER_STATUS';

export const DID_RECEIVE_PIXEL_STREAM_PLAYER_STATUS =
  'DID_RECEIVE_PIXEL_STREAM_PLAYER_STATUS';

export const DID_LOAD_PRODUCT = 'DID_LOAD_PRODUCT';
export const DID_SELECT_PRODUCT = 'DID_SELECT_PRODUCT';
export const UPDATE_SHOWING_DESCRIPTION_FOR_PRODUCT_ID =
  'UPDATE_SHOWING_DESCRIPTION_FOR_PRODUCT_ID';

// Meeting Actions

export const DID_JOIN_MEETING = 'MEETING.DID_JOIN_MEETING';
export const ENTER_MEETING_FROM_WELCOME_VIDEO =
  'MEETING.ENTER_MEETING_FROM_WELCOME_VIDEO';
export const PLAY_WELCOME_VIDEO_FROM_LOUNGE =
  'MEETING.PLAY_WELCOME_VIDEO_FROM_LOUNGE';
export const DID_START_WALKTHROUGH_FROM_LOUNGE =
  'MEETING.DID_START_WALKTHROUGH_FROM_LOUNGE';
export const DID_LEAVE_MEETING = 'MEETING.DID_LEAVE_MEETING';
export const PARTICIPANT_DID_LEAVE_MEETING =
  'MEETING.PARTICIPANT_DID_LEAVE_MEETING';
export const PARTICIPANT_DID_JOIN_MEETING =
  'MEETING.PARTICIPANT_DID_JOIN_MEETING';
export const DID_RECEIVE_MOVE_TO_SCENE_COMMAND =
  'MEETING.DID_RECEIVE_MOVE_TO_SCENE_COMMAND';
export const DID_RECEIVE_CHANGE_TO_VIEW_COMMAND =
  'MEETING.DID_RECEIVE_CHANGE_TO_VIEW_COMMAND';
export const REMOTE_TRACK_AUDIO_LEVEL_DID_CHANGE =
  'MEETING.REMOTE_TRACK_AUDIO_LEVEL_DID_CHANGE';
export const LOCAL_TRACK_AUDIO_LEVEL_DID_CHANGE =
  'MEETING.LOCAL_TRACK_AUDIO_LEVEL_DID_CHANGE';
export const LOCAL_TRACK_AUDIO_MUTE_DID_CHANGE =
  'MEETING.LOCAL_TRACK_AUDIO_MUTE_DID_CHANGE';
export const REMOTE_TRACK_AUDIO_MUTE_DID_CHANGE =
  'MEETING.REMOTE_TRACK_AUDIO_MUTE_DID_CHANGE';
export const LOCAL_TRACK_VIDEO_MUTE_DID_CHANGE =
  'MEETING.LOCAL_TRACK_VIDEO_MUTE_DID_CHANGE';
export const REMOTE_TRACK_VIDEO_MUTE_DID_CHANGE =
  'MEETING.REMOTE_TRACK_VIDEO_MUTE_DID_CHANGE';
export const UPDATE_MEETING_STATS = 'MEETING.UPDATE_MEETING_STATS';
export const UPDATE_REMOTE_USER_MEETING_STATS =
  'MEETING.UPDATE_REMOTE_USER_MEETING_STATS';
export const DID_RECEIVE_ENDPOINT_MESSAGE =
  'MEETING.DID_RECEIVE_ENDPOINT_MESSAGE';
export const ADVISOR_END_MEETING = 'MEETING.ADVISOR_END_MEETING';
export const SET_MEETING_AUTO_ADMIT = 'MEETING.SET_MEETING_AUTO_ADMIT';
export const SET_MEETING_ID = 'MEETING.SET_MEETING_ID';
export const SET_IS_MEETING_PRESENTER = 'MEETING.SET_IS_MEETING_PRESENTER';
export const VB_MEETING_OVERLAY = 'MEETING.VB_MEETING_OVERLAY';
export const SHOW_CLIENT_DETAIL_PANEL = 'MEETING.SHOW_CLIENT_DETAIL_PANEL';
export const HIDE_CLIENT_DETAIL_PANEL = 'MEETING.HIDE_CLIENT_DETAIL_PANEL';
export const POPUP_DID_LOAD_PAGE = 'MEETING.POPUP_DID_LOAD_PAGE';
export const POPUP_DID_SCROLL_PAGE = 'MEETING.POPUP_DID_SCROLL_PAGE';
export const PAGE_DID_SCROLL = 'MEETING.PAGE_DID_SCROLL';
export const PARTICIPANT_READY = 'MEETING.PARTICIPANT_READY';
export const DID_RECEIVE_PARTICIPANT_USER_INFO =
  'MEETING.DID_RECEIVE_PARTICIPANT_USER_INFO';
export const DID_RECEIVE_REQUEST_USER_INFO =
  'MEETING.DID_RECEIVE_REQUEST_USER_INFO';
export const DID_RECEIVE_ADVISOR_INFO = 'MEETING.DID_RECEIVE_ADVISOR_INFO';
export const DID_RECEIVE_REQUEST_ADVISOR_INFO =
  'MEETING.DID_RECEIVE_REQUEST_ADVISOR_INFO';
export const REMOTE_POPUP_ACTION = 'MEETING.REMOTE_POPUP_ACTION';
export const DID_RECEIVE_REMOTE_POPUP_ACTION =
  'MEETING.DID_RECEIVE_REMOTE_POPUP_ACTION';
export const REQUEST_PARTICIPANT_USER_INFO =
  'MEETING.REQUEST_PARTICIPANT_USER_INFO';
export const REQUEST_PARTICIPANT_ADVISOR_INFO =
  'MEETING.REQUEST_PARTICIPANT_ADVISOR_INFO';
export const REQUEST_TO_BE_ADMITTED_IN_MEETING =
  'MEETING.REQUEST_TO_BE_ADMITTED_IN_MEETING';
export const REQUEST_TO_BE_PRESENTER = 'MEETING.REQUEST_TO_BE_PRESENTER';
export const SEND_USER_INFO = 'MEETING.SEND_USER_INFO';
export const SEND_ADVISOR_INFO = 'MEETING.SEND_ADVISOR_INFO';
export const ADVISOR_INIT = 'MEETING.ADVISOR_INIT';
export const UPDATE_PARTICIPANT_WISHLIST_PRODUCTS =
  'MEETING.UPDATE_PARTICIPANT_WISHLIST_PRODUCTS';
export const UPDATE_PARTICIPANT_INTERESTED_PRODUCTS =
  'MEETING.UPDATE_PARTICIPANT_INTERESTED_PRODUCTS';
export const ENTER_MEETING = 'MEETING.ENTER_MEETING';
export const UPDATE_REMOTE_DISPLAY_NAME = 'MEETING.UPDATE_REMOTE_DISPLAY_NAME';
export const LATE_COMER_DID_JOIN_LOUNGE = 'MEETING.LATE_COMER_DID_JOIN_LOUNGE';
export const ADMIT_LATE_COMER_TO_MEETING =
  'MEETING.ADMIT_LATE_COMER_TO_MEETING';
export const BRIDGE_CHANNEL_OPENED = 'MEETING.BRIDGE_CHANNEL_OPENED';
export const BRIDGE_CHANNEL_CLOSED = 'MEETING.BRIDGE_CHANNEL_CLOSED';
export const KICK_OUT_DIFFERENT_ADVISOR = 'MEETING.KICK_OUT_DIFFERENT_ADVISOR';
export const JOIN_AS_ADVISOR = 'MEETING.JOIN_AS_ADVISOR';
export const JOIN_AGAIN_AS_CLIENT = 'MEETING.JOIN_AGAIN_AS_CLIENT';
export const DID_ADD_REMOTE_TRACK = 'MEETING.DID_ADD_REMOTE_TRACK';
export const DID_ADD_LOCAL_TRACK = 'MEETING.DID_ADD_LOCAL_TRACK';
export const DID_REMOVE_REMOTE_TRACK = 'MEETING.DID_REMOVE_REMOTE_TRACK';
export const DID_REMOVE_LOCAL_TRACK = 'MEETING.DID_REMOVE_LOCAL_TRACK';
export const TOGGLE_ENLARGE_VIDEO = 'MEETING.TOGGLE_ENLARGE_VIDEO';
export const SET_ENLARGE_VIDEO = 'MEETING.SET_ENLARGE_VIDEO';
export const TRANSFER_PRESENTER_ROLE = 'MEETING.TRANSFER_PRESENTER_ROLE';
export const REMOTE_SHOW_HOT_SPOT = 'MEETING.REMOTE_SHOW_HOT_SPOT';
export const REMOTE_HIDE_HOT_SPOT = 'MEETING.REMOTE_HIDE_HOT_SPOT';
export const ADD_DEVICES_LIST = 'MEETING.ADD_DEVICES_LIST';
export const DID_ADD_DEVICES_LIST = 'MEETING.DID_ADD_DEVICES_LIST';
export const SET_AUDIO_INPUT = 'MEETING.SET_AUDIO_INPUT';
export const SET_AUDIO_OUTPUT = 'MEETING.SET_AUDIO_OUTPUT';
export const SET_VIDEO_INPUT = 'MEETING.SET_VIDEO_INPUT';
export const SET_INPUT_CHANGE_AVAILABLE = 'MEETING.SET_INPUT_CHANGE_AVAILABLE';
export const SET_OUTPUT_CHANGE_AVAILABLE =
  'MEETING.SET_OUTPUT_CHANGE_AVAILABLE';
export const DID_CHANGE_DOMINANT_SPEAKER =
  'MEETING.DID_CHANGE_DOMINANT_SPEAKER';
export const DID_CHANGE_CONNECTION_STATUS =
  'MEETING.DID_CHANGE_CONNECTION_STATUS';

export const SHOW_NOTIFICATION = 'MEETING.SHOW_NOTIFICATION';
export const DID_GET_STORE_DEVICES = 'MEETING.DID_GET_STORE_DEVICES';

export const ZOOM_STUDIO_VIDEO = 'MEETING.ZOOM_STUDIO_VIDEO';
export const ROTATE_STUDIO_VIDEO = 'MEETING.ROTATE_STUDIO_VIDEO';
export const SET_STUDIO_VIEW_STATE = 'MEETING.SET_STUDIO_VIEW_STATE';
export const UPDATE_LAYOUT_MODE = 'MEETING.UPDATE_LAYOUT_MODE';
export const UPDATE_ONE_THIRD_LAYOUT = 'MEETING.UPDATE_ONE_THIRD_LAYOUT';
export const EXIT_ONE_THIRD_LAYOUT = 'MEETING.EXIT_ONE_THIRD_LAYOUT';
export const TOGGLE_SHOW_LAYOUT_CONTROL_BUTTON =
  'MEETING.TOGGLE_SHOW_LAYOUT_CONTROL_BUTTON';
export const SET_LAYOUT_STATE = 'MEETING.SET_LAYOUT_STATE';
export const ADD_LAYOUT_PRESENTER = 'MEETING.ADD_LAYOUT_PRESENTER';
export const REMOVE_LAYOUT_PRESENTER = 'MEETING.REMOVE_LAYOUT_PRESENTER';
export const KICK_PARTICIPANT_FROM_MEETING =
  'MEETING.KICK_PARTICIPANT_FROM_MEETING';
export const TOGGLE_PARTICIPANT_VISIBILITY =
  'MEETING.TOGGLE_PARTICIPANT_VISIBILITY';
export const TOGGLE_PIN_PARTICIPANT = 'MEETING.TOGGLE_PIN_PARTICIPANT';
export const KICKED_OUT_FROM_MEETING = 'MEETING.KICKED_OUT_FROM_MEETING';

export const ACTIVATE_FEATURES = 'MEETING.ACTIVATE_FEATURES';

export const MUTE_PARTICIPANT_AUDIO_TRACK =
  'MEETING.MUTE_PARTICIPANT_AUDIO_TRACK';
export const UNMUTE_PARTICIPANT_AUDIO_TRACK =
  'MEETING.UNMUTE_PARTICIPANT_AUDIO_TRACK';
export const UNMUTE_ALL_PARTICIPANTS_AUDIO_TRACK =
  'MEETING.UNMUTE_ALL_PARTICIPANTS_AUDIO_TRACK';
export const TOGGLE_RAISE_HAND = 'MEETING.TOGGLE_RAISE_HAND';
export const DID_RECEIVE_TOGGLE_RAISE_HAND =
  'MEETING.DID_RECEIVE_TOGGLE_RAISE_HAND';
export const REMOVE_RAISE_HAND_IN_ALERT = 'MEETING.REMOVE_RAISE_HAND_IN_ALERT';
export const SET_RAISE_HAND_STATE = 'MEETING.SET_RAISE_HAND_STATE';
export const APPROVE_RAISE_HAND = 'MEETING.ACCEPT_RAISE_HAND';

export const ADVISOR_ADD_TO_WISH_LIST = 'MEETING.ADVISOR_ADD_TO_WISH_LIST';

export const SHOW_MEETING_NOTIFICATION = 'MEETING.SHOW_MEETING_NOTIFICATION';

export const HIDE_MEETING_NOTIFICATION = 'MEETING.HIDE_MEETING_NOTIFICATION';

export const UPDATE_MEETING_SERVER_URL = 'MEETING.UPDATE_MEETING_SERVER_URL';

export const OPEN_PARTICIPANT_CONTROLS = 'MEETING.OPEN_PARTICIPANT_CONTROLS';

export const OPEN_VIRTUAL_BACKGROUND_DIALOG =
  'MEETING.OPEN_VIRTUAL_BACKGROUND_DIALOG';

export const TOGGLE_VIRTUAL_BACKGROUND_EFFECT =
  'MEETING.CHANGE_LOCAL_VIDEO_EFFECT';

export const TOGGLE_SHARE_SCREEN = 'MEETING.TOGGLE_SHARE_SCREEN';
export const TOGGLE_PRESENTER_MODE = 'MEETING.TOGGLE_PRESENTER_MODE';

export const MIRROR_ACTION = 'MEETING.MIRROR_ACTION';
export const SEND_CHAT = 'MEETING.SEND_CHAT';
export const DELETE_CHAT = 'MEETING.DELETE_CHAT';
export const LOAD_CHAT = 'MEETING.LOAD_CHAT';

export const POPUP_VIDEO_DID_PLAY_WITH_SOUND =
  'MEETING.POPUP_VIDEO_DID_PLAY_WITH_SOUND';
export const POPUP_DID_REQUEST_HIDE_MEETING_VEIL =
  'MEETING.POPUP_DID_REQUEST_HIDE_MEETING_VEIL';
export const HIDE_MEETING_CONTROLS = 'MEETING.HIDE_MEETING_CONTROLS';

export const SET_MEETING_DATE = 'MEETING.SET_MEETING_DATE';
export const RESYNC_MEETING_INFO = 'MEETING.RESYNC_MEETING_INFO';
export const TOGGLE_STATS_MODE = 'MEETING.TOGGLE_STATS_MODE';

export const CART_ADD_PRODUCT_FROM_POPUP = 'CART.ADD_PRODUCT_FROM_POPUP';
export const CART_ADD_PRODUCT = 'CART.ADD_PRODUCT';
export const CART_REMOVE_PRODUCT = 'CART.REMOVE_PRODUCT';
export const CART_ADD_QUANTITY = 'CART.ADD_QUANTITY';
export const CART_REDUCE_QUANTITY = 'CART.REDUCE_QUANTITY';
export const CART_UPDATE_SIZE = 'CART.UPDATE_SIZE';
export const CART_UPDATE_FORM_DATA = 'CART.UPDATE_FORM_DATA';
export const CART_UPDATE_CHECKOUT_FORM_DATA = 'CART.UPDATE_CHECKOUT_FORM_DATA';
export const CART_OPEN_POPUP = 'CART.OPEN_POPUP';
export const CART_UPDATE_SEND_STATUS = 'CART.UPDATE_SEND_STATUS';
export const CART_UPDATE_CHECKOUT_STEP = 'CART.UPDATE_CHECKOUT_STEP';
export const CART_UPDATE_CHECKOUT_STATUS = 'CART.UPDATE_CHECKOUT_STATUS';
export const CART_UPDATE_CHECKOUT_PAYMENT_STATUS =
  'CART.UPDATE_CHECKOUT_PAYMENT_STATUS';
export const CART_UPDATE_CHECKOUT_ORDER_ID = 'CART.UPDATE_CHECKOUT_ORDER_ID';
export const CART_UPDATE_CHECKOUT_TOKEN = 'CART.UPDATE_CHECKOUT_TOKEN';
export const CART_CLEAR_PRODUCTS = 'CART.CLEAR_PRODUCTS';

// Advisor Hub Actions
export const HUB_UPDATE_LOGIN_STATUS = 'HUB.UPDATE_LOGIN_STATUS';
export const HUB_UPDATE_LOGIN_ERROR = 'HUB.UPDATE_LOGIN_ERROR';

export const HUB_UPDATE_NUDGE_CONTENT = 'HUB.UPDATE_NUDGE_CONTENT';
export const HUB_UPDATE_NUDGE_RECIPIENT = 'HUB.UPDATE_NUDGE_RECIPIENT';
export const HUB_UPDATE_NUDGE_TITLE = 'HUB.UPDATE_NUDGE_TITLE';
export const HUB_UPDATE_NUDGE_DESCRIPTION = 'HUB.UPDATE_NUDGE_DESCRIPTION';
export const HUB_UPDATE_NUDGE_CURRENCY = 'HUB.UPDATE_NUDGE_CURRENCY';
export const HUB_CLEAR_NUDGE = 'HUB.CLEAR_NUDGE';
export const HUB_CREATE_NEW_NUDGE = 'HUB.CREATE_NEW_NUDGE';
export const HUB_UPDATE_NUDGE_OG_TITLE = 'HUB.UPDATE_NUDGE_OG_TITLE';
export const HUB_UPDATE_NUDGE_OG_DESCRIPTION =
  'HUB.UPDATE_NUDGE_OG_DESCRIPTION';
export const HUB_UPDATE_NUDGE_OG_IMAGE = 'HUB.UPDATE_NUDGE_OG_IMAGE';

export const HUB_ADD_USER_INFO = 'HUB.ADD_USER_INFO';

export const HUB_UPDATE_FOLDER = 'HUB.UPDATE_FOLDER';
export const HUB_SELECT_FOLDER = 'HUB.SELECT_FOLDER';
export const FOLDER_CURRENT_CONTEXT = 'HUB.FOLDER_CURRENT_CONTEXT';
export const HUB_LOAD_FOLDERS = 'HUB.LOAD_FOLDERS';
export const HUB_UPDATE_ELEMENTS_IN_FOLDER_KEYS =
  'HUB.UPDATE_ELEMENTS_IN_FOLDER_KEYS';

export const HUB_ADD_SEARCH_PRODUCT_FAMILIES =
  'HUB.ADD_SEARCH_PRODUCT_FAMILIES';
export const HUB_INIT = 'HUB.INIT';
export const HUB_SHOW_ALERT = 'HUB.SHOW_ALERT';
export const HUB_CLOSE_ALERT = 'HUB.CLOSE_ALERT';

export const HUB_LOAD_USER = 'HUB.LOAD_USER';
export const HUB_UPDATE_USER_ATTRIBUTES = 'HUB.UPDATE_USER_ATTRIBUTES';
export const HUB_LOAD_NUDGE = 'HUB.LOAD_NUDGE';
export const HUB_LOAD_NUDGE_LIST = 'HUB.LOAD_NUDGE_LIST';
export const HUB_LOAD_NUDGE_TEMPLATE_LIST = 'HUB.LOAD_NUDGE_TEMPLATE_LIST';
export const HUB_UPDATE_PROGRESS_STATUS = 'HUB.UPDATE_PROGRESS_STATUS';
export const HUB_COPY_AS_NEW_NUDGE = 'HUB.COPY_AS_NEW_NUDGE';

export const HUB_UPDATE_NUDGE_LANGUAGE = 'HUB.UPDATE_NUDGE_LANGUAGE';

export const HUB_UPDATE_SESSION_PROGRESS_STATUS =
  'HUB.UPDATE_SESSION_PROGRESS_STATUS';
export const HUB_PEXEL_UPLOAD_STATUS = 'HUB.PEXEL_UPLOAD_STATUS';
export const HUB_UPDATE_SESSION_DATE = 'HUB.UPDATE_SESSION_DATE';
export const HUB_LOAD_SESSION_LIST = 'HUB.LOAD_SESSION_LIST';
export const HUB_UPDATE_SESSION_FORM_DATA = 'HUB.UPDATE_SESSION_FORM_DATA';
export const HUB_SESSION_UPDATE_FILES = 'HUB.SESSION_UPDATE_FILES';
export const HUB_SESSION_UPDATE_FILE_UPLOAD_PROGRESS =
  'HUB.SESSION_UPDATE_FILE_UPLOAD_PROGRESS';
export const HUB_SESSION_UPDATE_FILE_UPLOAD_STATUS =
  'HUB.SESSION_UPDATE_FILE_UPLOAD_STATUS';
export const HUB_UPDATE_FAVORITES = 'HUB.UPDATE_FAVORITES';
export const HUB_TOGGLE_SHOW_ONLY_FAVORITES = 'HUB.TOGGLE_SHOW_ONLY_FAVORITES';
export const HUB_SET_LOADING_STATUS = 'HUB.SET_LOADING_STATUS';

export const HUB_SET_FILE_UPLOAD = 'HUB.SET_FILE_UPLOAD';
export const HUB_CLEAR_FILE_UPLOAD = 'HUB.CLEAR_FILE_UPLOAD';
export const HUB_ABORT_FILE_UPLOAD = 'HUB.ABORT_FILE_UPLOAD';
export const HUB_CONVERTED_FILE_UPLOAD = 'HUB.CONVERTED_FILE_UPLOAD';
export const HUB_ADD_FILE_UPLOADED = 'HUB.ADD_FILE_UPLOADED';
export const HUB_SET_COLLABORATION_STATE = 'HUB.SET_COLLABORATION_STATE';
export const HUB_UPDATE_SCENE = 'HUB.UPDATE_SCENE';

export const HUB_ASSET_LIBRARY_LIST_ASSETS_PENDING =
  'HUB.ASSET_LIBRARY_LIST_ASSETS_PENDING';
export const HUB_ASSET_LIBRARY_LIST_ASSETS_SUCCESSFUL =
  'HUB.ASSET_LIBRARY_LIST_ASSETS_SUCCESSFUL';
export const HUB_ASSET_LIBRARY_LIST_ASSETS_FAILED =
  'HUB.ASSET_LIBRARY_LIST_ASSETS_FAILED';

export const HUB_ASSET_LIBRARY_UPDATE_ASSET = 'HUB.ASSET_LIBRARY_UPDATE_ASSET';
export const HUB_ASSET_LIBRARY_DELETE_ASSETS_FROM_STORE =
  'HUB.ASSET_LIBRARY_DELETE_ASSETS_FROM_STORE';
export const HUB_ASSET_LIBRARY_ADD_UPDATING_ASSET =
  'HUB.ASSET_LIBRARY_ADD_UPDATING_ASSET';
export const PIXEL_STREAM_DID_FOCUS_ON_AN_OBJECT =
  'PIXEL_STREAM_DID_FOCUS_ON_AN_OBJECT';

export const HUB_SET_ASSET_LIBRARY_POPUP = 'HUB.SET_ASSET_LIBRARY_POPUP';

export const UPDATE_VIEWPORT_INFO = 'UPDATE_VIEWPORT_INFO';
export const BROWSE_STORYBOOK_IN_MEETING = 'BROWSE_STORYBOOK_IN_MEETING';

export const SET_PRODUCT_AR_VIEWER = 'SET_PRODUCT_AR_VIEWER';

export const REQUEST_HIDE_MEETING_VEIL = 'REQUEST_HIDE_MEETING_VEIL';

export const actionSetPixelStreamServer = (payload: IPixelStreamServer) => ({
  type: SET_PIXEL_STREAM_SERVER,
  payload
});

export const actionSetPixelStreamConnectionStatus = (
  payload: StreamConnectionStatus
) => ({
  type: SET_PIXEL_STREAM_CONNECTION_STATUS,
  payload
});

export const actionSetPixelStreamPlayerStatus = (
  payload: StreamPlayerStatus
) => ({
  type: SET_PIXEL_STREAM_PLAYER_STATUS,
  payload
});

export const actionReceiveParticipantPSPlayerStatus = (payload: {
  participantId: string;
  playerStatus: StreamPlayerStatus;
}) => ({
  type: DID_RECEIVE_PIXEL_STREAM_PLAYER_STATUS,
  payload
});

export const actionDidLoadProductInspirations = (
  product: IProduct,
  inspirations: IInspiration[]
) => ({
  type: DID_LOAD_PRODUCT_INSPIRATIONS,
  payload: { product, inspirations }
});

export const actionSetInspirationsList = (inspirations: IInspiration[]) => ({
  type: SET_INSPIRATIONS_LIST,
  payload: inspirations
});

export const actionOpenVBPopup = (url: string, darkHeader = false) => ({
  type: VB_OPEN_POPUP,
  payload: {
    url,
    darkHeader
  }
});

export const actionPopupRedirect = (url: string) => ({
  type: VB_POPUP_REDIRECT,
  payload: url
});
export const actionUpdateVBPopupPageType = (payload: PageView) => ({
  type: VB_UPDATE_POPUP_PAGE_TYPE,
  payload
});

export const actionCloseVBPopup = () => ({
  type: VB_CLOSE_POPUP,
  payload: null
});

export const actionAddProductToCompare = (product: IComparedProduct) => ({
  type: ADD_PRODUCT_TO_COMPARE,
  payload: product
});

export const actionRemoveProductFromCompare = (product: IComparedProduct) => ({
  type: REMOVE_PRODUCT_FROM_COMPARE,
  payload: product
});

export const actionFocusItem = (payload?: FocusedItemState) => ({
  type: FOCUS_ITEM,
  payload
});

export const actionUpdateWishlist = (payload: IWishlistPayload) => ({
  type: UPDATE_WISHLIST,
  payload
});

export const actionOpenVBJourney = () => ({
  type: VB_OPEN_JOURNEY,
  payload: null
});

export const actionHideVBJourney = () => ({
  type: VB_HIDE_JOURNEY,
  payload: null
});

export const actionEnableVBJourney = () => ({
  type: VB_ENABLE_JOURNEY,
  payload: null
});

export const actionDisableVBJourney = () => ({
  type: VB_DISABLE_JOURNEY,
  payload: null
});

export const actionDidViewPage = (pageView: PageView) => ({
  type: VIEW_PAGE,
  payload: {
    pageView,
    url: `${location.pathname}${location.search}`
  }
});

export const actionDidBackButton = (pageView: PageView) => ({
  type: CLICK_BACK_BUTTON,
  payload: {
    pageView,
    url: `${location.pathname}${location.search}`
  }
});

export const actionDidGoToScene = (sceneId: string) => ({
  type: VB_DID_GO_TO_SCENE,
  payload: sceneId
});

export const actionDidLookToView = (view: PanoView) => ({
  type: VB_DID_LOOK_TO_VIEW,
  payload: view
});

export const actionGoBackToLastScene = () => ({
  type: VB_DID_GO_BACK_TO_SCENE,
  payload: null
});

export const actionUpdateVirtualBoutiqueMode = (mode: VirtualBoutiqueMode) => ({
  type: VB_UPDATE_MODE,
  payload: mode
});

export const actionDidJoinMeeting = (
  participantId: string,
  role: MeetingRole,
  room: JitsiMeetingRoom
) => ({
  type: DID_JOIN_MEETING,
  payload: {
    participantId,
    role,
    room
  }
});

export const actionEnterMeetingFromWelcomeVideo = () => ({
  type: ENTER_MEETING_FROM_WELCOME_VIDEO,
  payload: null
});

export const actionPlayWelcomeVideoFromLounge = () => ({
  type: PLAY_WELCOME_VIDEO_FROM_LOUNGE,
  payload: null
});

export const actionDidLeaveMeeting = () => ({
  type: DID_LEAVE_MEETING,
  payload: null
});

export const actionParticipantDidJoinMeeting = (
  participantId: string,
  user: JitsiParticipant
) => ({
  type: PARTICIPANT_DID_JOIN_MEETING,
  payload: {
    participantId,
    user
  }
});

export const actionParticipantDidLeaveMeeting = (participantId: string) => ({
  type: PARTICIPANT_DID_LEAVE_MEETING,
  payload: participantId
});

export const actionDidReceiveMoveToSceneCommand = (sceneId: string) => ({
  type: DID_RECEIVE_MOVE_TO_SCENE_COMMAND,
  payload: sceneId
});

export const actionDidReceiveChangeToViewCommand = (view: PanoView) => ({
  type: DID_RECEIVE_CHANGE_TO_VIEW_COMMAND,
  payload: view
});

export const actionSetMeetingId = (meetingId: string) => ({
  type: SET_MEETING_ID,
  payload: meetingId
});

export const actionSetIsMeetingPresenter = (isPresenter: boolean) => ({
  type: SET_IS_MEETING_PRESENTER,
  payload: isPresenter
});

export const actionMeetingOverlay = (showOverlay: boolean) => ({
  type: VB_MEETING_OVERLAY,
  payload: showOverlay
});

export const actionPopupDidLoadPage = (url: string) => ({
  type: POPUP_DID_LOAD_PAGE,
  payload: url
});

export const actionPopupDidScrollPage = (scrollPercentage: number) => ({
  type: POPUP_DID_SCROLL_PAGE,
  payload: scrollPercentage
});

export const actionPageDidScroll = (scrollPercentage: number) => ({
  type: PAGE_DID_SCROLL,
  payload: scrollPercentage
});

export const actionDidReceiveRemotePopupAction = (nestedAction: AnyAction) => ({
  type: DID_RECEIVE_REMOTE_POPUP_ACTION,
  payload: nestedAction
});

export const actionShowClientDetailPanel = (participantId: string) => ({
  type: SHOW_CLIENT_DETAIL_PANEL,
  payload: participantId
});

export const actionHideClientDetailPanel = () => ({
  type: HIDE_CLIENT_DETAIL_PANEL,
  payload: null
});

export const actionDidReceiveParticipantUserInfo = (
  participantId: string,
  identityId: string,
  role: MeetingRole
) => ({
  type: DID_RECEIVE_PARTICIPANT_USER_INFO,
  payload: { participantId, identityId, role }
});

export const actionParticipantReady = (participantId: string) => ({
  type: PARTICIPANT_READY,
  payload: { participantId }
});

export const actionDidReceiveAdvisorInfo = (
  identityId: string,
  displayName: string,
  participantId: string
) => ({
  type: DID_RECEIVE_ADVISOR_INFO,
  payload: { displayName, identityId, participantId }
});

export const actionDidReceiveRequestUserInfo = () => ({
  type: DID_RECEIVE_REQUEST_USER_INFO,
  payload: null
});

export const actionDidReceiveRequestAdvisorInfo = () => ({
  type: DID_RECEIVE_REQUEST_ADVISOR_INFO,
  payload: null
});

export const actionRequestParticipantUserInfo = () => ({
  type: REQUEST_PARTICIPANT_USER_INFO,
  payload: null
});

export const actionAdvisorMeetingInit = () => ({
  type: ADVISOR_INIT,
  payload: null
});

export const actionRequestAdvisorInfo = () => ({
  type: REQUEST_PARTICIPANT_ADVISOR_INFO,
  payload: null
});

export const actionRequestToBeAdmittedInMeeting = () => ({
  type: REQUEST_TO_BE_ADMITTED_IN_MEETING,
  payload: null
});

export const actionSendUserInfo = (payload: MeetingRole) => ({
  type: SEND_USER_INFO,
  payload
});

export const actionSendAdvisorInfo = () => ({
  type: SEND_ADVISOR_INFO,
  payload: null
});

export const actionUpdateParticipantWishlistProducts = (
  participantId: string,
  products: IProduct[]
) => ({
  type: UPDATE_PARTICIPANT_WISHLIST_PRODUCTS,
  payload: { products, participantId }
});

export const actionUpdateParticipantInterestedProducts = (
  participantId: string,
  products: IProduct[]
) => ({
  type: UPDATE_PARTICIPANT_INTERESTED_PRODUCTS,
  payload: { products, participantId }
});

export const actionLocalTrackAudioLevelDidChange = (audioLevel: number) => ({
  type: LOCAL_TRACK_AUDIO_LEVEL_DID_CHANGE,
  payload: { audioLevel }
});

export const actionRemoteTrackAudioLevelDidChange = (
  participantId: string,
  audioLevel: number
) => ({
  type: REMOTE_TRACK_AUDIO_LEVEL_DID_CHANGE,
  payload: { participantId, audioLevel }
});

export const actionUpdateMeetingStats = (stats: IMeetingStats) => ({
  type: UPDATE_MEETING_STATS,
  payload: stats
});

export const actionUpdateRemoteUserMeetingStats = (
  participantId: string,
  stats: IRemoteUserStats
) => ({
  type: UPDATE_REMOTE_USER_MEETING_STATS,
  payload: { participantId, stats }
});

export const actionEnterMeeting = (
  meetingState?: ParticipantMeetingState,
  admittedParticipantIds?: string[]
) => ({
  type: ENTER_MEETING,
  payload: {
    admittedParticipantIds,
    meetingState
  }
});

export const actionLocalTrackAudioMuteDidChange = (
  isMuted: boolean,
  restoreLater?: boolean
) => ({
  type: LOCAL_TRACK_AUDIO_MUTE_DID_CHANGE,
  payload: { isMuted, restoreLater }
});

export const actionRemoteTrackAudioMuteDidChange = (
  participantId: string,
  isMuted: boolean
) => ({
  type: REMOTE_TRACK_AUDIO_MUTE_DID_CHANGE,
  payload: { participantId, isMuted }
});

export const actionLocalTrackVideoMuteDidChange = (isMuted: boolean) => ({
  type: LOCAL_TRACK_VIDEO_MUTE_DID_CHANGE,
  payload: { isMuted }
});

export const actionRemoteTrackVideoMuteDidChange = (
  participantId: string,
  isMuted: boolean
) => ({
  type: REMOTE_TRACK_VIDEO_MUTE_DID_CHANGE,
  payload: { participantId, isMuted }
});

export const actionUpdateRemoteDisplayName = (
  participantId: string,
  displayName: string
) => ({
  type: UPDATE_REMOTE_DISPLAY_NAME,
  payload: { participantId, displayName }
});

export const actionLateComerDidJoinLounge = (
  participantId: string,
  displayName: string | undefined
) => ({
  type: LATE_COMER_DID_JOIN_LOUNGE,
  payload: { participantId, displayName }
});

export const actionDidAdmitLateComerToMeeting = (
  participantId: string,
  meetingState?: ParticipantMeetingState
) => ({
  type: ADMIT_LATE_COMER_TO_MEETING,
  payload: { participantId, meetingState }
});

export const actionKickOutDifferentAdvisor = (participantId: string) => ({
  type: KICK_OUT_DIFFERENT_ADVISOR,
  payload: { participantId }
});

export const actionJoinAgainAsClient = () => ({
  type: JOIN_AGAIN_AS_CLIENT,
  payload: null
});

export const actionDidLoadLocalUser = (user: IUser) => ({
  type: DID_LOAD_LOCAL_USER,
  payload: user
});

export const actionDidLoadLocalUserFailed = () => ({
  type: DID_LOAD_LOCAL_USER_FAILED,
  payload: null
});

export const actionUpdateLocalUser = (user: IUser) => ({
  type: DID_UPDATE_LOCAL_USER,
  payload: user
});

export const actionBridgeChannelOpened = () => ({
  type: BRIDGE_CHANNEL_OPENED,
  payload: null
});

export const actionBridgeChannelClosed = () => ({
  type: BRIDGE_CHANNEL_CLOSED,
  payload: null
});

export const actionAppointmentPopup = (payload: AppointmentPopup) => ({
  type: VB_APPOINTMENT_POPUP,
  payload
});

export const actionSultanateOfOmanPopup = (payload: SultanateOfOmanPopup) => ({
  type: VB_SULTANATE_OF_OMAN_POPUP,
  payload
});

export const actionOpenAppointmentFromPopup = (payload: AppointmentPopup) => ({
  type: OPEN_APPOINTMENT_FROM_POPUP,
  payload
});

export const actionVideoAppointmentPopup = (payload: AppointmentPopup) => ({
  type: VB_VIDEO_APPOINTMENT_POPUP,
  payload
});

export const actionEnquiryPopup = (payload: EnquiryPopup) => ({
  type: VB_ENQUIRY_POPUP,
  payload
});

export const actionOpenEnquiryFromPopup = (payload: EnquiryPopup) => ({
  type: OPEN_ENQUIRY_FROM_POPUP,
  payload
});

export const actionShowContactPopup = (payload: boolean) => ({
  type: VB_CONTACT_POPUP,
  payload
});

export const actionDidAddRemoteTrack = (payload: JitsiRemoteTrack) => ({
  type: DID_ADD_REMOTE_TRACK,
  payload
});

export const actionDidRemoveRemoteTrack = (payload: JitsiRemoteTrack) => ({
  type: DID_REMOVE_REMOTE_TRACK,
  payload
});

export const actionDidAddLocalTrack = (payload: JitsiLocalTrack[]) => ({
  type: DID_ADD_LOCAL_TRACK,
  payload
});

export const actionDidRemoveLocalTrack = (payload: JitsiLocalTrack) => ({
  type: DID_REMOVE_LOCAL_TRACK,
  payload
});

export const actionToggleEnlargeVideo = (payload: {
  participantId: string;
  shouldEnlarge: boolean;
}) => ({
  type: TOGGLE_ENLARGE_VIDEO,
  payload
});

export const actionSetEnlargeVideo = (payload: { participantId: string }) => ({
  type: SET_ENLARGE_VIDEO,
  payload
});

export const actionTransferPresenterRole = (payload: {
  participantId: string;
}) => ({
  type: TRANSFER_PRESENTER_ROLE,
  payload
});

export const actionRemoteShowHotSpot = (payload: string) => ({
  type: REMOTE_SHOW_HOT_SPOT,
  payload
});

export const actionRemoteHideHotSpot = (payload: string) => ({
  type: REMOTE_HIDE_HOT_SPOT,
  payload
});

export const actionFeedbackPopup = (payload: boolean) => ({
  type: VB_FEEDBACK_POPUP,
  payload
});

export const actionFeedbackTimerFired = () => ({
  type: VB_FEEDBACK_TIMER_FIRED,
  payload: null
});

export const actionClickContact = () => ({
  type: VB_DID_CLICK_CONTACT,
  payload: null
});

export const actionDidLoadRelatedProductsForInspiration = (
  inspirationId: string,
  products: IProduct[]
) => ({
  type: DID_LOAD_RELATED_PRODUCTS_FOR_INSPIRATION,
  payload: {
    inspirationId,
    products
  }
});

export const actionStartLoadingRelatedProductsForInspiration = () => ({
  type: START_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION,
  payload: null
});

export const actionFinishLoadingRelatedProductsForInspiration = () => ({
  type: FINISH_LOADING_RELATED_PRODUCTS_FOR_INSPIRATION,
  payload: null
});

export const actionClickProductColor = (payload: number) => ({
  type: CLICK_PRODUCT_COLOR,
  payload
});

export const actionShowProductDescription = (payload: boolean) => ({
  type: SHOW_PRODUCT_DESCRIPTION,
  payload
});

export const actionShowProductSpecifications = (payload: boolean) => ({
  type: SHOW_PRODUCT_SPECIFICATIONS,
  payload
});

export const actionDidLoadStoreJourneyConfig = (
  payload: PanoJourneyConfig
) => ({
  type: VB_DID_LOAD_JOURNEY_CONFIG,
  payload
});

export const actionDidLoadStoreChatConfig = (payload: ChatConfig) => ({
  type: VB_DID_LOAD_CHAT_CONFIG,
  payload
});

export const actionShowVBZoomIndicator = (payload: boolean) => ({
  type: VB_SHOW_ZOOM_INDICATOR,
  payload
});

export const actionUpdateDiscoverMore = (payload: PanoDiscoverMore) => ({
  type: VB_UPDATE_DISCOVER_MORE,
  payload
});

export const actionDidShowHotSpot = (payload: string) => ({
  type: VB_SHOW_HOT_SPOT,
  payload
});

export const actionDidHideHotSpot = (payload: string) => ({
  type: VB_HIDE_HOT_SPOT,
  payload
});

export const actionVideoDidComplete = (payload: string) => ({
  type: VB_VIDEO_DID_COMPLETE,
  payload
});

export const actionDidClickOnHotSpot = (payload: string) => ({
  type: VB_DID_CLICK_ON_HOT_SPOT,
  payload
});

export const actionMuteBgSound = () => ({
  type: VB_MUTE_BACKGROUND_SOUND,
  payload: null
});

export const actionUnmuteBgSound = () => ({
  type: VB_UNMUTE_BACKGROUND_SOUND,
  payload: null
});

export const actionPanoLoaded = (payload: boolean) => ({
  type: VB_PANO_LOADED,
  payload
});

export const actionShowFullScreenVideoWithUrl = (payload: {
  show: boolean;
  url?: string | IMultilingualVideoUrls;
}) => ({
  type: VB_SHOW_FULL_SCREEN_VIDEO_WITH_URL,
  payload
});

export const actionShowHudButton = (payload: boolean) => ({
  type: VB_SHOW_HUD_BUTTON,
  payload
});

export const actionOpenHud = (payload: boolean) => ({
  type: VB_OPEN_HUD,
  payload
});

export const actionDidClickHudMenu = (menu: RDUHudMenu) => ({
  type: VB_DID_CLICK_HUD_MENU,
  payload: menu
});

export const actionShowWelcomeToScene = (payload: boolean) => ({
  type: VB_SHOW_WELCOME_TO_SCENE,
  payload
});

export const actionShowIWCChristmasVideo = () => ({
  type: VB_SHOW_IWC_XMAS_VIDEO,
  payload: null
});

export const actionToggleVeil = (payload: boolean) => ({
  type: VB_TOGGLE_VEIL,
  payload
});

export const actionAccessRequest = (payload: IAccessRequest) => ({
  type: VB_ACCESS_REQUEST,
  payload
});

export const actionDidStartPlayingFullScreenVideo = (payload: string) => ({
  type: VB_DID_START_PLAYING_FULL_SCREEN_VIDEO,
  payload
});

export const actionOpenMusicPlaylist = (payload: boolean) => ({
  type: VB_OPEN_MUSIC_PLAYLIST,
  payload
});

export const actionUpdateBackgroundMusicUrl = (payload?: string) => ({
  type: VB_UPDATE_BACKGROUND_MUSIC_URL,
  payload
});

export const actionSwitchOffLights = (payload?: boolean) => ({
  type: VB_SWITCH_OFF_LIGHTS,
  payload
});

export const actionShowOutro = (payload: boolean) => ({
  type: VB_SHOW_OUTRO,
  payload
});

export const actionShowStudioJoinButton = (payload: boolean) => ({
  type: VB_SHOW_STUDIO_JOIN_BUTTON,
  payload
});

export const actionLoadNewPano = (payload: any) => ({
  type: VB_LOAD_NEW_PANO,
  payload
});

export const actionReloadStoreDefaultPano = () => ({
  type: VB_RELOAD_DEFAULT_PANO,
  payload: null
});

export const actionDidLoadSimilarProducts = (
  productId: string,
  products: IProduct[]
) => ({
  type: DID_LOAD_SIMILAR_PRODUCTS,
  payload: {
    productId,
    products
  }
});

export const actionStartLoadingSimilarProducts = () => ({
  type: START_LOADING_SIMILAR_PRODUCTS,
  payload: null
});

export const actionFinishLoadingSimilarProducts = () => ({
  type: FINISH_LOADING_SIMILAR_PRODUCTS,
  payload: null
});

export const actionDidClickOnMainVideo = () => ({
  type: VB_DID_CLICK_ON_MAIN_VIDEO,
  payload: null
});

export const actionStartGlitchPano = () => ({
  type: VB_START_GLITCH_PANO,
  payload: null
});

export const actionFlipPano = (sceneName?: string) => ({
  type: VB_FLIP_PANO,
  payload: sceneName
});

export const actionResetAndFlipPano = () => ({
  type: VB_RESET_AND_FLIP_PANO,
  payload: null
});

export const actionDidShowVBCampaign = () => ({
  type: VB_DID_SHOW_CAMPAIGN,
  payload: null
});

export const actionUpdateProductSearchState = (
  payload: ProductSearchState
) => ({
  type: UPDATE_PRODUCT_SEARCH_STATE,
  payload
});

export const actionUpdateStorySearchState = (payload: IStorySearchState) => ({
  type: UPDATE_STORY_SEARCH_STATE,
  payload
});

export const actionDidSlideProductImagesSlider = (slideIndex: number) => ({
  type: DID_SLIDE_PRODUCT_IMAGES_SLIDER,
  payload: slideIndex
});

export const actionVideoPlayedWithSound = (payload: boolean) => ({
  type: VIDEO_PLAYED_WITH_SOUND,
  payload
});

export const actionDidClickPreventInteractionVeil = () => ({
  type: DID_CLICK_ON_PREVENT_INTERACTION_VEIL,
  payload: null
});

export const actionCartAddProductFromPopup = (payload: IProduct) => ({
  type: CART_ADD_PRODUCT_FROM_POPUP,
  payload
});

export const actionCartAddProduct = (
  product: IProduct,
  size?: string,
  noAutoOpen?: boolean
) => ({
  type: CART_ADD_PRODUCT,
  payload: {
    product,
    size,
    noAutoOpen
  }
});

export const actionCartRemoveProduct = (payload: string) => ({
  type: CART_REMOVE_PRODUCT,
  payload
});

export const actionCartAddProductQty = (payload: string) => ({
  type: CART_ADD_QUANTITY,
  payload
});

export const actionCartReduceProductQty = (payload: string) => ({
  type: CART_REDUCE_QUANTITY,
  payload
});

export const actionCartUpdateSize = (id: string, size: string) => ({
  type: CART_UPDATE_SIZE,
  payload: {
    id,
    size
  }
});

export const actionCartUpdateFormData = (payload: IShoppingFormData) => ({
  type: CART_UPDATE_FORM_DATA,
  payload
});

export const actionCartUpdateCheckoutFormData = (
  payload: ICheckoutFormData
) => ({
  type: CART_UPDATE_CHECKOUT_FORM_DATA,
  payload
});

export const actionCartOpenPopup = (payload: boolean) => ({
  type: CART_OPEN_POPUP,
  payload
});

export const actionCartUpdateSendStatus = (payload: EmailSentState) => ({
  type: CART_UPDATE_SEND_STATUS,
  payload
});

export const actionCartUpdateCheckoutStep = (payload: CheckoutStep) => ({
  type: CART_UPDATE_CHECKOUT_STEP,
  payload
});

export const actionCartUpdateCheckoutStatus = (payload: CheckoutStatus) => ({
  type: CART_UPDATE_CHECKOUT_STATUS,
  payload
});

export const actionCartUpdateCheckoutPaymentStatus = (
  payload: CheckoutPaymentStatus
) => ({
  type: CART_UPDATE_CHECKOUT_PAYMENT_STATUS,
  payload
});

export const actionCartUpdateCheckoutOrderId = (payload: string) => ({
  type: CART_UPDATE_CHECKOUT_ORDER_ID,
  payload
});

export const actionCartUpdateCheckoutToken = (payload: string) => ({
  type: CART_UPDATE_CHECKOUT_TOKEN,
  payload
});

export const actionCartClearProducts = () => ({
  type: CART_CLEAR_PRODUCTS,
  payload: null
});

export const actionHubUpdateLoginStatus = (status: LoginStatus) => ({
  type: HUB_UPDATE_LOGIN_STATUS,
  payload: status
});

export const actionHubUpdateLoginError = (message: string) => ({
  type: HUB_UPDATE_LOGIN_ERROR,
  payload: message
});

export const actionHubUpdateNudgeContent = (payload: INudgeItem[]) => ({
  type: HUB_UPDATE_NUDGE_CONTENT,
  payload
});

export const actionHubUpdateNudgeRecipient = (payload: string) => ({
  type: HUB_UPDATE_NUDGE_RECIPIENT,
  payload
});

export const actionHubClearNudge = () => ({
  type: HUB_CLEAR_NUDGE,
  payload: null
});

export const actionHubCreateNewNudge = () => ({
  type: HUB_CREATE_NEW_NUDGE,
  payload: null
});

export const actionHubCopyAsNewNudge = () => ({
  type: HUB_COPY_AS_NEW_NUDGE,
  payload: null
});

export const actionHubAddSearchProductFamilies = (
  payload: ProductFamily[]
) => ({
  type: HUB_ADD_SEARCH_PRODUCT_FAMILIES,
  payload
});

export const actionHubInit = () => ({
  type: HUB_INIT,
  payload: null
});

export const actionHubAlertError = (text: string) => ({
  type: HUB_SHOW_ALERT,
  payload: {
    text,
    type: HubAlertType.ERROR
  }
});

export const actionHubAlertSuccess = (text: string) => ({
  type: HUB_SHOW_ALERT,
  payload: {
    text,
    type: HubAlertType.SUCCESS
  }
});

export const actionHubAlertInfo = (text: string) => ({
  type: HUB_SHOW_ALERT,
  payload: {
    text,
    type: HubAlertType.INFO
  }
});

export const actionHubCloseAlert = () => ({
  type: HUB_CLOSE_ALERT,
  payload: null
});

export const actionHubLoadUser = (payload: IUser) => ({
  type: HUB_LOAD_USER,
  payload
});

export const actionUpdateUserAttr = (payload: IUser) => ({
  type: HUB_UPDATE_USER_ATTRIBUTES,
  payload
});

export const actionHubUpdateProgressStatus = (payload: ProgressStatus) => ({
  type: HUB_UPDATE_PROGRESS_STATUS,
  payload
});

export const actionHubLoadNudge = (payload: INudge) => ({
  type: HUB_LOAD_NUDGE,
  payload
});

export const actionHubUpdateNudgeTitle = (payload: string) => ({
  type: HUB_UPDATE_NUDGE_TITLE,
  payload
});
export const actionHubUpdateNudgeDescription = (payload: string) => ({
  type: HUB_UPDATE_NUDGE_DESCRIPTION,
  payload
});

export const actionHubUpdateNudgeOgTitle = (payload: string) => ({
  type: HUB_UPDATE_NUDGE_OG_TITLE,
  payload
});

export const actionHubUpdateNudgeOgDescription = (payload: string) => ({
  type: HUB_UPDATE_NUDGE_OG_DESCRIPTION,
  payload
});

export const actionHubUpdateNudgeOgImage = (payload: string) => ({
  type: HUB_UPDATE_NUDGE_OG_IMAGE,
  payload
});

export const actionHubUpdateNudgeCurrency = (payload?: {
  currency?: string;
  countryCode?: string;
}) => ({
  type: HUB_UPDATE_NUDGE_CURRENCY,
  payload
});

export const actionHubLoadNudgeList = (payload: INudgePayload[]) => ({
  type: HUB_LOAD_NUDGE_LIST,
  payload
});

export const actionHubLoadNudgeTemplateList = (payload: INudgeTemplate[]) => ({
  type: HUB_LOAD_NUDGE_TEMPLATE_LIST,
  payload
});

export const actionHubUpdateSessionProgressStatus = (
  payload: ProgressStatus
) => ({
  type: HUB_UPDATE_SESSION_PROGRESS_STATUS,
  payload
});

export const actionHubUpdateSessionDate = (payload: Date) => ({
  type: HUB_UPDATE_SESSION_DATE,
  payload
});

export const actionHubLoadSessionList = (payload: ISessionDetails[]) => ({
  type: HUB_LOAD_SESSION_LIST,
  payload
});

export const actionHubUpdateSessionFormData = (payload: ISessionFormData) => ({
  type: HUB_UPDATE_SESSION_FORM_DATA,
  payload
});

export const actionHubSessionUpdateFileUploadProgress = (
  name: string,
  progressPercentage: number,
  error?: Error
) => ({
  type: HUB_SESSION_UPDATE_FILE_UPLOAD_PROGRESS,
  payload: {
    name,
    progressPercentage,
    error
  }
});

export const actionHubSessionUpdateFiles = (files: ISessionFile[]) => ({
  type: HUB_SESSION_UPDATE_FILES,
  payload: files
});

export const actionHubSessionUpdateFileUploadStatus = (
  payload: IFileUploadStatus[]
) => ({
  type: HUB_SESSION_UPDATE_FILE_UPLOAD_STATUS,
  payload
});

export const actionHubUpdateNudgeLanguage = (payload: string) => ({
  type: HUB_UPDATE_NUDGE_LANGUAGE,
  payload
});

export const actionHubUpdateFavorites = (
  payload: {
    itemType: string;
    itemId: string;
  }[]
) => ({
  type: HUB_UPDATE_FAVORITES,
  payload
});

export const actionToggleOnlyShowFavorites = (payload: boolean) => ({
  type: HUB_TOGGLE_SHOW_ONLY_FAVORITES,
  payload
});

export const actionUpdateStore = (store: IStore) => ({
  type: UPDATE_STORE,
  payload: store
});

export const actionUpdateVBConfig = (config: IVirtualBoutiqueConfig) => ({
  type: UPDATE_VB_CONFIG,
  payload: config
});

export const actionDidLoadProductAccessories = (
  payload: IProductAccessories
) => ({
  type: DID_LOAD_PRODUCT_ACCESSORIES,
  payload
});

export const actionSelectProductStrap = (payload: IProduct) => ({
  type: SELECT_PRODUCT_STRAP,
  payload
});

export const actionSelectAccessoriesSection = (
  payload?: '#strapSection' | '#buckleSection' | '#dialSection'
) => ({
  type: SELECT_ACCESSORIES_SECTION,
  payload
});

export const actionSelectProductBuckle = (payload: IProduct) => ({
  type: SELECT_PRODUCT_BUCKLE,
  payload
});

export const actionSelectProductDial = (payload: IProduct) => ({
  type: SELECT_PRODUCT_DIAL,
  payload
});

export const actionDidStartPlayingVideo = (payload: boolean) => ({
  type: VB_DID_START_PLAYING_VIDEO,
  payload
});

export const actionAddDevicesList = (payload: JitsiDevice[]) => ({
  type: ADD_DEVICES_LIST,
  payload
});

export const actionDidAddDevicesList = (payload: JitsiDevice[]) => ({
  type: DID_ADD_DEVICES_LIST,
  payload
});

export const actionSetAudioInput = (payload: string) => ({
  type: SET_AUDIO_INPUT,
  payload
});

export const actionSetAudioOutput = (payload: string) => ({
  type: SET_AUDIO_OUTPUT,
  payload
});

export const actionSetVideoInput = (payload: string) => ({
  type: SET_VIDEO_INPUT,
  payload
});

export const actionSetInputChangeAvailable = (payload: boolean) => ({
  type: SET_INPUT_CHANGE_AVAILABLE,
  payload
});

export const actionSetOutputChangeAvailable = (payload: boolean) => ({
  type: SET_OUTPUT_CHANGE_AVAILABLE,
  payload
});

export const actionUpdateStudioVideoZoomLevel = (
  studioParticipantId: string,
  zoomLevel: number
) => ({
  type: ZOOM_STUDIO_VIDEO,
  payload: {
    studioParticipantId,
    zoomLevel
  }
});

export const actionUpdateStudioVideoRotateDeg = (
  studioParticipantId: string,
  rotateDeg: number
) => ({
  type: ROTATE_STUDIO_VIDEO,
  payload: {
    studioParticipantId,
    rotateDeg
  }
});

export const actionSetStudioViewState = (payload: IStudioViewState) => ({
  type: SET_STUDIO_VIEW_STATE,
  payload
});

export const actionUpdateMeetingLayout = (payload: MeetingLayoutMode) => ({
  type: UPDATE_LAYOUT_MODE,
  payload
});

export const actionToggleShowMeetingLayoutControlButton = () => ({
  type: TOGGLE_SHOW_LAYOUT_CONTROL_BUTTON,
  payload: null
});

export const actionSetMeetingLayoutState = (
  payload: MeetingLayout,
  restoreLater?: boolean
) => ({
  type: SET_LAYOUT_STATE,
  payload,
  restoreLater
});

export const actionAddLayoutPresenter = (payload: string) => ({
  type: ADD_LAYOUT_PRESENTER,
  payload
});

export const actionRemoveLayoutPresenter = (payload: string) => ({
  type: REMOVE_LAYOUT_PRESENTER,
  payload
});

export const actionKickParticipantFromMeeting = (payload: {
  participantId: string;
}) => ({
  type: KICK_PARTICIPANT_FROM_MEETING,
  payload
});

export const actionToggleParticipantVisibility = (payload: {
  participantId: string;
  toggleToVisible: boolean;
}) => ({
  type: TOGGLE_PARTICIPANT_VISIBILITY,
  payload
});

export const actionTogglePinParticipant = (payload: string) => ({
  type: TOGGLE_PIN_PARTICIPANT,
  payload
});

export const actionKickedOutFromMeeting = () => ({
  type: KICKED_OUT_FROM_MEETING,
  payload: null
});

export const actionMuteParticipantAudioTrack = (payload: {
  participantId: string;
}) => ({
  type: MUTE_PARTICIPANT_AUDIO_TRACK,
  payload
});

export const actionUnmuteParticipantAudioTrack = (payload: {
  participantId: string;
}) => ({
  type: UNMUTE_PARTICIPANT_AUDIO_TRACK,
  payload
});

export const actionUnmuteAllParticipantsAudioTrack = () => ({
  type: UNMUTE_ALL_PARTICIPANTS_AUDIO_TRACK,
  payload: null
});

export const actionToggleRaiseHand = (payload: string) => ({
  type: TOGGLE_RAISE_HAND,
  payload
});

export const actionDidReceiveToggleRaiseHand = (payload: string) => ({
  type: DID_RECEIVE_TOGGLE_RAISE_HAND,
  payload
});

export const actionRemoveRaiseHandInAlert = (payload: string) => ({
  type: REMOVE_RAISE_HAND_IN_ALERT,
  payload
});

export const actionSetRaiseHandState = (payload: string[]) => ({
  type: SET_RAISE_HAND_STATE,
  payload
});

export const actionApproveRaiseHand = (payload: string) => ({
  type: APPROVE_RAISE_HAND,
  payload
});

export const actionDidChangeDominantSpeaker = (payload: string) => ({
  type: DID_CHANGE_DOMINANT_SPEAKER,
  payload
});

export const actionDidChangeConnectionStatus = (payload: {
  participantId: string;
  status: JitsiConnectionStatus;
}) => ({
  type: DID_CHANGE_CONNECTION_STATUS,
  payload
});

export const actionDidGetStoreDevices = (payload: IStoreDevice[]) => ({
  type: DID_GET_STORE_DEVICES,
  payload
});

export const actionDidAcceptCookies = () => ({
  type: DID_ACCEPT_COOKIES,
  payload: null
});

export const actionDidRejectCookies = () => ({
  type: DID_REJECT_COOKIES,
  payload: null
});

export const actionDidReceiveEndpointMessage = (
  message: JitsiEndpointMessage
) => ({
  type: DID_RECEIVE_ENDPOINT_MESSAGE,
  payload: message
});

export const actionAdvisorEndMeeting = () => ({
  type: ADVISOR_END_MEETING,
  payload: null
});

export const actionSetMeetingAutoAdmit = (autoAdmit: boolean) => ({
  type: SET_MEETING_AUTO_ADMIT,
  payload: autoAdmit
});

export const actionUpdateMeetingServerUrl = (payload: string) => ({
  type: UPDATE_MEETING_SERVER_URL,
  payload
});

export const actionDidLoadFileList = (payload: IStorybookFileState) => ({
  type: LOAD_FILE_LIST,
  payload
});

export const actionSetActiveFile = (payload: string) => ({
  type: SET_ACTIVE_FILE,
  payload
});

export const actionSetActivePage = (
  currentPage?: {
    url: string;
    setting?: string;
    file?: string;
    id: string;
    silence?: boolean;
  },
  nextUrl?: string,
  prevUrl?: string
) => ({
  type: SET_ACTIVE_PAGE,
  payload: { currentPage, nextUrl, prevUrl }
});

export const actionParticipantSetActivePage = (
  currentPage?: {
    url: string;
    setting?: string;
    file?: string;
    id: string;
    silence?: boolean;
  },
  nextUrl?: string,
  prevUrl?: string
) => ({
  type: PARTICIPANT_DID_SET_ACTIVE_PAGE,
  payload: { currentPage, nextUrl, prevUrl }
});

export const actionSetFileController = (payload: string) => ({
  type: SET_FILE_CONTROLLER,
  payload
});

export const actionRequestToBePresenter = (file: IFile) => ({
  type: REQUEST_TO_BE_PRESENTER,
  payload: file
});

export const actionSetStorybook = (storyBook?: IFile) => ({
  type: SHOW_STORYBOOK_IN_MEETING,
  payload: { storyBook }
});

export const actionUpdateHiddenPages = (payload: number[]) => ({
  type: UPDATE_HIDDEN_PAGES,
  payload
});

export const actionUpdateFileViewerLayout = (payload: FileViewerLayout) => ({
  type: UPDATE_FILE_VIEWER_LAYOUT,
  payload
});

export const actionRequestUpdateFileViewerLayout = (
  payload: FileViewerLayout
) => ({
  type: REQUEST_UPDATE_FILE_VIEWER_LAYOUT,
  payload
});

export const actionUpdatePlayerControl = (payload: IPlayerControl) => ({
  type: UPDATE_PLAYER_CONTROL,
  payload
});

export const actionRequestUpdatePlayerControl = (payload: IPlayerControl) => ({
  type: REQUEST_UPDATE_PLAYER_CONTROL,
  payload
});
export const actionUpdateLocalPlayerControlCurrentScene = (
  currentScene: number
) => ({
  type: UPDATE_LOCAL_PLAYER_CONTROL_CURRENT_SCENE,
  payload: currentScene
});

export const actionShowMeetingNotification = (
  payload: MeetingNotification
) => ({
  type: SHOW_MEETING_NOTIFICATION,
  payload
});

export const actionHideMeetingNotification = (
  payload: MeetingNotification
) => ({
  type: HIDE_MEETING_NOTIFICATION,
  payload
});

export const actionOpenParticipantControls = (payload: string) => ({
  type: OPEN_PARTICIPANT_CONTROLS,
  payload
});

export const actionDidLoadProduct = (payload: IProduct[]) => ({
  type: DID_LOAD_PRODUCT,
  payload
});

export const actionDidSelectProduct = (payload: string) => ({
  type: DID_SELECT_PRODUCT,
  payload
});

export const actionUpdateShowingDescriptionForProductId = (
  productId?: string
) => ({
  type: UPDATE_SHOWING_DESCRIPTION_FOR_PRODUCT_ID,
  payload: productId
});

export const actionPopupVideoDidPlayWithSound = (payload?: boolean) => ({
  type: POPUP_VIDEO_DID_PLAY_WITH_SOUND,
  payload
});

export const actionPopupDidRequestHideMeetingVeil = (payload: boolean) => ({
  type: POPUP_DID_REQUEST_HIDE_MEETING_VEIL,
  payload
});

export const actionToggleVirtualDialog = (payload: boolean) => ({
  type: OPEN_VIRTUAL_BACKGROUND_DIALOG,
  payload
});

export const actionToggleVirtualBackground = (
  payload: JitsiVideoTrackEffect
) => ({
  type: TOGGLE_VIRTUAL_BACKGROUND_EFFECT,
  payload
});

export const actionUpdateMap = (payload: IMapState) => ({
  type: VB_UPDATE_MAP,
  payload
});

export const actionUpdateLanguage = (payload: string) => ({
  type: VB_UPDATE_LANGUAGE,
  payload
});

export const actionHideMeetingControls = (payload: boolean) => ({
  type: HIDE_MEETING_CONTROLS,
  payload
});

export const actionToggleShareScreen = (payload: boolean) => ({
  type: TOGGLE_SHARE_SCREEN,
  payload
});

export const actionAdvisorAddToWishlist = (payload: {
  item: IWishlistItem;
  storeId: string;
  brandId: string;
}) => ({
  type: ADVISOR_ADD_TO_WISH_LIST,
  payload
});

export const actionTeleportShowOptions = (payload: boolean) => ({
  type: TELEPORT_SHOW_OPTIONS,
  payload
});

export const actionTeleportTo = (payload?: ITeleportSite) => ({
  type: TELEPORT_TO,
  payload
});

export const actionTeleportSelectSite = (payload?: string) => ({
  type: TELEPORT_SELECT_SITE,
  payload
});

export const actionOpenTooltip = (payload: string | undefined) => ({
  type: OPEN_TOOLTIP,
  payload
});
export const actionSetMeetingDate = (payload: number) => ({
  type: SET_MEETING_DATE,
  payload
});

export const actionResyncMeeting = () => ({
  type: RESYNC_MEETING_INFO,
  payload: null
});

export const actionToggleStatsMode = () => ({
  type: TOGGLE_STATS_MODE,
  payload: null
});

export const actionSendMirrorAction = (action: AnyAction, to: string) => ({
  type: MIRROR_ACTION,
  payload: {
    action,
    to
  }
});

export const actionSendMeetChat = (payload: IMeetingChat) => ({
  type: SEND_CHAT,
  payload
});

export const actionDeleteMeetChat = (payload: string) => ({
  type: DELETE_CHAT,
  payload
});
export const actionLoadMeetChat = (payload: IMeetingChat[]) => ({
  type: LOAD_CHAT,
  payload
});

export const actionActivateFeatures = (payload: AppFeature[]) => ({
  type: ACTIVATE_FEATURES,
  payload
});

export const actionAddUserInfo = (payload: IUserInfoState) => ({
  type: HUB_ADD_USER_INFO,
  payload
});

export const actionTogglePresenterMode = (payload: boolean) => ({
  type: TOGGLE_PRESENTER_MODE,
  payload
});

export const PARTICIPANT_DID_ENTER_FROM_LOUNGE =
  'MEETING.PARTICIPANT_DID_ENTER_FROM_LOUNGE';
export const actionParticipantDidEnterFromLounge = (payload: string) => ({
  type: PARTICIPANT_DID_ENTER_FROM_LOUNGE,
  payload
});

export const PARTICIPANT_IS_RECONNECTING =
  'MEETING.PARTICIPANT_IS_RECONNECTING';
export const PARTICIPANT_IS_RECONNECTED = 'MEETING.PARTICIPANT_IS_RECONNECTED';
export const actionParticipantIsReconnecting = (payload: {
  meetingState: ParticipantMeetingState;
  oldParticipantId: string;
}) => ({
  type: PARTICIPANT_IS_RECONNECTING,
  payload
});

export const actionParticipantIsReconnected = () => ({
  type: PARTICIPANT_IS_RECONNECTED,
  payload: null
});
export const actionLoadFolders = (folder: IFolder[], context: string) => ({
  type: HUB_LOAD_FOLDERS,
  payload: {
    folder,
    context
  }
});

export const actionUpdateFolderInfo = (folder: IFolder, context: string) => ({
  type: HUB_UPDATE_FOLDER,
  payload: {
    folder,
    context
  }
});

export const actionSelectFolderKey = (payload: {
  expandedFolderKeys?: string[];
  activeFolderKey?: string;
}) => ({
  type: HUB_SELECT_FOLDER,
  payload
});
export const actionFolderCurrentContext = (payload: {
  currentContext?: IFolderContext;
}) => ({
  type: FOLDER_CURRENT_CONTEXT,
  payload
});

export const actionUpdateElementsInFolderKeys = (payload: {
  [key: string]: string[];
}) => ({
  type: HUB_UPDATE_ELEMENTS_IN_FOLDER_KEYS,
  payload
});

export const actionHubSetLoadingStatus = (
  key: string,
  status: LoadingStatus
) => ({
  type: HUB_SET_LOADING_STATUS,
  payload: {
    key,
    status
  }
});

export const actionPixelStreamDidFocusOnAnObject = (payload: IPSHotspot) => ({
  type: PIXEL_STREAM_DID_FOCUS_ON_AN_OBJECT,
  payload
});

export const actionUpdateViewportInfo = (payload: ViewportInfo) => ({
  type: UPDATE_VIEWPORT_INFO,
  payload
});

export const actionDidChangeWishlistTab = (payload: string) => ({
  type: DID_CHANGE_WISHLIST_TAB,
  payload
});

export const actionOpenARviewer = (viewerUrl: string) => ({
  type: SET_PRODUCT_AR_VIEWER,
  payload: {
    viewerUrl,
    open: true
  }
});

export const actionCloseARViewer = () => ({
  type: SET_PRODUCT_AR_VIEWER,
  payload: {
    viewerUrl: '',
    open: false
  }
});

export const actionHubSetFileUpload = (payload: IFileToUpload[]) => ({
  type: HUB_SET_FILE_UPLOAD,
  payload
});

export const actionHubClearFileUpload = () => ({
  type: HUB_CLEAR_FILE_UPLOAD
});

export const actionHubAddConvertedFile = (payload: string[]) => ({
  type: HUB_CONVERTED_FILE_UPLOAD,
  payload
});

export const actionHubAddFileUploaded = (payload: string) => ({
  type: HUB_ADD_FILE_UPLOADED,
  payload
});
export const actionHubAbortFileUpload = (payload: string) => ({
  type: HUB_ABORT_FILE_UPLOAD,
  payload
});
export const actionBrowseStorybookInMeeting = (payload: boolean) => ({
  type: BROWSE_STORYBOOK_IN_MEETING,
  payload
});

export const actionHubSetCollaborationState = (
  payload?: ICollaborationState
) => ({
  type: HUB_SET_COLLABORATION_STATE,
  payload
});

export const actionUpdateScene = (payload?: ISceneState) => ({
  type: HUB_UPDATE_SCENE,
  payload
});

export const actionListLibraryAssetsRequest = (payload) => ({
  type: HUB_ASSET_LIBRARY_LIST_ASSETS_PENDING,
  payload
});

export const actionListLibraryAssetsSuccessful = (payload: {
  assets: IAsset[];
  totalCount: number;
  loadMore?: 'start' | 'end';
  searchOptions: SearchAssetOptions;
  hasMore?: boolean;
  executeClientSort?: boolean;
}) => ({
  type: HUB_ASSET_LIBRARY_LIST_ASSETS_SUCCESSFUL,
  payload
});

export const actionListLibraryAssetsFailed = (payload: string) => ({
  type: HUB_ASSET_LIBRARY_LIST_ASSETS_FAILED,
  payload
});

export const actionUpdateLibraryAsset = (payload: IAsset) => ({
  type: HUB_ASSET_LIBRARY_UPDATE_ASSET,
  payload
});

export const actionDeleteAssetFromStore = (payload: string[]) => ({
  type: HUB_ASSET_LIBRARY_DELETE_ASSETS_FROM_STORE,
  payload
});

export const actionSetPopupAssets = (payload: AssetLibraryPopupState) => ({
  type: HUB_SET_ASSET_LIBRARY_POPUP,
  payload
});

export const actionRequestHideMeetingVeil = (payload?: boolean) => ({
  type: REQUEST_HIDE_MEETING_VEIL,
  payload
});

export const actionOpenSSPStorybook = (payload: {
  brandId?: string;
  sessionId?: string;
}) => ({
  type: VB_OPEN_SSP_STORYBOOK_SEARCH,
  payload
});

export const actionCloseSSPStorybook = () => ({
  type: VB_CLOSE_SSP_STORYBOOK_SEARCH,
  payload: null
});

export const SHOW_WARNING_NOTIFICATION = 'SHOW_WARNING_NOTIFICATION';
export const actionShowWarningNotification = (payload: IDeviceWarning) => {
  return {
    type: SHOW_WARNING_NOTIFICATION,
    payload
  };
};

export const NOTIFY_MIC_ERROR = 'NOTIFY_MIC_ERROR';
export const NOTIFY_CAM_ERROR = 'NOTIFY_CAM_ERROR';
export const actionNotifyMicError = (error) => {
  return {
    type: NOTIFY_MIC_ERROR,
    payload: error
  };
};

export const actionNotifyCamError = (error) => {
  return {
    type: NOTIFY_CAM_ERROR,
    payload: error
  };
};

export const actionUpdateOneThirdLayout = ({ presenterId, enlargedId }) => {
  return {
    type: UPDATE_ONE_THIRD_LAYOUT,
    payload: {
      presenterId,
      enlargedId
    }
  };
};

export const actionExitOneThirdLayout = () => {
  return {
    type: EXIT_ONE_THIRD_LAYOUT,
    payload: null
  };
};

export const actionUpdateStorybookIndex = (index) => {
  return {
    type: UPDATE_STORYBOOK_INDEX,
    payload: index
  };
};

export const SET_FILE_VIEWER_STATUS = 'MEETING.SET_FILE_VIEWER_STATUS';
export const actionSetFileViewerStatus = (status: LoadingStatus) => {
  return {
    type: SET_FILE_VIEWER_STATUS,
    payload: status
  };
};

export const actionUpdatePexelUploadStatus = (status: IPexelsUploadStatus) => {
  return {
    type: HUB_PEXEL_UPLOAD_STATUS,
    payload: status
  };
};
