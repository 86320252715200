import {
  panoBaseUrl,
  rogerDubuisRetailId,
  rogerDubuisWholeSaleId,
  rogerDubuisWW2021Id
} from '../config';
import { IVirtualBoutiquePano, PanoView } from '../interfaces';

export const getKRPano = () =>
  document.getElementById('krpanoSWFObject') as any;

export const krpanoInvoke = (command: string): any => {
  getKRPano()?.call(command);
};

export const lookToView = (view: PanoView) => {
  setTimeout(() => {
    krpanoInvoke(
      `lookto(${view.viewH || 0}, ${view.viewV || 0}, ${
        view.fov || 140
      }, smooth())`
    );
  }, 100);
};

export const getCurrentScene = () => getKRPano()?.get('xml.scene');

export const loadScene = (scene: string) => {
  krpanoInvoke(`loadscene('${scene}', null, MERGE, BLEND(1))`);
};

export const updatePanoBaseUrlByRegionIfRequired = (
  storeId: string,
  pano?: IVirtualBoutiquePano
): IVirtualBoutiquePano => {
  if (
    [rogerDubuisWW2021Id, rogerDubuisWholeSaleId, rogerDubuisRetailId].includes(
      storeId
    )
  ) {
    return {
      swf: pano?.swf?.replace('https://panos2.inspify.io', panoBaseUrl),
      xml: pano?.xml?.replace('https://panos2.inspify.io', panoBaseUrl),
      basepath: pano?.basepath?.replace(
        'https://panos2.inspify.io',
        panoBaseUrl
      )
    };
  }
  return pano;
};

export const getVoyageMovementType = (storeId: string) => {
  if (
    [rogerDubuisWW2021Id, rogerDubuisWholeSaleId, rogerDubuisRetailId].includes(
      storeId
    )
  ) {
    return 'KEEPVIEW';
  }
  return 'MERGE';
};

export const getPanoView = (): PanoView => {
  const krpano = getKRPano();
  return {
    viewH: krpano.get('view.hlookat'),
    viewV: krpano.get('view.vlookat'),
    fov: krpano.get('view.fov')
  };
};
