export const GA_INITIALIZED = 'GA_INITIALIZED';
export const IDENTITY_ID = 'IDENTITY_ID';
export const EXPIRY = 'EXPIRY';
export const CUSTOM_ANALYTICS_EVENT = 'CUSTOM_AE';
export const ASSOCIATED_STORE_ID = 'ASSOCIATED_STORE_ID';
export const INS_SESSION_ID = 'INS_SESSION_ID';
export const INS_NUDGE_ID = 'INS_NUDGE_ID';
export const INS_PRODUCT_CURRENCY = 'INS_PRODUCT_CURRENCY';
export const INS_CLOSE_POPUP = 'INS_CLOSE_POPUP';
export const INS_SEND_TO_CHAT = 'INS_SEND_TO_CHAT';
export const CHAT_HISTORY_STORAGE = 'CHAT_HISTORY_STORAGE';

export const IS_IN_MEETING = 'IS_IN_MEETING';
export const MEETING_ROLE = 'MEETING_ROLE';

export const DID_SHARE = 'DID_SHARE';
export const DID_START_SESSION = 'DID_START_SESSION';
export const DID_VIEW_PAGE = 'DID_VIEW_PAGE';
export const DID_VIEW_CONTENT = 'DID_VIEW_CONTENT';
export const DID_CLICK_CALL_NOW = 'DID_CLICK_CALL_NOW';
export const DID_CLICK_CHAT_NOW = 'DID_CLICK_CHAT_NOW';
export const DID_CLICK_ENQUIRE_NOW = 'DID_CLICK_ENQUIRE_NOW';
export const DID_CLICK_LINK_BUTTON = 'DID_CLICK_LINK_BUTTON';
export const DID_CLICK_SHOP_NOW_BUTTON = 'DID_CLICK_SHOP_NOW_BUTTON';
export const DID_SHOW_INSTALLATION_MODAL = 'DID_SHOW_INSTALLATION_MODAL';
export const DID_HIDE_INSTALLATION_MODAL = 'DID_HIDE_INSTALLATION_MODAL';
export const DID_CLICK_INSTALL_APP_BUTTON = 'DID_CLICK_INSTALL_APP_BUTTON';

export const DID_CLICK_PRIVACY_POLICY = 'DID_CLICK_PRIVACY_POLICY';
export const DID_CLICK_TERMS_OF_USE = 'DID_CLICK_TERMS_OF_USE';

// Virtual Boutique
export const DID_CLICK_CONTACT = 'DID_CLICK_CONTACT';
export const DID_CLICK_TUTORIAL = 'DID_CLICK_TUTORIAL';
export const DID_SHOW_TUTORIAL = 'DID_SHOW_TUTORIAL';
export const DID_SKIP_TUTORIAL_IN_FUTURE = 'DID_SKIP_TUTORIAL_IN_FUTURE';
export const DID_CLICK_GUIDED_TOUR = 'DID_CLICK_GUIDED_TOUR';
export const DID_CLICK_EXPLORE_MY_OWN = 'DID_CLICK_EXPLORE_MY_OWN';
export const DID_CLICK_APPOINTMENT = 'DID_CLICK_APPOINTMENT';
export const DID_CLICK_BOOK_PRIVATE_SESSION = 'DID_CLICK_BOOK_PRIVATE_SESSION';
export const DID_START_MEETING = 'DID_START_MEETING';
export const DID_TOGGLE_MEETING_CONTROLS = 'DID_TOGGLE_MEETING_CONTROLS';
export const DID_SHARE_PAGE = 'DID_SHARE_PAGE';
export const DID_CLICK_NEWS_LETTER = 'DID_CLICK_NEWS_LETTER';
export const DID_CLICK_CAMPAIGN = 'DID_CLICK_CAMPAIGN';
export const DID_CLOSE_CAMPAIGN = 'DID_CLOSE_CAMPAIGN';
export const DID_CLICK_PRODUCT_SEARCH_BUTTON =
  'DID_CLICK_PRODUCT_SEARCH_BUTTON';
export const DID_CLICK_STORY_SEARCH_BUTTON = 'DID_CLICK_STORY_SEARCH_BUTTON';
export const DID_CLICK_PRODUCT_COMPARE_BUTTON =
  'DID_CLICK_PRODUCT_COMPARE_BUTTON';
export const DID_SEND_APPOINTMENT_EMAIL = 'DID_SEND_APPOINTMENT_EMAIL';
export const DID_FAIL_TO_SEND_APPOINTMENT_EMAIL =
  'DID_FAIL_TO_SEND_APPOINTMENT_EMAIL';
export const DID_SEND_SULTANATE_OF_OMAN_EMAIL =
  'DID_SEND_SULTANATE_OF_OMAN_EMAIL';
export const DID_FAIL_TO_SEND_SULTANATE_OF_OMAN_EMAIL =
  'DID_FAIL_TO_SEND_SULTANATE_OF_OMAN_EMAIL';
export const DID_SEND_ACCESS_FORM = 'DID_SEND_ACCESS_FORM';
export const DID_FAILED_TO_SEND_ACCESS_FORM = 'DID_FAILED_TO_SEND_ACCESS_FORM';
export const DID_START_VB_SCENE = 'DID_START_VB_SCENE';
export const DID_OPEN_POPUP_IN_VB = 'DID_OPEN_POPUP_IN_VB';
export const DID_CLICK_POWERED_BY_INSPIFY = 'DID_CLICK_POWERED_BY_INSPIFY';
export const DID_ENABLE_JOURNEY_GUIDE = 'DID_ENABLE_JOURNEY_GUIDE';
export const DID_DISABLE_JOURNEY_GUIDE = 'DID_HIDE_JOURNEY_GUIDE';
export const DID_CLICK_JOURNEY_TO_SCENE = 'DID_CLICK_JOURNEY_TO_SCENE';
export const DID_SHOW_CHAT_MESSAGE = 'DID_SHOW_CHAT_MESSAGE';
export const DID_SELECT_CHAT_OPTION = 'DID_SELECT_CHAT_OPTION';
export const DID_HIDE_CHAT_PANEL = 'DID_HIDE_CHAT_PANEL';
export const DID_SHOW_CHAT_PANEL = 'DID_SHOW_CHAT_PANEL';
export const DID_CLICK_ON_CHAT_BUTTON = 'DID_CLICK_ON_CHAT_BUTTON';
export const DID_CLICK_ON_CHAT_MESSAGE = 'DID_CLICK_ON_CHAT_MESSAGE';

export const DID_MUTE_ACCESS_FORM_VIDEO = 'DID_MUTE_ACCESS_FORM_VIDEO';
export const DID_UNMUTE_ACCESS_FORM_VIDEO = 'DID_UNMUTE_ACCESS_FORM_VIDEO';
export const DID_INPUT_FORM_FIELD = 'DID_INPUT_FORM_FIELD';
export const DID_OPEN_FORM = 'DID_OPEN_FORM';
export const DID_CLICK_FORM_SUBMIT_BUTTON = 'DID_CLICK_FORM_SUBMIT_BUTTON';
export const DID_CLOSE_FORM = 'DID_CLOSE_FORM';

export const EYEBALL_TIME = 'EYEBALL_TIME';
export const DID_SEARCH_FOR_PRODUCTS = 'DID_SEARCH_FOR_PRODUCTS';
export const DID_VIEW_SPECIFICATIONS = 'DID_VIEW_SPECIFICATIONS';
export const DID_CLOSE_SPECIFICATIONS = 'DID_CLOSE_SPECIFICATIONS';
export const DID_CLICK_DISCOVER_MORE = 'DID_CLICK_DISCOVER_MORE';
export const DID_CLICK_ON_HOT_SPOT = 'DID_CLICK_ON_HOT_SPOT';
export const DID_CLICK_VIEW_IN_3D = 'DID_CLICK_VIEW_IN_3D';
export const DID_ADD_PRODUCT_TO_COMPARE = 'DID_ADD_PRODUCT_TO_COMPARE';
export const DID_REMOVE_PRODUCT_FROM_COMPARE =
  'DID_REMOVE_PRODUCT_FROM_COMPARE';
export const DID_FOCUS_PRODUCT_IN_COMPARE = 'DID_FOCUS_PRODUCT_IN_COMPARE';
export const DID_FOCUS_INSPIRATION_IN_SLIDER =
  'DID_FOCUS_INSPIRATION_IN_SLIDER';
export const DID_FOCUS_PRODUCT_IN_SLIDER = 'DID_FOCUS_PRODUCT_IN_SLIDER';

export const DID_VIEW_PRODUCT_DESCRIPTION = 'DID_VIEW_PRODUCT_DESCRIPTION';
export const DID_CLOSE_PRODUCT_DESCRIPTION = 'DID_CLOSE_PRODUCT_DESCRIPTION';

export const DID_ADD_TO_WISHLIST = 'DID_ADD_TO_WISHLIST';
export const DID_ADD_TO_FAVORITE = 'DID_ADD_TO_FAVORITE';
export const DID_REMOVE_FROM_WISHLIST = 'DID_REMOVE_FROM_WISHLIST';
export const DID_REMOVE_FROM_FAVORITE = 'DID_REMOVE_FROM_FAVORITE';
export const DID_TOGGLE_ONLY_SHOW_FAVORITES = 'DID_TOGGLE_ONLY_SHOW_FAVORITES';
export const DID_CLICK_WISHLIST_BUTTON = 'DID_CLICK_WISHLIST_BUTTON';
export const DID_SEND_WISHLIST = 'DID_SEND_WISHLIST';
export const DID_FAILED_TO_SEND_WISHLIST = 'DID_FAILED_TO_SEND_WISHLIST';

export const DID_CLICK_ZOOM_BUTTON = 'DID_CLICK_ZOOM_BUTTON';
export const DID_CLICK_DISMISS_ZOOM = 'DID_CLICK_DISMISS_ZOOM';
export const DID_CLICK_VB_DISCOVER_MORE = 'DID_CLICK_VB_DISCOVER_MORE';

// Meetings
export const JITSI_MEET_EVENT = 'JITSI_MEET_EVENT';
export const DID_MUTE_LOCAL_VIDEO = 'DID_MUTE_LOCAL_VIDEO';
export const DID_UNMUTE_LOCAL_VIDEO = 'DID_UNMUTE_LOCAL_VIDEO';
export const DID_MUTE_LOCAL_VIDEO_ON_SHARE_SCREEN =
  'DID_MUTE_LOCAL_VIDEO_ON_SHARE_SCREEN';
export const DID_UNMUTE_LOCAL_VIDEO_ON_SHARE_SCREEN =
  'DID_UNMUTE_LOCAL_VIDEO_ON_SHARE_SCREEN';
export const DID_MUTE_LOCAL_AUDIO = 'DID_MUTE_LOCAL_AUDIO';
export const DID_UNMUTE_LOCAL_AUDIO = 'DID_UNMUTE_LOCAL_AUDIO';
export const DID_FAIL_TO_CREATE_AUDIO_TRACK = 'DID_FAIL_TO_CREATE_AUDIO_TRACK';
export const DID_FAIL_TO_CREATE_VIDEO_TRACK = 'DID_FAIL_TO_CREATE_VIDEO_TRACK';
export const DID_JOIN_MEETING = 'DID_JOIN_MEETING';
export const DID_LEAVE_MEETING = 'DID_LEAVE_MEETING';
export const DID_SHOW_MEETING_CLIENT_DETAILS_PANEL =
  'DID_SHOW_MEETING_CLIENT_DETAILS_PANEL';
export const DID_SHOW_MEETING_STREAMING_STUDIO_FULL_SCREEN =
  'DID_SHOW_MEETING_STREAMING_STUDIO_FULL_SCREEN';
export const DID_HIDE_MEETING_STREAMING_STUDIO_FULL_SCREEN =
  'DID_HIDE_MEETING_STREAMING_STUDIO_FULL_SCREEN';
export const DID_HIDE_MEETING_CLIENT_DETAILS_PANEL =
  'DID_HIDE_MEETING_CLIENT_DETAILS_PANEL';
export const DID_SELECT_PRODUCT_IN_MEETING_CLIENT_DETAILS_PANEL =
  'DID_SELECT_PRODUCT_IN_MEETING_CLIENT_DETAILS_PANEL';
export const DID_TRANSFER_PRESENTER_ROLE = 'DID_TRANSFER_PRESENTER_ROLE';
export const DID_CHANGE_PARTICIPANT_CONN_STATUS =
  'DID_CHANGE_PARTICIPANT_CONN_STATUS';
export const DID_SET_DEVICE_PERMISSION = 'DID_SET_DEVICE_PERMISSION';

//Jitsi meet conference additional event
export const LAST_N_ENDPOINTS_CHANGED = 'CONFERENCE.LAST_N_ENDPOINTS_CHANGED';
export const SUBJECT_CHANGED = 'CONFERENCE.SUBJECT_CHANGED';
export const CONFERENCE_LEFT = 'CONFERENCE.CONFERENCE_LEFT';
export const CONFERENCE_UNIQUE_ID_SET = 'CONFERENCE.CONFERENCE_UNIQUE_ID_SET';
export const DTMF_SUPPORT_CHANGED = 'CONFERENCE.DTMF_SUPPORT_CHANGED';
export const USER_ROLE_CHANGED = 'CONFERENCE.USER_ROLE_CHANGED';
export const USER_STATUS_CHANGED = 'CONFERENCE.USER_STATUS_CHANGED';
export const CONFERENCE_FAILED = 'CONFERENCE.CONFERENCE_FAILED';
export const CONFERENCE_ERROR = 'CONFERENCE.CONFERENCE_ERROR';
export const KICKED = 'CONFERENCE.KICKED';
export const PARTICIPANT_KICKED = 'CONFERENCE.PARTICIPANT_KICKED';
export const START_MUTED_POLICY_CHANGED =
  'CONFERENCE.START_MUTED_POLICY_CHANGED';
export const LOW_CONNECTION_QUALITY = 'CONFERENCE.LOW_CONNECTION_QUALITY';
export const STARTED_MUTED = 'CONFERENCE.STARTED_MUTED';
export const NO_AUDIO_INPUT = 'CONFERENCE.NO_AUDIO_INPUT';
export const AUDIO_INPUT_STATE_CHANGE = 'CONFERRENCE.AUDIO_INPUT_STATE_CHANGE';
//Jitsi meet connection additional event
export const CONNECTION_FAILED = 'CONNECTION.CONNECTION_FAILED';
export const CONNECTION_ESTABLISHED = 'CONNECTION.CONNECTION_ESTABLISHED';
export const WRONG_STATE = 'CONNECTION.WRONG_STATE';
//Jitsi meet error
export const CONNECTION_ERROR = 'ERROR.CONNECTION_ERROR';
export const CONFERENCE_RESTARTED = 'ERROR.CONFERENCE_RESTARTED';
export const VIDEOBRIDGE_NOT_AVAILABLE = 'ERROR.VIDEOBRIDGE_NOT_AVAILABLE';
export const RESERVATION_ERROR = 'ERROR.RESERVATION_ERROR';
export const GRACEFUL_SHUTDOWN = 'ERROR.GRACEFUL_SHUTDOWN';
export const INCOMPATIBLE_SERVER_VERSIONS =
  'ERROR.INCOMPATIBLE_SERVER_VERSIONS';
export const CONFERENCE_DESTROYED = 'ERROR.JINGLE_FATAL_ERROR';
export const CONFERENCE_MAX_USERS = 'ERROR.CONFERENCE_MAX_USERS';
export const CONNECTION_DROPPED_ERROR = 'ERROR.CONNECTION_DROPPED_ERROR';
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED';
export const SERVER_ERROR = 'ERROR.SERVER_ERROR';
export const OTHER_ERROR = 'ERROR.OTHER_ERROR';

export const DID_SHOW_VIDEO_PANEL = 'DID_SHOW_VIDEO_PANEL';
export const DID_HIDE_VIDEO_PANEL = 'DID_HIDE_VIDEO_PANEL';

export const DID_SHOW_IWC_XMAS_VIDEO = 'DID_SHOW_IWC_XMAS_VIDEO';

export const DID_OPEN_POPUP_IN_LOUNGE = 'DID_OPEN_POPUP_IN_LOUNGE';
export const DID_TOGGLE_CAMERA_IN_LOUNGE = 'DID_TOGGLE_CAMERA_IN_LOUNGE';
export const DID_TOGGLE_MIC_IN_LOUNGE = 'DID_TOGGLE_MIC_IN_LOUNGE';

export const DID_CLICK_ON_HEALTH_CHECK_BUTTON =
  'DID_CLICK_ON_HEALTH_CHECK_BUTTON';
export const DID_PERFORM_MEETING_HEALTH_CHECK =
  'DID_PERFORM_MEETING_HEALTH_CHECK';

export const DID_VIEW_ALL_PRODUCTS_IN_MEETING_CLIENT_DETAILS_PANEL =
  'DID_VIEW_ALL_PRODUCTS_IN_MEETING_CLIENT_DETAILS_PANEL';
export const DID_OPEN_APPOINTMENT_POPUP = 'DID_OPEN_APPOINTMENT_POPUP';
export const DID_OPEN_EXTERNAL_LINK = 'DID_OPEN_EXTERNAL_LINK';
export const DID_OPEN_CONTACT_POPUP = 'DID_OPEN_CONTACT_POPUP';
export const DID_OPEN_VB_CONTENT_POPUP = 'DID_OPEN_VB_CONTENT_POPUP';

export {
  DID_ENTER_MEETING_FROM_LOUNGE,
  BRIDGE_CHANNEL_OPENED,
  DID_SHOW_WELCOME_VIDEO_FROM_LOUNGE,
  DID_CLICK_JOIN_NOW,
  DID_ADMIT_USER_INTO_MEETING
} from './events/meeting';

export const DID_UPDATE_MEETING_DISPLAY_NAME =
  'DID_UPDATE_MEETING_DISPLAY_NAME';

export const DID_SELECT_LANGUAGE = 'DID_SELECT_LANGUAGE';
export const DID_CLICK_PRODUCT_APPOINTMENT = 'DID_CLICK_PRODUCT_APPOINTMENT';

export const DID_ENLARGE_VIDEO = 'DID_ENLARGE_VIDEO';
export const DID_CLICK_BADGE = 'DID_CLICK_BADGE';

export const DID_CLICK_TOGGLE_GYROSCOPE = 'DID_CLICK_BADGE';

export const USER_FEEDBACK = 'USER_FEEDBACK';
export const DID_CLICK_FEEDBACK_BUTTON = 'DID_CLICK_FEEDBACK_BUTTON';
export const DID_CLICK_SEND_FEEDBACK = 'DID_CLICK_SEND_FEEDBACK';
export const DID_OPEN_FEEDBACK_POPUP = 'DID_OPEN_FEEDBACK_POPUP';
export const DID_CLOSE_FEEDBACK_POPUP = 'DID_CLOSE_FEEDBACK_POPUP';
export const DID_CLICK_PRODUCT_COLOR = 'DID_CLICK_PRODUCT_COLOR';
export const DID_CLICK_CONDITION_OF_SALE = 'DID_CLICK_CONDITION_OF_SALE';

export const DID_VIEW_PRODUCT = 'DID_VIEW_PRODUCT';
export const DID_CLOSE_VIEW_PRODUCT = 'DID_CLOSE_VIEW_PRODUCT';
export const DID_CLICK_AR_BUTTON = 'DID_CLICK_AR_BUTTON';
export const DID_CHANGE_PRODUCT_COLOR = 'DID_CHANGE_PRODUCT_COLOR';

export const DID_MUTE_BACKGROUND_SOUND = 'DID_MUTE_BACKGROUND_SOUND';
export const DID_UNMUTE_BACKGROUND_SOUND = 'DID_UNMUTE_BACKGROUND_SOUND';

export const DID_CLICK_PICK_OF_THE_MONTH_BUTTON =
  'DID_CLICK_PICK_OF_THE_MONTH_BUTTON';
export const DID_CLICK_WATCH_SPA_BUTTON = 'DID_CLICK_WATCH_SPA_BUTTON';
export const DID_CLICK_ADD_PRODUCT_TO_CART = 'DID_CLICK_ADD_PRODUCT_TO_CART';
export const DID_CLICK_REMOVE_PRODUCT_FROM_CART =
  'DID_CLICK_REMOVE_PRODUCT_FROM_CART';
export const DID_CLICK_INCREASE_PRODUCT_QTY_CART =
  'DID_CLICK_INCREASE_PRODUCT_QTY_CART';
export const DID_CLICK_REDUCE_PRODUCT_QTY_CART =
  'DID_CLICK_REDUCE_PRODUCT_QTY_CART';
export const DID_CLICK_PERSONALIZE_BUTTON = 'DID_CLICK_PERSONALIZE_BUTTON';
export const DID_CLICK_ORDER_NOW_BUTTON = 'DID_CLICK_ORDER_NOW_BUTTON';
export const DID_CLOSE_CART_FORM = 'DID_CLOSE_CART_FORM';
export const DID_CLICK_SEND_CART_BUTTON = 'DID_CLICK_SEND_CART_BUTTON';
export const DID_CLICK_CART_ICON = 'DID_CLICK_CART_ICON';
export const DID_FAIL_TO_SEND_CART = 'DID_FAIL_TO_SEND_CART';
export const DID_SEND_CART = 'DID_SEND_CART';
export const DID_CLICK_CART_CLOSE_BUTTON = 'DID_CLICK_CART_CLOSE_BUTTON';
export const DID_CLICK_STUDIO_ZOOM_BUTTON = 'DID_CLICK_STUDIO_ZOOM_BUTTON';
export const DID_CLICK_STUDIO_ROTATE_BUTTON = 'DID_CLICK_STUDIO_ROTATE_BUTTON';
export const DID_CLICK_STUDIO_GRID_BUTTON = 'DID_CLICK_STUDIO_GRID_BUTTON';
export const DID_FOCUS_PARTICIPANTS_IN_HD = 'DID_FOCUS_PARTICIPANTS_IN_HD';
export const DID_CHECKOUT_SUCCESSFULLY = 'DID_CHECKOUT_SUCCESSFULLY';
export const DID_FAIL_TO_CHECKOUT = 'DID_FAIL_TO_CHECKOUT';
export const DID_TOKENIZE_CARD_SUCCESSFULLY = 'DID_TOKENIZE_CARD_SUCCESSFULLY';
export const DID_FAIL_TO_TOKENIZE_CARD = 'DID_FAIL_TO_TOKENIZE_CARD';
export const DID_CLICK_PROCEED_TO_CHECKOUT_BUTTON =
  'DID_CLICK_PROCEED_TO_CHECKOUT_BUTTON';
export const DID_SELECT_CONTACT_METHOD = 'DID_SELECT_CONTACT_METHOD';
export const DID_SELECT_DELIVERY_METHOD = 'DID_SELECT_DELIVERY_METHOD';
export const DID_CHECK_GIFT_WRAPPING = 'DID_CHECK_GIFT_WRAPPING';
export const DID_CHECK_CHECKOUT_TERMS = 'DID_CHECK_CHECKOUT_TERMS';
export const DID_CLICK_SUBMIT_CARD_DETAILS = 'DID_CLICK_SUBMIT_CARD_DETAILS';
export const DID_CLICK_CONFIRM_ORDER_AND_PAY =
  'DID_CLICK_CONFIRM_ORDER_AND_PAY';
export const DID_CLICK_CLOSE_BTN = 'DID_CLICK_CLOSE_BTN';
export const DID_SUBMIT_CHECKOUT_CKO_SESSION_ID =
  'DID_SUBMIT_CHECKOUT_CKO_SESSION_ID';
export const DID_VERIFY_CHECKOUT_CKO_SESSION_ID =
  'DID_VERIFY_CHECKOUT_CKO_SESSION_ID';
export const DID_FAIL_TO_VERIFY_CHECKOUT_CKO_SESSION_ID =
  'DID_FAIL_TO_VERIFY_CHECKOUT_CKO_SESSION_ID';
export const DID_BOOK_VIDEO_APPOINTMENT_SUCCESSFULLY =
  'DID_BOOK_VIDEO_APPOINTMENT_SUCCESSFULLY';
export const DID_FAIL_TO_BOOK_VIDEO_APPOINTMENT =
  'DID_FAIL_TO_BOOK_VIDEO_APPOINTMENT';

// HUB

export const DID_SEARCH_FOR_STORIES = 'DID_SEARCH_FOR_STORIES';
export const DID_CLICK_HUB_MENU = 'DID_CLICK_HUB_MENU';

// HUB CHAT
export const DID_CLICK_JOIN_CHAT = 'DID_CLICK_JOIN_CHAT';
export const DID_CLICK_CHAT_SHARE = 'DID_CLICK_CHAT_SHARE';
export const DID_CLICK_CHAT_SHARE_ITEM = 'DID_CLICK_CHAT_SHARE_ITEM';
export const DID_CLICK_CHAT_SEND_MESSAGE = 'DID_CLICK_CHAT_SEND_MESSAGE';
export const DID_CLICK_SEND_TO_CHAT_BUTTON = 'DID_CLICK_SEND_TO_CHAT_BUTTON';
export const DID_CLICK_ACCEPT_NEW_CONVERSATION =
  'DID_CLICK_ACCEPT_NEW_CONVERSATION';
export const DID_CLICK_CHAT_CONVERSATION = 'DID_CLICK_CHAT_CONVERSATION';
export const DID_CHANGE_ADVISOR_AVAILABILITY =
  'DID_CHANGE_ADVISOR_AVAILABILITY';
export const DID_CHANGE_CHAT_TAB = 'DID_CHANGE_CHAT_TAB';
export const DID_REQUEST_BROWSING_HISTORY = 'DID_REQUEST_BROWSING_HISTORY';
export const DID_FETCH_BROWSING_HISTORY_SUCCESSFULLY =
  'DID_FETCH_BROWSING_HISTORY_SUCCESSFULLY';
export const DID_FAIL_TO_FETCH_BROWSING_HISTORY =
  'DID_FAIL_TO_FETCH_BROWSING_HISTORY';

export const DID_CLICK_DELETE_CHAT_MESSAGE = 'DID_CLICK_DELETE_CHAT_MESSAGE';
export const DID_QUOTE_CHAT_MESSAGE = 'DID_QUOTE_CHAT_MESSAGE';
export const DID_LOAD_CHAT_HISTORY = 'DID_LOAD_CHAT_HISTORY';

// SESSION MANAGEMENT
export const DID_START_LIVE_SESSION = 'DID_START_LIVE_SESSION';
export const DID_CLICK_SESSION_CREATE = 'DID_CLICK_SESSION_CREATE';
export const DID_CLICK_SESSION_EDIT = 'DID_CLICK_SESSION_EDIT';
export const DID_CLICK_JOIN_SESSION = 'DID_CLICK_JOIN_SESSION';
export const DID_CLICK_ADD_TO_CALENDAR = 'DID_CLICK_ADD_TO_CALENDAR';
export const DID_DELETE_SESSION = 'DID_DELETE_SESSION';
export const DID_CLICK_DOWNLOAD_EMAIL_TEMPLATE =
  'DID_CLICK_DOWNLOAD_EMAIL_TEMPLATE';
export const DID_RECEIVE_UNREAL_EVENT = 'DID_RECEIVE_UNREAL_EVENT';
export const DID_SEND_UNREAL_EVENT = 'DID_SEND_UNREAL_EVENT';
// HUB USER MANAGEMENT
export const DID_UPLOAD_AVATAR = 'DID_UPLOAD_AVATAR';
export const DID_DELETE_AVATAR = 'DID_DELETE_AVATAR';

export const DID_CREATE_SESSION = 'DID_CREATE_SESSION';
export const DID_FAIL_TO_CREATE_SESSION = 'DID_FAIL_TO_CREATE_SESSION';
export const DID_UPLOAD_FILE = 'DID_UPLOAD_FILE';
export const DID_DELETE_FILE = 'DID_DELETE_FILE';
export const DID_FAIL_TO_UPLOAD_FILE = 'DID_FAIL_TO_UPLOAD_FILE';
export const DID_ADD_PARTICIPANT = 'DID_ADD_PARTICIPANT';
export const DID_REMOVE_PARTICIPANT = 'DID_REMOVE_PARTICIPANT';
export const DID_EDIT_SESSION = 'DID_EDIT_SESSION';
export const DID_FAIL_TO_EDIT_SESSION = 'DID_FAIL_TO_EDIT_SESSION';

export const SESSION_PREPARED = 'SESSION_PREPARED';

//NUDGE
export const DID_SELECT_NUDGE_LANGUAGE = 'DID_SELECT_NUDGE_LANGUAGE';
export const DID_SELECT_NUDGE_CURRENCY = 'DID_SELECT_NUDGE_CURRENCY';
export const DID_SHARE_NUDGE = 'DID_SHARE_NUDGE';
export const DID_UNSELECT_ALL_NUDGE_STORIES = 'DID_UNSELECT_ALL_NUDGE_STORIES';
export const DID_UNSELECT_ALL_NUDGE_PRODUCTS =
  'DID_UNSELECT_ALL_NUDGE_PRODUCTS';
export const DID_SELECT_NUDGE_ITEM = 'DID_SELECT_NUDGE_ITEM';
export const DID_UNSELECT_NUDGE_ITEM = 'DID_UNSELECT_NUDGE_ITEM';

export const DID_CLICK_NEXT_BUTTON = 'DID_CLICK_NEXT_BUTTON';
export const DID_CLICK_BACK_BUTTON = 'DID_CLICK_BACK_BUTTON';
export const DID_CLICK_MEET_NOW = 'DID_CLICK_MEET_NOW';
export const DID_CLICK_NUDGE_DISCOVER_MORE = 'DID_CLICK_NUDGE_DISCOVER_MORE';
export const DID_CLICK_SHOPPING_CART_FROM_NUDGE =
  'DID_CLICK_SHOPPING_CART_FROM_NUDGE';
export const DID_CLICK_COPY_NUDGE = 'DID_CLICK_COPY_NUDGE';
export const DID_CLICK_COPY_LINK = 'DID_CLICK_COPY_LINK';
export const COPY_LINK_FAILED = 'COPY_LINK_FAILED';
export const COPY_LINK_SUCCESSFUL = 'COPY_LINK_SUCCESSFUL';
export const DID_CLOSE_NUDGE_PREVIEW = 'DID_CLOSE_NUDGE_PREVIEW';
export const DID_CLOSE_NUDGE_TEMPLATE_PREVIEW =
  'DID_CLOSE_NUDGE_TEMPLATE_PREVIEW';
export const DID_CLICK_USE_NUDGE_TEMPLATE = 'DID_CLICK_USE_NUDGE_TEMPLATE';

export const DID_CLICK_MICROSITE_ITEM = 'DID_CLICK_MICROSITE_ITEM';
export const DID_CLICK_STORIES_FILTER_BUTTON =
  'DID_CLICK_STORIES_FILTER_BUTTON';
export const DID_CLICK_PRODUCTS_FILTER_BUTTON =
  'DID_CLICK_PRODUCTS_FILTER_BUTTON';

export const DID_SHOWING_ALERT = 'DID_SHOWING_ALERT';

export const DID_CLICK_VIEW_BUTTON = 'DID_CLICK_VIEW_BUTTON';
export const DID_CLICK_JOIN_BUTTON = 'DID_CLICK_JOIN_BUTTON';
export const DID_CLICK_LOGIN_BUTTON = 'DID_CLICK_LOGIN_BUTTON';
export const DID_CLICK_CURATE_YOUR_NUDGE = 'DID_CLICK_CURATE_YOUR_NUDGE';
export const DID_CLICK_FILTER_NUDGE = 'DID_CLICK_FILTER_NUDGE';
export const DID_CLICK_PAGINATION = 'DID_CLICK_PAGINATION';
export const DID_CLICK_CREATE_TEAM = 'DID_CLICK_CREATE_TEAM';
export const DID_CLICK_ADD_INVITE = 'DID_CLICK_ADD_INVITE';
export const DID_TRY_TO_CREATE_NEW_INVITE = 'DID_TRY_TO_CREATE_NEW_INVITE';
export const DID_CREATE_NEW_INVITE = 'DID_CREATE_NEW_INVITE';
export const DID_FAIL_TO_CREATE_NEW_INVITE = 'DID_FAIL_TO_CREATE_NEW_INVITE';

export const DID_CLICK_INVITE_SEND_BUTTON = 'DID_CLICK_INVITE_SEND_BUTTON';
export const DID_FAIL_TO_SHARE_NATIVELY = 'DID_FAIL_TO_SHARE_NATIVELY';
export const DID_OPEN_SHARE_POPUP = 'DID_OPEN_SHARE_POPUP';

export const DID_SELECT_PRODUCT_STRAP = 'DID_SELECT_PRODUCT_STRAP';
export const DID_SELECT_PRODUCT_DIAL = 'DID_SELECT_PRODUCT_DIAL';
export const DID_SELECT_PRODUCT_BUCKLE = 'DID_SELECT_PRODUCT_BUCKLE';
export const DID_CLICK_SCAN_QR_BUTTON = 'DID_CLICK_SCAN_QR_BUTTON';

export const DID_SELECT_MICROPHONE = 'DID_SELECT_MICROPHONE';
export const DID_SELECT_CAMERA = 'DID_SELECT_CAMERA';
export const DID_SELECT_SPEAKER = 'DID_SELECT_SPEAKER';

export const DID_UPDATE_LAYOUT_MODE = 'DID_UPDATE_LAYOUT_MODE';
export const DID_CLICK_HUD_BUTTON = 'DID_CLICK_HUD_BUTTON';
export const DID_CLICK_HUD_MENU = 'DID_CLICK_HUD_MENU';
export const DID_CLICK_HUD_FLIP_BUTTON = 'DID_CLICK_HUD_FLIP_BUTTON';
export const DID_CLICK_CLOSE_HUD = 'DID_CLICK_CLOSE_HUD';

export const ALLOW_ENTER_TUNNEL = 'ALLOW_ENTER_TUNNEL';
export const IS_FLIPPING = 'IS_FLIPPING';

export const DID_CLICK_INVITE_CLIENT_TO_MEETING_BUTTON =
  'DID_CLICK_INVITE_CLIENT_TO_MEETING_BUTTON';
export const DID_INVITE_CLIENT_TO_MEETING = 'DID_INVITE_CLIENT_TO_MEETING';

//TODO: add to Confluence
export const KICKED_OUT_FROM_MEETING = 'KICKED_OUT_FROM_MEETING';
export const ADVISOR_DID_END_MEETING = 'ADVISOR_DID_END_MEETING';
export const DID_CLICK_KICK_PARTICIPANT = 'DID_CLICK_KICK_PARTICIPANT';
export const DID_KICK_PARTICIPANT = 'DID_KICK_PARTICIPANT';
export const CANCELED_TO_KICK_PARTICIPANT = 'CANCELED_TO_KICK_PARTICIPANT';
export const DID_TOGGLE_PARTICIPANT_VISIBILITY =
  'DID_TOGGLE_PARTICIPANT_VISIBILITY';

export const DID_MUTE_PARTICIPANT = 'DID_MUTE_PARTICIPANT';
export const DID_UNMUTE_PARTICIPANT = 'DID_UNMUTE_PARTICIPANT';

export const DID_MUTE_ALL_PARTICIPANTS = 'DID_MUTE_ALL_PARTICIPANTS';
export const DID_UNMUTE_ALL_PARTICIPANTS = 'DID_UNMUTE_ALL_PARTICIPANTS';
export const DID_ADD_PARTICIPANT_AS_PRESENTER =
  'DID_ADD_PARTICIPANT_AS_PRESENTER';
export const DID_REMOVE_PARTICIPANT_AS_PRESENTER =
  'DID_REMOVE_PARTICIPANT_AS_PRESENTER';
export const DID_CLICK_RAISE_HAND = 'DID_CLICK_RAISE_HAND';
export const DID_APPROVE_RAISE_HAND = 'DID_APPROVE_RAISE_HAND';
export const DID_REJECT_RAISE_HAND = 'DID_REJECT_RAISE_HAND';
export const DID_CLICK_ASK_WATCHMAKER = 'DID_CLICK_ASK_WATCHMAKER';
export const DID_SEND_ENQUIRY_EMAIL = 'DID_SEND_ENQUIRY_EMAIL';
export const DID_FAIL_TO_SEND_ENQUIRY_EMAIL = 'DID_FAIL_TO_SEND_ENQUIRY_EMAIL';

export const DID_TOGGLE_PARTICIPANT_ACTIONS_MENU =
  'DID_TOGGLE_PARTICIPANT_ACTIONS_MENU';

export const DID_LOAD_VIDEO = 'DID_LOAD_VIDEO';
export const VIDEO_ENDED = 'VIDEO_ENDED';
export const DID_START_VIDEO = 'DID_START_VIDEO';
export const DID_START_FULL_SCREEN_VIDEO = 'DID_START_FULL_SCREEN_VIDEO';
export const DID_SEEK_VIDEO = 'DID_SEEK_VIDEO';
export const WILL_SEEK_VIDEO = 'WILL_SEEK_VIDEO';
export const DID_CLICK_MUTE_VIDEO = 'DID_CLICK_MUTE_VIDEO';
export const DID_CLICK_UNMUTE_VIDEO = 'DID_CLICK_UNMUTE_VIDEO';
export const DID_CLICK_PLAY_VIDEO = 'DID_CLICK_PLAY_VIDEO';
export const VIDEO_PAUSED = 'VIDEO_PAUSED';
export const VIDEO_PLAYED = 'VIDEO_PLAYED';
export const VIDEO_ERROR = 'VIDEO_ERROR';
export const RETRY_PLAY_VIDEO = 'RETRY_PLAY_VIDEO';
export const IS_WATCHING_VIDEO = 'IS_WATCHING_VIDEO';
export const DID_PLAY_SOUND = 'DID_PLAY_SOUND';

export const IMAGE_ERROR = 'IMAGE_ERROR';

export const DID_CLICK_SHARE_STUDIO_URL = 'DID_CLICK_SHARE_STUDIO_URL';
export const DID_SHARE_STUDIO_URL = 'DID_SHARE_STUDIO_URL';
export const DID_CLICK_INVITE_STORE_DEVICE_TO_MEETING_BUTTON =
  'DID_CLICK_INVITE_STORE_DEVICE_TO_MEETING_BUTTON';
export const DID_INVITE_STORE_DEVICE_TO_MEETING =
  'DID_INVITE_STORE_DEVICE_TO_MEETING';
export const DID_FAIL_TO_INVITE_STORE_DEVICE_TO_MEETING =
  'DID_FAIL_TO_INVITE_STORE_DEVICE_TO_MEETING';

export const DID_SKIP_VIDEO = 'DID_SKIP_VIDEO';
export const DID_CLICK_CONTINUE_FLIGHT_BUTTON =
  'DID_CLICK_CONTINUE_FLIGHT_BUTTON';
export const DID_ACCEPT_COOKIE_DISCLAIMER = 'DID_ACCEPT_COOKIE_DISCLAIMER';
export const DID_REJECT_COOKIE_DISCLAIMER = 'DID_REJECT_COOKIE_DISCLAIMER';

// Animation
export const DID_SELECT_ANIMATION_MODEL = 'DID_SELECT_ANIMATION_MODEL';
export const DID_UPDATE_ANIMATION_STATE = 'DID_UPDATE_ANIMATION_STATE';

export const DID_SELECT_MAGAZINE = 'DID_SELECT_MAGAZINE';
export const DID_CLOSE_MAGAZINE = 'DID_CLOSE_MAGAZINE';
export const DID_FLIP_MAGAZINE = 'DID_FLIP_MAGAZINE';
export const DID_CLOSE_ANIMATION = 'DID_CLOSE_ANIMATION';
export const DID_SHOW_ANIMATION = 'DID_SHOW_ANIMATION';
export const DID_CLICK_2D_PRODUCT_HOT_SPOT = 'DID_CLICK_2D_PRODUCT_HOT_SPOT';

export const DID_OPEN_TRAY_LID = 'DID_OPEN_TRAY_LID';

export const DID_HOVER_2D_PRODUCT_HOTSPOT = 'DID_HOVER_2D_PRODUCT_HOTSPOT';
export const DID_UPDATE_2D_PRODUCT_IMAGE_STATE =
  'DID_UPDATE_2D_PRODUCT_IMAGE_STATE';

export const DID_CLICK_PLANE_PLAN_BUTTON = 'DID_CLICK_PLANE_PLAN_BUTTON';
export const DID_CLICK_PLANE_PLAN_SPOT = 'DID_CLICK_PLANE_PLAN_SPOT';

export const DID_CLOSE_WISHLIST_POPUP = 'DID_CLOSE_WISHLIST_POPUP';

export const DID_CLICK_SHARE_WITH_YOUR_SALES_CREW =
  'DID_CLICK_SHARE_WITH_YOUR_SALES_CREW';

export const DID_CLICK_SEND_WISHLIST_BUTTON = 'DID_CLICK_SEND_WISHLIST_BUTTON';

export const DID_SEND_LOUBI_AIRWAYS_WISHLIST =
  'DID_SEND_LOUBI_AIRWAYS_WISHLIST';

export const DID_FAILED_TO_SEND_LOUBI_AIRWAYS_WISHLIST =
  'DID_FAILED_TO_SEND_LOUBI_AIRWAYS_WISHLIST';
export const DID_CLOSE_CONTENT_POPUP = 'DID_CLOSE_CONTENT_POPUP';
export const DID_CLICK_EXIT_BUTTON = 'DID_CLICK_EXIT_BUTTON';
export const DID_CLICK_LEAVE_PLANE = 'DID_CLICK_LEAVE_PLANE';
export const DID_CLICK_CONTINUE_FLIGHT = 'DID_CLICK_CONTINUE_FLIGHT';

export const DID_SELECT_BOUTIQUE_LOCATION = 'DID_SELECT_BOUTIQUE_LOCATION';
export const DID_CLICK_EXPLORE_NOW = 'DID_CLICK_EXPLORE_NOW';
export const DID_CLICK_SHOW_FILES = 'DID_CLICK_SHOW_FILES';
export const DID_SELECT_FILE = 'DID_SELECT_FILE';
export const DID_SELECT_STORYBOOK = 'DID_SELECT_STORYBOOK';
export const DID_SELECT_PAGE = 'DID_SELECT_PAGE';
export const DID_HIDE_PAGE = 'DID_HIDE_PAGE';
export const DID_CLICK_MUSIC_LIST_BUTTON = 'DID_CLICK_MUSIC_LIST_BUTTON';
export const DID_CLICK_LIGHT_SWITCH_BUTTON = 'DID_CLICK_LIGHT_SWITCH_BUTTON';
export const DID_CLICK_HOME_BUTTON = 'DID_CLICK_HOME_BUTTON';

export const DID_CLICK_VIRTUAL_BACKGROUND = 'DID_CLICK_VIRTUAL_BACKGROUND';
export const DID_APPLY_VIRTUAL_BACKGROUND = 'DID_APPLY_VIRTUAL_BACKGROUND';
export const VIRTUAL_BACKGROUND_STORED = 'selected/virtual-background';
export const INPUT_DEVICES_STORED = 'selected/input-devices';
export const OUTPUT_DEVICES_STORED = 'selected/output-devices';
export const DID_CLICK_SHOW_STUDIO_VIDEO_PANEL =
  'DID_CLICK_SHOW_STUDIO_VIDEO_PANEL';
export const DID_SHOW_FLOWCHART_DASHBOARD = 'DID_SHOW_FLOWCHART_DASHBOARD';
export const DID_HIDE_FLOWCHART_DASHBOARD = 'DID_HIDE_FLOWCHART_DASHBOARD';
export const DID_CLICK_FLOWCHART_DASHBOARD_ITEM =
  'DID_CLICK_FLOWCHART_DASHBOARD_ITEM';

export const DID_CLICK_NOTIFICATION_BUTTON = 'DID_CLICK_NOTIFICATION_BUTTON';
export const DID_SHOW_NOTIFICATION = 'DID_SHOW_NOTIFICATION';

export const DID_CLOSE_DOCUMENT_VIEWER = 'DID_CLOSE_DOCUMENT_VIEWER';
export const DID_OPEN_DOCUMENT_VIEWER = 'DID_OPEN_DOCUMENT_VIEWER';
export const DID_CHANGE_DOCUMENT_VIEWER_LAYOUT =
  'DID_CHANGE_DOCUMENT_VIEWER_LAYOUT';
export const DID_CLICK_BACK_TO_SSP_HOME_BUTTON =
  'DID_CLICK_BACK_TO_SSP_HOME_BUTTON';

export const DID_START_AVATAR_VIDEO = 'DID_START_AVATAR_VIDEO';
export const DID_PIN_PARTICIPANT = 'DID_PIN_PARTICIPANT';
export const DID_UNPIN_PARTICIPANT = 'DID_UNPIN_PARTICIPANT';

export const DID_CLICK_CONFIRM_SESSION_BUTTON =
  'DID_CLICK_CONFIRM_SESSION_BUTTON';
export const DID_FAIL_TO_CONFIRM_NATIVELY = 'DID_FAIL_TO_CONFIRM_NATIVELY';
export const DID_CLICK_SESSION_CONFIRM_BUTTON =
  'DID_CLICK_SESSION_CONFIRM_BUTTON';

export const DID_CLICK_LIKED_PRODUCTS_BUTTON =
  'DID_CLICK_LIKED_PRODUCTS_BUTTON';
export const DID_CLICK_OPEN_RESERVATIONS_BUTTON =
  'DID_CLICK_OPEN_RESERVATIONS_BUTTON';
export const DID_CLICK_ADD_TO_RESERVATION = 'DID_CLICK_ADD_TO_RESERVATION';
export const DID_CLICK_REMOVE_FROM_RESERVATION =
  'DID_CLICK_REMOVE_FROM_RESERVATION';
export const DID_CLICK_CATALOG_BUTTON = 'DID_CLICK_CATALOG_BUTTON';
export const DID_CLICK_PRODUCT_SIZE_BUTTON = 'DID_CLICK_PRODUCT_SIZE_BUTTON';
export const DID_CLICK_SUBMIT_RESERVATION_BUTTON =
  'DID_CLICK_SUBMIT_RESERVATION_BUTTON';
export const DID_CLICK_SEND_RESERVATION_FORM =
  'DID_CLICK_SEND_RESERVATION_FORM';

export const WILL_SEND_RESERVATION = 'WILL_SEND_RESERVATION';
export const DID_SEND_RESERVATION = 'DID_SEND_RESERVATION';
export const DID_FAILED_TO_SEND_RESERVATION = 'DID_FAILED_TO_SEND_RESERVATION';

export const DID_VIEW_PRODUCT_DETAILS = 'DID_VIEW_PRODUCT_DETAILS';

export const DID_CLICK_MENU_BUTTON = 'DID_CLICK_MENU_BUTTON';
export const DID_CLICK_BUTTON = 'DID_CLICK_BUTTON';
export const DID_CLOSE_POPUP = 'DID_CLOSE_POPUP';
export const DID_OPEN_POPUP = 'DID_OPEN_POPUP';
export const DID_CLICK_SPOT = 'DID_CLICK_SPOT';

export const DID_CLICK_BACK_TO_VIDEO = 'DID_CLICK_BACK_TO_VIDEO';

export const DID_CLICK_SHOW_APROADOR_INFO = 'DID_CLICK_SHOW_APROADOR_INFO';
export const DID_CLICK_VIDEO_FULL_SCREEN = 'DID_CLICK_VIDEO_FULL_SCREEN';
export const DID_CLICK_CONTINUE_TOUR = 'DID_CLICK_CONTINUE_TOUR';
export const DID_CLICK_LEAVE_EXPERIENCE = 'DID_CLICK_LEAVE_EXPERIENCE';
export const DID_SUBMIT_GET_NOTIFIED = 'DID_SUBMIT_GET_NOTIFIED';
export const DID_CLICK_KEEP_ME_NOTIFIED = 'DID_CLICK_KEEP_ME_NOTIFIED';

export const DID_CLICK_CREATE_USER = 'DID_CLICK_CREATE_USER';
export const DID_SUBMIT_CREATE_USER_FORM = 'DID_SUBMIT_CREATE_USER_FORM';
export const DID_CREATE_USER_SUCCESSFULLY = 'DID_CREATE_USER_SUCCESSFULLY';
export const DID_FAIL_TO_CREATE_USER = 'DID_FAIL_TO_CREATE_USER';
export const DID_CLICK_EDIT_USER = 'DID_CLICK_EDIT_USER';
export const DID_CLOSE_EDIT_USER_FORM = 'DID_CLOSE_EDIT_USER_FORM';
export const DID_SUBMIT_EDIT_USER_FORM = 'DID_SUBMIT_EDIT_USER_FORM';
export const DID_CLICK_CHANGE_PASSWORD = 'DID_CLICK_CHANGE_PASSWORD';
export const DID_TOGGLE_SHOW_HIDE_PASSWORD = 'DID_TOGGLE_SHOW_HIDE_PASSWORD';
export const DID_SUBMIT_CHANGE_PASSWORD_FORM =
  'DID_SUBMIT_CHANGE_PASSWORD_FORM';
export const DID_CHANGE_PASSWORD_SUCCESSFULLY =
  'DID_CHANGE_PASSWORD_SUCCESSFULLY';
export const DID_FAIL_TO_CHANGE_PASSWORD = 'DID_FAIL_TO_CHANGE_PASSWORD';
export const DID_CLICK_EDIT_PROFILE = 'DID_CLICK_EDIT_PROFILE';

export const DID_CLICK_EDIT_CLIENT_NAME = 'DID_CLICK_EDIT_CLIENT_NAME';
export const DID_CLICK_UPDATE_CLIENT_NAME = 'DID_CLICK_UPDATE_CLIENT_NAME';
export const DID_RENAME_CLIENT_SUCCESSFULLY = 'DID_RENAME_CLIENT_SUCCESSFULLY';
export const DID_FAIL_TO_RENAME_CLIENT_SUCCESSFULLY =
  'DID_FAIL_TO_RENAME_CLIENT_SUCCESSFULLY';

export const DID_FAIL_TO_SAVE_NUDGE_TEMPLATE =
  'DID_FAIL_TO_SAVE_NUDGE_TEMPLATE';

export const DID_CLICK_CHAT_AUTOFILL_BUTTON = 'DID_CLICK_CHAT_AUTOFILL_BUTTON';

export const DID_SELECT_CHAT_AUTOFILL_MESSAGE =
  'DID_SELECT_CHAT_AUTOFILL_MESSAGE';

export const DID_UPDATE_PROGRESS_STATUS = 'DID_UPDATE_PROGRESS_STATUS';
export const DID_TOGGLE_MAP = 'DID_TOGGLE_MAP';
export const DID_SELECT_MAP_SPOT = 'DID_SELECT_MAP_SPOT';
export const DID_SELECT_MAP_ROOM = 'DID_SELECT_MAP_ROOM';
export const DID_CLICK_TELEPORT_SITE = `DID_CLICK_TELEPORT_SITE`;

export const DID_TOGGLE_NOTIFICATIONS = `DID_TOGGLE_NOTIFICATIONS`;

export const DID_TOGGLE_PRODUCT_FILTER_OPTIONS =
  'DID_TOGGLE_PRODUCT_FILTER_OPTIONS';
export const DID_SELECT_SEARCH_FILTER = 'DID_SELECT_SEARCH_FILTER';
export const DID_SEARCH_FOR_GALLERY = 'DID_SEARCH_FOR_GALLERY';
export const DID_SELECT_PRODUCT_FAMILY = 'DID_SELECT_PRODUCT_FAMILY';

export const DID_KEY_PRESS = 'DID_KEY_PRESS';
export const DID_CLICK_GALLERY_ITEM_SEARCH_BUTTON =
  'DID_CLICK_GALLERY_ITEM_SEARCH_BUTTON';
export const DID_CLICK_DOWNLOAD_BUTTON = 'DID_CLICK_DOWNLOAD_BUTTON';
export const DID_CLICK_EDIT = 'DID_CLICK_EDIT';
export const DID_CLICK_CANCEL = 'DID_CLICK_CANCEL';
export const DID_CLICK_SAVE = 'DID_CLICK_SAVE';
export const DID_UPDATE_GALLERY_ITEM_FILTER = 'DID_UPDATE_GALLERY_ITEM_FILTER';
export const DID_TOGGLE_GALLERY_ITEM_FILTER = 'DID_TOGGLE_GALLERY_ITEM_FILTER';

export const DID_CLICK_RESYNC_MEETING_BUTTON =
  'DID_CLICK_RESYNC_MEETING_BUTTON';

export const DID_CLICK_TOGGLE_STATS_MODE = 'DID_CLICK_TOGGLE_STATS_MODE';

export const DID_OPEN_STORYBOOK_VIEWER = 'DID_OPEN_STORYBOOK_VIEWER';

export const SAVING_STORYBOOK = 'SAVING_STORYBOOK';
export const SAVING_STORYBOOK_SUCCESSFUL = 'SAVING_STORYBOOK_SUCCESSFUL';
export const SAVING_STORYBOOK_FAILED = 'SAVING_STORYBOOK_FAILED';

export const LOADING_STORYBOOK = 'LOADING_STORYBOOK';
export const LOADING_STORYBOOK_SUCCESSFUL = 'LOADING_STORYBOOK_SUCCESSFUL';
export const LOADING_STORYBOOK_FAILED = 'LOADING_STORYBOOK_FAILED';

export const LOADING_STORYBOOKS = 'LOADING_STORYBOOKS';
export const LOADING_STORYBOOKS_SUCCESSFUL = 'LOADING_STORYBOOKS_SUCCESSFUL';
export const LOADING_STORYBOOKS_FAILED = 'LOADING_STORYBOOKS_FAILED';

export const LOADING_STORYBOOK_FILES = 'LOADING_STORYBOOK_FILES';
export const LOADING_STORYBOOK_FILES_SUCCESSFUL =
  'LOADING_STORYBOOK_FILES_SUCCESSFUL';
export const LOADING_STORYBOOK_FILES_FAILED = 'LOADING_STORYBOOK_FILES_FAILED';

export const ACTIVATING_STORYBOOK = 'ACTIVATING_STORYBOOK';
export const ACTIVATING_STORYBOOK_SUCCESSFUL =
  'ACTIVATING_STORYBOOK_SUCCESSFUL';
export const ACTIVATING_STORYBOOK_FAILED = 'ACTIVATING_STORYBOOK_FAILED';

export const UPDATING_STORYBOOK_ATTRIBUTES = 'UPDATING_STORYBOOK_ATTRIBUTES';
export const UPDATING_STORYBOOK_ATTRIBUTES_SUCCESSFUL =
  'UPDATING_STORYBOOK_ATTRIBUTES_SUCCESSFUL';
export const UPDATING_STORYBOOK_ATTRIBUTES_FAILED =
  'UPDATING_STORYBOOK_ATTRIBUTES_FAILED';

export const PUBLISHING_STORYBOOK = 'PUBLISHING_STORYBOOK';
export const PUBLISHING_STORYBOOK_SUCCESSFUL =
  'PUBLISHING_STORYBOOK_SUCCESSFUL';
export const PUBLISHING_STORYBOOK_FAILED = 'PUBLISHING_STORYBOOK_FAILED';

export const UNPUBLISHING_STORYBOOK = 'UNPUBLISHING_STORYBOOK';
export const UNPUBLISHING_STORYBOOK_SUCCESSFUL =
  'UNPUBLISHING_STORYBOOK_SUCCESSFUL';
export const UNPUBLISHING_STORYBOOK_FAILED = 'UNPUBLISHING_STORYBOOK_FAILED';

export const DELETING_STORYBOOK = 'DELETING_STORYBOOK';
export const DELETING_STORYBOOK_SUCCESSFUL = 'DELETING_STORYBOOK_SUCCESSFUL';
export const DELETING_STORYBOOK_FAILED = 'DELETING_STORYBOOK_FAILED';

export const DID_SHARE_STORYBOOK = 'DID_SHARE_STORYBOOK';
export const DID_SHARE_SCENE = 'DID_SHARE_SCENE';
export const SHARE_STORYBOOK_FAILED = 'SHARE_STORYBOOK_FAILED';

export const DID_SHARE_ASSET = 'DID_SHARE_ASSET';
export const SHARE_ASSET_FAILED = 'SHARE_ASSET_FAILED';

export const DID_PRESENT_STORYBOOK = 'DID_PRESENT_STORYBOOK';
export const PRESENT_STORYBOOK_FAILED = 'PRESENT_STORYBOOK_FAILED';

export const MEETING_RECONNECT_STATE = 'MEETING_RECONNECT_STATE';

export const AUDIO_OUTPUT = 'MEETING.AUDIO_OUTPUT';

export const DID_SEARCH_FOR_STORYBOOK = 'DID_SEARCH_FOR_STORYBOOK';
export const DID_LOGIN_HUB_IN_MEETING = 'DID_LOGIN_HUB_IN_MEETING';

export const DID_SHOW_ERROR = 'DID_SHOW_ERROR';

export const PLAY_FULL_SCREEN_VIDEO = 'PLAY_FULL_SCREEN_VIDEO';

export const IFRAME_ERROR = 'IFRAME_ERROR';
export const IFRAME_LOADED = 'IFRAME_LOADED';

export const DID_GO_TO_SCENE = 'DID_GO_TO_SCENE';
export const DID_GO_TO_SPOT = 'DID_GO_TO_SPOT';
export const WEBSOCKET_OPEN = 'WEBSOCKET_OPEN';
export const WEBSOCKET_CLOSED = 'WEBSOCKET_CLOSED';
export const WEBSOCKET_ERROR = 'WEBSOCKET_ERROR';
export const DID_ENTER_EXPERIENCE = 'DID_ENTER_EXPERIENCE';
export const DID_CLICK_THUMBNAIL = 'DID_CLICK_THUMBNAIL';

export const CLONING = 'CLONING';
export const CLONING_SUCCESSFUL = 'CLONING_SUCCESSFUL';
export const CLONING_FAILED = 'CLONING_FAILED';

export const CONTACT_SUPPORT_EVENT = 'CONTACT_SUPPORT_EVENT';
export const DID_CLICK_CONTACT_SUPPORT_BUTTON =
  'DID_CLICK_CONTACT_SUPPORT_BUTTON';
export const DID_CLICK_SUBMIT_CONTACT_SUPPORT =
  'DID_CLICK_SUBMIT_CONTACT_SUPPORT';
export const DID_SUBMIT_CONTACT_SUPPORT_SUCCESSFUL =
  'DID_SUBMIT_CONTACT_SUPPORT_SUCCESSFUL';
export const DID_SUBMIT_CONTACT_SUPPORT_FAILED =
  'DID_SUBMIT_CONTACT_SUPPORT_FAILED';

export const HUB_BRANDING_EVENT = 'HUB_BRANDING_EVENT';
export const DID_CLICK_BRAND_PROFILE_MENU = 'DID_CLICK_BRAND_PROFILE_MENU';
export const DID_CLICK_BRAND_THEME_MENU = 'DID_CLICK_BRAND_THEME_MENU';
export const DID_CLICK_BRAND_ASSET_MENU = 'DID_CLICK_BRAND_ASSET_MENU';
export const DID_CLICK_BRANDING_REMINDER = 'DID_CLICK_BRANDING_REMINDER';
export const DID_CLICK_BRANDING_PREVIEW = 'DID_CLICK_BRANDING_PREVIEW';
export const DID_CLICK_BRANDING_CHANGE_FILE = 'DID_CLICK_BRANDING_CHANGE_FILE';
export const DID_CLICK_BRANDING_DELETE_FILE = 'DID_CLICK_BRANDING_DELETE_FILE';

export const DID_CLICK_BRANDING_PREVIEW_THEME_BUTTON =
  'DID_CLICK_BRANDING_PREVIEW_THEME_BUTTON';

export const DID_UPDATE_BRAND_PROFILE_SUCCESSFUL =
  'DID_UPDATE_BRAND_PROFILE_SUCCESSFUL';
export const DID_UPDATE_BRAND_PROFILE_FAILED =
  'DID_UPDATE_BRAND_PROFILE_FAILED';
export const DID_UPDATE_BRAND_THEME_SUCCESSFUL =
  'DID_UPDATE_BRAND_THEME_SUCCESSFUL';
export const DID_UPDATE_BRAND_THEME_FAILED = 'DID_UPDATE_BRAND_THEME_FAILED';
export const DID_UPDATE_BRAND_ASSETS_SUCCESSFUL =
  'DID_UPDATE_BRAND_ASSETS_SUCCESSFUL';
export const DID_UPDATE_BRAND_ASSETS_FAILED = 'DID_UPDATE_BRAND_ASSETS_FAILED';

export const SET_USER_ID_FAILED = 'SET_USER_ID_FAILED';

export const DID_SAVE_POLOTNO_JSON = 'DID_SAVE_POLOTNO_JSON';
//
export const HUB_ASSET_LIBRARY_EVENT = 'HUB_ASSET_LIBRARY_EVENT';
export const DID_CLICK_UPLOAD_ASSET = 'DID_CLICK_UPLOAD_ASSET';
export const DID_CLICK_CONFIRM_UPLOAD_ASSET = 'DID_CLICK_CONFIRM_UPLOAD_ASSET';
export const DID_CLICK_UPLOAD_ASSET_SUCCESSFUL =
  'DID_CLICK_UPLOAD_ASSET_SUCCESSFUL';
export const DID_CLICK_UPLOAD_ASSET_FAILED = 'DID_CLICK_UPLOAD_ASSET_FAILED';
export const DID_CLICK_ASSET_THUMBNAIL = 'DID_CLICK_ASSET_THUMBNAIL';
export const DID_CLICK_PREVIEW_ASSET_THUMBNAIL =
  'DID_CLICK_PREVIEW_ASSET_THUMBNAIL';
export const DID_CLICK_CATEGORY = 'DID_CLICK_CATEGORY';
export const DID_CLICK_UPDATE_CATEGORY = 'DID_CLICK_UPDATE_CATEGORY';
export const DID_CLICK_DELETE_CATEGORY = 'DID_CLICK_DELETE_CATEGORY';
export const DID_CLICK_CREATE_CATEGORY = 'DID_CLICK_CREATE_CATEGORY';
export const DID_CLICK_ADD_CATEGORY = 'DID_CLICK_ADD_CATEGORY';
export const DID_CLICK_SORT_ASSETS_OPTION = 'DID_CLICK_SORT_ASSETS_OPTION';
export const DID_CLICK_SELECT_PARENT_CATEGORY =
  'DID_CLICK_SELECT_PARENT_CATEGORY';
export const DID_CLICK_DELETED_MENU = 'DID_CLICK_DELETED_MENU';
export const DID_CLICK_REMOVE_ASSET_BUTTON = 'DID_CLICK_REMOVE_ASSET_BUTTON';
export const DID_UPDATE_TITLE_FAILED = 'DID_UPDATE_TITLE_FAILED';
export const DID_UPDATE_TITLE_SUCCESSFUL = 'DID_UPDATE_TITLE_SUCCESSFUL';

export const DID_UPDATE_CATEGORY = 'DID_UPDATE_CATEGORY';
export const DID_DELETE_CATEGORY = 'DID_DELETE_CATEGORY';
export const DID_CREATE_CATEGORY = 'DID_CREATE_CATEGORY';
export const DID_UPLOAD_ASSET_SUCCESSFUL = 'DID_UPLOAD_ASSET_SUCCESSFUL';
export const DID_UPLOAD_ASSET_FAILED = 'DID_UPLOAD_ASSET_FAILED';

export const SORT_KEY_CAMEL_CASE = {
  title: 'title',
  createdat: 'createdAt',
  sizekb: 'sizeKb'
};

export const DID_FAIL_TO_SEND_END_POINT_MESSAGE =
  'DID_FAIL_TO_SEND_END_POINT_MESSAGE';
export const DID_RETRY_TO_SEND_END_POINT_MESSAGE =
  'DID_RETRY_TO_SEND_END_POINT_MESSAGE';

export const HISTORY_SCENES_STORED = 'scenesBackup';