// This file contains all the events in sequence to debug meeting journey

export const MEETING_DEBUG = 'MEETING_DEBUG';

export const DID_LOAD_LOUNGE = 'DID_LOAD_LOUNGE';
export const DID_INIT_JITSI = 'DID_INIT_JITSI';
export const DID_FAIL_TO_INIT_JITSI = 'DID_FAIL_TO_INIT_JITSI';
export const DID_CONNECT_TO_SERVER = 'DID_CONNECT_TO_SERVER';
export const DID_FAIL_TO_CONNECT_TO_SERVER = 'DID_FAIL_TO_CONNECT_TO_SERVER';
export const DID_GET_USER_MEDIA = 'DID_GET_USER_MEDIA';
export const DID_FAIL_TO_GET_USER_MEDIA = 'DID_FAIL_TO_GET_USER_MEDIA';
export const DID_CREATE_LOCAL_TRACKS = 'DID_CREATE_LOCAL_TRACKS';
export const DID_ATTACH_LOCAL_TRACKS = 'DID_ATTACH_LOCAL_TRACKS';
export const DID_FAIL_TO_ATTACH_LOCAL_TRACKS =
  'DID_FAIL_TO_ATTACH_LOCAL_TRACKS';
export const DID_FAIL_TO_CREATE_LOCAL_TRACKS =
  'DID_FAIL_TO_CREATE_LOCAL_TRACKS';
export const BRIDGE_CHANNEL_OPENED = 'BRIDGE_CHANNEL_OPENED';
export const BRIDGE_CHANNEL_CLOSED = 'BRIDGE_CHANNEL_CLOSED';
export const DID_SEND_ADVISOR_INFO = 'DID_SEND_ADVISOR_INFO';
export const DID_SEND_USER_INFO = 'DID_SEND_USER_INFO';
export const DID_CLICK_JOIN_NOW = 'DID_CLICK_JOIN_NOW';
export const DID_ADMIT_USER_INTO_MEETING = 'DID_ADMIT_USER_INTO_MEETING';
export const DID_SHOW_WELCOME_VIDEO_FROM_LOUNGE =
  'DID_SHOW_WELCOME_VIDEO_FROM_LOUNGE';
export const DID_ENTER_MEETING_FROM_LOUNGE = 'DID_ENTER_MEETING_FROM_LOUNGE';
export const DID_SHOW_MEETING_OVERLAY = 'DID_SHOW_MEETING_OVERLAY';
export const LOCAL_TRACK_STOPPED = 'LOCAL_TRACK_STOPPED';
