import { assetBaseUrl } from '../../../config';
import {
  IAnimationProduct,
  ITarotProduct
} from '../../../interfaces/loubiairways';

export const animationAssetsBaseUrl = `${assetBaseUrl}/loubiairways/luggage-animation/`;
// export const animationAssetsBaseUrl = 'http://192.168.1.12/Games/Client/CoachForEyes/Sheng/luggage-animation/';
// export const animationAssetsBaseUrl = 'http://192.168.1.12:3000/luggage-animation/';
const airplaneResourcePath = animationAssetsBaseUrl + 'airplane-window/v12/';
export const airplaneWindowImagePath = `${animationAssetsBaseUrl}airplane-window/background/v2/airplane_window_background.png`;
export const skyBackgroundImagePath = `${animationAssetsBaseUrl}airplane-window/background/v2/Windowclouds.jpg`;
export const getCloudImage = (i: number) => airplaneResourcePath + `c${i}.png`;
export const modelCloudEnvironmentImagePath =
  airplaneResourcePath + 'aircraft_workshop.hdr';
export const modelWorkshopEnvironmentImagePath =
  animationAssetsBaseUrl +
  'common/environment/v1/aircraft_workshop_01_1k_BW.hdr';

export const backImagePath =
  animationAssetsBaseUrl + 'common/back_to_animation.png';

export const windowTextures = {
  festividadeMap: `${airplaneResourcePath}3210411_J582_FESTIVIDADE/Textures/Festividate_mat_baseColor.jpg`,
  festividadeNormalMap: `${airplaneResourcePath}3210411_J582_FESTIVIDADE/Textures/Festividate_mat_normal_LightModel.jpg`,

  noorMap: `${airplaneResourcePath}3210276_E052_NOOR_55/Textures/Solid_mat_baseColor.jpg`,
  noorMetalMap: `${airplaneResourcePath}3210276_E052_NOOR_55/Textures/Solid_mat_occlusionRoughnessMetallic.jpg`,
  noorNormalMap: `${airplaneResourcePath}3210276_E052_NOOR_55/Textures/Solid_mat_normal.jpg`,

  mariaMap: `${airplaneResourcePath}3210259_M700_MARIE_ANNE_55/Textures/Base_mat_baseColor_LightModel.jpg`,
  mariaMetalMap: `${airplaneResourcePath}3210259_M700_MARIE_ANNE_55/Textures/Base_mat_occlusionRoughnessMetallic_LightModel.jpg`,
  mariaNormalMap: `${airplaneResourcePath}3210259_M700_MARIE_ANNE_55/Textures/Base_mat_normal_LightModel.jpg`,

  loopingaMap: `${airplaneResourcePath}3210521_CMA3_LOOPINGA_TOE_PLUME_100/Textures/lambert1_baseColor_LightModel.jpg`,
  loopingaMetalMap: `${airplaneResourcePath}3210521_CMA3_LOOPINGA_TOE_PLUME_100/Textures/lambert1_occlusionRoughnessMetallic_LightModel.jpg`,
  loopingaNormalMap: `${airplaneResourcePath}3210521_CMA3_LOOPINGA_TOE_PLUME_100/Textures/lambert1_normal.jpg`,

  papayaMap: `${airplaneResourcePath}FW21_PAPAGAYA_LEVITA_100/Textures/Papaya100_mat_baseColor.jpg`,
  papayaMetalMap: `${airplaneResourcePath}FW21_PAPAGAYA_LEVITA_100/Textures/Papaya100_mat_occlusionRoughnessMetallic.jpg`,

  relexMap: `${airplaneResourcePath}3210450_BL4G_RELAX_MAX/Textures/Relax_mat_normal.jpg`,
  relexNormalMap: `${airplaneResourcePath}3210450_BL4G_RELAX_MAX/Textures/Relax_mat_normal.jpg`,

  dandelionMap: `${airplaneResourcePath}3210435_H414_DANDELION_SPIKES/Textures/DANDELION_mat_baseColor.jpg`,
  dandelionNormalMap: `${airplaneResourcePath}3210435_H414_DANDELION_SPIKES/Textures/DANDELION_mat_normal.jpg`,
  dandelionMetalMap: `${airplaneResourcePath}3210435_H414_DANDELION_SPIKES/Textures/DANDELION_mat_occlusionRoughnessMetallic.jpg`,

  palomaBagMap: `${airplaneResourcePath}3215090_H620_PALOMA_BAGUETTE_SMALL/Textures/Baguette_01_mat_baseColor.jpg`,
  palomaBagNormalMap: `${airplaneResourcePath}3215090_H620_PALOMA_BAGUETTE_SMALL/Textures/Baguette_02_mat_normal.jpg`
};
const festividade: IAnimationProduct = {
  id: 'b0b8515a-7bd4-4c3f-92e0-c61dc01568d5',
  modelName: 'FESTIVIDADE',
  modelCode: 'CLLOUBIAIRWAYS001',
  lowPolyModelUrl: `${airplaneResourcePath}3210411_J582_FESTIVIDADE/512/FESTIVIDADE_512.glb`,
  highPolyModelUrl: `${airplaneResourcePath}3210411_J582_FESTIVIDADE/3210411_J582_FESTIVIDADE.glb`
};
const noor: IAnimationProduct = {
  id: '88d75c74-ce78-4bb4-b2b7-8363ea5cbbba',
  modelName: 'NOOR',
  modelCode: 'CLLOUBIAIRWAYS002',
  lowPolyModelUrl: `${airplaneResourcePath}3210276_E052_NOOR_55/512/Noor_55_512.glb`,
  highPolyModelUrl: `${airplaneResourcePath}3210276_E052_NOOR_55/Noor_55.glb`
};
const marie_anne: IAnimationProduct = {
  id: '7f6daa3f-7be8-4f86-8831-939a85f35440',
  modelName: 'MARIE ANNE',
  modelCode: 'CLLOUBIAIRWAYS003',
  lowPolyModelUrl: `${airplaneResourcePath}3210259_M700_MARIE_ANNE_55/512/MARIE_ANNE_55.glb`,
  highPolyModelUrl: `${airplaneResourcePath}3210259_M700_MARIE_ANNE_55/M700_MARIE_ANNE_55.glb`
};
const loopinga_toe_plume: IAnimationProduct = {
  id: '7c04a60d-8fe2-4901-81df-8d344697dab9',
  modelName: 'LOOPINGA TOE PLUME ',
  modelCode: 'CLLOUBIAIRWAYS004',
  lowPolyModelUrl: `${airplaneResourcePath}3210521_CMA3_LOOPINGA_TOE_PLUME_100/512/LOOPINGA_TOE_PLUME_100_512.glb`,
  highPolyModelUrl: `${airplaneResourcePath}3210521_CMA3_LOOPINGA_TOE_PLUME_100/3210521_CMA3_LOOPINGA_TOE_PLUME_100.glb`
};
const papagaya_levita: IAnimationProduct = {
  id: 'acab3445-0887-43d5-98c7-2dbdf881c7e9',
  modelName: 'PAPAGAYA LEVITA',
  modelCode: 'CLLOUBIAIRWAYS005',
  lowPolyModelUrl: `${airplaneResourcePath}FW21_PAPAGAYA_LEVITA_100/512/PAPAGAYA_LEVITA_100_512.glb`,
  highPolyModelUrl: `${airplaneResourcePath}FW21_PAPAGAYA_LEVITA_100/FW21_PAPAGAYA_LEVITA_100.glb`
};
const relax_max: IAnimationProduct = {
  id: '06a8cf21-6e87-465c-83f1-7206f7cabcd5',
  modelName: 'RELAX MAX',
  modelCode: 'CLLOUBIAIRWAYS006',
  lowPolyModelUrl: `${airplaneResourcePath}3210450_BL4G_RELAX_MAX/512/RELAX_MAX_512.glb`,
  highPolyModelUrl: `${airplaneResourcePath}3210450_BL4G_RELAX_MAX/3210450_BL4G_RELAX_MAX.glb`
};
const dandelion_spikes: IAnimationProduct = {
  id: '95544407-3580-469d-966a-8b973cf00d34',
  modelName: 'DANDELION SPIKES',
  modelCode: 'CLLOUBIAIRWAYS007',
  lowPolyModelUrl: `${airplaneResourcePath}3210435_H414_DANDELION_SPIKES/512/3210435_H414_DANDELION_SPIKES_512.glb`,
  highPolyModelUrl: `${airplaneResourcePath}3210435_H414_DANDELION_SPIKES/3210435_H414_DANDELION_SPIKES.glb`
};
const paloma_baguette: IAnimationProduct = {
  id: 'a33deace-927a-4d81-962e-605eaec77956',
  modelName: 'PALOMA BAGUETTE SMALL',
  modelCode: 'CLLOUBIAIRWAYS008',
  lowPolyModelUrl: `${airplaneResourcePath}3215090_H620_PALOMA_BAGUETTE_SMALL/512/Baguette_512.glb`,
  highPolyModelUrl: `${airplaneResourcePath}3215090_H620_PALOMA_BAGUETTE_SMALL/3215090_H620_PALOMA_BAGUETTE_SMALL.glb`
};

export const airplaneWindowProducts: IAnimationProduct[] = [
  festividade,
  noor,
  marie_anne,
  loopinga_toe_plume,
  papagaya_levita,
  relax_max,
  dandelion_spikes,
  paloma_baguette
];

export const tarotBaseUrl = `${animationAssetsBaseUrl}tarot/v5/`;
export const cardTopImage = `${tarotBaseUrl}anim/CL_TAROT_CARD_BACK_CARD.png`;

export const tarotTextures = {
  officialitoMap: `${tarotBaseUrl}3210414_M039_OFFICIALITO/Textures/Officialito_mat_baseColor.jpg`,
  officialitoNormalMap: `${tarotBaseUrl}3210414_M039_OFFICIALITO/Textures/Officialito_mat_normal.jpg`,

  noLimitMap: `${tarotBaseUrl}3210502_CMA3_NO LIMIT CARACABA/Textures/CARACABA_baseColor.jpg`,
  noLimitNormalMap: `${tarotBaseUrl}3210502_CMA3_NO LIMIT CARACABA/Textures/CARACABA_normal.jpg`,

  irizaMap: `${tarotBaseUrl}3210323_J546_IRIZA_100/Textures/Iriza_100_mat_baseColor.jpg`,
  irizaNormalMap: `${tarotBaseUrl}3210323_J546_IRIZA_100/Textures/Iriza_100_mat_normal.jpg`,

  sandaleMap: `${tarotBaseUrl}3210262_CMA3_SANDALE_DU_DESERT_ALTA_130/Textures/baseColor.jpg`,
  sandaleNormalMap: `${tarotBaseUrl}3210262_CMA3_SANDALE_DU_DESERT_ALTA_130/Textures/mat_normal.jpg`,

  caracabaMap: `${tarotBaseUrl}3215060_J596_CARACABA_SMALL/Textures/lambert1_baseColor.jpg`,
  caracabaNormalMap: `${tarotBaseUrl}3215060_J596_CARACABA_SMALL/Textures/lambert1_normal.jpg`,

  elisaMap: `${tarotBaseUrl}3215073_J627_ELISA_MINI_AFRICABA/Textures/AFRICABA_mat_baseColor.jpg`,
  elisaNormalMap: `${tarotBaseUrl}3215073_J627_ELISA_MINI_AFRICABA/Textures/AFRICABA_mat_normal.jpg`,

  elisaBlueMap: `${tarotBaseUrl}3215061_J627_ELISA_SMALL_MEXICABA/Textures/Mexicaba_mat_baseColor.jpg`,
  elisaBlueNormalMap: `${tarotBaseUrl}3215061_J627_ELISA_SMALL_MEXICABA/Textures/Mexicaba_mat_normal.jpg`,

  elisaTopMap: `${tarotBaseUrl}3215082_J628_ELISA_TOP_HANDLE_NANO/Textures/lambert2_baseColor.jpg`,
  elisaTopNormalMap: `${tarotBaseUrl}3215082_J628_ELISA_TOP_HANDLE_NANO/Textures/lambert2_normal.jpg`,

  elisaTop2Map: `${tarotBaseUrl}3215078_B533_ELISA_TOP_HANDLE_MINI/Textures/ElisaTopHandle_mat_baseColor.jpg`,
  elisaTop2NormalMap: `${tarotBaseUrl}3215078_B533_ELISA_TOP_HANDLE_MINI/Textures/ElisaTopHandle_mat_normal.jpg`
};

const officialito: IAnimationProduct = {
  id: 'a3711ec9-c7ff-4b94-94c4-a922637023c0',
  modelName: 'OFFICIALITO',
  modelCode: 'CLLOUBIAIRWAYS009',
  lowPolyModelUrl: `${tarotBaseUrl}3210414_M039_OFFICIALITO/512/3210414_M039_OFFICIALITO_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3210414_M039_OFFICIALITO/3210414_M039_OFFICIALITO.glb`
};
const no_limit_caracaba: IAnimationProduct = {
  id: 'e5c341bc-c97f-4d83-8408-6b2b688173d0',
  modelName: 'NO LIMIT CARACABA',
  modelCode: 'CLLOUBIAIRWAYS010',
  lowPolyModelUrl: `${tarotBaseUrl}3210502_CMA3_NO LIMIT CARACABA/512/NO_LIMIT_CARACABA_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3210502_CMA3_NO LIMIT CARACABA/3210502_NO_LIMIT_CARACABA.glb`
};
const iriza: IAnimationProduct = {
  id: '6f7498a1-bc7e-4f2d-bf68-b7e1b69964f6',
  modelName: 'IRIZA',
  modelCode: 'CLLOUBIAIRWAYS011',
  lowPolyModelUrl: `${tarotBaseUrl}3210323_J546_IRIZA_100/512/IRIZA_100_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3210323_J546_IRIZA_100/3210323_J546_IRIZA_100.glb`
};
const sandale_du_desert: IAnimationProduct = {
  id: 'c12c303b-845a-4444-9de3-67f249c3e228',
  modelName: 'SANDALE DU DESERT',
  modelCode: 'CLLOUBIAIRWAYS012',
  lowPolyModelUrl: `${tarotBaseUrl}3210262_CMA3_SANDALE_DU_DESERT_ALTA_130/512/3210262_CMA3_SANDALE_DU_DESERT_ALTA_130_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3210262_CMA3_SANDALE_DU_DESERT_ALTA_130/3210262_CMA3_SANDALE_DU_DESERT_ALTA_130.glb`
};
const caracaba: IAnimationProduct = {
  id: '8b0ca156-1bac-40be-b46b-06f2a9e5f459',
  modelName: 'CARACABA SMALL',
  modelCode: 'CLLOUBIAIRWAYS013',
  lowPolyModelUrl: `${tarotBaseUrl}3215060_J596_CARACABA_SMALL/512/Caracaba_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3215060_J596_CARACABA_SMALL/3215060_J596_CARACABA_SMALL_Small.glb`
};
const elisa: IAnimationProduct = {
  id: '9c859ea8-af6a-406d-aab3-1a5fcdf86817',
  modelName: 'ELISA MINI',
  modelCode: 'CLLOUBIAIRWAYS014',
  lowPolyModelUrl: `${tarotBaseUrl}3215073_J627_ELISA_MINI_AFRICABA/512/3215073_J627_ELISA_MINI_AFRICABA_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3215073_J627_ELISA_MINI_AFRICABA/3215073_J627_ELISA_MINI_AFRICABA.glb`
};
const elisa_blue: IAnimationProduct = {
  id: 'afbc1f48-d3a7-4085-aba3-497714f0c82f',
  modelName: 'ELISA SMALL',
  modelCode: 'CLLOUBIAIRWAYS015',
  lowPolyModelUrl: `${tarotBaseUrl}3215061_J627_ELISA_SMALL_MEXICABA/512/3215061_J627_ELISA_SMALL_MEXICABA_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3215061_J627_ELISA_SMALL_MEXICABA/3215061_J627_ELISA_SMALL_MEXICABA.glb`
};

const elisa_top_handle: IAnimationProduct = {
  id: '9ae17c0b-2e16-4365-bfe2-3275f91a42b1',
  modelName: 'ELISA TOP HANDLE NANO',
  modelCode: 'CLLOUBIAIRWAYS016',
  lowPolyModelUrl: `${tarotBaseUrl}3215082_J628_ELISA_TOP_HANDLE_NANO/512/ELISA_TOP_HANDLE_NANO_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3215082_J628_ELISA_TOP_HANDLE_NANO/ELISA_TOP_HANDLE_NANO.glb`
};
const elisa_top_handle_2: IAnimationProduct = {
  id: 'dcdf0435-1799-4950-a3e0-ed9f0a132239',
  modelName: 'ELISA TOP HANDLE MINI',
  modelCode: 'CLLOUBIAIRWAYS017',
  lowPolyModelUrl: `${tarotBaseUrl}3215078_B533_ELISA_TOP_HANDLE_MINI/512/ELISA_TOP_HANDLE_MINI_512.glb`,
  highPolyModelUrl: `${tarotBaseUrl}3215078_B533_ELISA_TOP_HANDLE_MINI/3215078_B533_ELISA_TOP_HANDLE_MINI.glb`
};

const amoureux: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/AMOUREUX.jpg`,
  row: 4,
  col: 2,
  frames: 8
};
const bateleur: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/BATELEUR.jpg`,
  row: 4,
  col: 2,
  frames: 8
};
const baton: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/BATON.jpg`,
  row: 4,
  col: 2,
  frames: 8
};
const chariot: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/CHARIOT.jpg`,
  row: 4,
  col: 2,
  frames: 8
};
const coupe: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/COUPE.jpg`,
  row: 4,
  col: 2,
  frames: 8
};
const denier: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/DENIER.jpg`,
  row: 4,
  col: 2,
  frames: 8
};
const etoile: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/ETOILE.jpg`,
  row: 4,
  col: 2,
  frames: 8
};
const la_force: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/LA_FORCE.jpg`,
  row: 4,
  col: 2,
  frames: 8
};
const le_monde00: ITarotProduct = {
  url: `${tarotBaseUrl}anim/v2/LE_MONDE.jpg`,
  row: 4,
  col: 2,
  frames: 8
};

export const tarotProducts: IAnimationProduct[] = [
  elisa,
  elisa_top_handle_2,
  elisa_blue,
  elisa_top_handle,
  iriza,
  sandale_du_desert,
  caracaba,
  no_limit_caracaba,
  officialito
];

export const tarotCardImage = [
  la_force,
  amoureux,
  chariot,
  baton,
  le_monde00,
  etoile,
  bateleur,
  denier,
  coupe
];

export const luggageBaseUrl = `${animationAssetsBaseUrl}luggage/v9/`;

export const suitcaseModel = `${luggageBaseUrl}suitcase/v1/Luggage Bag (Remodeled) Open Sticker.gltf`;

export const luggageTextures = {
  epicTrottaMap: `${luggageBaseUrl}3210849-EPIC TROTTA 70/Textures/Trotta_mat_baseColor.jpg`,
  epicTrottaNormal: `${luggageBaseUrl}3210849-EPIC TROTTA 70/Textures/Trotta_mat_normal.jpg`,

  epicBootyMap: `${luggageBaseUrl}3210852-268 EPICBOOTY 70 PAT OH/Textures/EPICBOOTY70_mat_baseColor.jpg`,
  epicBootyNormal: `${luggageBaseUrl}3210852-268 EPICBOOTY 70 PAT OH/Textures/EPICBOOTY70_mat_normal.jpg`,

  epicSandalMap: `${luggageBaseUrl}3210789-170 EPIC SANDAL 70/Textures/EpicSandal_mat_baseColor.jpg`,
  epicSandalNormal: `${luggageBaseUrl}3210789-170 EPIC SANDAL 70/Textures/EpicSandal_mat_normal.jpg`,

  epicSlingMap: `${luggageBaseUrl}3210795-269 EPIC SLING 70 PAT OH/Textures/Sling_mat_baseColor.jpg`,
  epicSlingNormalMap: `${luggageBaseUrl}3210795-269 EPIC SLING 70 PAT OH/Textures/Sling_mat_normal.jpg`,

  baguetteBagMap: `${luggageBaseUrl}3215238-PALOMA BAGUETTE S PATENT OH XTIAN-SOLE/Textures/Baguette_01_mat_baseColor.jpg`,
  baguetteBagNormalMap: `${luggageBaseUrl}3215238-PALOMA BAGUETTE S PATENT OH XTIAN-SOLE/Textures/Baguette_02_mat_normal.jpg`
};
const epic_trotta: IAnimationProduct = {
  id: 'e85e9f17-4228-4101-b29c-1bac4e2ec760',
  modelName: 'EPIC TROTTA',
  modelCode: 'CLLOUBIAIRWAYS030',
  lowPolyModelUrl: `${luggageBaseUrl}3210849-EPIC TROTTA 70/512/EPIC_TROTTA_70_512.glb`,
  highPolyModelUrl: `${luggageBaseUrl}3210849-EPIC TROTTA 70/EPIC_TROTTA_70.glb`
};
const epic_booty: IAnimationProduct = {
  id: '6a053148-5921-45d1-ba3d-f6ef189b6582',
  modelName: 'EPIC BOOTY',
  modelCode: 'CLLOUBIAIRWAYS031',
  lowPolyModelUrl: `${luggageBaseUrl}3210852-268 EPICBOOTY 70 PAT OH/512/EPICBOOTY_70_PAT_OH_512.glb`,
  highPolyModelUrl: `${luggageBaseUrl}3210852-268 EPICBOOTY 70 PAT OH/EPICBOOTY_70_PAT_OH.glb`
};
const epic_sandal: IAnimationProduct = {
  id: '56172f70-b4f8-4b57-84c8-0f0ac2456f38',
  modelName: 'EPIC SANDAL',
  modelCode: 'CLLOUBIAIRWAYS032',
  lowPolyModelUrl: `${luggageBaseUrl}3210789-170 EPIC SANDAL 70/512/EPIC_SANDAL_70_512.glb`,
  highPolyModelUrl: `${luggageBaseUrl}3210789-170 EPIC SANDAL 70/EPIC_SANDAL_70.glb`
};
const epic_sling: IAnimationProduct = {
  id: '994e4a0f-4b3f-4ded-ba78-21753b317f7c',
  modelName: 'EPIC SLING',
  modelCode: 'CLLOUBIAIRWAYS033',
  lowPolyModelUrl: `${luggageBaseUrl}3210795-269 EPIC SLING 70 PAT OH/512/EPIC_SLING_70_PAT_OH_512.glb`,
  highPolyModelUrl: `${luggageBaseUrl}3210795-269 EPIC SLING 70 PAT OH/EPIC_SLING_70_PAT_OH.glb`
};
const paloma_baguette_2: IAnimationProduct = {
  id: '85b58395-0b8e-42d1-a7c7-31f5e9dbb8fa',
  modelName: 'PALOMA BAGUETTE S',
  modelCode: 'CLLOUBIAIRWAYS034',
  lowPolyModelUrl: `${luggageBaseUrl}3215238-PALOMA BAGUETTE S PATENT OH XTIAN-SOLE/512/Patent_512.glb`,
  highPolyModelUrl: `${luggageBaseUrl}3215238-PALOMA BAGUETTE S PATENT OH XTIAN-SOLE/3215238-PALOMA BAGUETTE S PATENT OH XTIAN-SOLE.glb`
};

export const luggageAnimationProducts: IAnimationProduct[] = [
  epic_trotta,
  epic_booty,
  epic_sandal,
  epic_sling,
  paloma_baguette_2
];

export const trolleyBaseUrl = `${animationAssetsBaseUrl}trolley/v8/`;
export const trolleyImg = `${trolleyBaseUrl}trolley/v1/img.png`;

export const rantulow: IAnimationProduct = {
  id: 'efcea76c-7aab-4ac9-8f26-a107c00b8479',
  modelName: 'RANTULOW ORLATO',
  modelCode: 'CLLOUBIAIRWAYS018',
  lowPolyModelUrl: `${trolleyBaseUrl}1210284-RANTULOW ORLATO FLAT/512/RANTULOW_ORLATO_FLAT_512.glb`,
  highPolyModelUrl: `${trolleyBaseUrl}1210284-RANTULOW ORLATO FLAT/1210284_RANTULOW_ORLATO_FLAT_to_gltf.glb`
};

export const louisPStrass: IAnimationProduct = {
  id: '975f7920-cc00-4bfa-8c63-cf1d556dbe18',
  modelName: 'LOUIS P STRASS',
  modelCode: 'CLLOUBIAIRWAYS019',
  lowPolyModelUrl: `${trolleyBaseUrl}3210744-LOUIS P STRASS FLAT/512/3210744_LOUIS_P_STRASS_FLAT_512.glb`,
  highPolyModelUrl: `${trolleyBaseUrl}3210744-LOUIS P STRASS FLAT/3210744_LOUIS_P_STRASS_FLAT.glb`
};

export const navyPool: IAnimationProduct = {
  id: 'f154ce2e-a429-412b-9f11-ef6684bba8b0',
  modelName: 'NAVY POOL',
  modelCode: 'CLLOUBIAIRWAYS021',
  lowPolyModelUrl: `${trolleyBaseUrl}3210934-916 FLAT LAINE BOUCLETTE BRODE/512/FLAT_LAINE_BOUCLETTE_BRODE_512.glb`,
  highPolyModelUrl: `${trolleyBaseUrl}3210934-916 FLAT LAINE BOUCLETTE BRODE/v1/FLAT_LAINE_BOUCLETTE_BRODE.glb`
};

export const melonSpikes: IAnimationProduct = {
  id: '0f83e769-60d6-4e82-8065-9ae3d7b56874',
  modelName: 'MELON SPIKES',
  modelCode: 'CLLOUBIAIRWAYS022',
  lowPolyModelUrl: `${trolleyBaseUrl}3211052-MELON SPIKES FLA/512/Spike_512.glb`,
  highPolyModelUrl: `${trolleyBaseUrl}3211052-MELON SPIKES FLA/3211052-MELON SPIKES FLA.glb`
};

export const navyCoolito: IAnimationProduct = {
  id: '96d5b717-2e99-42f4-b5ed-fd406a4bd19e',
  modelName: 'NAVY COOLITO',
  modelCode: 'CLLOUBIAIRWAYS020',
  lowPolyModelUrl: `${trolleyBaseUrl}3211127-928 FLAT VELV AB DS BRODE/512/Brode_512.glb`,
  highPolyModelUrl: `${trolleyBaseUrl}3211127-928 FLAT VELV AB DS BRODE/3211127-928 FLAT VELV AB DS BRODE.glb`
};

export const blasterCrossbody: IAnimationProduct = {
  id: 'dad27dc6-9354-4ce2-ae4e-67b9e7e9cf25',
  modelName: 'BLASTER CROSSBODY',
  modelCode: 'CLLOUBIAIRWAYS023',
  lowPolyModelUrl: `${trolleyBaseUrl}3215176-BLASTER CROSSBODY LAINE BOUCLETTE/512/3215176_BLASTER_CROSSBODY_LAINE_BOUCLETTE_512.glb`,
  highPolyModelUrl: `${trolleyBaseUrl}3215176-BLASTER CROSSBODY LAINE BOUCLETTE/3215176_BLASTER_CROSSBODY_LAINE_BOUCLETTE.glb`
};

export const trolleyAnimationProducts: IAnimationProduct[] = [
  melonSpikes,
  navyCoolito,
  navyPool,
  rantulow,
  blasterCrossbody,
  louisPStrass
];

export const aquallia: IAnimationProduct = {
  id: '5c0b8e6b-c453-4ba4-9bcb-6a55766ed5d9',
  modelName: 'AQUALLIA',
  modelCode: 'CLLOUBIAIRWAYS051'
};

export const aquajane: IAnimationProduct = {
  id: '67031488-e166-4e8b-895f-f240830910b6',
  modelName: 'AQUAJANE',
  modelCode: 'CLLOUBIAIRWAYS052'
};

export const aquariaBotta: IAnimationProduct = {
  id: 'e48207fc-e302-4697-b5bf-94921cef49eb',
  modelName: 'AQUARIA BOTTA',
  modelCode: 'CLLOUBIAIRWAYS053'
};

export const aquaria: IAnimationProduct = {
  id: '1bbbbabd-5519-47d8-a113-8a515bb9112b',
  modelName: 'AQUARIA',
  modelCode: 'CLLOUBIAIRWAYS050'
};

export const kateSling: IAnimationProduct = {
  id: '25e00768-43f5-4a6e-85ce-f3c96ca727c4',
  modelName: 'KATE SLING',
  modelCode: 'CLLOUBIAIRWAYS121'
};

export const lipstickModel = `${animationAssetsBaseUrl}lipstick/Black_&_Matte_Pack.glb`;
export const getlipstickImage = (i: number) =>
  `${animationAssetsBaseUrl}lipstick/Textures/ColorsBullet/00${i}M.jpg`;

export const beautycartBaseUrl = `${animationAssetsBaseUrl}beautycart/v2/`;
export const lipstickBaseUrl = `${beautycartBaseUrl}Lipsticks/`;

export const beautyCartImg = `${beautycartBaseUrl}cart/v5/img.jpg`;
export const perfumesImg = `${beautycartBaseUrl}Perfumes/Perfume.png`;

export const lipstick2D = [
  `${beautycartBaseUrl}Lipsticks/black.png`,
  `${beautycartBaseUrl}Lipsticks/Gold.png`,
  `${beautycartBaseUrl}Lipsticks/Fluid.png`
];

export const lipstick = [
  `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Black_&_Matte_Pack_NA.glb`,
  `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Black_&_Shiny_Pack.glb`,
  `${beautycartBaseUrl}Lipsticks/Black_&_Snake_Skin_Pack/Black_Snake_Skin_Pack.glb`,
  `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Golden_&_Matte_Pack.glb`,
  `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Golden_&_Silky_Pack.glb`,
  `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Golden_&_Snake_Skin_Pack.glb`,
  `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Matte_Fluid.glb`,
  `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Metal_Matte_Fluid.glb`
];
export const lipstickLow = [
  `${beautycartBaseUrl}Lipsticks/lowres/Black_&_Matte_Pack/Black_&_Matte_Pack_low.glb`,
  `${beautycartBaseUrl}Lipsticks/lowres/Black_&_Shiny_Pack/Black_&_Shiny_Pack_low.glb`,
  `${beautycartBaseUrl}Lipsticks/lowres/Black_&_Snake_Skin_Pack/Black_Snake_Skin_Pack_low.glb`,
  `${beautycartBaseUrl}Lipsticks/lowres/Golden_&_Matte_Pack/Golden_&_Matte_Pack_low.glb`,
  `${beautycartBaseUrl}Lipsticks/lowres/Golden_&_Silky_Pack/Golden_&_Silky_Pack_low.glb`,
  `${beautycartBaseUrl}Lipsticks/lowres/Golden_&_Snake_Skin_Pack/Golden_&_Snake_Skin_Pack_low.glb`,
  `${beautycartBaseUrl}Lipsticks/lowres/Matte_Fluid/Matte_Fluid_low.glb`,
  `${beautycartBaseUrl}Lipsticks/lowres/Metal_Matte_Fluid/Metal_Matte_Fluid_low.glb`
];
export const lipstickTexturesUrl = {
  blackMattePackMap: `${lipstickBaseUrl}Black_&_Matte_Pack/Black_&_Matte_Pack_mat_baseColor.png`,
  blackMattePackNormal: `${lipstickBaseUrl}Black_&_Matte_Pack/Black_&_Matte_Pack_mat_normal.jpg`,
  blackMattePackMetallic: `${lipstickBaseUrl}Black_&_Matte_Pack/Black_&_Matte_Pack_mat_occlusionRoughnessMetallic.jpg`,
  blackShinyPackMap: `${lipstickBaseUrl}Black_&_Shiny_Pack/Black_&_Shiny_Pack_mat_baseColor.png`,
  blackShinyPackNormal: `${lipstickBaseUrl}Black_&_Shiny_Pack/Black_&_Shiny_Pack_mat_normal.jpg`,
  blackShinyPackMetallic: `${lipstickBaseUrl}Black_&_Shiny_Pack/Black_&_Shiny_Pack_mat_occlusionRoughnessMetallic.jpg`,
  blackSnakeSkinPackMap: `${lipstickBaseUrl}Black_&_Snake_Skin_Pack/Black_&_Snake_Skin_Pack_mat_baseColor.png`,
  blackSnakeSkinPackNormal: `${lipstickBaseUrl}Black_&_Snake_Skin_Pack/Black_&_Snake_Skin_Pack_mat_normal.jpg`,
  blackSnakeSkinPackMetallic: `${lipstickBaseUrl}Black_&_Snake_Skin_Pack/Black_&_Snake_Skin_Pack_mat_occlusionRoughnessMetallic.jpg`,
  
  goldenMattePackMap: `${lipstickBaseUrl}Golden_&_Matte_Pack/Golden_&_Matte_Pack_mat_baseColor.png`,
  goldenMattePackNormal: `${lipstickBaseUrl}Golden_&_Matte_Pack/Golden_&_Matte_Pack_mat_normal.jpg`,
  goldenMattePackMetallic: `${lipstickBaseUrl}Golden_&_Matte_Pack/Golden_&_Matte_Pack_mat_occlusionRoughnessMetallic.jpg`,
  
  goldenSilkyPackMap: `${lipstickBaseUrl}Golden_&_Silky_Pack/Black_Shiny_Pack_mat_baseColor.png`,
  goldenSilkyPackNormal: `${lipstickBaseUrl}Golden_&_Silky_Pack/Golden_&_Silky_Pack_mat_normal.jpg`,
  goldenSilkyPackMetallic: `${lipstickBaseUrl}Golden_&_Silky_Pack/Black_Shiny_Pack_mat_occlusionRoughnessMetallic.jpg`,
  
  goldenSnakeSkinPackMap: `${lipstickBaseUrl}Golden_&_Snake_Skin_Pack/Golden_&_Snake_Skin_Pack_mat_baseColor.png`,
  goldenSnakeSkinPackNormal: `${lipstickBaseUrl}Golden_&_Snake_Skin_Pack/Golden_&_Snake_Skin_Pack_mat_normal.jpg`,
  goldenSnakeSkinPackMetallic: `${lipstickBaseUrl}Golden_&_Snake_Skin_Pack/Black_Snake_Skin_Pack_mat_occlusionRoughnessMetallic.jpg`,


  matteFluidMap: `${lipstickBaseUrl}Matte_Fluid/Matte_Fluid_mat_baseColor.png`,
  matteFluidNormal: `${lipstickBaseUrl}Matte_Fluid/Matte_Fluid_mat_normal.jpg`,
  matteFluidMetallic: `${lipstickBaseUrl}Matte_Fluid/Matte_Fluid_mat_occlusionRoughnessMetallic.jpg`,
  metalMatteFluidMap: `${lipstickBaseUrl}Metal_Matte_Fluid/Metal_Matte_Fluid_mat_baseColor.png`,
  metalMatteFluidNormal: `${lipstickBaseUrl}Metal_Matte_Fluid/Metal_Matte_Fluid_mat_normal.jpg`,
  metalMatteFluidMetallic: `${lipstickBaseUrl}Metal_Matte_Fluid/Metal_Matte_Fluid_mat_occlusionRoughnessMetallic.jpg`
};

export const lipstickcolor = {
  dark: `${beautycartBaseUrl}Lipsticks/color/dark.png`,
  darkping: `${beautycartBaseUrl}Lipsticks/color/darkping.png`,
  darkping2: `${beautycartBaseUrl}Lipsticks/color/darkping2.png`,
  pink: `${beautycartBaseUrl}Lipsticks/color/pink.png`,
  red: `${beautycartBaseUrl}Lipsticks/color/red.png`
};

const loubirouge: IAnimationProduct = {
  id: '4b4e012d-c881-4edd-b337-6a6815fa1a6c',
  modelName: 'LOUBIROUGE',
  modelCode: 'CLLOUBIAIRWAYS108',
  lowPolyModelUrl: `${beautycartBaseUrl}Perfumes/img/Loubirouge.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Perfumes/fragrance-Loubirouge/fragrance-Loubirouge.glb`
};
const loubicroc: IAnimationProduct = {
  id: '838cf29b-d1e1-437f-89e6-addbbb3b6c50',
  modelName: 'LOUBICROC',
  modelCode: 'CLLOUBIAIRWAYS109',
  lowPolyModelUrl: `${beautycartBaseUrl}Perfumes/img/Loubicroc.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Perfumes/fragrance-Loubicroc/fragrance-Loubicroc.glb`
};

const loubiraj: IAnimationProduct = {
  id: '79dfdbd9-375f-4dbe-bf35-9686b511a2a6',
  modelName: 'LOUBIRAJ',
  modelCode: 'CLLOUBIAIRWAYS110',
  lowPolyModelUrl: `${beautycartBaseUrl}Perfumes/img/Loubiraj.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Perfumes/fragrance-Loubiraj/fragrance-Loubiraj.glb`
};
const loubicrown: IAnimationProduct = {
  id: '0327f642-0e05-4a5d-b475-db504d359f0d',
  modelName: 'LOUBICROWN',
  modelCode: 'CLLOUBIAIRWAYS111',
  lowPolyModelUrl: `${beautycartBaseUrl}Perfumes/img/Loubicrown.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Perfumes/fragrance-Loubicrown/fragrance-Loubicrown.glb`
};
const loubikiss: IAnimationProduct = {
  id: 'e2353217-6533-41a4-b9f8-16cfa0385214',
  modelName: 'LOUBIKISS',
  modelCode: 'CLLOUBIAIRWAYS112',
  lowPolyModelUrl: `${beautycartBaseUrl}Perfumes/img/Loubikiss.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Perfumes/fragrance-Loubikiss/fragrance-Loubikiss.glb`
};
const loubidoo: IAnimationProduct = {
  id: 'f632df55-bc5e-41c9-9475-73958989b58a',
  modelName: 'LOUBIDOO',
  modelCode: 'CLLOUBIAIRWAYS113',
  lowPolyModelUrl: `${beautycartBaseUrl}Perfumes/img/Loubidoo.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Perfumes/fragrance-Loubidoo/fragrance-Loubidoo.glb`
};

const loubifunk: IAnimationProduct = {
  id: '37c0b4e3-4f1a-440b-a2b7-938316e4dd65',
  modelName: 'LOUBIFUNK',
  modelCode: 'CLLOUBIAIRWAYS114',
  lowPolyModelUrl: `${beautycartBaseUrl}Perfumes/img/Loubifunk.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Perfumes/fragrance-Loubifunk/fragrance-Loubifunk.glb`
};
export const fragranceProducts: IAnimationProduct[] = [
  loubifunk,
  loubidoo,
  loubikiss,
  loubirouge,
  loubiraj,
  loubicrown,
  loubicroc
];
const velvetMatteRougeLouboutin: IAnimationProduct = {
  id: '5637c82b-bbf3-4d26-870c-851ab4408760',
  modelName: 'VELVET MATTE ROUGE LOUBOUTIN',
  modelCode: 'CLLOUBIAIRWAYS054',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/Colors_Bullet/001M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/chooser/001M.png`
};
const velvetMatteDiva: IAnimationProduct = {
  id: '08fb9ec7-e170-4a72-a572-6acddf49fa88',
  modelName: 'VELVET MATTE DIVA',
  modelCode: 'CLLOUBIAIRWAYS055',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/Colors_Bullet/007M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/chooser/007M.png`
};
const velvetMatteVeryPrive: IAnimationProduct = {
  id: '9d1c7623-0106-4712-903b-d5c5b68208c8',
  modelName: 'VELVET MATTE VERY PRIVE',
  modelCode: 'CLLOUBIAIRWAYS056',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/Colors_Bullet/012M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/chooser/012M.png`
};
const velvetMatteTriluna: IAnimationProduct = {
  id: 'b2c02c3b-0b4f-45e1-bf8c-1ae2a2b66c9a',
  modelName: 'VELVET MATTE TRILUNA',
  modelCode: 'CLLOUBIAIRWAYS063',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/Colors_Bullet/006M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/chooser/006M.png`
};
const velvetMatteDramadouce: IAnimationProduct = {
  id: '5e59bff5-a266-4a60-acee-a5f755416456',
  modelName: 'VELVET MATTE DRAMADOUCE',
  modelCode: 'CLLOUBIAIRWAYS064',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/Colors_Bullet/005M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/chooser/005M.png`
};
const velvetMatteJustine: IAnimationProduct = {
  id: 'dc8d14ed-dd48-425a-8c02-6f165eadcdc4',
  modelName: 'VELVET MATTE JUSTINE',
  modelCode: 'CLLOUBIAIRWAYS065',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/Colors_Bullet/003M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/chooser/003M.png`
};
const velvetMatteGoyetta: IAnimationProduct = {
  id: '526ee41d-1fa0-4662-820a-6b207722f69d',
  modelName: 'VELVET MATTE GOYETTA',
  modelCode: 'CLLOUBIAIRWAYS066',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/Colors_Bullet/004M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/chooser/004M.png`
};
const velvetMatteJackie: IAnimationProduct = {
  id: '647ba3f7-3aac-4831-a38d-3aedd9d69688',
  modelName: 'VELVET MATTE JACKIE',
  modelCode: 'CLLOUBIAIRWAYS067',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/Colors_Bullet/002M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Matte_Pack/Textures/chooser/002M.png`
};

const silkySatinRougeLouboutin: IAnimationProduct = {
  id: '9491977a-c1b7-415b-8fa0-ded4ee26d828',
  modelName: 'SILKY SATIN ROUGE LOUBOUTIN',
  modelCode: 'CLLOUBIAIRWAYS069',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/Colors_Bullet/001M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/chooser/001M.png`
};
const silkySatinVeryPrive: IAnimationProduct = {
  id: '550da4a1-bdb8-43d4-9b30-f8afe3edb2c1',
  modelName: 'SILKY SATIN VERY PRIVE',
  modelCode: 'CLLOUBIAIRWAYS070',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/Colors_Bullet/002.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/chooser/002.png`
};
const silkySatinTorrera: IAnimationProduct = {
  id: '1cad20ac-3a8b-4c76-b95a-a432c325133e',
  modelName: 'SILKY SATIN TORRERA',
  modelCode: 'CLLOUBIAIRWAYS071',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/Colors_Bullet/003.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/chooser/003.png`
};
const silkySatinYoupiyou: IAnimationProduct = {
  id: '8d071844-08ea-455d-9690-c42176e30c6e',
  modelName: 'SILKY SATIN YOUPIYOU',
  modelCode: 'CLLOUBIAIRWAYS072',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/Colors_Bullet/005.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/chooser/005.png`
};
const silkySatinTheophila: IAnimationProduct = {
  id: 'c9c0b3dd-65ed-420f-8172-115c4f015620',
  modelName: 'SILKY SATIN THEOPHILA',
  modelCode: 'CLLOUBIAIRWAYS080',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/Colors_Bullet/007.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/chooser/007.png`
};
const silkySatinLusita: IAnimationProduct = {
  id: '5568951d-a6da-4708-832e-1f22ebd1b6ed',
  modelName: 'SILKY SATIN LUSITA',
  modelCode: 'CLLOUBIAIRWAYS081',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/Colors_Bullet/004.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/chooser/004.png`
};
const silkySatinCatchyOne: IAnimationProduct = {
  id: 'c7a29bdd-73d0-4f59-9221-62da82581918',
  modelName: 'SILKY SATIN CATCHY ONE',
  modelCode: 'CLLOUBIAIRWAYS082',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/Colors_Bullet/006.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Shiny_Pack/Textures/chooser/006.png`
};

const sheerVoileRougeLouboutin: IAnimationProduct = {
  id: '721b928a-e72f-4625-b9e5-da332bb662b2',
  modelName: 'SHEER VOILE ROUGE LOUBOUTIN',
  modelCode: 'CLLOUBIAIRWAYS084',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Snake_Skin_Pack/Textures/Black_&_Snake_Skin_Pack_mat_baseColor.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Black_&_Snake_Skin_Pack/Textures/blacksnake.png`
};

const velvetMatteRococotte: IAnimationProduct = {
  id: 'a8aa42d6-e602-43bd-8d3d-7a5359022091',
  modelName: 'VELVET MATTE ROCOCOTTE',
  modelCode: 'CLLOUBIAIRWAYS057',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/Colors_Bullet/013M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/chooser/013M.png`
};
const velvetMatteJustNothing: IAnimationProduct = {
  id: 'd4416ab3-6428-41e7-a8ce-78bfdb712aef',
  modelName: 'VELVET MATTE JUST NOTHING',
  modelCode: 'CLLOUBIAIRWAYS058',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/Colors_Bullet/014M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/chooser/014M.png`
};
const velvetMatteMissLoubi: IAnimationProduct = {
  id: 'ff4741a1-8b21-4e2c-a463-1f74a34ae1a7',
  modelName: 'VELVET MATTE MISS LOUBI',
  modelCode: 'CLLOUBIAIRWAYS059',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/Colors_Bullet/009M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/chooser/009M.png`
};
const velvetMatteAltressa: IAnimationProduct = {
  id: '3e53049f-4f8e-419b-8343-35d727198335',
  modelName: 'VELVET MATTE ALTRESSA',
  modelCode: 'CLLOUBIAIRWAYS060',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/Colors_Bullet/008M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/chooser/008M.png`
};
const velvetMatteBengali: IAnimationProduct = {
  id: 'f21f2c6d-b7fd-4733-86f4-fc32740d4261',
  modelName: 'VELVET MATTE BENGALI',
  modelCode: 'CLLOUBIAIRWAYS061',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/Colors_Bullet/010M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/chooser/010M.png`
};
const velvetMatteSoTango: IAnimationProduct = {
  id: 'db2eea92-53f6-47aa-88cf-0933ed31d5b1',
  modelName: 'VELVET MATTE SO TANGO',
  modelCode: 'CLLOUBIAIRWAYS062',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/Colors_Bullet/011M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/chooser/011M.png`
};
const velvetMatteVeryGil: IAnimationProduct = {
  id: '52911724-abc1-475b-ae83-8293a0ffae34',
  modelName: 'VELVET MATTE VERY GIL',
  modelCode: 'CLLOUBIAIRWAYS069',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/Colors_Bullet/015M.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Matte_Pack/Textures/chooser/015M.png`
};

const silkySatinBengali: IAnimationProduct = {
  id: '486643df-3784-4066-a855-ba80647f92de',
  modelName: 'SILKY SATIN BENGALI',
  modelCode: 'CLLOUBIAIRWAYS073',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/Colors_Bullet/009.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/chooser/009.png`
};
const silkySatinBikini: IAnimationProduct = {
  id: '9d4d2953-d015-46b8-a4ef-8bbe8724a83c',
  modelName: 'SILKY SATIN BIKINI',
  modelCode: 'CLLOUBIAIRWAYS074',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/Colors_Bullet/010.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/chooser/010.png`
};
const silkySatinBellyBloom: IAnimationProduct = {
  id: '04ff298b-9e8d-47bc-986c-d5103a505e46',
  modelName: 'SILKY SATIN BELLY BLOOM',
  modelCode: 'CLLOUBIAIRWAYS075',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/Colors_Bullet/011.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/chooser/011.png`
};
const silkySatinLetMeTellYou: IAnimationProduct = {
  id: 'fdebda3c-46ff-441c-b8d5-cf52169c289f',
  modelName: 'SILKY SATIN LET ME TELL YOU',
  modelCode: 'CLLOUBIAIRWAYS076',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/Colors_Bullet/012.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/chooser/012.png`
};
const silkySatinImpera: IAnimationProduct = {
  id: 'a77cb3c4-b8f1-485e-9ef3-0186de998f8c',
  modelName: 'SILKY SATIN IMPERA',
  modelCode: 'CLLOUBIAIRWAYS077',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/Colors_Bullet/013.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/chooser/013.png`
};
const silkySatinMissLoubi: IAnimationProduct = {
  id: '0ea50d6e-f94c-4d25-8b3d-bdc14b611b32',
  modelName: 'SILKY SATIN MISS LOUBI',
  modelCode: 'CLLOUBIAIRWAYS078',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/Colors_Bullet/008.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/chooser/008.png`
};
const silkySatinMeNude: IAnimationProduct = {
  id: '819c3c09-2f59-4da0-9419-6be8a7e2ceb6',
  modelName: 'SILKY SATIN ME NUDE',
  modelCode: 'CLLOUBIAIRWAYS079',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/Colors_Bullet/014.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/chooser/014.png`
};
const silkySatinVeryGil: IAnimationProduct = {
  id: '016ef74c-24f5-4c40-ba91-fd5b1a2ba0fb',
  modelName: 'SILKY SATIN VERY GIL',
  modelCode: 'CLLOUBIAIRWAYS083',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/Colors_Bullet/015.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Silky_Pack/Textures/chooser/015.png`
};

const sheerVoileLoubiMinette: IAnimationProduct = {
  id: 'daa8d9e4-9089-4fc2-9aa5-24b3a06f8831',
  modelName: 'SHEER VOILE LOUBI MINETTE',
  modelCode: 'CLLOUBIAIRWAYS085',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/Colors_Bullet/105S.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/chooser/105S.png`
};
const sheerVoileTresBea: IAnimationProduct = {
  id: '882c7264-6d0a-43b1-a4be-57006f29f587',
  modelName: 'SHEER VOILE TRES BEA',
  modelCode: 'CLLOUBIAIRWAYS086',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/Colors_Bullet/115S.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/chooser/115S.png`
};
const sheerVoileRoseDuDesert: IAnimationProduct = {
  id: 'fe3676f5-184a-4b46-a76b-b01abb535f7b',
  modelName: 'SHEER VOILE ROSE DU DESERT',
  modelCode: 'CLLOUBIAIRWAYS087',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/Colors_Bullet/217S.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/chooser/217S.png`
};
const sheerVoilePetalRose: IAnimationProduct = {
  id: 'd6c14180-5dc8-409c-82e2-f7b52a3feb68',
  modelName: 'SHEER VOILE PETAL ROSE',
  modelCode: 'CLLOUBIAIRWAYS088',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/Colors_Bullet/225S.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/chooser/225S.png`
};
const sheerVoileYouYou: IAnimationProduct = {
  id: 'c6bf2a4c-ef13-468a-92c5-c39acb0f58c9',
  modelName: 'SHEER VOILE YOU YOU',
  modelCode: 'CLLOUBIAIRWAYS089',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/Colors_Bullet/415S.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/chooser/415S.png`
};
const sheerVoileMexicatchy: IAnimationProduct = {
  id: 'e368508c-6922-40d8-ae89-ee45a1ba1d1a',
  modelName: 'SHEER VOILE MEXICATCHY',
  modelCode: 'CLLOUBIAIRWAYS090',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/Colors_Bullet/503S.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/chooser/503S.png`
};
const sheerVoileEscatin: IAnimationProduct = {
  id: '7c7d32f8-9303-4b69-b4f7-1803404b8bbe',
  modelName: 'SHEER VOILE ESCATIN',
  modelCode: 'CLLOUBIAIRWAYS091',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/Colors_Bullet/605S.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/chooser/605S.png`
};
const sheerVoilePrivateNumber: IAnimationProduct = {
  id: '69727a4c-0914-4760-a919-5567786a54bb',
  modelName: 'SHEER VOILE PRIVATE NUMBER',
  modelCode: 'CLLOUBIAIRWAYS092',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/Colors_Bullet/717S.jpg`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Golden_&_Snake_Skin_Pack/Textures/chooser/717S.png`
};

const matteFluidsRougeLouboutin: IAnimationProduct = {
  id: '450ceb03-304f-45b6-b699-83579c8cde69',
  modelName: 'ROUGE LOUBOUTIN',
  modelCode: 'CLLOUBIAIRWAYS093',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/001F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/001F.png`
};
const matteFluidsSuziFolk: IAnimationProduct = {
  id: '30f6fdb4-88d8-4522-95fa-39c76e0656a9',
  modelName: 'SUZI FOLK',
  modelCode: 'CLLOUBIAIRWAYS094',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/005F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/005F.png`
};
const matteFluidsPatibaba: IAnimationProduct = {
  id: 'a4c63450-c1e4-46c3-80e5-875633fef8f2',
  modelName: 'PATIBABA',
  modelCode: 'CLLOUBIAIRWAYS095',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/009F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/009F.png`
};
const matteFluidsCorsetDamour: IAnimationProduct = {
  id: '075cd8da-6ddf-4f8b-962e-91c47ef6b4ba',
  modelName: "CORSET D'AMOUR",
  modelCode: 'CLLOUBIAIRWAYS096',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/007F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/007F.png`
};
const matteFluids: IAnimationProduct = {
  id: 'a16ec5fa-07b2-408b-a1e5-55d167750766',
  modelName: 'MATTE FLUIDS',
  modelCode: 'CLLOUBIAIRWAYS097',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/008F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/008F.png`
};
const matteFluidsAimanta: IAnimationProduct = {
  id: '910fb18f-f22f-4140-89b5-3959186e8b8a',
  modelName: 'AIMANTA',
  modelCode: 'CLLOUBIAIRWAYS098',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/002F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/002F.png`
};
const matteFluidsMaGil: IAnimationProduct = {
  id: 'babd2c16-17f8-4b65-a2e6-4f9f2ddef38b',
  modelName: 'MA GIL',
  modelCode: 'CLLOUBIAIRWAYS099',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/010F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/010F.png`
};
const matteFluidsAlmerica: IAnimationProduct = {
  id: '58b56585-74cb-421e-8421-4499e6d87ea4',
  modelName: 'ALMERICA',
  modelCode: 'CLLOUBIAIRWAYS100',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/006F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/006F.png`
};
const matteFluidsJamaisAssez: IAnimationProduct = {
  id: 'cba2d500-c0ec-4972-88f2-76262419a411',
  modelName: 'JAMAIS ASSEZ',
  modelCode: 'CLLOUBIAIRWAYS101',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/Colors_Bullet/004F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Matte_Fluid/Textures/chooser/004F.png`
};

const matteFluidsEtVoiciEtVoila: IAnimationProduct = {
  id: 'e5d9c908-d3db-4465-b3d5-e4656e53bf5f',
  modelName: 'ET VOICI ET VOILA',
  modelCode: 'CLLOUBIAIRWAYS102',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/Colors_Bullet/003F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/chooser/003F.png`
};
const matteFluidsRougeLouboutinMetallic: IAnimationProduct = {
  id: 'a313dbe7-97cf-4f6b-a5eb-d6b3cc1b99e6',
  modelName: 'ROUGE LOUBOUTIN METALLIC',
  modelCode: 'CLLOUBIAIRWAYS103',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/Colors_Bullet/101F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/chooser/101F.png`
};
const matteFluidsPatibabaMetallic: IAnimationProduct = {
  id: '5e0fbf36-638b-4b18-a5c5-4bc44e9f9309',
  modelName: 'PATIBABA METALLIC',
  modelCode: 'CLLOUBIAIRWAYS104',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/Colors_Bullet/109F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/chooser/109F.png`
};
const matteFluidsMultiMissMetallic: IAnimationProduct = {
  id: '3c22312e-c0c8-4a0b-9766-897aa26bf241',
  modelName: 'MULTI MISS METALLIC',
  modelCode: 'CLLOUBIAIRWAYS105',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/Colors_Bullet/108F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/chooser/108F.png`
};
const matteFluidsJamaisAssezMetallic: IAnimationProduct = {
  id: '73ae6a8b-210c-4f5e-85ff-dcfdc85c8e3f',
  modelName: 'JAMAIS ASSEZ METALLIC',
  modelCode: 'CLLOUBIAIRWAYS106',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/Colors_Bullet/104F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/chooser/104F.png`
};
const matteFluidsEtVoiciEtVoilaMetallic: IAnimationProduct = {
  id: 'af8ddee2-1a5d-4600-bc2e-ea7bffff3542',
  modelName: 'ET VOICI ET VOILA METALLIC',
  modelCode: 'CLLOUBIAIRWAYS107',
  lowPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/Colors_Bullet/103F.png`,
  highPolyModelUrl: `${beautycartBaseUrl}Lipsticks/Metal_Matte_Fluid/Textures/chooser/103F.png`
};

export const matte_Black: IAnimationProduct[] = [
  velvetMatteRougeLouboutin,
  velvetMatteDiva,
  velvetMatteVeryPrive,
  velvetMatteTriluna,
  velvetMatteDramadouce,
  velvetMatteJustine,
  velvetMatteGoyetta,
  velvetMatteJackie
];
export const silky_Black: IAnimationProduct[] = [
  silkySatinRougeLouboutin,
  silkySatinVeryPrive,
  silkySatinTorrera,
  silkySatinYoupiyou,
  silkySatinTheophila,
  silkySatinLusita,
  silkySatinCatchyOne
];
export const snake_Black: IAnimationProduct[] = [sheerVoileRougeLouboutin];

export const matte_Gold: IAnimationProduct[] = [
  velvetMatteRococotte,
  velvetMatteJustNothing,
  velvetMatteMissLoubi,
  velvetMatteAltressa,
  velvetMatteBengali,
  velvetMatteSoTango,
  velvetMatteVeryGil
];
export const Silky_Gold: IAnimationProduct[] = [
  silkySatinBengali,
  silkySatinBikini,
  silkySatinBellyBloom,
  silkySatinLetMeTellYou,
  silkySatinImpera,
  silkySatinMissLoubi,
  silkySatinMeNude,
  silkySatinVeryGil
];
export const snake_Gold: IAnimationProduct[] = [
  sheerVoileLoubiMinette,
  sheerVoileTresBea,
  sheerVoileRoseDuDesert,
  sheerVoilePetalRose,
  sheerVoileYouYou,
  sheerVoileMexicatchy,
  sheerVoileEscatin,
  sheerVoilePrivateNumber
];
export const fluid: IAnimationProduct[] = [
  matteFluidsRougeLouboutin,
  matteFluidsSuziFolk,
  matteFluidsPatibaba,
  matteFluidsCorsetDamour,
  matteFluids,
  matteFluidsAimanta,
  matteFluidsMaGil,
  matteFluidsAlmerica,
  matteFluidsJamaisAssez
];
export const metallic_fluid: IAnimationProduct[] = [
  matteFluidsEtVoiciEtVoila,
  matteFluidsRougeLouboutinMetallic,
  matteFluidsPatibabaMetallic,
  matteFluidsMultiMissMetallic,
  matteFluidsJamaisAssezMetallic,
  matteFluidsEtVoiciEtVoilaMetallic
];

export const lipstickColors = [
  matte_Black,
  silky_Black,
  snake_Black,
  matte_Gold,
  Silky_Gold,
  snake_Gold,
  fluid,
  metallic_fluid
];

export const all3DProducts: IAnimationProduct[] = [
  ...airplaneWindowProducts,
  ...tarotProducts,
  ...luggageAnimationProducts,
  ...trolleyAnimationProducts,
  ...fragranceProducts,
  ...matte_Black,
  ...silky_Black,
  ...snake_Black,
  ...matte_Gold,
  ...Silky_Gold,
  ...snake_Gold,
  ...fluid,
  ...metallic_fluid
];
