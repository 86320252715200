import { INudgePayload } from '../../interfaces';
import {
  IAnimationProduct,
  ILoubiAirwaysCatalogState,
  ILoubiAirwaysWishlist,
  IPlanePlan,
  ISelectedModel,
  LoubiAirwaysAnimation,
  LoubiAirwaysExitState,
  LoubiAirwaysFullScreenVideo,
  LoubiAirwaysPopupContentType,
  PlanSpotLight,
  ProductGender,
  ProductType
} from '../../interfaces/loubiairways';

export const UPDATE_LOUBI_AIRWAYS_ANIMATION =
  'LOUBIAIRWAYS.UPDATE_LOUBI_AIRWAYS_ANIMATION';
export const UPDATE_LOUBI_AIRWAYS_SELECTED_MODEL =
  'LOUBIAIRWAYS.UPDATE_LOUBI_AIRWAYS_SELECTED_MODEL';
export const DID_RECEIVE_REMOTE_LOUBI_AIRWAYS_ANIMATION_STATE =
  'LOUBIAIRWAYS.DID_RECEIVE_REMOTE_LOUBI_AIRWAYS_ANIMATION_STATE';
export const UPDATE_ACTIVE_ANIMATION_IN_PANO =
  'LOUBIAIRWAYS.UPDATE_ACTIVE_ANIMATION_IN_PANO';
export const UPDATE_FULL_SCREEN_VIDEO = 'LOUBIAIRWAYS.UPDATE_FULL_SCREEN_VIDEO';
export const UPDATE_LANDING_VIDEO_STATE =
  'LOUBIAIRWAYS.UPDATE_LANDING_VIDEO_STATE';
export const ADD_TO_WISHLIST = 'LOUBIAIRWAYS.ADD_TO_WISHLIST';
export const UPDATE_WISHLIST_ITEM_SIZE =
  'LOUBIAIRWAYS.UPDATE_WISHLIST_ITEM_SIZE';
export const REMOVE_FROM_WISHLIST = 'LOUBIAIRWAYS.REMOVE_FROM_WISHLIST';
export const OPEN_WISHLIST_POPUP = 'LOUBIAIRWAYS.OPEN_WISHLIST_POPUP';
export const UPDATE_WISHLIST = 'LOUBIAIRWAYS.UPDATE_WISHLIST';
export const TOGGLE_SHOW_SPOTIFY_PLAYER =
  'LOUBIAIRWAYS.TOGGLE_SHOW_SPOTIFY_PLAYER';
export const PLAY_INTRODUCTION_VIDEO_FOR_MEETING =
  'LOUBIAIRWAYS.PLAY_INTRODUCTION_VIDEO_FOR_MEETING';

export const UPDATE_LOUBI_AIRWAYS_PLANE_PLAN =
  'LOUBIAIRWAYS.UPDATE_LOUBI_AIRWAYS_PLANE_PLAN';
export const UPDATE_VISITED_PLAN_SPOT = 'LOUBIAIRWAYS.UPDATE_VISITED_PLAN_SPOT';
export const UPDATE_LOUBI_AIRWAYS_POPUP_CONTENT_TYPE =
  'LOUBIAIRWAYS.UPDATE_POPUP_CONTENT_TYPE';
export const UPDATE_LOUBI_AIRWAYS_EXIT_STATE = 'LOUBIAIRWAYS.UPDATE_EXIT_STATE';
export const UPDATE_ALREADY_PASSED_LAUNCH_TIME =
  'LOUBIAIRWAYS.UPDATE_ALREADY_PASSED_LAUNCH_TIME';
export const UPDATE_INVITE = 'LOUBIAIRWAYS.UPDATE_INVITE';
export const TOGGLE_CHAT_PANEL = 'LOUBIAIRWAYS.TOGGLE_CHAT_PANEL';
export const UPDATE_CATALOG = 'LOUBIAIRWAYS.UPDATE_CATALOG';
export const CLOSE_CATALOG = 'LOUBIAIRWAYS.CLOSE_CATALOG';
export const START_FETCHING_CATALOG = 'LOUBIAIRWAYS.START_FETCHING_CATALOG';

export const actionDidUpdateLoubiAirwaysAnimation = (
  animation: LoubiAirwaysAnimation,
  state: any
) => ({
  type: UPDATE_LOUBI_AIRWAYS_ANIMATION,
  payload: { animation, state }
});

export const actionDidReceiveRemoteLoubiAirwaysAnimationState = (
  animation: LoubiAirwaysAnimation,
  state: any
) => ({
  type: DID_RECEIVE_REMOTE_LOUBI_AIRWAYS_ANIMATION_STATE,
  payload: { animation, state }
});

export const actionUpdateActiveAnimationInPano = (
  animation: LoubiAirwaysAnimation
) => ({
  type: UPDATE_ACTIVE_ANIMATION_IN_PANO,
  payload: { animation }
});

export const actionUpdateLoubiAirwaysFullScreenVideo = (
  video: LoubiAirwaysFullScreenVideo
) => ({
  type: UPDATE_FULL_SCREEN_VIDEO,
  payload: { video }
});

export const actionUpdateLandingVideoState = (payload: {
  hidden?: boolean;
  finishedPlaying?: boolean;
}) => ({
  type: UPDATE_LANDING_VIDEO_STATE,
  payload
});

export const actionAddToWishlist = (payload: IAnimationProduct) => ({
  type: ADD_TO_WISHLIST,
  payload
});

export const actionUpdateWishlistItemSize = (id: string, size: string) => ({
  type: UPDATE_WISHLIST_ITEM_SIZE,
  payload: { id, size }
});

export const actionRemoveFromWishlist = (payload: IAnimationProduct) => ({
  type: REMOVE_FROM_WISHLIST,
  payload
});

export const actionOpenLoubiAirwaysWishlistPopup = (payload: boolean) => ({
  type: OPEN_WISHLIST_POPUP,
  payload
});

export const actionUpdateLoubiAirwaysWishlist = (
  payload: ILoubiAirwaysWishlist
) => ({
  type: UPDATE_WISHLIST,
  payload
});

export const actionToggleShowSpotifyPlayer = (payload: boolean) => ({
  type: TOGGLE_SHOW_SPOTIFY_PLAYER,
  payload
});

export const actionUpdateLoubiAirwaysSelectedModel = (
  selectedModel: ISelectedModel
) => ({
  type: UPDATE_LOUBI_AIRWAYS_SELECTED_MODEL,
  payload: { selectedModel }
});

export const actionUpdateLoubiAirwaysPlanePlan = (planePlan: IPlanePlan) => ({
  type: UPDATE_LOUBI_AIRWAYS_PLANE_PLAN,
  payload: { planePlan }
});

export const actionUpdateVisitedPlanePlanSpot = (spot: PlanSpotLight) => ({
  type: UPDATE_VISITED_PLAN_SPOT,
  payload: { spot }
});

export const actionPlayIntroductionVideo = () => ({
  type: PLAY_INTRODUCTION_VIDEO_FOR_MEETING,
  payload: null
});

export const actionUpdateLoubiAirwaysPopupContentType = (
  contentType: LoubiAirwaysPopupContentType
) => ({
  type: UPDATE_LOUBI_AIRWAYS_POPUP_CONTENT_TYPE,
  payload: contentType
});

export const actionUpdateLoubiAirwaysExitState = (
  exitState: LoubiAirwaysExitState
) => ({
  type: UPDATE_LOUBI_AIRWAYS_EXIT_STATE,
  payload: exitState
});

export const actionUpdateLoubiAirwaysAlreadyPassedLaunchTime = () => ({
  type: UPDATE_ALREADY_PASSED_LAUNCH_TIME,
  payload: null
});

export const actionUpdateLoubiAirwaysInvite = (payload: INudgePayload) => ({
  type: UPDATE_INVITE,
  payload
});

export const actionToggleChatPanel = (payload: boolean) => ({
  type: TOGGLE_CHAT_PANEL,
  payload
});

export const actionUpdateCatalog = (payload: ILoubiAirwaysCatalogState) => ({
  type: UPDATE_CATALOG,
  payload
});

export const actionStartFetchingCatalog = (payload: {
  type?: ProductType;
  gender?: ProductGender;
  index?: number;
}) => ({
  type: START_FETCHING_CATALOG,
  payload
});

export const actionCloseCatalog = () => ({
  type: CLOSE_CATALOG,
  payload: null
});
