import {
  allBoutiqueUrl,
  allBrands,
  chopardHappySportGenevaHQ,
  chopardHappySportKorea
} from './../config/index';
import { getMinutes, isWeekend, parse } from 'date-fns';
import { first, last } from 'lodash';
import {
  chopardHighJewelleryId,
  getJitsiRegionByString,
  isLoubiAirways,
  isProd,
  iwcBrazilSiteId,
  iwcSihh2020BoutiqueId,
  iwcWW2021Id,
  JitsiServerRegion,
  loubiAirwaysJpBoutiqueId,
  loubiFutureSiteId,
  sgPublicHolidays2022
} from '../config';
import {
  IBrandSetup,
  IViewRange,
  IVirtualBoutiqueConfig,
  MeetingLayoutMode,
  ProductFamily,
  ProductSearchFilter,
  VirtualBoutiqueMode
} from '../interfaces';
import { getFormattedDate, getNow, getWeekday } from './clock';
import { hjProductSearchFilters } from './productSearchFilters';
import { setIsOnboardingBrand } from './window';

const getStartEndTime = (
  config: IVirtualBoutiqueConfig
): (Date | undefined)[] => {
  const now = getNow();
  let openingHours = config.openingHours;
  let startTime, endTime;
  if (config.openingHoursByWeekday) {
    const weekday = getWeekday(now);
    if (!config.openingHoursByWeekday[weekday]) {
      return [undefined, undefined];
    }
    [startTime, endTime] = config.openingHoursByWeekday[weekday]
      .split('-')
      .map((s) => s.trim());
  } else {
    if (isWeekend(now)) {
      openingHours = config.weekendOpeningHours || config.openingHours;
    } else if (sgPublicHolidays2022.includes(getFormattedDate(now))) {
      openingHours = config.publicHolidayOpeningHours || config.openingHours;
    }
    startTime = first(openingHours)?.split('-')[0]?.trim();
    endTime = last(openingHours)?.split('-')[1]?.trim();
  }
  if (!startTime || !endTime) return [undefined, undefined];
  const parsedStartTime = parse(startTime, 'h:mmaa', new Date());
  const parsedEndTime = parse(endTime, 'h:mmaa', new Date());
  return [parsedStartTime, parsedEndTime];
};

export const isChatAvailable = (
  config: IVirtualBoutiqueConfig | undefined | null
): boolean => {
  if (!config) return false;
  const now = getNow();
  const offset = config.timezoneGMTOffset || 8;
  const [parsedStartTime, parsedEndTime] = getStartEndTime(config);
  if (!parsedStartTime || !parsedEndTime) return false;
  const currentHour = now.getUTCHours() + offset;
  const currentMinutes = getMinutes(now);
  const startHours = parsedStartTime.getHours();
  const startMinutes = getMinutes(parsedStartTime);
  const endHours = parsedEndTime.getHours();
  const endMinutes = getMinutes(parsedEndTime);
  if (currentHour === startHours) return currentMinutes >= startMinutes;
  if (currentHour === endHours) return currentMinutes <= endMinutes;
  return currentHour > startHours && currentHour < endHours;
};

export const getVirtualBoutiqueMode = (mode: string): VirtualBoutiqueMode => {
  switch (mode) {
    case 'presentation':
      return VirtualBoutiqueMode.PRESENTATION;
    case 'cockpit':
      return VirtualBoutiqueMode.COCKPIT;
    default:
      return VirtualBoutiqueMode.NORMAL;
  }
};

export const isInWalkThrough = (mode: VirtualBoutiqueMode) =>
  mode === VirtualBoutiqueMode.PRESENTATION ||
  mode === VirtualBoutiqueMode.COCKPIT;

export const isInChina = (storeId: string) => storeId === iwcSihh2020BoutiqueId;

export const viewInRange = (viewH: number, rangeList: IViewRange) => {
  const mod = viewH % 360;
  const normalizedViewH = mod < 0 ? mod + 360 : mod;
  return Object.keys(rangeList || {}).find(
    (key) =>
      rangeList[key].start < normalizedViewH &&
      rangeList[key].end > normalizedViewH
  );
};

export const sceneAndViewInRange = (
  viewH: number,
  rangeList: IViewRange,
  sceneId: string
) => {
  if (!Object.keys(rangeList || {}).includes(sceneId)) return;
  const range = {
    [sceneId]: { ...rangeList[sceneId] }
  };
  return viewInRange(viewH, range);
};

export const getGaTrackingIdByStore = (storeId?: string) => {
  if (isLoubiAirways(storeId) || storeId === loubiFutureSiteId) {
    return 'UA-7246511-13';
  }
  return;
};

const getJitsiRegionByLoubiAirwaysRegion = (
  loubiAirwaysRegion: string
): JitsiServerRegion | undefined => {
  if (isProd) {
    switch (loubiAirwaysRegion?.toLowerCase()) {
      case 'china':
        return 'cn';
      case 'apac':
      case 'japan':
      case 'middle_east':
      case 'india':
        return 'ap';
      case 'hq':
      case 'uk':
        return 'eu';
      case 'americas':
        return 'us';
      default:
        return 'eu';
    }
  }
  return 'eu';
};

export const getJitsiRegionByRegionAndStore = (
  region: string,
  storeId: string
) => {
  if (isLoubiAirways(storeId) && storeId !== loubiAirwaysJpBoutiqueId) {
    return getJitsiRegionByLoubiAirwaysRegion(region);
  }
  return getJitsiRegionByString(region);
};

export const isIWCWW2021 = (storeId: string) => {
  return storeId === iwcWW2021Id;
};

export const getDefaultMeetingLayout = (_: string) => {
  return MeetingLayoutMode.TILE;
};

export const getAdditionalStoreProductSearchFilters = (
  storeId: string
): ProductSearchFilter[] => {
  if (storeId === chopardHighJewelleryId) {
    return hjProductSearchFilters;
  }
  return [];
};

export const getFilteredProductFamilies = (
  productFamilies: ProductFamily[],
  storeId: string
) => {
  if (storeId === iwcBrazilSiteId) {
    const familiesToRemove = ['DA VINCI', 'INGENIEUR', 'JUBILEE'];
    return productFamilies.filter(
      (f) => !familiesToRemove.includes(f.family?.toUpperCase())
    );
  }
  if (
    storeId === chopardHappySportKorea ||
    storeId === chopardHappySportGenevaHQ
  ) {
    const familiesToShow = [
      'HAPPY SPORT',
      'HAPPY DIAMONDS JEWELLERY',
      'HAPPY SPIRIT',
      'HAPPY HEARTS'
    ];
    return productFamilies.filter((f) =>
      familiesToShow.includes(f.family?.toUpperCase())
    );
  }
  return productFamilies;
};

export const injectBrand = (brandSetup?: IBrandSetup) => {
  if (brandSetup) {
    if (!allBrands.find((b) => b.id === brandSetup.id)) {
      allBrands.push({
        id: brandSetup.id,
        name: brandSetup.brandName,
        host: brandSetup.brandUrl,
        isNotPreConfigured: true
      });
      allBoutiqueUrl[brandSetup.id] = {
        salesLoft: brandSetup.brandUrl
      };
    }
    setIsOnboardingBrand(true);
    return;
  }
  setIsOnboardingBrand(false);
};
