export const UPDATE_COMMON3D_ACTIVE_ANIMATION_IN_PANO =
  'COMMON3D.UPDATE_COMMON3D_ACTIVE_ANIMATION_IN_PANO';
export const DID_RECEIVE_REMOTE_COMMON3D_ANIMATION_STATE =
  'COMMON3D.DID_RECEIVE_REMOTE_COMMON3D_ANIMATION_STATE';
export const UPDATE_COMMON3D_ANIMATION = 'COMMON3D.UPDATE_COMMON3D_ANIMATION';
export const actionUpdateCommon3DActiveAnimationInPano = (
  animation: string
) => ({
  type: UPDATE_COMMON3D_ACTIVE_ANIMATION_IN_PANO,
  payload: { animation }
});
export const actionDidUpdateCommon3DAnimation = (
  animation: string,
  state: any
) => ({
  type: UPDATE_COMMON3D_ANIMATION,
  payload: { animation, state }
});
export const actionDidReceiveRemoteCommon3DAnimationState = (
  animation: string,
  state: any
) => ({
  type: DID_RECEIVE_REMOTE_COMMON3D_ANIMATION_STATE,
  payload: { animation, state }
});
