import { AxiosResponse } from 'axios';
import { chunk, flatten } from 'lodash';
import { logEvent } from '../analytics';
import { loubiAirwaysJpBoutiqueId } from '../config';
import { IInspiration, InspirationsSearchQuery } from '../interfaces';
import { DID_SEARCH_FOR_STORIES } from '../utils/constants';
import { filterInspirationsUnderEmbargo } from '../utils/inspiration';
import httpClient from './http';

interface IStoryFilters {
  context?: string[];
}
export const getQueryString = (filters: IStoryFilters | undefined) => {
  if (!filters) return '';
  return Object.keys(filters)
    .map((key) => {
      if (
        filters[key as keyof IStoryFilters] &&
        (filters[key as keyof IStoryFilters] as Array<any>).length > 0
      ) {
        return `(or ${(filters[key as keyof IStoryFilters] as any)
          .map((v: string) => `${key}: '${v}'`)
          .join(' ')})`;
      }
      return '';
    })
    .join(' ');
};

const getStoriesByChunkedIds = async (storyIds: string[]) => {
  if (storyIds.length === 0) {
    return [];
  }
  const result = await httpClient.post('/api/inspirationsByIds', storyIds);
  return result.data;
};

export const getStoriesByIds = async (storyIds: string[]) => {
  if (storyIds.length === 0) {
    return [];
  }
  const parallelResults = await Promise.all(
    chunk(storyIds, 10).map((ids) => getStoriesByChunkedIds(ids))
  );
  return flatten(parallelResults);
};

export const performStorySearch = async (
  keywords: string,
  brandId: string,
  language: string,
  filters: IStoryFilters | undefined,
  size: number,
  startIndex: number,
  storeId?: string
): Promise<IInspiration[]> => {
  const tagQuery =
    storeId === loubiAirwaysJpBoutiqueId ? ` taglist: 'loubiairwaysjp'` : '';
  const searchAttributes: InspirationsSearchQuery = {
    keywords,
    startIndex,
    size,
    languageCode: language.toUpperCase(),
    queryString: `(and ownerid:'${brandId}' ${getQueryString(
      filters
    )}${tagQuery})`
  };
  logEvent(DID_SEARCH_FOR_STORIES, brandId, searchAttributes);
  const response: AxiosResponse<IInspiration[]> = await httpClient.post(
    '/api/inspirationsSearch',
    searchAttributes
  );
  return filterInspirationsUnderEmbargo(response.data);
};

interface GetInspirationsByBrandPayload {
  id: string,
  language: string
}

export const getInspirationsByBrand = (payload: GetInspirationsByBrandPayload): Promise<AxiosResponse<IInspiration[]>> => (
  httpClient
    .post(`/api/getInspirationsByBrand`, payload)
)

export const getInspirationsByIds = (ids: string[]): Promise<AxiosResponse<IInspiration[]>> => (
  httpClient
    .post('/api/inspirationsByIds', ids)
)