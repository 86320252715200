import {
  INudge,
  INudgePayload,
  NudgeStatus,
  INudgeItem,
  NudgeItemType,
  IProduct,
  IInspiration,
  INudgeItemPayload,
  INudgeTemplate,
  ICreateNudgePayload
} from '../../interfaces';
import { getProductDisplayName } from './../../utils/product';
import { microSiteHeaderParams } from '../config';
import { getProductOGImageUrl } from '../../utils/image';

export interface IVirtualInvitationRequest {
  id: string;
  content: {
    id: string;
    type: NudgeItemType;
  }[];
  storeId: string;
  createdBy: string;
  createdAt: Date;
}

export const isInspiration = (item: INudgeItem) => {
  return !!(item as IInspiration)?.flipImageUrl;
};

export const isProduct = (item: INudgeItem) => {
  return !!(item as IProduct)?.imageUrls;
};

export const inspirationsCounter = (items: INudgeItem[]) => {
  let list = [];

  items.map((item) => {
    if (isInspiration(item)) list = [...list, item];
  });

  return list.length;
};

export const productsCounter = (items: INudgeItem[]) => {
  let list = [];

  items.map((item) => {
    if (isProduct(item)) list = [...list, item];
  });

  return list.length;
};

export const getNudgeItemType = (item: INudgeItem): NudgeItemType => {
  return isProduct(item) ? NudgeItemType.PRODUCT : NudgeItemType.STORY;
};

export const mapItemsToItemsRequest = (
  items: INudgeItem[]
): INudgeItemPayload[] => {
  return items.map((item) => ({
    id: item.id,
    type: getNudgeItemType(item)
  }));
};

export const changeContentOrder = (
  arr: any[],
  oldIndex: number,
  newIndex: number
) => {
  const items = [...arr];
  const item = items[oldIndex];
  items.splice(oldIndex, 1);
  items.splice(newIndex, 0, item);

  return items;
};

export const mapNudgeToPayload = (nudge: INudge): INudgePayload => {
  return {
    id: nudge.id,
    name: nudge.name || '',
    title: nudge.title || '',
    content: mapItemsToItemsRequest(nudge.content),
    storeId: nudge.storeId,
    createdBy: nudge.createdBy,
    meetingUrlAdvisor: nudge.meetingUrlAdvisor || undefined,
    meetingUrlClient: nudge.meetingUrlClient || undefined,
    status: nudge.status || undefined,
    customerId: nudge.customerId || undefined,
    customerEmail: nudge.customerEmail || undefined,
    customerPhoneCountryCode: nudge.customerPhoneCountryCode || undefined,
    customerPhoneNumber: nudge.customerPhoneNumber || undefined,
    advisorId: nudge.advisorId || undefined,
    languageCode: nudge.languageCode?.toUpperCase(),
    currency: nudge.currency || undefined,
    hidePrice: nudge.hidePrice
  };
};

export const mapNudgeToTemplatePayload = (
  nudge: INudge,
  userId: string
): INudgeTemplate => {
  return {
    id: nudge.id,
    storeId: nudge.storeId,
    brand: nudge.brand,
    title: nudge.title || '',
    content: mapItemsToItemsRequest(nudge.content),
    description: nudge.description || '',
    createdAt: nudge.createdAt || new Date().toISOString(),
    createdBy: nudge.createdBy || userId,
    modifiedAt: nudge.createdAt ? new Date().toISOString() : '',
    modifiedBy: nudge.createdAt ? userId : '',
    languageCode: nudge.languageCode?.toUpperCase(),
    currency: nudge.currency || undefined,
    hidePrice: nudge.hidePrice
  };
};

export const searchNudges = (keyword: string, data: any) => {
  if (!keyword) return data;
  return data.filter((o: any) =>
    Object.keys(o).some((k) =>
      o[k].toString().toLowerCase().includes(keyword.toLowerCase())
    )
  );
};

export const getProductIdsFromNudgeContent = (
  nudgeItems: INudgeItemPayload[]
) => {
  const items = nudgeItems.filter((item) => item.type === 'product');
  return items.map((item) => item.id);
};

export const getStoryIdsFromNudgeContent = (
  nudgeItems: INudgeItemPayload[]
) => {
  const items = nudgeItems.filter((item) => item.type === 'story');
  return items.map((item) => item.id);
};

export const mapProductsAndStoriesToNudgeItem = (
  nudgeItems: INudgeItemPayload[],
  products: IProduct[],
  stories: IInspiration[]
): INudgeItem[] => {
  return nudgeItems
    .map((item) => {
      if (item.type === 'product') {
        return products.find((product) => product.id === item.id);
      } else {
        return stories.find((story) => story.id === item.id);
      }
    })
    .filter((i) => !!i?.id);
};

export const mapNudgePayloadToNudgeState = (
  payload: INudgePayload,
  products: IProduct[],
  stories: IInspiration[]
): INudge => ({
  ...payload,
  content: mapProductsAndStoriesToNudgeItem(payload.content, products, stories)
});

export const getStatusDisplay = (status: NudgeStatus) => {
  switch (status) {
    case NudgeStatus.INVITATION_OPENED:
      return 'Opened';
    case NudgeStatus.INVITATION_SENT:
      return 'Sent';
    case NudgeStatus.AWAITING_WALKTHROUGH:
      return 'In the Lounge';
    case NudgeStatus.JOINED_WALKTHROUGH:
      return 'Joined walkthrough';
    case NudgeStatus.LEFT_WALKTHROUGH:
      return 'Left walkthrough';
    default:
      return 'Created';
  }
};

export const getNudgeItemImage = (item: INudgeItem) => {
  if (!item) return '';
  const imageUrl = isProduct(item)
    ? getProductOGImageUrl(item as IProduct)
    : (item as IInspiration)?.flipImageUrl;
  return imageUrl || '';
};

export const getNudgeItemTitle = (item: INudgeItem, language: string) => {
  if (!item) return '';
  return isProduct(item)
    ? getProductDisplayName(item as IProduct, language)
    : (item as IInspiration)?.title || '';
};

export const getNudgeHeaderParams = (brandId: string, lang: string) => {
  const language = lang?.toLowerCase() || 'en';
  return {
    title:
      microSiteHeaderParams[brandId]?.title?.[language] ||
      microSiteHeaderParams[brandId]?.title?.['en'] ||
      microSiteHeaderParams.default.title?.[language] ||
      microSiteHeaderParams.default.title?.['en'] ||
      '',
    description:
      microSiteHeaderParams[brandId]?.description?.[language] ||
      microSiteHeaderParams[brandId]?.description?.['en'] ||
      microSiteHeaderParams.default.description?.[language] ||
      microSiteHeaderParams.default.description?.['en'] ||
      ''
  };
};

export const getNudgeCountryForCurrency = (nudge: INudge) =>
  (nudge?.countryCode || nudge?.currency?.toLowerCase() || '').toUpperCase();

export const mapCreateNudgeToPayload = (nudge: INudge): ICreateNudgePayload => {
  return {
    advisorId: nudge.advisorId,
    brandId: nudge.brand || '',
    contactId: nudge.contactId || '',
    title: nudge.title || '',
    name: nudge.name || '',
    content: mapItemsToItemsRequest(nudge.content),
    storeId: nudge.storeId,
    createdBy: nudge.createdBy,
    currency: nudge.currency || '',
    languageCode: nudge.languageCode?.toUpperCase() || '',
    hidePrice: nudge.hidePrice || false,
    hideRecommendations: nudge.hideRecommendations || false,
    hideVariations: nudge.hideVariations || false,
    hideReferences: nudge.hideReferences || false,
    externalDomain: nudge.externalDomain || location.host,
    countryCode: nudge.countryCode || '',
    ogDescription: nudge.ogDescription || undefined,
    ogTitle: nudge.ogTitle || undefined,
    ogImage: nudge.ogImage || getNudgeItemImage(nudge.content[0]) || undefined,
    medium: nudge.medium || '',
    source: nudge.source || '',
    campaign: ''
  };
};
