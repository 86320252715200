import { uniqBy } from 'lodash';
import { AnyAction } from 'redux';
import { ClientState, WishlistType } from '../../interfaces';
import { DID_ACCEPT_COOKIES, DID_REJECT_COOKIES } from '../actions';
import {
  ADD_TO_WISHLIST,
  CLOSE_CATALOG,
  DID_RECEIVE_REMOTE_LOUBI_AIRWAYS_ANIMATION_STATE,
  OPEN_WISHLIST_POPUP,
  PLAY_INTRODUCTION_VIDEO_FOR_MEETING,
  REMOVE_FROM_WISHLIST,
  TOGGLE_CHAT_PANEL,
  TOGGLE_SHOW_SPOTIFY_PLAYER,
  UPDATE_ACTIVE_ANIMATION_IN_PANO,
  UPDATE_ALREADY_PASSED_LAUNCH_TIME,
  UPDATE_CATALOG,
  UPDATE_FULL_SCREEN_VIDEO,
  UPDATE_INVITE,
  UPDATE_LANDING_VIDEO_STATE,
  UPDATE_LOUBI_AIRWAYS_EXIT_STATE,
  UPDATE_LOUBI_AIRWAYS_PLANE_PLAN,
  UPDATE_LOUBI_AIRWAYS_POPUP_CONTENT_TYPE,
  UPDATE_LOUBI_AIRWAYS_SELECTED_MODEL,
  UPDATE_VISITED_PLAN_SPOT,
  UPDATE_WISHLIST,
  UPDATE_WISHLIST_ITEM_SIZE
} from '../customActions/loubiAirways';
import { defaultClientState } from './clientReducer';

const loubiAirwaysReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case DID_RECEIVE_REMOTE_LOUBI_AIRWAYS_ANIMATION_STATE: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          remoteAnimation: {
            ...(state.loubiAirways?.remoteAnimation || {}),
            [action.payload.animation]: action.payload.state
          }
        }
      };
    }

    case UPDATE_ACTIVE_ANIMATION_IN_PANO: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          activeAnimation: action.payload.animation
        }
      };
    }

    case UPDATE_LOUBI_AIRWAYS_SELECTED_MODEL: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          selectedModel: action.payload.selectedModel
        }
      };
    }

    case UPDATE_FULL_SCREEN_VIDEO: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          fullScreenVideo: action.payload.video
        }
      };
    }

    case UPDATE_LANDING_VIDEO_STATE: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          landingVideo: action.payload
        }
      };
    }

    case ADD_TO_WISHLIST: {
      const currentWishlist = state.loubiAirways?.wishlist?.wishlistItems || [];
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          wishlist: {
            ...(state.loubiAirways?.wishlist || {}),
            wishlistItems: uniqBy(
              [
                ...currentWishlist,
                { itemId: action.payload.id, itemType: WishlistType.PRODUCT }
              ],
              'itemId'
            )
          }
        }
      };
    }

    case REMOVE_FROM_WISHLIST: {
      const currentWishlist = state.loubiAirways?.wishlist?.wishlistItems || [];
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          wishlist: {
            ...(state.loubiAirways?.wishlist || {}),
            wishlistItems: currentWishlist.filter(
              (p) => p.itemId && p.itemId !== action.payload.id
            )
          }
        }
      };
    }

    case OPEN_WISHLIST_POPUP: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          wishlist: {
            ...(state.loubiAirways?.wishlist || {}),
            open: action.payload
          }
        }
      };
    }

    case UPDATE_WISHLIST: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          wishlist: {
            ...(state.loubiAirways?.wishlist || {}),
            ...action.payload
          }
        }
      };
    }

    case UPDATE_WISHLIST_ITEM_SIZE: {
      const productId = action.payload.id as string;
      const copy = [...(state.loubiAirways?.wishlist?.wishlistItems || [])];
      const item = copy.find((p) => p.itemId === productId);
      const itemIndex = copy.indexOf(item);

      if (itemIndex >= 0) {
        copy[itemIndex].size = action.payload.size;
      }

      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          wishlist: {
            ...(state.loubiAirways?.wishlist || {}),
            wishlistItems: copy
          }
        }
      };
    }

    case DID_ACCEPT_COOKIES: {
      return {
        ...state,
        agreedToCookie: true
      };
    }

    case DID_REJECT_COOKIES: {
      return {
        ...state,
        agreedToCookie: false
      };
    }

    case TOGGLE_SHOW_SPOTIFY_PLAYER: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          showSpotifyPlayer: action.payload
        }
      };
    }

    case UPDATE_LOUBI_AIRWAYS_PLANE_PLAN: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          planePlan: action.payload.planePlan
        }
      };
    }

    case UPDATE_VISITED_PLAN_SPOT: {
      const currentVisited = state.loubiAirways?.planePlan?.visitedSpot || [];
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          planePlan: {
            ...(state.loubiAirways?.planePlan || {}),
            visitedSpot: [...currentVisited, action.payload.spot]
          }
        }
      };
    }
    case PLAY_INTRODUCTION_VIDEO_FOR_MEETING: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          playIntroductionVideosInMeeting: true
        }
      };
    }

    case UPDATE_LOUBI_AIRWAYS_POPUP_CONTENT_TYPE: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          popupContentType: action.payload
        }
      };
    }

    case UPDATE_LOUBI_AIRWAYS_EXIT_STATE: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          exitState: action.payload
        }
      };
    }

    case UPDATE_ALREADY_PASSED_LAUNCH_TIME: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          alreadyPassedLaunchTime: true
        }
      };
    }

    case UPDATE_INVITE: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          invite: action.payload
        }
      };
    }

    case TOGGLE_CHAT_PANEL: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          openChatPanel: action.payload
        }
      };
    }

    case UPDATE_CATALOG: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          catalog: {
            ...(state.loubiAirways?.catalog || {}),
            ...(action.payload || {})
          }
        }
      };
    }

    case CLOSE_CATALOG: {
      return {
        ...state,
        loubiAirways: {
          ...(state.loubiAirways || {}),
          catalog: {
            ...(state.loubiAirways?.catalog || {}),
            show: false
          }
        }
      };
    }

    default:
      return state;
  }
};

export default loubiAirwaysReducer;
