import {
  isMobile,
  isMobileOnly,
  isIOS,
  isAndroid,
  isMobileSafari,
  browserVersion,
  isDesktop,
  isFirefox
} from 'react-device-detect';
import Bowser from 'bowser';

export const isUserOnMobileSafari = () => isIOS;
export const isUserOniOS = () => isIOS;
export const isUserOnAndroid = () => isAndroid;
export const isUserOnMobile = () => isMobile;
export const isUserOnMobileOnly = () => isMobileOnly;
export const isMobileViewport = () =>
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <
  768;
export const isTouchEnableDevice = () =>
  ('ontouchstart' in window || navigator.maxTouchPoints) > 0;
export const isUserOnMobileSafari15 = () =>
  isMobileSafari && browserVersion === '15';

export const isPortraitViewport = () =>
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <
  Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

type Browser = {
  [index: string]: string;
};

type OSAndBrowser = {
  [index: string]: Browser;
};

export type BrowserList = Browser | OSAndBrowser;

export const getBrowserInfo = () => {
  return Bowser.getParser(window.navigator.userAgent);
};

export const isValidBrowser = (list: BrowserList) => {
  const browser = getBrowserInfo();
  return browser.satisfies({ ...list });
};

const cockpitInvalidBrowsers: Bowser.Parser.checkTree = {
  chrome: '<72',
  firefox: '<68',
  edge: '<79',
  safari: '<14',
  iOS: {
    safari: '<14.3'
  }
};

export const isBrowserSupportedCockpit = () => {
  return !isValidBrowser(cockpitInvalidBrowsers);
};

export const isUserOnWeChat = () => /micromessenger/i.test(navigator.userAgent);

export const getUserAgent = () => navigator.userAgent;

export const isUserOnLineBrowser = () =>
  navigator.userAgent.indexOf('Line') !== -1;

export const isUserOnSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isUserOnKakaotalk = () =>
  navigator.userAgent.toLocaleLowerCase().indexOf('kakaotalk') !== -1;

export const isUserOnDesktop = () => isDesktop;

export const isUserOnFirefox = () => isFirefox;
