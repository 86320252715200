import { AnyAction } from 'redux';
import { ClientState, IProductAccessories } from '../../interfaces';
import {
  DID_LOAD_PRODUCT_ACCESSORIES,
  SELECT_PRODUCT_STRAP,
  SELECT_PRODUCT_BUCKLE,
  SELECT_PRODUCT_DIAL,
  SELECT_ACCESSORIES_SECTION
} from '../actions';
import { defaultClientState } from './clientReducer';

const productAccessoriesReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case DID_LOAD_PRODUCT_ACCESSORIES: {
      const payload: IProductAccessories = action.payload;
      if (!payload) {
        return {
          ...state,
          productAccessories: null
        };
      }

      return {
        ...state,
        productAccessories: {
          ...payload
        }
      };
    }
    case SELECT_PRODUCT_STRAP: {
      const accessoriesState = state?.productAccessories || {};
      let currentBuckle = accessoriesState?.selectedBuckle || null;
      const currentPair =
        accessoriesState?.strapBucklePairs?.[action.payload?.id] || [];

      if (!currentPair.length) {
        currentBuckle = null;
      }

      if (
        (!currentBuckle && currentPair.length) ||
        currentPair.indexOf(currentBuckle?.id) === -1
      ) {
        currentBuckle = accessoriesState.buckles?.find(
          (buckle) => buckle.id === currentPair[0]
        );
      }

      return {
        ...state,
        productAccessories: {
          ...accessoriesState,
          selectedStrap: action.payload,
          selectedBuckle: currentBuckle,
          activeSection: '#strapSection'
        }
      };
    }
    case SELECT_PRODUCT_BUCKLE: {
      return {
        ...state,
        productAccessories: {
          ...(state?.productAccessories || {}),
          selectedBuckle: action.payload,
          activeSection: '#buckleSection'
        }
      };
    }

    case SELECT_PRODUCT_DIAL: {
      return {
        ...state,
        productAccessories: {
          ...(state?.productAccessories || {}),
          selectedDial: action.payload,
          activeSection: '#dialSection'
        }
      };
    }

    case SELECT_ACCESSORIES_SECTION: {
      return {
        ...state,
        productAccessories: {
          ...(state?.productAccessories || {}),
          activeSection: action.payload
        }
      };
    }

    default:
      return state;
  }
};

export default productAccessoriesReducer;
