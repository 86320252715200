import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getCountryCode,
  getStoreById,
  getStoreByRackId as _getStoreByRackId,
  getStoreConfig
} from '../clientSideServices/store';
import { alsSgIonBoutiqueId, getWhatsappNumberForAls } from '../config';
import { useTranslation } from '../i18n';
import { IStore, IVirtualBoutiqueConfig, Source } from '../interfaces';
import { actionFetchWishlistAsync } from '../redux/asyncActions';
import { INS_CLOSE_POPUP } from '../utils/constants';
import { getIdentityId } from '../utils/identity';
import { tryGetParentWindowField } from '../utils/window';
import { TouchCustomStyle } from './BrandStyle';
import ChatContentSharingContextContainer from './ChatContentSharingContext';
import FaceBookPixel from './Tracking/FaceBookPixel';

export const parseSource = (source: string): Source | null => {
  const cleanSource = source?.toLowerCase()?.trim();
  switch (cleanSource) {
    case 'showroom':
      return Source.Showroom;
    case 'advisorhub':
      return Source.AdvisorHub;
    case 'nudge':
      return Source.Nudge;
    case 'ssp':
      return Source.SSP;
    case 'session':
      return Source.Session;
    case 'connect':
      return Source.Connect;
    case 'chat':
      return Source.Chat;
    default:
      return null;
  }
};

export const parseSourceString = (source: Source): string | null => {
  switch (source) {
    case Source.Showroom:
      return 'showroom';
    case Source.AdvisorHub:
      return 'advisorhub';
    case Source.Nudge:
      return 'nudge';
    case Source.SSP:
      return 'ssp';
    case Source.Session:
      return 'session';
    case Source.Connect:
      return 'connect';
    case Source.Chat:
      return 'chat';
    case Source.Storybook:
      return 'storybook';
    default:
      return null;
  }
};

export interface IAppContext {
  country: string;
  city: string;
  source: Source | null;
  store: IStore | null;
  storeConfig: IVirtualBoutiqueConfig | null;
}

export const defaultContext: IAppContext = {
  country: 'SG',
  city: 'Singapore',
  source: null,
  store: null,
  storeConfig: null
};

export const AppContext = React.createContext<IAppContext>(defaultContext);

export const ContextContainer = (props: {
  children: React.ReactNode;
  storeId?: string;
  geo?: {
    country: string;
    city: string;
  };
}) => {
  const [appContext, setAppContext] = React.useState<IAppContext>(null);
  const router = useRouter();
  const dispatch = useDispatch();

  const getCountry = (
    guess?: string | string[]
  ): Promise<{
    country: string;
    city: string;
  }> => {
    if (guess) {
      return Promise.resolve(
        typeof guess === 'string'
          ? {
              country: guess,
              city: guess
            }
          : {
              country: guess[0],
              city: ''
            }
      );
    }
    const { country, city } = props?.geo || {};
    if (country && city) {
      return Promise.resolve({
        country,
        city
      });
    }
    return new Promise((resolve, _) => {
      getCountryCode()
        .then((result) =>
          resolve({
            country: result.data.country,
            city: result.data.city
          })
        )
        .catch((_) =>
          resolve({
            country: 'SG',
            city: 'Singapore'
          })
        );
    });
  };

  const getStore = (storeId?: string): Promise<IStore | null> => {
    if (window['store']) return Promise.resolve(window['store']);
    if (!storeId) return Promise.resolve(null);
    return new Promise((resolve, _) => {
      getStoreById(storeId)
        .then((result) => resolve(result.data))
        .catch((_) => resolve(null));
    });
  };

  const getStoreByRackId = (rackId: string): Promise<IStore | null> => {
    return new Promise((resolve, _) => {
      _getStoreByRackId(rackId)
        .then((result) => resolve(result.data))
        .catch((_) => resolve(null));
    });
  };

  const getStoreConfigById = (
    id?: string,
    fromStorybook = false
  ): Promise<IVirtualBoutiqueConfig | null> => {
    if (!id) return Promise.resolve(null);
    return new Promise((resolve, _) => {
      getStoreConfig(id)
        .then((result) =>
          resolve({ ...result.data, fromStorybook: fromStorybook })
        )
        .catch((_) => resolve(null));
    });
  };

  const getStoreConfigByStoreID = (storeId?: string, storybookId?: string) => {
    if (window['storeConfig']) return Promise.resolve(window['storeConfig']);
    if (!storeId && !storybookId) return Promise.resolve(null);
    const getByStorePromise = getStoreConfigById(storeId);
    const getByStorybookPromise = getStoreConfigById(storybookId, true);

    return Promise.all([getByStorePromise, getByStorybookPromise]).then(
      ([storeConfig, sbConfig]) => {
        return {
          ...(storeConfig || {}),
          ...(sbConfig || {})
        };
      }
    );
  };

  const preserveContext = ({
    source,
    country,
    store,
    storeConfig,
    city
  }: IAppContext) => {
    window['source'] = source;
    window['country'] = country;
    window['store'] = store;
    window['storeConfig'] = storeConfig;
    window['city'] = city;
  };

  const getMappedStore = (store: IStore | null) => {
    if (!store) return null;
    if (store.id !== alsSgIonBoutiqueId) {
      return store;
    }
    return { ...store, chatNowNumber: getWhatsappNumberForAls() };
  };

  const { i18n } = useTranslation();

  React.useEffect(() => {
    const hasLanguagePrefix = Boolean(router.query.language);
    if (hasLanguagePrefix && router.query.language !== i18n.language) {
      i18n.changeLanguage(router.query.language as string);
    }
    const sourceStr = (router.query.source as string) || window['source'] || '';
    const knownCountry = router.query.country || window['country'];
    const storeId = (router.query.store as string) || props.storeId;
    const storybookId = (router.query.storybook as string) || '';

    let getStorePromise = getStore(storeId);
    if (
      router.pathname.startsWith('/showcase') &&
      router.query.id &&
      !router.query.store &&
      !window['store']
    ) {
      const rackId = router.query.id as string;
      getStorePromise = getStoreByRackId(rackId);
    }
    let storeIdBackup;
    if (storybookId && knownCountry) {
      storeIdBackup = `${storybookId}+${knownCountry.toLowerCase()}`;
    }

    const getStoreConfigPromise = getStoreConfigByStoreID(
      storeId,
      storeIdBackup
    );

    Promise.all([
      getCountry(knownCountry),
      getStorePromise,
      getStoreConfigPromise
    ]).then((values) => {
      const [{ country, city }, store, storeConfig] = values;
      const mappedStore = getMappedStore(store);
      const context: IAppContext = {
        source: parseSource(sourceStr),
        country,
        city,
        store: mappedStore,
        storeConfig
      };
      const hasStoreLanguage = Boolean(store?.languageCode);
      if (
        !hasLanguagePrefix &&
        hasStoreLanguage &&
        store.languageCode !== i18n.language
      ) {
        i18n.changeLanguage(store.languageCode);
      }
      setAppContext(context);
      preserveContext(context);
    });

    dispatch(
      actionFetchWishlistAsync(getIdentityId(), storeId || window['store']?.id)
    );

    const keydownListener = (e) => {
      if (e.key === 'Escape') {
        const closePopup = tryGetParentWindowField(INS_CLOSE_POPUP);
        if (typeof closePopup === 'function') {
          closePopup();
        }
      }
    };
    window.addEventListener('keydown', keydownListener);
    return () => {
      window.removeEventListener('keydown', keydownListener);
    };
  }, []);

  if (!appContext) {
    return null;
  }
  return (
    <AppContext.Provider value={appContext}>
      <ChatContentSharingContextContainer>
        <FaceBookPixel
          brandId={appContext.storeConfig?.brandId}
          storeId={props.storeId}
        >
          <FaceBookPixel brandId="inspify" storeId={props.storeId}>
            {props.children}
          </FaceBookPixel>
        </FaceBookPixel>
        <TouchCustomStyle storeId={appContext.store?.id} />
      </ChatContentSharingContextContainer>
    </AppContext.Provider>
  );
};
