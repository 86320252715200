import { isEmbeddedInStreamingStudio } from '../../utils/streamingstudio';
import { isUserOnMobile } from './../../utils/deviceDetector';
import { getShortenedUrl, ShareScenario } from './../../utils/shareUrl';
import { useRouter } from 'next/router';
import React from 'react';

// Custom Hook to deal with state values in the event listener
// https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
export function useStateRef(initialValue) {
  const [value, setValue] = React.useState(initialValue);

  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
}

export const useNextQueryParams = (): { [key: string]: string } => {
  const router = useRouter();
  const value = React.useMemo(() => {
    const queryParamsStr = router.asPath?.split('?')?.slice(1)?.join('');
    const urlSearchParams = new URLSearchParams(queryParamsStr);
    const params = Object.fromEntries(urlSearchParams?.entries());
    return params;
  }, [router.asPath]);

  return value;
};

export const useChatMessage = (defaultMessage: string | undefined) => {
  const [message, setMessage] = React.useState(defaultMessage);
  const [loading, setLoading] = React.useState(false);

  const getMessage = (): Promise<string> => {
    setLoading(true);
    return getShortenedUrl(ShareScenario.GENERAL)
      .then((shareUrl) => {
        setMessage(shareUrl);
        return shareUrl;
      })
      .finally(() => setLoading(false));
  };

  return { loading, message, getMessage };
};

export const useUserOnMobile = () => {
  const [isUsingMobile, setIsUsingMobile] = React.useState(false);
  React.useEffect(() => {
    setIsUsingMobile(isUserOnMobile());
  }, []);
  return { isUsingMobile };
};

export const useSspState = () => {
  const [isSsp, setIsSsp] = React.useState(false);
  React.useEffect(() => {
    setIsSsp(isEmbeddedInStreamingStudio());
  }, []);
  return isSsp;
};
