import { IDENTITY_ID } from './constants';
import { generateV4UUID } from './identityGenerator';

export const getIdentityId = () => {
  let id;
  try {
    id = localStorage.getItem(IDENTITY_ID);
  } catch (e) {
    console.error(e);
  }
  return id;
};

export const setNewIdentity = (newId?: string) => {
  try {
    localStorage.setItem(IDENTITY_ID, newId || generateV4UUID());
  } catch (e) {
    console.error(e);
  }
};

export const setNewIdentityIfNeeded = () => {
  if (getIdentityId()) return;
  setNewIdentity();
};
