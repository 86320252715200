import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { actionPageDidScroll } from '../../redux/actions';
import React from 'react';

const getScrollArea = () => document.getElementById('voyage-scroll-listener');

const getScrolledVerticalOffset = () => {
  return getScrollArea()?.scrollTop || 0;
};

const getVerticalScrollRange = () => {
  const scrollArea = getScrollArea();
  return scrollArea ? scrollArea.scrollHeight - scrollArea.clientHeight : 0;
};

export const useScrollListenerForVoyage = () => {
  const dispatch = useDispatch();

  const debouncedScrollListener = debounce(() => {
    const scrolledOffset = getScrolledVerticalOffset();
    const totalYScrollRange = getVerticalScrollRange();
    const scrolledRange =
      totalYScrollRange > 0 ? scrolledOffset / totalYScrollRange : 0;
    dispatch(actionPageDidScroll(scrolledRange));
    console.log(scrolledRange);
  }, 200);

  const addScrollListenForVoyage = () => {
    getScrollArea()?.addEventListener('scroll', debouncedScrollListener);
  };

  const removeScrollListenForVoyage = () => {
    getScrollArea()?.removeEventListener('scroll', debouncedScrollListener);
  };

  return [addScrollListenForVoyage, removeScrollListenForVoyage];
};

export const scrollForVoyage = (percentage: string) => {
  const scrolledPercentage = parseFloat(percentage);
  if (!isNaN(scrolledPercentage)) {
    const totalYScrollRange = getVerticalScrollRange();
    getScrollArea()?.scroll({
      top: totalYScrollRange * scrolledPercentage,
      left: 0,
      behavior: 'smooth'
    });
  }
};

export function useHorizontalScroll<T extends HTMLElement>() {
  const elRef = React.useRef<T>();
  React.useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth'
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);

  return elRef;
}
