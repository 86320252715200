import {
  REQUEST_TO_BE_PRESENTER,
  actionSetActiveFile,
  PARTICIPANT_DID_SET_ACTIVE_PAGE,
  actionSetMeetingLayoutState,
  actionUpdateFileViewerLayout,
  REQUEST_UPDATE_FILE_VIEWER_LAYOUT,
  REQUEST_UPDATE_PLAYER_CONTROL,
  actionUpdatePlayerControl
} from './../../../../redux/actions';
import {
  IFile,
  IMeeting,
  MeetingLayoutMode,
  IStorybookFileState
} from './../../../../interfaces/index';
import {
  getMeetingRole,
  wrapActionToBeMirroring
} from './../../../../utils/meeting';
import { AnyAction, Dispatch } from 'redux';
import {
  ClientState,
  JitsiEndpointMessage,
  MeetingRole,
  ParticipantMeetingState
} from '../../../../interfaces';
import {
  actionAddLayoutPresenter,
  actionDidAdmitLateComerToMeeting,
  actionDidReceiveParticipantUserInfo,
  actionDidReceiveRequestAdvisorInfo,
  actionDidReceiveToggleRaiseHand,
  actionJoinAgainAsClient,
  actionLateComerDidJoinLounge,
  actionParticipantReady,
  actionRemoveLayoutPresenter,
  actionToggleEnlargeVideo,
  actionToggleParticipantVisibility,
  actionUpdateMeetingLayout,
  actionUpdateStudioVideoRotateDeg,
  actionUpdateStudioVideoZoomLevel,
  actionSetMeetingDate,
  JOIN_AS_ADVISOR,
  actionKickOutDifferentAdvisor,
  actionParticipantDidEnterFromLounge,
  actionReceiveParticipantPSPlayerStatus,
  actionSetActivePage,
  actionSetFileController
} from '../../../../redux/actions';
import {
  JOIN_AGAIN_AS_CLIENT,
  MeetingCommand,
  PLAYER_STATUS,
  RAISE_HAND,
  REMOTE_CONTROL,
  REQUEST_ADMITTANCE,
  REQUEST_ADVISOR_INFO,
  USER_INFO
} from '../../commands';
import { first } from 'lodash';
import {
  getCurrentPage,
  handleLoadStorybook
} from '../Spectator/SpectatorCommandReceiver';
const syncRaiseHand = (dispatch: Function, participantIds: string[]) => {
  if (participantIds?.length) {
    participantIds?.forEach((id) =>
      dispatch(actionDidReceiveToggleRaiseHand(id))
    );
  }
};

const handleUserReconnectingIfNeeded = (
  participantId: string,
  oldParticipantId: string,
  meetingState: IMeeting,
  fileViewer: IStorybookFileState,
  dispatch: Function
) => {
  if (!oldParticipantId || !meetingState.remoteUsers?.[oldParticipantId])
    return;

  dispatch(
    actionToggleParticipantVisibility({
      participantId: oldParticipantId,
      toggleToVisible: false
    })
  );
  const isPresenter =
    meetingState.layout?.presenterIds?.includes?.(oldParticipantId);
  const isFileController = fileViewer?.controller === oldParticipantId;
  if (isPresenter) {
    dispatch(actionAddLayoutPresenter(participantId));
  }
  if (isFileController) {
    dispatch(actionSetFileController(participantId));
  }
};

const handleUserInfo = (
  payload: JitsiEndpointMessage,
  dispatch: Function,
  clientState: ClientState
) => {
  const {
    participantId,
    identityId,
    role,
    meetingDate,
    raiseHandParticipantIds,
    oldParticipantId
  } = payload.attributes;
  const action = actionDidReceiveParticipantUserInfo(
    participantId,
    identityId,
    role
  );
  if (meetingDate && !clientState?.meeting?.date) {
    dispatch(actionSetMeetingDate(meetingDate));
  }
  if (getMeetingRole(role) === MeetingRole.STUDIO) {
    dispatch(actionParticipantDidEnterFromLounge(participantId));
  }
  dispatch(action);
  dispatch(actionParticipantReady(participantId));
  !clientState?.meeting?.raiseHand &&
    syncRaiseHand(dispatch, raiseHandParticipantIds);

  const meetingState = clientState?.meeting || {};
  const fileViewer = clientState?.fileViewer || {};
  handleUserReconnectingIfNeeded(
    participantId,
    oldParticipantId,
    meetingState,
    fileViewer,
    dispatch
  );
};

const handleRequestAdvisorInfo = (
  payload: JitsiEndpointMessage,
  dispatch: Function
) => {
  dispatch(actionDidReceiveRequestAdvisorInfo());
  const { participantId } = payload.attributes;
  dispatch(actionParticipantReady(participantId));
};

const handleRequestAdmittance = (
  payload: JitsiEndpointMessage,
  dispatch: Function,
  meetingState?: ParticipantMeetingState,
  autoAdmit?: boolean
) => {
  const { participantId, displayName, role } = payload.attributes;
  const isStudio = getMeetingRole(role) === MeetingRole.STUDIO;
  const sentMeetingState = isStudio ? undefined : meetingState;
  // SSP sometimes does not respond to REQUEST_USER_INFO correctly
  if (autoAdmit || isStudio) {
    dispatch(actionDidAdmitLateComerToMeeting(participantId, sentMeetingState));
  } else {
    dispatch(actionLateComerDidJoinLounge(participantId, displayName));
  }
};

const handleRaiseHand = (payload: JitsiEndpointMessage, dispatch: Function) => {
  dispatch(actionDidReceiveToggleRaiseHand(payload.attributes.participantId));
};

const handlePlayerStatus = (
  payload: JitsiEndpointMessage,
  dispatch: Function
) => {
  dispatch(actionReceiveParticipantPSPlayerStatus(payload.attributes));
};

const getActionFromPayload = (
  payload: JitsiEndpointMessage,
  clientState: ClientState
) => {
  switch (payload.value) {
    case MeetingCommand.ENLARGE_VIDEO: {
      const currentlyEnlargedParticipantId =
        clientState.meeting?.layout?.enlargedVideoParticipantId;
      if (
        payload.attributes?.participantId === currentlyEnlargedParticipantId &&
        !payload.attributes?.shouldEnlarge
      ) {
        return actionToggleEnlargeVideo(payload.attributes);
      }
      if (
        payload.attributes?.participantId !== currentlyEnlargedParticipantId &&
        payload.attributes?.shouldEnlarge
      ) {
        return actionToggleEnlargeVideo(payload.attributes);
      }
      return;
    }
    case MeetingCommand.TOGGLE_PARTICIPANT_VISIBILITY: {
      const currentlyInvisibleParticipantIds =
        clientState.meeting?.layout?.invisibleParticipantIds || [];
      if (
        currentlyInvisibleParticipantIds.includes(
          payload.attributes?.participantId
        ) &&
        payload.attributes?.toggleToVisible
      ) {
        return actionToggleParticipantVisibility(payload.attributes);
      }
      if (
        !currentlyInvisibleParticipantIds.includes(
          payload.attributes?.participantId
        ) &&
        !payload.attributes?.toggleToVisible
      ) {
        return actionToggleParticipantVisibility(payload.attributes);
      }
      return;
    }
    case MeetingCommand.UPDATE_LAYOUT_MODE:
      if (clientState.meeting?.layout?.mode !== payload.attributes) {
        return actionUpdateMeetingLayout(payload.attributes);
      }
      return;
    case MeetingCommand.UPDATE_STUDIO_VIDEO_ZOOM_LEVEL:
      if (
        clientState.meeting?.layout?.studioViewState?.zoomLevel !==
        payload.attributes.zoomLevel
      ) {
        return actionUpdateStudioVideoZoomLevel(
          payload.attributes.studioParticipantId,
          payload.attributes.zoomLevel
        );
      }
      return;
    case MeetingCommand.UPDATE_STUDIO_VIDEO_ROTATE_DEGREE:
      if (
        clientState.meeting?.layout?.studioViewState?.rotateDeg !==
        payload.attributes.rotateDeg
      ) {
        return actionUpdateStudioVideoRotateDeg(
          payload.attributes.studioParticipantId,
          payload.attributes.rotateDeg
        );
      }
      return;
    case MeetingCommand.ADD_LAYOUT_PRESENTER:
      if (
        clientState.meeting?.layout?.presenterIds?.includes(payload.attributes)
      ) {
        return;
      }
      return actionAddLayoutPresenter(payload.attributes);
    case MeetingCommand.REMOVE_LAYOUT_PRESENTER:
      if (
        clientState.meeting?.layout?.presenterIds?.includes(payload.attributes)
      ) {
        return actionRemoveLayoutPresenter(payload.attributes);
      }
      return;
    default:
      return;
  }
};
const handleRequestToBePresenter = async (
  file: IFile,
  requesterId: string,
  meetingState: IMeeting,
  dispatch: Dispatch
) => {
  const remoteUsers = meetingState.remoteUsers || {};
  const totalParticipants =
    Object.keys(remoteUsers || {}).filter(
      (participantId) =>
        remoteUsers[participantId]?.shouldShowVideo &&
        remoteUsers[participantId]?.role !== MeetingRole.STUDIO
    ).length + 1;
  if (totalParticipants > 3 && !meetingState?.layout?.presenterIds?.length) {
    wrapActionToBeMirroring(
      dispatch,
      actionSetMeetingLayoutState(
        {
          mode: MeetingLayoutMode.NORMAL,
          presenterIds: [requesterId]
        },
        true
      ),
      { to: '' }
    );
  }
  try {
    let presentFile = file;
    if (file.type === 'storybook') {
      const sb = await handleLoadStorybook(file.id, dispatch);
      presentFile = sb;
    }
    dispatch(actionSetActiveFile(presentFile.id));
    dispatch(
      actionSetActivePage({
        id: presentFile.id,
        file: presentFile.title,
        url: first(presentFile.pages),
        setting: first(presentFile.pagesSetting),
        silence: first(presentFile.silences)
      })
    );
    dispatch(actionSetFileController(requesterId));
  } catch (error) {
    console.error('Fail to load storybook from presenter', {
      id: file.id,
      error
    });
  }
};
export const handlePresenterEndpointMessage = (
  payload: JitsiEndpointMessage,
  dispatch: Dispatch,
  clientState: ClientState,
  isSSP = false
) => {
  console.log('handlePresenterEndpointMessage - received payload');
  console.log(payload);
  switch (payload.type) {
    case JOIN_AS_ADVISOR:
      if (clientState?.meeting?.joinAt < payload.attributes.joinAt)
        dispatch(
          actionKickOutDifferentAdvisor(payload.attributes.participantId)
        );
      break;
    case JOIN_AGAIN_AS_CLIENT:
      dispatch(actionJoinAgainAsClient());
      break;
    case USER_INFO:
      handleUserInfo(payload, dispatch, clientState);
      break;
    case REQUEST_ADVISOR_INFO:
      handleRequestAdvisorInfo(payload, dispatch);
      break;
    case REQUEST_ADMITTANCE:
      handleRequestAdmittance(
        payload,
        dispatch,
        clientState.meeting?.state,
        clientState.meeting?.autoAdmit
      );
      break;
    case RAISE_HAND:
      handleRaiseHand(payload, dispatch);
      break;
    case PLAYER_STATUS:
      handlePlayerStatus(payload, dispatch);
      break;
    case REMOTE_CONTROL: {
      const action: AnyAction | undefined = getActionFromPayload(
        payload,
        clientState
      );
      let shouldMirrorRemoteAction = false;
      const meetingState = clientState.meeting?.state;
      if (meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE)
        shouldMirrorRemoteAction = true;
      else if (meetingState === ParticipantMeetingState.IN_THE_LOUNGE) {
        shouldMirrorRemoteAction = payload.value !== MeetingCommand.OPEN_POPUP;
      }

      if (action && shouldMirrorRemoteAction) {
        dispatch(action);
      }
      break;
    }

    case REQUEST_TO_BE_PRESENTER: {
      const meetingState = clientState.meeting || {};
      const file: IFile = payload.attributes.file;
      const requester = payload.attributes.participantId;
      handleRequestToBePresenter(file, requester, meetingState, dispatch);

      break;
    }

    case REQUEST_UPDATE_FILE_VIEWER_LAYOUT: {
      const fileViewerState = clientState.fileViewer || {};
      const requester = payload.attributes.participantId;
      if (fileViewerState?.controller === requester) {
        dispatch(actionUpdateFileViewerLayout(payload.attributes.layout));
      }
      break;
    }

    case REQUEST_UPDATE_PLAYER_CONTROL: {
      const fileViewerState = clientState.fileViewer || {};
      const requester = payload.attributes.participantId;
      if (fileViewerState?.controller === requester || isSSP) {
        dispatch(actionUpdatePlayerControl(payload.attributes.control));
      }
      break;
    }
    case PARTICIPANT_DID_SET_ACTIVE_PAGE: {
      const controller = clientState.fileViewer.controller;
      const participant = payload.attributes.participantId;
      const activeFile = clientState.fileViewer.sbFromMeeting;
      if (controller === participant) {
        const activePage = payload.attributes.activePage;
        const { currentPage, nextUrl, prevUrl } = activePage;
        if (!currentPage) {
          return dispatch(actionSetActivePage(currentPage, nextUrl, prevUrl));
        }
        if (activeFile && activeFile.id === currentPage.id) {
          dispatch(
            actionSetActivePage(
              getCurrentPage(activeFile, currentPage.url),
              nextUrl,
              prevUrl
            )
          );
        } else {
          handleLoadStorybook(currentPage.id, dispatch)
            .then((sb: IFile) => {
              dispatch(
                actionSetActivePage(
                  getCurrentPage(sb, currentPage.url),
                  nextUrl,
                  prevUrl
                )
              );
            })
            .catch((error) => {
              console.log('debug:: failed to load storybook', error);
            });
        }
      }
      break;
    }

    default:
      break;
  }
};
