import React from 'react';
import { useTranslation } from '../../i18n';

export const LoubiAirFontFamily = () => {
  const { i18n } = useTranslation();
  if (i18n.language === 'ja') {
    return `'Noto Sans JP', sans-serif`;
  }
  return `'AntiqueOlive-Bold', Arial`;
};

const LouboutinStyle = () => (
  <style jsx global>{`
    .vb-lite {
      background-size: 100% auto !important;
    }

    h1 {
      text-shadow: 0 0 20px #000;
    }

    @media (min-width: 1400px), (min-width: 1024px) and (max-height: 800px) {
      .vb-lite {
        background-size: auto 100% !important;
        background-position: left center !important;
      }
      .options-container {
        left: auto !important;
        right: 10%;
      }
    }
  `}</style>
);

export default LouboutinStyle;
