import { getMeetingRole } from './../utils/meeting';
import { uniqBy } from 'lodash';
import { updateNudgeStatus } from '../advisorHub/clientSideServices/nudge';
import {
  getInterestedProducts,
  getProductsByIds,
  getSimilarProducts
} from '../clientSideServices/products';
import { fetchSessionFiles } from '../clientSideServices/sessions';
import { getBrandDevices, getStoreDevices } from '../clientSideServices/store';
import {
  createUser,
  getUserByIdentityId,
  getUserEntitlements,
  saveUser,
  storeUserAssociatedStoreId
} from '../clientSideServices/users';
import {
  getStoreChatConfig,
  getStoreJourneyConfig
} from '../clientSideServices/virtualboutique';
import {
  fetchWishlist,
  getDefaultPayload,
  saveWishlist
} from '../clientSideServices/wishlist';
import { inspifySiteId } from '../config';
import {
  ClientState,
  IInspiration,
  IMainState,
  IMeetingLocalUser,
  IProduct,
  IUser,
  IWishlistItem,
  IWishlistPayload,
  JitsiLocalTrack,
  NudgeStatus,
  ParticipantMeetingState,
  WishlistType,
  WritableUserAttributes
} from '../interfaces';
import { getIdentityId } from '../utils/identity';
import { IStoreDevice, MeetingRole } from './../interfaces/index';
import {
  actionBrowseStorybookInMeeting,
  actionDidAddLocalTrack,
  actionDidGetStoreDevices,
  actionDidLoadFileList,
  actionDidLoadLocalUser,
  actionDidLoadLocalUserFailed,
  actionDidLoadRelatedProductsForInspiration,
  actionDidLoadSimilarProducts,
  actionDidLoadStoreChatConfig,
  actionDidLoadStoreJourneyConfig,
  actionFinishLoadingRelatedProductsForInspiration,
  actionFinishLoadingSimilarProducts,
  actionLocalTrackAudioMuteDidChange,
  actionLocalTrackVideoMuteDidChange,
  actionNotifyCamError,
  actionNotifyMicError,
  actionStartLoadingRelatedProductsForInspiration,
  actionStartLoadingSimilarProducts,
  actionUpdateLocalUser,
  actionUpdateParticipantInterestedProducts,
  actionUpdateParticipantWishlistProducts,
  actionUpdateWishlist,
  DID_LOAD_LOCAL_USER_FAILED,
  DID_UPDATE_LOCAL_USER
} from './actions';

import {
  createLocalTracksF,
  disposeTrackIfNeeded,
  getLocalAudioTrack,
  getLocalVideoTrack,
  getVideoStreamEffects
} from '../components/Meeting/utils/track';
import { logEvent } from '../analytics';
import { hasValidSession } from '../advisorHub/clientSideServices/login';
import { getHubUrl } from '../advisorHub/utils/hubPagesRoute';
import { popupwindow } from '../utils/window';
import {
  DID_FAIL_TO_CREATE_AUDIO_TRACK,
  DID_FAIL_TO_CREATE_VIDEO_TRACK
} from '../utils/constants';
import { LOCAL_TRACK_STOPPED, MEETING_DEBUG } from '../utils/events/meeting';

export const actionFetchWishlistAsync = (
  identityId: string | undefined | null,
  storeId: string | undefined
) => {
  return (dispatch) => {
    if (!identityId) return null;
    return fetchWishlist(identityId, storeId)
      .then((result) => {
        if (!result.data.errorMessage)
          return dispatch(actionUpdateWishlist(result.data));
      })
      .catch(() => {
        console.log(`fetchWishlist failed for identityId ${identityId}`);
      });
  };
};

export const actionRemoveFromWishlistAsync = (
  item: IWishlistItem,
  storeId: string,
  brandId: string
) => {
  return (dispatch, getState) => {
    const currentWishlist =
      (getState() as IMainState).clientState.wishlist ||
      getDefaultPayload(storeId, brandId);
    const updatedWishlist = {
      ...currentWishlist,
      wishlistItems: currentWishlist.wishlistItems.filter(
        (i) => i.itemId !== item.itemId
      )
    };
    return saveWishlist(updatedWishlist).then(() => {
      return dispatch(actionUpdateWishlist(updatedWishlist));
    });
  };
};

export const actionAddToWishlistAsync = (
  item: IWishlistItem,
  storeId: string,
  brandId: string
) => {
  return (dispatch, getState) => {
    const currentWishlist =
      (getState() as IMainState).clientState.wishlist ||
      getDefaultPayload(storeId, brandId);
    const updatedWishlist = {
      ...currentWishlist,
      wishlistItems: uniqBy([...currentWishlist.wishlistItems, item], 'itemId')
    };
    return saveWishlist(updatedWishlist).then(() => {
      return dispatch(actionUpdateWishlist(updatedWishlist));
    });
  };
};

export const actionFetchParticipantWishlistAsync = (
  participantId: string,
  identityId: string | undefined | null,
  storeId: string | undefined
) => {
  return (dispatch) => {
    if (!identityId) return null;
    return fetchWishlist(identityId, storeId)
      .then((result) => {
        const productIds =
          (result.data as IWishlistPayload).wishlistItems
            ?.filter((item) => item.itemType === WishlistType.PRODUCT)
            .map((item) => item.itemId) || [];
        return getProductsByIds(productIds);
      })
      .then((result) => {
        return dispatch(
          actionUpdateParticipantWishlistProducts(participantId, result.data)
        );
      })
      .catch((e) => {
        console.log(
          `actionFetchParticipantWishlistAsync failed for participant ${participantId}, identityId ${identityId}, storeId ${storeId}`
        );
        console.log(e);
      });
  };
};

export const actionFetchParticipantInterestedProductsAsync = (
  participantId: string,
  identityId: string,
  storeId: string
) => {
  return (dispatch) => {
    return getInterestedProducts(identityId, storeId)
      .then((result) => {
        if (result.data?.length) {
          const productIds = result.data.map((item) => item.productId) || [];
          return getProductsByIds(productIds);
        }
        throw new Error('No interested products fetched');
      })
      .then((result) => {
        return dispatch(
          actionUpdateParticipantInterestedProducts(participantId, result.data)
        );
      })
      .catch(console.error);
  };
};

const storeUserAssociatedStoreIdIfNeeded = (user: IUser) => {
  const associatedStoreId = user.entitlements?.find(
    (e) => e.entitlementType === 'ASSOCIATED_STORE_ID'
  )?.uuid;
  if (associatedStoreId) {
    storeUserAssociatedStoreId(associatedStoreId);
  }
};

export const actionFetchLocalUserAsync = (
  role: string | undefined = undefined
) => {
  return (dispatch) => {
    const identityId = getIdentityId();
    const meetingRole = getMeetingRole(role);
    return getUserByIdentityId(identityId)
      .then(async (result) => {
        if (!result.data['errorMessage']) {
          storeUserAssociatedStoreIdIfNeeded(result.data);
          if (meetingRole !== MeetingRole.ADVISOR) delete result.data.alias;
          let roleName;
          if (await hasValidSession()) {
            const entitlements = await getUserEntitlements();
            roleName = entitlements?.data?.[0]?.roles?.[0]?.name;
            return dispatch(
              actionDidLoadLocalUser({ ...result.data, role: roleName })
            );
          }
        }
      })
      .catch((e) => {
        dispatch(actionDidLoadLocalUserFailed());
        console.log(`cannot get user by id ${identityId}`, e);
      });
  };
};

export const actionFetchFilesAsync = (meetingId: string) => {
  return (dispatch) => {
    return fetchSessionFiles(meetingId)
      .then((result) => {
        if (!result.data['errorMessage']) {
          return dispatch(actionDidLoadFileList({ files: result.data }));
        }
      })
      .catch((e) => {
        console.log(`cannot get meeting files by id ${meetingId}`);
        console.log(e);
      });
  };
};

export const actionUpdateUserAttributesAsync = (
  identityId: string,
  attributes: WritableUserAttributes
) => {
  return (dispatch) => {
    return getUserByIdentityId(identityId)
      .then((result) => {
        const isAdvisorUser = result.data.userType?.toLowerCase() === 'sales';
        const updatedUser = { ...result.data, ...attributes };
        // if is advisor, only update local user.
        if (isAdvisorUser) {
          return dispatch(actionUpdateLocalUser(updatedUser));
        }
        // else update local and remote user.
        logEvent(DID_UPDATE_LOCAL_USER, DID_UPDATE_LOCAL_USER, {
          originalUserData: result.data,
          newUserData: updatedUser
        });

        return saveUser(updatedUser)
          .then(() => {
            return dispatch(actionUpdateLocalUser(updatedUser));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        logEvent(DID_LOAD_LOCAL_USER_FAILED, DID_LOAD_LOCAL_USER_FAILED, {
          error: err
        });
        //create new user
        const newUser = {
          ...createUser(identityId, attributes.alias || ''),
          ...attributes
        };
        return saveUser(newUser)
          .then(() => {
            return dispatch(actionUpdateLocalUser(newUser));
          })
          .catch((err) => console.error(err));
      });
  };
};

export const actionUpdateLocalUserAsync = (user: IUser) => {
  return (dispatch, getState) => {
    const currentUser = (getState() as IMainState).clientState.user || {};
    const updatedUser: IUser = { ...currentUser, ...user };
    return saveUser(updatedUser)
      .then(() => {
        return dispatch(actionUpdateLocalUser(updatedUser));
      })
      .catch(console.error);
  };
};

export const actionFetchRelatedProductsForInspiration = (
  inspiration: IInspiration
) => {
  return (dispatch) => {
    dispatch(actionStartLoadingRelatedProductsForInspiration());
    //HUB-115 - limit the number of products to 20
    const maximumProducts = 20;
    const productIds = inspiration.productIds.slice(0, maximumProducts);
    return getProductsByIds(productIds)
      .then((result) => {
        //filter out products not have name
        const products = result?.data?.filter?.((product) =>
          Boolean(product.modelName)
        );
        return dispatch(
          actionDidLoadRelatedProductsForInspiration(inspiration.id, products)
        );
      })
      .catch((e) => {
        console.log(
          `getProductsByIds failed with error for IDs ${inspiration.productIds}`
        );
        console.log(e);
      })
      .finally(() => {
        dispatch(actionFinishLoadingRelatedProductsForInspiration());
      });
  };
};

export const actionFetchJourneyConfig = (storeId: string) => {
  return (dispatch) => {
    return getStoreJourneyConfig(storeId)
      .then((result) => {
        return dispatch(actionDidLoadStoreJourneyConfig(result.data));
      })
      .catch((e) => {
        console.log(
          `fetchJourneyConfig failed for storeId ${storeId} with error`
        );
        console.log(e);
      });
  };
};

export const actionFetchChatConfig = (storeId: string) => {
  return (dispatch) => {
    return getStoreChatConfig(storeId)
      .then((result) => {
        return dispatch(actionDidLoadStoreChatConfig(result.data));
      })
      .catch((e) => {
        console.log(`fetchChatConfig failed for storeId ${storeId} with error`);
        console.log(e);
      });
  };
};

export const actionFetchSimilarProducts = (product: IProduct) => {
  return (dispatch) => {
    dispatch(actionStartLoadingSimilarProducts());
    return getSimilarProducts(product.id)
      .then((result) => {
        const data = result.data || [];
        const filteredData = data.filter((product) => product.modelName);
        return dispatch(actionDidLoadSimilarProducts(product.id, filteredData));
      })
      .catch((_) => {
        //not handle error, it's no similar products
      })
      .finally(() => {
        dispatch(actionFinishLoadingSimilarProducts());
      });
  };
};

export const actionUpdateNudgeStatusForClientAsync = (
  nudgeId: string,
  status: NudgeStatus
) => {
  return () => {
    const payload = {
      id: nudgeId,
      customerId: getIdentityId(),
      status
    };
    return updateNudgeStatus(payload);
  };
};

export const inspifySspProXOfficeOne: IStoreDevice = {
  deviceId: '5fa3cc04',
  deviceType: 'SSP',
  name: 'Streaming Cube Pro X - Office',
  status: 'AVAILABLE'
};

export const inspifySspProXOfficeTwo: IStoreDevice = {
  deviceId: '5fa3cc2e',
  deviceType: 'SSP',
  name: 'Streaming Cube Pro X',
  status: 'AVAILABLE'
};

export const actionGetStreamingStudioForStoreAsync = () => {
  return (dispatch, getState) => {
    const storeId = (getState() as IMainState).clientState.store?.id;
    if (!storeId) {
      return Promise.resolve();
    }
    return getStoreDevices(storeId)
      .then((result) => {
        let storeDevices: IStoreDevice[] = result.data || [];
        if (storeId !== inspifySiteId) {
          dispatch(actionDidGetStoreDevices(storeDevices));
        } else {
          if (
            !storeDevices.some(
              (d) => d.deviceId === inspifySspProXOfficeOne.deviceId
            )
          ) {
            storeDevices = [...storeDevices, inspifySspProXOfficeOne];
          }
          if (
            !storeDevices.some(
              (d) => d.deviceId === inspifySspProXOfficeTwo.deviceId
            )
          ) {
            storeDevices = [...storeDevices, inspifySspProXOfficeTwo];
          }
          dispatch(actionDidGetStoreDevices(storeDevices));
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };
};

export const actionGetStreamingStudioForBrandAsync = (brandId: string) => {
  return (dispatch) => {
    if (!brandId) return Promise.resolve();
    return getBrandDevices(brandId)
      .then((result) => {
        const storeDevices: IStoreDevice[] = result.data || [];
        dispatch(
          actionDidGetStoreDevices(
            storeDevices?.filter((device) => device?.deviceType === 'SSP')
          )
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };
};

const getTrackInfo = (
  trackType: 'audio' | 'video',
  localUser: IMeetingLocalUser
) => {
  if (trackType === 'audio')
    return {
      currentTrack: getLocalAudioTrack(localUser?.tracks) || null,
      muteAction: actionLocalTrackAudioMuteDidChange,
      currentMute: localUser?.audioMuted,
      deviceId: localUser?.activeDevices?.microphone
    };
  else
    return {
      currentTrack: getLocalVideoTrack(localUser?.tracks) || null,
      muteAction: actionLocalTrackVideoMuteDidChange,
      currentMute: localUser?.videoMuted,
      deviceId: localUser?.activeDevices?.camera
    };
};
export const actionReplaceLocalTrackAsync = (
  newTrack: JitsiLocalTrack,
  type?: 'video' | 'audio'
) => {
  return async (dispatch, getState) => {
    const meeting = (getState() as IMainState)?.clientState?.meeting || {};
    const room = meeting.jitsi;
    const meetingState = meeting.state;
    const currentLocalTracks = meeting.localUser?.tracks;
    const trackType = type || newTrack?.getType?.();
    const { currentTrack, currentMute, muteAction } = getTrackInfo(
      trackType,
      meeting.localUser
    );
    const theOtherTrack = currentLocalTracks.find(
      (t) => t.getType() !== trackType
    );
    const updatedTracks = [newTrack, theOtherTrack].filter((t) => t);
    //as jitsi prevent first publish track with muted state
    //we need to mute it after publishing
    //else we can mute it before replacing
    const firstPublish = currentTrack === null;

    if (!firstPublish && currentMute && newTrack) {
      await newTrack.mute();
    }

    const callBack = () => {
      dispatch(actionDidAddLocalTrack(updatedTracks));
      disposeTrackIfNeeded(currentTrack);
      //if it is remove current track
      if (!newTrack) {
        dispatch(muteAction(true));
        return;
      }
      newTrack.addEventListener(
        JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
        () => {
          if (newTrack.videoType === 'desktop') return;
          logMeetingEvent(
            LOCAL_TRACK_STOPPED,
            { type: newTrack.getType() },
            getState().clientState
          );
          dispatch(actionLocalTrackDidStopAsync(newTrack));
        }
      );
      firstPublish && currentMute && newTrack.mute();
    };
    if (room && meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE)
      return room
        .replaceTrack(currentTrack, newTrack)
        .then(callBack)
        .catch((e) => console.error(e));
    callBack();
    return Promise.resolve();
  };
};
const logMeetingEvent = (
  event: string,
  payload: any,
  clientState: ClientState
) => {
  const meeting = clientState?.meeting || {};
  const userAlias = clientState?.user?.alias || '';
  logEvent(MEETING_DEBUG, event, {
    ...payload,
    meetingId: meeting?.meetingId,
    participantId: meeting?.localUser?.participantId,
    role: meeting?.localUser?.role,
    displayName: userAlias
  });
};
export const actionChangeInputDeviceAsync = (
  id: string,
  type: 'audio' | 'video',
  errorCallBack?: () => void,
  notify = true
) => {
  return async (dispatch, getState) => {
    const meeting = (getState() as IMainState)?.clientState?.meeting || {};
    const tracks = meeting?.localUser?.tracks;
    const currentTrack = tracks?.find((t) => t.getType() === type);
    if (id === currentTrack?.getDeviceId()) {
      console.log("debug:: didn't change device id");
      return;
    }
    let effects = [];
    if (type === 'video') {
      const videoEffect = meeting?.localUser?.videoEffect?.effect;
      effects = await getVideoStreamEffects([videoEffect]);
    }

    const options = {
      devices: [type],
      effects,
      micDeviceId: type === 'audio' ? id : undefined,
      cameraDeviceId: type === 'video' ? id : undefined,
      timeout: 5000
    };
    console.log(
      `debug:: creating local ${type} track id: ${id} options:`,
      options
    );
    return createLocalTracksF(options)
      .then((tracks) => {
        if (tracks.length !== 0) {
          console.log(
            `debug:: created local ${type} track id: ${id}`,
            tracks[0]
          );
          dispatch(actionReplaceLocalTrackAsync(tracks[0]));
        }
      })
      .catch((err) => {
        console.error('fail to create track', err);
        errorCallBack?.();
        const action =
          type === 'audio' ? actionNotifyMicError : actionNotifyCamError;
        if (notify) dispatch(action(err));
        const event =
          type === 'audio'
            ? DID_FAIL_TO_CREATE_AUDIO_TRACK
            : DID_FAIL_TO_CREATE_VIDEO_TRACK;
        logMeetingEvent(event, { error: err }, getState().clientState);
      });
  };
};

export const actionToggleMuteLocalTrackAsync = (type: 'audio' | 'video') => {
  return (dispatch, getState) => {
    const meeting = (getState() as IMainState)?.clientState?.meeting || {};
    const { currentTrack, deviceId, muteAction, currentMute } = getTrackInfo(
      type,
      meeting.localUser
    );
    if (!currentTrack) {
      dispatch(muteAction(false));
      dispatch(
        actionChangeInputDeviceAsync(deviceId || 'default', type, () => {
          dispatch(muteAction(true));
        })
      );
    } else {
      if (!currentMute) {
        currentTrack.mute().then(() => {
          dispatch(muteAction(true));
        });
      } else {
        currentTrack
          .unmute()
          .then(() => {
            dispatch(muteAction(false));
          })
          .catch((err) => {
            const action =
              type === 'audio' ? actionNotifyMicError : actionNotifyCamError;
            dispatch(action(err));
          });
      }
    }
  };
};

export const actionLocalTrackDidStopAsync = (track: JitsiLocalTrack) => {
  return (dispatch, getState) => {
    const meeting = (getState() as IMainState)?.clientState?.meeting || {};
    const tracks = meeting?.localUser?.tracks;
    const type = track.getType();
    const deviceId = track.getDeviceId();
    const currentTrack = tracks?.find((t) => t.getDeviceId() === deviceId);
    if (!track || !currentTrack) return;
    disposeTrackIfNeeded(track);
    dispatch(
      actionChangeInputDeviceAsync(
        'default',
        type,
        () => {
          dispatch(actionReplaceLocalTrackAsync(null, type));
        },
        false
      )
    );
  };
};

export const actionBrowseStorybookInMeetingAsync = (
  onPopupOpened?: () => void,
  onSuccess?: () => void
) => {
  return (dispatch, getState) => {
    const state = getState() as IMainState;
    const brandId = state.clientState?.virtualBoutiqueConfig?.brandId;
    return hasValidSession().then((isValid) => {
      if (isValid) {
        dispatch(actionBrowseStorybookInMeeting(true));
        onSuccess?.();
        return;
      }

      setTimeout(() => {
        //open pop window
        const popup = popupwindow(getHubUrl(brandId), 'login', 500, 600);
        if (window.focus) popup?.focus?.();
        window['onLoginSuccess'] = () => {
          popup.close();
          onSuccess?.();
          dispatch(actionBrowseStorybookInMeeting(true));
        };
        popup.onload = () => onPopupOpened?.();
      });
    });
  };
};
