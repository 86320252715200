import { ISelectedModel, ChopardAnimation } from '../../interfaces/chopard';

export const UPDATE_CHOPARD_ANIMATION = 'CHOPARD.UPDATE_CHOPARD_ANIMATION';
export const UPDATE_CHOPARD_SELECTED_MODEL =
  'CHOPARD.UPDATE_CHOPARD_SELECTED_MODEL';
export const DID_RECEIVE_REMOTE_CHOPARD_ANIMATION_STATE =
  'CHOPARD.DID_RECEIVE_REMOTE_CHOPARD_ANIMATION_STATE';
export const UPDATE_CHOPARD_ACTIVE_ANIMATION_IN_PANO =
  'CHOPARD.UPDATE_CHOPARD_ACTIVE_ANIMATION_IN_PANO';
export const SHOW_CHOPARD_PRODUCT_DETAILS =
  'CHOPARD.SHOW_CHOPARD_PRODUCT_DETAILS';
export const SHOW_CHOPARD_STRAP_SELECTOR =
  'CHOPARD.SHOW_CHOPARD_STRAP_SELECTOR';
export const DID_SCROLL_PRODUCT_DETAILS =
  'CHOPARD.DID_SCROLL_PRODUCT_DETAILS';

export const actionDidUpdateChopardAnimation = (
  animation: ChopardAnimation,
  state: any
) => ({
  type: UPDATE_CHOPARD_ANIMATION,
  payload: { animation, state }
});

export const actionDidReceiveRemoteChopardAnimationState = (
  animation: ChopardAnimation,
  state: any
) => ({
  type: DID_RECEIVE_REMOTE_CHOPARD_ANIMATION_STATE,
  payload: { animation, state }
});

export const actionUpdateChopardActiveAnimationInPano = (
  animation: ChopardAnimation
) => ({
  type: UPDATE_CHOPARD_ACTIVE_ANIMATION_IN_PANO,
  payload: { animation }
});

export const actionUpdateChopardSelectedModel = (
  selectedModel: ISelectedModel
) => ({
  type: UPDATE_CHOPARD_SELECTED_MODEL,
  payload: { selectedModel }
});

export const actionShowChopardProductDetails = (payload: boolean) => ({
  type: SHOW_CHOPARD_PRODUCT_DETAILS,
  payload
});

export const actionShowChopardStrapSelector = (payload: boolean) => ({
  type: SHOW_CHOPARD_STRAP_SELECTOR,
  payload
});

export const actionDidScrollProductDetails = (payload: number) => ({
  type: DID_SCROLL_PRODUCT_DETAILS,
  payload
});
