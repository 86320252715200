import apigClientFactory from 'aws-api-gateway-client';
import AWS from 'aws-sdk';
import { baseUrl, region } from '../../config';

export const getCredentials = (): Promise<AWS.Credentials> => {
  return new AWS.CredentialProviderChain().resolvePromise();
};

export const getS3Instance = (): Promise<AWS.S3> => {
  return getCredentials().then(
    (credentials) =>
      new AWS.S3({
        region,
        credentials
      })
  );
};

export const apigClient = async (url?: string) => {
  const invokeUrl = url || baseUrl;
  const config = {
    region,
    invokeUrl: invokeUrl,
    defaultContentType: 'application/json',
    defaultAcceptType: 'application/json'
  };

  const credentials = await getCredentials();  
  const client = apigClientFactory.newClient({
    ...config,
    accessKey: credentials.accessKeyId,
    secretKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken
  });

  return client;
};
