import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import {
  CLICK_PRODUCT_COLOR,
  DID_LOAD_PRODUCT,
  DID_SELECT_PRODUCT,
  SHOW_PRODUCT_DESCRIPTION,
  SHOW_PRODUCT_SPECIFICATIONS,
  UPDATE_SHOWING_DESCRIPTION_FOR_PRODUCT_ID,
  SET_PRODUCT_AR_VIEWER
} from '../actions';
import { defaultClientState } from './clientReducer';

const productDetailsReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case CLICK_PRODUCT_COLOR: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          currentIndex: action.payload
        }
      };
    }
    case SHOW_PRODUCT_DESCRIPTION: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          showDescription: action.payload
        }
      };
    }
    case SHOW_PRODUCT_SPECIFICATIONS: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          showSpecifications: action.payload
        }
      };
    }
    case DID_LOAD_PRODUCT: {
      return {
        ...state,
        product: {
          ...(state.product || {}),
          loadedProducts: [
            ...(state.product?.loadedProducts || []),
            ...action.payload
          ]
        }
      };
    }

    case DID_SELECT_PRODUCT: {
      return {
        ...state,
        product: {
          ...(state.product || {}),
          selectedProductId: action.payload
        }
      };
    }

    case UPDATE_SHOWING_DESCRIPTION_FOR_PRODUCT_ID: {
      return {
        ...state,
        product: {
          ...(state.product || {}),
          showingDescriptionForProductId: action.payload
        }
      };
    }

    case SET_PRODUCT_AR_VIEWER: {
      return {
        ...state,
        productDetails: {
          ...(state.product || {}),
          arViewer: action.payload
        }
      };
    }

    default:
      return state;
  }
};

export default productDetailsReducer;
