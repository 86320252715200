import { AnyAction } from 'redux';
import {
  ClientState,
  JitsiEndpointMessage,
  JitsiMeetingRoom,
  MeetingRole
} from '../../../../interfaces';
import {
  ADD_LAYOUT_PRESENTER,
  ADMIT_LATE_COMER_TO_MEETING,
  DID_RECEIVE_REQUEST_USER_INFO,
  KICK_PARTICIPANT_FROM_MEETING,
  MUTE_PARTICIPANT_AUDIO_TRACK,
  REMOVE_LAYOUT_PRESENTER,
  REQUEST_PARTICIPANT_ADVISOR_INFO,
  REQUEST_TO_BE_ADMITTED_IN_MEETING,
  ROTATE_STUDIO_VIDEO,
  SEND_USER_INFO,
  TOGGLE_ENLARGE_VIDEO,
  TOGGLE_PARTICIPANT_VISIBILITY,
  TOGGLE_RAISE_HAND,
  UNMUTE_ALL_PARTICIPANTS_AUDIO_TRACK,
  UNMUTE_PARTICIPANT_AUDIO_TRACK,
  UPDATE_LAYOUT_MODE,
  ZOOM_STUDIO_VIDEO
} from '../../../../redux/actions';
import { getIdentityId } from '../../../../utils/identity';
import {
  ADMIT_INTO_MEETING,
  KICK_PARTICIPANT,
  MeetingCommand,
  MUTE_AUDIO_TRACK,
  RAISE_HAND,
  REMOTE_CONTROL,
  REQUEST_ADMITTANCE,
  REQUEST_ADVISOR_INFO,
  tryToSendMessage,
  UNMUTE_ALL_AUDIO_TRACK,
  UNMUTE_AUDIO_TRACK,
  USER_INFO
} from '../../commands';

const getEndpointMessageFromAction = (
  action: AnyAction,
  participantId: string | undefined,
  clientState: ClientState
): JitsiEndpointMessage | undefined => {
  switch (action.type) {
    case TOGGLE_ENLARGE_VIDEO:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.ENLARGE_VIDEO,
        attributes: action.payload
      };
    case TOGGLE_PARTICIPANT_VISIBILITY:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.TOGGLE_PARTICIPANT_VISIBILITY,
        attributes: action.payload
      };
    case UPDATE_LAYOUT_MODE:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_LAYOUT_MODE,
        attributes: action.payload
      };
    case ADMIT_LATE_COMER_TO_MEETING: {
      const { participantId, meetingState } = action.payload;
      return {
        type: ADMIT_INTO_MEETING,
        attributes: {
          participantId,
          meetingState,
          to: participantId
        }
      };
    }
    case ZOOM_STUDIO_VIDEO: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_STUDIO_VIDEO_ZOOM_LEVEL,
        attributes: action.payload
      };
    }
    case ROTATE_STUDIO_VIDEO: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_STUDIO_VIDEO_ROTATE_DEGREE,
        attributes: action.payload
      };
    }

    case ADD_LAYOUT_PRESENTER: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.ADD_LAYOUT_PRESENTER,
        attributes: action.payload
      };
    }

    case REMOVE_LAYOUT_PRESENTER: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.REMOVE_LAYOUT_PRESENTER,
        attributes: action.payload
      };
    }

    case KICK_PARTICIPANT_FROM_MEETING: {
      return {
        type: KICK_PARTICIPANT,
        attributes: {
          to: action.payload.participantId
        }
      };
    }

    case TOGGLE_RAISE_HAND: {
      return {
        type: RAISE_HAND,
        attributes: {
          participantId: action.payload
        }
      };
    }

    case MUTE_PARTICIPANT_AUDIO_TRACK: {
      return {
        type: MUTE_AUDIO_TRACK,
        attributes: {
          to:
            action.payload.participantId === 'all'
              ? ''
              : action.payload.participantId
        }
      };
    }

    case UNMUTE_PARTICIPANT_AUDIO_TRACK: {
      return {
        type: UNMUTE_AUDIO_TRACK,
        attributes: {
          to: action.payload.participantId
        }
      };
    }

    case UNMUTE_ALL_PARTICIPANTS_AUDIO_TRACK: {
      return {
        type: UNMUTE_ALL_AUDIO_TRACK,
        attributes: {}
      };
    }

    case SEND_USER_INFO:
    case DID_RECEIVE_REQUEST_USER_INFO:
      return {
        type: USER_INFO,
        value: undefined,
        attributes: {
          participantId,
          identityId: getIdentityId(),
          role: MeetingRole.MC,
          oldParticipantId: clientState?.meeting?.reconnect?.oldParticipantId
        }
      };
    case REQUEST_PARTICIPANT_ADVISOR_INFO:
      return {
        type: REQUEST_ADVISOR_INFO,
        attributes: {
          participantId
        }
      };

    case REQUEST_TO_BE_ADMITTED_IN_MEETING:
      return {
        type: REQUEST_ADMITTANCE,
        attributes: {
          participantId: participantId,
          displayName: clientState.user?.alias
        }
      };
    default:
      return;
  }
};

export const sendMeetingControllerCommandForAction = (
  room: JitsiMeetingRoom,
  action: AnyAction,
  clientState: ClientState
) => {
  const message = getEndpointMessageFromAction(
    action,
    room.myUserId(),
    clientState
  );
  if (message) {
    console.log('sendMeetingControllerCommandForAction - sending message');
    console.log(message);
    tryToSendMessage(room, message);
  }
};
