import { uniqBy } from 'lodash';
import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import {
  LOAD_FILE_LIST,
  SET_ACTIVE_FILE,
  SET_ACTIVE_PAGE,
  UPDATE_HIDDEN_PAGES,
  UPDATE_FILE_VIEWER_LAYOUT,
  UPDATE_PLAYER_CONTROL,
  UPDATE_LOCAL_PLAYER_CONTROL_CURRENT_SCENE,
  SHOW_STORYBOOK_IN_MEETING,
  SET_FILE_CONTROLLER
} from '../actions';
import { defaultClientState } from './clientReducer';

const fileViewerReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case LOAD_FILE_LIST: {
      const files = uniqBy(
        [
          ...(state.fileViewer?.files?.filter((file) => file.hideInMenu) || []),
          ...(action.payload?.files || [])
        ],
        'id'
      );
      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          ...action.payload,
          files
        }
      };
    }

    case SET_ACTIVE_FILE: {
      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          activeFile: action.payload
        }
      };
    }

    case SET_ACTIVE_PAGE: {
      const currentActiveFile = state.fileViewer?.activeFile;
      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          currentPage: action.payload?.currentPage || null,
          nextUrl: action.payload?.nextUrl || null,
          prevUrl: action.payload?.prevUrl || null,
          activeFile: !action.payload?.currentPage?.url
            ? null
            : currentActiveFile,
          playerControl: undefined,
          layoutMode: action.payload?.currentPage?.url
            ? state.fileViewer?.layoutMode
            : undefined
        },
        vb: {
          ...(state?.vb || {}),
          popup: {
            ...(state?.vb?.popup || {}),
            open: false,
            url: null
          }
        }
      };
    }

    case SET_FILE_CONTROLLER: {
      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          controller: action.payload
        }
      };
    }

    case SHOW_STORYBOOK_IN_MEETING: {
      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          sbFromMeeting: action.payload?.storyBook
        }
      };
    }

    case UPDATE_HIDDEN_PAGES: {
      const activeFile =
        state.fileViewer?.activeFile || state.fileViewer?.files?.[0]?.id;
      let currentFile = state.fileViewer?.files?.find(
        (file) => file.id === activeFile
      );
      const isSBFromMeeting = !currentFile && state.fileViewer?.sbFromMeeting;
      const copy = [...(state?.fileViewer?.files || [])];
      if (isSBFromMeeting) {
        currentFile = {
          ...state.fileViewer?.sbFromMeeting,
          hiddenPages: action.payload
        };
      } else {
        copy[copy.indexOf(currentFile)].hiddenPages = action.payload;
      }
      const field = isSBFromMeeting ? 'sbFromMeeting' : 'files';
      const value = isSBFromMeeting ? currentFile : copy;

      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          [field]: value
        }
      };
    }

    case UPDATE_FILE_VIEWER_LAYOUT: {
      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          layoutMode: action.payload
        }
      };
    }

    case UPDATE_LOCAL_PLAYER_CONTROL_CURRENT_SCENE: {
      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          playerControl: {
            ...(state.fileViewer?.playerControl || {}),
            currentScene: action.payload
          }
        }
      };
    }

    case UPDATE_PLAYER_CONTROL: {
      return {
        ...state,
        fileViewer: {
          ...(state.fileViewer || {}),
          playerControl: {
            ...(state.fileViewer?.playerControl || {}),
            ...action.payload
          }
        }
      };
    }

    default:
      return state;
  }
};

export default fileViewerReducer;
