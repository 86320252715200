import React from 'react';

const JLCVBStyle = () => (
  <style jsx global>{`
    .discoverbutton .label {
      padding: 0 8px !important;
      font-size: 11px !important;
    }
    body {
      font-family: 'Montserrat', Arial;
      font-size: 15px;
    }
    @media (min-width: 768px) {
      #chat-panel {
        font-size: 14px !important;
      }
    }
  `}</style>
);

export default JLCVBStyle;
