import { partition } from 'lodash';
import { AnyAction, Dispatch } from 'redux';
import { PanoView } from '../../interfaces';
import { IS_FLIPPING } from '../../utils/constants';
import { getKRPano } from '../../utils/krpano';
import { actionShowVBZoomIndicator, VB_DID_LOOK_TO_VIEW } from '../actions';

export const normalize = (degree: number, offset = 360) => {
  const mod = degree % offset;
  return mod < 0 ? mod + offset : mod;
};

export const isHotspotInHorizontalView = (
  hotspotH: number,
  currentViewH: number,
  hOffset: number
) => {
  const normalizedViewH = normalize(currentViewH);
  const normalizedHotspotH = normalize(hotspotH);
  const diff = Math.abs(normalizedViewH - normalizedHotspotH);
  if (diff > 180) {
    return 360 - diff < hOffset;
  }
  return diff < hOffset;
};

export const isHotspotInVerticalView = (
  hotspotV: number,
  currentViewV: number,
  vOffset: number
) => {
  const normalizedViewH = normalize(currentViewV);
  const normalizedHotspotH = normalize(hotspotV);
  const diff = Math.abs(normalizedViewH - normalizedHotspotH);
  if (diff > 180) {
    return 360 - diff < vOffset;
  }
  return diff < vOffset;
};

export const getAllProductHotspotsInScene = () => {
  const krpano = getKRPano();
  const totalHotspotsCount = krpano?.get('hotspot.count') || 0;
  const productHotspots = [];
  for (let i = 0; i < totalHotspotsCount; i++) {
    const hotspot = krpano.get(`hotspot[${i}]`);
    if (hotspot.style === 'hsfr') {
      productHotspots.push(hotspot);
    }
  }
  return productHotspots;
};

const handleProductHotspots = (view: PanoView, dispatch: Dispatch) => {
  if (window[IS_FLIPPING]) return;
  const krpano = getKRPano();
  const productHotspots = getAllProductHotspotsInScene();
  const hOffset = parseInt(krpano?.get('frame_range.hoffset') || 60);
  const vOffset = parseInt(krpano?.get('frame_range.voffset') || 45);
  const fovLimit = parseInt(krpano?.get('frame_range.zoom') || 125);
  let hotspotsInView = [];
  let hotspotsOutOfView = [];
  if (view.fov > fovLimit) {
    hotspotsOutOfView = [...productHotspots];
  } else {
    [hotspotsInView, hotspotsOutOfView] = partition(productHotspots, (hs) => {
      return (
        isHotspotInHorizontalView(hs.ath, view.viewH, hOffset) &&
        isHotspotInVerticalView(hs.atv, view.viewV, vOffset)
      );
    });
  }
  hotspotsInView.forEach((hs) => {
    krpano?.call(`tween(hotspot[${hs.name}].scale, 1, 0.3, easeoutback);`);
  });
  hotspotsOutOfView.forEach((hs) => {
    krpano?.call(`tween(hotspot[${hs.name}].scale, 0, 0.3, easeoutback);`);
  });
  const hotspotsInViewButTooFar = productHotspots.some((hs) => {
    return (
      isHotspotInHorizontalView(hs.ath, view.viewH, hOffset) &&
      isHotspotInVerticalView(hs.atv, view.viewV, vOffset) &&
      view.fov > fovLimit
    );
  });
  dispatch(actionShowVBZoomIndicator(hotspotsInViewButTooFar));
};

export const handleCommonAction = (
  action: AnyAction,
  dispatch: Dispatch<AnyAction>
) => {
  switch (action.type) {
    case VB_DID_LOOK_TO_VIEW:
      handleProductHotspots(action.payload, dispatch);
      break;
    default:
      break;
  }
};
