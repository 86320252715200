import { AnyAction, Dispatch } from 'redux';
import {
  ClientState,
  JitsiEndpointMessage,
  JitsiMeetingRoom,
  MeetingRole
} from '../../interfaces';
import { MIRROR_ACTION } from '../../redux/actions';
import { isOnClientSide } from '../../utils/window';
import { handleMeetingControllerEndpointMessage } from './Messaging/MeetingController/MeetingControllerCommandReceiver';
import { sendMeetingControllerCommandForAction } from './Messaging/MeetingController/MeetingControllerCommandSender';
import { handlePresenterEndpointMessage } from './Messaging/Presenter/PresenterCommandReceiver';
import { sendPresenterCommandForAction } from './Messaging/Presenter/PresenterCommandSender';
import { handleSpectatorEndpointMessage } from './Messaging/Spectator/SpectatorCommandReceiver';
import { sendSpectatorCommandForAction } from './Messaging/Spectator/SpectatorCommandSender';
import { sendCommandForMirroringAction } from './Messaging/Mirroring/MirroringSender';
import { handleMirroringEndpointMessage } from './Messaging/Mirroring/MirroringReceiver';
import { logEvent } from '../../analytics';
import {
  DID_FAIL_TO_SEND_END_POINT_MESSAGE,
  DID_RETRY_TO_SEND_END_POINT_MESSAGE
} from '../../utils/constants';

export enum MeetingCommand {
  MOVE = 'MOVE',
  CHANGE_VIEW = 'CHANGE_VIEW',
  CLOSE_POPUP = 'CLOSE_POPUP',
  OPEN_POPUP = 'OPEN_POPUP',
  POPUP_ACTION = 'POPUP_ACTION',
  ENLARGE_VIDEO = 'ENLARGE_VIDEO',
  TOGGLE_PARTICIPANT_VISIBILITY = 'TOGGLE_PARTICIPANT_VISIBILITY',
  SHOW_HOT_SPOT = 'SHOW_HOT_SPOT',
  HIDE_HOT_SPOT = 'HIDE_HOT_SPOT',
  CLICK_HOT_SPOT = 'CLICK_HOT_SPOT',
  APPOINTMENT_POPUP = 'APPOINTMENT_POPUP',
  HUD_MENU_CLICK = 'HUD_MENU_CLICK',
  UPDATE_LAYOUT_MODE = 'UPDATE_LAYOUT_MODE',
  UPDATE_STUDIO_VIDEO_ZOOM_LEVEL = 'UPDATE_STUDIO_VIDEO_ZOOM_LEVEL',
  UPDATE_STUDIO_VIDEO_ROTATE_DEGREE = 'UPDATE_STUDIO_VIDEO_ROTATE_DEGREE',
  FLIP_PANO = 'FLIP_PANO',
  RESET_AND_FLIP_PANO = 'RESET_AND_FLIP_PANO',
  UPDATE_LAYOUT = 'UPDATE_LAYOUT',
  ADD_LAYOUT_PRESENTER = 'ADD_LAYOUT_PRESENTER',
  REMOVE_LAYOUT_PRESENTER = 'REMOVE_LAYOUT_PRESENTER',
  UPDATE_MAP = 'UPDATE_MAP',
  TELEPORT_TO = 'TELEPORT_TO',
  MEETING_OVERLAY_IMAGE = 'MEETING_OVERLAY_IMAGE',
  UPDATE_ONE_THIRD_LAYOUT = 'UPDATE_ONE_THIRD_LAYOUT',
  EXIT_ONE_THIRD_LAYOUT = 'EXIT_ONE_THIRD_LAYOUT'
}

export const REMOTE_CONTROL = 'REMOTE_CONTROL';
export const REMOTE_CONTROL_ANIMATION = 'REMOTE_CONTROL_ANIMATION';
export const UPDATE_ACTIVE_ANIMATION = 'UPDATE_ACTIVE_ANIMATION';
export const UPDATE_SELECTED_MODEL = 'UPDATE_SELECTED_MODEL';
export const UPDATE_LOUBIAIRWAYS_FULL_SCREEN_VIDEO =
  'UPDATE_LOUBIAIRWAYS_FULL_SCREEN_VIDEO';
export const UPDATE_LOUBIAIRWAYS_PLAN = 'UPDATE_LOUBIAIRWAYS_PLAN';
export const UPDATE_LOUBIAIRWAYS_VISITED_PLAN =
  'UPDATE_LOUBIAIRWAYS_VISITED_PLAN';
export const PLAY_LOUBIAIRWAYS_INTRODUCTION_VIDEO_FOR_MEETING =
  'PLAY_LOUBIAIRWAYS_INTRODUCTION_VIDEO_FOR_MEETING';
export const RELAY_ACTION = 'RELAY_ACTION';
export const ADVISOR_END_MEETING = 'ADVISOR_END_MEETING';
export const TRANSFER_PRESENTER = 'TRANSFER_PRESENTER';
export const USER_INFO = 'USER_INFO';
export const ADVISOR_INFO = 'ADVISOR_INFO';
export const SYNC_MEETING_INFO = 'SYNC_MEETING_INFO';
export const JOIN_AGAIN_AS_CLIENT = 'JOIN_AGAIN_AS_CLIENT';
export const REQUEST_USER_INFO = 'REQUEST_USER_INFO';
export const REQUEST_ADVISOR_INFO = 'REQUEST_ADVISOR_INFO';
export const REQUEST_ADMITTANCE = 'REQUEST_ADMITTANCE';
export const ADMIT_INTO_MEETING = 'ADMIT_INTO_MEETING';
export const KICK_PARTICIPANT = 'KICK_PARTICIPANT';
export const MUTE_AUDIO_TRACK = 'MUTE_AUDIO_TRACK';
export const UNMUTE_AUDIO_TRACK = 'UNMUTE_AUDIO_TRACK';
export const UNMUTE_ALL_AUDIO_TRACK = 'UNMUTE_ALL_AUDIO_TRACK';
export const RAISE_HAND = 'RAISE_HAND';
export const PLAYER_STATUS = 'PLAYER_STATUS';
export const RAISE_HAND_APPROVED = 'RAISE_HAND_APPROVED';
export const DID_SEND_FILE_VIEWER_STATE = 'DID_SEND_FILE_VIEWER_STATE';
export const PAGE_SCROLL = 'PAGE_SCROLL';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const ACTIVE_PAGE = 'ACTIVE_PAGE';
export const PLAYER_STATE = 'PLAYER_STATE';
export const FILE_VIEWER_LAYOUT = 'FILE_VIEWER_LAYOUT';

export const CHOPARD_REMOTE_CONTROL_ANIMATION =
  'CHOPARD_REMOTE_CONTROL_ANIMATION';
export const CHOPARD_UPDATE_ACTIVE_ANIMATION =
  'CHOPARD_UPDATE_ACTIVE_ANIMATION';

export const COMMON3D_REMOTE_CONTROL_ANIMATION =
  'COMMON3D_REMOTE_CONTROL_ANIMATION';
export const COMMON3D_UPDATE_ACTIVE_ANIMATION =
  'COMMON3D_UPDATE_ACTIVE_ANIMATION';

export const CHOPARD_UPDATE_SELECTED_MODEL = 'CHOPARD_UPDATE_SELECTED_MODEL';

const getEndpointMessageHandlerByRole = (
  role: MeetingRole,
  payload: JitsiEndpointMessage,
  dispatch: Dispatch,
  clientState: ClientState
) => {
  switch (role) {
    case MeetingRole.ADVISOR:
      handlePresenterEndpointMessage(payload, dispatch, clientState);
      break;
    case MeetingRole.MC:
      handleMeetingControllerEndpointMessage(payload, dispatch, clientState);
      break;
    default:
      handleSpectatorEndpointMessage(payload, dispatch, clientState);
      break;
  }
};

export const handleEndpointMessage = (
  payload: JitsiEndpointMessage,
  dispatch: Dispatch,
  clientState: ClientState
) => {
  console.log(`handleEndpointMessage`, payload, new Date());
  if (payload.type === MIRROR_ACTION) {
    handleMirroringEndpointMessage(payload, dispatch);
  } else {
    getEndpointMessageHandlerByRole(
      clientState.meeting?.localUser?.role,
      payload,
      dispatch,
      clientState
    );
  }
};

export const tryToSendMessage = (
  room: JitsiMeetingRoom,
  message: JitsiEndpointMessage,
  retryCount = 0
) => {
  if (retryCount >= 10) {
    logEvent(
      DID_FAIL_TO_SEND_END_POINT_MESSAGE,
      DID_FAIL_TO_SEND_END_POINT_MESSAGE,
      {
        participantId: room.myUserId()
      }
    );
    return;
  }
  try {
    room.sendEndpointMessage(message.attributes.to || '', message);
  } catch (error) {
    logEvent(
      DID_RETRY_TO_SEND_END_POINT_MESSAGE,
      DID_RETRY_TO_SEND_END_POINT_MESSAGE,
      {
        participantId: room.myUserId(),
        error,
        retryCount: retryCount + 1
      }
    );
    setTimeout(() => {
      tryToSendMessage(room, message, retryCount + 1);
    }, 1000);
  }
};

const getSendCommandForActionByRole = (
  role: MeetingRole,
  clientState: ClientState,
  action: AnyAction,
  dispatch: Dispatch<AnyAction>,
  room: JitsiMeetingRoom
) => {
  switch (role) {
    case MeetingRole.ADVISOR:
      sendPresenterCommandForAction(room, action, clientState, dispatch);
      break;
    case MeetingRole.MC:
      sendMeetingControllerCommandForAction(room, action, clientState);
      break;
    default:
      sendSpectatorCommandForAction(room, action, clientState);
      break;
  }
};

export const sendCommandForAction = (
  clientState: ClientState,
  action: AnyAction,
  dispatch: Dispatch<AnyAction>
) => {
  // Move it out of window
  const room = isOnClientSide() && window['room'];
  if (!room) return;

  if (action.type === MIRROR_ACTION) {
    sendCommandForMirroringAction(room, action);
  } else {
    getSendCommandForActionByRole(
      clientState.meeting?.localUser?.role,
      clientState,
      action,
      dispatch,
      room
    );
  }
};
