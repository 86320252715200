import { last, pick } from 'lodash';
import { AnyAction, Dispatch } from 'redux';
import {
  chopardHappySportVirtualExpId,
  loubiAirwaysJpBoutiqueId,
  rogerDubuisSgBoutiqueId
} from '../../../../config';
import { rduHudMenuToMirrorInRemoteSelling } from '../../../../config/rduSg';
import { TRANSITIONED_FROM_WW2021_TO_MANUFACTURE } from '../../../../config/rduWW2021';
import {
  ClientState,
  JitsiEndpointMessage,
  JitsiMeetingRoom
} from '../../../../interfaces';
import {
  actionSetIsMeetingPresenter,
  ADD_LAYOUT_PRESENTER,
  ADMIT_LATE_COMER_TO_MEETING,
  ADVISOR_END_MEETING,
  APPROVE_RAISE_HAND,
  CART_ADD_PRODUCT,
  CART_CLEAR_PRODUCTS,
  CART_OPEN_POPUP,
  CART_REMOVE_PRODUCT,
  DID_RECEIVE_REQUEST_ADVISOR_INFO,
  DID_SELECT_PRODUCT,
  DID_SLIDE_PRODUCT_IMAGES_SLIDER,
  ENTER_MEETING,
  ENTER_MEETING_FROM_WELCOME_VIDEO,
  KICK_PARTICIPANT_FROM_MEETING,
  MUTE_PARTICIPANT_AUDIO_TRACK,
  PAGE_DID_SCROLL,
  PLAY_WELCOME_VIDEO_FROM_LOUNGE,
  REMOTE_POPUP_ACTION,
  REMOVE_LAYOUT_PRESENTER,
  REQUEST_PARTICIPANT_USER_INFO,
  ROTATE_STUDIO_VIDEO,
  SEND_ADVISOR_INFO,
  SET_ACTIVE_PAGE,
  SET_LAYOUT_STATE,
  TOGGLE_ENLARGE_VIDEO,
  TOGGLE_PARTICIPANT_VISIBILITY,
  TOGGLE_RAISE_HAND,
  TRANSFER_PRESENTER_ROLE,
  UNMUTE_ALL_PARTICIPANTS_AUDIO_TRACK,
  UNMUTE_PARTICIPANT_AUDIO_TRACK,
  UPDATE_FILE_VIEWER_LAYOUT,
  UPDATE_LAYOUT_MODE,
  UPDATE_PLAYER_CONTROL,
  UPDATE_SHOWING_DESCRIPTION_FOR_PRODUCT_ID,
  VB_APPOINTMENT_POPUP,
  VB_CLOSE_POPUP,
  VB_DID_CLICK_HUD_MENU,
  VB_DID_CLICK_ON_HOT_SPOT,
  VB_DID_GO_TO_SCENE,
  VB_DID_LOOK_TO_VIEW,
  VB_FLIP_PANO,
  VB_HIDE_HOT_SPOT,
  VB_OPEN_POPUP,
  VB_RELOAD_DEFAULT_PANO,
  VB_RESET_AND_FLIP_PANO,
  VB_SHOW_FULL_SCREEN_VIDEO_WITH_URL,
  VB_SHOW_HOT_SPOT,
  VB_UPDATE_BACKGROUND_MUSIC_URL,
  ZOOM_STUDIO_VIDEO,
  VB_UPDATE_MAP,
  TELEPORT_SELECT_SITE,
  SET_MEETING_DATE,
  RESYNC_MEETING_INFO,
  VB_UPDATE_LANGUAGE,
  JOIN_AS_ADVISOR,
  ADVISOR_INIT,
  KICK_OUT_DIFFERENT_ADVISOR,
  SET_FILE_CONTROLLER,
  UPDATE_ONE_THIRD_LAYOUT,
  EXIT_ONE_THIRD_LAYOUT
} from '../../../../redux/actions';
import {
  ADD_TO_WISHLIST,
  CLOSE_CATALOG,
  PLAY_INTRODUCTION_VIDEO_FOR_MEETING,
  REMOVE_FROM_WISHLIST,
  START_FETCHING_CATALOG,
  UPDATE_ACTIVE_ANIMATION_IN_PANO,
  UPDATE_FULL_SCREEN_VIDEO,
  UPDATE_LOUBI_AIRWAYS_ANIMATION,
  UPDATE_LOUBI_AIRWAYS_PLANE_PLAN,
  UPDATE_LOUBI_AIRWAYS_POPUP_CONTENT_TYPE,
  UPDATE_LOUBI_AIRWAYS_SELECTED_MODEL,
  UPDATE_VISITED_PLAN_SPOT
} from '../../../../redux/customActions/loubiAirways';

import {
  SHOW_CHOPARD_PRODUCT_DETAILS,
  UPDATE_CHOPARD_ACTIVE_ANIMATION_IN_PANO,
  UPDATE_CHOPARD_ANIMATION,
  UPDATE_CHOPARD_SELECTED_MODEL,
  DID_SCROLL_PRODUCT_DETAILS
} from '../../../../redux/customActions/chopard';

import { CLOSE_POPUP } from '../../../../redux/customActions/vca';

import { getIdentityId } from '../../../../utils/identity';
import {
  ACTIVE_PAGE,
  ADMIT_INTO_MEETING,
  ADVISOR_INFO,
  FILE_VIEWER_LAYOUT,
  KICK_PARTICIPANT,
  MeetingCommand,
  MUTE_AUDIO_TRACK,
  PLAYER_STATE,
  PLAY_LOUBIAIRWAYS_INTRODUCTION_VIDEO_FOR_MEETING,
  RAISE_HAND,
  RAISE_HAND_APPROVED,
  RELAY_ACTION,
  REMOTE_CONTROL,
  REMOTE_CONTROL_ANIMATION,
  REQUEST_USER_INFO,
  TRANSFER_PRESENTER,
  UNMUTE_ALL_AUDIO_TRACK,
  UNMUTE_AUDIO_TRACK,
  UPDATE_ACTIVE_ANIMATION,
  UPDATE_LOUBIAIRWAYS_FULL_SCREEN_VIDEO,
  UPDATE_LOUBIAIRWAYS_PLAN,
  UPDATE_LOUBIAIRWAYS_VISITED_PLAN,
  UPDATE_SELECTED_MODEL,
  CHOPARD_REMOTE_CONTROL_ANIMATION,
  CHOPARD_UPDATE_ACTIVE_ANIMATION,
  CHOPARD_UPDATE_SELECTED_MODEL,
  PAGE_SCROLL,
  COMMON3D_REMOTE_CONTROL_ANIMATION,
  COMMON3D_UPDATE_ACTIVE_ANIMATION,
  SYNC_MEETING_INFO,
  UPDATE_LANGUAGE,
  JOIN_AGAIN_AS_CLIENT,
  tryToSendMessage
} from '../../commands';
import {
  OPEN_POPUP,
  PLAY_LOUBIFUTURE_INTRODUCTION_VIDEO_FOR_MEETING,
  REPLAY_MUSIC_VIDEO,
  UPDATE_LANDING_VIDEO,
  UPDATE_LOUBI_FUTURE_CANVAS_STATE,
  UPDATE_MAP
} from '../../../../redux/customActions/loubiFuture';
import { getPageViewFromUrl, PageView } from '../../../../utils/pageView';
import { getCompareProductsUrlParam } from '../../../../utils/shareUrl';
import {
  UPDATE_COMMON3D_ACTIVE_ANIMATION_IN_PANO,
  UPDATE_COMMON3D_ANIMATION
} from '../../../../redux/customActions/common3DAction';

const getAdvisorState = (clientState: ClientState, participantId: string) => {
  const meeting = clientState.meeting;

  const loubiAirwaysState = pick(clientState.loubiAirways || {}, [
    'activeAnimation',
    'selectedModel',
    'planePlan',
    'fullScreenVideo'
  ]);

  const loubiFutureState = pick(clientState.loubiFuture || {}, [
    'canvasState',
    'map',
    'selectedProductId'
  ]);

  const chopardState = pick(clientState.chopard || {}, [
    'activeAnimation',
    'selectedModel'
  ]);

  const vcaState = pick(clientState.vca || {}, ['popup']);

  const common3DState = pick(clientState.common3D || {}, [
    'name',
    'animationState'
  ]);

  return {
    identityId: getIdentityId(),
    displayName: clientState.user?.alias,
    participantId,
    advisorMeetingState: meeting?.state,
    advisorSceneId: last(clientState.vb?.sceneIds || []),
    advisorView: clientState.vb?.currentView,
    layout: meeting?.layout,
    raiseHandParticipantIds: meeting?.raiseHand?.participantIds || [],
    remoteUsersInfo: Object.keys(meeting?.remoteUsers || {})
      .filter((participantId) => meeting?.remoteUsers[participantId]?.role)
      .map((participantId) => ({
        participantId,
        role: meeting?.remoteUsers[participantId]?.role,
        identityId: meeting?.remoteUsers[participantId]?.identityId
      })),
    loubiAirwaysState,
    chopardState,
    common3DState,
    loubiFutureState,
    vcaState,
    fullScreenVideo: clientState.vb?.fullScreenVideoWithUrl,
    transitionedToRDUManufacture:
      window[TRANSITIONED_FROM_WW2021_TO_MANUFACTURE],
    fileViewer: configureFileviewerState(clientState),
    map: clientState.vb.map,
    meetingDate: clientState?.meeting?.date,
    popupState: configurePopupState(clientState),
    teleportTo: clientState?.teleport?.teleportTo?.siteId,
    activeFeatures: clientState?.activeFeatures,
    languageCode: clientState?.meeting?.languageCode,
    isReconnecting: clientState?.meeting?.reconnect?.isReconnect,
    psFocusedObject: clientState?.vb?.pixelStreaming?.focusedObject,
    focusedItem: clientState?.focusedItem,
    isOverlayImgVisible: meeting?.isOverlayImgVisible
  };
};

export const formatActivePageMessage = (
  clientState: ClientState,
  currentPage: {
    url: string;
    setting?: string;
    file?: string;
    id: string;
    silence?: boolean;
  },
  nextUrl,
  prevUrl
) => {
  const fileId = currentPage?.id;
  const document = getDocumentInfo(clientState, fileId);
  const type = document?.type;
  if (!document || type !== 'storybook')
    return {
      currentPage,
      nextUrl,
      prevUrl
    };
  return {
    currentPage: {
      url: currentPage.url,
      id: currentPage.id,
      type: document.type
    },
    nextUrl,
    prevUrl
  };
};
const getDocumentInfo = (clientState: ClientState, fileId: string) => {
  if (!fileId) return;
  const state = clientState?.fileViewer;
  const files = state?.files || [];
  let currentFile = files.find((file) => file.id === fileId);
  if (!currentFile && state?.sbFromMeeting) {
    currentFile = state?.sbFromMeeting;
  }
  return currentFile;
};

export const getEndpointMessageFromAction = (
  action: AnyAction,
  participantId: string | undefined,
  clientState: ClientState
): JitsiEndpointMessage | undefined => {
  switch (action.type) {
    case ADVISOR_INIT:
      return {
        type: JOIN_AS_ADVISOR,
        attributes: {
          participantId,
          joinAt: clientState?.meeting?.joinAt
        }
      };
    case KICK_OUT_DIFFERENT_ADVISOR:
      return {
        type: JOIN_AGAIN_AS_CLIENT,
        attributes: {
          to: action.payload?.participantId
        }
      };
    case TELEPORT_SELECT_SITE:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.TELEPORT_TO,
        attributes: action.payload
      };
    case VB_CLOSE_POPUP:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.CLOSE_POPUP,
        attributes: {}
      };
    case VB_DID_GO_TO_SCENE:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.MOVE,
        attributes: {
          sceneId: action.payload
        }
      };
    case VB_DID_CLICK_ON_HOT_SPOT:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.CLICK_HOT_SPOT,
        attributes: action.payload
      };
    case VB_DID_LOOK_TO_VIEW:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.CHANGE_VIEW,
        attributes: {
          currentView: action.payload
        }
      };
    case VB_OPEN_POPUP:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.OPEN_POPUP,
        attributes: {
          ...action.payload
        }
      };
    case VB_UPDATE_LANGUAGE:
      return {
        type: UPDATE_LANGUAGE,
        attributes: action.payload
      };
    case REMOTE_POPUP_ACTION:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.POPUP_ACTION,
        attributes: action.payload
      };
    case TOGGLE_ENLARGE_VIDEO:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.ENLARGE_VIDEO,
        attributes: action.payload
      };
    case TOGGLE_PARTICIPANT_VISIBILITY:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.TOGGLE_PARTICIPANT_VISIBILITY,
        attributes: action.payload
      };
    case UPDATE_LAYOUT_MODE:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_LAYOUT_MODE,
        attributes: action.payload
      };
    case UPDATE_ONE_THIRD_LAYOUT:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_ONE_THIRD_LAYOUT,
        attributes: action.payload
      };
    case EXIT_ONE_THIRD_LAYOUT:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.EXIT_ONE_THIRD_LAYOUT,
        attributes: action.payload
      };

    case VB_FLIP_PANO:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.FLIP_PANO,
        attributes: action.payload
      };

    case VB_RESET_AND_FLIP_PANO:
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.RESET_AND_FLIP_PANO
      };
    case REQUEST_PARTICIPANT_USER_INFO: {
      return {
        type: REQUEST_USER_INFO,
        attributes: {}
      };
    }
    case VB_APPOINTMENT_POPUP: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.APPOINTMENT_POPUP,
        attributes: {
          ...action.payload
        }
      };
    }

    case VB_DID_CLICK_HUD_MENU: {
      if (rduHudMenuToMirrorInRemoteSelling.includes(action.payload)) {
        return {
          type: REMOTE_CONTROL,
          value: MeetingCommand.HUD_MENU_CLICK,
          attributes: action.payload
        };
      }
      return;
    }
    case VB_UPDATE_MAP: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_MAP,
        attributes: action.payload
      };
    }
    case DID_RECEIVE_REQUEST_ADVISOR_INFO:
    case SEND_ADVISOR_INFO: {
      return {
        type: ADVISOR_INFO,
        attributes: getAdvisorState(clientState, participantId)
      };
    }
    case RESYNC_MEETING_INFO: {
      return {
        type: SYNC_MEETING_INFO,
        attributes: getAdvisorState(clientState, participantId)
      };
    }
    case ENTER_MEETING: {
      return {
        type: ADMIT_INTO_MEETING,
        attributes: {
          participantId: 'all'
        }
      };
    }

    case SET_MEETING_DATE: {
      return {
        type: SET_MEETING_DATE,
        attributes: action.payload
      };
    }

    case ADMIT_LATE_COMER_TO_MEETING: {
      const { participantId, meetingState } = action.payload;
      return {
        type: ADMIT_INTO_MEETING,
        attributes: {
          participantId,
          meetingState,
          to: participantId
        }
      };
    }

    case VB_SHOW_HOT_SPOT: {
      if (clientState.store?.id === rogerDubuisSgBoutiqueId) {
        return;
      }
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.SHOW_HOT_SPOT,
        attributes: action.payload
      };
    }
    case VB_HIDE_HOT_SPOT: {
      if (clientState.store?.id === rogerDubuisSgBoutiqueId) {
        return;
      }
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.HIDE_HOT_SPOT,
        attributes: action.payload
      };
    }
    case ZOOM_STUDIO_VIDEO: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_STUDIO_VIDEO_ZOOM_LEVEL,
        attributes: action.payload
      };
    }
    case ROTATE_STUDIO_VIDEO: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_STUDIO_VIDEO_ROTATE_DEGREE,
        attributes: action.payload
      };
    }

    case SET_LAYOUT_STATE: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.UPDATE_LAYOUT,
        attributes: action.payload
      };
    }

    case ADD_LAYOUT_PRESENTER: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.ADD_LAYOUT_PRESENTER,
        attributes: action.payload
      };
    }

    case REMOVE_LAYOUT_PRESENTER: {
      return {
        type: REMOTE_CONTROL,
        value: MeetingCommand.REMOVE_LAYOUT_PRESENTER,
        attributes: action.payload
      };
    }

    case TRANSFER_PRESENTER_ROLE: {
      return {
        type: TRANSFER_PRESENTER,
        attributes: {
          to: action.payload.participantId
        }
      };
    }

    case KICK_PARTICIPANT_FROM_MEETING: {
      return {
        type: KICK_PARTICIPANT,
        attributes: {
          to: action.payload.participantId
        }
      };
    }

    case TOGGLE_RAISE_HAND: {
      return {
        type: RAISE_HAND,
        attributes: {
          participantId: action.payload
        }
      };
    }

    case APPROVE_RAISE_HAND: {
      return {
        type: RAISE_HAND_APPROVED,
        attributes: {
          participantId: action.payload
        }
      };
    }

    case MUTE_PARTICIPANT_AUDIO_TRACK: {
      return {
        type: MUTE_AUDIO_TRACK,
        attributes: {
          to:
            action.payload.participantId === 'all'
              ? ''
              : action.payload.participantId
        }
      };
    }

    case UNMUTE_PARTICIPANT_AUDIO_TRACK: {
      return {
        type: UNMUTE_AUDIO_TRACK,
        attributes: {
          to: action.payload.participantId
        }
      };
    }

    case SET_ACTIVE_PAGE: {
      return {
        type: ACTIVE_PAGE,
        attributes: formatActivePageMessage(
          clientState,
          action.payload?.currentPage,
          action.payload?.nextUrl,
          action.payload?.prevUrl
        )
      };
    }

    case UPDATE_PLAYER_CONTROL: {
      return {
        type: PLAYER_STATE,
        attributes: action.payload
      };
    }

    case UPDATE_FILE_VIEWER_LAYOUT: {
      return {
        type: FILE_VIEWER_LAYOUT,
        attributes: action.payload
      };
    }

    case UNMUTE_ALL_PARTICIPANTS_AUDIO_TRACK: {
      return {
        type: UNMUTE_ALL_AUDIO_TRACK,
        attributes: {}
      };
    }

    case UPDATE_LOUBI_AIRWAYS_ANIMATION: {
      return {
        type: REMOTE_CONTROL_ANIMATION,
        attributes: action.payload
      };
    }

    case UPDATE_CHOPARD_ANIMATION: {
      return {
        type: CHOPARD_REMOTE_CONTROL_ANIMATION,
        attributes: action.payload
      };
    }

    case UPDATE_COMMON3D_ANIMATION: {
      return {
        type: COMMON3D_REMOTE_CONTROL_ANIMATION,
        attributes: action.payload
      };
    }

    case UPDATE_COMMON3D_ACTIVE_ANIMATION_IN_PANO: {
      return {
        type: COMMON3D_UPDATE_ACTIVE_ANIMATION,
        attributes: action.payload
      };
    }

    case UPDATE_ACTIVE_ANIMATION_IN_PANO: {
      return {
        type: UPDATE_ACTIVE_ANIMATION,
        attributes: action.payload
      };
    }

    case UPDATE_CHOPARD_ACTIVE_ANIMATION_IN_PANO: {
      return {
        type: CHOPARD_UPDATE_ACTIVE_ANIMATION,
        attributes: action.payload
      };
    }

    case UPDATE_LOUBI_AIRWAYS_SELECTED_MODEL: {
      return {
        type: UPDATE_SELECTED_MODEL,
        attributes: action.payload
      };
    }

    case UPDATE_CHOPARD_SELECTED_MODEL: {
      return {
        type: CHOPARD_UPDATE_SELECTED_MODEL,
        attributes: action.payload
      };
    }

    case UPDATE_LOUBI_AIRWAYS_PLANE_PLAN: {
      return {
        type: UPDATE_LOUBIAIRWAYS_PLAN,
        attributes: action.payload
      };
    }
    case UPDATE_VISITED_PLAN_SPOT: {
      return {
        type: UPDATE_LOUBIAIRWAYS_VISITED_PLAN,
        attributes: action.payload
      };
    }
    case UPDATE_FULL_SCREEN_VIDEO: {
      return {
        type: UPDATE_LOUBIAIRWAYS_FULL_SCREEN_VIDEO,
        attributes: action.payload
      };
    }
    case PLAY_INTRODUCTION_VIDEO_FOR_MEETING: {
      return {
        type: PLAY_LOUBIAIRWAYS_INTRODUCTION_VIDEO_FOR_MEETING,
        attributes: action.payload
      };
    }
    case PAGE_DID_SCROLL: {
      return {
        type: PAGE_SCROLL,
        attributes: action.payload
      };
    }

    case SET_FILE_CONTROLLER:
    case SHOW_CHOPARD_PRODUCT_DETAILS:
    case DID_SCROLL_PRODUCT_DETAILS:
    case VB_RELOAD_DEFAULT_PANO:
    case ENTER_MEETING_FROM_WELCOME_VIDEO:
    case PLAY_WELCOME_VIDEO_FROM_LOUNGE:
    case VB_SHOW_FULL_SCREEN_VIDEO_WITH_URL:
    case VB_UPDATE_BACKGROUND_MUSIC_URL:
    case ADVISOR_END_MEETING:
    case UPDATE_LOUBI_AIRWAYS_POPUP_CONTENT_TYPE:
    case DID_SELECT_PRODUCT:
    case START_FETCHING_CATALOG:
    case CLOSE_CATALOG:
    case ADD_TO_WISHLIST:
    case REMOVE_FROM_WISHLIST:
    case DID_SLIDE_PRODUCT_IMAGES_SLIDER:
    case UPDATE_LOUBI_FUTURE_CANVAS_STATE:
    case PLAY_LOUBIFUTURE_INTRODUCTION_VIDEO_FOR_MEETING:
    case UPDATE_LANDING_VIDEO:
    case OPEN_POPUP:
    case UPDATE_MAP:
    case REPLAY_MUSIC_VIDEO:
    case CLOSE_POPUP:
    case UPDATE_SHOWING_DESCRIPTION_FOR_PRODUCT_ID: {
      return {
        type: RELAY_ACTION,
        attributes: action
      };
    }

    case CART_CLEAR_PRODUCTS:
    case CART_REMOVE_PRODUCT:
    case CART_ADD_PRODUCT:
    case CART_OPEN_POPUP: {
      if (
        clientState.store?.id === chopardHappySportVirtualExpId ||
        clientState.store?.id === loubiAirwaysJpBoutiqueId
      ) {
        return {
          type: RELAY_ACTION,
          attributes: action
        };
      }
      return;
    }
    default:
      return;
  }
};

const configureFileviewerState = (clientState: ClientState) => {
  return clientState.fileViewer
    ? {
        ...formatActivePageMessage(
          clientState,
          clientState.fileViewer?.currentPage,
          clientState.fileViewer?.nextUrl,
          clientState.fileViewer?.prevUrl
        ),
        layoutMode: clientState.fileViewer?.layoutMode,
        playerControl: clientState.fileViewer?.playerControl,
        controller: clientState.fileViewer?.controller
      }
    : undefined;
};

const configurePopupState = (clientState: ClientState) => {
  if (!clientState?.meeting?.popupState) return;
  if (!clientState?.meeting?.popupState?.url)
    return clientState?.meeting?.popupState;

  let popupUrl = clientState?.meeting?.popupState?.url;

  const currentPopupPage = getPageViewFromUrl(
    clientState.meeting?.popupState?.url
  );
  if (
    currentPopupPage === PageView.PRODUCT_SEARCH ||
    currentPopupPage === PageView.PRODUCT_COMPARE
  ) {
    if (clientState?.productsToCompare?.length) {
      const compareParam = getCompareProductsUrlParam(
        clientState?.productsToCompare
      );
      popupUrl += popupUrl.includes('?')
        ? compareParam
        : compareParam.replace('&compareProducts', '?compareProducts');
      if (clientState?.focusedItem?.section === 'compareProduct') {
        popupUrl += `&focused=${clientState?.focusedItem?.itemId}`;
      }
    }
  }

  if (currentPopupPage === PageView.PRODUCT_SEARCH) {
    if (clientState?.productSearch?.keywords) {
      popupUrl += `&keywords=${clientState?.productSearch?.keywords}`;
    }
    if (clientState?.productSearch?.compareMode) {
      if (!popupUrl.includes('compare=true')) popupUrl += '&compare=true';
    } else popupUrl = popupUrl.replace('compare=true', '');
  }

  return {
    ...(clientState?.meeting?.popupState || {}),
    url: popupUrl
  };
};

export const sendPresenterCommandForAction = (
  room: JitsiMeetingRoom,
  action: AnyAction,
  clientState: ClientState,
  dispatch: Dispatch<AnyAction>
) => {
  const message = getEndpointMessageFromAction(
    action,
    room.myUserId(),
    clientState
  );
  const remoteUsers = clientState.meeting?.remoteUsers || {};
  if (message && Object.keys(remoteUsers).length) {
    tryToSendMessage(room, message);
    if (message.type === TRANSFER_PRESENTER) {
      dispatch(actionSetIsMeetingPresenter(false));
    }

    if (action.type === ADMIT_LATE_COMER_TO_MEETING) {
      const msg = {
        type: ADVISOR_INFO,
        attributes: {
          to: action.payload.participantId,
          fileViewer: configureFileviewerState(clientState),
          popupState: configurePopupState(clientState),
          teleportTo: clientState?.teleport?.teleportTo?.siteId
        }
      };
      tryToSendMessage(room, msg);
    }
  }
};
