import { IAppContext } from '../components/AppContext';
import { getIdentityId } from './identity';
import { IComparedProduct } from '../interfaces';
import { getProductIdFromUrl } from './product';
import { getCurrentHost } from './window';
import httpClient from '../clientSideServices/http';

export enum ShareScenario {
  CHAT_NOW,
  COMPARE,
  GENERAL,
  PRODUCT,
  PERSONALIZE,
  STORYBOOK
}

export const getCurrentUrl = () =>
  `${window.location.origin}${window.location.pathname}`;

export const getCurrentUrlWithParams = () => window.location.href;

const getReferrer = () => {
  const identityId = getIdentityId();
  if (identityId) {
    return `referrer=website&referrerId=${identityId}`;
  } else {
    return `referrer=website`;
  }
};

const getShareUrlForChatNow = () => {
  return `${getCurrentUrl()}?${getReferrer()}`;
};

const getGeneralShareUrl = (context: IAppContext | undefined = undefined) => {
  const url = getCurrentUrl();
  const source = context?.source
    ? `source=${context?.source}`.toLowerCase()
    : null;
  const storeId = context?.store ? `store=${context.store.id}` : null;
  return `${url}?${[source, storeId, getReferrer()]
    .filter((i) => i)
    .join('&')}`;
};

const getShareUrlForProduct = (
  context: IAppContext | undefined = undefined,
  productId: string | undefined = undefined
) => {
  const url = getGeneralShareUrl(context);
  const currentProductId = getProductIdFromUrl(url);
  return url.replace(currentProductId, productId);
};

export const getPersonalizeUrlParam = (personalize: Personalize) => {
  if (!personalize) return '';
  const strapParam = personalize?.strap ? `&strap=${personalize.strap}` : '';
  const buckleParam = personalize?.buckle
    ? `&buckle=${personalize.buckle}`
    : '';

  if (buckleParam && !strapParam) return '';
  return `${strapParam}${buckleParam}`;
};

export const getCompareProductsUrlParam = (
  products: IComparedProduct[] | undefined
): string => {
  if (!products) return '';
  if (products.length === 0) return '';
  const type = products[0].type;
  const ids = products.map((p) => p.id).join(',');
  return `&compareProducts=${ids}&compareType=${type}`;
};

export const getShareUrlForStorybook = (context: IAppContext) => {
  try {
    const url = new URL(getCurrentUrlWithParams());
    const searchParams = url.searchParams;
    const source = context?.source?.toLocaleLowerCase?.();
    const store = context?.store;
    const referrerId = getIdentityId();
    const referrer = 'website';
    source && searchParams.set('source', source);
    store && searchParams.set('store', store.id);
    referrerId && searchParams.set('referrerId', referrerId);
    searchParams.set('referrer', referrer);
    return url.toString();
  } catch (error) {
    console.error('error while get share url', error);
    return getGeneralShareUrl(context);
  }
};

export const getShareUrl = (
  scenario: ShareScenario,
  context: IAppContext | undefined = undefined,
  query?: ShortenUrlQuery
) => {
  switch (scenario) {
    case ShareScenario.CHAT_NOW:
      return getShareUrlForChatNow();
    case ShareScenario.COMPARE:
      return `${getGeneralShareUrl(context)}${getCompareProductsUrlParam(
        query?.products
      )}`;
    case ShareScenario.PRODUCT:
      return getShareUrlForProduct(context, query?.productId);
    case ShareScenario.PERSONALIZE:
      return `${getGeneralShareUrl(context)}${getPersonalizeUrlParam(
        query?.personalize
      )}`;
    case ShareScenario.GENERAL:
      return getGeneralShareUrl(context);
    case ShareScenario.STORYBOOK:
      return getShareUrlForStorybook(context);
    default:
      break;
  }
};

interface Personalize {
  strap?: string;
  buckle?: string;
}

export interface ShortenUrlQuery {
  products?: IComparedProduct[];
  productId?: string;
  personalize?: Personalize;
}

export const getSimpleShortenedUrl = (
  originalUrl: string,
  hostUrl?: string
) => {
  return httpClient
    .post('/api/shortenUrl', {
      url: originalUrl
    })
    .then((result) => {
      const currentHost = `${hostUrl || getCurrentHost()}/links`;
      const originalShortenedUrl = new URL(result.data);
      return `${currentHost}${originalShortenedUrl.pathname}`;
    })
    .catch(() => {
      return originalUrl;
    });
};

export const getShortenedUrl = (
  scenario: ShareScenario,
  context: IAppContext | undefined = undefined,
  query?: ShortenUrlQuery
) => {
  const originalUrl = getShareUrl(scenario, context, query);
  return getSimpleShortenedUrl(originalUrl);
};
export const getShortenedUrlWithLang = (
  scenario: ShareScenario,
  context: IAppContext | undefined = undefined,
  query?: ShortenUrlQuery,
  lang?: string,
  externalUrl?: string
) => {
  console.log('getShortenedUrlWithLang', {
    externalUrl,
    lang
  });
  const originalUrl = externalUrl || getShareUrl(scenario, context, query);

  //set language params to original url if it does not have any language
  const url = new URL(originalUrl).searchParams.has('language')
    ? originalUrl
    : originalUrl + (lang && !externalUrl ? '&language=' + lang : '');

  return getSimpleShortenedUrl(url);
};
