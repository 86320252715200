import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import {
  VIEW_PAGE,
  CLICK_BACK_BUTTON,
  SET_INSPIRATIONS_LIST
} from '../actions';
import { defaultClientState } from './clientReducer';
import { last } from 'lodash';
import { PageView } from '../../utils/pageView';

const parentPathReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case VIEW_PAGE: {
      const lastPath = last(state.pathHistory);
      if (
        lastPath &&
        lastPath.pageView === action.payload.pageView &&
        (lastPath.pageView === PageView.INSPIRATION ||
          lastPath.url === action.payload.url)
      ) {
        return state;
      }
      return {
        ...state,
        pathHistory: [...state.pathHistory, action.payload]
      };
    }
    case CLICK_BACK_BUTTON: {
      const copy = state.pathHistory;
      copy.pop();
      return {
        ...state,
        pathHistory: copy
      };
    }
    case SET_INSPIRATIONS_LIST:
      return {
        ...state,
        inspirationsList: action.payload
      };
    default:
      return state;
  }
};

export default parentPathReducer;
