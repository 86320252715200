const amplify = {
  cognito: {
    REGION: 'ap-southeast-1',
    USER_POOL_ID: 'ap-southeast-1_shbaqwQz2',
    APP_CLIENT_ID: '69c2sbshhaiu703vpk08sn6ptk',
    IDENTITY_POOL_ID: 'ap-southeast-1:0267a27a-914b-4161-96f0-b9c6f9ea64d5'
  }
};

export const STREAM_TOKENS_ATTR = 'custom:live_stream_tokens';
export default amplify;
