import { ProductSearchFilter } from '../interfaces';

const stoneShape ={
  display: 'STONE SHAPE',
  filterGroup: 'stone_shape'
};

const stoneType ={
  display: 'HIGH JEWELLERY MATERIAL',
  filterGroup: 'material'
};

const itemType ={
  display: 'ITEM TYPE',
  filterGroup: 'category'
};

const caratRange ={
  display: 'CARAT RANGE',
  filterGroup: 'carat_range'
};

const priceBucket ={
  display: 'PRICE RANGE',
  filterGroup: 'price_range'
};

export const hjProductSearchFilters: ProductSearchFilter[] = [
  {
    filterName: {
      displayName: {
        en: stoneShape.display
      },
      cloudSearchField: stoneShape.filterGroup,
      cloudSearchGroup: stoneShape.filterGroup
    },
    filterOptions: [
      {
        displayName: {
          en: 'Round'
        },
        cloudSearchField: 'Round',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Rose'
        },
        cloudSearchField: 'Rose',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Briolette'
        },
        cloudSearchField: 'Briolette',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Buff Top'
        },
        cloudSearchField: 'Buff Top',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Beads'
        },
        cloudSearchField: 'Beads',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Cushion-shaped'
        },
        cloudSearchField: 'Cushion-shaped',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Cut‐Cornered'
        },
        cloudSearchField: 'Cut‐Cornered',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Emerald-cut'
        },
        cloudSearchField: 'Emerald-cut',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Heart-shaped'
        },
        cloudSearchField: 'Heart-shaped',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Marquise-cut'
        },
        cloudSearchField: 'Marquise-cut',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Pear-shaped'
        },
        cloudSearchField: 'Pear-shaped',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Princess-cut'
        },
        cloudSearchField: 'Princess-cut',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Square'
        },
        cloudSearchField: 'Square',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Rectangular'
        },
        cloudSearchField: 'Rectangular',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Oval-shaped'
        },
        cloudSearchField: 'Oval-shaped',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Asscher'
        },
        cloudSearchField: 'Asscher',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Cabochon'
        },
        cloudSearchField: 'Cabochon',
        cloudSearchGroup: stoneShape.filterGroup
      },
      {
        displayName: {
          en: 'Other'
        },
        cloudSearchField: 'Other',
        cloudSearchGroup: stoneShape.filterGroup
      }
    ]
  },
  {
    filterName: {
      displayName: {
        en: stoneType.display
      },
      cloudSearchField: stoneType.filterGroup,
      cloudSearchGroup: stoneType.filterGroup
    },
    filterOptions: [
      {
        displayName: {
          en: 'Diamond'
        },
        cloudSearchField: 'Diamond',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Coloured Diamond'
        },
        cloudSearchField: 'Coloured Diamond',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Brillant'
        },
        cloudSearchField: 'Brillant',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Granite'
        },
        cloudSearchField: 'Granite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Ceramic'
        },
        cloudSearchField: 'Ceramic',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Pearl'
        },
        cloudSearchField: 'Pearl',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Slate'
        },
        cloudSearchField: 'Slate',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Ruby'
        },
        cloudSearchField: 'Ruby',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Silicium'
        },
        cloudSearchField: 'Silicium',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Hematite'
        },
        cloudSearchField: 'Hematite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Sapphire'
        },
        cloudSearchField: 'Sapphire',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Emerald'
        },
        cloudSearchField: 'Emerald',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Natural Pearl'
        },
        cloudSearchField: 'Natural Pearl',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Cultured Pearl'
        },
        cloudSearchField: 'Cultured Pearl',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Keishi Pearl'
        },
        cloudSearchField: 'Keishi Pearl',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Mother of Pearl'
        },
        cloudSearchField: 'Mother of Pearl',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Amethyst'
        },
        cloudSearchField: 'Amethyst',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Aquamarine'
        },
        cloudSearchField: 'Aquamarine',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Garnet'
        },
        cloudSearchField: 'Garnet',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Opal'
        },
        cloudSearchField: 'Opal',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Fire Opal'
        },
        cloudSearchField: 'Fire Opal',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Peridot'
        },
        cloudSearchField: 'Peridot',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Spinel'
        },
        cloudSearchField: 'Spinel',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Topaz'
        },
        cloudSearchField: 'Topaz',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Turquoise'
        },
        cloudSearchField: 'Turquoise',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Alexandrite'
        },
        cloudSearchField: 'Alexandrite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Andalusite'
        },
        cloudSearchField: 'Andalusite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Apatite'
        },
        cloudSearchField: 'Apatite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Beryl'
        },
        cloudSearchField: 'Beryl',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Chalcedony (Carnelian)'
        },
        cloudSearchField: 'Chalcedony (Carnelian)',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: "Cat's Eye"
        },
        cloudSearchField: "Cat's Eye",
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Chalcedony'
        },
        cloudSearchField: 'Chalcedony',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Chrome-Diopside'
        },
        cloudSearchField: 'Chrome-Diopside',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Chrysoprase'
        },
        cloudSearchField: 'Chrysoprase',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Citrine'
        },
        cloudSearchField: 'Citrine',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Coral'
        },
        cloudSearchField: 'Coral',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Goldstone'
        },
        cloudSearchField: 'Goldstone',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Iolite'
        },
        cloudSearchField: 'Iolite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Ivory'
        },
        cloudSearchField: 'Ivory',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Jadeite'
        },
        cloudSearchField: 'Jadeite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Kunzite'
        },
        cloudSearchField: 'Kunzite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Lapis lazuli'
        },
        cloudSearchField: 'Lapis lazuli',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Malachite'
        },
        cloudSearchField: 'Malachite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Moonstone'
        },
        cloudSearchField: 'Moonstone',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Morganite'
        },
        cloudSearchField: 'Morganite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Chalcedony (Onyx)'
        },
        cloudSearchField: 'Chalcedony (Onyx)',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Tourmaline (Paraíba)'
        },
        cloudSearchField: 'Tourmaline (Paraíba)',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Quartz'
        },
        cloudSearchField: 'Quartz',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Rhodolite'
        },
        cloudSearchField: 'Rhodolite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Tourmaline (Rubellite)'
        },
        cloudSearchField: 'Tourmaline (Rubellite)',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Tanzanite'
        },
        cloudSearchField: 'Tanzanite',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: "Quartz (Tiger's Eye)"
        },
        cloudSearchField: "Quartz (Tiger's Eye)",
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Tourmaline'
        },
        cloudSearchField: 'Tourmaline',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Garnet (Tsavorite)'
        },
        cloudSearchField: 'Garnet (Tsavorite)',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Zircon'
        },
        cloudSearchField: 'Zircon',
        cloudSearchGroup: stoneType.filterGroup
      },
      {
        displayName: {
          en: 'Other'
        },
        cloudSearchField: 'Other',
        cloudSearchGroup: stoneType.filterGroup
      }
    ]
  },
  {
    filterName: {
      displayName: {
        en: itemType.display
      },
      cloudSearchField: itemType.filterGroup,
      cloudSearchGroup: itemType.filterGroup
    },
    filterOptions: [
      {
        displayName: {
          en: 'Ring'
        },
        cloudSearchField: 'Ring',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Pair of Earclips'
        },
        cloudSearchField: 'Pair of Earclips',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Pair of Earrings'
        },
        cloudSearchField: 'Pair of Earrings',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Necklace'
        },
        cloudSearchField: 'Necklace',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Watch Ladys Quartz'
        },
        cloudSearchField: 'Watch Ladys Quartz',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Pendant'
        },
        cloudSearchField: 'Pendant',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Bracelet'
        },
        cloudSearchField: 'Bracelet',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Watch Ladys Manual'
        },
        cloudSearchField: 'Watch Ladys Manual',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Watch Ladys Automatic'
        },
        cloudSearchField: 'Watch Ladys Automatic',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Jewellery Set'
        },
        cloudSearchField: 'Jewellery Set',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Loose stone, unmounted'
        },
        cloudSearchField: 'Loose stone, unmounted',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Watch Mens Quartz'
        },
        cloudSearchField: 'Watch Mens Quartz',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Cufflinks'
        },
        cloudSearchField: 'Cufflinks',
        cloudSearchGroup: itemType.filterGroup
      },
      {
        displayName: {
          en: 'Brooch'
        },
        cloudSearchField: 'Brooch',
        cloudSearchGroup: itemType.filterGroup
      }
    ]
  },
  {
    filterName: {
      displayName: {
        en: caratRange.display
      },
      cloudSearchField: caratRange.filterGroup,
      cloudSearchGroup: caratRange.filterGroup
    },
    filterOptions: [
      {
        displayName: {
          en: '<1 carat'
        },
        cloudSearchField: '<1ct',
        cloudSearchGroup: caratRange.filterGroup
      },
      {
        displayName: {
          en: '1 to 2'
        },
        cloudSearchField: '1-2ct',
        cloudSearchGroup: caratRange.filterGroup
      },
      {
        displayName: {
          en: '2 to 5'
        },
        cloudSearchField: '2-5ct',
        cloudSearchGroup: caratRange.filterGroup
      },
      {
        displayName: {
          en: '5 to 10'
        },
        cloudSearchField: '5-10ct',
        cloudSearchGroup: caratRange.filterGroup
      },
      {
        displayName: {
          en: '10 to 15'
        },
        cloudSearchField: '10-15ct',
        cloudSearchGroup: caratRange.filterGroup
      },
      {
        displayName: {
          en: '>15'
        },
        cloudSearchField: '15+ct',
        cloudSearchGroup: caratRange.filterGroup
      }
    ]
  },
  {
    filterName: {
      displayName: {
        en: priceBucket.display
      },
      cloudSearchField: priceBucket.filterGroup,
      cloudSearchGroup: priceBucket.filterGroup
    },
    filterOptions: [
      {
        displayName: {
          en: 'PRICE ON REQUEST'
        },
        cloudSearchField: 'null',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '0 - 20k CHF'
        },
        cloudSearchField: '0-20k_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '0 - 20k EUR'
        },
        cloudSearchField: '0-20k_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '0 - 20k USD'
        },
        cloudSearchField: '0-20k_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '20k - 50k CHF'
        },
        cloudSearchField: '20-50k_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '20k - 50k EUR'
        },
        cloudSearchField: '20-50k_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '20k - 50k USD'
        },
        cloudSearchField: '20-50k_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '50k - 100k CHF'
        },
        cloudSearchField: '50-100k_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '50k - 100k EUR'
        },
        cloudSearchField: '50-100k_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '50k - 100k USD'
        },
        cloudSearchField: '50-100k_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '100k - 150k CHF'
        },
        cloudSearchField: '100-150k_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '100k - 150k EUR'
        },
        cloudSearchField: '100-150k_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '100k - 150k USD'
        },
        cloudSearchField: '100-150k_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '150k - 200k CHF'
        },
        cloudSearchField: '150-200k_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '150k - 200k EUR'
        },
        cloudSearchField: '150-200k_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '150k - 200k USD'
        },
        cloudSearchField: '150-200k_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '200k - 300k CHF'
        },
        cloudSearchField: '200-300k_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '200k - 300k EUR'
        },
        cloudSearchField: '200-300k_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '200k - 300k USD'
        },
        cloudSearchField: '200-300k_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '300k - 500k CHF'
        },
        cloudSearchField: '300-500k_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '300k - 500k EUR'
        },
        cloudSearchField: '300-500k_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '300k - 500k USD'
        },
        cloudSearchField: '300-500k_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '500k - 750k CHF'
        },
        cloudSearchField: '500-750k_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '500k - 750k EUR'
        },
        cloudSearchField: '500-750k_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '500k - 750k USD'
        },
        cloudSearchField: '500-750k_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '750k - 1m CHF'
        },
        cloudSearchField: '750k-1m_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '750k - 1m EUR'
        },
        cloudSearchField: '750k-1m_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '750k - 1m USD'
        },
        cloudSearchField: '750k-1m_US',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '1m CHF +'
        },
        cloudSearchField: '1m+_CH',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '1m EUR +'
        },
        cloudSearchField: '1m+_EU',
        cloudSearchGroup: priceBucket.filterGroup
      },
      {
        displayName: {
          en: '1m USD +'
        },
        cloudSearchField: '1m+_US',
        cloudSearchGroup: priceBucket.filterGroup
      }
    ]
  }
];
