import React from 'react';

const RDUVBStyle = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'Rockwell';
      src: url('/asset/rdu/Rockwell.woff2') format('woff2'),
        url('/asset/rdu/Rockwell.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    .iframe-container {
      background: #000 !important;
    }
    .popup-page-content .close {
      color: #eaeaea !important;
    }

    .popup-page-content {
      box-shadow: 0 0 50px rgba(165, 31, 37, 0.7);
      background: #000;
    }

    @media (max-width: 769px) {
      .popup-page-content .close {
        color: #eaeaea !important;
      }
    }
  `}</style>
);

export const RDUTouchStyle = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'Rockwell';
      src: url('/asset/rdu/Rockwell.woff2') format('woff2'),
        url('/asset/rdu/Rockwell.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    html,
    body {
      font-family: 'Montserrat', 'Helvetica', 'sans-serif';
    }

    h1,
    h2,
    .light-text,
    .inspiration-page .copy strong,
    .products-landing .text-center p {
      font-family: 'Montserrat', 'Helvetica';
      font-weight: normal;
      letter-spacing: 0 !important;
      color: #eaeaea !important;
    }
    .inspiration-page,
    .dark-mode .inspiration-page .content {
      background: #000;
    }

    .dark-mode header {
      background-color: #000;
      border-color: #666;
    }

    .inspiration-page .row-container .content-container {
      background: #222;
    }
    .dark-mode header svg {
      fill: #eaeaea !important;
    }

    .dark-mode header .pagetitle {
      color: #eaeaea !important;
      font-family: 'Montserrat', 'Helvetica';
      font-weight: normal;
    }

    .inspiration-page .row-container .copy {
      color: #fff;
    }
    .inspiration-page nav {
      background: rgba(0, 0, 0, 0.7) !important;
    }
    .inspiration-page nav .btn {
      color: #ccc;
    }
    .product-landing .light-text {
      font-family: 'Montserrat', 'Helvetica', 'sans-serif' !important;
    }
    .wishlist-button.remove svg {
      fill: #a51f25 !important;
    }
    .slider-inner .avatar.rounded-circle {
      display: none !important;
    }
    @media (max-width: 768px) {
      .dark-mode header {
        background-color: #000 !important;
        border-color: #666 !important;
      }
      .dark-mode header svg {
        fill: #eaeaea !important;
      }
    }
  `}</style>
);
export default RDUVBStyle;
