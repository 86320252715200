import {
  ILoubiFutureCanvasState,
  ILoubiFutureWishlist,
  IMapState,
  ISoundEffect,
  LFPopupContent
} from '../../interfaces/loubifuture';

export const UPDATE_LOUBI_FUTURE_CANVAS_STATE =
  'LOUBIFUTURE.UPDATE_CANVAS_STATE';

export const UPDATE_MAP = 'LOUBIFUTURE.UPDATE_MAP';
export const ADD_TO_WISHLIST = 'LOUBIFUTURE.ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'LOUBIFUTURE.REMOVE_FROM_WISHLIST';
export const UPDATE_WISHLIST = 'LOUBIFUTURE.UPDATE_WISHLIST';
export const UPDATE_SELECTED_PRODUCT = 'LOUBIFUTURE.UPDATE_SELECTED_PRODUCT';
export const OPEN_WISHLIST_POPUP = 'LOUBIFUTURE.OPEN_WISHLIST_POPUP';
export const OPEN_POPUP = 'LOUBIFUTURE.OPEN_POPUP';
export const PLAY_SOUND_EFFECT = 'LOUBIFUTURE.PLAY_SOUND_EFFECT';
export const UPDATE_LANDING_VIDEO = 'LOUBIFUTURE.UPDATE_LANDING_VIDEO';
export const HIDE_CURSOR_GUIDE = 'LOUBIFUTURE.HIDE_CURSOR_GUIDE';
export const REPLAY_MUSIC_VIDEO = 'LOUBIFUTURE.REPLAY_MUSIC_VIDEO';
export const PLAY_LOUBIFUTURE_INTRODUCTION_VIDEO_FOR_MEETING =
  'LOUBIFUTURE.PLAY_INTRODUCTION_VIDEO_FOR_MEETING';

export const actionUpdateLoubiFutureCanvasState = (
  canvasState: ILoubiFutureCanvasState
) => ({
  type: UPDATE_LOUBI_FUTURE_CANVAS_STATE,
  payload: canvasState
});

export const actionUpdateMap = (payload: IMapState) => ({
  type: UPDATE_MAP,
  payload
});

export const actionUpdateLoubiFutureSelectedProduct = (payload?: string) => ({
  type: UPDATE_SELECTED_PRODUCT,
  payload
});

export const actionAddToWishlist = (payload: string) => ({
  type: ADD_TO_WISHLIST,
  payload
});

export const actionRemoveFromWishlist = (payload: string) => ({
  type: REMOVE_FROM_WISHLIST,
  payload
});

export const actionUpdateLFWishlist = (payload: ILoubiFutureWishlist) => ({
  type: UPDATE_WISHLIST,
  payload
});

export const actionOpenWishlistPopup = (payload: boolean) => ({
  type: OPEN_WISHLIST_POPUP,
  payload
});

export const actionOpenLFPopup = (payload?: LFPopupContent) => ({
  type: OPEN_POPUP,
  payload
});

export const actionLFPlaySoundEffect = (payload?: ISoundEffect) => ({
  type: PLAY_SOUND_EFFECT,
  payload
});

export const actionUpdateLandingVideoState = (payload: {
  hidden?: boolean;
  finishedPlaying?: boolean;
}) => ({
  type: UPDATE_LANDING_VIDEO,
  payload
});

export const actionHideCursorGuide = () => ({
  type: HIDE_CURSOR_GUIDE,
  payload: null
});

export const actionPlayIntroductionVideo = () => ({
  type: PLAY_LOUBIFUTURE_INTRODUCTION_VIDEO_FOR_MEETING,
  payload: null
});

export const actionReplayMusicVideo = (payload: boolean) => ({
  type: REPLAY_MUSIC_VIDEO,
  payload
});
