import React from 'react';
import { assetBaseUrl } from '../../config';

const IWCBrazilStyle = () => (
  <style jsx global>
    {`
      @font-face {
        font-family: Gotham;
        src: url('https://static.inspify.io/voice/fonts/Gotham.woff')
            format('woff'),
          url('https://static.inspify.io/voice/fonts/Gotham.otf')
            format('woff2');
      }

      @font-face {
        font-family: 'Proxima Nova Rg';
        src: url('${assetBaseUrl}/iwc/font/ProximaNova/ProximaNova-Regular.eot');
        src: url('${assetBaseUrl}/iwc/font/ProximaNova/ProximaNova-Regular.eot?#iefix')
            format('embedded-opentype'),
          url('${assetBaseUrl}/iwc/font/ProximaNova/ProximaNova-Regular.woff2')
            format('woff2'),
          url('${assetBaseUrl}/iwc/font/ProximaNova/ProximaNova-Regular.woff')
            format('woff'),
          url('${assetBaseUrl}/iwc/font/ProximaNova/ProximaNova-Regular.ttf')
            format('truetype'),
          url('${assetBaseUrl}/iwc/font/ProximaNova/ProximaNova-Regular.svg#GothamPro-Bold')
            format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      body {
        font-family: HelveticaNeue, sans-serif;
        font-size: 14px;
      }
      strong,
      .strong {
        font-weight: 500;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      .appointment label {
        font-family: Gotham, HelveticaNeue, sans-serif;
        font-weight: normal !important;
      }

      button,
      .btn,
      .LoadingProgress .label {
        font-family: Gotham, HelveticaNeue, sans-serif;
        font-weight: normal !important;
        letter-spacing: 0.11em;
      }

      .input-group-sm > .form-control {
        padding: 0.25rem 0.5rem 0px;
      }

      .action-button,
      .action-button button {
        font-weight: normal;
      }

      .btn-action-group .action-button,
      .btn-action-group .action-button button {
        font-weight: bold;
        font-weight: normal !important;
      }

      .tstc label {
        font-family: Gotham, HelveticaNeue, sans-serif;
      }

      #ap-close,
      .close {
        font-family: HelveticaNeue, sans-serif;
      }
      .nav-item__icon svg {
        margin-top: -5px;
      }

      p {
        letter-spacing: 0.2em;
      }

      .chat-content .btn {
        text-transform: uppercase;
        font-size: 0.75em;
        font-weight: normal !important;
      }

      @media (max-width: 768px) {
        .landing-page button {
          font-size: 0.65em;
        }
      }
      @media (min-width: 1024px) {
        .landing-page button {
          height: 69px;
        }
        .explore-own {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    `}
  </style>
);

export const IWCBrazilTouchStyle = () => (
  <style jsx global>{`
    @font-face {
      font-family: Gotham;
      src: url('https://static.inspify.io/voice/fonts/Gotham.woff')
          format('woff'),
        url('https://static.inspify.io/voice/fonts/Gotham.otf') format('woff2');
    }

    html,
    body {
      font-family: HelveticaNeue, sans-serif;
      font-size: 14px;
    }

    h1,
    h2,
    .light-text,
    .inspiration-page .copy strong,
    .products-landing .text-center p,
    .modelcode,
    .prod-title,
    h4 {
      font-family: Gotham, HelveticaNeue, sans-serif;
      font-weight: normal !important;
      letter-spacing: 0.1em;
      color: #000 !important;
    }

    p,
    span {
      letter-spacing: 0.05em;
    }

    .dark-mode header .pagetitle {
      font-family: HelveticaNeue, sans-serif;
      font-weight: normal;
    }

    .product-landing .light-text {
      font-family: HelveticaNeue, sans-serif !important;
    }
    @media (min-width: 768px) {
      .product-landing .title {
        font-size: 1.8em !important;
      }
      .prod-title {
        min-height: 48px;
      }
    }
  `}</style>
);

export default IWCBrazilStyle;
