import { getNow } from '../../../../utils/clock';
import { CHAT_HISTORY_STORAGE } from '../../../../utils/constants';
import { IMeetingChat, ChatParticipant } from './index';
import {
  tryGetLocalStorage,
  trySetLocalStorage
} from '../../../../utils/window';

interface ChatLocalStorage {
  timeStamp: string;
  chat: IMeetingChat[];
  participants: ChatParticipant[];
}

const getChatHistory = () => {
  const chatHistory = tryGetLocalStorage(CHAT_HISTORY_STORAGE);
  if (chatHistory) {
    const history = JSON.parse(chatHistory) as {
      [key: string]: ChatLocalStorage;
    };
    //for loop history and clear history that over 1 day
    for (const key in history) {
      const timeStamp = new Date(history[key].timeStamp);
      const now = new Date();
      const diff = now.getTime() - timeStamp.getTime();
      const diffDay = diff / (1000 * 3600 * 24);

      if (diffDay >= 1) {
        delete history[key];
      }
    }
    return history;
  }
  return {};
};

export const getChatHistoryByMeetingId = (meetingId: string) => {
  const restoredHistory = { ...getChatHistory() };
  const selectedHistory = restoredHistory[meetingId];
  if (selectedHistory) {
    return selectedHistory;
  }
};

export const saveParticipantsToLocalStorage = (
  payload: ChatParticipant[],
  meetingId: string
) => {
  const existingHistory = getChatHistory();

  const newHistory = {
    timeStamp: getNow().toISOString(),
    participants: payload,
    chat: existingHistory[meetingId]?.chat || []
  };

  existingHistory[meetingId] = newHistory;

  trySetLocalStorage(CHAT_HISTORY_STORAGE, JSON.stringify(existingHistory));
};

export const saveChatToLocalStorage = (
  payload: IMeetingChat[],
  meetingId: string
) => {
  const existingHistory = getChatHistory();

  const newHistory = {
    timeStamp: getNow().toISOString(),
    participants: existingHistory[meetingId]?.participants || [],
    chat: payload
  };

  existingHistory[meetingId] = newHistory;

  trySetLocalStorage(CHAT_HISTORY_STORAGE, JSON.stringify(existingHistory));
};
