import { ServerState } from '../../interfaces';
import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { DID_LOAD_PRODUCT_INSPIRATIONS } from '../actions';

const defaultServerState: ServerState = {
  productInspirations: {
    inspirations: []
  }
};

const serverReducer = (
  state: ServerState = defaultServerState,
  action: AnyAction
): ServerState => {
  switch (action.type) {
    case HYDRATE:
      // Attention! This will overwrite client state! Real apps should use proper reconciliation.
      console.log('HYDRATE');
      return {
        ...state,
        ...action.payload.serverState
      };
    case DID_LOAD_PRODUCT_INSPIRATIONS:
      console.log('DID_LOAD_PRODUCT_INSPIRATIONS');
      return {
        ...state,
        productInspirations: action.payload
      };
    default:
      return state;
  }
};

export default serverReducer;
