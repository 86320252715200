import { AnyAction, Dispatch } from 'redux';
import {
  blasterCrossbody,
  louisPStrass,
  melonSpikes,
  navyCoolito,
  navyPool,
  rantulow
} from '../../components/ThreejsAnimation/louboutinJp/assets';
import { ClientState } from '../../interfaces';
import {
  IPlanePlan,
  LoubiAirwaysAnimation,
  LoubiAirwaysFullScreenVideo,
  PlanSpotLight,
  REMOTE_ACTION
} from '../../interfaces/loubiairways';
import { getKRPano, krpanoInvoke } from '../../utils/krpano';
import {
  actionMuteBgSound,
  actionOpenMusicPlaylist,
  actionUnmuteBgSound,
  VB_DID_CLICK_ON_HOT_SPOT,
  VB_DID_GO_TO_SCENE
} from '../actions';
import {
  actionToggleShowSpotifyPlayer,
  actionUpdateActiveAnimationInPano,
  actionUpdateLoubiAirwaysFullScreenVideo,
  actionUpdateLoubiAirwaysSelectedModel,
  actionUpdateVisitedPlanePlanSpot,
  ADD_TO_WISHLIST,
  START_FETCHING_CATALOG,
  UPDATE_LANDING_VIDEO_STATE,
  UPDATE_LOUBI_AIRWAYS_PLANE_PLAN
} from '../customActions/loubiAirways';
import { actionGetLoubiAirwaysCatalog } from '../customAsyncActions/loubiAirwaysAsyncActions';
import { actionFetchProductByIdAsync } from '../productAsyncAction';

const temporarilyHideHotSpot = (hotSpot: string) => {
  getKRPano()?.call(`tween(hotspot[${hotSpot}].alpha, 0, 0)`);
  setTimeout(() => {
    getKRPano()?.call(`tween(hotspot[${hotSpot}].alpha, 1, 0)`);
  }, 3000);
};

const goToScene = (sceneName: string) => {
  const viewSetting =
    'view.fisheyefovlink=0.5&amp;view.architectural=0.0&amp;view.pannini=0.0&amp;view.fisheye=0.0&amp;view.stereographic=false';
  krpanoInvoke(
    `loadscene('${sceneName}', ${viewSetting}, MERGE, BLEND(1.0, easeInCubic));`
  );
};

const adjustViewDelay = 3000;

const goToNewspaper = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('newspaper_animation');
  }
  setTimeout(
    () => {
      goToScene('scene_000000');
      krpanoInvoke(
        `lookat(-177.60445317747084, 4.239639710822045, 110, tween(linear, 0))`
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToLuggage = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('luggage_pop_animation');
  }
  setTimeout(
    () => {
      goToScene('scene_020000');
      krpanoInvoke(
        `lookat(-92.71777135183464, 2.174284687433836, 130.46234901706376, tween(linear, 0))`
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToBeautyCart = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('beauty_cart_animation');
  }
  setTimeout(
    () => {
      goToScene('scene_030000');
      krpanoInvoke(
        'lookat(-87.98644863495888, 17.53614395286497, 108.18693441911527, tween(linear, 0))'
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToTrolley = () => {
  goToScene('scene_030000');
  // temporarilyHideHotSpot('trolley_animation');
  krpanoInvoke(
    'lookat(92.02386532613738, 16.399615459061806, 85.1595944899295, tween(linear, 0))'
  );
};

const goToStylishDemoVideo = () => {
  goToScene('scene_000000a');
  temporarilyHideHotSpot('stylish_demo_video');
  krpanoInvoke(
    `lookat(-183.60445317747084, 4.239639710822045, 110, tween(linear, 0))`
  );
};

const goToBeautyDemoAnimation = () => {
  goToScene('scene_090000');
  temporarilyHideHotSpot('beauty_demo_animation');
  krpanoInvoke(
    'lookat(86.59520906802912, 33.31539386107141, 109.76841837491197, tween(linear, 0))'
  );
};

const goToHeadSet = () => {
  goToScene('scene_110000');
  temporarilyHideHotSpot('beauty_demo_animation');
  krpanoInvoke(
    'lookat(-108.98644863495888, 17.53614395286497, 108.18693441911527, tween(linear, 0))'
  );
};

const goToAirplaneWindow = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('airplane_window_animation');
  }
  setTimeout(
    () => {
      goToScene('scene_020000');
      krpanoInvoke('lookto(-67.47, 2.21, 90, tween(linear, 0))');
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToOmgMagazine = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('omg_magazine_animation');
    temporarilyHideHotSpot('style_guide_animation');
  }
  setTimeout(
    () => {
      goToScene('scene_090000');
      krpanoInvoke(
        'lookto(-92.99558289667276, 7.110961287745182, 105.91785794352245, tween(linear, 0))'
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToTarotCard = (noDelay?: boolean) => {
  temporarilyHideHotSpot('tarot_card_animation');
  setTimeout(
    () => {
      goToScene('scene_120000');
      krpanoInvoke('lookto(0, 0, 118, tween(linear, 0))');
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToInflightMagazine = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('inflight_magazine');
  }
  setTimeout(
    () => {
      goToScene('scene_070000');
      krpanoInvoke(
        'lookto(174.511, 8.659, 110.4434236037944, tween(linear, 0))'
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToStylishGuide = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('omg_magazine_animation');
    temporarilyHideHotSpot('style_guide_animation');
  }
  setTimeout(
    () => {
      goToScene('scene_090000');
      krpanoInvoke(
        'lookto(-92.99558289667276, 7.110961287745182, 105.91785794352245, tween(linear, 0))'
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToTrayTable = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('tray_table_animation');
  }
  setTimeout(
    () => {
      goToScene('scene_060000a');
      krpanoInvoke(
        'lookto(113.13132395431069, -2.2567813067443194, 120, tween(linear, 0))'
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToXRay = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('compartment_x_ray_animation');
  }
  setTimeout(
    () => {
      goToScene('scene_050000');
      krpanoInvoke(
        'lookto(89.88285645591485, -9.91618137061159, 118, tween(linear, 0))'
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const goToCockpitEntrance = (noDelay?: boolean) => {
  if (!noDelay) {
    temporarilyHideHotSpot('cockpit_entrance');
  }
  setTimeout(
    () => {
      goToScene('scene_010000');
      krpanoInvoke(
        'lookto(178.28070448795665, 1.9055294119744564, 118, tween(linear, 0))'
      );
    },
    noDelay ? 0 : adjustViewDelay
  );
};

const handleHotSpotClick = (hotSpotName: string, dispatch: Dispatch) => {
  switch (hotSpotName) {
    case 'airplane_window_animation': {
      goToAirplaneWindow();
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.AIRPLANE_WINDOW)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.WINDOW));
      break;
    }
    case 'luggage_pop_animation': {
      goToLuggage();
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.LUGGAGE)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.LUGGAGE));
      break;
    }
    case 'tarot_card_animation': {
      goToTarotCard();
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.TAROT_CARD)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TARROT));
      break;
    }
    case 'beauty_cart_model':
    case 'beauty_cart_animation': {
      goToBeautyCart();
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.BEAUTY_CART)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.BEAUTY_CART));
      break;
    }
    case 'trolley_model':
    case 'trolley_animation': {
      goToTrolley();
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TROLEY));
      break;
    }
    case 'trolley_top_left': {
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.TROLLEY)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TROLEY));
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel({
          model: melonSpikes,
          state: REMOTE_ACTION.OPEN
        })
      );
      break;
    }
    case 'trolley_top_right': {
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.TROLLEY)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TROLEY));
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel({
          model: rantulow,
          state: REMOTE_ACTION.OPEN
        })
      );
      break;
    }
    case 'trolley_middle_left': {
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.TROLLEY)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TROLEY));
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel({
          model: navyCoolito,
          state: REMOTE_ACTION.OPEN
        })
      );
      break;
    }
    case 'trolley_middle_right': {
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.TROLLEY)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TROLEY));
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel({
          model: blasterCrossbody,
          state: REMOTE_ACTION.OPEN
        })
      );
      break;
    }
    case 'trolley_bottom_left': {
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.TROLLEY)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TROLEY));
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel({
          model: navyPool,
          state: REMOTE_ACTION.OPEN
        })
      );
      break;
    }
    case 'trolley_bottom_right': {
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.TROLLEY)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TROLEY));
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel({
          model: louisPStrass,
          state: REMOTE_ACTION.OPEN
        })
      );
      break;
    }
    case 'omg_magazine_animation': {
      goToOmgMagazine();
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.OMG_MAGAZINE)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.OMG_MAGAZINE));
      break;
    }
    case 'inflight_magazine': {
      goToInflightMagazine();
      dispatch(
        actionUpdateActiveAnimationInPano(
          LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE
        )
      );
      dispatch(
        actionUpdateVisitedPlanePlanSpot(PlanSpotLight.IN_FLIGHT_MAGAZINE)
      );
      break;
    }
    case 'tray_table_animation': {
      goToTrayTable();
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.TRAY_TABLE)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.TRAY));
      break;
    }
    case 'style_guide_animation': {
      goToStylishGuide();
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.STYLISH_GUIDE)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.STYLISH_GUIDE));
      break;
    }
    case 'compartment_x_ray_animation': {
      goToXRay();
      dispatch(
        actionUpdateActiveAnimationInPano(
          LoubiAirwaysAnimation.COMPARTMENT_XRAY
        )
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.XRAY));
      break;
    }
    case 'newspaper_animation': {
      goToNewspaper();
      dispatch(
        actionUpdateActiveAnimationInPano(LoubiAirwaysAnimation.NEWSPAPER)
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.NEWSPAPER));
      break;
    }
    case 'cockpit_entrance': {
      goToCockpitEntrance();
      dispatch(
        actionUpdateLoubiAirwaysFullScreenVideo(
          LoubiAirwaysFullScreenVideo.COCKPIT
        )
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.COCKPIT));
      break;
    }
    case 'tv_screen':
    case 'stylish_demo_video': {
      dispatch(actionMuteBgSound());
      dispatch(
        actionUpdateLoubiAirwaysFullScreenVideo(
          LoubiAirwaysFullScreenVideo.STYLISH_DEMO
        )
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.STYLISH_VIDEO));
      break;
    }
    case 'telephone':
    case 'beauty_demo_animation': {
      dispatch(actionMuteBgSound());
      dispatch(
        actionUpdateLoubiAirwaysFullScreenVideo(
          LoubiAirwaysFullScreenVideo.BEAUTY_DEMO
        )
      );
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.PHONE));
      break;
    }
    case 'headset': {
      dispatch(actionMuteBgSound());
      dispatch(actionToggleShowSpotifyPlayer(true));
      dispatch(actionOpenMusicPlaylist(true));
      dispatch(actionUpdateVisitedPlanePlanSpot(PlanSpotLight.HEADSET));
      break;
    }

    default:
      break;
  }
};

const handleMoveToSpotLight = (state: IPlanePlan) => {
  const selectedSpot = state?.selectedSpot;

  switch (selectedSpot) {
    case PlanSpotLight.NEWSPAPER:
      goToNewspaper(true);
      break;
    case PlanSpotLight.STYLISH_VIDEO:
      goToStylishDemoVideo();
      break;
    case PlanSpotLight.LUGGAGE:
      goToLuggage(true);
      break;
    case PlanSpotLight.BEAUTY_CART:
      goToBeautyCart(true);
      break;
    case PlanSpotLight.TROLEY:
      goToTrolley();
      break;
    case PlanSpotLight.WINDOW:
      goToAirplaneWindow(true);
      break;
    case PlanSpotLight.XRAY:
    case PlanSpotLight.TRAY:
      goToTrayTable(true);
      break;
    case PlanSpotLight.IN_FLIGHT_MAGAZINE:
      goToInflightMagazine(true);
      break;
    case PlanSpotLight.OMG_MAGAZINE:
      goToOmgMagazine(true);
      break;
    case PlanSpotLight.STYLISH_GUIDE:
      goToOmgMagazine(true);
      break;
    case PlanSpotLight.PHONE:
      goToBeautyDemoAnimation();
      break;
    case PlanSpotLight.HEADSET:
      goToHeadSet();
      break;
    case PlanSpotLight.TARROT:
      goToTarotCard(true);
      break;

    case PlanSpotLight.COCKPIT:
      goToCockpitEntrance(true);
      break;
  }
};

export const handleLoubiAirwaysJpAction = (
  action: AnyAction,
  dispatch: Dispatch<AnyAction>,
  clientState: ClientState
) => {
  switch (action.type) {
    case VB_DID_CLICK_ON_HOT_SPOT:
      handleHotSpotClick(action.payload, dispatch);
      break;
    case UPDATE_LANDING_VIDEO_STATE:
      if (action.payload.hidden) {
        dispatch(actionUnmuteBgSound());
      }
      break;
    case UPDATE_LOUBI_AIRWAYS_PLANE_PLAN:
      handleMoveToSpotLight(action.payload.planePlan);
      break;
    case VB_DID_GO_TO_SCENE: {
      if (
        clientState.vb?.openMusicPlaylist &&
        action.payload !== 'scene_110000'
      ) {
        dispatch(actionOpenMusicPlaylist(false));
      }
      break;
    }
    case START_FETCHING_CATALOG: {
      const { type, gender, index } = action.payload;
      dispatch(actionGetLoubiAirwaysCatalog(type, gender, index) as any);
      break;
    }
    case ADD_TO_WISHLIST: {
      dispatch(actionFetchProductByIdAsync(action.payload?.id) as any);
      break;
    }
    default:
      break;
  }
};
