
// let cachedApiKey: string | null = null;

export async function getApiKey() {
    return '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';
//   if (cachedApiKey) return cachedApiKey;

//   const secret_name = "prod/chatgpt/apikey";
//   const client = new SecretsManagerClient({ region: "ap-southeast-1" });

//   try {
//     const response = await client.send(
//       new GetSecretValueCommand({
//         SecretId: secret_name,
//         VersionStage: "AWSCURRENT",
//       })
//     );
//     cachedApiKey = JSON.parse(response.SecretString).apiKey;
//     return cachedApiKey;
//   } catch (error) {
//     console.error("Error retrieving API key:", error);
//     throw error;
//   }
}