import { VCAPopupContent } from '../../interfaces/vca';

export const OPEN_POPUP = 'VANCLEEFARPELS.OPEN_POPUP';
export const CLOSE_POPUP = 'VANCLEEFARPELS.CLOSE_POPUP';

export const actionOpenVCAPopup = (payload: VCAPopupContent) => ({
  type: OPEN_POPUP,
  payload
});

export const actionCloseVCAPopup = () => ({
  type: CLOSE_POPUP,
  payload: null
});
