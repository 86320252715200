import { ClientState, LoadingStatus } from '../../interfaces';
import { defaultClientState } from './clientReducer';
import { AnyAction } from 'redux';
import {
  DID_LOAD_LOCAL_USER,
  DID_LOAD_LOCAL_USER_FAILED,
  DID_UPDATE_LOCAL_USER
} from '../actions';

const userReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case DID_UPDATE_LOCAL_USER:
      return {
        ...state,
        user: {
          ...(state.user || {}),
          ...action.payload
        }
      };
    case DID_LOAD_LOCAL_USER: {
      return {
        ...state,
        userLoadState: LoadingStatus.LOADED,
        user: {
          ...(state.user || {}),
          ...action.payload
        }
      };
    }
    case DID_LOAD_LOCAL_USER_FAILED: {
      return {
        ...state,
        userLoadState: LoadingStatus.FAILED
      };
    }
    default:
      return state;
  }
};

export default userReducer;
