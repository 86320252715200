import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import {
  DID_RECEIVE_REMOTE_CHOPARD_ANIMATION_STATE,
  DID_SCROLL_PRODUCT_DETAILS,
  SHOW_CHOPARD_PRODUCT_DETAILS,
  UPDATE_CHOPARD_ACTIVE_ANIMATION_IN_PANO,
  UPDATE_CHOPARD_SELECTED_MODEL
} from '../customActions/chopard';
import { defaultClientState } from './clientReducer';

const chopardReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case DID_RECEIVE_REMOTE_CHOPARD_ANIMATION_STATE: {
      return {
        ...state,
        chopard: {
          ...(state.chopard || {}),
          remoteAnimation: {
            ...(state.chopard?.remoteAnimation || {}),
            [action.payload.animation]: action.payload.state
          }
        }
      };
    }

    case UPDATE_CHOPARD_ACTIVE_ANIMATION_IN_PANO: {
      return {
        ...state,
        chopard: {
          ...(state.chopard || {}),
          activeAnimation: action.payload.animation
        }
      };
    }

    case UPDATE_CHOPARD_SELECTED_MODEL: {
      return {
        ...state,
        chopard: {
          ...(state.chopard || {}),
          selectedModel: action.payload.selectedModel
        }
      };
    }

    case SHOW_CHOPARD_PRODUCT_DETAILS: {
      return {
        ...state,
        chopard: {
          ...(state.chopard || {}),
          showProductDetails: action.payload
        }
      };
    }

    case DID_SCROLL_PRODUCT_DETAILS: {
      return {
        ...state,
        chopard: {
          ...(state.chopard || {}),
          productDetailsScrolledPercentage: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export default chopardReducer;
