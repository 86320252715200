import {
  IFont,
  ISceneLayer,
  IStorybookPage,
  IStorybookVisibility
} from './../interfaces/index';
import { format } from 'date-fns';
import { mapLanguageToImageName } from '../advisorHub/clientSideServices/library';
import { defaultStorybookSettings } from '../advisorHub/config';
import {
  ILibraryScene,
  ILibrarySceneContent,
  ILibraryScenePayload,
  LibrarySceneType
} from '../interfaces';
import {
  getDateIsoString,
  getFileNameFromUrl,
  getFileType,
  removeFileExtension
} from '../utils/file';
import { generateV4UUID } from '../utils/identityGenerator';
import { checkAndFixPolotnoJsonString } from './polotno';
import {
  appendNewValueIntoSetting,
  getPagesInPayload,
  getThumbnailOfPagesInPayload,
  mapStorybookSettingsStringToObject,
  mapUrl,
  StorybookFilesResponse
} from './storybook';
import {
  getLanguageJSON,
  getStorybookPageType
} from '../advisorHub/utils/storybook';

export const generateBlankScene = (
  context?: LibrarySceneType,
  initId?: string
) => ({
  id: initId || generateV4UUID(),
  content: {
    name: '',
    overlay: ''
  }
});

export const mapStorybookFilesToScenes = (
  payload: StorybookFilesResponse
): ILibraryScene[] => {
  const { key, pageBucket, pages } = payload;

  const cleanTitle = getFileNameFromUrl(removeFileExtension(key));

  const pagesInPayload = getPagesInPayload(key, pages);
  const thumbnails = getThumbnailOfPagesInPayload(pagesInPayload, pages);
  const scenes: ILibraryScene[] = [];

  const dateInKey = getDateIsoString(key);

  pagesInPayload.forEach((page, i) => {
    const url = mapUrl(pageBucket, page);
    scenes.push({
      id: generateV4UUID(),
      content: {
        title: (cleanTitle || '').trim(),
        subtitle: getFileType(url),
        description: dateInKey
          ? format(new Date(dateInKey), 'p - MMM dd, yyyy')
          : undefined,
        settings: key,
        thumbnail: mapUrl(pageBucket, thumbnails[i]),

        url
      }
    });
  });

  return scenes;
};

export const getBlankScene = () => ({
  id: generateV4UUID(),
  content: {
    url: '',
    thumbnail: '',
    settings: defaultStorybookSettings
  }
});

export const mapSceneToPayload = ({
  scene,
  userId,
  context
}: {
  scene: ILibraryScene;
  userId: string;
  context: LibrarySceneType;
}): ILibraryScenePayload => {
  return {
    id: scene.id || generateV4UUID(),
    keys: scene.keys || [],
    tags: scene.tags || [],
    status: scene.status || 'draft',
    createdBy: scene.createdBy || userId,
    modifiedBy: scene.createdBy ? userId : scene.modifiedBy || '',
    type: context,
    content: {
      name: scene.content.name || '',
      description: scene.content.description || '',
      title: scene.content.title || '',
      subtitle: scene.content.subtitle || '',
      settings: scene.content.settings || '',
      url: scene.content.url || '',
      thumbnail: scene.content.thumbnail || '',
      visibility: scene.content.visibility || 'private',
      visibilityScope: scene.content.visibilityScope || [],
      overlay: scene.content.overlay
        ? checkAndFixPolotnoJsonString(scene.content.overlay)
        : ''
    }
  };
};

export const mapStorybookPageToScene = ({
  page,
  userId,
  visibility = 'private',
  fonts
}: {
  page: IStorybookPage;
  userId: string;
  visibility?: IStorybookVisibility;
  fonts?: IFont[];
}): ILibraryScene => {
  return {
    id: page.id,
    createdBy: userId,
    content: {
      name: page?.sceneDetails?.name || '',
      description: page?.sceneDetails?.description || '',
      title: page.title,
      subtitle: page.subtitle,
      settings: appendNewValueIntoSetting(page.settings, {
        customFont: fonts || [],
        exportable: false,
        type: getStorybookPageType(
          page,
          mapStorybookSettingsStringToObject(page.settings)
        )
      }),
      url: page.url,
      thumbnail: page.thumbnail,
      overlay: page.overlay,
      visibility: visibility
    }
  };
};

export const mapSceneForClone = (
  scene: ILibraryScene,
  name: string
): ILibraryScene => {
  return {
    id: generateV4UUID(),
    createdBy: '',
    modifiedBy: '',
    keys: [],
    tags: [],
    status: 'draft',
    editor: scene.editor,
    content: {
      ...scene.content,
      name,
      visibility: 'private',
      visibilityScope: [],
      overlay: scene.content.overlay || ''
    }
  };
};

export const mapGeneratedImageToSceneContent = (
  content: ILibrarySceneContent,
  generatedImages
): ILibrarySceneContent => {
  const imageLanguages = generatedImages?.imageLanguages || [];
  console.log('imageLanguages', imageLanguages);
  const result = { ...content };

  const settingValue = {
    layer: generatedImages?.layer
  };

  imageLanguages?.map((imageLanguage) => {
    const url = getLanguageJSON(result['url']);
    const thumbnail = getLanguageJSON(result['thumbnail']);
    result['url'] = JSON.stringify({
      ...url,
      [imageLanguage]:
        generatedImages[mapLanguageToImageName('mainImage', imageLanguage)]
    });
    result['thumbnail'] = JSON.stringify({
      ...thumbnail,
      [imageLanguage]:
        generatedImages[mapLanguageToImageName('preview', imageLanguage)]
    });
    settingValue[mapLanguageToImageName('portraitUrl', imageLanguage)] =
      generatedImages[mapLanguageToImageName('portraitImage', imageLanguage)];
    settingValue[mapLanguageToImageName('portraitThumbnail', imageLanguage)] =
      generatedImages[
        mapLanguageToImageName('portraitThumbnail', imageLanguage)
      ];
    result.settings = appendNewValueIntoSetting(content.settings, settingValue);
  });

  return result;
};

export const mapGeneratedLayersToSceneContent = (
  content: ILibrarySceneContent,
  layers: ISceneLayer
): ILibrarySceneContent => {
  return {
    ...content,
    settings: appendNewValueIntoSetting(content.settings, {
      layer: layers
    })
  };
};
