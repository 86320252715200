import { MeetingCommand } from '../../components/Meeting/commands';
import {
  getJitsiRegionByString,
  getProdHostForRegion,
  getProdHostForStore,
  getSspWebviewUrlByStoreId
} from '../../config';
import { ISessionDetails, JitsiEndpointMessage } from '../../interfaces';
import {
  getSspControls,
  isOnboardingBrand,
  tryGetParentWindowField
} from '../window';

export const joinMeetingFromSsp = (
  session: ISessionDetails,
  advisorName: string,
  webUrl?: string
) => {
  const jitsiRegion = getJitsiRegionByString(session.region);
  const meetingServerUrl = jitsiRegion
    ? getProdHostForRegion(jitsiRegion)
    : getProdHostForStore(session.storeId);
  const isOnboarding = isOnboardingBrand();
  const webviewUrl = isOnboarding
    ? webUrl
    : getSspWebviewUrlByStoreId(session.storeId);
  if (meetingServerUrl && webviewUrl) {
    getSspControls()?.joinMeeting(
      advisorName,
      `https://${meetingServerUrl}`,
      session.id,
      webviewUrl,
      !!session.joinAnytime
    );
  }
};

export const goToSspHome = () => {
  return getSspControls()?.exit?.();
};

export const isEmbeddedInStreamingStudio = () => {
  return !!getSspControls() || !!tryGetParentWindowField('SspControls');
};

export const getStreamingStudioDeviceId = () => {
  return getSspControls()?.getDeviceId();
};

export const inviteStreamingStudioToMeeting = (
  meetingId: string,
  serverUrl: string,
  hostName: string
) => {
  getSspControls()?.startSspSession(hostName, serverUrl, meetingId);
};

export const sendVoyageControlMessage = (message?: JitsiEndpointMessage) => {
  if (message) {
    if (message.value === MeetingCommand.UPDATE_LAYOUT_MODE) {
      return;
    }
    try {
      getSspControls()?.sendVoyageMessage(JSON.stringify(message));
    } catch (e) {
      console.log('Failed to send voyage control message to SSP', e);
    }
  }
};
