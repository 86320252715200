import { IProduct, IProductResponse, ProductType } from '../interfaces';
import { toS3Url, toWebResolution } from '../utils/image';
import {
  getWatchBuckleImagesUrl,
  getWatchStrapImagesUrl
} from './../utils/image';

export const mapProductResponseToProduct = (
  payload: IProductResponse
): IProduct => {
  const {
    id,
    brand,
    brand_name,
    colortext,
    description,
    long_description,
    model_code,
    model_group,
    model_name,
    imageUrl,
    type,
    product_images,
    productColorImageURL,
    sales_price_sg,
    offering_price,
    currency,
    offeringCurrency,
    officialSalesPrices,
    watchSpecifications,
    jewelrySpecifications,
    highJewelrySpecifications,
    buckleStrapSpecifications,
    editions,
    product_family,
    gender
  } = payload;

  const getPreviewImageUrl = (): string | null => {
    if (imageUrl) {
      return toS3Url(imageUrl.replace('_preview', '_001')).replace('.jpeg', '.png');
    }
    if (product_images && product_images.length > 0) {
      return toS3Url(product_images[0]).replace('.jpeg', '.png');
    }
    return null;
  };

  const getImageUrls = (): string[] => {
    if (!product_images || !product_images?.length) return [];
    if (type === ProductType.BUCKLE)
      return getWatchBuckleImagesUrl(product_images[0]);
    if (type === ProductType.STRAP)
      return getWatchStrapImagesUrl(product_images[0]);
    return product_images
      .map((url) => toWebResolution(url))
      .map((url) => toS3Url(url))
      .filter((url) => !!url) as string[];
  };

  return {
    id,
    brandId: brand || null,
    brandName: brand_name || null,
    colorText: colortext || null,
    countryCode: null,
    description: description || null,
    longDescription: long_description || null,
    type: type || null,
    modelCode: model_code,
    modelGroup: model_group,
    modelName: model_name || null,
    gender:gender || null,
    previewImageUrl: getPreviewImageUrl(),
    imageUrls: getImageUrls(),
    productColorImageURL: toS3Url(productColorImageURL),
    officialSalesPrices: officialSalesPrices || null,
    salesPriceSg: sales_price_sg || null,
    offeringPrice: offering_price || null,
    currency: currency || null,
    offeringCurrency: offeringCurrency || null,
    watchSpecifications: watchSpecifications || null,
    jewelrySpecifications: jewelrySpecifications || null,
    highJewelrySpecifications: highJewelrySpecifications || null,
    buckleStrapSpecifications: buckleStrapSpecifications || null,
    editions: editions || null,
    productFamily: product_family || null
  };
};
