import httpClient from '../../../clientSideServices/http';
import { getStoriesByIds } from '../../../clientSideServices/inspirations';
import { getProductsByIds } from '../../../clientSideServices/products';
import {
  INudge,
  INudgeContentFavorites,
  INudgeItemPayload,
  INudgePayload,
  INudgeTemplate,
  NudgeStatus
} from '../../../interfaces';
import { generateV4UUID } from '../../../utils/identityGenerator';
import {
  getProductIdsFromNudgeContent,
  getStoryIdsFromNudgeContent,
  mapNudgeToPayload,
  mapNudgeToTemplatePayload,
  mapProductsAndStoriesToNudgeItem
} from '../../utils/nudge';
import { apigClient } from '../aws';

export const getPayload = (nudge: INudge, userId?: string): INudgePayload => {
  const payload = mapNudgeToPayload(nudge);
  if (!nudge?.status) {
    return { ...payload, advisorId: userId, createdBy: userId };
  }
  return { ...payload, advisorId: userId, modifiedBy: userId };
};

export const saveNudge = async (nudge: INudge, userId?: string) => {
  const client = await apigClient();
  const payload = getPayload(nudge, userId);
  const path = `/invites/v1`;
  const result = await client.invokeApi({}, path, 'PUT', {}, payload);
  return { result, payload };
};

export interface StartKey {
  createdBy: {
    S: string;
  };
  id: {
    S: string;
  };
  modifiedAt: {
    S: string;
  };
}

export const getNudgeByUserId = async (id: string, startKey?: StartKey) => {
  const client = await apigClient();
  const queryParams = {
    userId: id,
    limit: 100,
    startKey
  };
  const path = `/invites/v1/paginatedByUserId`;
  let result = { data: { invites: [] } };
  try {
    result = await client.invokeApi({}, path, 'POST', {}, queryParams);
  } catch {
    (e) => console.log('error', e);
  }
  return result.data.invites || [];
};

export const getNudgeById = async (id: string) => {
  console.log('getNudgeById: ', getNudgeById);
  const client = await apigClient();
  const queryParams = {
    id: id
  };
  const path = `/invites/v1/byId`;
  const result = await client.invokeApi({}, path, 'GET', { queryParams });
  return result.data;
};

export const updateNudgeStatus = (request: {
  id: string;
  status: NudgeStatus;
  customerId?: string;
  advisorId?: string;
}) => httpClient.post('/api/nudge', request);

export const getNudgeItems = async (
  nudgeId: string,
  nudgeItems: INudgeItemPayload[],
  noAdditional?: boolean
) => {
  const productIds = getProductIdsFromNudgeContent(nudgeItems);
  const storyIds = getStoryIdsFromNudgeContent(nudgeItems);
  const productsResponse = await getProductsByIds(
    productIds,
    noAdditional ? undefined : { NUDGE: nudgeId }
  );
  const stories = await getStoriesByIds(storyIds);

  return mapProductsAndStoriesToNudgeItem(
    nudgeItems,
    productsResponse.data,
    stories
  );
};

export const getNudgeContentFavorites = async (
  storeId: string,
  identityId: string
): Promise<INudgeContentFavorites> => {
  const client = await apigClient();
  const queryParams = {
    storeId,
    identityId
  };
  const path = `/nudgeContentFavourites/v1/byIdentityAndStoreId`;
  const result = await client.invokeApi({}, path, 'GET', { queryParams });
  return result.data;
};

const getDefaultNudgeContentFavorites = (
  storeId: string,
  brandId: string,
  identityId: string
) => ({
  id: generateV4UUID(),
  modifiedAt: '',
  status: '',
  createdAt: '',
  identityId,
  brand: brandId,
  description: '',
  createdBy: identityId,
  modifiedBy: identityId,
  store: storeId,
  favouriteItems: []
});

export const saveNudgeContentFavorites = async (
  storeId: string,
  brandId: string,
  identityId: string,
  favouriteItems: {
    itemId: string;
    itemType: string;
  }[]
): Promise<any> => {
  let existingFavorites;
  try {
    existingFavorites = await getNudgeContentFavorites(storeId, identityId);
  } catch {
    existingFavorites = getDefaultNudgeContentFavorites(
      storeId,
      brandId,
      identityId
    );
  }
  const client = await apigClient();
  const payload = {
    ...existingFavorites,
    favouriteItems
  };
  const path = `/nudgeContentFavourites/v1`;
  const result = await client.invokeApi({}, path, 'PUT', {}, payload);
  return result.data;
};

export const getNudgeTemplates = async (
  userId: string
): Promise<INudgeTemplate[]> => {
  const client = await apigClient();
  const queryParams = {
    userId
  };
  const path = `/inviteTemplates/v1/byUserId`;
  const result = await client.invokeApi({}, path, 'GET', { queryParams });
  return result.data;
};

export const saveNudgeTemplates = async (nudge: INudge, userId?: string) => {
  const client = await apigClient();
  const payload = mapNudgeToTemplatePayload(nudge, userId);
  const path = `/inviteTemplates/v1`;
  const result = await client.invokeApi({}, path, 'PUT', {}, payload);
  return result.data;
};
