import { uniq, uniqBy } from 'lodash';
import { AnyAction } from 'redux';
import { StreamPlayerStatus } from '../../components/PixelStream/PSInterface';
import {
  ClientState,
  MeetingLayoutMode,
  MeetingRole,
  ParticipantMeetingState
} from '../../interfaces';
import {
  PARTICIPANT_DID_ENTER_FROM_LOUNGE,
  ADD_LAYOUT_PRESENTER,
  ADVISOR_END_MEETING,
  APPROVE_RAISE_HAND,
  BRIDGE_CHANNEL_OPENED,
  DID_ADD_LOCAL_TRACK,
  DID_ADD_REMOTE_TRACK,
  DID_CHANGE_DOMINANT_SPEAKER,
  DID_GET_STORE_DEVICES,
  DID_JOIN_MEETING,
  DID_LEAVE_MEETING,
  DID_RECEIVE_ADVISOR_INFO,
  DID_RECEIVE_CHANGE_TO_VIEW_COMMAND,
  DID_RECEIVE_MOVE_TO_SCENE_COMMAND,
  DID_RECEIVE_PARTICIPANT_USER_INFO,
  DID_RECEIVE_REMOTE_POPUP_ACTION,
  DID_RECEIVE_TOGGLE_RAISE_HAND,
  DID_REMOVE_LOCAL_TRACK,
  DID_REMOVE_REMOTE_TRACK,
  DID_SLIDE_PRODUCT_IMAGES_SLIDER,
  ENTER_MEETING,
  HIDE_CLIENT_DETAIL_PANEL,
  KICKED_OUT_FROM_MEETING,
  LATE_COMER_DID_JOIN_LOUNGE,
  LOCAL_TRACK_AUDIO_LEVEL_DID_CHANGE,
  LOCAL_TRACK_AUDIO_MUTE_DID_CHANGE,
  LOCAL_TRACK_VIDEO_MUTE_DID_CHANGE,
  PARTICIPANT_DID_JOIN_MEETING,
  PARTICIPANT_DID_LEAVE_MEETING,
  PARTICIPANT_READY,
  POPUP_DID_LOAD_PAGE,
  REMOTE_HIDE_HOT_SPOT,
  REMOTE_POPUP_ACTION,
  REMOTE_SHOW_HOT_SPOT,
  REMOTE_TRACK_AUDIO_LEVEL_DID_CHANGE,
  REMOTE_TRACK_AUDIO_MUTE_DID_CHANGE,
  REMOTE_TRACK_VIDEO_MUTE_DID_CHANGE,
  REMOVE_LAYOUT_PRESENTER,
  REMOVE_RAISE_HAND_IN_ALERT,
  ROTATE_STUDIO_VIDEO,
  SET_AUDIO_INPUT,
  SET_AUDIO_OUTPUT,
  SET_ENLARGE_VIDEO,
  SET_INPUT_CHANGE_AVAILABLE,
  SET_IS_MEETING_PRESENTER,
  SET_LAYOUT_STATE,
  SET_MEETING_ID,
  SET_OUTPUT_CHANGE_AVAILABLE,
  SET_RAISE_HAND_STATE,
  SET_STUDIO_VIEW_STATE,
  SET_VIDEO_INPUT,
  SHOW_CLIENT_DETAIL_PANEL,
  TOGGLE_ENLARGE_VIDEO,
  TOGGLE_PARTICIPANT_VISIBILITY,
  TOGGLE_RAISE_HAND,
  TOGGLE_SHOW_LAYOUT_CONTROL_BUTTON,
  UPDATE_LAYOUT_MODE,
  UPDATE_MEETING_SERVER_URL,
  UPDATE_MEETING_STATS,
  UPDATE_PARTICIPANT_INTERESTED_PRODUCTS,
  UPDATE_PARTICIPANT_WISHLIST_PRODUCTS,
  UPDATE_REMOTE_DISPLAY_NAME,
  UPDATE_REMOTE_USER_MEETING_STATS,
  VB_CLOSE_POPUP,
  VB_OPEN_POPUP,
  ZOOM_STUDIO_VIDEO,
  PLAY_WELCOME_VIDEO_FROM_LOUNGE,
  ENTER_MEETING_FROM_WELCOME_VIDEO,
  SHOW_MEETING_NOTIFICATION,
  HIDE_MEETING_NOTIFICATION,
  OPEN_PARTICIPANT_CONTROLS,
  TOGGLE_PIN_PARTICIPANT,
  POPUP_VIDEO_DID_PLAY_WITH_SOUND,
  DID_CHANGE_CONNECTION_STATUS,
  OPEN_VIRTUAL_BACKGROUND_DIALOG,
  TOGGLE_VIRTUAL_BACKGROUND_EFFECT,
  TOGGLE_SHARE_SCREEN,
  HIDE_MEETING_CONTROLS,
  SET_MEETING_AUTO_ADMIT,
  DID_ADD_DEVICES_LIST,
  RESYNC_MEETING_INFO,
  SET_MEETING_DATE,
  UPDATE_PRODUCT_SEARCH_STATE,
  UPDATE_STORY_SEARCH_STATE,
  SEND_CHAT,
  LOAD_CHAT,
  DELETE_CHAT,
  ACTIVATE_FEATURES,
  TOGGLE_PRESENTER_MODE,
  VB_UPDATE_LANGUAGE,
  TOGGLE_STATS_MODE,
  PARTICIPANT_IS_RECONNECTING,
  PARTICIPANT_IS_RECONNECTED,
  DID_RECEIVE_PIXEL_STREAM_PLAYER_STATUS,
  VB_MEETING_OVERLAY,
  POPUP_DID_REQUEST_HIDE_MEETING_VEIL,
  SHOW_WARNING_NOTIFICATION,
  EXIT_ONE_THIRD_LAYOUT,
  UPDATE_ONE_THIRD_LAYOUT,
  BRIDGE_CHANNEL_CLOSED,
  UPDATE_STORYBOOK_INDEX
} from '../actions';
import { defaultClientState } from './clientReducer';

const meetingReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case DID_JOIN_MEETING: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          state: ParticipantMeetingState.IN_THE_LOUNGE,
          localUser: {
            ...(state.meeting?.localUser || {}),
            participantId: action.payload.participantId,
            role: action.payload.role
          },
          joinAt: Date.now(),
          jitsi: action.payload.room
        }
      };
    }
    case PLAY_WELCOME_VIDEO_FROM_LOUNGE: {
      if (!state.meeting?.state) return state;
      const meetingState =
        state.meeting?.state === ParticipantMeetingState.IN_THE_LOUNGE
          ? ParticipantMeetingState.SHOW_WELCOME_VIDEO
          : state.meeting?.state;
      const remoteUsers = state.meeting?.remoteUsers || {};
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          state: meetingState,
          initialParticipantsInLounge: Object.keys(remoteUsers)
        }
      };
    }

    case ENTER_MEETING_FROM_WELCOME_VIDEO: {
      const meetingState =
        state.meeting?.state === ParticipantMeetingState.SHOW_WELCOME_VIDEO
          ? ParticipantMeetingState.ENTERED_FROM_LOUNGE
          : state.meeting?.state;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          state: meetingState
        }
      };
    }

    case SET_MEETING_ID: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          meetingId: action.payload
        }
      };
    }
    case LOCAL_TRACK_AUDIO_LEVEL_DID_CHANGE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            audioLevel: action.payload.audioLevel
          }
        }
      };
    }
    case LOCAL_TRACK_AUDIO_MUTE_DID_CHANGE: {
      const previousMuteState = state.meeting?.localUser?.lastAudioMuted;
      const mute = action.payload.isMuted;
      const restoreLater = action.payload?.restoreLater;

      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            audioMuted: mute,
            lastAudioMuted: restoreLater ? previousMuteState : mute
          }
        }
      };
    }
    case VB_UPDATE_LANGUAGE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          languageCode: action.payload
        }
      };
    }

    case POPUP_VIDEO_DID_PLAY_WITH_SOUND: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          popupState: {
            ...(state.meeting?.popupState || {}),
            videoPlayWithSound: action.payload
          }
        }
      };
    }

    case POPUP_DID_REQUEST_HIDE_MEETING_VEIL: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          popupState: {
            ...(state.meeting?.popupState || {}),
            hideMeetingVeil: action.payload
          }
        }
      };
    }
    case LOCAL_TRACK_VIDEO_MUTE_DID_CHANGE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            videoMuted: action.payload.isMuted
          }
        }
      };
    }

    case REMOTE_TRACK_AUDIO_LEVEL_DID_CHANGE: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const { participantId, audioLevel } = action.payload;
      copy[participantId] = {
        ...(copy[participantId] || {}),
        audioLevel
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }
    case REMOTE_TRACK_AUDIO_MUTE_DID_CHANGE: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const { participantId, isMuted } = action.payload;
      copy[participantId] = {
        ...(copy[participantId] || {}),
        audioMuted: isMuted
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }

    case TOGGLE_ENLARGE_VIDEO: {
      const layout = state.meeting?.layout || {};
      const layoutMode = layout?.mode;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            enlargedVideoParticipantId: action.payload.shouldEnlarge
              ? action.payload.participantId
              : undefined,
            mode: action.payload.shouldEnlarge
              ? MeetingLayoutMode.NORMAL
              : layoutMode,
            oneThirdLayout: action.payload.shouldEnlarge
              ? undefined
              : layout?.oneThirdLayout
          }
        }
      };
    }

    case SET_ENLARGE_VIDEO: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            enlargedVideoParticipantId: action.payload.participantId,
            oneThirdLayout: undefined
          }
        }
      };
    }

    case REMOTE_TRACK_VIDEO_MUTE_DID_CHANGE: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const { participantId, isMuted } = action.payload;
      copy[participantId] = {
        ...(copy[participantId] || {}),
        videoMuted: isMuted
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }
    case PARTICIPANT_READY: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const { participantId } = action.payload;
      copy[participantId] = {
        ...(copy[participantId] || {}),
        ready: true
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }
    case UPDATE_REMOTE_DISPLAY_NAME: {
      const { participantId, displayName } = action.payload;
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      copy[participantId] = {
        ...(copy[participantId] || {}),
        displayName
      };

      if (state.meeting?.isPresenter) {
        return {
          ...state,
          meeting: {
            ...(state.meeting || {}),
            remoteUsers: copy
          }
        };
      } else {
        const advisorParticipantId = state.meeting?.advisorParticipantId;
        let advisorName = state.meeting?.advisorName;
        if (participantId && participantId === advisorParticipantId) {
          advisorName = displayName;
        }
        return {
          ...state,
          meeting: {
            ...(state.meeting || {}),
            remoteUsers: copy,
            advisorName
          }
        };
      }
    }

    case RESYNC_MEETING_INFO: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      Object.keys(copy).forEach((participantId) => {
        copy[participantId].shouldShowVideo = true;
      });
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }
    case ENTER_MEETING: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          state:
            action.payload?.meetingState ||
            ParticipantMeetingState.ENTERED_FROM_LOUNGE
        },
        vb: {
          ...state?.vb,
          popup: {
            open: false,
            url: '',
            darkHeader: false
          }
        }
      };
    }
    case BRIDGE_CHANNEL_OPENED: {
      if (state.meeting?.bridgeChannelOpened) {
        return state;
      }
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          bridgeChannelOpened: true
        }
      };
    }

    case BRIDGE_CHANNEL_CLOSED: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          bridgeChannelOpened: false
        }
      };
    }

    case PARTICIPANT_DID_JOIN_MEETING: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const { participantId, user } = action.payload;
      copy[participantId].jitsiUser = user;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }
    case PARTICIPANT_DID_LEAVE_MEETING: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const participantId = action.payload;
      const currentEnlarged = state.meeting?.layout?.enlargedVideoParticipantId;
      const currentPresenterIds = state.meeting?.layout?.presenterIds || [];
      delete copy[participantId];
      const isUserAdvisor =
        participantId === state.meeting?.advisorParticipantId;
      const isFileController = state?.fileViewer?.controller === participantId;
      const raiseHand = state?.meeting?.raiseHand;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy,
          advisorName: isUserAdvisor ? undefined : state.meeting?.advisorName,
          advisorParticipantId: isUserAdvisor
            ? undefined
            : state.meeting?.advisorParticipantId,
          advisorIdentityId: isUserAdvisor
            ? undefined
            : state.meeting?.advisorIdentityId,
          layout: {
            ...(state.meeting?.layout || {}),
            presenterIds: currentPresenterIds.filter(
              (p) => p !== participantId
            ),
            enlargedVideoParticipantId:
              currentEnlarged === participantId ? undefined : currentEnlarged
          },
          raiseHand: {
            approved: raiseHand?.approved,
            participantIds: raiseHand?.participantIds?.filter(
              (id) => id !== participantId
            ),
            participantInAlert: raiseHand?.participantInAlert?.filter(
              (id) => id !== participantId
            )
          }
        },
        fileViewer:
          isUserAdvisor || isFileController ? undefined : state.fileViewer,
        vb: {
          ...(state.vb || {}),
          popup: isUserAdvisor ? undefined : state.vb?.popup
        },
        teleport: state?.teleport?.teleportTo
          ? {
              ...(state?.teleport || {}),
              teleportTo: isUserAdvisor
                ? {
                    ...(state?.teleport?.teleportTo || {}),
                    siteId: state?.store?.id
                  }
                : state?.teleport?.teleportTo
            }
          : undefined,
        common3D: {
          ...(state?.common3D || {}),
          name: isUserAdvisor ? undefined : state?.common3D?.name
        }
      };
    }
    case DID_RECEIVE_MOVE_TO_SCENE_COMMAND: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteSceneId: action.payload
        }
      };
    }
    case DID_RECEIVE_CHANGE_TO_VIEW_COMMAND: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteView: action.payload
        }
      };
    }
    case DID_SLIDE_PRODUCT_IMAGES_SLIDER: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteProductImageSliderIndex: action.payload
        }
      };
    }
    case SET_IS_MEETING_PRESENTER: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          isPresenter: action.payload
        }
      };
    }
    case VB_MEETING_OVERLAY: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          isOverlayImgVisible: action.payload
        }
      };
    }
    case DID_LEAVE_MEETING: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: undefined,
          state: ParticipantMeetingState.LEFT_MEETING,
          remoteUsers: {},
          remoteSceneId: undefined,
          remoteView: undefined,
          remotePopupAction: undefined,
          clientDetailPanelParticipantId: undefined,
          showClientDetailPanel: undefined,
          popupState: undefined
        }
      };
    }
    case DID_RECEIVE_REMOTE_POPUP_ACTION: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remotePopupAction: action.payload
        }
      };
    }
    case SHOW_CLIENT_DETAIL_PANEL: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          showClientDetailPanel: true,
          clientDetailPanelParticipantId: action.payload
        }
      };
    }
    case HIDE_CLIENT_DETAIL_PANEL: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          showClientDetailPanel: false
        }
      };
    }
    case DID_RECEIVE_PARTICIPANT_USER_INFO: {
      const { identityId, participantId, role } = action.payload;
      if (participantId) {
        const remoteUsers = state.meeting?.remoteUsers || {};
        const meetingRole =
          role?.toLowerCase?.() === 'product' ? 'STUDIO' : role;
        const copy = { ...remoteUsers };
        copy[participantId] = {
          ...(copy[participantId] || {}),
          identityId,
          role: meetingRole
        };
        return {
          ...state,
          meeting: {
            ...(state.meeting || {}),
            remoteUsers: copy
          }
        };
      } else {
        return state;
      }
    }

    case DID_RECEIVE_ADVISOR_INFO: {
      const { displayName, identityId, participantId } = action.payload;
      const remoteUsers = { ...(state.meeting?.remoteUsers || {}) };
      if (remoteUsers[participantId]) {
        remoteUsers[participantId].identityId = identityId;
        remoteUsers[participantId].role = MeetingRole.ADVISOR;
      }
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          advisorName: displayName,
          advisorIdentityId: identityId,
          advisorParticipantId: participantId,
          remoteUsers
        }
      };
    }
    case UPDATE_PARTICIPANT_WISHLIST_PRODUCTS: {
      const { products, participantId } = action.payload;
      if (participantId) {
        const remoteUsers = state.meeting?.remoteUsers || {};
        const copy = { ...remoteUsers };
        copy[participantId] = {
          ...(copy[participantId] || {}),
          wishlistProducts: products
        };
        return {
          ...state,
          meeting: {
            ...(state.meeting || {}),
            remoteUsers: copy
          }
        };
      } else {
        return state;
      }
    }
    case UPDATE_PARTICIPANT_INTERESTED_PRODUCTS: {
      const { products, participantId } = action.payload;
      if (participantId) {
        const remoteUsers = state.meeting?.remoteUsers || {};
        const copy = { ...remoteUsers };
        copy[participantId] = {
          ...(copy[participantId] || {}),
          interestedProducts: products
        };
        return {
          ...state,
          meeting: {
            ...(state.meeting || {}),
            remoteUsers: copy
          }
        };
      } else {
        return state;
      }
    }
    case LATE_COMER_DID_JOIN_LOUNGE: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      copy[action.payload.participantId] = {
        ...(copy[action.payload.participantId] || {}),
        shouldShowVideo: false,
        displayName: action.payload.displayName
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }
    case PARTICIPANT_DID_ENTER_FROM_LOUNGE: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      if (copy[action.payload]) copy[action.payload].shouldShowVideo = true;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }

    case DID_ADD_REMOTE_TRACK: {
      const track = action.payload;
      const participantId = track.getParticipantId();
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const existingTracks = copy[participantId]?.tracks || [];
      copy[participantId] = {
        ...(copy[participantId] || {}),
        tracks: [...existingTracks, track]
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }

    case DID_REMOVE_REMOTE_TRACK: {
      const track = action.payload;
      const participantId = track.getParticipantId();
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const existingTracks = copy[participantId]?.tracks || [];
      copy[participantId] = {
        ...(copy[participantId] || {}),
        tracks: existingTracks.filter((t) => t.getId() !== track.getId())
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }

    case DID_ADD_LOCAL_TRACK: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            tracks: action.payload
          }
        }
      };
    }

    case DID_REMOVE_LOCAL_TRACK: {
      const track = action.payload;
      const localUser = state.meeting?.localUser || {};
      const existingTracks = localUser.tracks || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            tracks: existingTracks.filter((t) => t.getId() !== track.getId())
          }
        }
      };
    }
    case REMOTE_SHOW_HOT_SPOT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteShowHotSpotName: action.payload,
          remoteHideHotSpotName: undefined
        }
      };
    }
    case REMOTE_HIDE_HOT_SPOT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteShowHotSpotName: undefined,
          remoteHideHotSpotName: action.payload
        }
      };
    }

    case DID_ADD_DEVICES_LIST: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            availableDevices: action.payload
          }
        }
      };
    }

    case SET_AUDIO_INPUT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            activeDevices: {
              ...state.meeting?.localUser?.activeDevices,
              microphone: action.payload
            }
          }
        }
      };
    }

    case SET_AUDIO_OUTPUT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            activeDevices: {
              ...state.meeting?.localUser?.activeDevices,
              speaker: action.payload
            }
          }
        }
      };
    }

    case SET_VIDEO_INPUT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            activeDevices: {
              ...state.meeting?.localUser?.activeDevices,
              camera: action.payload
            }
          }
        }
      };
    }

    case SET_INPUT_CHANGE_AVAILABLE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            isInputChangeAvailable: action.payload
          }
        }
      };
    }

    case SET_OUTPUT_CHANGE_AVAILABLE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            isOutputChangeAvailable: action.payload
          }
        }
      };
    }

    case UPDATE_LAYOUT_MODE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            mode: action.payload,
            enlargedVideoParticipantId: undefined,
            oneThirdLayout: undefined
          }
        }
      };
    }

    case UPDATE_ONE_THIRD_LAYOUT: {
      const layout = state.meeting?.layout || {};
      const presenterId = action?.payload.presenterId;
      const enlargedId = action?.payload?.enlargedId;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(layout || {}),
            mode: MeetingLayoutMode.ONE_THIRD,
            oneThirdLayout: {
              presenterId,
              enlargedId
            },
            enlargedVideoParticipantId: undefined
          }
        }
      };
    }
    case EXIT_ONE_THIRD_LAYOUT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state?.meeting?.layout || {}),
            mode: MeetingLayoutMode.NORMAL,
            oneThirdLayout: undefined
          }
        }
      };
    }
    case KICKED_OUT_FROM_MEETING: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            kickedOut: true
          }
        }
      };
    }

    case TOGGLE_SHOW_LAYOUT_CONTROL_BUTTON: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          showLayoutControlButton: !state.meeting?.showLayoutControlButton
        }
      };
    }

    case SET_LAYOUT_STATE: {
      const meeting = state.meeting || {};
      return {
        ...state,
        meeting: {
          ...meeting,
          layout: action.payload,
          layoutToRestore: action.restoreLater ? meeting.layout : undefined
        }
      };
    }

    case UPDATE_MEETING_SERVER_URL: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          serverUrl: action.payload
        }
      };
    }

    case ADD_LAYOUT_PRESENTER: {
      const participantId = action.payload;
      const presenterIdsState = state.meeting?.layout?.presenterIds || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            presenterIds: uniq([...presenterIdsState, participantId])
          }
        }
      };
    }

    case REMOVE_LAYOUT_PRESENTER: {
      const participantId = action.payload;
      const presenterIdsState = state.meeting?.layout?.presenterIds || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            presenterIds: presenterIdsState.filter((p) => p !== participantId)
          }
        }
      };
    }

    case TOGGLE_PARTICIPANT_VISIBILITY: {
      const { participantId, toggleToVisible } = action.payload;
      const currentInvisibleIds =
        state.meeting?.layout?.invisibleParticipantIds || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            invisibleParticipantIds: toggleToVisible
              ? currentInvisibleIds.filter((p) => p !== participantId)
              : uniq([...currentInvisibleIds, participantId])
          }
        }
      };
    }

    case TOGGLE_PIN_PARTICIPANT: {
      const currentPinnedIds =
        state.meeting?.layout?.pinnedParticipantIds || [];

      const isExist = currentPinnedIds.includes(action.payload);

      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            pinnedParticipantIds: isExist
              ? currentPinnedIds.filter((id) => id !== action.payload)
              : [...currentPinnedIds, action.payload]
          }
        }
      };
    }

    case DID_RECEIVE_TOGGLE_RAISE_HAND: {
      const participantId = action.payload;
      const raiseHandList = state.meeting?.raiseHand?.participantIds || [];
      const alertList = state.meeting?.raiseHand?.participantInAlert || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          raiseHand: {
            ...(state.meeting?.raiseHand || {}),
            participantIds: raiseHandList.includes(participantId)
              ? raiseHandList.filter((p) => p !== participantId)
              : [...raiseHandList, participantId],
            participantInAlert: raiseHandList.includes(participantId)
              ? alertList.filter((a) => a !== participantId)
              : [...alertList, participantId]
          }
        }
      };
    }

    case DID_RECEIVE_PIXEL_STREAM_PLAYER_STATUS: {
      const { participantId, playerStatus } = action.payload;
      const playedList =
        state.meeting?.layout?.playerStatusParticipantIds || [];
      const newList =
        playerStatus === StreamPlayerStatus.PLAYING
          ? [...playedList, participantId]
          : playedList.filter((p) => p !== participantId);
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            playerStatusParticipantIds: newList
          }
        }
      };
    }

    case TOGGLE_RAISE_HAND: {
      const participantId = action.payload;
      const raiseHandList = state.meeting?.raiseHand?.participantIds || [];
      const alertList = state.meeting?.raiseHand?.participantInAlert || [];

      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          raiseHand: {
            participantIds: raiseHandList.includes(participantId)
              ? raiseHandList.filter((p) => p !== participantId)
              : [...raiseHandList, participantId],
            participantInAlert: raiseHandList.includes(participantId)
              ? alertList.filter((p) => p !== participantId)
              : [...alertList, participantId],
            approved: false
          }
        }
      };
    }

    case REMOVE_RAISE_HAND_IN_ALERT: {
      const alertList = state.meeting?.raiseHand?.participantInAlert || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          raiseHand: {
            ...(state.meeting?.raiseHand || {}),
            participantInAlert: alertList.filter((p) => p !== action.payload)
          }
        }
      };
    }

    case APPROVE_RAISE_HAND: {
      const myId = state.meeting?.localUser?.participantId;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          raiseHand: {
            ...(state.meeting?.raiseHand || {}),
            approved: myId === action.payload ? true : false
          }
        }
      };
    }

    case SET_RAISE_HAND_STATE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          raiseHand: {
            participantIds: action.payload
          }
        }
      };
    }

    case ZOOM_STUDIO_VIDEO: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const { studioParticipantId } = action.payload;
      if (copy[studioParticipantId]) {
        copy[studioParticipantId].role = MeetingRole.STUDIO;
      }
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy,
          layout: {
            ...(state.meeting?.layout || {}),
            studioViewState: {
              ...(state.meeting?.layout?.studioViewState || {}),
              zoomLevel: action.payload.zoomLevel
            }
          }
        }
      };
    }

    case ROTATE_STUDIO_VIDEO: {
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      const { studioParticipantId } = action.payload;
      if (copy[studioParticipantId]) {
        copy[studioParticipantId].role = MeetingRole.STUDIO;
      }
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy,
          layout: {
            ...(state.meeting?.layout || {}),
            studioViewState: {
              ...(state.meeting?.layout?.studioViewState || {}),
              rotateDeg: action.payload.rotateDeg
            }
          }
        }
      };
    }

    case SET_STUDIO_VIEW_STATE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            studioViewState: action.payload
          }
        }
      };
    }

    case VB_CLOSE_POPUP: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          popupState: {},
          remotePopupAction: undefined
        },
        productsToCompare: [],
        focusedItem: undefined
      };
    }

    case VB_OPEN_POPUP: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          popupState: {
            url: action.payload.url
          }
        }
      };
    }

    case REMOTE_POPUP_ACTION: {
      const wrappedAction = action.payload;
      const type = wrappedAction.type;
      const shouldUpdateAction =
        type === UPDATE_STORY_SEARCH_STATE ||
        type === UPDATE_PRODUCT_SEARCH_STATE ||
        type === UPDATE_STORYBOOK_INDEX;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          popupState: {
            ...(state.meeting?.popupState || {}),
            ...(type === POPUP_DID_LOAD_PAGE
              ? { url: wrappedAction.payload }
              : shouldUpdateAction
              ? { action: wrappedAction }
              : {})
          }
        }
      };
    }

    case DID_CHANGE_DOMINANT_SPEAKER: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          dominantSpeakerParticipantId: action.payload
        }
      };
    }

    case DID_CHANGE_CONNECTION_STATUS: {
      const { participantId, status } = action.payload;
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      copy[participantId] = {
        ...(copy[participantId] || {}),
        connectionStatus: status
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }

    case UPDATE_MEETING_STATS: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          stats: action.payload
        }
      };
    }

    case UPDATE_REMOTE_USER_MEETING_STATS: {
      const { participantId, stats } = action.payload;
      const remoteUsers = state.meeting?.remoteUsers || {};
      const copy = { ...remoteUsers };
      copy[participantId] = {
        ...(copy[participantId] || {}),
        stats
      };
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          remoteUsers: copy
        }
      };
    }

    case DID_GET_STORE_DEVICES: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          associatedDevices: action.payload
        }
      };
    }

    case ADVISOR_END_MEETING: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          advisorEndsMeeting: true
        }
      };
    }

    case SHOW_MEETING_NOTIFICATION: {
      const currentNotifications = state.meeting?.notifications || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          notifications: [...currentNotifications, action.payload]
        }
      };
    }

    case HIDE_MEETING_NOTIFICATION: {
      const currentNotifications = state.meeting?.notifications || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          notifications: currentNotifications.filter(
            (n) => n !== action.payload
          )
        }
      };
    }

    case OPEN_PARTICIPANT_CONTROLS: {
      const currentParticipant = state.meeting?.participantControls;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          participantControls:
            currentParticipant === action.payload ? undefined : action.payload
        }
      };
    }

    case HIDE_MEETING_CONTROLS: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          layout: {
            ...(state.meeting?.layout || {}),
            hideControls: action.payload
          }
        }
      };
    }

    case OPEN_VIRTUAL_BACKGROUND_DIALOG: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            videoEffect: {
              ...(state.meeting?.localUser?.videoEffect || {}),
              showDialog: action.payload
            }
          }
        }
      };
    }
    case TOGGLE_VIRTUAL_BACKGROUND_EFFECT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            videoEffect: {
              ...(state.meeting?.localUser?.videoEffect || {}),
              effect: action.payload
            }
          }
        }
      };
    }

    case TOGGLE_SHARE_SCREEN: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            isSharingScreen: action.payload,
            isOnPresenterMode: false
          }
        }
      };
    }

    case TOGGLE_PRESENTER_MODE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          localUser: {
            ...(state.meeting?.localUser || {}),
            isOnPresenterMode: action.payload
          }
        }
      };
    }

    case SET_MEETING_AUTO_ADMIT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          autoAdmit: action.payload
        }
      };
    }

    case SET_MEETING_DATE: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          date: action.payload
        }
      };
    }

    case SEND_CHAT: {
      const currentChat = state.meeting?.chat || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          chat: [...currentChat, action.payload]
        }
      };
    }

    case LOAD_CHAT: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          chat: uniqBy(
            [...(state.meeting?.chat || []), ...action.payload],
            (msg) => msg.id
          )
        }
      };
    }
    case DELETE_CHAT: {
      const currentChat = state.meeting?.chat || [];
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          chat: currentChat.filter((message) => message.id !== action.payload)
        }
      };
    }

    case ACTIVATE_FEATURES: {
      return {
        ...state,
        activeFeatures: action.payload
      };
    }

    case TOGGLE_STATS_MODE: {
      const status = state?.meeting?.statsMode || false;
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          statsMode: !status
        }
      };
    }

    case PARTICIPANT_IS_RECONNECTING: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          reconnect: {
            meetingState: action.payload.meetingState,
            oldParticipantId: action.payload.oldParticipantId,
            isReconnect: true,
            isReconnected: false
          }
        }
      };
    }
    case PARTICIPANT_IS_RECONNECTED: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          reconnect: {
            ...(state.meeting?.reconnect || {}),
            isReconnected: true
          }
        }
      };
    }

    case SHOW_WARNING_NOTIFICATION: {
      return {
        ...state,
        meeting: {
          ...(state.meeting || {}),
          deviceWarning: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export default meetingReducer;
