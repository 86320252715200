import { orderBy } from 'lodash';
import { iwcBrandId } from '../config';
import { IInspiration } from '../interfaces';

export const getDisplayedContext = (inspiration: IInspiration) => {
  if (inspiration.sender === iwcBrandId) return null;
  const context = inspiration.context?.toUpperCase();
  const sender = inspiration.senderName?.toUpperCase();
  if (!sender) return context;
  switch (context) {
    case 'VIDEO CLIP':
      return 'VIDEO CLIP';
    case 'INSTAGRAM':
      return `${sender} on INSTAGRAM`;
    case 'FEATURE':
      return `${sender} online`;
    case 'YOUTUBE':
      return `${sender} on YOUTUBE`;
    case 'PINTEREST':
      return `${sender} on PINTEREST`;
    case 'WEIBO':
      return `${sender} on WEIBO`;
    default:
      return `${context} | ${sender}`;
  }
};

export const orderInspirationsByPriority = (
  inspirations: IInspiration[]
): IInspiration[] => {
  return orderBy(inspirations, ['priority'], ['desc']);
};

export const getPrevNextUrlForInspirationsList = (
  inspirations: IInspiration[],
  currentInspiration: IInspiration
) => {
  if (!inspirations || inspirations.length <= 1) return [null, null];
  const index = inspirations.findIndex(
    (ins) => ins.id === currentInspiration.id
  );
  if (index < 0) return [null, null];
  if (index === 0)
    return [null, `/inspirations/${encodeURIComponent(inspirations[1].id)}`];
  if (index === inspirations.length - 1)
    return [
      `/inspirations/${encodeURIComponent(inspirations[index - 1].id)}`,
      null
    ];
  return [
    `/inspirations/${encodeURIComponent(inspirations[index - 1].id)}`,
    `/inspirations/${encodeURIComponent(inspirations[index + 1].id)}`
  ];
};

export const filterInspirationsUnderEmbargo = (inspirations: IInspiration[]) => {
  return inspirations.filter(
    (inspiration) =>
      !(inspiration.tagList || [])
        .map((tag) => tag.toUpperCase())
        .includes('#EMBARGO')
  );
};
