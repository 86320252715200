import { isUserOnMobile } from './../../utils/deviceDetector';
import { AnyAction, Dispatch } from 'redux';
import { VCAPopupContent } from '../../interfaces/vca';
import { VB_DID_CLICK_ON_HOT_SPOT } from '../actions';
import { actionOpenVCAPopup } from '../customActions/vca';

const openInstagramFilterOnMobile = () => {
  if (!isUserOnMobile()) return;
  const url = 'https://www.instagram.com/ar/722979608398236/';
  window.open(url, '_blank');
};

const handleHotSpotClick = (hotSpotName: string, dispatch: Dispatch) => {
  switch (hotSpotName) {
    case 'scene_04_hotspot':
      dispatch(actionOpenVCAPopup(VCAPopupContent.INVITATION));
      break;
    case 'scene_02_hotspot':
      openInstagramFilterOnMobile();
      break;
    default:
      break;
  }
};

export const handleVCAAction = (
  action: AnyAction,
  dispatch: Dispatch<AnyAction>
) => {
  switch (action.type) {
    case VB_DID_CLICK_ON_HOT_SPOT:
      handleHotSpotClick(action.payload, dispatch);
      break;
    default:
      break;
  }
};
