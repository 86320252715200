import { logSessionStart } from '../analytics';
import { generateV4UUID } from './identityGenerator';
import { tryGetParentWindowField } from './window';

export const sessionKey = 'insSessionId';

export const createNewSessionIfNeeded = (storeId?: string) => {
  if (!window[sessionKey] && !tryGetParentWindowField(sessionKey)) {
    const sessionId = generateV4UUID();
    window[sessionKey] = sessionId;
    logSessionStart(sessionId, storeId);
  }
};
