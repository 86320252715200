import React from 'react';
import { useSelector } from 'react-redux';
import { isProd } from '../../config';
import { IMainState } from '../../interfaces';
import {
  storesWithCookieBanner
} from '../../utils/cookie';
import { getFaceBookPixelTrackingIdByBrand } from './config';

const FaceBookPixel = ({
  brandId,
  storeId,
  children
}: {
  brandId: string;
  storeId?: string;
  children: React.ReactNode;
}) => {
  const agreedToCookie = useSelector(
    (state: IMainState) => state.clientState.agreedToCookie
  );
  const initAnalytics = () => {
    const trackingId = getFaceBookPixelTrackingIdByBrand(brandId);
    if (trackingId && isProd) {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(trackingId);
          ReactPixel.pageView();
        });
    }
  };
  React.useEffect(() => {
    if (!storesWithCookieBanner.includes(storeId)) {
      initAnalytics();
    }
  }, []);

  React.useEffect(() => {
    if (agreedToCookie) {
      initAnalytics();
    }
  }, [agreedToCookie]);
  return <div>{children}</div>;
};

export default FaceBookPixel;
