import { last } from 'lodash';
import { FileType, FileViewerLayout, MeetingLayoutMode } from '../interfaces';

const removeDateIsoString = (str: string) =>
  str?.replace(
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
    ''
  ) || str;

export const getDateIsoString = (str: string) =>
  str?.match(
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g
  )?.[0];

export const getFileNameFromUrl = (url: string) => {
  return url?.split('/')?.slice(-1)?.[0]?.split('?')?.[0] || url;
};

export const getFileExtensionFromUrl = (url: string) => {
  const fileName = removeDateIsoString(getFileNameFromUrl(url));
  return last(fileName?.split('.'))?.toLocaleLowerCase();
};

export const getThumbnailUrl = (url: string): string => {
  const fileName = getFileNameFromUrl(url);
  const fileNameNoExtension = fileName.split('.')?.[0];
  return url.replace(fileName, `${fileNameNoExtension}_thumbnail.png`);
};

export const getFileType = (url: string): FileType => {
  if (!url) return;
  const extension = getFileExtensionFromUrl(url);
  if (!extension) return;
  if (['svg'].includes(extension)) return FileType.SVG;
  if (['ttf','woff','woff2'].includes(extension)) return FileType.FONT;
  if (['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(extension))
    return FileType.IMAGE;
  if (['mp4', 'qt', 'mov', 'mpeg', 'm3u8'].includes(extension)) return FileType.VIDEO;
  if (extension === 'pdf') return FileType.PDF;
};

export const isGifUrl = (url: string) => {
  if (!url) return false;
  return getFileExtensionFromUrl(url) === 'gif';
};

export const getFileViewerLayoutFromMeetingLayout = (
  layout: MeetingLayoutMode
): FileViewerLayout => {
  if (layout === MeetingLayoutMode.PRESENTATION)
    return FileViewerLayout.PRESENTATION;
  if (layout === MeetingLayoutMode.NORMAL) return FileViewerLayout.SIDE;
  return FileViewerLayout.NORMAL;
};

export const isVideo = (url: string) => getFileType(url) === FileType.VIDEO;
export const isImage = (url: string) => getFileType(url) === FileType.IMAGE;
export const isPDF = (url: string) => getFileType(url) === FileType.PDF;
export const isSVG = (url: string) => getFileType(url) === FileType.SVG;

export const getPdfFile = (
  baseUrl: string,
  pageCount: number,
  title: string
) => {
  const pages = Array(pageCount)
    .fill('')
    .map((val, index) => index + 1);
  return {
    id: title,
    title,
    pages: pages.map((i) => `${baseUrl}page_${i}.jpg`),
    thumbnails: pages.map((i) => `${baseUrl}page_${i}_thumbnail.png`),
    hideInMenu: true
  };
};

export const removeFileExtension = (fileName: string) => {
  let name = fileName;
  const component = fileName.split('.');
  if (component.length > 1) {
    name = fileName.replace('.' + component[component.length - 1], '');
  }
  return removeDateIsoString(name)
    .replace(/\.[^.$]+$/, '')
    .trim();
};

export const removeFileNameExtension = (fileName: string) => {
  const extension = last(fileName.split('.'));
  return fileName.replace(`.${extension}`, '');
};

const getExtensionFromMimeType = (mimeType: string) => {
  const extension = mimeType.split('/')[1];
  if (extension) {
    return extension?.toLowerCase();
  }
  if (!extension && mimeType.includes('.')) {
    return mimeType.split('.')[1]?.toLowerCase();
  }
  return mimeType?.toLowerCase();
};

const getExtensionsFromMimeTypes = (mimeTypes: string[]) => {
  return mimeTypes.map((mimeType) => getExtensionFromMimeType(mimeType));
};

export const isValidMimeByAcceptedType = (
  mimeTypes: string,
  acceptedMimeTypes: string
): boolean => {
  const acceptedExtensions = getExtensionsFromMimeTypes(
    acceptedMimeTypes.split(',')
  );
  const fileExtension = getExtensionFromMimeType(mimeTypes);
  return acceptedExtensions.includes(fileExtension);
};
