import { AnyAction } from 'redux';
import { ClientState, IProduct } from '../../interfaces';
import {
  CART_ADD_PRODUCT,
  CART_ADD_QUANTITY,
  CART_REMOVE_PRODUCT,
  CART_REDUCE_QUANTITY,
  CART_UPDATE_FORM_DATA,
  CART_UPDATE_CHECKOUT_FORM_DATA,
  CART_OPEN_POPUP,
  CART_UPDATE_SEND_STATUS,
  CART_UPDATE_CHECKOUT_STEP,
  CART_UPDATE_CHECKOUT_STATUS,
  CART_UPDATE_CHECKOUT_ORDER_ID,
  CART_UPDATE_CHECKOUT_TOKEN,
  CART_CLEAR_PRODUCTS,
  CART_UPDATE_CHECKOUT_PAYMENT_STATUS,
  CART_UPDATE_SIZE
} from '../actions';
import { defaultClientState } from './clientReducer';

const shoppingCartReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case CART_ADD_PRODUCT: {
      const product = action.payload.product as IProduct;
      const items = [...(state.shoppingCart?.items || [])];
      const item = items.find((p) => p.id === product.id);

      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          open: action.payload.noAutoOpen ? false : true,
          items: item
            ? items
            : [...items, { ...product, quantity:1, size:action.payload.size}]
        }
      };
    }

    case CART_REMOVE_PRODUCT: {
      const items = state.shoppingCart?.items;
      const updatedItems = items.filter((p) => p.id !== action.payload);
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          items: updatedItems
        }
      };
    }

    case CART_ADD_QUANTITY: {
      const payload = action.payload as string;
      const items = [...(state.shoppingCart?.items || [])];
      const item = items.find((p) => p.id === payload);

      if (item) {
        const itemIndex = items.indexOf(item);
        items[itemIndex].quantity += 1;
      }

      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          items
        }
      };
    }

    case CART_REDUCE_QUANTITY: {
      const payload = action.payload as string;
      const items = [...(state.shoppingCart?.items || [])];
      const item = items.find((p) => p.id === payload);
      const itemIndex = items.indexOf(item);

      if (item.quantity > 1) {
        items[itemIndex].quantity -= 1;
      } else {
        items.splice(itemIndex, 1);
      }

      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          items
        }
      };
    }

    case CART_UPDATE_SIZE: {
      const productId = action.payload.id as string;
      const items = [...(state.shoppingCart?.items || [])];
      const item = items.find((p) => p.id === productId);
      const itemIndex = items.indexOf(item);
      items[itemIndex].size = action.payload.size;
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          items
        }
      };
    }

    case CART_UPDATE_FORM_DATA: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          formData: action.payload
        }
      };
    }

    case CART_UPDATE_CHECKOUT_FORM_DATA: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          checkoutFormData: action.payload
        }
      };
    }

    case CART_OPEN_POPUP: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          open: action.payload || false
        }
      };
    }

    case CART_UPDATE_SEND_STATUS: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          sendStatus: action.payload
        }
      };
    }

    case CART_UPDATE_CHECKOUT_STEP: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          checkout: {
            ...state.shoppingCart.checkout,
            step: action.payload
          }
        }
      };
    }

    case CART_UPDATE_CHECKOUT_STATUS: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          checkout: {
            ...state.shoppingCart.checkout,
            status: action.payload
          }
        }
      };
    }

    case CART_UPDATE_CHECKOUT_PAYMENT_STATUS: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          checkout: {
            ...state.shoppingCart.checkout,
            paymentStatus: action.payload
          }
        }
      };
    }

    case CART_UPDATE_CHECKOUT_ORDER_ID: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          checkout: {
            ...state.shoppingCart.checkout,
            orderId: action.payload
          }
        }
      };
    }

    case CART_UPDATE_CHECKOUT_TOKEN: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          checkout: {
            ...state.shoppingCart.checkout,
            token: action.payload
          }
        }
      };
    }

    case CART_CLEAR_PRODUCTS: {
      return {
        ...state,
        shoppingCart: {
          ...state.shoppingCart,
          items: []
        }
      };
    }
    default:
      return state;
  }
};

export default shoppingCartReducer;
