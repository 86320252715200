import React from 'react';

const VACBrandStyle = () => (
  <style jsx global>{`
    .options .btn {
      border-radius: 0 !important;
    }
  `}</style>
);

export default VACBrandStyle;
