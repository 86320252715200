import { TLanguage } from '../../mappers/polotno';
import { translateText } from './chatGPT';
import { getApiKey } from './awsUtils';

export async function translatePolotnoElement(element: any, targetLanguage: TLanguage): Promise<any> {
  let apiKey: string | null = null;
  
  try {
    apiKey = await getApiKey();
  } catch (error) {
    console.error('Error retrieving API key:', error);
    return element; // Return original element if API key retrieval fails
  }

  if (!apiKey) {
    console.error('API key is null or undefined');
    return element; // Return original element if API key is not available
  }

  if (element.type === 'text' && element.text) {
    try {
      const translatedText = await translateText(element.text, targetLanguage, apiKey);
      return { ...element, text: translatedText };
    } catch (error) {
      console.error('Error translating text:', error);
      return element; // Return original element if translation fails
    }
  }

  if (element.children) {
    const translatedChildren = await Promise.all(
      element.children.map((child: any) => translatePolotnoElement(child, targetLanguage))
    );
    return { ...element, children: translatedChildren };
  }

  return element;
}