import { AnyAction } from 'redux';
import {
  ADD_PRODUCT_TO_COMPARE,
  REMOVE_PRODUCT_FROM_COMPARE,
  POPUP_DID_LOAD_PAGE,
  POPUP_DID_SCROLL_PAGE,
  REMOTE_POPUP_ACTION,
  OPEN_APPOINTMENT_FROM_POPUP,
  OPEN_ENQUIRY_FROM_POPUP,
  VB_POPUP_ACTION,
  CLICK_PRODUCT_COLOR,
  SHOW_PRODUCT_DESCRIPTION,
  SHOW_PRODUCT_SPECIFICATIONS,
  UPDATE_PRODUCT_SEARCH_STATE,
  DID_SLIDE_PRODUCT_IMAGES_SLIDER,
  CART_ADD_PRODUCT_FROM_POPUP,
  UPDATE_STORY_SEARCH_STATE,
  SELECT_PRODUCT_STRAP,
  SELECT_PRODUCT_BUCKLE,
  FOCUS_ITEM,
  VIDEO_PLAYED_WITH_SOUND,
  ADVISOR_ADD_TO_WISH_LIST,
  DID_CHANGE_WISHLIST_TAB,
  VIEW_PAGE,
  SET_PRODUCT_AR_VIEWER,
  REQUEST_HIDE_MEETING_VEIL,
  UPDATE_STORYBOOK_INDEX,
  VB_POPUP_REDIRECT
} from '../../../../redux/actions';
import { ClientState } from '../../../../interfaces';
const wrapActionAsRemotePopupAction = (action: AnyAction) => ({
  type: REMOTE_POPUP_ACTION,
  payload: action
});

const wrapActionAsPopupAction = (action: AnyAction) => ({
  type: VB_POPUP_ACTION,
  payload: action
});

const AppIsRunningInPopup = (clientState: ClientState) => !clientState.vb;

const remoteControlPopupActions = [
  ADD_PRODUCT_TO_COMPARE,
  REMOVE_PRODUCT_FROM_COMPARE,
  FOCUS_ITEM,
  POPUP_DID_LOAD_PAGE,
  POPUP_DID_SCROLL_PAGE,
  CLICK_PRODUCT_COLOR,
  DID_SLIDE_PRODUCT_IMAGES_SLIDER,
  SHOW_PRODUCT_DESCRIPTION,
  SHOW_PRODUCT_SPECIFICATIONS,
  UPDATE_PRODUCT_SEARCH_STATE,
  UPDATE_STORY_SEARCH_STATE,
  UPDATE_STORYBOOK_INDEX,
  SELECT_PRODUCT_STRAP,
  SELECT_PRODUCT_BUCKLE,
  ADVISOR_ADD_TO_WISH_LIST,
  DID_CHANGE_WISHLIST_TAB,
  SET_PRODUCT_AR_VIEWER
];

const popupActions = [
  OPEN_APPOINTMENT_FROM_POPUP,
  CART_ADD_PRODUCT_FROM_POPUP,
  OPEN_ENQUIRY_FROM_POPUP,
  VIDEO_PLAYED_WITH_SOUND,
  VIEW_PAGE,
  REQUEST_HIDE_MEETING_VEIL,
  VB_POPUP_REDIRECT
];

export const sendPopupMessageToVB = (
  clientState: ClientState,
  action: AnyAction
) => {
  if (!AppIsRunningInPopup(clientState)) {
    return;
  }

  if (popupActions.includes(action.type)) {
    parent.postMessage(
      wrapActionAsPopupAction(action),
      `${location.protocol}//${location.host}`
    );
  }

  if (remoteControlPopupActions.includes(action.type)) {
    parent.postMessage(
      wrapActionAsRemotePopupAction(action),
      `${location.protocol}//${location.host}`
    );
  }
};
