import React from 'react';
import { shouldInDarkMode } from '../../utils/entitlements';
import {
  chopardAtViennaBoutiqueId,
  chopardSgMbsBoutiqueId,
  chopardSgNacBoutiqueId,
  chopardUkLondonBoutiqueId,
  chopardUsMiamiBoutiqueId,
  iwcChSchaffhausenBoutiqueId,
  iwcChZurichBoutiqueId,
  iwcChZurichVirtualBoutiqueId,
  iwcFranceParisBoutiqueId,
  iwcGermanyMunichBoutiqueId,
  iwcItalyMilanBoutiqueId,
  iwcSpainMadridBoutiqueId,
  iwcUaeDubaiBoutiqueId,
  iwcUkLondonBoutiqueId,
  jlcAuMelbourneBoutiqueId,
  louboutinFranceParisBoutiqueId,
  piagetBrandId,
  rogerDubuisSgBoutiqueId,
  zegnaUSNewYorkBoutiqueId,
  iwcWW2021Id,
  rogerDubuisWW2021Id,
  chopardHappySportVirtualExpId,
  isChopardWW2021,
  isChopardVBLite,
  iwcUsMadisonBoutiqueId,
  iwcUsMiamiBoutiqueId,
  iwcUsBalHarbourBoutiqueId,
  iwcUsRodeoDriveBoutiqueId,
  louboutinBrandId,
  iwcUsCostamesaSiteId,
  iwcUsCrcSiteId,
  iwcChGenevaSiteId,
  iwcCanadaVancouverSiteId,
  iwcSgIonBoutiqueIdV3,
  iwcSgMbsBoutiqueId,
  vacSgBoutiqueId,
  rogerDubuisWholeSaleId,
  rogerDubuisRetailId,
  chopardHighJewelleryId,
  vanCleefArpelsUsBeverlyHillsSiteId,
  vanCleefArpelsBrandId,
  iwcBrazilSiteId,
  isRDULite,
  inspifySiteId,
  inspifyTestingSiteId,
  inspifyTestBrandSiteId,
  gustoLuxeSiteId,
  inspifyBrandId,
  gustoLuxeBrandId,
  vanCleefArpelsUsSiliconValleyFairSiteId,
  inspifyGCCSiteId,
  vanCleefArpelsUsKingOfPrussiaSiteId,
  inspifyJPSilkRoadSiteId,
  chopardHappySportKorea,
  vacMarinaBaySandsBoutiqueId,
  tiffanyUsNycId,
  chopardHappySportGenevaHQ
} from './../../config';
import IWCVBStyle from './IWCStyle';
import JLCVBStyle from './JLCStyle';
import RDUVBStyle, { RDUTouchStyle } from './RDUStyle';
import ZegnaStyle from './ZegnaStyle';
import LouboutinStyle from './LouboutinStyle';
import ChopardStyle, {
  ChopardHappySportStyle,
  ChopardHappySportTouchStyle,
  ChopardHJTouchStyle,
  ChopardHSProductSearchStyle,
  ChopardWW2021Style
} from './ChopardStyle';

import { chopardChGenevaBoutiqueId } from './../../config/index';
import VACBrandStyle from './VACBrandStyle';
import { VanCleefStyle, VanCleefTouchStyle } from './VanCleefStyle';
import InspifyStyle from './InspifyStyle';
import IWCBrazilStyle, { IWCBrazilTouchStyle } from './IWCBrazilStyle';
import GustoLuxeStyle from './GustoLuxeStyle';
import TiffanyStyle from './TiffanyStyle';

export interface ChatTheme {
  header: string;
  optionButton: string;
  textBackground: string;
}

export const getBrandChatTheme = (brandId: string): ChatTheme => {
  if (brandId === piagetBrandId) {
    return {
      header: '#37687d',
      optionButton: '#45829c',
      textBackground: '#e7f8ff'
    };
  }
  if (brandId === louboutinBrandId) {
    return {
      header: '#000',
      optionButton: '#bf080e',
      textBackground: '#f7f4ec'
    };
  }
  return {
    header: '#000',
    optionButton: '#000',
    textBackground: '#f7f4ec'
  };
};

export interface JourneyTheme {
  titleBackground: string;
  titleTextColor: string;
  guideOptionBackground: string;
  guideTextColor: string;
  dismissBackground: string;
  dismissTextColor: string;
}

export const getBrandJourneyTheme = (brandId: string) => {
  if (brandId === piagetBrandId) {
    return {
      titleBackground: 'rgba(0, 0, 0, 0.8)',
      titleTextColor: '#fff',
      titlePadding: '20px 20px 20px 40px',
      titleTextSize: '22px',
      guideOptionBackground: 'rgba(13, 97, 138, 0.8)',
      guideTextColor: '#fff',
      guideTextSize: '16px',
      guidePadding: '15px 20px 15px 40px',
      dismissBackground: 'rgba(255, 255, 255, 0.8)',
      dismissTextColor: '#000'
    };
  }
  return {
    titleBackground: 'rgba(183, 169, 142, 0.8)',
    titleTextColor: '#000',
    titlePadding: '15px 15px 15px 40px',
    titleTextSize: '18px',
    guideOptionBackground: 'rgba(58, 57, 53, 0.8)',
    guideTextColor: '#fff',
    guideTextSize: '14px',
    guidePadding: '10px 15px 10px 40px',
    dismissBackground: 'rgba(243, 241, 237, 0.8)',
    dismissTextColor: '#000'
  };
};

const VBCustomStyle = ({
  storeId,
  language
}: {
  storeId;
  language: string;
}) => {
  if (isChopardWW2021(storeId)) {
    return <ChopardWW2021Style />;
  }

  if (isChopardVBLite(storeId)) {
    return <ChopardStyle />;
  }

  if (isRDULite(storeId)) {
    return <RDUVBStyle />;
  }

  switch (storeId) {
    case iwcUkLondonBoutiqueId:
    case iwcGermanyMunichBoutiqueId:
    case iwcChZurichBoutiqueId:
    case iwcChZurichVirtualBoutiqueId:
    case iwcChSchaffhausenBoutiqueId:
    case iwcSpainMadridBoutiqueId:
    case iwcItalyMilanBoutiqueId:
    case iwcFranceParisBoutiqueId:
    case iwcUaeDubaiBoutiqueId:
    case iwcWW2021Id:
    case iwcUsMadisonBoutiqueId:
    case iwcUsCostamesaSiteId:
    case iwcUsCrcSiteId:
    case iwcChGenevaSiteId:
    case iwcCanadaVancouverSiteId:
    case iwcUsMiamiBoutiqueId:
    case iwcUsBalHarbourBoutiqueId:
    case iwcUsRodeoDriveBoutiqueId:
    case iwcSgIonBoutiqueIdV3:
    case iwcSgMbsBoutiqueId:
      return <IWCVBStyle language={language} />;
    case iwcBrazilSiteId:
      return <IWCBrazilStyle />;
    case jlcAuMelbourneBoutiqueId:
      return <JLCVBStyle />;
    case rogerDubuisWW2021Id:
    case rogerDubuisSgBoutiqueId:
    case rogerDubuisWholeSaleId:
    case rogerDubuisRetailId:
      return <RDUVBStyle />;
    case zegnaUSNewYorkBoutiqueId:
      return <ZegnaStyle />;
    case louboutinFranceParisBoutiqueId:
      return <LouboutinStyle />;
    case chopardHappySportVirtualExpId:
    case chopardHappySportKorea:
    case chopardHappySportGenevaHQ:
      return <ChopardHappySportStyle storeId={storeId} />;
    case vacSgBoutiqueId:
    case vacMarinaBaySandsBoutiqueId:
      return <VACBrandStyle />;
    case vanCleefArpelsBrandId:
    case vanCleefArpelsUsBeverlyHillsSiteId:
    case vanCleefArpelsUsSiliconValleyFairSiteId:
    case vanCleefArpelsUsKingOfPrussiaSiteId:
      return <VanCleefStyle />;
    case inspifySiteId:
    case inspifyTestingSiteId:
    case inspifyTestBrandSiteId:
    case inspifyGCCSiteId:
    case inspifyJPSilkRoadSiteId:
      return <InspifyStyle />;
    case gustoLuxeSiteId:
      return <GustoLuxeStyle />;
    case tiffanyUsNycId:
      return <TiffanyStyle />;
    default:
      return null;
  }
};

export const BrandStyle = ({ brandId }: { brandId: string }) => {
  switch (brandId) {
    case inspifyBrandId:
      return <InspifyStyle />;
    case gustoLuxeBrandId:
      return <GustoLuxeStyle />;
    default:
      return null;
  }
};

export const TouchCustomStyle = ({ storeId }: { storeId: string }) => {
  React.useEffect(() => {
    if (shouldInDarkMode(storeId)) document.body.classList.add('dark-mode');
  }, []);

  if (isRDULite(storeId)) {
    return <RDUTouchStyle />;
  }

  switch (storeId) {
    case rogerDubuisWW2021Id:
    case rogerDubuisSgBoutiqueId:
    case rogerDubuisWholeSaleId:
    case rogerDubuisRetailId:
      return <RDUTouchStyle />;
    case chopardHighJewelleryId:
      return <ChopardHJTouchStyle />;
    case chopardHappySportKorea:
    case chopardHappySportGenevaHQ:
      return <ChopardHappySportTouchStyle />;
    case vanCleefArpelsUsBeverlyHillsSiteId:
    case vanCleefArpelsUsSiliconValleyFairSiteId:
    case vanCleefArpelsUsKingOfPrussiaSiteId:
      return (
        <VanCleefTouchStyle
          hidePrice={
            storeId === vanCleefArpelsUsSiliconValleyFairSiteId ||
            storeId === vanCleefArpelsUsKingOfPrussiaSiteId
          }
        />
      );
    case iwcBrazilSiteId:
      return <IWCBrazilTouchStyle />;
    default:
      return null;
  }
};

export const logoShouldDark = (storeId: string) => {
  const darkList = [
    chopardSgNacBoutiqueId,
    chopardSgMbsBoutiqueId,
    chopardUsMiamiBoutiqueId,
    chopardUkLondonBoutiqueId,
    chopardAtViennaBoutiqueId,
    chopardChGenevaBoutiqueId,
    chopardHappySportVirtualExpId,
    iwcWW2021Id
  ];

  return darkList.includes(storeId);
};

export const ProductSearchCustomStyle = ({ storeId }: { storeId: string }) => {
  const getStyle = () => {
    switch (storeId) {
      case chopardHappySportKorea:
      case chopardHappySportGenevaHQ:
        return <ChopardHSProductSearchStyle />;
      default:
        return null;
    }
  };
  return getStyle();
};
export default VBCustomStyle;
