import { useRouter } from 'next/router';
import React from 'react';
import { logEvent } from '../analytics';
import { ISharingEntity, Source } from '../interfaces';
import {
  DID_CLICK_SEND_TO_CHAT_BUTTON,
  INS_SEND_TO_CHAT
} from '../utils/constants';
import { PageView } from '../utils/pageView';
import { getKnownIframeHosts } from '../utils/window';
import { ChatMessageType } from './ChatConnectionContext';

export interface IChatContentSharingContext {
  isChatContentSelection?: boolean;
  sendToChat?: (payload: ISharingEntity) => void;
  getSendToShareButton?: (
    pageView: PageView,
    id?: string
  ) => React.ReactNode | null;
}

const defaultContext = {};

export const ChatContentSharingContext =
  React.createContext<IChatContentSharingContext>(defaultContext);

const ChatContentSharingContextContainer = (props: {
  children: React.ReactNode;
}) => {
  const sendToChat = (payload: ISharingEntity) => {
    getKnownIframeHosts().forEach((host) => {
      try {
        parent?.postMessage(
          {
            messageType: INS_SEND_TO_CHAT,
            payload
          },
          host
        );
      } catch (e) {
        console.error(e);
      }
    });
  };
  const getSendToShareButton = (pageView: PageView, id?: string) => {
    console.log(pageView);
    logEvent(DID_CLICK_SEND_TO_CHAT_BUTTON, DID_CLICK_SEND_TO_CHAT_BUTTON, {
      pageView,
      id
    });

    let onClick = () => {
      console.log('will send to chat');
    };

    switch (pageView) {
      case PageView.PRODUCT:
        onClick = () => {
          sendToChat({
            type: ChatMessageType.PRODUCT,
            content: id
          });
        };
        break;
      case PageView.INSPIRATION:
        onClick = () => {
          sendToChat({
            type: ChatMessageType.STORY,
            content: id
          });
        };
        break;
      case PageView.PRODUCT_COMPARE:
        onClick = () => {
          sendToChat({
            type: ChatMessageType.PRODUCT_COMPARE,
            content: id
          });
        };
        break;
      case PageView.PRODUCT_PERSONALIZE:
        onClick = () => {
          sendToChat({
            type: ChatMessageType.PRODUCT_PERSONALIZE,
            content: id
          });
        };
        break;
      default:
        break;
    }
    return (
      <>
        <button onClick={onClick}>SHARE TO CHAT</button>
        <style jsx>{`
          button {
            position: fixed;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            background: #000;
            border: none;
            border-radius: 0.25rem;
            padding: 10px 15px;
            z-index: 10;
          }
        `}</style>
      </>
    );
    return null;
  };
  const [contextValue, setContextValue] =
    React.useState<IChatContentSharingContext>({
      isChatContentSelection: false,
      sendToChat,
      getSendToShareButton
    });
  const router = useRouter();

  React.useEffect(() => {
    setContextValue({
      isChatContentSelection:
        router.query.source === Source.Chat || window['CHAT_CONTENT_SELECTION'],
      sendToChat,
      getSendToShareButton
    });
    if (router.query.source === Source.Chat) {
      window['CHAT_CONTENT_SELECTION'] = true;
    }
  }, []);

  return (
    <ChatContentSharingContext.Provider value={contextValue}>
      {props.children}
    </ChatContentSharingContext.Provider>
  );
};

export default ChatContentSharingContextContainer;
