import { uniqBy } from 'lodash';
import { AnyAction } from 'redux';
import { ClientState, WishlistType } from '../../interfaces';
import { DID_ACCEPT_COOKIES, DID_REJECT_COOKIES } from '../actions';
import {
  ADD_TO_WISHLIST,
  OPEN_WISHLIST_POPUP,
  UPDATE_LOUBI_FUTURE_CANVAS_STATE,
  UPDATE_MAP,
  UPDATE_SELECTED_PRODUCT,
  REMOVE_FROM_WISHLIST,
  OPEN_POPUP,
  PLAY_SOUND_EFFECT,
  UPDATE_LANDING_VIDEO,
  HIDE_CURSOR_GUIDE,
  PLAY_LOUBIFUTURE_INTRODUCTION_VIDEO_FOR_MEETING,
  UPDATE_WISHLIST,
  REPLAY_MUSIC_VIDEO
} from '../customActions/loubiFuture';
import { defaultClientState } from './clientReducer';

const loubiFutureReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case UPDATE_LOUBI_FUTURE_CANVAS_STATE: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          canvasState: action.payload
        }
      };
    }

    case UPDATE_MAP: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          map: { ...(state?.loubiFuture?.map || {}), ...action.payload }
        }
      };
    }

    case UPDATE_SELECTED_PRODUCT: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          selectedProductId: action.payload
        }
      };
    }

    case ADD_TO_WISHLIST: {
      const currentWishlist = state.loubiFuture?.wishlist?.wishlistItems || [];
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          wishlist: {
            ...(state.loubiFuture?.wishlist || {}),
            wishlistItems: uniqBy(
              [
                ...currentWishlist,
                { itemId: action.payload, itemType: WishlistType.PRODUCT }
              ],
              'itemId'
            )
          }
        }
      };
    }

    case REMOVE_FROM_WISHLIST: {
      const currentWishlist = state.loubiFuture?.wishlist?.wishlistItems || [];
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          wishlist: {
            ...(state.loubiFuture?.wishlist || {}),
            wishlistItems: currentWishlist.filter(
              (p) => p.itemId && p.itemId !== action.payload
            )
          }
        }
      };
    }

    case UPDATE_WISHLIST: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          wishlist: {
            ...(state.loubiFuture?.wishlist || {}),
            ...action.payload
          }
        }
      };
    }

    case OPEN_WISHLIST_POPUP: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          wishlist: {
            ...(state.loubiFuture?.wishlist || {}),
            open: action.payload
          }
        }
      };
    }

    case OPEN_POPUP: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          popup: action.payload
        }
      };
    }

    case PLAY_SOUND_EFFECT: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          soundEffect: {
            ...(state.loubiFuture?.soundEffect || {}),
            ...action.payload
          }
        }
      };
    }

    case UPDATE_LANDING_VIDEO: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          landingVideo: action.payload
        }
      };
    }

    case DID_ACCEPT_COOKIES: {
      return {
        ...state,
        agreedToCookie: true
      };
    }

    case DID_REJECT_COOKIES: {
      return {
        ...state,
        agreedToCookie: false
      };
    }

    case HIDE_CURSOR_GUIDE: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          hideCursorGuide: true
        }
      };
    }

    case PLAY_LOUBIFUTURE_INTRODUCTION_VIDEO_FOR_MEETING: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          playIntroductionVideosInMeeting: true
        }
      };
    }

    case REPLAY_MUSIC_VIDEO: {
      return {
        ...state,
        loubiFuture: {
          ...(state.loubiFuture || {}),
          replayMusicVideo: action.payload
        }
      };
    }

    default:
      return state;
  }
};

export default loubiFutureReducer;
