import { createHash } from './crypt';

export const getCanonical = (method: string, url: string, payload: any) => {
  let cache = [];
  const encPayload = createHash(
    JSON.stringify(payload, (_, value) => {
      if (typeof value === 'object' && value !== null) {
        // Duplicate reference found, discard key
        if (cache.includes(value)) return;

        // Store value in our collection
        cache.push(value);
      }
      return value;
    }),
    process.env.NEXT_PUBLIC_SIGNING_SECRET
  );
  cache = null;
  const canonicalRequest =
    method.toUpperCase() + '\n' + url + '\n' + encPayload;
  return canonicalRequest;
};
export function getSignatureKey(key: string, dateStamp: string) {
  const kDate = createHash(dateStamp, key);
  const kSigning = createHash('ins_request', kDate);
  return kSigning;
}

export const validateSignature = async (req) => {
  try {
    const xSignature = req.headers['x-ins-signature'];
    const requestDateTime = req.headers['x-ins-date'];
    if (xSignature) {
      const url = req.url,
        data: any = req.body || {},
        method = req.method.toUpperCase();
      const canonical = getCanonical(method, url, data);
      const hashedCanonicalRequest = createHash(
        canonical,
        process.env.NEXT_PUBLIC_SIGNING_SECRET
      );
      const algorithm = 'INS-SHA256';
      const stringToSign =
        algorithm + '\n' + requestDateTime + '\n' + hashedCanonicalRequest;
      const derivingKey = getSignatureKey(
        process.env.NEXT_PUBLIC_SIGNING_SECRET,
        requestDateTime
      );
      const signatureINS = createHash(stringToSign, derivingKey);
      const comparedSignature = `INS-SHA256;Signature=${signatureINS}`;
      if (comparedSignature === xSignature) {
        return req;
      }
    }
    return Promise.reject({
      message: 'Signatures do not match',
      responseCode: 401
    });
  } catch (error) {
    console.log('Failed to verify signature', error);
    return Promise.reject(error);
  }
};
