import { AnyAction, Dispatch } from 'redux';
import { videoBaseUrl } from '../../config';
import { ChopardAnimation } from '../../interfaces/chopard';
import {
  actionShowFullScreenVideoWithUrl,
  VB_DID_CLICK_ON_HOT_SPOT
} from '../actions';
import {
  actionUpdateChopardActiveAnimationInPano,
  SHOW_CHOPARD_STRAP_SELECTOR
} from '../customActions/chopard';

const handleHotSpotClick = (hotSpotName: string, dispatch: Dispatch) => {
  switch (hotSpotName) {
    case 'artisan_left_video':
      dispatch(
        actionShowFullScreenVideoWithUrl({
          show: true,
          url: `${videoBaseUrl}/content/converted/355646d0-f0d0-492a-8848-efba0cbb845b/chopard_leftvideo.mp4/index.m3u8`
        })
      );
      break;
    case 'artisan_right_video':
      dispatch(
        actionShowFullScreenVideoWithUrl({
          show: true,
          url: `${videoBaseUrl}/content/converted/355646d0-f0d0-492a-8848-efba0cbb845b/chopard_rightvideo.mp4/index.m3u8`
        })
      );
      break;
    case 'atrium_watches':
      // swap artisan and atrium in demo to show gravity first
      dispatch(
        actionUpdateChopardActiveAnimationInPano(ChopardAnimation.ARTISAN)
      );
      break;
    case 'artisan_watches':
      // swap artisan and atrium in demo to show gravity first
      dispatch(
        actionUpdateChopardActiveAnimationInPano(ChopardAnimation.ATRIUM)
      );
      break;
    case 'clown':
      dispatch(
        actionUpdateChopardActiveAnimationInPano(ChopardAnimation.CIRCUS)
      );
      break;
    default:
      break;
  }
};

export const handleChopardHappySportAction = (
  action: AnyAction,
  dispatch: Dispatch<AnyAction>
) => {
  switch (action.type) {
    case VB_DID_CLICK_ON_HOT_SPOT:
      handleHotSpotClick(action.payload, dispatch);
      break;
    case SHOW_CHOPARD_STRAP_SELECTOR:
      if (window['showStrapSelector'] && action.payload) {
        window['showStrapSelector']();
      }
      break;
    default:
      break;
  }
};
