import { AnyAction, Dispatch } from 'redux';
import { rduSgPanoBasePath } from '../../../../config/rduWW2021';
import {
  ClientState,
  JitsiEndpointMessage,
  MeetingRole,
  participantInMeetingStates,
  ParticipantMeetingState
} from '../../../../interfaces';
import {
  actionAddLayoutPresenter,
  actionAppointmentPopup,
  actionCloseVBPopup,
  actionDidClickHudMenu,
  actionDidClickOnHotSpot,
  actionDidReceiveChangeToViewCommand,
  actionDidReceiveMoveToSceneCommand,
  actionDidReceiveRemotePopupAction,
  actionDidReceiveRequestUserInfo,
  actionDidReceiveToggleRaiseHand,
  actionEnterMeeting,
  actionFlipPano,
  actionKickedOutFromMeeting,
  actionLateComerDidJoinLounge,
  actionLoadNewPano,
  actionLocalTrackAudioMuteDidChange,
  actionOpenVBPopup,
  actionParticipantDidEnterFromLounge,
  actionRemoteHideHotSpot,
  actionRemoteShowHotSpot,
  actionRemoveLayoutPresenter,
  actionResetAndFlipPano,
  actionSetActivePage,
  actionSetMeetingLayoutState,
  actionTeleportSelectSite,
  actionToggleEnlargeVideo,
  actionToggleParticipantVisibility,
  actionUpdateFileViewerLayout,
  actionUpdateMap as actionUpdateVBMap,
  actionUpdateMeetingLayout,
  actionUpdatePlayerControl,
  actionUpdateStudioVideoRotateDeg,
  actionUpdateStudioVideoZoomLevel
} from '../../../../redux/actions';
import {
  actionDidReceiveRemoteLoubiAirwaysAnimationState,
  actionPlayIntroductionVideo,
  actionUpdateActiveAnimationInPano,
  actionUpdateLoubiAirwaysFullScreenVideo,
  actionUpdateLoubiAirwaysSelectedModel
} from '../../../../redux/customActions/loubiAirways';

import { scrollForVoyage } from '../../../hooks/scroll';
import {
  ACTIVE_PAGE,
  ADMIT_INTO_MEETING,
  ADVISOR_INFO,
  FILE_VIEWER_LAYOUT,
  KICK_PARTICIPANT,
  MeetingCommand,
  MUTE_AUDIO_TRACK,
  PAGE_SCROLL,
  PLAYER_STATE,
  PLAY_LOUBIAIRWAYS_INTRODUCTION_VIDEO_FOR_MEETING,
  RAISE_HAND,
  RELAY_ACTION,
  REMOTE_CONTROL,
  REMOTE_CONTROL_ANIMATION,
  REQUEST_ADMITTANCE,
  REQUEST_USER_INFO,
  SYNC_MEETING_INFO,
  UNMUTE_ALL_AUDIO_TRACK,
  UNMUTE_AUDIO_TRACK,
  UPDATE_ACTIVE_ANIMATION,
  UPDATE_LOUBIAIRWAYS_FULL_SCREEN_VIDEO,
  UPDATE_SELECTED_MODEL,
  USER_INFO
} from '../../commands';
import {
  handleAdvisorInfo,
  handleUserInfo
} from '../Spectator/SpectatorCommandReceiver';

const getActionFromPayload = (payload, clientState) => {
  switch (payload.value) {
    case MeetingCommand.MOVE:
      if (payload.attributes?.sceneId) {
        return actionDidReceiveMoveToSceneCommand(payload.attributes.sceneId);
      } else {
        return;
      }
    case MeetingCommand.CHANGE_VIEW:
      if (payload.attributes?.currentView) {
        return actionDidReceiveChangeToViewCommand(
          payload.attributes.currentView
        );
      } else {
        return;
      }
    case MeetingCommand.CLICK_HOT_SPOT:
      return actionDidClickOnHotSpot(payload.attributes);
    case MeetingCommand.CLOSE_POPUP:
      return actionCloseVBPopup();
    case MeetingCommand.OPEN_POPUP:
      return actionOpenVBPopup(
        payload.attributes?.url,
        payload.attributes?.darkHeader
      );
    case MeetingCommand.POPUP_ACTION:
      return actionDidReceiveRemotePopupAction(payload.attributes);
    case MeetingCommand.ENLARGE_VIDEO: {
      const currentlyEnlargedParticipantId =
        clientState.meeting?.layout?.enlargedVideoParticipantId;
      if (
        payload.attributes?.participantId === currentlyEnlargedParticipantId &&
        !payload.attributes?.shouldEnlarge
      ) {
        return actionToggleEnlargeVideo(payload.attributes);
      }
      if (
        payload.attributes?.participantId !== currentlyEnlargedParticipantId &&
        payload.attributes?.shouldEnlarge
      ) {
        return actionToggleEnlargeVideo(payload.attributes);
      }
      return;
    }
    case MeetingCommand.TOGGLE_PARTICIPANT_VISIBILITY: {
      const currentlyInvisibleParticipantIds =
        clientState.meeting?.layout?.invisibleParticipantIds || [];
      if (
        currentlyInvisibleParticipantIds.includes(
          payload.attributes?.participantId
        ) &&
        payload.attributes?.toggleToVisible
      ) {
        return actionToggleParticipantVisibility(payload.attributes);
      }
      if (
        !currentlyInvisibleParticipantIds.includes(
          payload.attributes?.participantId
        ) &&
        !payload.attributes?.toggleToVisible
      ) {
        return actionToggleParticipantVisibility(payload.attributes);
      }
      return;
    }
    case MeetingCommand.UPDATE_LAYOUT_MODE:
      if (clientState.meeting?.layout?.mode !== payload.attributes) {
        return actionUpdateMeetingLayout(payload.attributes);
      }
      return;
    case MeetingCommand.UPDATE_STUDIO_VIDEO_ZOOM_LEVEL:
      if (
        clientState.meeting?.layout?.studioViewState?.zoomLevel !==
        payload.attributes.zoomLevel
      ) {
        return actionUpdateStudioVideoZoomLevel(
          payload.attributes.studioParticipantId,
          payload.attributes.zoomLevel
        );
      }
      return;
    case MeetingCommand.TELEPORT_TO:
      return actionTeleportSelectSite(payload.attributes);

    case MeetingCommand.UPDATE_STUDIO_VIDEO_ROTATE_DEGREE:
      if (
        clientState.meeting?.layout?.studioViewState?.rotateDeg !==
        payload.attributes.rotateDeg
      ) {
        return actionUpdateStudioVideoRotateDeg(
          payload.attributes.studioParticipantId,
          payload.attributes.rotateDeg
        );
      }
      return;
    case MeetingCommand.ADD_LAYOUT_PRESENTER:
      if (
        clientState.meeting?.layout?.presenterIds?.includes(payload.attributes)
      ) {
        return;
      }
      return actionAddLayoutPresenter(payload.attributes);
    case MeetingCommand.REMOVE_LAYOUT_PRESENTER:
      if (
        clientState.meeting?.layout?.presenterIds?.includes(payload.attributes)
      ) {
        return actionRemoveLayoutPresenter(payload.attributes);
      }
      return;
    case MeetingCommand.SHOW_HOT_SPOT:
      return actionRemoteShowHotSpot(payload.attributes);
    case MeetingCommand.HIDE_HOT_SPOT:
      return actionRemoteHideHotSpot(payload.attributes);

    case MeetingCommand.APPOINTMENT_POPUP:
      return actionAppointmentPopup(payload.attributes);
    case MeetingCommand.FLIP_PANO:
      return actionFlipPano(payload.attributes);
    case MeetingCommand.RESET_AND_FLIP_PANO:
      return actionResetAndFlipPano();
    case MeetingCommand.UPDATE_LAYOUT:
      return actionSetMeetingLayoutState(payload.attributes);
    case MeetingCommand.HUD_MENU_CLICK:
      return actionDidClickHudMenu(payload.attributes);
    case MeetingCommand.UPDATE_MAP:
      return actionUpdateVBMap(payload.attributes);
    default:
      return;
  }
};

const handleRequestAdmittance = (
  payload: JitsiEndpointMessage,
  dispatch: Dispatch
) => {
  const { participantId, displayName } = payload.attributes;
  dispatch(actionLateComerDidJoinLounge(participantId, displayName));
};

export const syncRduManufacture = (
  dispatch: Dispatch,
  advisorSceneId: string
) => {
  dispatch(
    actionLoadNewPano({
      swf: `${rduSgPanoBasePath}tour.swf`,
      xml: `${rduSgPanoBasePath}tour.xml`,
      target: 'embedframe',
      html5: 'auto',
      consolelog: location.hostname === 'localhost',
      mobilescale: 1.0,
      passQueryParameters: true,
      basepath: `${rduSgPanoBasePath}`,
      initvars: {
        startscene: advisorSceneId || 'scene_pano_looby'
      }
    })
  );
};

export const handleMeetingControllerEndpointMessage = (
  payload: JitsiEndpointMessage,
  dispatch: Dispatch,
  clientState: ClientState
) => {
  console.log('handleMeetingControllerEndpointMessage - received payload');
  console.log(payload);
  const currentUserEnteredFromLounge = participantInMeetingStates.includes(
    clientState.meeting?.state
  );
  switch (payload.type) {
    case USER_INFO:
      handleUserInfo(payload.attributes, dispatch);
      break;
    case ADVISOR_INFO: {
      handleAdvisorInfo(
        payload.attributes,
        clientState,
        dispatch,
        currentUserEnteredFromLounge
      );
      break;
    }
    case REQUEST_USER_INFO:
      dispatch(actionDidReceiveRequestUserInfo());
      break;
    case ADMIT_INTO_MEETING: {
      const { participantId, meetingState } = payload.attributes;
      const myParticipantId = clientState.meeting?.localUser?.participantId;
      if (myParticipantId === participantId || participantId === 'all') {
        dispatch(actionEnterMeeting(meetingState));
      } else dispatch(actionParticipantDidEnterFromLounge(participantId));
      break;
    }

    case SYNC_MEETING_INFO: {
      handleAdvisorInfo(
        payload.attributes,
        clientState,
        dispatch,
        currentUserEnteredFromLounge,
        true
      );
      break;
    }
    case REMOTE_CONTROL: {
      const action: AnyAction | undefined = getActionFromPayload(
        payload,
        clientState
      );
      const meetingState = clientState.meeting?.state;
      let shouldMirrorRemoteAction = false;
      if (meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE)
        shouldMirrorRemoteAction = true;
      else if (meetingState === ParticipantMeetingState.IN_THE_LOUNGE) {
        shouldMirrorRemoteAction = payload.value !== MeetingCommand.OPEN_POPUP;
      }

      if (action && shouldMirrorRemoteAction) {
        dispatch(action);
      }
      break;
    }
    case KICK_PARTICIPANT: {
      dispatch(actionKickedOutFromMeeting());
      break;
    }

    case RAISE_HAND: {
      dispatch(
        actionDidReceiveToggleRaiseHand(payload.attributes.participantId)
      );
      break;
    }

    case MUTE_AUDIO_TRACK: {
      const meeting = clientState.meeting || {};
      const localTracks = meeting.localUser?.tracks || [];
      const audio = localTracks.find((t) => t.getType() === 'audio');

      audio?.mute()?.then(() => {
        dispatch(actionLocalTrackAudioMuteDidChange(true));
      });
      break;
    }

    case UNMUTE_AUDIO_TRACK: {
      const meeting = clientState.meeting || {};
      const localTracks = meeting.localUser?.tracks || [];
      const audio = localTracks.find((t) => t.getType() === 'audio');

      audio?.unmute()?.then(() => {
        dispatch(actionLocalTrackAudioMuteDidChange(false));
      });

      break;
    }

    case UNMUTE_ALL_AUDIO_TRACK: {
      const meeting = clientState.meeting || {};
      const localTracks = meeting.localUser?.tracks || [];
      const audio = localTracks.find((t) => t.getType() === 'audio');

      if (meeting.localUser?.role !== MeetingRole.STUDIO) {
        if (audio?.isMuted()) {
          audio?.unmute()?.then(() => {
            dispatch(actionLocalTrackAudioMuteDidChange(false));
          });
        }
      }

      break;
    }

    case REQUEST_ADMITTANCE:
      handleRequestAdmittance(payload, dispatch);
      break;

    case REMOTE_CONTROL_ANIMATION: {
      dispatch(
        actionDidReceiveRemoteLoubiAirwaysAnimationState(
          payload.attributes.animation,
          payload.attributes.state
        )
      );
      break;
    }

    case UPDATE_ACTIVE_ANIMATION: {
      dispatch(
        actionUpdateActiveAnimationInPano(payload.attributes?.animation)
      );
      break;
    }

    case UPDATE_SELECTED_MODEL: {
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel(payload.attributes?.selectedModel)
      );
      break;
    }

    case UPDATE_LOUBIAIRWAYS_FULL_SCREEN_VIDEO: {
      dispatch(
        actionUpdateLoubiAirwaysFullScreenVideo(payload.attributes?.video)
      );
      break;
    }

    case PLAY_LOUBIAIRWAYS_INTRODUCTION_VIDEO_FOR_MEETING: {
      dispatch(actionPlayIntroductionVideo());
      break;
    }

    case RELAY_ACTION: {
      dispatch(payload.attributes);
      break;
    }

    case ACTIVE_PAGE: {
      dispatch(
        actionSetActivePage(
          payload.attributes?.currentPage,
          payload.attributes?.nextUrl,
          payload.attributes?.prevUrl
        )
      );
      break;
    }

    case PLAYER_STATE: {
      dispatch(actionUpdatePlayerControl(payload.attributes));
      break;
    }

    case FILE_VIEWER_LAYOUT: {
      dispatch(actionUpdateFileViewerLayout(payload.attributes));
      break;
    }

    case PAGE_SCROLL: {
      scrollForVoyage(payload.attributes);
      break;
    }

    default:
      break;
  }
};
