import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import {
  DID_RECEIVE_REMOTE_COMMON3D_ANIMATION_STATE,
  UPDATE_COMMON3D_ACTIVE_ANIMATION_IN_PANO,
  UPDATE_COMMON3D_ANIMATION
} from '../customActions/common3DAction';
import { defaultClientState } from './clientReducer';

const common3DReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case UPDATE_COMMON3D_ANIMATION:
    case DID_RECEIVE_REMOTE_COMMON3D_ANIMATION_STATE: {
      return {
        ...state,
        common3D: {
          ...(state.common3D || {}),
          animationState: {
            ...(state.common3D?.animationState || {}),
            [action.payload.animation]: action.payload.state
          }
        }
      };
    }
    case UPDATE_COMMON3D_ACTIVE_ANIMATION_IN_PANO: {
      return {
        ...state,
        common3D: {
          ...(state.common3D || {}),
          name: action.payload.animation
        }
      };
    }

    default:
      return state;
  }
};

export default common3DReducer;
