import { performProductSearchWithQuery } from '../../clientSideServices/products';
import { louboutinBrandId } from '../../config';
import { IMainState } from '../../interfaces';
import { ProductGender, ProductType } from '../../interfaces/loubiairways';
import { actionUpdateCatalog } from '../customActions/loubiAirways';

export const actionGetLoubiAirwaysCatalog = (
  type?: ProductType,
  gender?: ProductGender,
  index = 0
) => {
  return (dispatch, getState) => {
    const batchSize = 30;
    if (index === 0) {
      dispatch(actionUpdateCatalog({ products: [], hasMoreResults: true }));
    }
    dispatch(actionUpdateCatalog({ show: true, loading: true, type, gender }));
    const genderQuery = gender ? `gender: '${gender}'` : '';
    const typeQuery = (() => {
      if (type === 'leather goods') {
        return `(or type_en: 'bags' type_en: 'leather goods')`;
      }
      return type ? `type_en:'${type}'` : '';
    })();
    return performProductSearchWithQuery(
      index,
      batchSize,
      louboutinBrandId,
      `(and ${genderQuery} ${typeQuery} brand: '${louboutinBrandId}' tag: 'loubiairwaysjp')`
    )
      .then((result) => {
        const existingProducts =
          (getState() as IMainState).clientState.loubiAirways?.catalog
            ?.products || [];
        dispatch(
          actionUpdateCatalog({
            products: [...existingProducts, ...result.data],
            index: index + result.data.length,
            hasMoreResults: result.data.length === batchSize
          })
        );
      })
      .finally(() => {
        dispatch(actionUpdateCatalog({ loading: false }));
      });
  };
};
