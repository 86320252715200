import { Source } from '../interfaces';
import { actionOpenVBPopup } from '../redux/actions';

export enum PageView {
  LEGACY_QR_CODE = 'LEGACY_QR_CODE',
  VIRTUAL_BOUTIQUE = 'VIRTUAL_BOUTIQUE',
  PRODUCT = 'PRODUCT',
  PRODUCT_360 = 'PRODUCT_360',
  CAMPAIGN = 'CAMPAIGN',
  INSPIRATION = 'INSPIRATION',
  INSPIRATION_BY_TAG = 'INSPIRATION_BY_TAG',
  PRODUCT_INSPIRATIONS = 'PRODUCT_INSPIRATIONS',
  PRODUCT_STORIES = 'PRODUCT_STORIES',
  STORYBOOK_BY_TAG = 'STORYBOOK_BY_TAG',
  STORY_JOURNEY = 'STORY_JOURNEY',
  SHOWCASE = 'SHOWCASE',
  PRODUCT_SEARCH = 'PRODUCT_SEARCH',
  STORY_SEARCH = 'STORY_SEARCH',
  PRODUCT_COMPARE = 'PRODUCT_COMPARE',
  WISHLIST = 'WISHLIST',
  PICK_OF_THE_MONTH = 'PICK_OF_THE_MONTH',
  PRODUCT_PERSONALIZE = 'PRODUCT_PERSONALIZE',
  NUDGE = 'NUDGE',
  STORYBOOK = 'STORYBOOK',
  SCENE = 'SCENE',
  DOCUMENTS = 'DOCUMENTS',
  HUB_HOME = 'HUB.HOME',
  HUB_LOGIN = 'HUB.LOGIN',
  HUB_CONTACT_CENTER = 'HUB.CONTACT_CENTER',
  HUB_CHAT_DETAILS = 'HUB.CHAT_DETAILS',
  HUB_NUDGES = 'HUB.NUDGES',
  HUB_NUDGES_CREATE = 'HUB.NUDGES_CREATE',
  HUB_NUDGES_CREATE_FROM_TEMPLATE = 'HUB.NUDGES_CREATE_FROM_TEMPLATE',
  HUB_NUDGES_TEMPLATE_CREATE = 'HUB.NUDGES_TEMPLATE_CREATE',
  HUB_NUDGES_VIEW = 'HUB.NUDGES_VIEW',
  HUB_SESSION_OVERVIEW = 'HUB.SESSION_OVERVIEW',
  HUB_MEETING_ROOMS = 'HUB.MEETING_ROOMS',
  HUB_NUDGE_TEMPLATES = 'HUB.NUDGE_TEMPLATES',
  HUB_NUDGE_TEMPLATES_SELECT = 'HUB.NUDGE_TEMPLATES_SELECT',
  HUB_NUDGE_TEMPLATE_VIEW = 'HUB.NUDGE_TEMPLATE_VIEW',
  HUB_NUDGE_TEMPLATE_EDIT = 'HUB.NUDGE_TEMPLATE_EDIT',
  HUB_NUDGE_TEMPLATE_MANAGE = 'HUB.NUDGE_TEMPLATE_MANAGE',
  HUB_SESSION_CREATE = 'HUB.SESSION_CREATE',
  HUB_SESSION_EDIT = 'HUB.SESSION_EDIT',
  HUB_MEETING_ROOM_CREATE = 'HUB.MEETING_ROOM_CREATE',
  HUB_MEETING_ROOM_EDIT = 'HUB.MEETING_ROOM_EDIT',
  HUB_NUDGES_COPY = 'HUB.NUDGES_COPY',
  HUB_NUDGE_TEMPLATE_COPY = 'HUB.NUDGES_TEMPLATE_COPY',
  HUB_USERMANAGEMENT = 'HUB.USERMANAGEMENT',
  HUB_TEAMS = 'HUB.TEAMS',
  HUB_CREATE_TEAMS = 'HUB.CREATE_TEAMS',
  HUB_TEAM_DETAIL = 'HUB.TEAM_DETAIL',
  HUB_ACCOUNTSETTINGS = 'HUB.ACCOUNTSETTINGS',
  HUB_BRANDING = 'HUB.BRANDING',
  HUB_ACCOUNT = 'HUB.ACCOUNT',
  HUB_ASSET_LIBRARY = 'HUB.ASSET_LIBRARY',
  MEETING_HEALTH_CHECK = 'MEETING_HEALTH_CHECK',
  LAUNCH_EVENT = 'LAUNCH_EVENT',
  ANIMATION = 'ANIMATION',
  CONDITIONS_OF_SALE = 'CONDITIONS_OF_SALE',
  MEETING_LANDING_SHARE = 'MEETING_LANDING_SHARE',
  HUB_GALLERY = 'HUB.GALLERY',
  HUB_STORYBOOK = 'HUB.STORYBOOK',
  HUB_SCENE = 'HUB.SCENE',
  HUB_SCENE_TEMPLATE = 'HUB.SCENE_TEMPLATE',
  HUB_CONTACT_SUPPORT = 'HUB.CONTACT_SUPPORT',
  INSPIFY_CONTACT_SUPPORT = 'INSPIFY_CONTACT_SUPPORT',
  HUB_DEVICES_MANAGEMENT = 'HUB.DEVICES_MANAGEMENT',
  HUB_DEVIVES_MONITORING = 'HUB.DEVICES_MONITORING'
}

export const BILLING_URL =
  'https://billing.stripe.com/p/login/3csdUmggY1T4b1CfYY';

export const getPath = (type: PageView) => {
  switch (type) {
    case PageView.PRODUCT:
      return 'products';
    case PageView.PRODUCT_360:
      return 'product360';
    case PageView.CAMPAIGN:
      return 'campaign';
    case PageView.INSPIRATION:
      return 'inspirations';
    case PageView.INSPIRATION_BY_TAG:
      return 'inspirationbytag';
    case PageView.PRODUCT_INSPIRATIONS:
      return 'productinspirations';
    case PageView.PRODUCT_STORIES:
      return 'productstories';
    case PageView.STORYBOOK:
      return 'storybook';
    case PageView.STORYBOOK_BY_TAG:
      return 'storybookbytag';
    case PageView.STORY_JOURNEY:
      return 'storyjourney';
    case PageView.SHOWCASE:
      return 'showcase';
    case PageView.PRODUCT_SEARCH:
      return 'productsearch';
    case PageView.STORY_SEARCH:
      return 'storysearch';
    case PageView.PRODUCT_COMPARE:
      return 'compare';
    case PageView.WISHLIST:
      return 'wishlist';
    case PageView.PICK_OF_THE_MONTH:
      return 'potm';
    case PageView.PRODUCT_PERSONALIZE:
      return 'personalize';
    case PageView.NUDGE:
      return 'nudge';
    case PageView.ANIMATION:
      return 'animation';
    case PageView.DOCUMENTS:
      return 'doc';
    case PageView.CONDITIONS_OF_SALE:
      return 'conditionsofsale';
    case PageView.MEETING_LANDING_SHARE:
      return 'meet';
    case PageView.SCENE:
      return 'scene';
    default:
      return null;
  }
};

export const getPageViewFromPath = (path: string): PageView | undefined => {
  const match = Object.keys(PageView).find(
    (key) => getPath(PageView[key]) === path
  );
  if (match) {
    return PageView[match];
  }
  return;
};

export const getPageViewFromUrl = (url: string): PageView | undefined => {
  const match = Object.keys(PageView).find(
    (key) =>
      url.includes('/' + getPath(PageView[key]) + '/') ||
      url.includes('/' + getPath(PageView[key]) + '?')
  );
  if (match) {
    return PageView[match];
  }
  return;
};

export const getPopupAction = (
  hostUrl: string,
  type: PageView,
  id?: string,
  brandId?: string,
  storeId?: string,
  language?: string
) => {
  const path = getPath(type);
  if (!path) return null;
  if (type !== PageView.PRODUCT_SEARCH && !id) {
    return null;
  }
  const url = `${hostUrl}/${path}/${
    type === PageView.PRODUCT_SEARCH ? brandId : id
  }?source=${Source.Showroom}&store=${storeId}${
    language ? `&language=${language}` : ''
  }`;
  return actionOpenVBPopup(url);
};
