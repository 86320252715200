import { isToday, getDay, isSameDay, addHours, format } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';

export const getFormattedDate = (date: Date = new Date()) =>
  formatToTimeZone(date, 'YYYY-MM-DD', { timeZone: 'Asia/Singapore' });

export const getFormattedClock = (date: Date | string) => {
  if (typeof date === 'string') {
    const d = new Date(date);
    const minutes = d.getMinutes();
    return `${d.getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
  }
};

export const getNow = () => new Date();
export const getNowISOString = () => new Date().toISOString();

export const isDateToday = (date: Date) => isToday(date);
export const isDateSameDay = (date: Date, additionalHours: number) =>
  isSameDay(date, addHours(new Date(), additionalHours));

export const formatTo24HourTime = (time: string) => {
  if (!time) {
    return '';
  }

  let hour = parseInt(time.split(':')[0]);
  const minutes = time.split(':')[1].substring(0, 2);

  if (time.indexOf('PM') > -1 && hour !== 12) {
    hour = hour + 12;
  }

  return `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const isDateStringValid = (dateString: string) => {
  return !Number.isNaN(Date.parse(dateString));
};

export const getWeekday = (date: Date) => {
  const allWeekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];
  return allWeekdays[getDay(date)];
};

export const isValidDate = (date) =>
  date instanceof Date && !isNaN(date.valueOf());

export const dateFormat = (date: string, formatType: string) =>
  new Date(date).toString() !== 'Invalid Date'
    ? format(new Date(date), formatType)
    : 'unknown';
