export enum ChopardAnimation {
  ARTISAN = 'ARTISAN',
  ATRIUM = 'ATRIUM',
  CIRCUS = 'CIRCUS'
}

export interface IChopardState {
  remoteAnimation?: IChopardAnimationState;
  activeAnimation?: ChopardAnimation;
  selectedModel?: ISelectedModel;
  showProductDetails?: boolean;
  productDetailsScrolledPercentage?: number;
}

export interface IChopardAnimationState {
  [key: string]: IChopard3DAnimationState;
}

export interface IChopard3DAnimationState {
  selectedProduct?: IAnimationProduct;
  flag?: REMOTE_ACTION;
  position?: number[];
  scale?: number[];
  rotation?: number[];
  focusItem?: number;
  strArray?: string[];
}

export interface IChopardCircusState extends IChopard3DAnimationState {
  hoopsPosition?: number[][];
}
export enum REMOTE_ACTION {
  FOCUS = 'FOCUS',
  UN_FOCUS = 'UN_FOCUS',
  CHANGE_STRAP = 'CHANGE_STRAP',
  EXPLORE = 'EXPLORE',
  MOUSE_CONTROL = 'MOUSE_CONTROL'
}
export interface IChopardHoop {
  position?: number[];
}
export interface IAnimationProduct {
  id?: string;
  modelName?: string;
  modelCode?: string;
  lowPolyModelUrl?: string;
  highPolyModelUrl?: string;
}
export interface ISelectedModel {
  model: IAnimationProduct;
  state?: REMOTE_ACTION;
  position?: number[];
  scale?: number[];
}
