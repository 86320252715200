import { AnyAction } from 'redux';
import { JitsiMeetingRoom } from '../../../../interfaces';
import { MIRROR_ACTION } from '../../../../redux/actions';
import { tryToSendMessage } from '../../commands';

export const sendCommandForMirroringAction = (
  room: JitsiMeetingRoom,
  action: AnyAction
) => {
  const message = {
    to: action.payload.to || '',
    type: MIRROR_ACTION,
    attributes: action.payload.action
  };
  tryToSendMessage(room, message);
};
