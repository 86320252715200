import { assetBaseUrl, videoBaseUrl } from '../config';
import {
  getDateIsoString,
  getFileNameFromUrl,
  isPDF,
  isVideo,
  removeFileExtension
} from '../utils/file';
import { generateV4UUID } from '../utils/identityGenerator';
import { toS3Url } from '../utils/image';
import { format } from 'date-fns';
import {
  IFilePageSettings,
  ILibraryScene,
  ISceneLayer,
  IStorybook,
  IStorybookPage,
  IStoryBookPageType,
  IStorybookSettings,
  IStorybookStatus,
  IStorybookVisibility,
  SBMetadata
} from '../interfaces';
import {
  getLanguageJSON,
  getSBPageSettingString,
  getSBStats
} from '../advisorHub/utils/storybook';
import { IFile } from './../interfaces/index';
import { isString, sortBy } from 'lodash';
import { mapLanguageToImageName } from '../advisorHub/clientSideServices/library';
import { checkAndFixPolotnoJsonString } from './polotno';

export interface StorybookFilesResponse {
  key: string;
  pageBucket: string;
  pages: string[];
  metadata: SBMetadata;
}

export const mapUrl = (bucket: string, key: string) => {
  if (bucket === 'inspify-videos') {
    return `${videoBaseUrl}/${key}`;
  }
  if (bucket === 'inspify-assets') {
    return `${assetBaseUrl}/${key}`;
  }

  return toS3Url(`${bucket}/${key}`);
};

export const getPagesInPayload = (key: string, pages: string[]): string[] => {
  if (isVideo(key)) {
    return pages.filter((item) => getFileNameFromUrl(item) === 'index.m3u8');
  }
  const filtered = pages.filter(
    (item) =>
      !getFileNameFromUrl(item).includes('_thumbnail.') &&
      !getFileNameFromUrl(item).includes('_preview.') &&
      !getFileNameFromUrl(item).includes('.webp') &&
      !item.endsWith('/') &&
      !isPDF(item)
  );
  return sortBy(filtered, [
    (item) =>
      Number(getFileNameFromUrl(item).replace('page_', '').replace('.jpg', ''))
  ]);
};

export const getThumbnailOfPagesInPayload = (
  pages: string[],
  allItems: string[]
): string[] => {
  let thumbnails = [];
  pages.forEach((page) => {
    const pageKeyNoExtension = page.replace(/\.[^.$]+$/, '');
    const thumbnail =
      allItems.find(
        (item) =>
          item.includes(pageKeyNoExtension + '_preview') ||
          item.includes(pageKeyNoExtension + '_thumbnail') ||
          item.includes('preview.0000000.jpg') ||
          item.includes('poster.0000000.jpg') ||
          item.includes('poster.0000001.jpg')
      ) || page;
    thumbnails = [...thumbnails, thumbnail];
  });
  return thumbnails;
};

export const mapStorybookFilesResponseToStorybook = (
  payload: StorybookFilesResponse
): IStorybook => {
  const { key, pageBucket, pages, metadata } = payload;

  const cleanTitle = getFileNameFromUrl(removeFileExtension(key));

  const pagesInPayload = getPagesInPayload(key, pages);

  const thumbnails = getThumbnailOfPagesInPayload(pagesInPayload, pages);

  const dateInKey = getDateIsoString(key);

  const shouldMuteParticipant = payload.metadata?.silence === 'false';

  return {
    id: key,
    title: cleanTitle,
    subtitle: dateInKey
      ? format(new Date(dateInKey), 'p - MMM dd, yyyy')
      : undefined,
    pages: pagesInPayload.map((page, i) => ({
      id: generateV4UUID(),
      url: mapUrl(pageBucket, page),
      thumbnail: mapUrl(pageBucket, thumbnails[i]),
      pageType: isVideo(page) ? 'video' : 'image',
      settings: mapStorybookSettingsObjectToString(
        isVideo(page)
          ? {
              muteParticipant: shouldMuteParticipant,
              exportable: true,
              type: 'video'
            }
          : { exportable: true, type: 'image' }
      )
    })),
    metadata: metadata
  };
};

export interface IStorybookPayload {
  id: string;
  brand: string;
  title: string;
  subtitle: string;
  settings: string;
  status: string;
  pages: {
    id?: string;
    title?: string;
    subtitle?: string;
    settings?: string;
    overlay?: string;
    url?: string;
    pageType?: string;
  }[];
  visibility: IStorybookVisibility;
  visibilityScope?: string[];
  tags?: string[];
  createdBy: string;
  createdAt: string;
  modifiedBy?: string;
  modifiedAt?: string;
  version: number;
  featuredImage?: string;
  keys: string[] | [];
}

const mapSettingToTheLatestVersion = (
  settings: string,
  storybookSetting?: boolean
) => {
  if (!settings) {
    return JSON.stringify({
      settingJSONVersion: 1,
      settingStorybookVersion: storybookSetting ? 1 : undefined
    });
  }
  const settingObj = mapStorybookSettingsStringToObject(settings);
  settingObj.settingJSONVersion = 1;
  if (storybookSetting) {
    settingObj.settingStorybookVersion = 1;
  }
  return JSON.stringify(settingObj);
};

export const mapStorybookToPayload = (
  storybook: IStorybook,
  userId: string,
  brandId: string,
  doNotConvertToLatestVersion?: boolean
): IStorybookPayload => {
  const {
    id,
    brand,
    title,
    subtitle,
    settings,
    pages,
    version,
    visibility,
    visibilityScope,
    tags,
    createdBy,
    createdAt,
    featuredImage,
    keys
  } = storybook;

  const mappedContent = pages?.map((item) => ({
    id: item.id || '',
    title: item.title || '',
    subtitle: item.subtitle || '',
    settings: doNotConvertToLatestVersion
      ? item.settings
      : mapSettingToTheLatestVersion(item.settings || ''),
    url: item.url || '',
    pageType: item.pageType || '',
    thumbnail: item.thumbnail || '',
    overlay: item.overlay ? checkAndFixPolotnoJsonString(item.overlay) : '',
    modifiedAt: item.hasChanged
      ? new Date().toISOString()
      : item.modifiedAt || undefined
  }));

  return {
    id: id || generateV4UUID(),
    brand: brand || brandId,
    title: title || '',
    subtitle: subtitle || '',
    keys: keys || [],
    status: 'draft',
    settings: doNotConvertToLatestVersion
      ? settings
      : mapSettingToTheLatestVersion(settings || '', true),
    version: version || 1,
    visibility: visibility || 'private',
    visibilityScope,
    tags,
    pages: mappedContent,
    createdBy: createdBy || userId,
    createdAt: createdAt || new Date().toISOString(),
    modifiedBy: createdAt ? userId : '',
    modifiedAt: createdAt ? new Date().toISOString() : '',
    featuredImage: featuredImage || ''
  };
};

const checkAndFixPageOverlay = (pages: IStorybookPage[]) =>
  pages.map((page) => ({
    ...page,
    ...(page.overlay
      ? { overlay: checkAndFixPolotnoJsonString(page.overlay) }
      : {})
  }));

export const mapResponseToStorybook = (payload: IStorybook): IStorybook => {
  const stats = getSBStats(payload);

  return {
    ...payload,
    pages: payload?.pages?.length
      ? checkAndFixPageOverlay(payload.pages)
      : null,
    status: String(payload.status) as IStorybookStatus,
    stats
  };
};

const mapStorybookPageToPageForPublish = (
  pages: IStorybookPage[]
): IStorybookPage[] =>
  pages.map((page) => ({
    id: page.id || '',
    title: page.title || '',
    subtitle: page.subtitle || '',
    settings: page.settings || '',
    thumbnail: page.thumbnail || '',
    url: page.url || '',
    pageType: (page.pageType || '') as IStoryBookPageType,
    metadata: page.metadata || {}
  }));

export const mapResponseToStorybookForPublish = (
  payload: IStorybook
): IStorybook => {
  const mapped = mapResponseToStorybook(payload);
  return {
    id: mapped.id || '',
    brand: mapped.brand || '',
    title: mapped.title || '',
    subtitle: mapped.subtitle || '',
    settings: mapped.settings || '',
    publishedBy: mapped.publishedBy || '',
    publishedAt: mapped.publishedAt || '',
    featuredImage: mapped.featuredImage || '',
    status: mapped.status || 'draft',
    createdBy: mapped.createdBy || '',
    pages: payload?.pages?.length
      ? mapStorybookPageToPageForPublish(mapped.pages)
      : null
  };
};

const isNewSettingVersion = (settings: string | IStorybookSettings) => {
  if (!settings) return false;
  if (isString(settings)) {
    return settings.includes('settingJSONVersion');
  } else {
    return settings.settingJSONVersion !== undefined;
  }
};

export const isNewStorybookSettingVersion = (
  settings: string | IStorybookSettings
) => {
  if (!settings) return false;
  if (isString(settings)) {
    return settings.includes('settingStorybookVersion');
  } else {
    return settings.settingStorybookVersion !== undefined;
  }
};

export const mapStorybookSettingsStringToObject = (
  settings: string
): IStorybookSettings => {
  if (!settings) return {};

  if (isNewSettingVersion(settings)) {
    return JSON.parse(settings);
  }

  const settingsArray = settings.split(',').map((s) => s.trim());
  const color = settingsArray
    ?.find((s) => s.startsWith('color'))
    ?.split('=')[1];
  const text = settingsArray?.find((s) => s.startsWith('text'))?.split('=')[1];
  const background = settingsArray
    ?.find((s) => s.startsWith('background'))
    ?.split('=')[1];

  const duration = settingsArray
    .find((s) => s.startsWith('duration'))
    ?.split('=')[1];
  return {
    color,
    text,
    background,
    duration: duration !== undefined && Number(duration),
    autoplay: settingsArray.includes('autoplay=true')
      ? true
      : settingsArray.includes('autoplay=false')
      ? false
      : undefined,
    videoLoop: settingsArray.includes('videoLoop=true')
      ? true
      : settingsArray.includes('videoLoop=false')
      ? false
      : undefined,
    muteParticipant: settingsArray.includes('muteParticipant=true')
      ? true
      : settingsArray.includes('muteParticipant=false')
      ? false
      : undefined
  };
};

export const mapSceneToStorybookPage = (
  scene: ILibraryScene
): IStorybookPage => {
  const { id, content, modifiedAt } = scene;
  const { name, settings, url, thumbnail, title, subtitle, metadata } =
    content || {};

  return {
    id: id || generateV4UUID(),
    url: url || '',
    title: title || '',
    subtitle: subtitle || '',
    thumbnail: thumbnail || '',
    pageType: 'scene',
    settings: settings || '',
    modifiedAt: modifiedAt || '',
    metadata: metadata || {},
    name: name || ''
  };
};

export const getFeaturedImageForScene = (scene: ILibraryScene): string => {
  const thumbnail = scene.content?.thumbnail;
  if (!thumbnail) return '';
  try {
    const thumbObj = JSON.parse(thumbnail);
    return thumbObj['en'] || Object.values(thumbObj)[0];
  } catch (error) {
    return thumbnail;
  }
};

export const getPublicThumbnailForScene = (sceneId: string) => {
  const key = `scenes/${sceneId}/featuredImage.jpg`;
  const bucket = 'inspify-campaignpics';
  const fullUrl = mapUrl(bucket, key);
  return {
    key,
    bucket,
    fullUrl
  };
};

export const mapSceneToStorybook = (scene: ILibraryScene): IStorybook => {
  const { id, content, createdAt, createdBy, modifiedAt, modifiedBy } = scene;
  const {
    name,
    description,
    settings,
    url,
    thumbnail,
    title,
    subtitle,
    metadata
  } = content || {};

  const featuredImage = getPublicThumbnailForScene(scene.id).fullUrl;
  return {
    id: id || generateV4UUID(),
    title: name || '',
    subtitle: description || '',
    createdAt: createdAt || '',
    createdBy: createdBy || '',
    modifiedAt: modifiedAt || '',
    modifiedBy: modifiedBy || '',
    settings,
    featuredImage: featuredImage || '',
    pages: [
      {
        id: id || generateV4UUID(),
        url: url || '',
        title: title || '',
        subtitle: subtitle || '',
        thumbnail: thumbnail || '',
        pageType: 'scene',
        settings: settings || '',
        modifiedAt: modifiedAt || '',
        metadata: metadata || {},
        name: name || ''
      }
    ]
  };
};

export const mapLatestSceneToStorybookPage = (
  sceneFromLibrary: ILibraryScene,
  sceneFromStorybook: IStorybookPage
): IStorybookPage => {
  if (!sceneFromLibrary) return sceneFromStorybook;
  const sceneToSBPage = mapSceneToStorybook(sceneFromLibrary).pages[0];
  const settingsFromStorybook = mapStorybookSettingsStringToObject(
    sceneFromStorybook.settings
  );
  const settingsFromSceneLibrary = mapStorybookSettingsStringToObject(
    sceneToSBPage.settings
  );
  const newSettings = mapStorybookSettingsObjectToString({
    ...settingsFromStorybook,
    layer: settingsFromSceneLibrary.layer
  });
  return {
    ...sceneToSBPage,
    overlay: sceneFromStorybook.overlay,
    settings: newSettings,
    title: sceneFromStorybook.title,
    subtitle: sceneFromStorybook.subtitle
  };
};

export const mapStorybookSettingStringToDocumentSettings = (
  settings: string,
  alternateId: string
): IFilePageSettings => {
  if (!settings)
    return {
      pageId: alternateId
    };
  if (isNewSettingVersion(settings)) {
    const parsedSettings = JSON.parse(settings);
    return {
      ...parsedSettings,
      pageId: alternateId
    };
  }

  const storybookSetting = mapStorybookSettingsStringToObject(settings);

  const settingsArray = settings.split(',').map((s) => s.trim());
  const title =
    settingsArray?.find((s) => s.startsWith('title'))?.split('=')[1] || '';
  const subtitle =
    settingsArray?.find((s) => s.startsWith('subtitle'))?.split('=')[1] || '';
  const pageId =
    settingsArray?.find((s) => s.startsWith('pageId'))?.split('=')[1] || '';
  return {
    ...storybookSetting,
    title: title ? decodeURIComponent(title) : title,
    subtitle: subtitle ? decodeURIComponent(subtitle) : subtitle,
    pageId: pageId ? decodeURIComponent(pageId) : pageId
  };
};

export const mapStorybookSettingsObjectToString = (
  settings: IStorybookSettings
): string => {
  return JSON.stringify({ ...settings, settingJSONVersion: 1 });
};

export const mapStorybookToDocument = (storybook: IStorybook): IFile => {
  return {
    id: storybook.id,
    title: storybook.title,
    pages: storybook.pages.map((page) => page.url),
    thumbnails: storybook.pages.map((page) => page.thumbnail || page.url),
    pagesSetting: storybook.pages.map((page) =>
      getSBPageSettingString(page.settings, storybook.settings, {
        title: page.title || '',
        subtitle: page.subtitle || ''
      })
    ),
    type: 'storybook',
    silences: storybook.pages.map((page) => page.metadata?.silence === 'true')
  };
};

export const appendNewValueIntoSetting = (
  setting: string,
  payload: IStorybookSettings
) =>
  mapStorybookSettingsObjectToString({
    ...mapStorybookSettingsStringToObject(setting),
    ...payload
  });

export const appendLayerIntoSetting = (setting: string, layer: ISceneLayer) =>
  appendNewValueIntoSetting(setting, { layer });

export const addTitleAndSubtitleIntoSettingString = ({
  title,
  subtitle,
  settings
}: {
  title?: string;
  subtitle?: string;
  settings?: string;
}) => {
  if (isNewSettingVersion(settings)) {
    const parsedSettings = JSON.parse(settings);
    return mapStorybookSettingsObjectToString({
      ...parsedSettings,
      title,
      subtitle
    });
  }
  return `title=${title},subtitle=${subtitle},${settings}`;
};

export const mapGeneratedImageToStorybookPage = (
  page: IStorybookPage,
  generatedImages
): IStorybookPage => {
  const imageLanguages = generatedImages.imageLanguages;
  const result = { ...page };
  const settingValue = {
    layer: generatedImages.layer
  };

  imageLanguages.map((imageLanguage) => {
    const url = getLanguageJSON(result['url']);
    const thumbnail = getLanguageJSON(result['thumbnail']);
    result['url'] = JSON.stringify({
      ...url,
      [imageLanguage]:
        generatedImages[mapLanguageToImageName('mainImage', imageLanguage)]
    });
    result['thumbnail'] = JSON.stringify({
      ...thumbnail,
      [imageLanguage]:
        generatedImages[mapLanguageToImageName('preview', imageLanguage)]
    });
    settingValue[mapLanguageToImageName('portraitUrl', imageLanguage)] =
      generatedImages[mapLanguageToImageName('portraitImage', imageLanguage)];
    settingValue[mapLanguageToImageName('portraitThumbnail', imageLanguage)] =
      generatedImages[
        mapLanguageToImageName('portraitThumbnail', imageLanguage)
      ];
    result.settings = appendNewValueIntoSetting(page.settings, settingValue);
  });

  return result;
};
