import React from 'react';

const ZegnaStyle = () => (
  <style jsx global>{`
    .vb-lite {
      background-size: 100% auto !important;
    }
    .logo-top {
      display: none !important;
    }
    @media (orientation: landscape) and (min-width: 1024px),
      (min-width: 620px) and (max-height: 500px) {
      .vb-lite {
        background-size: auto 100% !important;
        background-position: left center !important;
      }
      .logo-middle {
        display: none !important;
      }
      h1 {
        width: 530px !important;
      }
      .options-container {
        left: auto !important;
        right: 10%;
      }
    }
  `}</style>
);

export default ZegnaStyle;
