import { ClientState } from '../../interfaces';
import { defaultClientState } from './clientReducer';
import { AnyAction } from 'redux';
import {
    DID_LOAD_SIMILAR_PRODUCTS,
    START_LOADING_SIMILAR_PRODUCTS,
    FINISH_LOADING_SIMILAR_PRODUCTS
} from '../actions';

const similarProductsReducer = (
    state: ClientState = defaultClientState,
    action: AnyAction
): ClientState => {
    switch (action.type) {
        case DID_LOAD_SIMILAR_PRODUCTS: {
            const existingProducts = {
                ...(state.similarProducts?.data || {})
            };
            existingProducts[action.payload.productId] =
                action.payload.products;
            return {
                ...state,
                similarProducts: {
                    ...(state.similarProducts || {}),
                    data: existingProducts
                }
            };
        }
        case START_LOADING_SIMILAR_PRODUCTS:
            return {
                ...state,
                similarProducts: {
                    ...(state.similarProducts || {}),
                    loading: true
                }
            };
        case FINISH_LOADING_SIMILAR_PRODUCTS:
            return {
                ...state,
                similarProducts: {
                    ...(state.similarProducts || {}),
                    loading: false
                }
            };
        default:
            return state;
    }
};

export default similarProductsReducer;
