import { isProd } from './../../config/index';
import { allBrands, UAT_HOST } from '../../config';
import { PageView } from '../../utils/pageView';
import { hubDefaultPage } from '../config';

export const getHashPathFromPageType = (page: PageView) => {
  switch (page) {
    case PageView.HUB_NUDGES_CREATE:
      return 'nudges/new';
    case PageView.HUB_NUDGES_CREATE_FROM_TEMPLATE:
      return 'nudges/usetemplate';
    case PageView.HUB_NUDGES_VIEW:
      return 'nudges/view';
    case PageView.HUB_NUDGES_COPY:
      return 'nudges/copy';
    case PageView.HUB_LOGIN:
      return 'login';
    case PageView.HUB_NUDGES:
      return 'nudges';
    case PageView.HUB_CONTACT_CENTER:
      return 'chat';
    case PageView.HUB_CHAT_DETAILS:
      return 'chat/details';
    case PageView.HUB_SESSION_OVERVIEW:
      return 'sessions';
    case PageView.HUB_MEETING_ROOMS:
      return 'rooms';
    case PageView.HUB_NUDGE_TEMPLATES:
      return 'templates';
    case PageView.HUB_NUDGE_TEMPLATES_SELECT:
      return 'templates/select';
    case PageView.HUB_NUDGES_TEMPLATE_CREATE:
      return 'templates/new';
    case PageView.HUB_NUDGE_TEMPLATE_VIEW:
      return 'templates/view';
    case PageView.HUB_NUDGE_TEMPLATE_COPY:
      return 'templates/copy';
    case PageView.HUB_NUDGE_TEMPLATE_EDIT:
      return 'templates/edit';
    case PageView.HUB_NUDGE_TEMPLATE_MANAGE:
      return 'templates/manage';
    case PageView.HUB_SESSION_CREATE:
      return 'sessions/create';
    case PageView.HUB_SESSION_EDIT:
      return 'sessions/edit';
    case PageView.HUB_MEETING_ROOM_CREATE:
      return 'rooms/create';
    case PageView.HUB_MEETING_ROOM_EDIT:
      return 'rooms/edit';
    case PageView.HUB_HOME:
      return '';
    case PageView.HUB_USERMANAGEMENT:
      return 'users';
    case PageView.HUB_TEAMS:
      return 'teams';
    case PageView.HUB_ACCOUNTSETTINGS:
      return 'account';
    case PageView.HUB_BRANDING:
      return 'branding';
    case PageView.HUB_ACCOUNT:
      return 'account-key';
    case PageView.HUB_GALLERY:
      return 'gallery';
    case PageView.HUB_STORYBOOK:
      return 'storybook';
    case PageView.HUB_SCENE:
      return 'scene';
    case PageView.HUB_CONTACT_SUPPORT:
      return 'contact-support';
    case PageView.HUB_SCENE_TEMPLATE:
      return 'scene/template';
    case PageView.HUB_ASSET_LIBRARY:
      return 'asset-library';
    case PageView.HUB_DEVICES_MANAGEMENT:
      return 'devices';
    case PageView.HUB_DEVIVES_MONITORING:
      return 'devices-monitoring';
    default:
      return null;
  }
};

export const getPageTypeFromHashPath = (path: string): PageView => {
  const page = Object.keys(PageView).find(
    (key) => getHashPathFromPageType(PageView[key]) === path
  );
  if (page) return PageView[page];

  return PageView.HUB_HOME;
};

export const hubNavTo = (page: PageView) => {
  window.location.hash = `/${getHashPathFromPageType(page)}`;
};
export const hubNavToByString = (page: string) => {
  window.location.hash = `/${page}`;
};

export const hubNavToDefaultLanding = () => {
  hubNavTo(hubDefaultPage);
};

export const isWrongHubHost = (host: string, brandId: string) => {
  if (host === UAT_HOST) {
    console.log('UAT CHECK HOST');
    return !allBrands.some((b) => b.id === brandId);
  }
  const brand = allBrands.find((b) => b.host === host);
  return brand?.id !== brandId;
};

const getCurrentHost = () => {
  return typeof window !== undefined ? window.location.host : '';
};
export const getCorrectHubHost = (brandId: string) => {
  const currentHost = getCurrentHost();
  if (currentHost === UAT_HOST) return currentHost;
  return allBrands.find((brand) => brandId === brand.id)?.host || currentHost;
};

export const getCurrentProtocol = () => {
  return typeof window !== undefined ? window.location.protocol : '';
};
export const isOnBoardingBrandByBrandId = (brandId: string | undefined) => {
  if (allBrands.find((brand) => brandId === brand.id)?.isNotPreConfigured) {
    return true;
  }
  return allBrands.find((brand) => brandId === brand.id) ? false : true;
};

export const getHubUrl = (brandId: string) => {
  const host = getCurrentHost();
  return isProd && host !== UAT_HOST
    ? `https://${getCorrectHubHost(brandId)}/hub`
    : `${getCurrentProtocol()}//${getCurrentHost()}/hub/${brandId}`;
};
