import { IHubUser } from '../interfaces';
export class MissingEntitlementError extends Error {
  constructor(message) {
    super(message);
    this.name = 'MissingEntitlementError';
  }
}

export class AmplifyChallengeNameError extends Error {
  constructor(challengeName) {
    super(challengeName);
    this.name = 'AmplifyChallengeNameError';
  }
}

export class WrongBrandSiteError extends Error {
  user: IHubUser;
  constructor(message, user?: IHubUser) {
    super(message);
    this.name = 'WrongBrandSiteError';
    this.user = user;
  }
}

export const defaultLoginErrorMessage = 'Invalid username or password. Please try again.';
