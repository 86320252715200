import React from 'react';
import { StartKey } from '../advisorHub/clientSideServices/nudge';
import { chatWsUrl } from '../config';
import { enc } from '../utils/crypt';
import { generateV4UUID } from '../utils/identityGenerator';
import { useStateRef } from './hooks/common';

export enum WSReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}

export enum WSMessageType {
  ACK = 'Acknowledgement',
  SUBSCRIPTION_EVENT = 'SUBSCRIPTION_EVENT',
  BROADCAST = 'Broadcast',
  QUERY_RESULT = 'QueryResult'
}

export enum ChatMessageType {
  PLAIN_TEXT = 'PLAIN/TEXT',
  PRODUCT = 'PRODUCT',
  STORY = 'STORY',
  PRODUCT_COMPARE = 'PRODUCT_COMPARE',
  PRODUCT_PERSONALIZE = 'PRODUCT_PERSONALIZE',
  MEETING_LINK = 'MEETING_LINK',
  APPOINTMENT_FORM = 'APPOINTMENT_FORM',
  USER_UPDATED = 'USER_UPDATED',
  USER_ONLINE = 'USER_ONLINE',
  JOINED_CHAT = 'JOINED_CHAT',
  INDICATOR = 'INDICATOR',
  TRANSFER_CHAT = 'TRANSFER_CHAT'
}

export enum IndicatorMessageType {
  ADD_CART = 'ADD_TO_SHOPPING_CART',
  ADD_WISHLIST = 'ADD_TO_WISHLIST',
  REMOVE_WISHLIST = 'REMOVE_FROM_WISHLIST'
}

export enum WSAcknowledgementStatusType {
  SET_AVAILABILITY = 'SET_AVAILABILITY',
  CONVERSATION_INITIATED = 'CONVERSATION_INITIATED',
  MESSAGE_SENT = 'MESSAGE_SENT',
  SUBSCRIBED = 'SUBSCRIBED',
  USER_DISCONNECTED = 'USER_DISCONNECTED'
}

export enum WSActionType {
  FETCH_CONVERSATIONS = 'fetchChatConversationsByUserId',
  FETCH_CONVERSATION_MESSAGES = 'fetchChatMessagesByConversationId',
  INITIATE_CONVERSATION = 'initiateConversation',
  SET_CHAT_USER_AVAILABILITY = 'setChatUserAvailability',
  SEND_CHAT_MESSAGE = 'sendChatMessage',
  SUBSCRIBE_TO_CONVERSATION = 'subscribeToConversation',
  CHECK_CHAT_USER_AVAILABILITY = 'chatUserAvailability',
  CHAT_STORE_AVAILABILITY = 'chatStoreAvailability',
  JOIN_CHAT_SESSION = 'joinChatSession',
  TRANSFER_CONVERSATION = 'transferConversation',
  FETCH_AVAILABLE_AGENTS = 'fetchAvailableAgentsByStoreId',
  FETCH_UNSUBSCRIBE_CONVERSATIONS = 'fetchUnsubscribedConversationsByStoreId'
}

export enum WSChatUserAvailability {
  UNAVAILABLE = 'UNAVAILABLE',
  AVAILABLE = 'AVAILABLE'
}

export interface IChatWsMessageListener {
  id: string;
  listener: Function;
}

export enum WSActionType {}

export interface WSMessageListener {
  id: string;
  listener: Function;
}

export interface WSOnConnectAction {
  id: string;
  action: Function;
}

export enum ChatUserType {
  AGENT = 'agent',
  CLIENT = 'client'
}

export interface IChatMessageBody {
  content: string;
  type: ChatMessageType;
  storeId?: string;
  timestamp: Date;
}

export interface IChatMessage {
  requestId: string;
  userId: string;
  conversationId: string;
  messageBody: string;
  createdAt?: string;
}

let isMounted = false;

export const ChatConnectionContext = React.createContext<{
  connected: boolean;
  connect: () => void;
  disconnect: () => void;
  addMessageListener: ({ id, listener }: IChatWsMessageListener) => void;
  removeMessageListener: (id: string) => void;
  addOnConnectAction: ({ id, action }: WSOnConnectAction) => void;
  removeOnConnectAction: (id: string) => void;
  fetchConversations: (userId: string) => void;
  initiateConversation: (
    storeId: string,
    userId: string,
    userName: string
  ) => void;
  fetchChatMessagesByConversationId: (
    conversationId: string,
    limit?: number,
    startkey?: StartKey
  ) => void;
  setChatUserAvailability: (
    userId: string,
    storeId: string,
    status: WSChatUserAvailability,
    userType: ChatUserType
  ) => void;
  joinChatSession: (
    userId: string,
    storeId: string,
    userType: ChatUserType
  ) => void;
  checkChatStoreAvailability: (storeId: string) => void;
  onSendMessage: (payload: IChatMessage) => void;
  subscribeToConversation: (userId: string, conversationId: string) => void;
  checkChatUserAvailability: (userId: string, storeId: string) => void;
  transferConversation: (
    fromId: string,
    toId: string,
    conversationId: string
  ) => void;
  fetchAvailableAdvisors: (storeId: string) => void;
  fetchUnsubscribeConversations: (storeId: string, limit?: number) => void;
}>(undefined);

let ws;

const ChatConnectionContextContainer = ({
  storeId,
  children
}: {
  storeId: string;
  children: React.ReactNode;
}) => {
  const [connected, setConnected] = React.useState(false);
  const [messageListeners, setMessageListeners, messageListenersRef] =
    useStateRef([]);
  const [onConnectActions, setOnConnectActions, onConnectActionsRef] =
    useStateRef([]);

  const addMessageListener = (listener: WSMessageListener) => {
    setMessageListeners([...messageListeners, listener]);
  };

  const removeMessageListener = (id: string) => {
    setMessageListeners(
      messageListeners.filter((listener) => listener?.id !== id)
    );
  };

  const addOnConnectAction = (action: WSOnConnectAction) => {
    setOnConnectActions([...onConnectActions, action]);
  };

  const removeOnConnectAction = (id: string) => {
    setOnConnectActions(
      onConnectActions.filter((listener) => listener?.id !== id)
    );
  };

  const initiateConversation = (storeId, userId, userName) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.INITIATE_CONVERSATION,
      storeId,
      userId,
      userName
    });
  };

  const fetchConversations = (userId: string) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.FETCH_CONVERSATIONS,
      userId: userId
    });
  };

  const fetchChatMessagesByConversationId = (
    conversationId: string,
    limit = 25,
    startKey?: StartKey
  ) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.FETCH_CONVERSATION_MESSAGES,
      conversationId: conversationId,
      limit: limit,
      startKey
    });
  };

  const joinChatSession = (
    userId: string,
    storeId: string,
    userType: ChatUserType
  ) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.JOIN_CHAT_SESSION,
      userId: userId,
      storeId: storeId,
      userType: userType
    });
  };

  const setChatUserAvailability = (
    userId: string,
    storeId: string,
    status: string,
    userType: ChatUserType
  ) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.SET_CHAT_USER_AVAILABILITY,
      userId: userId,
      storeId: storeId,
      userType: userType,
      status: status
    });
  };

  const checkChatUserAvailability = (userId: string, storeId: string) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.CHECK_CHAT_USER_AVAILABILITY,
      userId: userId,
      storeId: storeId
    });
  };

  const checkChatStoreAvailability = (storeId: string) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.CHAT_STORE_AVAILABILITY,
      storeId: storeId
    });
  };

  const onSendMessage = (payload: IChatMessage) => {
    sendMessageToWS({ ...payload, action: WSActionType.SEND_CHAT_MESSAGE });
  };

  const subscribeToConversation = (userId: string, conversationId: string) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.SUBSCRIBE_TO_CONVERSATION,
      userId,
      conversationId
    });
  };

  const transferConversation = (
    fromId: string,
    toId: string,
    conversationId
  ) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.TRANSFER_CONVERSATION,
      fromUserId: fromId,
      toUserId: toId,
      conversationId
    });
  };

  const fetchAvailableAdvisors = (storeId: string) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.FETCH_AVAILABLE_AGENTS,
      storeId
    });
  };

  const fetchUnsubscribeConversations = (storeId: string, limit = 10) => {
    sendMessageToWS({
      requestId: generateV4UUID(),
      action: WSActionType.FETCH_UNSUBSCRIBE_CONVERSATIONS,
      storeId,
      limit
    });
  };

  const connect = () => {
    ws = new WebSocket(chatWsUrl);
    ws.onmessage = (event) => {
      messageListenersRef.current.forEach(({ listener }) => {
        listener(event);
      });
    };
    ws.onopen = () => {
      console.log('Connected to Web socket');
      onConnectActionsRef.current.forEach(({ action }) => {
        action();
      });
      setConnected(true);
    };
    ws.onclose = (e) => {
      setConnected(false);
      if (isMounted) {
        console.log(
          'Websocket is closed. Reconnect will be attempted in 1 second.',
          e.reason
        );
        setTimeout(function () {
          reconnectWS();
        }, 1000);
      }
    };
    ws.onerror = (error) => {
      setConnected(false);
      console.error(
        'Websocket Socket encountered error: ',
        error.message,
        'Closing socket'
      );
    };
  };

  const disconnect = () => {
    ws.close();
  };

  const reconnectWS = () => {
    if (
      ws?.readyState !== WSReadyState.CONNECTING &&
      ws?.readyState !== WSReadyState.OPEN
    ) {
      connect();
    }
  };

  const sendMessageToWS = (message: Object, callback?: Function) => {
    waitForConnection(() => {
      const data = `${window.location.host}|${new Date().getTime()}`;
      const protocol = enc(data, storeId);

      const messageWithProtocol = {
        ...message,
        protocol
      };
      ws.send(JSON.stringify(messageWithProtocol));
      if (typeof callback !== 'undefined') {
        callback();
      }
    }, 1000);
  };

  const waitForConnection = (callback: Function, interval: number) => {
    if (ws.readyState === WSReadyState.OPEN) {
      callback();
    } else if (ws.readyState === WSReadyState.CLOSED) {
      connect();
    } else {
      setTimeout(function () {
        waitForConnection(callback, interval);
      }, interval);
    }
  };

  const visibilityChangeListener = () => {
    if (document.visibilityState === 'visible') {
      reconnectWS();
    }
  };

  const handleConnectionChange = (event) => {
    if (event.type == 'offline') {
      console.log('Internet connection lost.');
      return;
    }
    if (event.type == 'online') {
      console.log('Internet connection restored.');
      reconnectWS();
    }
  };

  React.useEffect(() => {
    isMounted = true;
    document.addEventListener('visibilitychange', visibilityChangeListener);
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    return () => {
      isMounted = false;
      document.removeEventListener(
        'visibilitychange',
        visibilityChangeListener
      );
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
      if (ws) {
        ws.close();
      }
    };
  }, []);

  return (
    <ChatConnectionContext.Provider
      value={{
        connected,
        connect,
        disconnect,
        setChatUserAvailability,
        checkChatStoreAvailability,
        addMessageListener,
        removeMessageListener,
        addOnConnectAction,
        removeOnConnectAction,
        fetchConversations,
        initiateConversation,
        fetchChatMessagesByConversationId,
        onSendMessage,
        subscribeToConversation,
        checkChatUserAvailability,
        joinChatSession,
        transferConversation,
        fetchAvailableAdvisors,
        fetchUnsubscribeConversations
      }}
    >
      {children}
    </ChatConnectionContext.Provider>
  );
};

export default ChatConnectionContextContainer;
