import {
  getProductAccessories,
  getProductsById
} from '../clientSideServices/products';
import { IMainState, IProduct } from '../interfaces';
import {
  actionDidLoadProduct,
  actionDidLoadProductAccessories
} from './actions';

export const actionFetchProductByIdAsync = (id: string) => {
  return (dispatch, getState) => {
    const existingProducts =
      (getState() as IMainState).clientState.product?.loadedProducts || [];
    if (!existingProducts.some((product) => product.id === id)) {
      return getProductsById(id)
        .then((result) => {
          return dispatch(actionDidLoadProduct(result.data));
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };
};

export const actionGetProductAccessoriesAsync = (
  productId: string,
  defaultSection?: string,
  defaultStrap?: string
) => {
  return (dispatch) => {
    return getProductAccessories(productId)
      .then((res) => {
        if (res?.data?.straps?.length) {
          console.log('getProductAccessories', res);
          dispatch(
            actionDidLoadProductAccessories({
              ...res.data,
              selectedStrap: defaultStrap || null,
              selectedDial: res.data.dials[0],
              activeSection: defaultSection
            })
          );
        } else {
          dispatch(actionDidLoadProductAccessories(undefined));
        }
        return;
      })
      .catch((e) => {
        dispatch(actionDidLoadProductAccessories(undefined));
        console.log('getProductAccessories', e);
      });
  };
};

export const actionGetProductStrapsAsync = (
  dial: IProduct,
  openTab?: string
) => {
  return (dispatch, getState) => {
    const productAccessories =
      (getState() as IMainState).clientState.productAccessories || {};

    return getProductAccessories(dial.id)
      .then((res) => {
        if (res?.data?.straps?.length) {
          dispatch(
            actionDidLoadProductAccessories({
              ...productAccessories,
              straps: res.data.straps,
              selectedStrap: res.data.straps[0],
              selectedDial: dial,
              activeSection: openTab
            })
          );
        } else {
          dispatch(
            actionDidLoadProductAccessories({
              ...productAccessories,
              selectedDial: dial,
              straps: undefined,
              selectedStrap: undefined
            })
          );
        }
        return;
      })
      .catch((e) => {
        dispatch(
          actionDidLoadProductAccessories({
            ...productAccessories,
            selectedDial: dial,
            straps: undefined,
            selectedStrap: undefined
          })
        );
        console.log('getProductAccessories', e);
      });
  };
};
