const InspifyStyle = () => (
  <style jsx global>{`
    html,
    body {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
    }
  `}</style>
);
export default InspifyStyle;
