import React from 'react';

export const VanCleefStyle = () => (
  <style jsx global>{`
    body {
      font-family: 'Maison Neue', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
      font-weight: 400 !important;
    }
    h1,
    h1 *,
    h2,
    .vca-title {
      font-family: 'Janson Text', Times, 'Times New Roman', serif !important;
      font-weight: 400 !important;
      text-transform: capitalize !important;
      letter-spacing: normal !important;
    }
    .Feedback {
      z-index: 1000 !important;
    }

    .landing-page h1 > span {
      text-transform: uppercase !important;
    }

    .Feedback .btn-outline-dark {
      background: #efefef !important;
    }
    .express-icons-container {
      position: fixed;
      right: 12px;
      top: 12px;
      display: flex;
      flex-direction: row-reverse;
      z-index: 999;
    }
    #content-popup {
      background: none !important;
    }
  `}</style>
);

export const VanCleefTouchStyle = ({ hidePrice }: { hidePrice?: boolean }) => (
  <style jsx global>{`
    body {
      font-family: 'Maison Neue', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
      font-weight: 400;
      background: none;
    }
    h1,
    h2,
    h3,
    h4,
    .light-text,
    .inspiration-page .copy strong,
    .products-landing .text-center p {
      font-family: 'Janson Text', Times, 'Times New Roman', serif !important;
      font-weight: 400 !important;
      text-transform: capitalize !important;
      letter-spacing: normal !important;
    }

    .products-landing h1 {
      display: none !important;
    }

    .product-landing .avatar {
      margin-bottom: 0 !important;
    }

    .product-landing .light-text {
      font-family: 'Maison Neue', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
      padding-top: 0 !important;
    }
    .product-inspirations .light-text,
    .similar-products .light-text {
      text-transform: capitalize !important;
      letter-spacing: normal !important;
    }
    .express-icons-container {
      position: fixed;
      right: 12px;
      top: 12px;
      display: flex;
      flex-direction: row-reverse;
      z-index: 999;
    }
    .price,
    .prod-price {
      display: ${hidePrice ? 'none' : 'block'};
    }
    .product-page .similar-products {
      display: ${hidePrice ? 'none' : 'block'};
    }
    .content-wrapper {
      background: #fff;
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding;
      background-clip: padding-box;
    }
    .inspiration-4d0f6e90-cb72-11ec-ae47-3d51b8fc903a .pagetitle,
    .inspiration-4d0f6e90-cb72-11ec-ae47-3d51b8fc903a .copy p:first-child,
    .inspiration-page.YOUTUBE .pagetitle,
    .inspiration-page.YOUTUBE .copy p:first-child {
      display: none;
    }
  `}</style>
);
