import { INudgePayload, IProduct, IWishlistItem } from '.';

export enum LoubiAirwaysAnimation {
  AIRPLANE_WINDOW = 'AIRPLANE WINDOW',
  LUGGAGE = 'LUGGAGE',
  COMPARTMENT_XRAY = 'COMPARTMENT XRAY',
  TAROT_CARD = 'TAROT CARD',
  OMG_MAGAZINE = 'OMG MAGAZINE',
  IN_FLIGHT_MAGAZINE = 'IN FLIGHT MAGAZINE',
  TRAY_TABLE = 'TRAY TABLE',
  STYLISH_GUIDE = 'STYLISH GUIDE',
  NEWSPAPER = 'NEWSPAPER',
  TROLLEY = 'TROLLEY',
  BEAUTY_CART = 'BEAUTY CART'
}

export enum LoubiAirwaysFullScreenVideo {
  INTRO = 'INTRO',
  COCKPIT = 'COCKPIT',
  STYLISH_DEMO = 'STYLISH_DEMO',
  BEAUTY_DEMO = 'BEAUTY_DEMO',
  EXIT = 'EXIT'
}

export enum LoubiAirwaysPopupContentType {
  WELCOME_INSTRUCTION = 'WELCOME_INSTRUCTION',
  TAROT = 'TAROT',
  XRAY = 'XRAY',
  LUGGAGE = 'LUGGAGE',
  SAFETY_GUIDE = 'SAFETY_GUIDE',
  AIRPLANE_WINDOW = 'AIRPLANE_WINDOW',
  TROLLEY = 'TROLLEY',
  IN_FLIGHT_MAGAZINE = 'IN_FLIGHT_MAGAZINE'
}

export interface ILoubiAirwaysWishlist {
  id?: string;
  identityId?: string;
  description?: string;
  name?: string;
  store?: string;
  brand?: string;
  wishlistItems?: IWishlistItem[];
  createdAt?: string | Date;
  modifiedAt?: string | Date;
  surname?: string;
  status?: string;
  region?: string;
  open?: boolean;
}

export enum LoubiAirwaysExitState {
  SHOW_POPUP = 'SHOW_POPUP'
}

export interface ILoubiAirwaysState {
  remoteAnimation?: ILoubiAirwaysAnimationState;
  activeAnimation?: LoubiAirwaysAnimation;
  selectedModel?: ISelectedModel;
  planePlan?: IPlanePlan;
  fullScreenVideo?: LoubiAirwaysFullScreenVideo;
  landingVideo?: {
    hidden?: boolean;
    finishedPlaying?: boolean;
  };
  wishlist?: ILoubiAirwaysWishlist;
  showSpotifyPlayer?: boolean;
  playIntroductionVideosInMeeting?: boolean;
  popupContentType?: LoubiAirwaysPopupContentType;
  exitState?: LoubiAirwaysExitState;
  alreadyPassedLaunchTime?: boolean;
  openChatPanel?: boolean;
  invite?: INudgePayload;
  catalog?: ILoubiAirwaysCatalogState;
}

export interface ILoubiAirwaysCatalogState {
  show?: boolean;
  loading?: boolean;
  hasMoreResults?: boolean;
  products?: IProduct[];
  index?: number;
  type?: ProductType;
  gender?: ProductGender;
}

export interface ILoubiAirwaysAnimationState {
  [key: string]: ILoubiAirways3DAnimationState;
}

export interface ILoubiAirways3DAnimationState {
  selectedProduct?: IAnimationProduct;
  flag?: REMOTE_ACTION;
  position?: number[];
  scale?: number[];
  rotation?: number[];
  focusItem?: number;
}

export interface ILoubiAirwaysAirplaneWindowAnimationState
  extends ILoubiAirways3DAnimationState {
  displayedProductIndices?: number[];
}
export interface ILoubiAirwaysLuggagePopAnimationState
  extends ILoubiAirways3DAnimationState {
  trunkOpen?: boolean;
  focusItem?: number;
}

export interface ILoubiAirways2dAnimationState
  extends ILoubiAirways3DAnimationState {
  close?: boolean;
  activePage?: number;
  flip?: boolean;
}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface ILoubiAirwaysLipstickAnimationState
  extends ILoubiAirways3DAnimationState {}

export interface ILoubiAirwaysTarotCardAnimationState
  extends ILoubiAirways3DAnimationState {}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface ILoubiAirwaysTrolleyAnimationState
  extends ILoubiAirways3DAnimationState {
  animationState?: string;
}
export interface ILoubiBeautyCartAnimationState
  extends ILoubiAirways3DAnimationState {
  focuscolor?: number;
  animationState?: string;
}

export interface ILoubiAirwaysXRayAnimationState
  extends ILoubiAirways3DAnimationState {
  maskCoordinatesInPercentage?: {
    x: number;
    y: number;
  };
  maskSize?: number;
  revealed?: boolean;
}

export enum REMOTE_ACTION {
  CLICK_LEFT = 'CLICK_LEFT',
  CLICK_RIGHT = 'CLICK_RIGHT',
  FOCUS = 'FOCUS',
  UN_FOCUS = 'UN_FOCUS',
  MOUSE_CONTROL = 'MOUSE_CONTROL',
  OPEN_LID = 'OPEN_LID',
  CLOSE_GATE_1 = 'CLOSE_GATE_1',
  CLOSE_GATE_2 = 'CLOSE_GATE_2',
  CLICK_GATE_1 = 'CLICK_GATE_1',
  CLICK_GATE_2 = 'CLICK_GATE_2',
  SEL_COLOR = 'SEL_COLOR',
  PERFUMES_BASE = 'PERFUMES_BASE',
  OPEN = 'OPEN'
}

export interface IAnimationProduct {
  id?: string;
  modelName?: string;
  modelCode?: string;
  lowPolyModelUrl?: string;
  highPolyModelUrl?: string;
}

export interface ISelectedModel {
  model: IAnimationProduct;
  state?: REMOTE_ACTION;
  position?: number[];
  scale?: number[];
}

export interface ITarotProduct {
  url?: string;
  row?: number;
  col?: number;
  frames?: number;
}

export interface IPlanePlan {
  open?: boolean;
  selectedSpot?: PlanSpotLight;
  visitedSpot?: PlanSpotLight[];
}

export enum PlanSpotLight {
  NEWSPAPER = 'PLAN_SPOT.NEWSPAPER',
  STYLISH_VIDEO = 'PLAN_SPOT.STYLISH_VIDEO',
  LUGGAGE = 'PLAN_SPOT.LUGGAGE',
  BEAUTY_CART = 'PLAN_SPOT.BEAUTY_CART',
  TROLEY = 'PLAN_SPOT.TROLEY',
  WINDOW = 'PLAN_SPOT.WINDOW',
  TRAY = 'PLAN_SPOT.TRAY',
  IN_FLIGHT_MAGAZINE = 'PLAN_SPOT.IN_FLIGHT_MAGAZINE',
  OMG_MAGAZINE = 'PLAN_SPOT.OMG_MAGAZINE',
  STYLISH_GUIDE = 'PLAN_SPOT.STYLISH_GUIDE',
  PHONE = 'PLAN_SPOT.PHONE',
  HEADSET = 'PLAN_SPOT.HEADSET',
  TARROT = 'PLAN_SPOT.TARROT',
  COCKPIT = 'COCKPIT',
  XRAY = 'XRAY'
}

export type ProductGender = 'men' | 'women';

export type ProductType = 'shoes' | 'bags' | 'beauty' | 'leather goods';
