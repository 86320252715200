import axios from 'axios';
import { createHash } from '../utils/crypt';
import { format } from 'date-fns';
import { getCanonical, getSignatureKey } from '../utils/signature';
const httpClient = axios.create();

//return date formated YYYYMMDD'T'HHmmss'Z'
const formatDate = (date: Date | number) => {
  return format(date, "yyyyMMdd'T'hhmmss'Z'").toString();
};

export const getRequestSignature = (config: {
  method: string;
  url: string;
  data: any;
}) => {
  const data = config.data || {};
  let signature;
  const requestDateTime = formatDate(new Date().getTime());
  try {
    const canonical = getCanonical(config.method, config.url, data);
    const hashedCanonicalRequest = createHash(
      canonical,
      process.env.NEXT_PUBLIC_SIGNING_SECRET
    );
    const algorithm = 'INS-SHA256';

    const stringToSign =
      algorithm + '\n' + requestDateTime + '\n' + hashedCanonicalRequest;
    const derivingKey = getSignatureKey(
      process.env.NEXT_PUBLIC_SIGNING_SECRET,
      requestDateTime
    );
    const signatureINS = createHash(stringToSign, derivingKey);
    signature = `INS-SHA256;Signature=${signatureINS}`;
  } catch (e) {
    console.log('error create signature', e);
    signature = undefined;
  }
  return {
    date: requestDateTime,
    key: signature
  };
};

httpClient.interceptors.request.use(
  (config) => {
    const data = config.data || {};
    const { date, key } = getRequestSignature({
      method: config.method,
      data,
      url: config.url
    });
    config.headers['x-ins-date'] = date;
    if (config.headers && key) {
      config.headers['x-ins-signature'] = key;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default httpClient;
