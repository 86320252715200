import { AnyAction } from 'redux';
import { ClientState } from '../../interfaces';
import {
  UPDATE_PRODUCT_SEARCH_STATE,
  UPDATE_STORYBOOK_INDEX,
  UPDATE_STORY_SEARCH_STATE
} from '../actions';
import { defaultClientState } from './clientReducer';

const searchReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case UPDATE_PRODUCT_SEARCH_STATE: {
      return {
        ...state,
        productSearch: action.payload
      };
    }

    case UPDATE_STORY_SEARCH_STATE: {
      const currentState = state.storySearch || {};
      const payload = action.payload || {};

      return {
        ...state,
        storySearch: {
          ...currentState,
          ...payload
        }
      };
    }

    case UPDATE_STORYBOOK_INDEX: {
      return {
        ...state,
        storybookViewerIndex: action.payload
      };
    }

    default:
      return state;
  }
};

export default searchReducer;
