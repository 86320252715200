import { combineReducers } from 'redux';
import clientReducer from './clientReducer';
import serverReducer from './serverReducer';

const rootReducer = combineReducers({
  clientState: clientReducer,
  serverState: serverReducer
});

export default rootReducer;
