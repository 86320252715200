import { identity } from 'lodash';
import {
  aspreyBrandId,
  chopardBrandId,
  chopardHappySportGenevaHQ,
  chopardHappySportKorea,
  commonProductSpecs,
  highJewelrySpecs,
  iwcBrandId,
  jewelrySpecs,
  watchSpecs
} from '../config';
import { i18n } from '../i18n';
import {
  IOfficialSalesPrices,
  IProduct,
  IProductAccessories,
  IStrictProductDisplayConfig,
  ProductsFilter,
  ProductType
} from '../interfaces';

export const getUnknownPrice = () =>
  i18n?.t('price_on_request') || 'price_on_request';

export const getCountrySpecificPrice = (
  product: Partial<IProduct>,
  countryCode: keyof IOfficialSalesPrices
) =>
  countryCode &&
  product.officialSalesPrices &&
  product.officialSalesPrices[countryCode];

export const getCountrySpecificDisplayPrice = (
  product: Partial<IProduct>,
  countryCode: keyof IOfficialSalesPrices,
  strictProducts?: IStrictProductDisplayConfig
) => {
  const country =
    strictProducts?.[product.modelCode]?.officialSalesPrice || countryCode;
  const countrySpecificPrice = getCountrySpecificPrice(product, country);

  if (countrySpecificPrice) {
    const currency = countrySpecificPrice.currencyCode;
    const price = countrySpecificPrice.salesPrice;

    if (currency && price > 0) {
      // Derive locale from country code
      const locale = `${navigator.language.split('-')[0]}-${country}`;

      try {
        const formatter = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return formatter.format(price);
      } catch (error) {
        console.warn(`Failed to format price for locale ${locale}. Falling back to default.`);
        // Fallback to a default formatting if the specific locale is not supported
        const defaultFormatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return defaultFormatter.format(price);
      }
    }
    return getUnknownPrice();
  }
  return getUnknownPrice();
};

export const getUnknownCountryFallbackPriceDisplay = (
  product: Partial<IProduct>
) => {
  return product &&
    product.currency &&
    product.salesPriceSg &&
    product.salesPriceSg > 0
    ? `${product.currency} ${product.salesPriceSg.toLocaleString()}`
    : getUnknownPrice();
};

export const getAvailableDisplayPrice = (
  product: Partial<IProduct>,
  preferCountryCode: string
) => {
  const countryCode = preferCountryCode.toUpperCase();
  const salePrices = product.officialSalesPrices;
  if (product.officialSalesPrices?.[countryCode]?.salesPrice) {
    return getCountrySpecificDisplayPrice(product, countryCode);
  }

  const availablePrice = Object.keys(product.officialSalesPrices || {}).find(
    (key) => salePrices[key]?.salesPrice
  );
  if (availablePrice) {
    console.log('availablePrices', availablePrice);
    return getCountrySpecificDisplayPrice(product, availablePrice);
  }
  return getUnknownCountryFallbackPriceDisplay(product);
};
export const getProductDisplayPrice = (
  product: Partial<IProduct>,
  countryCode: string,
  strictProducts?: IStrictProductDisplayConfig
) => {
  if (countryCode) {
    return getCountrySpecificDisplayPrice(
      product,
      countryCode.toUpperCase(),
      strictProducts
    );
  }
  return getUnknownCountryFallbackPriceDisplay(product);
};

export const getProductOriginalLink = (
  product: IProduct,
  countryCode: keyof IOfficialSalesPrices
) => {
  return product?.officialSalesPrices?.[countryCode]?.productLinkOwnerWebsite;
};

const getHighJewelleryDisplayName = (product: IProduct, lang: string) => {
  const defaultDisplayName =
    product.highJewelrySpecifications?.EN?.mainStoneMaterial || null;

  if (!lang) return defaultDisplayName;

  if (product.highJewelrySpecifications[lang.toUpperCase()]) {
    return (
      product.highJewelrySpecifications[lang.toUpperCase()]
        ?.mainStoneMaterial || defaultDisplayName
    );
  }
  return defaultDisplayName;
};

export const getProductDisplayName = (product: IProduct, lang: string) => {
  if (product.type === ProductType.HIGH_JEWELLERY)
    return getHighJewelleryDisplayName(product, lang);

  if (product.type === ProductType.JEWELLERY)
    return getJewelleryDisplayName(product, lang);

  if (!lang) return product.modelName;

  if (
    product.watchSpecifications &&
    product.watchSpecifications[lang.toUpperCase()]
  ) {
    const localizedName = product.watchSpecifications[lang.toUpperCase()].name;
    if (lang.toUpperCase() === 'AR') {
      return localizedName.replace(/"/g, '');
    } else {
      return localizedName.replace(/&amp;/g, '&');
    }
  }
  return product.modelName;
};

export const getJewelleryDisplayName = (product: IProduct, lang: string) => {
  const defaultDisplayName = product.jewelrySpecifications?.EN?.name || null;

  if (!lang) return defaultDisplayName;

  if (product?.jewelrySpecifications[lang.toUpperCase()]) {
    return (
      product?.jewelrySpecifications[lang.toUpperCase()]?.name ||
      defaultDisplayName
    );
  }
  return defaultDisplayName;
};

export const getProductDescription = (product: IProduct, lang: string) => {
  const defaultDescription = product.description || null;
  if (!lang) return defaultDescription;
  if (
    product.type === ProductType.WRISTWATCH &&
    product.watchSpecifications &&
    product.watchSpecifications[lang.toUpperCase()]
  ) {
    return (
      product.watchSpecifications[lang.toUpperCase()].description ||
      defaultDescription
    );
  }
  if (
    product.type === ProductType.JEWELLERY &&
    product.jewelrySpecifications &&
    product.jewelrySpecifications[lang.toUpperCase()]
  ) {
    return (
      product.jewelrySpecifications[lang.toUpperCase()].longDescription ||
      product.jewelrySpecifications.EN?.longDescription ||
      product.longDescription ||
      defaultDescription
    );
  }

  if (
    product.type === ProductType.HIGH_JEWELLERY &&
    product.highJewelrySpecifications &&
    product.highJewelrySpecifications[lang.toUpperCase()]
  ) {
    return (
      product.highJewelrySpecifications[lang.toUpperCase()].description ||
      defaultDescription
    );
  }

  //watchSpecifications will be used as fallback
  if (
    product.watchSpecifications &&
    product.watchSpecifications[lang.toUpperCase()]
  ) {
    return (
      product.watchSpecifications[lang.toUpperCase()].description ||
      defaultDescription
    );
  }

  return defaultDescription;
};

export const getProductLongDescription = (product: IProduct, lang: string) => {
  const defaultDescription =
    product.longDescription || product.description || null;
  if (!lang) return defaultDescription;
  if (
    product.type === ProductType.WRISTWATCH &&
    product.watchSpecifications &&
    product.watchSpecifications[lang.toUpperCase()]
  ) {
    return (
      product.watchSpecifications[lang.toUpperCase()].longDescription ||
      product.watchSpecifications[lang.toUpperCase()].description ||
      defaultDescription
    );
  }
  if (
    product.type === ProductType.JEWELLERY &&
    product.jewelrySpecifications &&
    product.jewelrySpecifications[lang.toUpperCase()]
  ) {
    return (
      product.jewelrySpecifications[lang.toUpperCase()].longDescription ||
      product.jewelrySpecifications.EN?.longDescription ||
      product.longDescription ||
      defaultDescription
    );
  }

  if (
    product.type === ProductType.HIGH_JEWELLERY &&
    product.highJewelrySpecifications &&
    product.highJewelrySpecifications[lang.toUpperCase()]
  ) {
    return (
      product.highJewelrySpecifications[lang.toUpperCase()].longDescription ||
      product.highJewelrySpecifications[lang.toUpperCase()].description ||
      defaultDescription
    );
  }

  return defaultDescription;
};

export const onlyShowOwnInspirations = (brandId: string) =>
  brandId === iwcBrandId;

export const getProductEdition = (product: IProduct, lang: string) => {
  return (
    product.editions &&
    product.editions[lang.toUpperCase()] &&
    product.editions[lang.toUpperCase()].edition
  );
};

type CompareSpec = {
  title: string;
  specs: string;
};
export const getProductsSpecsConfig = (
  products: IProduct[],
  brandId: string,
  storeId?: string
): CompareSpec[][] => {
  if (brandId === aspreyBrandId) {
    return products.map(getProductSpecsConfigForAsprey);
  } else {
    return products.map((p) => getProductSpecsConfigForSameType(p, storeId));
  }
};

export const isProductSpecsAvailable = (products: IProduct) => {
  return getProductSpecsConfigForSameType(products);
};
export const getProductSpecsConfigForSameType = (
  product: IProduct,
  storeId?: string
): CompareSpec[] => {
  switch (product.type) {
    case ProductType.WRISTWATCH:
      return watchSpecs;
    case ProductType.JEWELLERY:
      if (
        product.brandId === chopardBrandId &&
        storeId !== chopardHappySportKorea &&
        storeId !== chopardHappySportGenevaHQ
      )
        return [jewelrySpecs.find((spec) => spec.specs === 'other')];
      return jewelrySpecs.filter((spec) => spec.specs !== 'other');
    case ProductType.HIGH_JEWELLERY:
      return highJewelrySpecs;
    default:
      return watchSpecs || null;
  }
};
export const getProductSpecsConfigForAsprey = (
  product: IProduct
): CompareSpec[] => {
  switch (product.type) {
    case ProductType.WRISTWATCH:
      return watchSpecs;
    case ProductType.JEWELLERY:
      if (product.brandId === chopardBrandId)
        return [jewelrySpecs.find((spec) => spec.specs === 'other')];
      return jewelrySpecs.filter((spec) => spec.specs !== 'other');
    case ProductType.HIGH_JEWELLERY:
      return highJewelrySpecs;
    default:
      return commonProductSpecs;
  }
};
export const getProductSpecificationsByLang = (
  product: IProduct,
  language: string | undefined | null,
  storeId?: string
) => {
  const lang = language?.toUpperCase() || 'EN';
  switch (product?.type) {
    case ProductType.WRISTWATCH:
      return product.watchSpecifications?.[lang];
    case ProductType.JEWELLERY:
      if (
        product.brandId === chopardBrandId &&
        storeId !== chopardHappySportKorea &&
        storeId !== chopardHappySportGenevaHQ
      ) {
        return { other: product.jewelrySpecifications?.[lang]?.other };
      }
      return {
        ...(product.jewelrySpecifications?.[lang] || {}),
        other: undefined
      };
    case ProductType.STRAP:
    case ProductType.BUCKLE:
      return product?.buckleStrapSpecifications?.[lang];
    case ProductType.HIGH_JEWELLERY:
      return (
        product.highJewelrySpecifications?.[lang] ||
        product.highJewelrySpecifications.EN ||
        {}
      );
    default:
      return product?.watchSpecifications?.[lang];
  }
};

export const getProductIdFromUrl = (url: string): string => {
  if (url) return url.split('/products/')[1].split('?')[0];
};

export const isCustomizable = (accessories: IProductAccessories) => {
  return (
    accessories?.straps?.length > 1 ||
    (accessories?.straps?.length === 1 && accessories?.buckles?.length > 1)
  );
};

export const getDefaultStrap = (
  accessories: IProductAccessories,
  selectedStrapId?: string
): IProduct | null => {
  return (
    accessories.straps.find((strap) => strap.id === selectedStrapId) ||
    accessories.straps[Math.floor(accessories.straps.length / 2)]
  );
};

export const getDefaultBuckle = (
  accessories: IProductAccessories,
  selectedStrapId?: string,
  selectedBuckleId?: string
): IProduct | null => {
  const selectedStrap = getDefaultStrap(accessories, selectedStrapId);
  const currentPair = accessories.strapBucklePairs[selectedStrap?.id];

  if (!currentPair.length || (!selectedBuckleId && selectedStrapId))
    return null;

  return (
    accessories.buckles.find((buckle) => buckle.id === selectedBuckleId) ||
    accessories.buckles[0]
  );
};

export const getDefaultDial = (
  products: IProduct[],
  selectedDialId?: string
): IProduct => {
  return (
    products.find((product) => product.id === selectedDialId) || products[0]
  );
};

export const mapPreciousStoneSpec = (spec: {
  [key: string]: string | number;
}) => {
  const colorKey = spec.color_to ? 'colorFromTo' : 'color';
  const colorValue =
    colorKey === 'colorFromTo'
      ? `${spec.color} - ${spec.color_to}`
      : spec.color;
  const clarityKey = spec.clarity_to ? 'clarityFromTo' : 'clarity';
  const clarityValue =
    clarityKey === 'clarityFromTo'
      ? `${spec.clarity} - ${spec.clarity_to}`
      : spec.clarity;

  return {
    material: spec.material,
    shape: spec.shape,
    stoneType: spec.stoneType,
    numberOfStones: spec.numberOfStones,
    carats: spec.carat,
    [colorKey]: colorValue,
    [clarityKey]: clarityValue,
    origin: spec.origin,
    treatment: spec.treatment
  };
};

export const getProductsFilterByBlackAndWhitelist = (
  blacklistModelCodes?: string[],
  whitelistModelCodes?: string[]
): ProductsFilter => {
  if (whitelistModelCodes?.length) {
    return (products: IProduct[]) =>
      products?.filter((p) => whitelistModelCodes.includes(p.modelCode)) || [];
  }
  if (blacklistModelCodes?.length) {
    return (products: IProduct[]) =>
      products?.filter((p) => !blacklistModelCodes.includes(p.modelCode)) || [];
  }
  return identity;
};

export const getProductSpecifications = (
  product?: IProduct,
  language?: string
) => {
  if (!product) return;
  const lang = language?.toUpperCase() || 'EN';
  if (product.type === ProductType.WRISTWATCH) {
    return product.watchSpecifications[lang] || product.watchSpecifications.EN;
  }
  if (product.type === ProductType.STRAP) {
    return (
      product.buckleStrapSpecifications[lang] ||
      product.buckleStrapSpecifications.EN
    );
  }
  return product.watchSpecifications[lang] || product.watchSpecifications.EN;
};

export const chineseLenseBarReferreIds = [
  'ap-southeast-1:2c2e98e6-254d-4cb9-9fe5-95973b0a394d',
  'ap-southeast-1:df804c9e-075f-42c8-98f5-061b04a13420',
  'ap-southeast-1:2ef6dc81-2d7a-4b40-aa64-ec280460cb88',
  'ap-southeast-1:e5e84b70-944e-4b77-acbb-77de9e7fac90',
  'ap-southeast-1:a7c111c1-aa53-4938-b043-06d4211986d7',
  'ap-southeast-1:a05927db-a3d9-4bfc-b883-304a80b814b0',
  'ap-southeast-1:585ca5f2-22dc-4773-8cbc-7cb6e792e16f'
];

export const chineseShowcaseIds = [
  'c4b988de-082c-4170-9a20-3e145f2dca72',
  'e331f6c8-a485-4ab8-9c9b-49a70e46fa0f',
  '090262fc-edf2-42bc-9178-9b0a0b95bbe0',
  '09b88876-de2e-4250-8e4a-320e79607066',
  '28f9f8bd-26bb-4e52-bbe6-0ef73a8d30fc',
  'ab9858d4-dd36-4a7f-9a5e-c3819a1dd352',
  '9e134aba-c51e-4e55-99e3-21174040b6da'
];
