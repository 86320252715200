import { AnyAction, Dispatch } from 'redux';
import {
  rogerDubuisRetailId,
  rogerDubuisWholeSaleId,
  rogerDubuisWW2021Id
} from '../../config';
import {
  blackDoorForRetailAndWholeSaleUrl,
  blackDoorMobileUrl,
  blackDoorMobileUrlForRetailAndWholeSaleUrl,
  redDoorMobileForRetailAndWholeSaleUrl,
  redDoorForRetailAndWholeSaleUrl,
  blackDoorUrl,
  redDoorMobileUrl,
  redDoorUrl,
  SceneType,
  TRANSITIONED_FROM_WW2021_TO_MANUFACTURE
} from '../../config/rduWW2021';
import { ClientState } from '../../interfaces';
import { isUserOnMobileOnly } from '../../utils/deviceDetector';
import {
  getKRPano,
  krpanoInvoke,
  updatePanoBaseUrlByRegionIfRequired
} from '../../utils/krpano';
import {
  actionShowFullScreenVideoWithUrl,
  actionShowHudButton,
  actionSwitchOffLights,
  actionUpdateBackgroundMusicUrl,
  PARTICIPANT_DID_LEAVE_MEETING,
  VB_DID_CLICK_ON_HOT_SPOT,
  VB_DID_GO_TO_SCENE,
  VB_LOAD_NEW_PANO,
  VB_RELOAD_DEFAULT_PANO,
  VB_SWITCH_OFF_LIGHTS
} from '../actions';

const getCurrentScene = () => getKRPano()?.get('xml.scene');

const setSceneLight = (sceneName: string) => {
  const viewSetting =
    'view.fisheyefovlink=0.5&amp;view.architectural=0.0&amp;view.pannini=0.0&amp;view.fisheye=0.0&amp;view.stereographic=false';
  const h = getKRPano()?.get('view.hlookat');
  const v = getKRPano()?.get('view.vlookat');
  const f = getKRPano()?.get('view.fov');
  krpanoInvoke(
    `loadscene('${sceneName}', ${viewSetting}, MERGE, BLEND(1.0, easeInCubic));lookat(${h}, ${v}, ${f});`
  );
};

const stopBackgroundMusic = (dispatch: Dispatch) => {
  dispatch(actionUpdateBackgroundMusicUrl(undefined));
};

const handleHotSpotClickForOriginalWW2021 = (
  hotSpotName: string,
  dispatch: Dispatch
) => {
  switch (hotSpotName) {
    case 'scene_rd020000_video':
    case 'spot_tap2':
      dispatch(
        actionShowFullScreenVideoWithUrl({
          show: true,
          url: isUserOnMobileOnly() ? blackDoorMobileUrl : blackDoorUrl
        })
      );
      break;
    case 'scene_rd030000_video':
    case 'spot_tap3':
      dispatch(
        actionShowFullScreenVideoWithUrl({
          show: true,
          url: isUserOnMobileOnly() ? redDoorMobileUrl : redDoorUrl
        })
      );
      stopBackgroundMusic(dispatch);
      break;
    default:
      break;
  }
};
const handleHotSpotClickForRetailAndWholeSale = (
  hotSpotName: string,
  dispatch: Dispatch
) => {
  switch (hotSpotName) {
    case 'scene_rd020000_video':
    case 'spot_tap2':
      dispatch(
        actionShowFullScreenVideoWithUrl({
          show: true,
          url: isUserOnMobileOnly()
            ? blackDoorMobileUrlForRetailAndWholeSaleUrl
            : blackDoorForRetailAndWholeSaleUrl
        })
      );
      break;
    case 'scene_rd030000_video':
    case 'spot_tap3':
      dispatch(
        actionShowFullScreenVideoWithUrl({
          show: true,
          url: isUserOnMobileOnly()
            ? redDoorMobileForRetailAndWholeSaleUrl
            : redDoorForRetailAndWholeSaleUrl
        })
      );
      stopBackgroundMusic(dispatch);
      break;
    default:
      break;
  }
};

const handleHotSpotClick = (
  storeId: string,
  hotSpotName: string,
  dispatch: Dispatch
) => {
  switch (storeId) {
    case rogerDubuisWW2021Id:
      handleHotSpotClickForOriginalWW2021(hotSpotName, dispatch);
      break;
    case rogerDubuisRetailId:
    case rogerDubuisWholeSaleId:
      handleHotSpotClickForRetailAndWholeSale(hotSpotName, dispatch);
      break;
    default:
      break;
  }
};

const handleLightSwitchForOriginalWW2021 = (show: boolean) => {
  const currentScene = getCurrentScene();
  switch (currentScene) {
    case SceneType.scene_010000:
    case SceneType.scene_010000_night:
      setSceneLight(
        show ? SceneType.scene_010000_night : SceneType.scene_010000
      );
      break;
    case SceneType.scene_rd010000:
    case SceneType.scene_rd010000_night:
      setSceneLight(
        show ? SceneType.scene_rd010000_night : SceneType.scene_rd010000
      );
      break;
    case SceneType.scene_rd020000:
    case SceneType.scene_rd020000_night:
      setSceneLight(
        show ? SceneType.scene_rd020000_night : SceneType.scene_rd020000
      );
      break;
    case SceneType.scene_rd030000:
    case SceneType.scene_rd030000_night:
      setSceneLight(
        show ? SceneType.scene_rd030000_night : SceneType.scene_rd030000
      );
      break;
    case SceneType.scene_rd040000:
    case SceneType.scene_rd040000_night:
      setSceneLight(
        show ? SceneType.scene_rd040000_night : SceneType.scene_rd040000
      );
      break;
    case SceneType.scene_rd050000:
    case SceneType.scene_rd050000_night:
      setSceneLight(
        show ? SceneType.scene_rd050000_night : SceneType.scene_rd050000
      );
      break;
    case SceneType.scene_rd060000:
    case SceneType.scene_rd060000_night:
      setSceneLight(
        show ? SceneType.scene_rd060000_night : SceneType.scene_rd060000
      );
      break;
    case SceneType.scene_rd070000:
    case SceneType.scene_rd070000_night:
      setSceneLight(
        show ? SceneType.scene_rd070000_night : SceneType.scene_rd070000
      );
      break;
    case SceneType.scene_left_030000:
    case SceneType.scene_left_030000_night:
      setSceneLight(
        show ? SceneType.scene_left_030000_night : SceneType.scene_left_030000
      );
      break;
    case SceneType.scene_left_040000:
    case SceneType.scene_left_040000_night:
      setSceneLight(
        show ? SceneType.scene_left_040000_night : SceneType.scene_left_040000
      );
      break;
    case SceneType.scene_left_060000:
    case SceneType.scene_left_060000_night:
      setSceneLight(
        show ? SceneType.scene_left_060000_night : SceneType.scene_left_060000
      );
      break;
    case SceneType.scene_lounge_010000:
    case SceneType.scene_lounge_010000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_010000_night
          : SceneType.scene_lounge_010000
      );
      break;
    case SceneType.scene_lounge_020000:
    case SceneType.scene_lounge_020000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_020000_night
          : SceneType.scene_lounge_020000
      );
      break;
    case SceneType.scene_lounge_030000:
    case SceneType.scene_lounge_030000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_030000_night
          : SceneType.scene_lounge_030000
      );
      break;
    case SceneType.scene_lounge_040000:
    case SceneType.scene_lounge_040000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_040000_night
          : SceneType.scene_lounge_040000
      );
      break;
    case SceneType.scene_lounge_050000:
    case SceneType.scene_lounge_050000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_050000_night
          : SceneType.scene_lounge_050000
      );
      break;
    case SceneType.scene_lounge_060000:
    case SceneType.scene_lounge_060000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_060000_night
          : SceneType.scene_lounge_060000
      );
      break;
    case SceneType.scene_lounge_070000:
    case SceneType.scene_lounge_070000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_070000_night
          : SceneType.scene_lounge_070000
      );
      break;
    case SceneType.scene_lounge_080000:
    case SceneType.scene_lounge_080000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_080000_night
          : SceneType.scene_lounge_080000
      );
      break;
    case SceneType.scene_left_080000:
    case SceneType.scene_left_080000_night:
      setSceneLight(
        show ? SceneType.scene_left_080000_night : SceneType.scene_left_080000
      );
      break;
    case SceneType.scene_lounge_090000:
    case SceneType.scene_lounge_090000_night:
      setSceneLight(
        show
          ? SceneType.scene_lounge_090000_night
          : SceneType.scene_lounge_090000
      );
      break;
    case SceneType.scene_rd01a:
    case SceneType.scene_rd01a_night:
      setSceneLight(show ? SceneType.scene_rd01a_night : SceneType.scene_rd01a);
      break;
    case SceneType.scene_rd020000_door:
    case SceneType.scene_rd020000_door_night:
      setSceneLight(
        show
          ? SceneType.scene_rd020000_door_night
          : SceneType.scene_rd020000_door
      );
      break;
    case SceneType.scene_left_070000:
    case SceneType.scene_left_070000_night:
      setSceneLight(
        show ? SceneType.scene_left_070000_night : SceneType.scene_left_070000
      );
      break;
  }
};

const handleLightSwitchForRetail = (show: boolean) => {
  const currentScene = getCurrentScene();
  switch (currentScene) {
    case 'scene_010000':
    case 'scene_010000_night':
      setSceneLight(show ? 'scene_010000_night' : 'scene_010000');
      break;
    case 'scene_rd01a':
    case 'scene_rd01a_night':
      setSceneLight(show ? 'scene_rd01a_night' : 'scene_rd01a');
      break;
    case 'scene_rd010000':
    case 'scene_rd010000_night':
      setSceneLight(show ? 'scene_rd010000_night' : 'scene_rd010000');
      break;
    case 'scene_rd020000':
    case 'scene_rd020000_night':
      setSceneLight(show ? 'scene_rd020000_night' : 'scene_rd020000');
      break;
    case 'scene_rd020000_door':
    case 'scene_rd020000_door_night':
      setSceneLight(show ? 'scene_rd020000_door_night' : 'scene_rd020000_door');
      break;
    case 'scene_rd030000':
    case 'scene_rd030000_night':
      setSceneLight(show ? 'scene_rd030000_night' : 'scene_rd030000');
      break;
    case 'scene_rd040000':
    case 'scene_rd040000_night':
      setSceneLight(show ? 'scene_rd040000_night' : 'scene_rd040000');
      break;
    case 'scene_rd050000':
    case 'scene_rd050000_night':
      setSceneLight(show ? 'scene_rd050000_night' : 'scene_rd050000');
      break;
    case 'scene_rd060000':
    case 'scene_rd060000_night':
      setSceneLight(show ? 'scene_rd060000_night' : 'scene_rd060000');
      break;
    case 'scene_rd070000':
    case 'scene_rd070000_night':
      setSceneLight(show ? 'scene_rd070000_night' : 'scene_rd070000');
      break;
    case 'scene_left_030000':
    case 'scene_left_030000_night':
      setSceneLight(show ? 'scene_left_030000_night' : 'scene_left_030000');
      break;
    case 'scene_left_040000':
    case 'scene_left_040000_night':
      setSceneLight(show ? 'scene_left_040000_night' : 'scene_left_040000');
      break;
    case 'scene_left_060000':
    case 'scene_left_060000_night':
      setSceneLight(show ? 'scene_left_060000_night' : 'scene_left_060000');
      break;
    case 'scene_newbar_070000':
    case 'scene_newbar_070000_night':
      setSceneLight(show ? 'scene_newbar_070000_night' : 'scene_newbar_070000');
      break;
    case 'scene_left_070000':
    case 'scene_left_070000_night':
      setSceneLight(show ? 'scene_left_070000_night' : 'scene_left_070000');
      break;
    case 'scene_left_080000':
    case 'scene_left_080000_night':
      setSceneLight(show ? 'scene_left_080000_night' : 'scene_left_080000');
      break;
    case 'scene_left_090000':
    case 'scene_left_090000_night':
      setSceneLight(show ? 'scene_left_090000_night' : 'scene_left_090000');
      break;
    case 'scene_left_100000':
    case 'scene_left_100000_night':
      setSceneLight(show ? 'scene_left_100000_night' : 'scene_left_100000');
      break;
    case 'scene_lounge_010000':
    case 'scene_lounge_010000_night':
      setSceneLight(show ? 'scene_lounge_010000_night' : 'scene_lounge_010000');
      break;
    case 'scene_lounge_020000':
    case 'scene_lounge_020000_night':
      setSceneLight(show ? 'scene_lounge_020000_night' : 'scene_lounge_020000');
      break;
    case 'scene_lounge_030000':
    case 'scene_lounge_030000_night':
      setSceneLight(show ? 'scene_lounge_030000_night' : 'scene_lounge_030000');
      break;
    case 'scene_lounge_040000':
    case 'scene_lounge_040000_night':
      setSceneLight(show ? 'scene_lounge_040000_night' : 'scene_lounge_040000');
      break;
    case 'scene_lounge_050000':
    case 'scene_lounge_050000_night':
      setSceneLight(show ? 'scene_lounge_050000_night' : 'scene_lounge_050000');
      break;
    case 'scene_lounge_060000':
    case 'scene_lounge_060000_night':
      setSceneLight(show ? 'scene_lounge_060000_night' : 'scene_lounge_060000');
      break;
    case 'scene_lounge_070000':
    case 'scene_lounge_070000_night':
      setSceneLight(show ? 'scene_lounge_070000_night' : 'scene_lounge_070000');
      break;
    case 'scene_lounge_080000':
    case 'scene_lounge_080000_night':
      setSceneLight(show ? 'scene_lounge_080000_night' : 'scene_lounge_080000');
      break;
    case 'scene_lounge_090000':
    case 'scene_lounge_090000_night':
      setSceneLight(show ? 'scene_lounge_090000_night' : 'scene_lounge_090000');
      break;
    case 'scene_left_110000':
    case 'scene_left_110000_night':
      setSceneLight(show ? 'scene_left_110000_night' : 'scene_left_110000');
      break;
    case 'scene_left_120000':
    case 'scene_left_120000_night':
      setSceneLight(show ? 'scene_left_120000_night' : 'scene_left_120000');
      break;
    case 'scene_left_130000':
    case 'scene_left_130000_night':
      setSceneLight(show ? 'scene_left_130000_night' : 'scene_left_130000');
      break;
    case 'scene_left_140000':
    case 'scene_left_140000_night':
      setSceneLight(show ? 'scene_left_140000_night' : 'scene_left_140000');
      break;

    case 'scene_newbar_080000':
    case 'scene_newbar_080000_night':
      setSceneLight(show ? 'scene_newbar_080000_night' : 'scene_newbar_080000');
      break;
    case 'scene_newbar_090000':
    case 'scene_newbar_090000_night':
      setSceneLight(show ? 'scene_newbar_090000_night' : 'scene_newbar_090000');
      break;
    case 'scene_newbar_100000':
    case 'scene_newbar_100000_night':
      setSceneLight(show ? 'scene_newbar_100000_night' : 'scene_newbar_100000');
      break;
  }
};

const restorePano = (clientState: ClientState) => {
  removepano('krpanoSWFObject');
  window[TRANSITIONED_FROM_WW2021_TO_MANUFACTURE] = false;
  const storeId =
    clientState.teleport?.teleportTo?.store?.id || clientState.store?.id;
  const panoConfig =
    clientState.teleport?.teleportTo?.config?.pano ||
    clientState.virtualBoutiqueConfig?.pano;
  if (storeId && panoConfig) {
    const updatedPano = updatePanoBaseUrlByRegionIfRequired(
      storeId,
      panoConfig
    );
    embedpano({
      swf: updatedPano?.swf,
      xml: updatedPano?.xml,
      target: 'embedframe',
      html5: 'auto',
      consolelog: location.hostname === 'localhost',
      mobilescale: 1.0,
      passQueryParameters: true,
      basepath: updatedPano?.basepath
    });
  }
};

const handleLightSwitch = (storeId: string, show: boolean) => {
  switch (storeId) {
    case rogerDubuisWW2021Id:
      handleLightSwitchForOriginalWW2021(show);
      break;
    case rogerDubuisRetailId:
    case rogerDubuisWholeSaleId:
      handleLightSwitchForRetail(show);
      break;
    default:
      break;
  }
};

const backToHome = (
  dispatch: Dispatch<AnyAction>,
  clientState: ClientState
) => {
  restorePano(clientState);
  dispatch(actionSwitchOffLights(false));
  dispatch(actionShowHudButton(false));
};

export const handleRDWW2021Action = (
  action: AnyAction,
  dispatch: Dispatch<AnyAction>,
  clientState: ClientState,
  storeId: string
) => {
  switch (action.type) {
    case VB_LOAD_NEW_PANO:
      removepano('krpanoSWFObject');
      window[TRANSITIONED_FROM_WW2021_TO_MANUFACTURE] = true;
      embedpano(action.payload);
      break;
    case VB_DID_CLICK_ON_HOT_SPOT:
      handleHotSpotClick(storeId, action.payload, dispatch);
      break;
    case VB_SWITCH_OFF_LIGHTS:
      handleLightSwitch(storeId, action.payload);
      break;
    case VB_RELOAD_DEFAULT_PANO:
      backToHome(dispatch, clientState);
      break;
    case PARTICIPANT_DID_LEAVE_MEETING:
      if (
        !clientState?.meeting?.advisorParticipantId &&
        !clientState?.meeting?.isPresenter &&
        window[TRANSITIONED_FROM_WW2021_TO_MANUFACTURE] === true
      ) {
        backToHome(dispatch, clientState);
      }
      break;
    case VB_DID_GO_TO_SCENE:
      if (action.payload === 'scene_pano_looby') {
        krpanoInvoke(
          `removehotspot('spot1');removehotspot('spot2');removehotspot('spot3');`
        );
      }
      break;
    default:
      break;
  }
};
