import {
  FOLDER_CURRENT_CONTEXT,
  HUB_ABORT_FILE_UPLOAD,
  HUB_ADD_FILE_UPLOADED,
  HUB_ASSET_LIBRARY_ADD_UPDATING_ASSET,
  HUB_ASSET_LIBRARY_DELETE_ASSETS_FROM_STORE,
  HUB_ASSET_LIBRARY_LIST_ASSETS_FAILED,
  HUB_ASSET_LIBRARY_LIST_ASSETS_PENDING,
  HUB_ASSET_LIBRARY_LIST_ASSETS_SUCCESSFUL,
  HUB_ASSET_LIBRARY_UPDATE_ASSET,
  HUB_CLEAR_FILE_UPLOAD,
  HUB_CONVERTED_FILE_UPLOAD,
  HUB_SET_ASSET_LIBRARY_POPUP,
  HUB_SET_COLLABORATION_STATE,
  HUB_SET_FILE_UPLOAD,
  HUB_SET_LOADING_STATUS,
  HUB_UPDATE_ELEMENTS_IN_FOLDER_KEYS,
  HUB_UPDATE_NUDGE_OG_DESCRIPTION,
  HUB_UPDATE_NUDGE_OG_IMAGE,
  HUB_UPDATE_NUDGE_OG_TITLE,
  HUB_UPDATE_SCENE,
  HUB_UPDATE_USER_ATTRIBUTES
} from './../actions';
import { AnyAction } from 'redux';
import {
  ClientState,
  IAsset,
  LoginStatus,
  NudgeSaveStatus,
  ProgressStatus
} from '../../interfaces';
import {
  HUB_ADD_USER_INFO,
  HUB_CLEAR_NUDGE,
  HUB_CLOSE_ALERT,
  HUB_COPY_AS_NEW_NUDGE,
  HUB_CREATE_NEW_NUDGE,
  HUB_LOAD_FOLDERS,
  HUB_LOAD_NUDGE,
  HUB_LOAD_NUDGE_LIST,
  HUB_LOAD_NUDGE_TEMPLATE_LIST,
  HUB_LOAD_SESSION_LIST,
  HUB_LOAD_USER,
  HUB_SELECT_FOLDER,
  HUB_SESSION_UPDATE_FILES,
  HUB_SESSION_UPDATE_FILE_UPLOAD_PROGRESS,
  HUB_SESSION_UPDATE_FILE_UPLOAD_STATUS,
  HUB_SHOW_ALERT,
  HUB_TOGGLE_SHOW_ONLY_FAVORITES,
  HUB_UPDATE_FAVORITES,
  HUB_UPDATE_FOLDER,
  HUB_UPDATE_LOGIN_ERROR,
  HUB_UPDATE_LOGIN_STATUS,
  HUB_UPDATE_NUDGE_CONTENT,
  HUB_UPDATE_NUDGE_CURRENCY,
  HUB_UPDATE_NUDGE_DESCRIPTION,
  HUB_UPDATE_NUDGE_LANGUAGE,
  HUB_UPDATE_NUDGE_RECIPIENT,
  HUB_UPDATE_NUDGE_TITLE,
  HUB_UPDATE_PROGRESS_STATUS,
  HUB_UPDATE_SESSION_DATE,
  HUB_UPDATE_SESSION_FORM_DATA,
  HUB_UPDATE_SESSION_PROGRESS_STATUS,
  HUB_PEXEL_UPLOAD_STATUS
} from '../actions';
import { generateV4UUID } from './../../utils/identityGenerator';
import { defaultClientState } from './clientReducer';
import { orderBy, uniq } from 'lodash';
import { SORT_KEY_CAMEL_CASE } from '../../utils/constants';

const hubReducer = (
  state: ClientState = defaultClientState,
  action: AnyAction
): ClientState => {
  switch (action.type) {
    case HUB_UPDATE_LOGIN_STATUS: {
      if (action.payload === LoginStatus.LOGGED_OUT) {
        return {
          ...state,
          hub: undefined
        };
      }
      const existingErrorMessage = state.hub?.loginErrorMessage;
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          loginStatus: action.payload,
          loginErrorMessage:
            action.payload === LoginStatus.LOGGED_IN
              ? undefined
              : existingErrorMessage
        }
      };
    }
    case HUB_UPDATE_LOGIN_ERROR: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          loginErrorMessage: action.payload
        }
      };
    }

    case HUB_UPDATE_NUDGE_CONTENT: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            content: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_NUDGE_RECIPIENT: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            name: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_NUDGE_TITLE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            title: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_NUDGE_DESCRIPTION: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            description: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_NUDGE_OG_TITLE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            ogTitle: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_NUDGE_OG_DESCRIPTION: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            ogDescription: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_NUDGE_OG_IMAGE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            ogImage: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_NUDGE_CURRENCY: {
      const { currency, countryCode } = action.payload || {};
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            currency: currency || '',
            countryCode: countryCode || '',
            hidePrice: !currency && !countryCode ? true : undefined
          }
        }
      };
    }

    case HUB_LOAD_NUDGE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            ...action.payload
          }
        }
      };
    }

    case HUB_CLEAR_NUDGE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            name: null,
            content: [],
            saveStatus: NudgeSaveStatus.INITIAL
          }
        }
      };
    }

    case HUB_CREATE_NEW_NUDGE: {
      const languageCode = state.hub?.user?.language?.toUpperCase();
      const storeId = state.hub?.user?.storeId;
      const brand = state.hub?.user?.brandId;
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            id: generateV4UUID(),
            content: [],
            languageCode,
            storeId,
            brand
          },
          progressStatus: ProgressStatus.INITIAL
        }
      };
    }

    case HUB_COPY_AS_NEW_NUDGE: {
      const languageCode = state.hub?.nudge?.languageCode;
      const storeId = state.hub?.user?.storeId;
      const brand = state.hub?.user?.brandId;
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            id: generateV4UUID(),
            content: state.hub?.nudge?.content || [],
            languageCode,
            storeId,
            brand,
            currency: state.hub?.nudge?.currency,
            hidePrice: state?.hub?.nudge?.hidePrice
          },
          progressStatus: ProgressStatus.INITIAL
        }
      };
    }

    case HUB_UPDATE_PROGRESS_STATUS: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          progressStatus: action.payload
        }
      };
    }

    case HUB_SHOW_ALERT: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          alert: {
            open: true,
            ...action.payload
          }
        }
      };
    }

    case HUB_CLOSE_ALERT: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          alert: {
            ...(state.hub.alert || {}),
            open: false
          }
        }
      };
    }

    case HUB_LOAD_NUDGE_LIST: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudgeList: action.payload
        }
      };
    }

    case HUB_LOAD_NUDGE_TEMPLATE_LIST: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudgeTemplateList: action.payload
        }
      };
    }

    case HUB_LOAD_USER: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          user: action.payload
        }
      };
    }

    case HUB_UPDATE_USER_ATTRIBUTES: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          user: {
            ...(state.hub?.user || {}),
            ...action.payload
          }
        }
      };
    }

    case HUB_UPDATE_NUDGE_LANGUAGE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          nudge: {
            ...(state.hub?.nudge || {}),
            languageCode: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_FAVORITES: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          favorites: action.payload
        }
      };
    }

    case HUB_TOGGLE_SHOW_ONLY_FAVORITES: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          showingOnlyFavorites: action.payload
        }
      };
    }

    case HUB_UPDATE_SESSION_FORM_DATA: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          sessions: {
            ...(state.hub?.sessions || {}),
            formData: action.payload
          }
        }
      };
    }

    case HUB_SESSION_UPDATE_FILE_UPLOAD_PROGRESS: {
      const currentProgress = state.hub?.sessions?.fileUploadStatus || [];
      const otherFilesStatus = currentProgress.filter(
        (status) => status.name !== action.payload.name
      );
      const updatedProgress = [...otherFilesStatus, action.payload];
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          sessions: {
            ...(state.hub?.sessions || {}),
            fileUploadStatus: updatedProgress
          }
        }
      };
    }

    case HUB_SESSION_UPDATE_FILES: {
      const currentFormData = state.hub?.sessions?.formData || {};
      const updatedFormData = {
        ...currentFormData,
        uploadedFiles: action.payload
      };
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          sessions: {
            ...(state.hub?.sessions || {}),
            formData: updatedFormData
          }
        }
      };
    }

    case HUB_SESSION_UPDATE_FILE_UPLOAD_STATUS: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          sessions: {
            ...(state.hub?.sessions || {}),
            fileUploadStatus: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_SESSION_PROGRESS_STATUS: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          sessions: {
            ...(state.hub?.sessions || {}),
            progressStatus: action.payload
          }
        }
      };
    }

    case HUB_UPDATE_SESSION_DATE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          sessions: {
            ...(state.hub?.sessions || {}),
            date: action.payload
          }
        }
      };
    }

    case HUB_LOAD_SESSION_LIST: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          sessions: {
            ...(state.hub?.sessions || {}),
            sessionList: action.payload
          }
        }
      };
    }

    case HUB_ADD_USER_INFO: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          userInfo: {
            ...(state?.hub?.userInfo || {}),
            [action.payload.id]: action.payload
          }
        }
      };
    }

    case HUB_LOAD_FOLDERS: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          folders: {
            ...(state.hub?.folders || {}),
            [action.payload.context]: action.payload.folder
          }
        }
      };
    }

    case HUB_SELECT_FOLDER: {
      const currentState = state.hub?.folders || {};
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          folders: {
            ...currentState,
            activeFolderKey:
              action.payload.activeFolderKey || currentState.activeFolderKey,
            expandedFolderKeys:
              action.payload.expandedFolderKeys ||
              currentState.expandedFolderKeys
          }
        }
      };
    }
    case FOLDER_CURRENT_CONTEXT: {
      const currentState = state.hub?.folders || {};
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          folders: {
            ...currentState,
            currentContext: action.payload.currentContext
          }
        }
      };
    }

    case HUB_UPDATE_FOLDER: {
      const currentFolderInContext =
        state.hub?.folders?.[action.payload.context] || [];

      const currentFolder = currentFolderInContext.find(
        (f) => f.id === action.payload.folder.id
      );

      const updatedCurrentFolder = {
        ...(currentFolder || {}),
        ...action.payload.folder
      };
      const updatedFolderInContext = [
        updatedCurrentFolder,
        ...currentFolderInContext.filter(
          (f) => f.id !== action.payload.folder.id
        )
      ];

      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          folders: {
            ...(state.hub?.folders || {}),
            [action.payload.context]: updatedFolderInContext
          }
        }
      };
    }

    case HUB_UPDATE_ELEMENTS_IN_FOLDER_KEYS: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          folders: {
            ...(state.hub?.folders || {}),
            elementsInFolderKeys: {
              ...(state.hub?.folders?.elementsInFolderKeys || {}),
              ...action.payload
            }
          }
        }
      };
    }

    case HUB_SET_LOADING_STATUS: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          loadingStatus: {
            ...(state.hub?.loadingStatus || {}),
            [action.payload.key]: action.payload.status
          }
        }
      };
    }

    case HUB_SET_FILE_UPLOAD: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          fileUpload: {
            ...(state.hub?.fileUpload || {}),
            files: action.payload
          }
        }
      };
    }

    case HUB_CONVERTED_FILE_UPLOAD: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          fileUpload: {
            ...(state.hub?.fileUpload || {}),
            converted: uniq([
              ...(state.hub?.fileUpload?.converted || []),
              ...action.payload
            ])
          }
        }
      };
    }

    case HUB_CLEAR_FILE_UPLOAD: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          fileUpload: undefined
        }
      };
    }

    case HUB_ADD_FILE_UPLOADED: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          fileUpload: {
            ...(state.hub?.fileUpload || {}),
            uploaded: [
              ...(state.hub?.fileUpload?.uploaded || []),
              action.payload
            ]
          }
        }
      };
    }
    case HUB_ABORT_FILE_UPLOAD: {
      const uploaded = (state.hub?.fileUpload?.uploaded || []).filter(
        (item) => item !== action.payload
      );
      const converted = (state.hub?.fileUpload?.converted || []).filter(
        (item) => item !== action.payload
      );
      const files = (state.hub?.fileUpload?.files || []).filter(
        (item) => item.key !== action.payload
      );

      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          fileUpload: {
            uploaded,
            converted,
            files
          }
        }
      };
    }
    case HUB_ASSET_LIBRARY_LIST_ASSETS_PENDING: {
      return {
        ...state,
        hub: {
          ...state.hub,
          assetLibrary: {
            ...(state?.hub?.assetLibrary || {}),
            assets: {
              ...(state?.hub?.assetLibrary?.assets || {}),
              loading: true
            }
          }
        }
      };
    }
    case HUB_ASSET_LIBRARY_LIST_ASSETS_SUCCESSFUL: {
      let newList: IAsset[] = action.payload?.assets || [];
      if (action.payload.loadMore) {
        if (
          state.hub?.assetLibrary?.assets?.data?.length + newList.length >
          action.payload?.totalCount
        ) {
          newList = state.hub?.assetLibrary?.assets?.data;
        }
        if (action.payload.loadMore === 'start') {
          newList = newList.concat(
            state?.hub?.assetLibrary?.assets?.data || []
          );
        }
        if (action.payload.loadMore === 'end') {
          newList = (state?.hub?.assetLibrary?.assets?.data || []).concat(
            newList
          );
        }
      }

      if (action.payload?.executeClientSort) {
        const searchOptions = action.payload?.searchOptions;
        const sortKey =
          SORT_KEY_CAMEL_CASE[searchOptions.sortKey] || searchOptions.sortKey;
        const sortOrder = searchOptions.sortOrder;
        newList = orderBy(newList, sortKey, sortOrder);
      }

      return {
        ...state,
        hub: {
          ...state.hub,
          assetLibrary: {
            ...(state?.hub?.assetLibrary || {}),
            assets: {
              data: newList,
              total: action.payload?.totalCount || 0,
              loading: false,
              hasMore: action.payload?.hasMore
            }
          }
        }
      };
    }

    case HUB_ASSET_LIBRARY_LIST_ASSETS_FAILED: {
      return {
        ...state,
        hub: {
          ...state.hub,
          assetLibrary: {
            ...(state?.hub?.assetLibrary || {}),
            assets: {
              ...(state?.hub?.assetLibrary?.assets || {}),
              loading: false,
              errorMessage: action.payload
            }
          }
        }
      };
    }

    case HUB_ASSET_LIBRARY_UPDATE_ASSET: {
      const updateAsset = action.payload as IAsset;
      const indexOfAssetInState =
        state.hub?.assetLibrary?.assets?.data?.findIndex(
          (asset) => asset.id === updateAsset.id
        );
      if (indexOfAssetInState >= 0) {
        state.hub.assetLibrary.assets.data[indexOfAssetInState] = {
          ...updateAsset
        };
      }
      return {
        ...state,
        hub: {
          ...state.hub,
          assetLibrary: {
            ...(state?.hub?.assetLibrary || {}),
            assets: {
              ...(state?.hub?.assetLibrary?.assets || {})
            }
          }
        }
      };
    }

    case HUB_ASSET_LIBRARY_ADD_UPDATING_ASSET: {
      const updateAsset = action.payload as IAsset;
      let newList = state?.hub?.assetLibrary.updatingAsset || [];
      const assetIndex = newList.findIndex(
        (asset) => asset.id === updateAsset.id
      );
      if (assetIndex >= 0) {
        newList[assetIndex] = {
          ...updateAsset
        };
      } else {
        newList = newList.concat(updateAsset);
      }
      return {
        ...state,
        hub: {
          ...state.hub,
          assetLibrary: {
            ...(state?.hub?.assetLibrary || {}),
            updatingAsset: newList
          }
        }
      };
    }

    case HUB_ASSET_LIBRARY_DELETE_ASSETS_FROM_STORE: {
      const deletedIds = action.payload as string[];

      return {
        ...state,
        hub: {
          ...state.hub,
          assetLibrary: {
            ...(state?.hub?.assetLibrary || {}),
            assets: {
              ...(state?.hub?.assetLibrary?.assets || {}),
              data: (state?.hub?.assetLibrary?.assets?.data || []).filter(
                (asset) => !deletedIds.includes(asset.id)
              ),
              total: state?.hub?.assetLibrary?.assets?.total - deletedIds.length
            }
          }
        }
      };
    }

    case HUB_SET_ASSET_LIBRARY_POPUP: {
      return {
        ...state,
        hub: {
          ...state.hub,
          assetLibrary: {
            ...(state?.hub?.assetLibrary || {}),
            popup: action.payload
          }
        }
      };
    }

    case HUB_SET_COLLABORATION_STATE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          collaboration: action.payload
        }
      };
    }

    case HUB_UPDATE_SCENE: {
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          scene: action.payload
        }
      };
    }

    case HUB_PEXEL_UPLOAD_STATUS: {
      const currentStatus = state.hub?.scene?.pexelsUploadStatus || {};
      const newStatus = { ...currentStatus, ...action.payload };
      return {
        ...state,
        hub: {
          ...(state.hub || {}),
          scene: {
            ...(state.hub?.scene || {}),
            pexelsUploadStatus: newStatus
          }
        }
      };
    }

    default:
      return state;
  }
};

export default hubReducer;
