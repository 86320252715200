import React from 'react';
import { iwcWW2021Id } from '../../config';

const IWCVBEnStyle = () => (
  <style jsx global>
    {`
      @font-face {
        font-family: 'Helvetica Neue LT Std';
        src: url('/asset/iwc/fonts/HelveticaNeueLTStd-Lt.eot');
        src: url('/asset/iwc/fonts/HelveticaNeueLTStd-Lt.eot?#iefix')
            format('embedded-opentype'),
          url('/asset/iwc/fonts/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
          url('/asset/iwc/fonts/HelveticaNeueLTStd-Lt.woff') format('woff'),
          url('/asset/iwc/fonts/HelveticaNeueLTStd-Lt.ttf') format('truetype'),
          url('/asset/iwc/fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt')
            format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Gotham Pro';
        src: url('/asset/iwc/fonts/GothamPro-Bold.eot');
        src: url('/asset/iwc/fonts/GothamPro-Bold.eot?#iefix')
            format('embedded-opentype'),
          url('/asset/iwc/fonts/GothamPro-Bold.woff2') format('woff2'),
          url('/asset/iwc/fonts/GothamPro-Bold.woff') format('woff'),
          url('/asset/iwc/fonts/GothamPro-Bold.ttf') format('truetype'),
          url('/asset/iwc/fonts/GothamPro-Bold.svg#GothamPro-Bold')
            format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      body {
        font-family: 'Helvetica Neue LT Std', sans-serif;
        font-size: 15px;
      }
      strong,
      .strong {
        font-weight: 500;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      .appointment label {
        font-family: 'Gotham Pro', sans-serif;
        font-weight: normal;
      }

      button,
      .btn,
      .LoadingProgress .label {
        font-family: Helvetica, sans-serif;
      }

      .input-group-sm > .form-control {
        padding: 0.25rem 0.5rem 0px;
      }

      .action-button,
      .action-button button {
        font-weight: normal;
      }

      .btn-action-group .action-button,
      .btn-action-group .action-button button {
        font-weight: bold;
      }

      .tstc label {
        font-family: 'Helvetica Neue LT Std', sans-serif;
      }


      #ap-close,
      .close {
        font-family: 'Source Sans Pro', sans-serif;
      }
      .nav-item__icon svg {
        margin-top: -5px;
      }

      @media (max-width: 768px) {
        .landing-page button {
          font-size: 0.65em;
        }
      }
      @media (min-width: 1024px) {
        .landing-page button {
          height: 69px;
        }
        .explore-own {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
      .store-${iwcWW2021Id}{
        background-size:cover !important;
      }
      .store-${iwcWW2021Id} .logo-top {
        display: none !important;
      }
      .store-${iwcWW2021Id} .logo-middle {
        display: block !important;
        width: 150px;
        margin: 0 auto 20px;
      }
    `}
  </style>
);

const IWCVBStyle = ({ language }: { language: string }) =>
  language === 'ar' ? null : <IWCVBEnStyle />;

export default IWCVBStyle;
