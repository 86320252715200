import { first } from 'lodash';
import { AnyAction, Dispatch } from 'redux';
import { isProd } from '../../config';
import {
  adrenalineScenes,
  expressiveSingularityScenes,
  manufactureScenes,
  pirelliScenesRange,
  interiorWallVideoScenes,
  redPirelliFrameUrl,
  redPirelliProductId,
  sceneByMenu,
  secretRoomFlipScenePairs,
  secretRoomTunnelDesktopVideoUrl,
  secretRoomTunnelMobileVideoUrl,
  welcomeToScene,
  whitePirelliFrameUrl,
  whitePirelliProductId,
  yellowPirelliFrameUrl,
  yellowPirelliProductId
} from '../../config/rduSg';
import { TRANSITIONED_FROM_WW2021_TO_MANUFACTURE } from '../../config/rduWW2021';
import { PanoView, RDUHudMenu } from '../../interfaces';
import { ALLOW_ENTER_TUNNEL, IS_FLIPPING } from '../../utils/constants';
import { isUserOnMobile } from '../../utils/deviceDetector';
import { getKRPano, krpanoInvoke } from '../../utils/krpano';
import {
  actionAccessRequest,
  actionOpenHud,
  actionOpenMusicPlaylist,
  actionReloadStoreDefaultPano,
  actionShowFullScreenVideoWithUrl,
  actionShowHudButton,
  actionShowVBZoomIndicator,
  actionShowWelcomeToScene,
  actionToggleVeil,
  VB_ACCESS_REQUEST,
  VB_DID_CLICK_HUD_MENU,
  VB_DID_CLICK_ON_HOT_SPOT,
  VB_DID_GO_TO_SCENE,
  VB_DID_LOOK_TO_VIEW,
  VB_DID_START_PLAYING_FULL_SCREEN_VIDEO,
  VB_FLIP_PANO,
  VB_RESET_AND_FLIP_PANO,
  VB_SHOW_WELCOME_TO_SCENE,
  VB_START_GLITCH_PANO,
  VB_VIDEO_DID_COMPLETE
} from '../actions';
import { getAllProductHotspotsInScene, normalize } from './common';

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let shouldGlitch = false;

const startGlitchEffect = async () => {
  shouldGlitch = true;
  let counter = 0;
  const sceneName = getCurrentScene();
  while (shouldGlitch && counter < 8) {
    if (getCurrentScene() !== sceneName) {
      shouldGlitch = false;
      break;
    }
    getKRPano()?.call('switch(display.anaglyph);');
    await timeout(Math.random() * 500);
    counter++;
  }
};

const handleEnterTunnel = (dispatch: Dispatch) => {
  dispatch(
    actionShowFullScreenVideoWithUrl({
      show: true,
      url: isUserOnMobile()
        ? secretRoomTunnelMobileVideoUrl
        : secretRoomTunnelDesktopVideoUrl
    })
  );
};

const handleInteriorWallVideoPlaylist = () => {
  const videos = Array.from(Array(8).keys()).map((i) => `video_${i + 1}`);
  const baseUrl =
    'https://panos2.inspify.io/virtual_boutique/6578a5b9-0382-4ebc-a4b5-ebeb4a5408a5/interior_wall/v1/';
  const currentUrl =
    getKRPano()?.get('hotspot[interior_wall_video].videourl') || '';
  const currentIndex = videos.findIndex((video) => currentUrl.includes(video));
  const nextVideoIndex = (currentIndex + 1) % 8;
  getKRPano()?.set(
    'hotspot[interior_wall_video].posterurl',
    `${baseUrl}${videos[nextVideoIndex]}_poster.png`
  );
  krpanoInvoke(
    `hotspot[interior_wall_video].playvideo('${baseUrl}${videos[nextVideoIndex]}.mp4')`
  );
};

const handleVideoDidComplete = async (
  videoName: string,
  dispatch: Dispatch
) => {
  if (videoName === 'stargate_video') {
    dispatch(actionToggleVeil(true));
    await timeout(2000);
    dispatch(actionToggleVeil(false));
  } else if (videoName === 'interior_wall_video') {
    if (interiorWallVideoScenes.includes(getCurrentScene())) {
      handleInteriorWallVideoPlaylist();
    }
  }
};

const loadScene = (scene: string) => {
  krpanoInvoke(`loadscene('${scene}', null, MERGE, BLEND(1))`);
};

const lookToView = (view: PanoView) => {
  krpanoInvoke(
    `lookto(${view.viewH || 0}, ${view.viewV || 0}, ${
      view.fov || 140
    }, smooth())`
  );
};

const loadSceneByMenu = (menu: RDUHudMenu) => {
  const sceneMenu = sceneByMenu[menu];
  const currentScene = getCurrentScene();
  if (currentScene !== sceneMenu.scene) {
    loadScene(sceneMenu.scene);
  }
  if (sceneMenu.view) lookToView(sceneMenu.view);
};
const handleTunnelVideoPlayed = (dispatch: Dispatch) => {
  setTimeout(() => {
    krpanoInvoke(`loadscene('scene_RD_ExpSing_01', null, MERGE, BLEND(0))`);
  }, 700);
  setTimeout(() => {
    dispatch(actionShowFullScreenVideoWithUrl({ show: false, url: undefined }));
  }, 1700);
};

const handleHotSpotClick = (hotSpotName: string, dispatch: Dispatch) => {
  switch (hotSpotName) {
    case 'entertunnel':
      if (window[ALLOW_ENTER_TUNNEL]) {
        handleEnterTunnel(dispatch);
      } else {
        loadScene('scene_12');
        lookToView({ viewH: 0.3013529975219101 });
        dispatch(actionAccessRequest({ open: true, muteVideo: false }));
      }
      break;
    case 'hotspot_name':
      // TO-DO: change to correct music hotspot name
      dispatch(actionOpenMusicPlaylist(true));
      break;
    default:
      break;
  }
};

const secretRoomScenes = [...expressiveSingularityScenes, ...adrenalineScenes];

const scenesToShowHud = [...secretRoomScenes, ...manufactureScenes];

const handleSceneChange = (sceneId: string, dispatch: Dispatch) => {
  dispatch(actionShowHudButton(scenesToShowHud.includes(sceneId)));
  getKRPano()?.set('display.anaglyph', false);
  if (window['pirelliHasOpened']) {
    setTimeout(() => {
      delete window['pirelliHasOpened'];
    }, 500);
  }

  if (welcomeToScene.includes(sceneId)) {
    setTimeout(() => {
      dispatch(actionShowWelcomeToScene(true));
    }, 1500);
  }
};

const getCurrentScene = () => getKRPano()?.get('xml.scene');

const hideHotSpot = () => {
  getKRPano()?.call('tween(hotspot[rd_movement].alpha, 0)');
  getKRPano()?.call('tween(hotspot[logo].alpha, 0)');
  getAllProductHotspotsInScene().forEach((hs) => {
    getKRPano()?.call(`tween(hotspot[${hs.name}].scale, 0, 0)`);
  });
  if (getCurrentScene() === 'scene_adrenaline09') {
    ['pirelli_red', 'pirelli_yellow', 'pirelli_white'].forEach((hs) => {
      getKRPano()?.call(`tween(hotspot[${hs}].scale, 0, 0)`);
    });
  }
};

const performFlip = async (dispatch: Dispatch, sceneName = undefined) => {
  dispatch(actionOpenHud(false));
  dispatch(actionShowVBZoomIndicator(false));
  const currentSceneName = getCurrentScene();
  if (!currentSceneName) return;
  const newSceneName = sceneName || secretRoomFlipScenePairs[currentSceneName];
  if (newSceneName) {
    window[IS_FLIPPING] = true;
    hideHotSpot();
    await timeout(500);
    krpanoInvoke(
      'set(rz,0);tween(rz,180,0.5,easeoutquad,null,txtadd(image.prealign,0|0|,get(rz)));'
    );
    await timeout(1000);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(300);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(300);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(200);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(100);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(100);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(150);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(100);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(50);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(50);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(50);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(50);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(50);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(50);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(20);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(20);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(20);
    krpanoInvoke('switch(display.anaglyph);');
    await timeout(20);
    krpanoInvoke('switch(display.anaglyph);');
    krpanoInvoke(
      'set(rz,0);tween(rz,0,0,easeoutquad,null,txtadd(image.prealign,0|0|,get(rz)));'
    );
    window[IS_FLIPPING] = false;
    krpanoInvoke(
      `loadscene('${newSceneName}', null, ${
        sceneName ? 'null' : 'KEEPVIEW'
      }, BLEND(0))`
    );
  } else {
    console.warn(
      `No corresponding scene found for current scene ${currentSceneName} to perform flip.`
    );
  }
};

const resetViewThenFlip = () => {
  const krpano = getKRPano();
  krpano?.call(
    `lookto(${krpano.get('xml.view.hlookat')}, ${krpano.get(
      'xml.view.vlookat'
    )}, ${krpano.get('xml.view.fov')}, , , ,jscall('krpanoCB.performFlip();'))`
  );
};

const goToManufactureFromStargate = () => {
  loadScene('stargate');
};

const updatePirelliProductFrame = (productId: string, frameUrl: string) => {
  const frame = first(getAllProductHotspotsInScene());
  if (frame) {
    getKRPano()?.set(
      `hotspot[${frame.name}].onclick`,
      `openPopup('PRODUCT','${productId}')`
    );
    getKRPano()?.set(`hotspot[${frame.name}].url`, frameUrl);
  }
};

const showPirelliWhite = () => {
  const krpano = getKRPano();
  krpano?.set('hotspot[pirelli_white].visible', true);
  krpano?.set('hotspot[pirelli_red].visible', false);
  krpano?.set('hotspot[pirelli_yellow].visible', false);
  updatePirelliProductFrame(whitePirelliProductId, whitePirelliFrameUrl);
};

const showPirelliYellow = () => {
  const krpano = getKRPano();
  krpano?.set('hotspot[pirelli_yellow].visible', true);
  krpano?.set('hotspot[pirelli_white].visible', false);
  krpano?.set('hotspot[pirelli_red].visible', false);
  updatePirelliProductFrame(yellowPirelliProductId, yellowPirelliFrameUrl);
};

const showPirelliRed = () => {
  const krpano = getKRPano();
  krpano?.set('hotspot[pirelli_red].visible', true);
  krpano?.set('hotspot[pirelli_yellow].visible', false);
  krpano?.set('hotspot[pirelli_white].visible', false);
  updatePirelliProductFrame(redPirelliProductId, redPirelliFrameUrl);
};

const showAdrenalineAnimation = () => {
  const krpano = getKRPano();
  krpano?.call(
    `lookto(${krpano.get('xml.view.hlookat')}, ${krpano.get(
      'xml.view.vlookat'
    )}, ${krpano.get(
      'xml.view.fov'
    )}, , , ,jscall('krpanoCB.performFlip("lambo");');)`
  );
};

const handleHudMenuClick = (menu: RDUHudMenu, dispatch: Dispatch) => {
  const currentScene = getCurrentScene();
  if (!currentScene) {
    return;
  }
  switch (menu) {
    case RDUHudMenu.ADRENALINE:
      if (expressiveSingularityScenes.includes(currentScene))
        isProd ? resetViewThenFlip() : showAdrenalineAnimation();
      break;
    case RDUHudMenu.EXPRESSIVE_SINGULARITY:
      if (adrenalineScenes.includes(currentScene)) resetViewThenFlip();
      break;
    case RDUHudMenu.LOBBY:
      if (manufactureScenes.includes(currentScene)) {
        loadSceneByMenu(RDUHudMenu.LOBBY);
      } else {
        goToManufactureFromStargate();
      }
      break;
      
    case RDUHudMenu.FIVE_AXIS_MILLING:
    case RDUHudMenu.ELECTRO_EROSION:
    case RDUHudMenu.WHEELS_POLISHING_WORKSHOPS:
    case RDUHudMenu.POLISH_OF_THE_EDGES:
    case RDUHudMenu.POLISH_OF_THE_WINGS:
    case RDUHudMenu.DIMENSION_CONTROLLING:
    case RDUHudMenu.CERCLAGE:
    case RDUHudMenu.PERLAGE:
    case RDUHudMenu.FLAT_MIRROR_POLISH_AND_DETOURING:
    case RDUHudMenu.FLAT_MIRROR_POLISH:
    case RDUHudMenu.DETOURING:
    case RDUHudMenu.GARNISH_WORKSHOPS:
    case RDUHudMenu.GARNISH_WORKSHOPS_OPENING:
    case RDUHudMenu.WATCH_MAKING_WORKSHOPS:
    case RDUHudMenu.QLAB:
    case RDUHudMenu.ETIRAGE:
      loadSceneByMenu(menu);
      break;

    case RDUHudMenu.PIRELLI_RED:
      loadSceneByMenu(RDUHudMenu.PIRELLI_RED);
      showPirelliRed();
      break;
    case RDUHudMenu.PIRELLI_WHITE:
      loadSceneByMenu(RDUHudMenu.PIRELLI_WHITE);
      showPirelliWhite();
      break;
    case RDUHudMenu.PIRELLI_YELLOW:
      loadSceneByMenu(RDUHudMenu.PIRELLI_YELLOW);
      showPirelliYellow();
      break;

    case RDUHudMenu.EXIT:
      if (window[TRANSITIONED_FROM_WW2021_TO_MANUFACTURE]) {
        dispatch(actionReloadStoreDefaultPano());
      } else {
        loadSceneByMenu(RDUHudMenu.EXIT);
      }
      break;
    default:
      break;
  }
};

const handleHideWelcomeImage = () => {
  const currentSceneName = getCurrentScene();
  if (welcomeToScene.includes(currentSceneName)) {
    krpanoInvoke('hotspot[video_hs].play();');
  }
};

const anglageVideosRange = {
  video_hs_1: {
    start: 178.86071408392732,
    end: 236.74878152625476
  },
  video_hs_2: {
    start: 236.74878152625476,
    end: 307.25068195874562
  },
  video_hs_3: {
    start: -0.1,
    end: 49.231200983679564
  },
  video_hs_4: {
    start: 49.231200983679564,
    end: 113.72872448715036
  }
};

const axisMillingVideoRange = {
  start: 218,
  end: 301
};

const electroErosionVideoRange = {
  left: {
    start: 300,
    end: 360
  },
  right: {
    start: 0,
    end: 75.81440981488385
  }
};

const isVideoShown = (videoName: string) => {
  return getKRPano()?.get(`hotspot[${videoName}].visible`);
};

const showVideoHotSpot = (videoName: string) => {
  getKRPano().set(`hotspot[${videoName}].visible`, true);
  krpanoInvoke(`hotspot[${videoName}].play();`);
};

const hideVideoHotSpot = (videoName: string) => {
  krpanoInvoke(`hotspot[${videoName}].pause();`);
  getKRPano().set(`hotspot[${videoName}].visible`, false);
};

const handleAnglagePanoView = (normalizedViewH: number) => {
  const videoInRange = Object.keys(anglageVideosRange).find(
    (videoKey) =>
      anglageVideosRange[videoKey].start < normalizedViewH &&
      anglageVideosRange[videoKey].end > normalizedViewH
  );
  if (videoInRange && !isVideoShown(videoInRange)) {
    showVideoHotSpot(videoInRange);
    Object.keys(anglageVideosRange)
      .filter((key) => key !== videoInRange)
      .forEach((key) => {
        hideVideoHotSpot(key);
      });
  }
};

const handlePirelliPanoView = (normalizedViewH: number, dispatch: Dispatch) => {
  const currentSceneName = getCurrentScene();
  if (!currentSceneName || !pirelliScenesRange[currentSceneName]) return;
  const match =
    pirelliScenesRange[currentSceneName].start < normalizedViewH &&
    pirelliScenesRange[currentSceneName].end > normalizedViewH;

  if (match && !window['pirelliHasOpened']) {
    dispatch(actionOpenHud(true));
  }
};

const handleAxisMillingPanoView = (normalizedViewH: number) => {
  if (
    axisMillingVideoRange.start < normalizedViewH &&
    axisMillingVideoRange.end > normalizedViewH
  ) {
    if (!isVideoShown('video_hs')) {
      showVideoHotSpot('video_hs');
    }
  } else {
    hideVideoHotSpot('video_hs');
  }
};

const handleElectroErosionPanoView = (normalizedViewH: number) => {
  if (
    (electroErosionVideoRange.left.start < normalizedViewH &&
      electroErosionVideoRange.left.end > normalizedViewH) ||
    (electroErosionVideoRange.right.start < normalizedViewH &&
      electroErosionVideoRange.right.end > normalizedViewH)
  ) {
    if (!isVideoShown('video_hs')) {
      showVideoHotSpot('video_hs');
    }
  } else {
    hideVideoHotSpot('video_hs');
  }
};

const handlePanoView = (view: PanoView, dispatch: Dispatch) => {
  const currentSceneName = getCurrentScene();
  const viewH = view.viewH;
  if (!viewH) return;
  const normalizedViewH = normalize(viewH);
  switch (currentSceneName) {
    case 'scene_anglage':
      handleAnglagePanoView(normalizedViewH);
      break;
    case 'scene_pano_5axis_milling':
      handleAxisMillingPanoView(normalizedViewH);
      break;
    case 'scene_pano_electro-erosion':
      handleElectroErosionPanoView(normalizedViewH);
      break;
    case 'scene_adrenaline09':
    case 'scene_adrenaline05':
    case 'scene_adrenaline01':
      handlePirelliPanoView(normalizedViewH, dispatch);
      break;
    default:
      break;
  }
};

export const handleRogerDubuisSGBoutiqueAction = (
  action: AnyAction,
  dispatch: Dispatch<AnyAction>
) => {
  switch (action.type) {
    case VB_DID_GO_TO_SCENE:
      shouldGlitch = false;
      handleSceneChange(action.payload, dispatch);
      break;
    case VB_DID_CLICK_ON_HOT_SPOT:
      handleHotSpotClick(action.payload, dispatch);
      break;
    case VB_START_GLITCH_PANO:
      startGlitchEffect();
      break;
    case VB_DID_CLICK_HUD_MENU:
      handleHudMenuClick(action.payload, dispatch);
      break;
    case VB_FLIP_PANO:
      performFlip(dispatch, action.payload);
      break;
    case VB_RESET_AND_FLIP_PANO:
      resetViewThenFlip();
      break;
    case VB_SHOW_WELCOME_TO_SCENE:
      if (!action.payload) {
        handleHideWelcomeImage();
      }
      break;
    case VB_DID_LOOK_TO_VIEW:
      handlePanoView(action.payload, dispatch);
      break;
    case VB_DID_START_PLAYING_FULL_SCREEN_VIDEO:
      if (
        action.payload === secretRoomTunnelDesktopVideoUrl ||
        action.payload === secretRoomTunnelMobileVideoUrl
      ) {
        handleTunnelVideoPlayed(dispatch);
      }
      break;
    case VB_ACCESS_REQUEST:
      if (!action.payload?.open && action.payload?.granted)
        handleEnterTunnel(dispatch);
      break;
    case VB_VIDEO_DID_COMPLETE:
      handleVideoDidComplete(action.payload, dispatch);
      break;
    default:
      break;
  }
};
