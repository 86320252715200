import { iwcBrandId } from '../../config';
const iwcFaceBookPixelTrackingId = '916605855041283';
const inspifyTrackingId = '641691106539629';

export const getFaceBookPixelTrackingIdByBrand = (brandId: string) => {
  if (brandId === 'inspify') {
    return inspifyTrackingId;
  }
  if (brandId === iwcBrandId) {
    return iwcFaceBookPixelTrackingId;
  }
  return null;
};
