import { AnyAction, Dispatch, Store } from 'redux';
import { sendPopupMessageToVB } from '../components/Meeting/Messaging/PopupBridge/popupMessageSender';
import { IMainState } from '../interfaces';

const popupMeetingMiddleware = (store: Store<IMainState>) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  const result = next(action);
  const state = store.getState();
  sendPopupMessageToVB(state.clientState, action);
  return result;
};

export default popupMeetingMiddleware;
