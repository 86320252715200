import { IWishlistPayload } from '../interfaces';
import { generateV4UUID } from '../utils/identityGenerator';
import { getIdentityId } from '../utils/identity';
import httpClient from './http';

export const fetchWishlist = (
  identityId: string,
  storeId: string | undefined
) => httpClient.post(`/api/getWishlist`, { identityId, storeId });

export const saveWishlist = (wishlist: IWishlistPayload) =>
  httpClient.post('/api/saveWishlist', {
    ...wishlist,
    modifiedAt: new Date()
  });

export const getDefaultPayload = (storeId: string, brandId: string) => ({
  id: generateV4UUID(),
  identityId: getIdentityId(),
  description: '',
  name: '',
  store: storeId,
  brand: brandId,
  wishlistItems: [],
  createdAt: new Date(),
  modifiedAt: new Date(),
  createdBy: getIdentityId()
});
