import { videoBaseUrl } from '.';

const rduVideoBaseUrl = `${videoBaseUrl}/content/converted/rdu_streaming/`;
const rduVideoBaseUrl2 = `${videoBaseUrl}/content/converted/67ed01a5-ee29-4a49-b6a0-7f5a0d15bf19/`;

const rduVideoBaseForRetailAndWholeSaleUrlGray = `${videoBaseUrl}/content/converted/c966268c-e9d1-4c0a-902e-1f82f317dc77/`;
const rduVideoBaseForRetailAndWholeSaleUrlRed = `${videoBaseUrl}/content/converted/13197352-2fbd-47e3-8c40-ee9810f2a387/`;

export const TRANSITIONED_FROM_WW2021_TO_MANUFACTURE =
  'TRANSITIONED_FROM_WW2021_TO_MANUFACTURE';

export const blackDoorUrl = `${rduVideoBaseUrl}black main.mov/index.m3u8`;
export const blackDoorMobileUrl = `${rduVideoBaseUrl2}black mb process.mp4/index.m3u8`;
export const redDoorUrl = `${rduVideoBaseUrl}red main.mov/index.m3u8`;
export const redDoorMobileUrl = `${rduVideoBaseUrl2}red mb proress.mp4/index.m3u8`;

export const blackDoorForRetailAndWholeSaleUrl = `${rduVideoBaseForRetailAndWholeSaleUrlGray}black main.mp4/index.m3u8`;
export const blackDoorMobileUrlForRetailAndWholeSaleUrl = `${rduVideoBaseForRetailAndWholeSaleUrlGray}black mb.mp4/index.m3u8`;

export const redDoorForRetailAndWholeSaleUrl = `${rduVideoBaseForRetailAndWholeSaleUrlRed}red main.mp4/index.m3u8`;
export const redDoorMobileForRetailAndWholeSaleUrl = `${rduVideoBaseForRetailAndWholeSaleUrlRed}red mb.mp4/index.m3u8`;

export enum SceneType {
  scene_010000 = 'scene_010000',
  scene_010000_night = 'scene_010000_night',

  scene_rd010000 = 'scene_rd010000',
  scene_rd010000_night = 'scene_rd010000_night',

  scene_rd020000 = 'scene_rd020000',
  scene_rd020000_night = 'scene_rd020000_night',

  scene_rd020000_door = 'scene_rd020000_door',
  scene_rd020000_door_night = 'scene_rd020000_door_night',

  scene_rd030000 = 'scene_rd030000',
  scene_rd030000_night = 'scene_rd030000_night',

  scene_rd040000 = 'scene_rd040000',
  scene_rd040000_night = 'scene_rd040000_night',

  scene_rd050000 = 'scene_rd050000',
  scene_rd050000_night = 'scene_rd050000_night',

  scene_rd060000 = 'scene_rd060000',
  scene_rd060000_night = 'scene_rd060000_night',

  scene_rd070000 = 'scene_rd070000',
  scene_rd070000_night = 'scene_rd070000_night',

  scene_left_030000 = 'scene_left_030000',
  scene_left_030000_night = 'scene_left_030000_night',

  scene_left_040000 = 'scene_left_040000',
  scene_left_040000_night = 'scene_left_040000_night',

  scene_left_060000 = 'scene_left_060000',
  scene_left_060000_night = 'scene_left_060000_night',

  scene_lounge_010000 = 'scene_lounge_010000',
  scene_lounge_010000_night = 'scene_lounge_010000_night',

  scene_lounge_020000 = 'scene_lounge_020000',
  scene_lounge_020000_night = 'scene_lounge_020000_night',

  scene_lounge_030000 = 'scene_lounge_030000',
  scene_lounge_030000_night = 'scene_lounge_030000_night',

  scene_lounge_040000 = 'scene_lounge_040000',
  scene_lounge_040000_night = 'scene_lounge_040000_night',

  scene_lounge_050000 = 'scene_lounge_050000',
  scene_lounge_050000_night = 'scene_lounge_050000_night',

  scene_lounge_060000 = 'scene_lounge_060000',
  scene_lounge_060000_night = 'scene_lounge_060000_night',

  scene_lounge_070000 = 'scene_lounge_070000',
  scene_lounge_070000_night = 'scene_lounge_070000_night',

  scene_lounge_080000 = 'scene_lounge_080000',
  scene_lounge_080000_night = 'scene_lounge_080000_night',

  scene_lounge_090000 = 'scene_lounge_090000',
  scene_lounge_090000_night = 'scene_lounge_090000_night',

  scene_rd01a = 'scene_rd01a',
  scene_rd01a_night = 'scene_rd01a_night',

  scene_left_070000 = 'scene_left_070000',
  scene_left_070000_night = 'scene_left_070000_night',
  
  scene_left_080000 = 'scene_left_080000',
  scene_left_080000_night = 'scene_left_080000_night'
}

export const rduSgPanoBasePath =
  'https://panos2.inspify.io/rogerdubuis/sg/ion/v173/vtour/';
