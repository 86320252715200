import axios, { AxiosResponse } from 'axios';
import { groupBy } from 'lodash';
import { logEvent } from '../analytics';
import {
  chopardBrandId,
  chopardHighJewelleryId,
  loubiAirwaysJpBoutiqueId
} from '../config';
import {
  CloudSearchFilter,
  IInterestedProduct,
  IProduct,
  ProductFamily,
  ProductSearchFilter,
  ProductSearchQuery
} from '../interfaces';
import { DID_SEARCH_FOR_PRODUCTS } from '../utils/constants';
import { getClientContextRequestHeader } from '../utils/window';
import httpClient from './http';
import { mapProductResponseToProduct } from '../mappers/product';

const baseUrl = `https://api.inspify.io`;


export const getProductsById = (productId: string) =>
  httpClient.get(`/api/products/${productId}`, getClientContextRequestHeader());

export const getProductAccessories = (productId: string) =>
  httpClient.get(
    `/api/productAccessories/${productId}`,
    getClientContextRequestHeader()
  );

export const getProductsByIds = (
  productIds: string[],
  additional: object = undefined
) =>
  httpClient.post(
    `/api/productsByIds`,
    productIds,
    getClientContextRequestHeader(additional)
  );

export const getInterestedProducts = (
  identityId: string,
  storeId: string
): Promise<AxiosResponse<IInterestedProduct[]>> =>
  httpClient.post('/api/getInterestedProducts', {
    identityId,
    storeId
  });

export const getSimilarProducts = (productId: string) =>
  httpClient.get(
    `/api/similarProducts/${productId}`,
    getClientContextRequestHeader()
  );

export interface QueryProductInspiration {
  productId: string;
  onlyProductOwner?: boolean;
  language: string;
}
export const getProductInspirations = (query: QueryProductInspiration) =>
  httpClient.post(`/api/productInspirations`, query);

export const queryForProductFamilies = (
  productFamilies: ProductFamily[]
): string => {
  if (productFamilies.length === 0) {
    return '';
  }
  return `(or ${productFamilies
    .map((f) => `product_family_facet: '${f.family}'`)
    .join(' ')})`;
};

export const queryForSearchFilters = (filters: CloudSearchFilter[]): string => {
  const byGroups = groupBy(filters, 'cloudSearchGroup');
  return Object.keys(byGroups || {})
    .map((group) => {
      const filtersInGroup = byGroups[group];
      return `(or ${filtersInGroup
        .map((f) => `${f.cloudSearchGroup}: '${f.cloudSearchField}'`)
        .join(' ')})`;
    })
    .join(' ');
};

const brandContentFilter = (storeId: string, brandId: string) => {
  if (storeId === chopardHighJewelleryId) {
    return `(or type_en: 'JEWELLERY' type_en: 'WRISTWATCH' type_en: 'HIGH JEWELLERY')`;
  }
  if (brandId === chopardBrandId) {
    return `(and (or type_en: 'JEWELLERY' type_en: 'WRISTWATCH') (not type_en: 'HIGH JEWELLERY'))`;
  }
  return '';
};

export const performProductSearch = (
  keywords: string,
  startIndex: number,
  size: number,
  brandId: string,
  productFamilies: ProductFamily[],
  filters: CloudSearchFilter[],
  storeId?: string
): Promise<AxiosResponse<IProduct[]>> => {
  const tagFilter =
    storeId === loubiAirwaysJpBoutiqueId ? ` tag: 'loubiairwaysjp'` : '';

  const storeIdWhitelistTag = storeId ? `tag: 'storeId:${storeId}'` : '';
  const query: ProductSearchQuery = {
    searchAttributes: {
      keywords,
      startIndex,
      size,
      queryString: `(and brand: '${brandId}' (or status: 'active' ${storeIdWhitelistTag}) ${queryForProductFamilies(
        productFamilies
      )} ${queryForSearchFilters(filters)} ${brandContentFilter(
        storeId,
        brandId
      )}${tagFilter})`
    },
    flattenResult: false
  };
  logEvent(DID_SEARCH_FOR_PRODUCTS, brandId, query);
  return httpClient.post(
    '/api/productSearch',
    query,
    getClientContextRequestHeader({ STORE: storeId })
  );
};

export const performProductSearchWithQuery = (
  startIndex: number,
  size: number,
  brandId: string,
  queryString: string
): Promise<AxiosResponse<IProduct[]>> => {
  const query: ProductSearchQuery = {
    searchAttributes: {
      keywords: '',
      startIndex,
      size,
      queryString
    },
    flattenResult: false
  };
  logEvent(DID_SEARCH_FOR_PRODUCTS, brandId, query);
  return httpClient.post('/api/productSearch', query);
};

export const getProductFamilies = (
  brandId: string
): Promise<AxiosResponse<ProductFamily[]>> => {
  const body = {
    brandId
  };
  return httpClient.post('/api/getProductFamilies', body);
};

export const getProductSearchFilters = (
  brandId: string
): Promise<AxiosResponse<ProductSearchFilter[]>> => {
  const body = {
    brandId
  };
  return httpClient.post('/api/getProductSearchFilters', body);
};

export const performProductSearchForPublic = async (
  keywords: string,
  startIndex: number,
  size: number,
  brandId: string,
  productFamilies: ProductFamily[],
  filters: CloudSearchFilter[],
  storeId?: string,
  apiKey?: string
) => {
  try {
    const tagFilter =
      storeId === loubiAirwaysJpBoutiqueId ? ` tag: 'loubiairwaysjp'` : '';

    const storeIdWhitelistTag = storeId ? `tag: 'storeId:${storeId}'` : '';
    const query: ProductSearchQuery = {
      searchAttributes: {
        keywords,
        startIndex,
        size,
        queryString: `(and brand: '${brandId}' (or status: 'active' ${storeIdWhitelistTag}) ${queryForProductFamilies(
          productFamilies
        )} ${queryForSearchFilters(filters)} ${brandContentFilter(
          storeId,
          brandId
        )}${tagFilter})`
      },
      flattenResult: false
    };
    logEvent(DID_SEARCH_FOR_PRODUCTS, brandId, query);
    const response = await axios.post(`${baseUrl}/products/search`, query, {
      headers: {
        'x-api-key': apiKey
      }
    });
    return response.data.productOfferings?.map(mapProductResponseToProduct);
  } catch (error) {
    return Promise.reject(error?.response);
  }
};