import { AxiosResponse } from 'axios';
import { orderBy } from 'lodash';
import httpClient from '../../../clientSideServices/http';
import { ISessionDetails, ISessionPayload } from '../../../interfaces';
import { apigClient, getS3Instance } from '../aws';

export const getMeetingRoomsByUserId = async (
  userId: string
): Promise<ISessionDetails[]> => {
  const client = await apigClient();
  const body = {
    userId,
    sessionType: 'room'
  };
  const path = `/sessions/v2/byUserIdAndType`;
  try {
    const response = await client.invokeApi({}, path, 'POST', {}, body);
    return orderBy(
      response.data,
      [(session) => session.topic?.toLowerCase()],
      ['asc']
    );
  } catch {
    return [];
  }
};

export const getSessionsByDate = async (
  userId: string,
  date: string
): Promise<AxiosResponse<ISessionDetails[]>> => {
  const client = await apigClient();
  const body = {
    userId,
    date
  };
  const path = `/sessions/v2/byUserIdAndDate`;
  return client.invokeApi({}, path, 'POST', {}, body);
};

export const saveSession = async (session: ISessionPayload) => {
  const client = await apigClient();
  const path = `/sessions/v2`;
  return client.invokeApi({}, path, 'PUT', {}, session);
};

export const deleteSession = async (sessionId: string) => {
  const client = await apigClient();
  const path = `/sessions/v2/byId`;
  const queryParams = {
    id: sessionId
  };
  return client.invokeApi({}, path, 'DELETE', { queryParams });
};

export const uploadToS3 = (
  file: File,
  bucket: string,
  key: string,
  onProgress?: (percentage: number) => void,
  isPublic?: boolean,
  contentType?: string,
  metadata?: any,
  contentEncoding?: string
) => {
  const payload = {
    Bucket: bucket,
    Key: key,
    Body: file,
    ACL: isPublic ? 'public-read' : undefined,
    ContentType: contentType,
    Metadata: metadata,
    ContentEncoding: contentEncoding
  };
  return getS3Instance().then((s3) =>
    s3
      .upload(payload)
      .on('httpUploadProgress', (progress) => {
        const progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        onProgress?.(progressPercentage);
      })
      .promise()
  );
};

export const addEventToCalendar = (event): Promise<AxiosResponse<Blob>> =>
  httpClient.post('/api/addEventToCalendar', event);
