const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;
const path = require('path');
const isChina = process.env.NEXT_PUBLIC_APP_REGION === 'cn';
module.exports = {
  backend: {
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 },
      {
        loadPath: '/static/locales/{{lng}}/{{ns}}.json'
      }
    ],
    backends:
      typeof window !== 'undefined' ? [LocalStorageBackend, HttpBackend] : []
  },
  i18n: {
    defaultLocale: isChina ? 'zh' : 'en',
    locales:
      isChina === 'cn'
        ? ['zh']
        : ['en', 'ar', 'zh', 'de', 'th', 'fr', 'vi', 'ja', 'pt_br', 'ko']
  },
  fallbackLng: {
    default: ['en']
  },
  localePath: path.resolve('./public/static/locales'),
  debug: false,
  serializeConfig: false,
  use: typeof window !== 'undefined' ? [ChainedBackend] : [],
  react: {
    bindI18n: 'languageChanged loaded',
    useSuspense: false
  }
};
