import {
  iwcBrandId,
  piagetBrandId,
  jlcBrandId,
  alsBrandId,
  rduBrandId,
  louboutinBrandId,
  zegnaBrandId,
  chopardBrandId
} from '../config';
import { PageView } from '../utils/pageView';

export const identityPool =
  'ap-southeast-1:0267a27a-914b-4161-96f0-b9c6f9ea64d5';
export const loginUrl = 'https://login-dev.inspify.com';
export const baseUrl =
  'https://hmux9j27n4.execute-api.ap-southeast-1.amazonaws.com/dev';
export const region = 'ap-southeast-1';
export const prodBaseUrlV2 =
  'https://5lcdbu2xk6.execute-api.ap-southeast-1.amazonaws.com/prod';

export const prodBaseUrlV1 =
  'https://wii4lu121a.execute-api.ap-southeast-1.amazonaws.com/prod';

export const avatarUrl = (userId: string) =>
  `https://inspify-users.s3.ap-southeast-1.amazonaws.com/${userId}/avatar.jpg`;
export const defaultAvatarUrl = 'https://assets.inspify.com/icons/profile.jpg';
export const storybookAnalyticsBaseUrl =
  'https://rmfc1cv9eh.execute-api.ap-southeast-1.amazonaws.com/prod';
export const assetBasePath =
  'https://inspify-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub';

export const hubDefaultPage = PageView.HUB_SESSION_OVERVIEW;
export const hubDefaultStoryPage = PageView.HUB_STORYBOOK;

export const knownStoryContexts = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'heritage', label: 'Heritage' },
  { value: 'about', label: 'About' },
  { value: 'partnership', label: 'Partnership' },
  { value: 'feature', label: 'Feature' },
  { value: 'video clip', label: 'Video Clip' },
  { value: 'lookbook', label: 'Lookbook' },
  { value: 'press release', label: 'Press Release' },
  { value: 'how to', label: 'How To' },
  { value: 'movement', label: 'Movement' },
  { value: 'complication', label: 'Complication' },
  { value: 'pinterest', label: 'Pinterest' },
  { value: 'edit', label: 'Edit' },
  { value: 'weibo', label: 'Weibo' },
  { value: 'youtube', label: 'Youtube' },
  { value: 'youku', label: 'YouKu' },
  { value: 'facebook', label: 'Facebook' }
];

export const knownLanguages = [
  { value: 'EN', label: 'English' },
  { value: 'AR', label: 'Arabic' },
  { value: 'ZH', label: 'Chinese' },
  { value: 'DE', label: 'German' },
  { value: 'FR', label: 'French' },
  { value: 'TH', label: 'Thai' },
  { value: 'JA', label: 'Japanese' },
  { value: 'KO', label: 'Korean' }
];

export const microSiteHeaderParams = {
  [iwcBrandId]: {
    title: {
      en: 'THE LATEST FROM THE WORLD OF IWC SCHAFFHAUSEN',
      ar: 'الأحدث من عالم IWC SCHAFFHAUSEN',
      fr: 'LE DERNIER DU MONDE DE IWC SCHAFFHAUSEN',
      th: 'ล่าสุดจากโลกของ IWC SCHAFFHAUSEN'
    }
  },
  [jlcBrandId]: {
    title: {
      en: 'THE LATEST FROM THE WORLD OF JAEGER-LECOULTRE',
      ar: 'الأحدث من عالم JAEGER-LECOULTRE',
      fr: 'LE DERNIER DU MONDE DE JAEGER-LECOULTRE',
      th: 'ล่าสุดจากโลกของ JAEGER-LECOULTRE'
    }
  },
  [piagetBrandId]: {
    title: {
      en: 'THE LATEST FROM THE WORLD OF PIAGET',
      ar: 'أحدث إصدار من عالم PIAGET',
      fr: 'LE DERNIER DU MONDE DE PIAGET',
      th: 'ล่าสุดจากโลกของ PIAGET'
    }
  },
  [alsBrandId]: {
    title: {
      en: 'THE LATEST FROM THE WORLD OF A. LANGE & SÖHNE',
      ar: 'الأحدث من عالم A. LANGE & SÖHNE',
      fr: 'LE DERNIER DU MONDE DE A. LANGE & SÖHNE',
      th: 'ล่าสุดจากโลกของ A.LANGE & SÖHNE'
    }
  },

  [rduBrandId]: {
    title: {
      en: 'THE LATEST FROM THE WORLD OF ROGER DUBUIS',
      ar: 'الأحدث من عالم ROGER DUBUIS',
      fr: 'LE DERNIER DU MONDE DE ROGER DUBUIS',
      th: 'ล่าสุดจากโลกของ ROGER DUBUIS'
    }
  },
  [louboutinBrandId]: {
    title: {
      en: 'THE LATEST FROM THE WORLD OF CHRISTIAN LOUBOUTIN',
      ar: 'الأحدث من عالم CHRISTIAN LOUBOUTIN',
      fr: 'LE DERNIER DU MONDE DE CHRISTIAN LOUBOUTIN',
      th: 'ล่าสุดจากโลกของคริสเตียนลูบูติน'
    }
  },
  [zegnaBrandId]: {
    title: {
      en: 'THE LATEST FROM THE WORLD OF ERMENEGILDO ZEGNA',
      ar: 'الأحدث من عالم ERMENEGILDO ZEGNA',
      fr: "LE DERNIER DU MONDE D'ERMENEGILDO ZEGNA",
      th: 'ล่าสุดจากโลกของ ERMENEGILDO ZEGNA'
    }
  },
  [chopardBrandId]: {
    title: {
      en: 'Your Chopard Selection'
    },
    description: {
      en: 'Inspirations individually curated for you based on your preferences.'
    }
  },
  default: {
    title: {
      en: 'THE LATEST INSPIRATIONS FOR YOU',
      ar: 'أحدث الإلهام لك',
      fr: 'LES DERNIÈRES INSPIRATIONS POUR VOUS',
      th: 'แรงบันดาลใจล่าสุดสำหรับคุณ'
    },
    description: {
      en: 'Inspirations individually curated for you like ',
      ar: 'الإلهام برعاية فردية لك مثل ',
      fr: 'Inspirations sélectionnées individuellement pour vous comme ',
      th: 'แรงบันดาลใจที่คัดสรรมาเพื่อคุณเช่น '
    }
  }
};

export const defaultChatSnippet: {
  [key: string]: { label: string; message: string }[];
} = {
  en: [
    {
      label: 'End chat',
      message:
        "It's been a pleasure speaking with you today, please let me know if I can assist with anything else."
    },
    {
      label: 'Transfer',
      message:
        "I'm going to transfer you to a colleague who will be best placed to assist you with this query."
    },
    {
      label: 'CRC contact',
      message:
        "Unfortunately, I don't have the means to check this in the Boutique. For this type of request I would recommend you to contact our concierge service at +XXXXXXXX. They will be happy to help you with your request. "
    },
    {
      label: 'Busy',
      message:
        'I am momentarily helping a client in store. Thank you in advance for your patience, I will be with you shortly.'
    },
    {
      label: 'Busy Boutique Transferring',
      message:
        'Our Boutique is suddenly busy with clients, please wait a moment while I transfer you to another Client Advisor so you can have our undivided attention. Thank you in advance for your patience.'
    },
    {
      label: 'Need to gather more info to answer?',
      message: 'Please wait a moment while I check on that for you.'
    },
    {
      label: 'Inventory',
      message: 'Please wait a moment while I locate the timepiece for you.'
    },
    {
      label: 'Black Book',
      message:
        'To give you further assistance, may I ask you to share your contact details. I will get back to you once I have gathered all the necessary information.'
    }
  ]
};

export const defaultStorybookSettings =
  'color=#000000,textColor=#ffffff,background=#ffffff,duration=3,muteParticipant=true,autoplay=true';

export const storybookEditHistoryStep = 5;
